import { setAssetModalOpen, setCurrentAsset } from 'actions/assets';
import { CompoundAsset } from 'components/EquityManagement/Asset/types';
import { EquityModalTypes } from 'components/EquityManagement/useEquityInputState/constants';
import { useDispatch } from 'react-redux';
import { PlaidAccount } from '@compoundfinance/compound-core/dist/types/plaid';
import PlaidAccountUtils from 'utils/plaid/accounts';
import { AssetTypes } from '@compoundfinance/compound-core/dist/types/account';

export type AssetModalTypes =
  | AssetTypes
  | EquityModalTypes.Grant
  | EquityModalTypes.Certificate;

function getManualAssetType(assetType: AssetModalTypes): AssetModalTypes {
  switch (assetType) {
    case AssetTypes.Crypto:
      return AssetTypes.ManualCryptoAccount;
    case AssetTypes.PublicInvestment:
      return AssetTypes.ManualInvestmentAccount;
    case AssetTypes.Cash:
      return AssetTypes.ManualBankAccount;
    default:
      return assetType;
  }
}

function useAssetModal() {
  const dispatch = useDispatch();

  function closeAssetModal() {
    dispatch(setAssetModalOpen(false));
  }

  /**
   * Opens asset modal. If no asset is provided, assume we're creating a new one
   * @param assetType Asset type to add.
   * @param asset asset to edit. If undefined, assume we're creating a new one.
   */
  function openAssetModal(assetType: AssetModalTypes, asset = {}) {
    const isManual = PlaidAccountUtils.isSelfServeProvider(
      asset as PlaidAccount,
    );
    const currentAsset = {
      ...asset,
      assetType: isManual ? getManualAssetType(assetType) : assetType,
    };
    dispatch(setCurrentAsset(currentAsset as CompoundAsset));
    dispatch(setAssetModalOpen(true));
  }

  return { openAssetModal, closeAssetModal };
}

export default useAssetModal;
