import AffirmLogo from 'assets/images/companies/affirm.webp';
import AirbnbLogo from 'assets/images/companies/airbnb.webp';
import AirtableLogo from 'assets/images/companies/airtable.webp';
import AsanaLogo from 'assets/images/companies/asana.webp';
import BlendLogo from 'assets/images/companies/blend.webp';
import BrazeLogo from 'assets/images/companies/braze.webp';
import BrexLogo from 'assets/images/companies/brex.webp';
import CabalLogo from 'assets/images/companies/cabal.webp';
import CartaLogo from 'assets/images/companies/carta.webp';
import ClassPassLogo from 'assets/images/companies/classpass.webp';
import CoinbaseLogo from 'assets/images/companies/coinbase.webp';
import CommsorLogo from 'assets/images/companies/commsor.webp';
import CompoundLogo from 'assets/images/companies/compound.webp';
import CourseraLogo from 'assets/images/companies/coursera.webp';
import DatabricksLogo from 'assets/images/companies/databricks.webp';
import DiscordLogo from 'assets/images/companies/discord.webp';
import DivvyLogo from 'assets/images/companies/divvy.webp';
import EpicGamesLogo from 'assets/images/companies/epicgames.webp';
import ExpensifyLogo from 'assets/images/companies/expensify.webp';
import FacebookLogo from 'assets/images/companies/facebook.webp';
import FaireLogo from 'assets/images/companies/faire.webp';
import FigmaLogo from 'assets/images/companies/figma.webp';
import FlexportLogo from 'assets/images/companies/flexport.webp';
import GithubLogo from 'assets/images/companies/github.webp';
import GoogleLogo from 'assets/images/companies/google.webp';
import HeadspaceLogo from 'assets/images/companies/headspace.webp';
import Instacart from 'assets/images/companies/instacart.webp';
import Lattice from 'assets/images/companies/lattice.webp';
import MercuryLogo from 'assets/images/companies/mercury.webp';
import NotionLogo from 'assets/images/companies/notion.webp';
import PalantirLogo from 'assets/images/companies/palantir.webp';
import PlaidLogo from 'assets/images/companies/plaid.webp';
import PostmatesLogo from 'assets/images/companies/postmates.webp';
import OpendoorLogo from 'assets/images/companies/opendoor.webp';
import RedditLogo from 'assets/images/companies/reddit.webp';
import ReplitLogo from 'assets/images/companies/replit.webp';
import RetoolLogo from 'assets/images/companies/retool.webp';
import RipplingLogo from 'assets/images/companies/rippling.webp';
import RobinhoodLogo from 'assets/images/institutions/robinhood.webp';
import SpaceXLogo from 'assets/images/companies/spacex.webp';
import SquarespaceLogo from 'assets/images/companies/squarespace.webp';
import StripeLogo from 'assets/images/companies/stripe.webp';
import TwilioLogo from 'assets/images/companies/twilio.webp';
import UberLogo from 'assets/images/companies/uber.webp';
import XenditLogo from 'assets/images/companies/xendit.webp';
import ZillowLogo from 'assets/images/companies/zillow.webp';
import { getEnumValues } from 'utils/constants';

export enum CompanyNames {
  Affirm = 'affirm',
  AirBnB = 'airbnb',
  Airtable = 'airtable',
  Asana = 'asana',
  Blend = 'blend',
  Braze = 'braze',
  Brex = 'brex',
  Cabal = 'cabal',
  Carta = 'carta',
  ClassPass = 'classpass',
  Coinbase = 'coinbase',
  Commsor = 'commsor',
  Compound = 'compound',
  Coursera = 'coursera',
  Databricks = 'databricks',
  Discord = 'discord',
  Divvy = 'divvy',
  Expensify = 'expensify',
  EpicGames = 'epicgames',
  Facebook = 'facebook',
  Faire = 'faire',
  Figma = 'figma',
  Flexport = 'flexport',
  Github = 'github',
  Google = 'google',
  Headspace = 'headspace',
  Instacart = 'instacart',
  Lattice = 'lattice',
  Mercury = 'mercury',
  Notion = 'notion',
  Opendoor = 'opendoor',
  Palantir = 'palantir',
  Plaid = 'plaid',
  Postmates = 'postmates',
  Reddit = 'reddit',
  Replit = 'replit',
  Retool = 'retool',
  Rippling = 'rippling',
  Robinhood = 'robinhood',
  SpaceX = 'spacex',
  Squarespace = 'squarespace',
  Stripe = 'stripe',
  Twilio = 'twilio',
  Uber = 'uber',
  Xendit = 'xendit',
  Zillow = 'zillow',
}

const companyLogosList = getEnumValues(CompanyNames);

const CompanyLogos = {
  [CompanyNames.Affirm]: AffirmLogo,
  [CompanyNames.AirBnB]: AirbnbLogo,
  [CompanyNames.Airtable]: AirtableLogo,
  [CompanyNames.Asana]: AsanaLogo,
  [CompanyNames.Blend]: BlendLogo,
  [CompanyNames.Braze]: BrazeLogo,
  [CompanyNames.Brex]: BrexLogo,
  [CompanyNames.Cabal]: CabalLogo,
  [CompanyNames.Carta]: CartaLogo,
  [CompanyNames.ClassPass]: ClassPassLogo,
  [CompanyNames.Coinbase]: CoinbaseLogo,
  [CompanyNames.Commsor]: CommsorLogo,
  [CompanyNames.Compound]: CompoundLogo,
  [CompanyNames.Coursera]: CourseraLogo,
  [CompanyNames.Databricks]: DatabricksLogo,
  [CompanyNames.Discord]: DiscordLogo,
  [CompanyNames.Divvy]: DivvyLogo,
  [CompanyNames.EpicGames]: EpicGamesLogo,
  [CompanyNames.Expensify]: ExpensifyLogo,
  [CompanyNames.Facebook]: FacebookLogo,
  [CompanyNames.Faire]: FaireLogo,
  [CompanyNames.Figma]: FigmaLogo,
  [CompanyNames.Flexport]: FlexportLogo,
  [CompanyNames.Github]: GithubLogo,
  [CompanyNames.Google]: GoogleLogo,
  [CompanyNames.Headspace]: HeadspaceLogo,
  [CompanyNames.Instacart]: Instacart,
  [CompanyNames.Lattice]: Lattice,
  [CompanyNames.Mercury]: MercuryLogo,
  [CompanyNames.Notion]: NotionLogo,
  [CompanyNames.Opendoor]: OpendoorLogo,
  [CompanyNames.Palantir]: PalantirLogo,
  [CompanyNames.Plaid]: PlaidLogo,
  [CompanyNames.Postmates]: PostmatesLogo,
  [CompanyNames.Reddit]: RedditLogo,
  [CompanyNames.Replit]: ReplitLogo,
  [CompanyNames.Retool]: RetoolLogo,
  [CompanyNames.Rippling]: RipplingLogo,
  [CompanyNames.Robinhood]: RobinhoodLogo,
  [CompanyNames.SpaceX]: SpaceXLogo,
  [CompanyNames.Squarespace]: SquarespaceLogo,
  [CompanyNames.Stripe]: StripeLogo,
  [CompanyNames.Twilio]: TwilioLogo,
  [CompanyNames.Uber]: UberLogo,
  [CompanyNames.Xendit]: XenditLogo,
  [CompanyNames.Zillow]: ZillowLogo,
};

function getCompanyImage(companyName: string) {
  const safeCompanyName = companyName || '';
  const companyMatch = companyLogosList.find((name) =>
    new RegExp(`(\\s|^)${name}(\\s|$)`, 'i').test(safeCompanyName),
  );

  if (!companyMatch) {
    return null;
  }

  return CompanyLogos[companyMatch];
}

export default getCompanyImage;
