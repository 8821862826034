import { setupWorker } from 'msw';

import { getEnvironmentName, PRODUCTION } from 'utils/env';

const handlers = [];
const hasHandlers = handlers.length > 0;

if (hasHandlers && getEnvironmentName() === PRODUCTION) {
  const worker = setupWorker(...handlers);
  worker.start({
    onUnhandledRequest: 'bypass',
    quiet: true,
  });
}
