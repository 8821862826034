export enum IntegrationType {
  Plaid = 'plaid',
  PrivateEquityAccount = 'privateEquityAccount',
  ManualBank = 'manualBank',
  ManualCrypto = 'manualCrypto',
  Coinbase = 'coinbase',
  CoinbasePro = 'coinbasePro',
  Gemini = 'gemini',
  BlackDiamond = 'blackDiamond',
  Yodlee = 'yodlee',
  Ethplorer = 'ethplorer',
  BlockchainDotCom = 'blockchainDotCom',
  Zapper = 'zapper',
  Mx = 'mx',
  Argyle = 'argyle',
  Contro = 'contro',
}

export enum IntegrationRefreshStatus {
  Pending = 'pending',
  Timeout = 'timeout',
  Success = 'success',
}
