import { ContainerState, ContainerActions } from '../types/reducers/admin';
import ActionTypes from '../actionTypes/admin';
import { DEFAULT_USER_STATE } from 'containers/App/util/constants';
import { replaceReducer } from 'reducers/hmr';
import { Reducers } from 'types/reducers';
// The initial state of the App
export const initialState: ContainerState = {
  loading: false,
  error: '',
  active: false,
  userData: {
    firstName: '',
    lastName: '',
    email: '',
    companyData: [],
    filingStatus: '',
    onboardingComplete: false,
    reportComplete: false,
    taxableIncome: 0,
  },
  adminUser: DEFAULT_USER_STATE,
};

// Take this container's state (as a slice of root state), this container's actions and return new state
function adminReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.UpdateAdminState:
      return {
        ...state,
        ...action.payload.state,
      };
    default:
      return state;
  }
}

// Replace the reducer with the hot loaded one
if (import.meta.hot) {
  import.meta.hot.accept(replaceReducer(Reducers.ADMIN));
}

export default adminReducer;
