import { Box, Col, Row, Text } from '@compoundfinance/design-system';
import {
  CSS,
  config,
} from '@compoundfinance/design-system/dist/stitches.config';
import URLUtils from 'utils/constants/url';
import UiUtils from 'utils/ui';
import {
  PrivateMarketDeal,
  PrivateMarketDealClass,
  PrivateMarketDealFundType,
  PrivateMarketDealGoal,
} from './types';

const { colors } = config.theme;

interface DealCardProps {
  deal: PrivateMarketDeal;
  recommended?: boolean;
  selectDeal: () => void;
}

export enum TagType {
  Goal = 'goal',
  Class = 'class',
  Recommended = 'recommended',
}

export const classMapping = {
  [PrivateMarketDealClass.Debt]: 'Debt',
  [PrivateMarketDealClass.HedgeFund]: 'Hedge Fund',
  [PrivateMarketDealClass.Venture]: 'Venture',
  [PrivateMarketDealClass.RealEstate]: 'Real Estate',
  [PrivateMarketDealClass.PrivateEquity]: 'Private Equity',
  [PrivateMarketDealClass.DirectOrCoInvestment]: 'Direct or Co-Investment',
};

export const goalMapping = {
  [PrivateMarketDealGoal.Diversification]: 'Diversification',
  [PrivateMarketDealGoal.Growth]: 'Growth',
  [PrivateMarketDealGoal.Income]: 'Income Generation',
};

export const fundTypeMapping = {
  [PrivateMarketDealFundType.OpenEnded]: 'Open Ended',
  [PrivateMarketDealFundType.CloseEnded]: 'Close Ended',
};

export function Tag(props: { text: string; type: TagType; css?: CSS }) {
  const { text, type, css } = props;

  const styling = (() => {
    if (type === TagType.Goal) {
      return {
        background: 'rgba(221, 243, 228, 1)',
        color: 'green10' as const,
      };
    } else if (type === TagType.Class) {
      if (text === classMapping[PrivateMarketDealClass.Debt]) {
        return {
          background: 'rgba(252, 229, 243, 1)',
          color: 'pink10' as const,
        };
      }

      return {
        background: 'rgba(243, 231, 252, 1)',
        color: 'purple10' as const,
      };
    }
    return {
      background: 'white',
      fontWeight: 'medium',
      color: 'gray12',
    };
  })();

  return (
    <Box
      css={{
        ...styling,
        borderRadius: '$4',
        py: '$2',
        px: '$6',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...css,
      }}
    >
      <Text
        size="12"
        color={styling.color as keyof typeof colors}
        css={{ display: 'inline-block', whiteSpace: 'nowrap' }}
      >
        {text}
      </Text>
    </Box>
  );
}

function DealCard(props: DealCardProps) {
  const { deal, selectDeal } = props;
  const isDemo = URLUtils.isDemo();
  return (
    <Row
      css={{
        p: '$8',
        columnGap: '$36',
        br: '$10',
        bg: '$gray0',
        cursor: isDemo ? 'default' : 'pointer',
        transition: 'box-shadow 200ms ease-in-out',
        '&:hover': isDemo ? undefined : { boxShadow: '$shadows$small' },
      }}
      role="button"
      tabIndex={0}
      onClick={isDemo ? undefined : selectDeal}
    >
      <Box css={{ w: 200, position: 'relative' }}>
        {/* Using a div with `background` instead of <img/> prevents flashing from object-fit: cover. */}
        <Box
          css={{
            width: 200,
            height: '100%',
            borderRadius: '$8',
            background: `url(${deal.bannerImageUrl}) center / cover`,
          }}
        />
      </Box>
      <Col css={{ flex: 1.3, mt: '$16' }}>
        <Text size="16" weight="bold" css={{ lineHeight: 1.21 }}>
          {deal.name}
        </Text>
        <Text size="13" color="gray10" css={{ mt: '$8' }}>
          <div dangerouslySetInnerHTML={{ __html: deal.description }} />
        </Text>
        <Row css={{ mt: 'auto', mb: '$6', gap: '$8', flexWrap: 'wrap' }}>
          <Tag text={classMapping[deal.class]} type={TagType.Class} />
          {deal.goals.map((goal) => (
            <Tag text={goalMapping[goal]} type={TagType.Goal} />
          ))}
        </Row>
      </Col>
      <Col css={{ flex: 1, mr: '$32', maxWidth: '360px', mt: '$16' }}>
        <Row
          css={{
            justifyContent: 'space-between',
            borderBottom: '1px solid $gray3',
            pb: '$12',
          }}
        >
          <Text size="13" weight="medium">
            Targeted Return
          </Text>
          <Text size="13" css={{ textAlign: 'right' }}>
            {deal.targetReturnLowerBound}% - {deal.targetReturnUpperBound}%
          </Text>
        </Row>
        <Row
          css={{
            justifyContent: 'space-between',
            borderBottom: '1px solid $gray3',
            padding: '$12 0',
          }}
        >
          <Text size="13" color="gray10">
            Lockup Period
          </Text>
          <Text size="13" color="gray12" css={{ textAlign: 'right' }}>
            {deal.lockUpPeriod}
          </Text>
        </Row>
        <Row
          css={{
            justifyContent: 'space-between',
            borderBottom: '1px solid $gray3',
            padding: '$12 0',
          }}
        >
          <Text size="13" color="gray10">
            Min. Investment
          </Text>
          <Text size="13" color="gray12" css={{ textAlign: 'right' }}>
            {UiUtils.formatDecimals(deal.minimumInvestment, 0, 0, true)}
          </Text>
        </Row>
        <Row
          css={{
            justifyContent: 'space-between',
            borderBottom: '1px solid $gray3',
            padding: '$12 0',
          }}
        >
          <Text size="13" color="gray10">
            Liquidity
          </Text>
          <Text size="13" color="gray12" css={{ textAlign: 'right' }}>
            {deal.liquidity}
          </Text>
        </Row>
        <Row
          css={{
            justifyContent: 'space-between',
            padding: '$12 0',
          }}
        >
          <Text size="13" color="gray10">
            Fund Type
          </Text>
          <Text size="13" color="gray12" css={{ textAlign: 'right' }}>
            {fundTypeMapping[deal.fundType]}
          </Text>
        </Row>
      </Col>
    </Row>
  );
}

export default DealCard;
