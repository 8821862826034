import {
  GREEN,
  RED,
  YELLOW,
  OFF_YELLOW,
  CORN_YELLOW,
  BORDER,
  OFF_WHITE,
  BLUE_GREY_BORDER,
  STONE_BLUE,
  BORDER_GREY,
  INACTIVE_GREY,
  GREY,
  ORANGE,
  NAVY,
  DARK_BLUE_GREY,
  MUTED_BLUE,
  OFF_WHITE_BLUE,
  BORDER_BLACK,
  ONBOARD_DARK_GREY,
  ONBOARD_MID_GREY,
  VIS_ORANGE,
  VIS_AQUA,
  VIS_PURPLE,
  PORTAGE_PURPLE,
  VIS_BLUE,
  BORDER_OFF_WHITE,
  LIGHT_GREY,
  DB_LIGHT_GREY,
  DB_LIGHTER_GREY,
  DB_MID_GREY,
  OFF_WHITE_LIGHTER,
  VIS_YELLOW,
  PEACH,
  LIGHT_BLUE,
  LIGHT_RED,
  LIGHT_GREEN,
  LIGHT_YELLOW,
  PINK,
  DARKER_MUTED_BLUE,
  BG_GREY,
  AL_CASH,
  AL_PUBLIC_INVESTMENTS,
  AL_LOANS,
  AL_CREDIT_CARDS,
  AL_EQUITY,
  AL_REAL_ESTATE,
  AL_PRIVATE_INVESTMENTS,
  AL_FUND_INVESTMENTS,
  AL_OTHER_ASSETS,
  AL_OTHER_LIABILITIES,
  AL_CRYPTO,
  DENIM_BLUE,
  WARNING_RED,
  DARKER_GREY,
  VIS_MAGENTA,
  ACTIVE_GREY,
  HOVER_GREY,
  VIS_DEEP_BLUE,
  LIGHT_BLACK_GREY,
  TRANSPARENT,
  ICE_BLUE,
  PALE_YELLOW,
  BROWN,
  SUCCESS_GREEN,
  DARKER_YELLOW,
} from '@compoundfinance/design-system/dist/style/theme';
import { AssetTypes } from '@compoundfinance/compound-core/dist/types/account';

export const mainTheme = {
  colors: {
    navy: NAVY,
    muted_blue: MUTED_BLUE,
    darker_muted_blue: DARKER_MUTED_BLUE,
    orange: ORANGE,
    light_grey: LIGHT_GREY,
    mid_light_grey: '#d6d6d6',
    grey: GREY,
    hover_grey_on_background: '#EDEDED',
    dark_grey: '#C0C0C0',
    darker_grey: DARKER_GREY,
    mid_grey: '#6F6F6F',
    inactive_grey: INACTIVE_GREY,
    border_grey: BORDER_GREY,
    border_off_white: BORDER_OFF_WHITE,
    hover_grey: HOVER_GREY,
    active_grey: ACTIVE_GREY,
    off_white: OFF_WHITE,
    off_white_lighter: OFF_WHITE_LIGHTER,
    black_grey: '#5B5A5A',
    blue_grey: '#698297',
    dark_blue_grey: DARK_BLUE_GREY,
    blue_grey_border: BLUE_GREY_BORDER,
    stone_blue: STONE_BLUE,
    white: '#ffffff',
    black: '#222222',
    green: GREEN,
    peach: PEACH,
    red: RED,
    yellow: YELLOW,
    off_yellow: OFF_YELLOW,
    corn_yellow: CORN_YELLOW,
    off_white_blue: OFF_WHITE_BLUE,
    onboard_dark_grey: ONBOARD_DARK_GREY,
    onboard_mid_grey: ONBOARD_MID_GREY,
    vis_orange: VIS_ORANGE,
    vis_aqua: VIS_AQUA,
    vis_blue: VIS_BLUE,
    vis_purple: VIS_PURPLE,
    vis_magenta: VIS_MAGENTA,
    vis_yellow: VIS_YELLOW,
    vis_deep_blue: VIS_DEEP_BLUE,
    portage_purple: PORTAGE_PURPLE,
    db_light_grey: DB_LIGHT_GREY,
    db_lighter_grey: DB_LIGHTER_GREY,
    db_mid_grey: DB_MID_GREY,
    light_blue: LIGHT_BLUE,
    light_red: LIGHT_RED,
    light_yellow: LIGHT_YELLOW,
    pink: PINK,
    bg_grey: BG_GREY,
    db_sidebar_icon_grey: '#6c6d6f',
    transparent: TRANSPARENT,
    light_green: LIGHT_GREEN,
    denim_blue: DENIM_BLUE,
    warning_red: WARNING_RED,
    success_green: SUCCESS_GREEN,
    darker_yellow: DARKER_YELLOW,
    light_black_grey: LIGHT_BLACK_GREY,
    brown: BROWN,
    ice_blue: ICE_BLUE,
    pale_yellow: PALE_YELLOW,
    /**
     * Colors tied to assets and accounts page
     */
    [AssetTypes.Cash]: AL_CASH,
    [AssetTypes.ManualBankAccount]: AL_CASH,
    [AssetTypes.PublicInvestment]: AL_PUBLIC_INVESTMENTS,
    [AssetTypes.ManualInvestmentAccount]: AL_PRIVATE_INVESTMENTS,
    [AssetTypes.StartupEquity]: AL_EQUITY,
    [AssetTypes.RealEstate]: AL_REAL_ESTATE,
    [AssetTypes.PrivateInvestment]: AL_PRIVATE_INVESTMENTS,
    [AssetTypes.FundInvestment]: AL_FUND_INVESTMENTS,
    [AssetTypes.CompoundFunds]: AL_FUND_INVESTMENTS,
    [AssetTypes.LP]: AL_FUND_INVESTMENTS,
    [AssetTypes.GP]: AL_FUND_INVESTMENTS,
    [AssetTypes.Other]: AL_OTHER_ASSETS,
    [AssetTypes.Loan]: AL_LOANS,
    [AssetTypes.CreditCard]: AL_CREDIT_CARDS,
    [AssetTypes.OtherLiability]: AL_OTHER_LIABILITIES,
    [AssetTypes.Crypto]: AL_CRYPTO,
    [AssetTypes.ManualCryptoAccount]: AL_CRYPTO,
    'private-equity-fund': AL_FUND_INVESTMENTS,
    'venture-capital-fund': AL_FUND_INVESTMENTS,
    'real-estate-private-fund': AL_FUND_INVESTMENTS,
    'private-credit-fund': AL_FUND_INVESTMENTS,
    'hedge-fund': AL_FUND_INVESTMENTS,
    'other-fund-investments': AL_FUND_INVESTMENTS,
  },
  sizes: {
    container_lg: '800px',
    container_sm: '600px',
  },
  fonts: {
    serif: 'Le Monde Livre',
    sansSerif: 'Inter var',
    mono: 'Inter var',
  },
  fw: {
    regular: 450,
    medium: 525,
    bold: 600,
  },
  border: BORDER,
  border_black: BORDER_BLACK,
  breakpoints: {
    a: '1280px',
    a1: '1100px',
    b: '930px',
    mobile: '500px',
  },
};

export const COMPOUND_COLORS = mainTheme.colors;
