export enum EntityFileTypes {
  Grant = 'grant',
  PrivateEquity = 'privateEquityAccount',
  Certificate = 'certificate',
  Loan = 'loan',
  RealEstate = 'realEstate',
  Other = 'other',
  PrivateInvestment = 'privateInvestment',
  LP = 'lpExposure',
  GP = 'gpExposure',
  PlaidAccount = 'plaidAccount',
  TaxProfile = 'taxProfile',
  OtherFiles = 'otherFiles',
  Crypto = 'crypto',
  CompoundInvestAccount = 'compoundInvestAccount',
  BankAccount = 'bankAccount',
}

export const DEFAULT_DOCUMENTS_HEIGHT = '240px';
