export interface ClientAgreement {
  signedDate: Date | null;
  docusignPowerFormUrl: string | null;
  fileId?: string;
  id?: string;
  userId?: string;
}

export const DEFAULT_CLIENT_AGREEMENT: ClientAgreement & { loaded: boolean } = {
  docusignPowerFormUrl: '',
  signedDate: null,
  loaded: false,
};
