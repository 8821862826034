import { NumberUtils } from 'utils/NumberUtils';

type Vector = Array<number>;
const VectorUtils = {
  multiply: (a: Vector, b: Vector) =>
    a
      .map((value, index) => NumberUtils.multiply(value, b[index]))
      .reduce(NumberUtils.add, 0),
};

export type Matrix = Array<Vector>;
export const MatrixUtils = {
  transpose: (matrix: Matrix): Matrix =>
    matrix.reduce(
      (transposed: Matrix, row) =>
        transposed.map((transposedRow, index) => [
          ...(transposedRow ?? []),
          row[index],
        ]),
      Array.from(
        { length: Array.isArray(matrix[0]) ? matrix[0].length : 0 },
        () => [],
      ),
    ),
  multiply: (a: Matrix, b: Matrix) =>
    a.map((aRow) =>
      MatrixUtils.transpose(b).map((bRow) => VectorUtils.multiply(aRow, bRow)),
    ),
};
