import React, { lazy, Suspense } from 'react';

interface Props {
  fallback: React.ReactNode | null;
  delay?: number;
}
const loadable = <T extends React.ComponentType<any>>(
  importFunc: () => Promise<{ default: T }>,
  { fallback = null }: Props = { fallback: null },
  delay = 200,
) => {
  const LazyComponent = delay
    ? lazy(async () => {
        await new Promise((resolve) => setTimeout(resolve, delay));
        return importFunc();
      })
    : lazy(importFunc);

  return (props: any) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default loadable;
