export const USER_FILING_STATUS = {
  SINGLE: 'single',
  MARRIED_JOINTLY: 'marriedJointly',
  MARRIED_SEPARATELY: 'marriedSeparately',
  HEAD_OF_HOUSEHOLD: 'headOfHouseHold',
  WIDOW: 'widow',
};

export const MARRIED_FILING_STATUSES = [
  USER_FILING_STATUS.MARRIED_JOINTLY,
  USER_FILING_STATUS.MARRIED_SEPARATELY,
];

export const USER_FIELDS = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  LEGAL_FIRST_NAME: 'legalFirstName',
  LEGAL_LAST_NAME: 'legalLastName',
  EMAIL: 'email',
  REPORT_COMPLETE: 'reportComplete',
  ONBOARDING_COMPLETE: 'onboardingComplete',
  ACCOUNT_AUTHENTICATED: 'accountAuthenticated',
  BIRTHDAY: 'birthday',
  GENDER: 'gender',
  PHONE_NUMBER: 'phoneNumber',
  HAS_SPOUSE: 'hasSpouse',
};

export const USER_FIELD_LABELS = {
  [USER_FIELDS.FIRST_NAME]: 'First name',
  [USER_FIELDS.LAST_NAME]: 'Last name',
  [USER_FIELDS.LEGAL_FIRST_NAME]: 'Legal first name',
  [USER_FIELDS.LEGAL_LAST_NAME]: 'Legal last name',
  [USER_FIELDS.EMAIL]: 'Email',
  [USER_FIELDS.REPORT_COMPLETE]: USER_FIELDS.REPORT_COMPLETE,
  [USER_FIELDS.ONBOARDING_COMPLETE]: USER_FIELDS.ONBOARDING_COMPLETE,
  [USER_FIELDS.ACCOUNT_AUTHENTICATED]: USER_FIELDS.ACCOUNT_AUTHENTICATED,
  [USER_FIELDS.BIRTHDAY]: 'Date of birth',
  [USER_FIELDS.GENDER]: 'Gender',
  [USER_FIELDS.PHONE_NUMBER]: 'Phone number',
  [USER_FIELDS.HAS_SPOUSE]: 'Do you have a spouse?',
};

export enum UserRoleType {
  Default = 'default',
  Advisor = 'advisor',
  Partner = 'partner',
}
