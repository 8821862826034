import { PlaidAccountProvider } from 'utils/plaid/constants';

import { AllocationAsset } from '../../Accounts/AssetAllocation/AllocationExplorer';
import { AssetSource } from 'domain/AssetSource';
import { SimplifiedOriginalAsset } from '../utils/types';

const simplifyOriginalAsset = (
  originalAsset: any,
): SimplifiedOriginalAsset => ({
  id: originalAsset.id!,
  plaidAccountId: originalAsset.plaidAccountId,
  assetType: originalAsset.assetType,
  provider: originalAsset.provider,
  type: originalAsset.type,
  subtype: originalAsset.subtype,
  institutionId: originalAsset.institutionId,
  name: originalAsset.name,
});

const isCompoundInvestment = (asset: SimplifiedOriginalAsset) =>
  asset.provider === PlaidAccountProvider.BlackDiamond ||
  asset.provider === PlaidAccountProvider.Orion;

const belongsToAccount = (asset: any) => !!asset.account;

const isRealEstate = (asset: AllocationAsset) =>
  asset.assetSource === AssetSource.RealEstate;

export const Asset = {
  belongsToAccount,
  isCompoundInvestment,
  isRealEstate,
  simplifyOriginalAsset,
};
