import { ChevronRight, Flex, Row, Text } from '@compoundfinance/design-system';

import { AllocationProposalTypes } from 'containers/Dashboard/AllocationProposals/domain/AllocationProposal';

import AllocationProposalChanges from 'containers/Dashboard/AllocationProposals/components/AllocationProposalChanges';
import { Percentage } from '../../../components/Figures';

const SimplifiedAllocationProposalChanges = ({
  allocationProposal,
}: {
  allocationProposal: AllocationProposalTypes.CategorizedAllocationProposal;
}) => {
  const {
    blended: {
      original: { changes: blendedOriginalChanges },
      target: { changes: blendedTargetChanges },
    },
  } = allocationProposal;

  return (
    <AllocationProposalChanges allocationProposal={allocationProposal}>
      <Row css={{ jc: 'center', ai: 'center', gap: '$8' }}>
        <Flex css={{ w: '120px', flex: '0 0 auto', jc: 'end' }}>
          <Text
            size="12"
            color="gray9"
            css={{ textAlign: 'end', whiteSpace: 'pre-line' }}
          >
            Annual Return
          </Text>
        </Flex>

        <Flex
          css={{
            w: '100%',
            p: '$8',
            bg: '$gray2',
            borderRadius: '$4',
          }}
        >
          <Text size="12" color="gray11">
            <Percentage percentage={blendedOriginalChanges.annualReturn} />
          </Text>
        </Flex>

        <Flex css={{ flex: '0 0 auto' }}>
          <ChevronRight size={16} />
        </Flex>

        <Flex
          css={{
            w: '100%',
            p: '$8',
            bg: '$gray2',
            borderRadius: '$4',
          }}
        >
          <Text size="12" color="gray11">
            <Percentage percentage={blendedTargetChanges.annualReturn} />
          </Text>
        </Flex>
      </Row>

      <Row css={{ jc: 'center', ai: 'center', gap: '$8' }}>
        <Flex css={{ w: '120px', flex: '0 0 auto', jc: 'end' }}>
          <Text size="12" color="gray9" css={{ textAlign: 'end' }}>
            Annual Volatility
          </Text>
        </Flex>

        <Flex
          css={{
            w: '100%',
            p: '$8',
            bg: '$gray2',
            borderRadius: '$4',
          }}
        >
          <Text size="12" color="gray11">
            <Percentage percentage={blendedOriginalChanges.annualVolatility} />
          </Text>
        </Flex>

        <Flex css={{ flex: '0 0 auto' }}>
          <ChevronRight size={16} />
        </Flex>

        <Flex
          css={{
            w: '100%',
            p: '$8',
            bg: '$gray2',
            borderRadius: '$4',
          }}
        >
          <Text size="12" color="gray11">
            <Percentage percentage={blendedTargetChanges.annualVolatility} />
          </Text>
        </Flex>
      </Row>
    </AllocationProposalChanges>
  );
};

export default SimplifiedAllocationProposalChanges;
