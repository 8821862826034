import { AssetTypes } from '@compoundfinance/compound-core/dist/types/account';

export enum FundInvestmentType {
  PrivateEquity = 'privateEquity',
  VentureCapital = 'ventureCapital',
  RealEstate = 'realEstate',
  PrivateCredit = 'privateCredit',
  Hedge = 'hedge',
  Other = 'other',
}

export const FUND_INVESTMENT_TYPE_TO_CATEGORY_NAME = {
  ventureCapital: 'Venture Capital Fund',
  realEstate: 'Real Estate Private Fund',
  privateEquity: 'Private Equity Fund',
  privateCredit: 'Private Credit Fund',
  hedge: 'Hedge Fund',
  other: 'Other Fund Investments',
} as const;

export enum LPExposureEventType {
  Distribution = 'distribution',
  CapitalCall = 'capitalCall',
  ValuationChange = 'valuationChange',
  ValuationDecrease = 'valuationDecrease',
  ValuationIncrease = 'valuationIncrease',
  CommittedCapital = 'committedCapital',
  Comment = 'comment',
  Buy = 'buy',
  Dividend = 'dividend',
  Other = 'other',
}

export const LPExposureEventTypeToTitle = {
  [LPExposureEventType.Distribution]: 'Distributions',
  [LPExposureEventType.CapitalCall]: 'Capital Calls',
  [LPExposureEventType.ValuationDecrease]: 'Valuation Changes',
  [LPExposureEventType.ValuationIncrease]: 'Valuation Changes',
  [LPExposureEventType.ValuationChange]: 'Valuation Changes',
  [LPExposureEventType.CommittedCapital]: 'Committed Capital',
  [LPExposureEventType.Comment]: 'Comments',
  [LPExposureEventType.Buy]: 'Buys',
  [LPExposureEventType.Dividend]: 'Dividends',
  [LPExposureEventType.Other]: 'Other',
};

export interface LPExposureEvent {
  id?: string;
  date: Date;
  amount: number;
  type: LPExposureEventType;
  lpExposureId: string;
  accountId?: string | null;
  userId?: string;
}

export interface FundInvestment {
  id?: string;
  ownerId: string;
  name: string;
  fundName: string;
  firmName: string;
  type: FundInvestmentType;
  notes?: string;
  currentBalance: number;
  actualCurrentBalance?: number | null;
  assetType?: AssetTypes;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface LPExposure extends FundInvestment {
  committedCapital: number;
  legallySubscribed: boolean;
  events: LPExposureEvent[];
  managedByCompound: boolean;
  provider: string | null;
  providerId?: string;
  fundId?: string;
  firmId?: string;
  integrationId?: string;
  // used for settings/accounts/connects page
  companyName?: string;
  username?: string;
  key?: string;
  date?: Date;
}

export interface GPExposure extends FundInvestment {
  fundLaunchYear: number | null;
  fundSize: number | null;
  managementFeeRate: number | null;
  estimatedCarryChargedPercent: number | null;
  ownershipPercent: number | null;
}

export interface InvestmentEvent {
  id?: string;
  date: Date;
  amount: number;
  accountId: string | null;
  investmentId: string | null;
  accountType: string | null;
  investmentType: string | null;
  type: LPExposureEventType.Distribution;
  createdAt?: Date;
  updatedAt?: Date;
}
