import buildRequest from 'utils/api';
import { REQUEST_TYPES } from 'utils/constants/axios';
import { CompoundDocument } from '../Advisor/DocumentsAdmin';

type addModuleToDocumentParams = {
  documentId: string;
  module: string;
  insertAfter: string | null;
};

async function updateDocumentProperties(
  documentId: string,
  properties: CompoundDocument['properties'],
) {
  return buildRequest(
    `/api/documents/${documentId}/properties`,
    true,
    properties,
    REQUEST_TYPES.PATCH,
  ).then((response) => response.data);
}

async function addModuleToDocument(params: addModuleToDocumentParams) {
  const { documentId, module, insertAfter } = params;
  try {
    const result = await buildRequest(
      `/api/documents/${documentId}/modules`,
      true,
      { module, insertAfter },
      REQUEST_TYPES.POST,
    );

    return result.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

async function updateModuleProperties(
  moduleId: string,
  value: Record<string, any>,
) {
  try {
    const property = await buildRequest(
      `/api/documents/modules/${moduleId}/properties`,
      true,
      value,
      REQUEST_TYPES.PATCH,
    );

    return property.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

async function addPropertyValue(
  moduleId: string,
  propertyKey: string,
  value: any,
) {
  try {
    const property = await buildRequest(
      `/api/documents/modules/${moduleId}/properties`,
      true,
      { propertyKey, propertyValue: value },
      REQUEST_TYPES.POST,
    );

    return property.data;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

async function getLinkPreview(url: string) {
  try {
    const response = await buildRequest(
      '/api/documents/helpers/resolve-url',
      true,
      {},
      REQUEST_TYPES.GET,
      { url },
    );

    return response.data.url;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

const DocumentsAPI = {
  updateDocumentProperties,
  addModuleToDocument,
  updateModuleProperties,
  addPropertyValue,
  getLinkPreview,
};

export default DocumentsAPI;
