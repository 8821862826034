import { ValueOf } from 'types';
import {
  AccountSnapshot,
  Ownership,
} from '@compoundfinance/compound-core/dist/types/account';
import {
  InvestmentValuationType,
  InvestmentStatus,
  InvestmentVehicle,
  InvestmentPlatform,
} from 'utils/constants/privateInvestments';
import { InvestmentEvent } from 'types/fundInvestments';
import {
  Grant,
  ExerciseEvent,
  SimulatedExerciseEvent,
} from '@compoundfinance/compound-core/dist/types/equity';
import { PrivateInvestmentProvider } from '@compoundfinance/compound-core/dist/types/equity';

export interface SimulatorGrant extends Grant {
  saleFmv?: number;
  exerciseFmv?: number;
}

export type CountedEvent = (ExerciseEvent | SimulatedExerciseEvent) & {
  fmv: number | null;
  sold?: number;
  tempId?: string;
  activityId: string | null;
  certificateId: string | null;
};

export enum PrivateInvestmentType {
  Common = 'common',
  Preferred = 'preferred',
  ConvertibleNote = 'convertibleNote',
  OtherSimpleAgreement = 'otherSimpleAgreement',
}

export interface PrivateInvestmentValuation {
  id?: string;
  date: Date | null;
  estimatedValue: number | null;
  privateInvestmentId: string;
  sharePrice: number | null;
  valuationType: ValueOf<typeof InvestmentValuationType>;
}

export interface PrivateInvestment {
  id?: string;
  fundName: string | null;
  companyName: string | null;
  investmentStage: string | null;
  committed: number | null;
  shareQuantity: number | null;
  date: Date | null;
  sharePrice: number | null;
  type: PrivateInvestmentType | null;
  provider: PrivateInvestmentProvider | null;
  notes: string;
  ownershipPercent: number | null;
  projections: any;
  changes: string[];
  accountSnapshots: AccountSnapshot[];
  privateInvestmentValuations: PrivateInvestmentValuation[];
  providerId: string | null;
  securityId: string | null;
  cancelled: boolean;
  certificateId?: string;
  ownerId?: string;
  integrationId?: string;
  estimatedValue: number | null;
  latestValuation: number | null;
  valuationType: ValueOf<typeof InvestmentValuationType>;
  investmentStatus: ValueOf<typeof InvestmentStatus> | null;
  exitDate: Date | null;
  investmentVehicle: ValueOf<typeof InvestmentVehicle> | null;
  carryPercent: number | null;
  platformHeld: ValueOf<typeof InvestmentPlatform> | null;
  platformName: string | null;
  updatedAt?: Date;
  investmentEvents: InvestmentEvent[];
  isQsbsQualifiedBusiness: boolean;
  qsbsAssetsQualified: boolean;
  ownership?: Ownership;
}
