import { isNil, isNumber, sumBy, orderBy } from 'lodash';
import moment from 'moment';
import { AccountSectionItem } from 'types/assets/section';
import { isCurrentEmployee } from 'shared/equity/peAccount/utils';
import {
  Grant,
  PrivateEquityAccount,
} from '@compoundfinance/compound-core/dist/types/equity';
import { CertificateIssueReason } from '../certificates/constants';
import { getSharesVestedByDate, isRSA, isRSU } from '../utils';
import DateUtils from '@compoundfinance/compound-core/dist/date-utils';

function getBargainElement(grant: Grant, valuation: number) {
  if (isRSU(grant)) {
    return valuation;
  }

  const price = isRSA(grant)
    ? 0 // technically this should be grant.pricePerShare, but we assume the client has already paid the price since they've early exercised the grant
    : grant.strikePrice;

  return valuation - (price || 0);
}

function getExercisedCounts(props: {
  grant: Grant;
  unvested?: number;
  date?: Date;
}) {
  const { grant, date, unvested } = props;
  // We always have to get this, as we have to loop over exercise event objects to
  // get an accurate count by the supplied date.
  // Should this actually just break up counts into two buckets?
  // 1. get vested shares by date in question, 2. if an exercise event occured before
  // that date, it is early, 3. if an exercise event occurs after, it is regular
  const rawExerciseCount = getOptionExercisedCount(props.grant, props.date);
  const unvestedCount = !isNil(unvested)
    ? unvested
    : getUnvestedCount(grant, date);
  const vestedCount = (grant.shareCount ?? 0) - unvestedCount;
  const earlyExerciseCount = Math.max(rawExerciseCount - vestedCount, 0);

  return {
    earlyExerciseCount,
    exerciseCount: Math.max(rawExerciseCount - earlyExerciseCount, 0),
  };
}

function getOptionExercisedCount(grant: Grant, date?: Date) {
  // We assume that all RSAs are fully early exercised
  if (isRSA(grant)) {
    return grant.shareCount ?? 0;
  }

  // If no date is supplied, we just need to get the total exercised count as represented on
  // the grant object
  if (!date) {
    return grant.exercisedCount ?? 0;
  }

  // If a date is supplied, we sum all exercise events that occured on or prior to the date
  const eventsBeforeDate = grant.exerciseEvents.filter((e) => {
    const eventDate = DateUtils.getMomentCompatibleNativeDate(e.date);
    return (
      eventDate && new Date(eventDate).getTime() <= new Date(date).getTime()
    );
  });

  return Math.max(sumBy(eventsBeforeDate, 'amount'), 0);
}

/**
 * Determine if the user has early exercised any of their options.
 * RSAs are treated slightly different, in that we assume that all options
 * are early exercised upon grant, as that is the most typical treatment.
 *
 * Note that any time of grant can be passed to this function, if the grant can't
 * be early exercised, a 0 will be returned.
 *
 * @param grant — A direct equity grant
 * @param vested An overriding vested count, useful if one was precomputed in a previous function. Optional
 * @param date Date by which we want to know how many options were early exercised. Optional
 */
function getEarlyExercisedCount(grant: Grant, vested?: number, date?: Date) {
  const vestedCount = vested ? vested : grant.vestedCount ?? 0;

  let rawEarlyExerciseAmt: number;

  if (isRSA(grant)) {
    // It is likely that the exercisedCount is not set accurately, so we'll set it manually.
    // Currently, we assume that all RSAs have had an 83(b) election (i.e. were exercised early)
    rawEarlyExerciseAmt = (grant.shareCount ?? 0) - vestedCount;
  } else {
    rawEarlyExerciseAmt = getOptionExercisedCount(grant, date) - vestedCount;
  }

  if (date) {
    return Math.max(rawEarlyExerciseAmt, 0);
  }

  return Math.max(rawEarlyExerciseAmt, 0);
}

/**
 * Get a count of all unvested options, less the options which have been cancelled
 * as a result of the user leaving their company
 * @param grant
 */
function getUnvestedCount(grant: Grant, date?: Date) {
  if (date) {
    const sharesVested = getSharesVestedByDate(grant.vestEvents, date);
    return Math.max((grant.shareCount ?? 0) - sharesVested, 0);
  }

  return Math.max(grant.unvestedCount ?? 0, 0);
}

function getUnvestedValue(grant: Grant, valuation: number, count: number) {
  return count * getBargainElement(grant, valuation);
}

/**
 * Get the amount of time that a user has to exercise vested options
 * following an exit from their current company
 * @param grant Compound grant
 * @returns exerciseWindow
 */
function getExerciseWindow(grant: Grant, targetDate?: Date) {
  const { exerciseWindow, expirationDate } = grant;
  const target = moment(targetDate || new Date());

  // Get date of when exerciseWindow will end
  const exerciseWindowEnd = target.clone().add(exerciseWindow || 0, 'days');

  const expiration = expirationDate && moment.utc(expirationDate);

  // If there is an expiration date that's before exerciseWindowEnd, use that
  // If the number is negative, we know we can reset the value to zero
  if (
    expiration &&
    (!isNumber(exerciseWindow) || expiration.isBefore(exerciseWindowEnd))
  ) {
    // If we have an expiration date, use it. The amount of days the client has to exercise, using the expiration date
    // is the # of days between the expiration date and the current date,
    return Math.max(
      Math.abs(expiration.diff(moment.utc().startOf('day'), 'days')),
      0,
    );
  }
  // If exercise window is a number, use it, or set it to zero
  return isNumber(exerciseWindow) ? exerciseWindow : 0;
}

/**
 * Generates a string that lists different type of grants there are for the given accounts
 */
export const generateNameForGrants = (accounts: AccountSectionItem[]) => {
  const tranches = accounts.map((account) => account?.name?.split(' · ')[1]);
  if (!tranches.length) {
    return '';
  }
  if (tranches.length === 1) {
    return tranches[0] ?? '';
  }
  const name = [
    tranches.slice(0, -1).join(', '),
    tranches[tranches.length - 1],
  ].join(tranches.length > 2 ? ', and ' : ' and ');
  if (name === 'Vested options and Unvested options') {
    return 'Vested and unvested options';
  }
  if (name === 'Unvested options and Unvested RSUs') {
    return 'Unvested options and RSUs';
  }

  return name;
};

interface FaultyGrant {
  grant: Grant;
  failureReasons: string[];
}

function validateGrant(
  privateEquityAccount: PrivateEquityAccount,
  grant: Grant,
) {
  const { exerciseEvents, vestEvents } = grant;
  const failureReasons: string[] = [];

  if (exerciseEvents?.length) {
    const { certificates = [] } = privateEquityAccount;
    const grantCertificates = certificates.filter(
      (cert) =>
        cert.grantId === grant.id &&
        cert.issueReason === CertificateIssueReason.Exercise,
    );

    // Check if there's less certificates than exercise events
    if (grantCertificates?.length < exerciseEvents.length) {
      failureReasons.push('Missing certificates');
      // Note: Stock splits mean there is a  1:n ratio of exercise events to grant certificates
    } else if (grantCertificates?.length > exerciseEvents.length) {
      failureReasons.push('Potential stock split certificates');
    }
  }

  const orderedVestEvents = orderBy(vestEvents, 'date', 'asc');

  // Check if grant has any vest events
  if (!orderedVestEvents || orderedVestEvents.length === 0) {
    failureReasons.push('Missing Vest Events');
  }

  // Check if exercise event amounts add up to grant exercise count
  const grantCount = grant.exercisedCount;
  const exercisedCount = sumBy(exerciseEvents, 'amount');
  if (grantCount !== exercisedCount) {
    failureReasons.push(
      'Grant Exercise Count does not match sum of counts from exercise events',
    );
  }
  return failureReasons;
}

/**
 * Determines if grants are valid and ingested properly
 * @param grants
 * @param privateEquityAccount
 */
function validateGrants(privateEquityAccount: PrivateEquityAccount) {
  const { grants } = privateEquityAccount;
  if (!grants.length) {
    return [];
  }

  const faultyGrants: FaultyGrant[] = [];

  // Detect if grant exercise events match certificates.
  grants.forEach((grant) => {
    const failureReasons = validateGrant(privateEquityAccount, grant);

    if (failureReasons.length > 0) {
      faultyGrants.push({ grant, failureReasons });
    }
  });
  return faultyGrants;
}

/**
 *
 * Check whether the given grant has expired
 * If the account belongs to a current employee, then we assume
 * the grant has not expired. Otherwise, use the exercise window to
 * compute the expiration date of the grant, and compare it with today
 *
 * @param grant the grant instance to check
 * @param peAccount the private equity account associated with the grant
 * @returns expired or not
 */
function isGrantExpired(grant: Grant, peAccount: PrivateEquityAccount) {
  const today = moment.utc().startOf('day');
  const employmentEndDate = peAccount.endDate && moment.utc(peAccount.endDate);
  if (
    (peAccount.relationship && isCurrentEmployee(peAccount.relationship)) ||
    isNil(employmentEndDate)
  ) {
    return false;
  }
  const exerciseWindow: number = GrantUtils.getExerciseWindow(grant);
  const exerciseEndDate = (employmentEndDate as moment.Moment)
    .clone()
    .add(exerciseWindow, 'days');
  return exerciseEndDate.isSameOrBefore(today);
}

/**
 *
 * Check whether the given grant is early exercisable
 * the grant is early exercisable if the field is set to true
 * OR if it is set to not sure, then we rely on the account policy
 *
 * @param grant the grant instance to check
 * @param peAccount the private equity account associated with the grant
 * @returns expired or not
 */
function isGrantEarlyExercisable(
  grant: Grant,
  peAccount: PrivateEquityAccount,
) {
  return (
    grant.earlyExercise ||
    (isNil(grant.earlyExercise) && peAccount.earlyExercise)
  );
}

/**
 *
 * Check whether the given grant has been fully exercised, that is:
 * the grant is not early exercisable and all vested shares are exercised
 * OR the grant is early exercisable and all shares are (early) exercised
 *
 * @param grant the grant instance to check
 * @param peAccount the private equity account associated with the grant
 * @returns is fully exercised or not
 */
function isGrantHasExercisableShares(
  grant: Grant,
  peAccount: PrivateEquityAccount,
) {
  if (isGrantExpired(grant, peAccount)) {
    return false;
  }
  const today = moment.utc().startOf('day').toDate();
  const earlyExercisable = isGrantEarlyExercisable(grant, peAccount);
  const allExercisedCount = GrantUtils.getOptionExercisedCount(grant, today);
  if (earlyExercisable) {
    return allExercisedCount < (grant.shareCount ?? 0);
  } else {
    return allExercisedCount < (grant.vestedCount ?? 0);
  }
}

/**
 * Exposes a set of helper functions to compute counts and values for a tranche
 * of direct equity. Each of these function accepts at least an object conforming
 * to the Grant interface.
 */
const GrantUtils = {
  getEarlyExercisedCount,
  getUnvestedCount,
  getUnvestedValue,
  getBargainElement,
  getExercisedCounts,
  getExerciseWindow,
  validateGrants,
  getOptionExercisedCount,
  isGrantExpired,
  isGrantEarlyExercisable,
  isGrantHasExercisableShares,
};

export default GrantUtils;
