/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 */

enum ActionTypes {
  START_REQUEST = 'INTEGRATIONS/START_REQUEST',
  COMPLETE_REQUEST = 'INTEGRATIONS/COMPLETE_REQUEST',
  SENDING_PE_INTEGRATIONS_REQUEST = 'INTEGRATIONS/SENDING_PE_INTEGRATIONS_REQUEST',
  UPDATE_INTEGRATIONS_STATE = 'INTEGRATIONS/UPDATE_INTEGRATIONS_STATE',
  LOGIN = 'INTEGRATIONS/LOGIN',
  SET_AUTH = 'INTEGRATIONS/SET_AUTH',
  SET_ERROR_MESSAGE = 'INTEGRATIONS/SET_ERROR_MESSAGE',
  REMOVE_INTEGRATION_REQUEST = 'INTEGRATIONS/REMOVE_INTEGRATION_REQUEST',
  UPDATE_PLAID_INTEGRATIONS = 'INTEGRATIONS/UPDATE_PLAID_INTEGRATIONS',
  DELETE_PLAID_INTEGRATION = 'INTEGRATIONS/DELETE_PLAID_INTEGRATION',
  RESYNC_PE_ACCOUNT = 'INTEGRATIONS/RESYNC_PE_ACCOUNT',
  REFRESH_PE_ACCOUNTS = 'INTEGRATIONS/REFRESH_PE_ACCOUNTS',
  SET_RESYNCED = 'INTEGRATIONS/SET_RESYNCED',
  UpdateEquityIntegrations = 'INTEGRATIONS/UpdateEquityIntegrations',
  UPDATE_ARGYLE_INTEGRATIONS = 'INTEGRATIONS/UPDATE_ARGYLE_INTEGRATIONS',
  ResetRefresh = 'INTEGRATIONS/ResetRefresh',
  DismissIntegrationError = 'INTEGRATIONS/DismissIntegrationError',
}

export default ActionTypes;
