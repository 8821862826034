import _ from 'lodash';
import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { PrivateInvestment, PrivateInvestmentType } from 'types/equity';
import AssetUtils from 'utils/assets';
import useTypedSelector from 'hooks/typedSelector';
import {
  InvestmentStatus,
  SortMethods,
} from 'utils/constants/privateInvestments';
import { CompoundAsset } from 'components/EquityManagement/Asset/types';
import { AssetTypes } from '@compoundfinance/compound-core/dist/types/account';

type PIType = PrivateInvestmentType | string | null;

function isConvertibleNote(type: PIType) {
  return (
    (type as PrivateInvestmentType) === PrivateInvestmentType.ConvertibleNote
  );
}

function isOtherSimpleAgreement(type: PIType) {
  return (
    (type as PrivateInvestmentType) ===
    PrivateInvestmentType.OtherSimpleAgreement
  );
}

function isCommonStock(type: PIType) {
  return (type as PrivateInvestmentType) === PrivateInvestmentType.Common;
}

function isPreferredStock(type: PIType) {
  return (type as PrivateInvestmentType) === PrivateInvestmentType.Preferred;
}

function getPriceString(investment: PrivateInvestment) {
  const { type } = investment;
  return type === PrivateInvestmentType.Preferred ? 'preferred price' : '409A';
}

const sortByIssueDate = (investments: CompoundAsset[]) => {
  return _.orderBy(
    investments,
    (i) => new Date((i as PrivateInvestment).date as Date).getTime(),
    'desc',
  );
};

const sortByCurrentValue = (investments: CompoundAsset[]) => {
  return investments.sort(
    (a, b) => AssetUtils.getAssetValue(b) - AssetUtils.getAssetValue(a),
  );
};

const sortByStatus = (investments: CompoundAsset[]) => {
  const grouped = _.groupBy(investments, 'investmentStatus');

  return _.compact([
    grouped[InvestmentStatus.Active],
    grouped[InvestmentStatus.Exited],
    grouped[InvestmentStatus.Failed],
  ]);
};

const sortByStatusIssueDate = (investments: CompoundAsset[]) => {
  const investmentsByStatus = sortByStatus(investments);
  const sorted = investmentsByStatus.flatMap(sortByIssueDate);

  return sorted;
};

const sortByStatusCurrentValue = (investments: CompoundAsset[]) => {
  const investmentsByStatus = sortByStatus(investments);
  const sorted = investmentsByStatus.flatMap(sortByCurrentValue);

  return sorted;
};

const SortMethodMap = {
  [SortMethods.CurrentValue]: sortByCurrentValue,
  [SortMethods.IssueDate]: sortByIssueDate,
  [SortMethods.StatusCurrentValue]: sortByStatusCurrentValue,
  [SortMethods.StatusIssueDate]: sortByStatusIssueDate,
};

const getInvestmentsSorter = (state: ApplicationRootState) =>
  state.global.userSettings.privateInvestmentHoldingsSort;

const getInvestments = (state: ApplicationRootState) =>
  state.assets.privateInvestment.map((pi) => ({
    ...pi,
    assetType: AssetTypes.PrivateInvestment,
  }));

const getSortedPrivateInvestments = () => {
  const selector = createSelector(
    [getInvestmentsSorter, getInvestments],
    (sorter, investments) => {
      const sortFn = SortMethodMap[sorter] ?? sortByStatusIssueDate;

      return sortFn(investments);
    },
  );

  return useTypedSelector(selector, _.isEqual);
};

const PrivateInvestmentUtils = {
  isConvertibleNote,
  isOtherSimpleAgreement,
  isPreferredStock,
  isCommonStock,
  getPriceString,
  getSortedPrivateInvestments,
};

export default PrivateInvestmentUtils;
