import { useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import compact from 'lodash/compact';
import orderBy from 'lodash/orderBy';
import first from 'lodash/first';
import sumBy from 'lodash/sumBy';
import partition from 'lodash/partition';

import PlaidAccountUtils from 'utils/plaid/accounts';
import useTypedSelector from './typedSelector';
import AssetUtils from 'utils/assets';
import { CompoundAsset } from 'components/EquityManagement/Asset/types';
import { AssetTypes } from '@compoundfinance/compound-core/dist/types/account';
import getOtherAssetsSectionBreakdown from 'utils/assets/breakdown';
import { AssetAccountSection, AccountSectionItem } from 'types/assets/section';
import AccountsViewUtils from 'utils/view/accounts';
import { AssetTitles } from 'components/EquityManagement/Asset/constants';
import { AssetsDisplayOrder } from 'utils/constants/assets';
import { buildAssetAccountSection } from 'utils/presenters/accountSections';
import { FilterType } from 'utils/assetFilters/types';

/**
 * This function is used to move private investments that may be tied
 * to a private equity account from the equity asset section to the
 * private investment asset section.
 *
 * This handles an edge case in which a user has certificates or convertible notes
 * tied to a private equity account rather than a private investment asset.
 *
 * This should only happen with older accounts synced via carta.
 */
function allocatePrivateInvestments(
  assetSections: AssetAccountSection[],
  additionalAssets: AccountSectionItem[],
  filters: Record<string, boolean>,
) {
  let privateInvestments = assetSections.find(
    (a) => a.type === AssetTypes.PrivateInvestment,
  );

  /**
   * Both of these conditionals handle an edge case in which a user has only certificates,
   * and those are only tied to a PE account, and not to instances of the PrivateInvestment model
   * */
  if (!privateInvestments) {
    // The user doesnt have any other private investments, so we create this section for them
    privateInvestments = buildAssetAccountSection({
      type: AssetTypes.PrivateInvestment,
      accounts: additionalAssets,
      title: AssetTitles[AssetTypes.PrivateInvestment],
      displayOrder: AssetsDisplayOrder[AssetTypes.PrivateInvestment],
      filtered: additionalAssets
        .filter((account) => filters[account.id as string])
        .map((a) => a.id as string),
    });
  } else {
    /**
     * There are existing private investments, shift any equity from the company section (DirectEquity)
     * and into the private investments section. This is an unlikely case as most
     * private investments should have been properly synced via carta and will
     * be represented as an instance of the PrivateInvestment model
     **/
    privateInvestments.updateAccounts(additionalAssets);
    privateInvestments.setFiltered([
      ...(privateInvestments.filtered || []),
      ...additionalAssets
        .filter((account) => filters[account.id as string])
        .map((a) => a.id as string),
    ]);
  }
}

/**
 * Gets account breakdown and account sections.
 * @param excludeEquity - denotes whether we want to exclude equity from the breakdown numbers
 * @returns {Breakdown} - composed of assetAccountSections and liabilityAccountSections which are used when rendering various plaid and equity accounts in table formats
 * and the "breakdown" object which assigns a value to each component of a user's net worth (investments, liabilities, assets, cash, etc)
 */

interface BreakdownConfig {
  excludeEquity?: boolean;
  useCashflowFilters?: boolean;
  inputAccountFilters?: Record<string, boolean>;
  includeHiddenAccounts?: boolean;
  splitPublicInvestmentsAndCompoundInvestments?: boolean;
  splitFundInvestmentAndCompoundAlts?: boolean;
  includeGrantlessPrivateEquityAccounts?: boolean;
  filterAssets?: (
    value?: CompoundAsset,
    index?: number,
    array?: CompoundAsset[],
  ) => boolean;
}

function useGetAccountsBreakdown({
  excludeEquity = false,
  useCashflowFilters = false,
  inputAccountFilters = undefined,
  includeHiddenAccounts = false,
  filterAssets,
  splitPublicInvestmentsAndCompoundInvestments = false,
  includeGrantlessPrivateEquityAccounts = false,
  splitFundInvestmentAndCompoundAlts = false,
}: BreakdownConfig = {}) {
  const plaidAccounts = useTypedSelector(
    (state) =>
      orderBy(
        includeHiddenAccounts
          ? state.assets.rawAccounts
          : state.assets.accounts,
        'updatedAt',
      ),
    isEqual,
  );

  const accountFilters = useTypedSelector((state) => {
    if (inputAccountFilters) {
      return inputAccountFilters;
    }
    if (useCashflowFilters) {
      const filters = Object.entries(
        state.cashflow.activeCashflow.accountFilters,
      ).map(([accountId, hidden]) => [accountId, FilterType.Hide]);
      return Object.fromEntries(filters);
    }
    return state.assets.assetIdToFilterType;
  }, isEqual);

  const peAccounts = useTypedSelector(
    (state) =>
      includeHiddenAccounts
        ? state.assets.rawPrivateEquityAccounts
        : state.assets.privateEquityAccounts,
    isEqual,
  );

  const { equitySection, convertibleNotes, investorEquity } = useMemo(() => {
    return AccountsViewUtils.getEquityAccountSection(
      peAccounts,
      accountFilters,
      undefined,
      includeHiddenAccounts,
      includeGrantlessPrivateEquityAccounts,
    );
  }, [
    peAccounts,
    accountFilters,
    includeHiddenAccounts,
    includeGrantlessPrivateEquityAccounts,
  ]);

  const flatAssets = AssetUtils.useFlattenCompoundAssets(includeHiddenAccounts);

  const assets = useMemo(() => {
    if (filterAssets) {
      return flatAssets.filter(filterAssets);
    }
    return flatAssets;
  }, [flatAssets, filterAssets]);

  const {
    assetAccountSections: plaidAssetAccountSections,
    liabilityAccountSections,
  } = useMemo(() => {
    const plaidAccountsWithAssetTypes = plaidAccounts.map((p) => ({
      ...p,
      assetType: PlaidAccountUtils.getAssetType(p),
    }));
    return PlaidAccountUtils.getPlaidAccountSections(
      plaidAccountsWithAssetTypes,
      accountFilters,
      splitPublicInvestmentsAndCompoundInvestments,
    );
  }, [
    plaidAccounts,
    accountFilters,
    splitPublicInvestmentsAndCompoundInvestments,
  ]);

  const otherAssetsAcctSections = useMemo(() => {
    return getOtherAssetsSectionBreakdown(
      assets as CompoundAsset[],
      accountFilters,
      includeHiddenAccounts,
      splitFundInvestmentAndCompoundAlts,
    );
  }, [
    assets,
    accountFilters,
    includeHiddenAccounts,
    splitFundInvestmentAndCompoundAlts,
  ]);
  // Split out liabilitiesfrom assets,as we do with the plaid accounts
  const [manualDebts, manualAssets] = useMemo(() => {
    return partition(
      otherAssetsAcctSections,
      (section) => section.type === AssetTypes.OtherLiability,
    );
  }, [otherAssetsAcctSections]);

  /**
   * Determine if the user has private investments linked to a private equity account.
   *
   * Note that despite the name, a private equity account generally represent a company
   * in which a user has DIRECT equity; the `private` nomeclature indiates that the
   * company likely (though not always) hasn't gone public
   **/
  const additionalAssets = useMemo(
    () => [...convertibleNotes, ...investorEquity],
    [convertibleNotes, investorEquity],
  );

  if (additionalAssets.length) {
    allocatePrivateInvestments(manualAssets, additionalAssets, accountFilters);
  }

  const assetSections = useMemo(() => {
    return compact([
      ...plaidAssetAccountSections,
      ...manualAssets,
      excludeEquity ? null : equitySection,
    ]);
  }, [plaidAssetAccountSections, manualAssets, excludeEquity, equitySection]);

  const combinedAssetSection = useMemo(
    () => orderBy(assetSections, 'displayOrder'),
    [assetSections],
  );

  // Final liability account sections, which may encompass loans added either manually or via plaid
  const collectedLiabilities = useMemo(() => {
    const [plaidLoansList, otherPlaidDebts] = partition(
      liabilityAccountSections,
      (section) => section.type === AssetTypes.Loan,
    );

    const plaidLoans = first(plaidLoansList) as AssetAccountSection;

    return compact([...otherPlaidDebts, plaidLoans, ...manualDebts]);
  }, [liabilityAccountSections, manualDebts]);

  const sortedLiabilityAccountSections = useMemo(
    () =>
      orderBy(collectedLiabilities, 'displayOrder') as AssetAccountSection[],
    [collectedLiabilities],
  );

  const allAcctSections = useMemo(
    () => [...combinedAssetSection, ...sortedLiabilityAccountSections],
    [combinedAssetSection, sortedLiabilityAccountSections],
  );

  const returnValues = useMemo(() => {
    return {
      assets,
      assetAccountSections: combinedAssetSection as AssetAccountSection[],
      otherAssetsAcctSections: manualAssets,
      liabilityAccountSections: sortedLiabilityAccountSections,
      equityNet: excludeEquity ? 0 : equitySection.filteredTotal,
      plaidAccounts,
      net: sumBy(allAcctSections, 'total'),
      filteredNet: sumBy(allAcctSections, 'filteredTotal'),
      allAccounts: allAcctSections.flatMap((s) => s.accounts),
    };
  }, [
    assets,
    combinedAssetSection,
    manualAssets,
    sortedLiabilityAccountSections,
    excludeEquity,
    equitySection,
    plaidAccounts,
    allAcctSections,
  ]);

  return returnValues;
}

export default useGetAccountsBreakdown;
