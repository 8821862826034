export enum AccountType {
  Equity = 'equity',
  Investment = 'investment',
  Credit = 'credit',
  Depository = 'depository',
  Tax = 'tax',
  Loan = 'loan',
  Other = 'other',
  Crypto = 'cryptocurrency',
}

export enum AccountSubtype {
  CreditCard = 'credit card',
  PayPal = 'paypal',
  CashManagement = 'cash management',
  CD = 'cd',
  Checking = 'checking',
  HSA = 'hsa',
  Savings = 'savings',
  MoneyMarket = 'money market',
  Prepaid = 'prepaid',
  Auto = 'auto',
  Commercial = 'commercial',
  Construction = 'construction',
  Consumer = 'consumer',
  HomeEquity = 'home equity',
  Loan = 'loan',
  Mortgage = 'mortgage',
  Overdraft = 'overdraft',
  LineOfCredit = 'line of credit',
  Student = 'student',
  Other = 'other',
  Tax401a = '401a',
  Tax401k = '401k',
  Tax403b = '403b',
  Tax457b = '457b',
  Tax529 = '529',
  Brokerage = 'brokerage',
  CashISA = 'cash isa',
  EducationSavings = 'education savings account',
  FixedAnnuity = 'fixed annuity',
  GIC = 'gic',
  HealthArrangement = 'health reimbursement arrangement',
  IRA = 'ira',
  ISA = 'isa',
  KEOGH = 'keogh',
  LIF = 'lif',
  LIRA = 'lira',
  LRIF = 'lrif',
  LRSP = 'lrsp',
  MutualFund = 'mutual fund',
  NonTaxableBrokerage = 'non-taxable brokerage account',
  Pension = 'pension',
  PRIF = 'prif',
  ProfitSharingPlan = 'profit sharing plan',
  QSHR = 'qshr',
  RDSP = 'rdsp',
  RESP = 'resp',
  Retirement = 'retirement',
  RLIF = 'rlif',
  ROTH = 'roth',
  Roth401K = 'roth 401k',
  RRIF = 'rrif',
  RRSP = 'rrsp',
  SARSEP = 'sarsep',
  SEPIRA = 'sep ira',
  SimpleIRA = 'simple ira',
  SIPP = 'sipp',
  StockPlan = 'stock plan',
  TFSA = 'tfsa',
  ThriftSavingsPlan = 'thrift savings plan',
  Trust = 'trust',
  UGMA = 'ugma',
  UTMA = 'utma',
  VariableAnnuity = 'variable annuity',
}

export enum CryptoAccountSubtype {
  Hot = 'Hot Wallet',
  Cold = 'Cold Wallet',
  Hardware = 'Hardware Wallet',
  Paper = 'Paper Wallet',
  Desktop = 'Desktop Wallet',
  Mobile = 'Mobile Wallet',
  Web = 'Web Wallet',
}
