import pipe from 'lodash/fp/pipe';
import omit from 'lodash/omit';
import { ValueOf } from 'types';

import { NumberUtils } from '../../../../../utils/NumberUtils';
import { AllocationAsset } from '../../../Accounts/AssetAllocation/AllocationExplorer';
import { ASSET_CATEGORY } from '../../constants';
import { not } from '../../utils';
import { Fee } from '../../utils/Fee';
import { SimplifyOriginalAsset } from '../../utils/types';
import { Allocation } from '../Allocation';
import { AllocationProposalCalculations } from '../AllocationProposalCalculations';
import { AllocationProposalSettingsTypes } from '../AllocationProposalSettings';
import {
  AllocationProposalStrategy,
  AllocationProposalStrategyTypes,
} from '../AllocationProposalStrategy';
import { InvestmentTarget } from '../InvestmentTarget';
import {
  StrategicAllocationProposalStrategy,
  StrategicAllocationProposalStrategyTypes,
} from './StrategicAllocationProposalStrategy';
import {
  AllocationProposalMessageTypes,
  AllocationProposalTypes,
} from '../AllocationProposal';

export const AllocationProposalStatus = {
  Default: 'default',
  Archived: 'archived',
} as const;
export type AllocationProposalStatus = ValueOf<typeof AllocationProposalStatus>;

export const AllocationProposalType = {
  Strategic: 'strategic',
  Target: 'target',
} as const;
export type AllocationProposalType = ValueOf<typeof AllocationProposalType>;

export namespace StrategicAllocationProposalTypes {
  type BaseAllocationProposal = {
    id?: string;
    name: string;
    description: string;
    blended: BlendendAllocationProposals;
    type: AllocationProposalType;
    status: AllocationProposalStatus;
    networth: number;
    settings: AllocationProposalSettingsTypes.Settings;
    updatedAt: string;
    unomittedNetworth: number;
    transitionedNetworth: number;
    createdAt: string;
  };
  export type AllocationProposal = BaseAllocationProposal & {
    original: StrategicAllocationProposalStrategyTypes.OriginalAllocationProposalStrategy;
    investmentTargets: StrategicAllocationProposalStrategyTypes.InvestmentTargetAllocationProposalStrategy[];
  };
  export type CategorizedAllocationProposal = BaseAllocationProposal & {
    original: StrategicAllocationProposalStrategyTypes.CategorizedOriginalAllocationProposalStrategy;
    investmentTargets: StrategicAllocationProposalStrategyTypes.CategorizedInvestmentTargetAllocationProposalStrategy[];
  };
  export type CurrentAllocationProposal = BaseAllocationProposal & {
    original: StrategicAllocationProposalStrategyTypes.CategorizedOriginalAllocationProposalStrategy;
  };
  export type BlendendAllocationProposals = {
    original: {
      wholistic: AllocationProposalTypes.BlendendAllocationProposal;
      changes: AllocationProposalTypes.BlendendAllocationProposal;
    };
    target: {
      wholistic: AllocationProposalTypes.BlendendAllocationProposal;
      changes: AllocationProposalTypes.BlendendAllocationProposal;
    };
  };

  type PortfolioItem = { category: string; weight: number };
  export type PresetAllocationProposal = {
    id: string;
    name: string;
    type: string;
    risk: string;
    portfolio: PortfolioItem[];
    presetAssetAllocation: AllocationProposalStrategyTypes.PresetAssetAllocation;
    annualReturn?: number;
    annualVolatility?: number;
  };

  export type InvestmentAllocationProposal = Omit<
    SimplifyOriginalAsset<CategorizedAllocationProposal>,
    'original'
  > & { original: null };
}

const blended = (
  allocationProposal: Pick<
    StrategicAllocationProposalTypes.CategorizedAllocationProposal,
    'original' | 'investmentTargets'
  >,
): StrategicAllocationProposalTypes.BlendendAllocationProposals => {
  const { original, investmentTargets } = allocationProposal;

  const { allocations: originalAllocations } = original;
  const unomittedOriginalAllocations = AllocationProposalStrategy.toCategorized(
    AllocationProposalStrategy.fromCategorized(originalAllocations).filter(
      not(Allocation.isOmitted),
    ),
  );
  const blendedOriginalAllocations = AllocationProposalStrategy.toAssetClasses(
    unomittedOriginalAllocations,
  );

  const transitions =
    StrategicAllocationProposalStrategy.transitions(investmentTargets);

  const originalAllocationsWithoutTransitions =
    StrategicAllocationProposalStrategy.subtract(
      unomittedOriginalAllocations,
      StrategicAllocationProposalStrategy.merge(
        transitions.map((transition) =>
          AllocationProposalStrategy.toCategorized([transition]),
        ),
      ),
    );
  const blendedOriginalAllocationsWithoutTransitions =
    AllocationProposalStrategy.toAssetClasses(
      originalAllocationsWithoutTransitions,
    );
  const blendedInvestmentTargets = investmentTargets.map(
    pipe(
      StrategicAllocationProposalStrategy.constrainAllocationsWeightByInvestmentTargetWeight,
      AllocationProposalStrategy.toAssetClasses,
    ),
  );
  const blendedTargetAllocation = StrategicAllocationProposalStrategy.merge([
    blendedOriginalAllocationsWithoutTransitions,
    ...blendedInvestmentTargets,
  ]);

  const originalTransitionChanges = AllocationProposalStrategy.toAssetClasses(
    AllocationProposalStrategy.rebalanceTo100(
      AllocationProposalStrategy.toCategorized(transitions),
    ),
  );
  const targetTransitionChanges = AllocationProposalStrategy.rebalanceTo100(
    StrategicAllocationProposalStrategy.merge(blendedInvestmentTargets),
  );

  const unomittedOriginalNetworth =
    StrategicAllocationProposalStrategy.unomittedNetworth(original);
  const originalCompoundManagedNetworth =
    StrategicAllocationProposalStrategy.compoundManagedNetworth(original);
  const transitionedNetworth =
    StrategicAllocationProposalStrategy.transitionedNetworth(investmentTargets);
  const potentialCompoundManagedNetworth = NumberUtils.add(
    originalCompoundManagedNetworth,
    transitionedNetworth,
  );
  const compoundFee = Fee.compoundInvestmentFee(
    potentialCompoundManagedNetworth,
  );

  const originalWholisticAssets = AllocationProposalStrategy.rebalanceTo100(
    unomittedOriginalAllocations,
  );
  const originalChangesAssets = AllocationProposalStrategy.rebalanceTo100(
    AllocationProposalStrategy.toCategorized(transitions),
  );
  const categorizedClientAllocations = AllocationProposalStrategy.toCategorized(
    investmentTargets.map(({ investmentTarget }) =>
      InvestmentTarget.asClientAllocation(investmentTarget),
    ),
  );
  const targetWholisticAssets = AllocationProposalStrategy.rebalanceTo100({
    ...originalAllocationsWithoutTransitions,
    ...categorizedClientAllocations,
  });
  const targetChangesAssets = AllocationProposalStrategy.rebalanceTo100(
    categorizedClientAllocations,
  );

  return {
    original: {
      wholistic: {
        allocations: AllocationProposalStrategy.recomputeValues({
          allocations: AllocationProposalStrategy.rebalanceTo100(
            blendedOriginalAllocations,
          ),
          networth: unomittedOriginalNetworth,
        }),
        annualReturn: NumberUtils.subtract(
          AllocationProposalCalculations.return.mean(
            blendedOriginalAllocations,
          ),
          compoundFee,
        ),
        annualVolatility:
          AllocationProposalCalculations.volatility.standardDeviation(
            blendedOriginalAllocations,
          ),
        singleStockExposure:
          AllocationProposalCalculations.insights.singleStockExposure(
            originalAllocations,
          ),
        assets: originalWholisticAssets,
        representationalAssets: AllocationProposalStrategy.toCategorized(
          StrategicAllocationProposalStrategy.representationalClientAllocations(
            {
              clientAllocations: AllocationProposalStrategy.fromCategorized(
                originalWholisticAssets,
              ),
              numberOfAssets: 6,
            },
          ),
        ),
      },
      changes: {
        allocations: AllocationProposalStrategy.recomputeValues({
          allocations: AllocationProposalStrategy.rebalanceTo100(
            originalTransitionChanges,
          ),
          networth: transitionedNetworth,
        }),
        annualReturn: NumberUtils.subtract(
          AllocationProposalCalculations.return.mean(originalTransitionChanges),
          compoundFee,
        ),
        annualVolatility:
          AllocationProposalCalculations.volatility.standardDeviation(
            originalTransitionChanges,
          ),
        singleStockExposure:
          AllocationProposalCalculations.insights.singleStockExposure(
            AllocationProposalStrategy.toCategorized(transitions),
          ),
        assets: originalChangesAssets,
        representationalAssets: AllocationProposalStrategy.toCategorized(
          StrategicAllocationProposalStrategy.representationalClientAllocations(
            {
              clientAllocations: AllocationProposalStrategy.fromCategorized(
                originalChangesAssets,
              ),
              numberOfAssets: 6,
            },
          ),
        ),
      },
    },
    target: {
      wholistic: {
        allocations: AllocationProposalStrategy.recomputeValues({
          allocations: AllocationProposalStrategy.rebalanceTo100(
            blendedTargetAllocation,
          ),
          networth: unomittedOriginalNetworth,
        }),
        annualReturn: NumberUtils.subtract(
          AllocationProposalCalculations.return.mean(blendedTargetAllocation),
          compoundFee,
        ),
        annualVolatility:
          AllocationProposalCalculations.volatility.standardDeviation(
            blendedTargetAllocation,
          ),
        singleStockExposure:
          AllocationProposalCalculations.insights.singleStockExposure(
            originalAllocationsWithoutTransitions,
          ),
        assets: targetWholisticAssets,
        representationalAssets: AllocationProposalStrategy.toCategorized(
          StrategicAllocationProposalStrategy.representationalClientAllocations(
            {
              clientAllocations: AllocationProposalStrategy.fromCategorized(
                targetWholisticAssets,
              ),
              numberOfAssets: 6,
            },
          ),
        ),
      },
      changes: {
        allocations: AllocationProposalStrategy.recomputeValues({
          allocations: AllocationProposalStrategy.rebalanceTo100(
            targetTransitionChanges,
          ),
          networth: transitionedNetworth,
        }),
        annualReturn: NumberUtils.subtract(
          AllocationProposalCalculations.return.mean(targetTransitionChanges),
          compoundFee,
        ),
        annualVolatility:
          AllocationProposalCalculations.volatility.standardDeviation(
            targetTransitionChanges,
          ),
        singleStockExposure: 0,
        assets: targetChangesAssets,
        representationalAssets: AllocationProposalStrategy.toCategorized(
          StrategicAllocationProposalStrategy.representationalClientAllocations(
            {
              clientAllocations:
                AllocationProposalStrategy.fromCategorized(targetChangesAssets),
              numberOfAssets: 6,
            },
          ),
        ),
      },
    },
  };
};

const create = ({
  original,
  type,
  ...allocationProposal
}: {
  original: StrategicAllocationProposalStrategyTypes.OriginalAllocationProposalStrategy;
  type: AllocationProposalType;
} & Partial<StrategicAllocationProposalTypes.AllocationProposal>): StrategicAllocationProposalTypes.CategorizedAllocationProposal => {
  const settings = {
    id: 'CREATED_STRATEGIC_SETTINGS',
    investorQualification: null,
    investmentPolicy: null,
    preference: null,
  };
  const investmentTargets: StrategicAllocationProposalStrategyTypes.InvestmentTargetAllocationProposalStrategy[] =
    [];
  const {
    original: categorizedOriginal,
    investmentTargets: categorizedInvestmentTargets,
    ...categorizedProposal
  } = toCategorized({
    name: 'New asset allocation',
    description: 'Describe the proposal',
    original: original,
    investmentTargets: [],
    settings,
    type,
    status: 'default',
    blended: blended({
      original: {
        ...original,
        allocations: AllocationProposalStrategy.toCategorized(
          original.allocations,
        ),
      },
      investmentTargets: investmentTargets.map((investmentTarget) => ({
        ...investmentTarget,
        allocations: AllocationProposalStrategy.toCategorized(
          investmentTarget.allocations,
        ),
      })),
    }),
    networth: NaN,
    unomittedNetworth: NaN,
    transitionedNetworth: NaN,
    createdAt: new Date().toString(),
    updatedAt: new Date().toString(),
    ...allocationProposal,
  });
  return {
    ...categorizedProposal,
    original: categorizedOriginal,
    investmentTargets: categorizedInvestmentTargets,
    networth: StrategicAllocationProposalStrategy.networth(categorizedOriginal),
    unomittedNetworth:
      StrategicAllocationProposalStrategy.unomittedNetworth(
        categorizedOriginal,
      ),
    transitionedNetworth:
      StrategicAllocationProposalStrategy.transitionedNetworth(
        categorizedInvestmentTargets,
      ),
  };
};

const isBlank = (
  allocationProposal: StrategicAllocationProposalTypes.CategorizedAllocationProposal,
) => {
  const { investmentTargets } = allocationProposal;
  const hasTransitions = investmentTargets.some(
    ({ investmentTarget }) => investmentTarget.transitions.length > 0,
  );
  return !hasTransitions;
};

const withBlended = (
  allocationProposal:
    | StrategicAllocationProposalTypes.CategorizedAllocationProposal
    | StrategicAllocationProposalTypes.InvestmentAllocationProposal,
): StrategicAllocationProposalTypes.CategorizedAllocationProposal => ({
  ...allocationProposal,
  // @ts-ignore
  investmentTargets: Object.hasOwn(allocationProposal, 'investmentTargets')
    ? allocationProposal.investmentTargets
    : [],
  // @ts-ignore
  blended: Object.hasOwn(allocationProposal, 'blended')
    ? // @ts-ignore
      allocationProposal.blended ?? blended(allocationProposal)
    : // @ts-ignore
      blended(allocationProposal),
});

const fromCategorized = (
  allocationProposal: StrategicAllocationProposalTypes.CategorizedAllocationProposal,
): StrategicAllocationProposalTypes.AllocationProposal => {
  const { original, investmentTargets } = allocationProposal;
  const { allocations: originalAllocations } = original;
  return {
    ...allocationProposal,
    original: {
      ...original,
      allocations:
        AllocationProposalStrategy.fromCategorized(originalAllocations),
    },
    investmentTargets: investmentTargets.map((investmentTarget) => {
      const { allocations: investmentTargetAllocations } = investmentTarget;
      return {
        ...investmentTarget,
        allocations: AllocationProposalStrategy.fromCategorized(
          investmentTargetAllocations,
        ),
      };
    }),
  };
};

const toCategorized = (
  allocationProposal: StrategicAllocationProposalTypes.AllocationProposal,
): StrategicAllocationProposalTypes.CategorizedAllocationProposal => {
  const { original, investmentTargets } = allocationProposal;
  const { allocations: originalAllocations } = original;
  return {
    ...allocationProposal,
    original: {
      ...original,
      allocations:
        AllocationProposalStrategy.toCategorized(originalAllocations),
    },
    investmentTargets: investmentTargets.map((investmentTarget) => {
      const { allocations: investmentTargetAllocations } = investmentTarget;
      return {
        ...investmentTarget,
        allocations: AllocationProposalStrategy.toCategorized(
          investmentTargetAllocations,
        ),
      };
    }),
  };
};

const toInvestmentProposal = (
  assetAllocationStrategy: StrategicAllocationProposalTypes.CategorizedAllocationProposal,
): StrategicAllocationProposalTypes.InvestmentAllocationProposal => {
  const {
    id,
    name,
    description,
    investmentTargets,
    blended: {
      original: { wholistic: originalWholistic, changes: originalChanges },
      target: { wholistic: targetWholistic, changes: targetChanges },
    },
    settings,
    networth,
    unomittedNetworth,
    transitionedNetworth,
    type,
    status,
    updatedAt,
    createdAt,
  } = assetAllocationStrategy;

  return {
    id,
    name,
    description,
    blended: {
      original: {
        wholistic: {
          ...originalWholistic,
          assets: {},
        },
        changes: {
          ...originalChanges,
          assets: {},
        },
      },
      target: {
        wholistic: {
          ...targetWholistic,
          assets: {},
        },
        changes: {
          ...targetChanges,
          assets: {},
        },
      },
    },
    original: null,
    investmentTargets:
      StrategicAllocationProposalStrategy.toInvestmentProposal(
        investmentTargets,
      ),
    settings,
    networth,
    unomittedNetworth,
    transitionedNetworth,
    type,
    status,
    updatedAt,
    createdAt,
  };
};

const toMessage = (
  assetAllocationStrategy: StrategicAllocationProposalTypes.CategorizedAllocationProposal,
): AllocationProposalMessageTypes.AllocationProposalMessage => {
  const {
    investmentTargets,
    original,
    settings: allocationProposalSetting,
    ...assetAllocationStrategyMessage
  } = assetAllocationStrategy;
  return {
    ...omit(
      assetAllocationStrategyMessage,
      'blended',
      'networth',
      'unomittedNetworth',
      'transitionedNetworth',
    ),
    strategies: [...investmentTargets, original].map(
      StrategicAllocationProposalStrategy.toMessage,
    ),
    allocationProposalSetting,
  };
};

const fromMessage =
  ({
    assetCategories,
    assets,
  }: {
    assetCategories: ASSET_CATEGORY[];
    assets: AllocationAsset[];
  }) =>
  (
    assetAllocationStrategyMessage: AllocationProposalMessageTypes.AllocationProposalMessage,
  ): StrategicAllocationProposalTypes.CategorizedAllocationProposal => {
    const {
      strategies: strategiesFromMessage,
      allocationProposalSetting: settings,
      ...rest
    } = assetAllocationStrategyMessage;
    const originalFromMessage = strategiesFromMessage.find(
      (strategy) => strategy.type === 'original',
    );
    const { networth = 0 } = originalFromMessage ?? {};
    const strategies = strategiesFromMessage.map(
      StrategicAllocationProposalStrategy.fromMessage({
        assetCategories,
        assets,
        networth,
      }),
    );
    const original = StrategicAllocationProposalStrategy.onlyOriginal(
      strategies,
    ) as StrategicAllocationProposalStrategyTypes.CategorizedOriginalAllocationProposalStrategy;
    const investmentTargets =
      StrategicAllocationProposalStrategy.onlyInvestmentTargets(
        strategies,
      ) as StrategicAllocationProposalStrategyTypes.CategorizedInvestmentTargetAllocationProposalStrategy[];
    return {
      ...rest,
      settings,
      original,
      investmentTargets,
      blended: blended({ original, investmentTargets }),
      networth,
      unomittedNetworth:
        StrategicAllocationProposalStrategy.unomittedNetworth(original),
      transitionedNetworth:
        StrategicAllocationProposalStrategy.transitionedNetworth(
          investmentTargets,
        ),
    };
  };

export const StrategicAllocationProposal = {
  blended,
  create,
  fromCategorized,
  isBlank,
  fromMessage,
  toCategorized,
  toMessage,
  toInvestmentProposal,
  withBlended,
};
