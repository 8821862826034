import ActionTypes from '../actionTypes/onboard';
import { ContainerState, ContainerActions } from '../types/reducers/onboard';
import { replaceReducer } from 'reducers/hmr';
import { Reducers } from 'types/reducers';

const initialState: ContainerState = {
  loading: false,
  onboardingStep: 0,
  lastOnboardingSection: 'intro',
  currentOnboardingSection: 'intro',
  lastOnboardingStep: 'intro',
  currentOnboardingStep: 'intro',
};

function onboardingReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.START_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.COMPLETE_REQUEST:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

// Replace the reducer with the hot loaded one
if (import.meta.hot) {
  import.meta.hot.accept(replaceReducer(Reducers.ONBOARD));
}

export default onboardingReducer;
