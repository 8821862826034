import * as _ from 'lodash';
import { CashflowProfileUpdateType } from 'types/cashflow';
import DateUtils from '@compoundfinance/compound-core/dist/date-utils';

// Determines number of years to calculate cashflows
export const CASHFLOW_NUM_YEARS = 10;

export const getNumMonthsInCashflowRange = (
  cashflowNumYears: number = CASHFLOW_NUM_YEARS,
  startDate: Date = new Date(),
) => DateUtils.moTillYearEnd(startDate) + 1 + 12 * (cashflowNumYears - 1);

export const getCashflowMonthRange = (
  cashflowNumYears: number = CASHFLOW_NUM_YEARS,
  startDate: Date = new Date(),
) => _.range(0, getNumMonthsInCashflowRange(cashflowNumYears, startDate));

export const getCashflowYearRange = (
  cashflowNumYears: number = CASHFLOW_NUM_YEARS,
) => _.range(0, cashflowNumYears);

export const getCashFlowTimeRangeForFidelity = (
  cashflowNumYears: number = CASHFLOW_NUM_YEARS,
  selectedTimeFidelityInMonths: number = 12,
  startDate: Date = new Date(),
) =>
  selectedTimeFidelityInMonths < 12
    ? getCashflowMonthRange(cashflowNumYears, startDate)
    : getCashflowYearRange(cashflowNumYears);

export const getCashFlowTimeRangeLengthForFidelity = (
  cashflowNumYears: number = CASHFLOW_NUM_YEARS,
  selectedTimeFidelityInMonths: number = 12,
  startDate: Date = new Date(),
) =>
  selectedTimeFidelityInMonths < 12
    ? getNumMonthsInCashflowRange(cashflowNumYears, startDate)
    : cashflowNumYears;

export const CASHFLOW_YEAR_RANGE = _.range(0, CASHFLOW_NUM_YEARS);

export enum CashflowModalType {
  Cashflow = 'cashflow',
  EquityHome = 'equityHome',
  EquityModalOnly = 'equityModalOnly',
}

export enum CashflowSubModalType {
  DefaultOutflowAccounts = 'defaultOutflowAccounts',
  ActivityForm = 'activityForm',
}

// Growth rate account IDs that are not associated with assets
// i.e. these are the accountIds you associate with salary and monthly expense growth rate objects
export const GROWTH_RATE_NON_ASSET_IDS = [
  CashflowProfileUpdateType.AnnualIncome,
  CashflowProfileUpdateType.MonthlyExpenses,
] as string[];
