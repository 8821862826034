import styled from 'styled-components';
import { FlexRow, ContentWrapSizeStyle } from 'components/global';
import { ScProps } from 'components/types';
import Wordmark from 'assets/svg/Wordmark';
import { CURRENT_TAX_YEAR } from 'utils/constants/tax';

const FooterWrap = styled.footer`
  ${ContentWrapSizeStyle};
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${(p: ScProps) => p.theme.colors.grey};
  padding: 4rem 0;
  background-color: ${(p: ScProps) => p.bg || 'transparent'};
  margin-bottom: 0;
` as any;

const SmFooterWrap = styled(FooterWrap)`
  justify-content: center;
  max-width: 100%;
  min-width: 100%;
  font-size: 12px;
  padding: 2rem 0;
  svg {
    width: 107px;
  }
  a,
  button {
    font-size: 12px !important;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;
  }
` as any;

const FooterWordmark = styled(FlexRow)`
  align-items: center;
  margin-right: 2rem;
  svg {
    margin-right: 0.5rem;
  }
  path {
    fill: ${(p: ScProps) => p.theme.colors.grey};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin-right: 0;
    margin-bottom: 1rem;
  }
`;

const FooterLink = styled.a.attrs({ target: '_blank' })`
  font-size: 14px;
  text-decoration: none;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  color: ${(p: ScProps) => p.theme.colors.grey};
  transition: 0.3s;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    text-decoration: none;
    color: ${(p: ScProps) => p.theme.colors.muted_blue};
    opacity: 0.8;
  }
`;

interface FooterProps {
  bg?: string;
  sm?: boolean;
  style?: any;
}

const FooterContent = () => {
  return (
    <>
      <FooterWordmark justifyContent="center">
        <Wordmark /> <span>© {CURRENT_TAX_YEAR}</span>
      </FooterWordmark>
      <FlexRow>
        <FooterLink href="https://legal.compoundplanning.com/terms-of-service">
          Terms of use
        </FooterLink>
        ·
        <FooterLink href="https://legal.compoundplanning.com/privacy-policy">
          Privacy policy
        </FooterLink>
      </FlexRow>
    </>
  );
};

function Footer(props: FooterProps) {
  return props.sm ? (
    <SmFooterWrap style={props.style}>
      <FooterContent />
    </SmFooterWrap>
  ) : (
    <FooterWrap bg={props.bg} style={props.style}>
      <FooterContent />
    </FooterWrap>
  );
}

export default Footer;
