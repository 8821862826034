/* eslint-disable no-restricted-syntax */
import useSWROriginal, { BareFetcher, mutate as mutateOriginal } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { Key, PublicConfiguration } from 'swr/_internal';
import { select } from 'redux-saga/effects';
import { useMemo } from 'react';

import { buildGetRequestForSWR } from 'utils/api';
import useTypedSelector from './typedSelector';
import { ApplicationRootState } from 'types';

type Options<DataType = any, ErrorType = any> = {
  fetcher?: BareFetcher<DataType>;
  options?: Partial<
    PublicConfiguration<DataType, ErrorType, BareFetcher<DataType>>
  > & { notForwardUserId?: boolean };
};

const DEFAULT_OPTIONS = {
  fetcher: buildGetRequestForSWR,
  options: {
    notForwardUserId: false,
  },
};

const buildKey = (
  baseKey: Key,
  customKey?: any[],
): any[] | null | undefined => {
  const key = typeof baseKey === 'function' ? baseKey() : baseKey;
  if (key == null) return key;
  return [...(Array.isArray(key) ? key! : [key!]), ...(customKey ?? [])];
};

/**
 * Convenience utility over `useSWR` which forwards the `userId` (`advisorRequestId`) as a query parameter.
 * In those few cases where the `userId` query parameter is inconvenient,
 * you can avoid passing it by using the `notForwardUserId` option.
 *
 * @returns { data, error, isResolved, isLoading, isError, isRevalidating, isValidating, mutate }
 * `data`: the fetched data.
 * `error`: the error produced by an erroneous request.
 * `isLoading`: whether data is being fetched for the first time.
 * `isResolved`: whether fetch was successful and `data` is available.
 * `isError`: whether fetch was erroneous and `error` is available.
 * `isRevalidating`: whether data is being refetched for Nth time; notice data/error is still available
 * while revalidating.
 * `isValidating`: whether there's an inflight request.
 * `mutate`: invalidates the current cache entry.
 */
const buildUseFetch = (useSWR: typeof useSWROriginal = useSWROriginal) => {
  return function useFetch<DataType = any, ErrorType = any>(
    key: Key,
    {
      fetcher = buildGetRequestForSWR,
      options: {
        notForwardUserId = false,
        onErrorRetry = async (
          error,
          key,
          config,
          revalidate,
          { retryCount },
        ) => {
          // Only retry limited amount of times
          if (retryCount > 3) return;
          if (
            // @ts-ignore
            error?.response?.status === 504 ||
            // @ts-ignore
            error?.response?.status === 403
          ) {
            await revalidate({ retryCount });
          }
        },
        ...options
      } = {
        notForwardUserId: false,
      },
    }: Options<DataType, ErrorType> = DEFAULT_OPTIONS,
  ) {
    const userId = useTypedSelector((state) => state.global.user?.id);
    const { data, error, isValidating, mutate } = useSWR<DataType, ErrorType>(
      /**
       * We append the current `userId` to every key, so the cache key changes
       * when an `advisor` or `partner` who can view multiple dashboards navigates among them.
       * `state.global.user.id` can be:
       * - If customer: its id.
       * - If advisor or partner:
       *   - If not in a customer's dashboard: its id.
       *   - If in a customer's dashboard: the customer's id.
       */
      notForwardUserId ? key : buildKey(key, [userId]),
      /**
       * However, when calling the fetcher,
       * we don't forward the `userId` so it doens't unintendedly leak
       * into functions which function signature allows a variable number of arguments
       * Example:
       * ```js
       * const fetcher = (url, argument, optionalArgument?) => { ... }
       * useCompoundFetch(
       *   ['api/url', argumentValue],
       *   { fetcher }
       * )
       * ```
       * If we didn't do this, the `userId` would leak into the `optionalArgument`.
       */
      () => fetcher(...buildKey(key)!),
      options,
    );
    return {
      data,
      error,
      isLoading: isValidating && !data && !error,
      isResolved: !!data,
      isError: !!error,
      isRevalidating: isValidating && !!data,
      isValidating,
      mutate,
    };
  };
};

export const useCompoundFetch = buildUseFetch(useSWROriginal);
export const useCompoundFetchImmutable = buildUseFetch(useSWRImmutable);

export const useMutateCompoundFetch = () => {
  const userId = useTypedSelector((state) => state.global.user?.id);
  return useMemo(
    () =>
      ({
        mutate(key: Key, ...args) {
          return mutateOriginal(buildKey(key, [userId]), ...args);
        },
      } as {
        mutate: typeof mutateOriginal;
      }),
    [userId],
  );
};

export function* mutateCompoundFetch(key: Key, ...args) {
  const userId = yield select(
    (state: ApplicationRootState) => state.global.user?.id,
  );
  return mutateOriginal(buildKey(key, [userId]), ...args);
}
