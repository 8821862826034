import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

import { BankAccountToast as BankAccountToastType } from './types';
import { Box, Row, Text } from '@compoundfinance/design-system';
import AccountLogo from 'components/AccountLogo';
import AssetUtils from 'utils/assets';
import { sanitizeNum } from 'utils/ui';
import useEditAsset from 'hooks/assets/useEditAsset';
import { Cross } from '@compoundfinance/design-system/dist/icons/legacy/Cross';
import useGetAccountsBreakdown from 'hooks/useGetAccountsBreakdown';
import Pencil from 'assets/svg/icons/Pencil';

const variants = {
  enter: {
    y: 150,
    opacity: 0,
  },
  center: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: 150,
    opacity: 0,
  },
};

interface BankAccountToastProps {
  bankAccountToast: BankAccountToastType;
  removeToast: (toast: BankAccountToastType) => void;
}

function BankAccountToast(props: BankAccountToastProps) {
  const editAsset = useEditAsset();
  const { allAccounts } = useGetAccountsBreakdown();
  const { bankAccountToast, removeToast } = props;
  const [readyToBeRemoved, setReadyToBeRemoved] = useState(true);

  // New accounts returned from plaid.ts were missing accountType, we do this to ensure each account has an account type.
  const newUpdatedAccounts = allAccounts.filter((account) =>
    bankAccountToast.accounts.find(
      (accountItem) => accountItem.id === account.id,
    ),
  );

  useEffect(() => {
    // When your mouse is not on the toast, the toast will be removed after 4s
    if (readyToBeRemoved) {
      const timer = setTimeout(() => {
        removeToast(bankAccountToast);
      }, 4000);
      return () => clearTimeout(timer);
    }

    return;
  }, [bankAccountToast, removeToast, readyToBeRemoved]);

  if (newUpdatedAccounts.length === 0) {
    removeToast(bankAccountToast);
  }

  return (
    <Box
      as={motion.div}
      onMouseOut={() => setReadyToBeRemoved(true)}
      onMouseOver={() => setReadyToBeRemoved(false)}
      initial="enter"
      animate="center"
      exit="exit"
      variants={variants}
      transition={{ ease: 'easeInOut' }}
      css={{
        position: 'fixed',
        bottom: '$64',
        maxWidth: '600px',
        left: 0,
        right: 0,
        br: '$10',
        mx: 'auto',
        bg: '$gray0',
        boxShadow: '0px 8px 20px 0px rgba(0, 0, 0, 0.2);',
      }}
    >
      <Box
        css={{
          position: 'relative',
          br: '$10',
          overflow: 'hidden',

          '&:before': {
            content: '',
            pointerEvents: 'none',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: 24,
            // https://larsenwork.com/easing-gradients/
            background:
              'linear-gradient( to top,  hsla(0, 0%, 100%, 0) 0%, hsla(0, 0%, 100%, 0.013) 8.1%,hsla(0, 0%, 100%, 0.049) 15.5%, hsla(0, 0%, 100%, 0.104) 22.5%, hsla(0, 0%, 100%, 0.175) 29%, hsla(0, 0%, 100%, 0.259) 35.3%, hsla(0, 0%, 100%, 0.352) 41.2%, hsla(0, 0%, 100%, 0.45) 47.1%, hsla(0, 0%, 100%, 0.55) 52.9%, hsla(0, 0%, 100%, 0.648) 58.8%, hsla(0, 0%, 100%, 0.741) 64.7%, hsla(0, 0%, 100%, 0.825) 71%, hsla(0, 0%, 100%, 0.896) 77.5%, hsla(0, 0%, 100%, 0.951) 84.5%, hsla(0, 0%, 100%, 0.987) 91.9%, hsl(0, 0%, 100%) 100%)',
          },

          '&:after': {
            content: '',
            pointerEvents: 'none',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: 24,
            background:
              'linear-gradient( to bottom,  hsla(0, 0%, 100%, 0) 0%, hsla(0, 0%, 100%, 0.013) 8.1%,hsla(0, 0%, 100%, 0.049) 15.5%, hsla(0, 0%, 100%, 0.104) 22.5%, hsla(0, 0%, 100%, 0.175) 29%, hsla(0, 0%, 100%, 0.259) 35.3%, hsla(0, 0%, 100%, 0.352) 41.2%, hsla(0, 0%, 100%, 0.45) 47.1%, hsla(0, 0%, 100%, 0.55) 52.9%, hsla(0, 0%, 100%, 0.648) 58.8%, hsla(0, 0%, 100%, 0.741) 64.7%, hsla(0, 0%, 100%, 0.825) 71%, hsla(0, 0%, 100%, 0.896) 77.5%, hsla(0, 0%, 100%, 0.951) 84.5%, hsla(0, 0%, 100%, 0.987) 91.9%, hsl(0, 0%, 100%) 100%)',
          },
        }}
      >
        <Box
          css={{
            maxHeight: '14.0625rem',
            overflow: 'auto',
            p: '$10',
          }}
        >
          <Row
            css={{
              jc: 'space-between',
              ai: 'center',
              ml: '$10',
              mt: '$6',
              mb: '$16',
              pr: '$6',
            }}
          >
            <Text
              size="12"
              weight="medium"
              css={{
                display: 'flex',
                ai: 'center',
                gap: '$5',
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                <path
                  d="M 3 8.5 L 6 11.5 L 13 4.5"
                  fill="transparent"
                  strokeWidth="1.5"
                  stroke="currentColor"
                ></path>
              </svg>
              Pulled from {bankAccountToast.providerName}
            </Text>
            <Box
              onClick={() => removeToast(bankAccountToast)}
              css={{
                bg: 'none',
                border: 'none',
                cursor: 'pointer',
                br: '$6',
                overflow: 'hidden',
                transition: 'background-color $default',
                w: 22,
                h: 22,

                '&:hover': {
                  bg: '$gray3',
                },
              }}
            >
              <Cross
                fill="currentColor"
                style={{ width: 22, height: 22, transform: 'translateY(-2px)' }}
              />
            </Box>
          </Row>
          {newUpdatedAccounts.map((asset) => {
            const defaultImageProps = AssetUtils.getDefaultAssetImageProps(
              asset as any,
            );

            // Right after adding asset through plaid the assetType is undefined so we need to manual set it to PlaidAccount
            // asset.assetType = AssetTypes.PlaidAccount;

            const imageProps = {
              ...defaultImageProps,
              logo: defaultImageProps.logo,
            };

            return (
              <Row
                key={asset.id}
                css={{
                  p: '$10',
                  borderRadius: '$6',
                  jc: 'space-between',
                  ai: 'center',
                  cursor: 'pointer',

                  '&:hover': {
                    [`& ${Box}`]: {
                      opacity: 1,
                    },
                  },
                }}
                onClick={() => editAsset(asset)}
              >
                <Row css={{ ai: 'center' }}>
                  <Box css={{ position: 'relative' }}>
                    <AccountLogo {...imageProps} fontSize={14} size={30} />
                    <Row
                      css={{
                        bg: '$gray4',
                        color: '$gray10',
                        position: 'absolute',
                        inset: 0,
                        opacity: 0,
                        br: '$round',
                        ai: 'center',
                        jc: 'center',
                      }}
                    >
                      <Pencil color="currentColor" size={16} />
                    </Row>
                  </Box>
                  <Text size="14" weight="medium" css={{ ml: '$15' }}>
                    {AssetUtils.getAssetName(asset)}
                  </Text>
                </Row>
                <Text size="14">{sanitizeNum(asset.currentBalance)}</Text>
              </Row>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default BankAccountToast;
