import { styled } from '@compoundfinance/design-system/dist/stitches.config';

import { Text, Col, Flex } from '@compoundfinance/design-system';

import { getPerson } from 'containers/Dashboard/Document/utils';
import { DirectoryPerson } from 'domain/Employees/constants';

import EditablePerson from 'containers/Dashboard/Document/components/EditablePerson';
import EditableTextArea from 'containers/Dashboard/Document/components/EditableTextArea';
import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import { EditableSectionTitle } from 'containers/Dashboard/Document/components/SectionTitle';

const Container = styled(ModuleContainer, {
  h: '260px',
  minHeight: '260px',
  p: '$24',
  gap: '$24',

  '@bp1': {
    py: '$44',
    px: '$48',
    gap: '$48',

    h: '340px',
    minHeight: '340px',
  },
});

export interface NoTextIntroductionProps {
  title: string;
  recipient: string;
  financialAdvisor: DirectoryPerson;
}

function NoTextIntroduction(props: NoTextIntroductionProps) {
  const { title, recipient, financialAdvisor } = props;

  const financialAdvisorPerson = getPerson(financialAdvisor);

  return (
    <Container>
      <Col css={{ h: '100%', ai: 'stretch', gap: '$24' }}>
        <Col css={{ gap: '$12' }}>
          <EditableSectionTitle title={title} />

          <Text size="20" color="gray10">
            <EditableTextArea propertyKey="recipient" value={recipient}>
              for {recipient}
            </EditableTextArea>
          </Text>
        </Col>

        <Flex css={{ flex: '1 0 auto', w: '100%', ai: 'end' }}>
          <EditablePerson
            propertyKey="financialAdvisor"
            propertyValue={financialAdvisor}
            person={financialAdvisorPerson}
            size="large"
            level="document"
          />
        </Flex>
      </Col>
    </Container>
  );
}

export default NoTextIntroduction;
