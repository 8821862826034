import { ErrorBoundary } from '@sentry/react';
import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import {
  Box,
  Col,
  CompoundCutOutIcon,
  CrossIcon,
  IconButton,
  Row,
  Text,
  UploadIcon,
} from '@compoundfinance/design-system';
import { isProduction } from 'utils/env';
import ModuleSelector from '../DocumentViewer/ModuleSelector';
import ModuleContextProvider from '../DocumentViewer/ModuleContextProvider';
import DocumentProvider from '../DocumentViewer/DocumentProvider';
import DocumentProgressIndicator from './DocumentProgressIndicator';
import { useEffect, useRef, useState } from 'react';
import DocumentMobileNavigation from './DocumentMobileNavigation';
import { AnimatePresence, motion } from 'framer-motion';

interface DocumentMobileViewerProps {
  document: any;
  mutateDocument: any;
}

function DocumentMobileViewer(props: DocumentMobileViewerProps) {
  const { document, mutateDocument } = props;
  const [currentStep, setCurrentStep] = useState(0);

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    contentRef.current?.scrollTo({ top: 0 });
  }, [currentStep, contentRef]);

  if (!document) return null;

  const currentModule = document.modules?.[document.moduleOrder?.[currentStep]];

  return (
    <DocumentProvider initialDocument={document} isEditor={false}>
      <Col
        css={{
          h: '100vh',
          bg: '$gray0',
          jc: 'space-between',
        }}
      >
        {/* Navigation */}
        <Col
          as={'nav'}
          css={{
            bg: '$gray2',
            w: '100%',
            borderBottom: '1px solid $gray4',
          }}
        >
          <Row
            css={{
              jc: 'space-between',
              ai: 'center',
              px: '$16',
              h: '$48',
            }}
          >
            <Row
              css={{
                gap: '$8',
                ai: 'center',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              <CompoundCutOutIcon css={{ flexShrink: 0 }} />
              <Text
                color="gray12"
                css={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {document.title}
              </Text>
            </Row>
            <Row css={{ gap: '$24' }}>
              <IconButton
                ariaLabel="Share"
                variant="naked"
                css={{ cursor: 'pointer' }}
                disabled
              >
                <UploadIcon
                  onClick={() => {
                    navigator.share?.({
                      url: window.location.href,
                      title: `${document.title} for ${document.user?.firstName} ${document.user?.lastName}`,
                    });
                  }}
                />
              </IconButton>
              <IconButton
                ariaLabel="Close"
                variant="naked"
                css={{ cursor: 'pointer' }}
              >
                <CrossIcon
                  onClick={() => {
                    window.close?.();
                  }}
                />
              </IconButton>
            </Row>
          </Row>
          <DocumentProgressIndicator
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            totalSteps={document.moduleOrder?.length || 1}
          />
        </Col>

        {/* Content */}
        <Box
          css={{
            overflowY: 'auto',
            flexGrow: 1,
            overscrollBehavior: 'contain',
          }}
          ref={contentRef}
        >
          {/* Show current module */}
          {currentModule && (
            <ErrorBoundary
              key={currentModule.id}
              fallback={(error) =>
                isProduction ? (
                  <></>
                ) : (
                  <>
                    <ModuleContainer css={{ p: '$48' }}>
                      <Text weight="medium">
                        Unable to render component properly:
                      </Text>
                      <pre>{error.error.message}</pre>
                    </ModuleContainer>
                  </>
                )
              }
            >
              <AnimatePresence exitBeforeEnter>
                <motion.div
                  variants={{
                    initial: { opacity: 0 },
                    animate: { opacity: 1 },
                    exit: { opacity: 0 },
                  }}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  transition={{ duration: 0.1 }}
                >
                  <ModuleContextProvider
                    module={currentModule}
                    mutateDocument={mutateDocument}
                    documentProperties={document.properties}
                  >
                    <ModuleSelector
                      mutateDocument={mutateDocument}
                      isEditor={false}
                    />
                  </ModuleContextProvider>
                </motion.div>
              </AnimatePresence>
              <Box css={{ h: '$12' }}></Box>
            </ErrorBoundary>
          )}

          {/* TODO where do we show the disclaimer? */}
        </Box>

        {/* Mobile navigation */}
        <Box
          css={{
            bg: '$gray2',
            w: '100%',
            borderTop: '1px solid $gray4',
          }}
        >
          {document && (
            <DocumentMobileNavigation
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              totalSteps={document.moduleOrder?.length || 1}
            />
          )}
        </Box>
      </Col>
    </DocumentProvider>
  );
}

export default DocumentMobileViewer;
