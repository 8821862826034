import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import { Box, Col } from '@compoundfinance/design-system';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';
import SocialProof from './SocialProof';
import { AboutVariation } from './AboutVariationEdit';
import { ILinkPreview } from 'domain/Employees/types';
import AboutText from './AboutText';

const Container = styled(ModuleContainer, {
  p: '$24',
  minHeight: '0',

  '@bp1': {
    py: '$44',
    px: '$48',
  },
});

const SocialProofContainer = styled(Box, {
  width: '120%',
  left: '-10%',
  position: 'relative',
});

export interface AboutProps {
  variation: AboutVariation;
  videoPreview: ILinkPreview;
}

function About(props: AboutProps) {
  return (
    <Container>
      <Col css={{ gap: '$48' }}>
        <Box>
          {/* VIDEO VARIATION OPTION DISABLED UNTIL CONTENT EXISTS */}
          {/* <AboutVariationEdit
            variation={variation}
            videoPreview={videoPreview}
          /> */}
          <AboutText />
        </Box>
        <SocialProofContainer>
          <SocialProof size="100%" />
        </SocialProofContainer>
      </Col>
    </Container>
  );
}

export default About;
