import styled from 'styled-components';
import { CompoundColor } from 'components/types';

export const TooltipContainer = styled.div.attrs<{
  x: number;
  y: number;
  minHeight?: string;
  w?: string;
}>((props) => ({
  style: {
    transform: `translate(${props.x}px, ${props.y + 10}px)`,
    zIndex: 9999,
    minHeight: props.minHeight ? props.minHeight : 0,
    width: props.w ? props.w : 'auto',
  },
}))`
  pointer-events: none;
  visibility: visible;
  position: absolute;
  top: 0px;
` as any;

export const TooltipBox = styled.div<{ bg?: CompoundColor }>`
  min-width: 12rem;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme, bg }) => theme.colors[bg || 'white']};
  margin-left: 5px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  width: auto;
` as any;
