export const InvestmentTransactionTypes = {
  CapitalCalled: 'Capital Called',
  CorporateAction: 'Corporate Action',
  Dividend: 'Dividend',
  Rebate: 'Rebate',
  ShortSale: 'Short Sale',
  Valuation: 'Valuation',
  Sale: 'Sale',
  CommittedCapital: 'Committed Capital',
  CashDeposit: 'Cash Deposit',
  IncomeReinvestment: 'Income Reinvestment',
  ReturnOfCapital: 'Return of Capital',
  Journal: 'Journal',
  Income: 'Income',
  Interest: 'Interest',
  CapitalGains: 'Capital Gains',
  TransferOut: 'Transfer Out',
  Buy: 'Buy',
  BuyToClose: 'Buy to Close',
  OptionActivity: 'Option Activity',
  DividendReinvestment: 'Dividend Reinvestment',
  Expense: 'Expense',
  ManagementFee: 'Management Fee',
  CashWithdrawal: 'Cash Withdrawal',
  TransferIn: 'Transfer In',
  // Plaid specific transaction types
  Cancel: 'Cancel',
  Adjustment: 'Adjustment',
  // Types we do not recognze will be mapped based on its direction
  Debit: 'Debit',
  Credit: 'Credit',
  Unknown: 'Unknown',
};
