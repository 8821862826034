import React from 'react';
import { KeyedMutator } from 'swr';
import { CompoundDocument, CompoundDocumentSection } from '../..';

type ModuleContextType = {
  module: CompoundDocumentSection | null;
  mutateDocument: KeyedMutator<CompoundDocument> | null;
  isHover: boolean;
  setIsHover: (isHover: boolean) => void;
  documentProperties: Record<string, any>;
};

const ModuleContext = React.createContext<ModuleContextType>({
  module: null,
  mutateDocument: null,
  isHover: false,
  setIsHover: () => {},
  documentProperties: {},
});

export default ModuleContext;
