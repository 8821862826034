import {
  Box,
  Button,
  Col,
  Dialog,
  Row,
  Text,
  TextField,
  useDialog,
} from '@compoundfinance/design-system';
import { noop } from 'lodash/fp';
import { useCallback, useState } from 'react';
import buildRequest from 'utils/api';
import { REQUEST_TYPES } from 'utils/constants/axios';
import DateUtils from '@compoundfinance/compound-core/dist/date-utils';

interface ExpiredOrDeletedViewPros {
  document: any;
  expirationDate: Date;
  code: string;
  isDeleted: boolean;
}

function ExpiredOrDeletedView(props: ExpiredOrDeletedViewPros) {
  const { document, expirationDate, code, isDeleted } = props;
  const [formState, setFormState] = useState({ name: '', email: '' });
  const { isOpen, setIsOpen } = useDialog();

  const updateFormState = useCallback((field: string, val: string) => {
    setFormState((state) => ({ ...state, [field]: val }));
  }, []);

  const openRequestAccess = async () => {
    setIsOpen(true);
  };

  const requestAccess = async () => {
    const res = await buildRequest(
      `/api/reports-private/request-access/${code}/${formState.name}/${formState.email}`,
      true,
      {},
      REQUEST_TYPES.GET,
      {},
    );
    setIsOpen(false);
    return res;
  };

  return (
    <Dialog
      isOpen={true}
      setIsOpen={noop}
      onClose={noop}
      css={{
        h: '100vh',
        maxHeight: '100vh',
        w: '100%',
        p: 0,
        bg: '$gray2',
        borderRadius: 0,
        overflow: 'hidden',
      }}
      zIndex={9999}
      hideCross
    >
      <Dialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        zIndex={99999}
        css={{ py: '$24' }}
        hideCross
      >
        <Text size="18">Request access to this report</Text>
        <Box>
          <Col css={{ py: '$24' }}>
            <Box>
              <Text color="gray11" size="13">
                Name
              </Text>
              <TextField
                type="text"
                css={{ width: '100%', order: 1 }}
                name="name"
                value={formState.name}
                onChange={(e) => updateFormState('name', e.target.value)}
                placeholder="Your name"
              />
            </Box>
            <Box>
              <Text color="gray11" size="13">
                Email
              </Text>
              <TextField
                type="text"
                css={{ width: '100%', order: 1 }}
                name="email"
                value={formState.email}
                onChange={(e) => updateFormState('email', e.target.value)}
                placeholder="you@email.com"
              />
            </Box>
          </Col>
          <Row
            css={{
              gap: '$12',
              jc: 'flex-end',
              w: '100%',
            }}
          >
            <Button variant="ghost" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button
              disabled={
                !formState.email ||
                formState.email === '' ||
                !formState.name ||
                formState.name === ''
              }
              onClick={requestAccess}
            >
              Send
            </Button>
          </Row>
        </Box>
      </Dialog>
      <Box
        css={{
          h: '100%',
          overflowY: 'auto',
          scrollBehavior: 'smooth',
        }}
      >
        <Col>
          <Col
            as={'nav'}
            css={{
              bg: '$gray0',
              w: '100%',
              h: '$72',
              borderBottom: '1px solid $gray4',
            }}
          >
            <Row
              css={{
                jc: 'center',
                ai: 'center',
                h: '100%',
                px: '$24',
              }}
            >
              {/* TODO Center relative to viewport */}
              <Row
                css={{
                  gap: '$8',
                }}
              >
                <Text>{document.title}</Text>
              </Row>
            </Row>
          </Col>
          {isDeleted ? (
            <>
              <Col
                css={{
                  pt: '120px',
                  w: '100%',
                  ai: 'center',
                  jc: 'center',
                }}
              >
                <Text size={20}>Document cannot be found</Text>
              </Col>
            </>
          ) : (
            <>
              <Col
                css={{
                  pt: '120px',
                  w: '100%',
                  ai: 'center',
                  jc: 'center',
                  gap: '$8',
                }}
              >
                <Text size={20}>Access to this report has expired</Text>
                <Text color={'gray10'}>
                  This report access link expired on{' '}
                  {DateUtils.formatToStr(expirationDate, false)}
                </Text>
              </Col>
              <Col css={{ pt: '$32', w: '100%', ai: 'center', jc: 'center' }}>
                <Button variant="ghost" onClick={openRequestAccess}>
                  Request Access
                </Button>
              </Col>
            </>
          )}
        </Col>
      </Box>
    </Dialog>
  );
}

export default ExpiredOrDeletedView;
