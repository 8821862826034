import * as _ from 'lodash';
import compact from 'lodash/compact';
import ActionTypes from '../actionTypes/assets';
import { ContainerState, AssetsActions } from 'types/reducers/assets';
import { PlaidAccount } from '@compoundfinance/compound-core/dist/types/plaid';
import { LifecycleStatus, Reducers } from 'types/reducers';
import { CompoundAsset } from 'components/EquityManagement/Asset/types';
import OwnershipAdjustmentUtils from 'utils/assets/ownershipAdjustment';
import { CSVModalTypes, CSVSources } from 'containers/CSVModal/types';
import {
  getAssetIdToAssetFilterMapping,
  getAssetFilterKey,
  getVisibleAccountsFromAssetsState,
  getAssetIdToFilterType,
} from 'utils/assetFilters/utils';
import { ValuationMethodType } from '@compoundfinance/compound-core/dist/constants/peAccount';
import PeAccountUtils from 'utils/peAccount';
import { replaceReducer } from 'reducers/hmr';
import { AssetSource } from 'domain/AssetSource';

// The initial state of the App
export const DEFAULT_PE_STATE = {
  id: '',
  grants: [],
  certificates: [],
  convertibleNotes: [],
  exerciseEvents: [],
  vestEvents: [],
  valuationHistory: [],
  saleEvents: [],
  fmv: 0,
  preferredPrice: 0,
  estimatedPrice: 0,
  valuation: 0,
  companyName: '',
  logo: '',
  startDate: null,
  endDate: null,
  provider: '',
  userId: '',
  integrationId: '',
  ownerId: '',
  username: '',
  relationship: '',
  notes: '',
  accountSnapshots: [],
  changes: [],
  inactive: false,
  hasLiquidityEvent: false,
  liquidityEventAt: null,
  brokerageAdded: false,
  tickerSymbol: '',
  isQsbsQualifiedBusiness: false,
  valuationMethod: ValuationMethodType.FMV,
  originalFmv: 0,
  fundingRound: '',
  sharesOutstanding: 0,
  earlyExercise: null,
  exerciseWindow: 0,
  totalAssetsOver50Million: null,
  totalAssetsOver50MillionDate: null,
  providerId: null,
  assetCategorization: {
    id: '',
    assetType: AssetSource.PrivateEquityAccount,
    assetId: '',
    categorizations: [],
  },
};

export const INITIAL_ASSET_STATE: ContainerState = {
  // These are ALL plaid accounts, both active and inactive
  rawAccounts: [],
  // These are only the active plaid accounts
  accounts: [],
  defiAccounts: [],
  transactions: [],
  investmentTransactions: [],
  holdings: [],
  realEstate: [],
  privateInvestment: [],
  rawPrivateInvestment: [],
  privateEquityAccounts: [],
  rawPrivateEquityAccounts: [],
  activePeAccount: DEFAULT_PE_STATE,
  other: [],
  otherLiability: [],
  lpExposure: [],
  gpExposure: [],
  loan: [],
  error: '',
  status: LifecycleStatus.Idle,
  loaded: false,
  assetsLoaded: false,
  holdingsLoaded: false,
  defiAccountsLoaded: false,
  activeAsset: {} as CompoundAsset | PlaidAccount,
  assetModalOpen: false,
  addAssetModalOpen: false,
  ownerManagementModalOpen: false,
  viewAssetModalOpen: { isOpen: false, step: null },
  viewCompanyEquityModalOpen: { isOpen: false, step: null },
  csvModal: {
    type: CSVModalTypes.Private,
    source: CSVSources.AngelList,
    open: false,
  },
  mxModal: {
    open: false,
  },
  accountSnapshotsLoaded: false,
  filteredNetWorth: 0,
  totalNetWorth: 0,
  assetFilters: [],
  assetIdToFilterType: {},
  refinery: { fetched: false, metadata: [] },
};

function assetsReducer(
  state: ContainerState = INITIAL_ASSET_STATE,
  action: AssetsActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.UPDATE_ASSETS_STATE_FIELD: {
      if (
        ['realEstate', 'other', 'loan', 'otherLiability'].includes(
          action.payload.field,
        )
      ) {
        return {
          ...state,
          [action.payload.field]: action.payload.value?.map((asset) =>
            OwnershipAdjustmentUtils.adjustCompoundAsset(
              asset,
              action.payload.field,
            ),
          ),
        };
      } else if (['accounts', 'rawAccounts'].includes(action.payload.field)) {
        return {
          ...state,
          [action.payload.field]: action.payload.value?.map(
            OwnershipAdjustmentUtils.adjustPlaidAccount,
          ),
        };
      }
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    }
    case ActionTypes.UPDATE_ASSETS_STATE:
      const assetIdToFilterType = getAssetIdToFilterType(
        action.payload.stateUpdate.assetFilters ?? state.assetFilters,
      );
      const assetTypes = ['realEstate', 'other', 'loan', 'otherLiability'];
      const adjustedCompoundAssets = assetTypes.reduce(
        (memo, assetType) => ({
          ...memo,
          [assetType]: action.payload.stateUpdate[assetType]?.map((asset) =>
            OwnershipAdjustmentUtils.adjustCompoundAsset(
              asset as CompoundAsset,
              assetType,
            ),
          ),
        }),
        {},
      );
      const rawAccounts = action.payload.stateUpdate.rawAccounts?.map(
        OwnershipAdjustmentUtils.adjustPlaidAccount,
      );
      const visibleAccounts = getVisibleAccountsFromAssetsState(
        {
          rawAccounts,
          rawPrivateEquityAccounts:
            action.payload.stateUpdate.rawPrivateEquityAccounts,
          rawPrivateInvestment: action.payload.stateUpdate.rawPrivateInvestment,
        },
        assetIdToFilterType,
      );

      const { privateEquityAccounts } = visibleAccounts;
      let activePeAccount;
      if (action.payload.stateUpdate.rawPrivateEquityAccounts) {
        activePeAccount = _.isEmpty(privateEquityAccounts)
          ? undefined
          : PeAccountUtils.getActivePeAccount(
              privateEquityAccounts,
              state.activePeAccount.id,
            );
      }

      // Remove undefined values
      const adjustedAssets = _.pickBy(
        {
          ...adjustedCompoundAssets,
          ...visibleAccounts,
          rawAccounts,
          activePeAccount,
        },
        _.identity,
      );
      return {
        ...state,
        ...action.payload.stateUpdate,
        ...adjustedAssets,
        assetIdToFilterType,
      };

    case ActionTypes.SetCurrentAsset: {
      return {
        ...state,
        activeAsset: action.payload.asset,
      };
    }
    case ActionTypes.SetAssetModalOpen: {
      return { ...state, assetModalOpen: action.payload };
    }
    case ActionTypes.SetAddAssetModalOpen: {
      return { ...state, addAssetModalOpen: action.payload };
    }
    case ActionTypes.SetOwnerManagementModalOpen: {
      return { ...state, ownerManagementModalOpen: action.payload };
    }
    case ActionTypes.SetViewAssetModalOpen: {
      return { ...state, viewAssetModalOpen: action.payload };
    }
    case ActionTypes.SetViewCompanyEquityModalOpen: {
      return { ...state, viewCompanyEquityModalOpen: action.payload };
    }
    case ActionTypes.SetCSVModal: {
      return { ...state, csvModal: { ...state.csvModal, ...action.payload } };
    }
    case ActionTypes.SetMxModal: {
      return { ...state, mxModal: action.payload };
    }
    case ActionTypes.SENDING_REQUEST:
      return {
        ...state,
        status: LifecycleStatus.Pending,
        error: '',
      };
    case ActionTypes.RESOLVE_REQUEST: {
      return {
        ...state,
        status: LifecycleStatus.Resolved,
      };
    }
    case ActionTypes.REJECT_REQUEST: {
      return {
        ...state,
        status: LifecycleStatus.Rejected,
      };
    }
    case ActionTypes.COMPLETE_REQUEST:
      return {
        ...state,
        status: LifecycleStatus.Idle,
      };
    case ActionTypes.UPDATE_NET_WORTH:
      return {
        ...state,
        filteredNetWorth: action.payload.filteredNetWorth,
        totalNetWorth: action.payload.totalNetWorth,
      };
    case ActionTypes.BATCH_UPDATE_ACCOUNT_FILTERS: {
      const newAssetIdToAssetFilter = getAssetIdToAssetFilterMapping(
        action.payload.filters,
      );
      const updatedAssetFilters = compact(
        state.assetFilters.map((assetFilter) => {
          const key = getAssetFilterKey(assetFilter);
          const updatedAssetFilter = newAssetIdToAssetFilter[key];
          if (updatedAssetFilter) {
            delete newAssetIdToAssetFilter[key];
            if (action.payload.isFiltering) {
              return updatedAssetFilter;
            } else {
              return null;
            }
          }

          return assetFilter;
        }),
      );
      const newFilters = action.payload.isFiltering
        ? Object.values(newAssetIdToAssetFilter)
        : [];
      const assetFilters = [...updatedAssetFilters, ...newFilters];
      const updatedAssetIdToFilterType = getAssetIdToFilterType(assetFilters);
      return {
        ...state,
        ...getVisibleAccountsFromAssetsState(state, updatedAssetIdToFilterType),
        assetFilters,
        assetIdToFilterType: updatedAssetIdToFilterType,
      };
    }
    case ActionTypes.UPDATE_ACCOUNT_FILTERS:
      let alreadySynced = false;
      const assetFilters = compact(
        state.assetFilters.map((assetFilter) => {
          if (
            getAssetFilterKey(assetFilter) ===
            getAssetFilterKey(action.payload.filter)
          ) {
            alreadySynced = true;
            if (action.payload.filter.filter) {
              return action.payload.filter;
            } else {
              return null;
            }
          }

          return assetFilter;
        }),
      );
      if (!alreadySynced) {
        assetFilters.push(action.payload.filter);
      }
      const updatedAssetIdToFilterType = getAssetIdToFilterType(assetFilters);
      return {
        ...state,
        ...getVisibleAccountsFromAssetsState(state, updatedAssetIdToFilterType),
        assetFilters,
        assetIdToFilterType: updatedAssetIdToFilterType,
      };
    case ActionTypes.SET_ACTIVE_PE_INTEGRATION:
      return {
        ...state,
        activePeAccount: action.payload.privateEquityAccount,
      };
    case ActionTypes.UPDATE_PE_ACCOUNT:
      if (action.payload.ind < 0) {
        return state;
      }
      const updatedAccount = {
        ...state.privateEquityAccounts[action.payload.ind],
        [action.payload.field]: action.payload.value,
      };
      const updatedActivePeAccount =
        updatedAccount.id === state.activePeAccount.id
          ? {
              ...state.activePeAccount,
              ...updatedAccount,
            }
          : state.activePeAccount;

      return {
        ...state,
        activePeAccount: updatedActivePeAccount,
        privateEquityAccounts: [
          ...state.privateEquityAccounts.slice(0, action.payload.ind),
          updatedAccount,
          ...state.privateEquityAccounts.slice(action.payload.ind + 1),
        ],
      };
    case ActionTypes.UPDATE_ACTIVE_PE_ACCOUNT:
      return {
        ...state,
        activePeAccount: {
          ...state.activePeAccount,
          ...action.payload.value,
        },
      };
    case ActionTypes.REMOVE_PE_ACCOUNT:
      return {
        ...state,
        privateEquityAccounts: state.privateEquityAccounts.filter(
          ({ id }) => id !== action.payload.id,
        ),
        rawPrivateEquityAccounts: state.rawPrivateEquityAccounts.filter(
          ({ id }) => id !== action.payload.id,
        ),
      };
    case ActionTypes.CLEAR_PE_ACCOUNT_STATE:
      return {
        ...state,
        activePeAccount: DEFAULT_PE_STATE,
      };
    case ActionTypes.UPDATE_CERTIFICATE_ELECTION: {
      const { activePeAccount, election } = action.payload;
      const { certificates } = activePeAccount;
      const updatedCerts = (certificates ?? []).map((cert) => {
        if (cert.id === election.certificateId) {
          return {
            ...cert,
            election,
          };
        }

        return cert;
      });

      const updatedAccount = {
        ...activePeAccount,
        certificates: updatedCerts,
      };
      const nextAccounts = state.privateEquityAccounts.slice();
      nextAccounts.splice(
        nextAccounts.findIndex((a) => a.id === activePeAccount.id),
        1,
        updatedAccount,
      );

      return {
        ...state,
        privateEquityAccounts: nextAccounts,
        activePeAccount: updatedAccount,
      };
    }
    case ActionTypes.DELETE_CERTIFICATE_ELECTION: {
      const { electionId, activePeAccount } = action.payload;
      const { certificates } = activePeAccount;

      const updatedCerts = (certificates ?? []).map((cert) => {
        if (cert.election && cert.election.id === electionId) {
          return {
            ...cert,
            election: null,
          };
        }
        return cert;
      });
      const updatedAccount = {
        ...activePeAccount,
        certificates: updatedCerts,
      };
      const nextAccounts = state.privateEquityAccounts.slice();
      nextAccounts.splice(
        nextAccounts.findIndex((a) => a.id === activePeAccount.id),
        1,
        updatedAccount,
      );

      return {
        ...state,
        privateEquityAccounts: nextAccounts,
        activePeAccount: updatedAccount,
      };
    }
    case ActionTypes.SET_REFINERY_METADATA:
      return {
        ...state,
        refinery: {
          fetched: true,
          metadata: action.payload.metadata,
        },
      };
    default:
      return state;
  }
}

// Replace the reducer with the hot loaded one
if (import.meta.hot) {
  import.meta.hot.accept(replaceReducer(Reducers.ASSETS));
}

export default assetsReducer;
