import { styled } from '@compoundfinance/design-system/dist/stitches.config';

import {
  Box,
  Col,
  Flex,
  ModalIcon,
  Row,
  Text,
} from '@compoundfinance/design-system';

import {
  AllocationProposal,
  AllocationProposalTypes,
} from 'containers/Dashboard/AllocationProposals/domain/AllocationProposal';
import { NumberUtils } from 'utils/NumberUtils';

import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import EditableTextArea from 'containers/Dashboard/Document/components/EditableTextArea';
import {
  Value,
  Weight,
} from 'containers/Dashboard/Document/components/Figures';
import { EditableSectionTitle } from 'containers/Dashboard/Document/components/SectionTitle';
import { ReturnAndVolatilityDisclaimer } from 'containers/Dashboard/AllocationProposals/components/ReturnAndVolatilityDisclaimer';
import SimplifiedAllocationProposalChanges from './SimplifiedAllocationProposalChanges';

const Container = styled(ModuleContainer, {
  display: 'flex',
  flexDirection: 'column',
  py: '$24',
  px: '$24',
  gap: '$24',

  '@bp1': {
    py: '$44',
    px: '$48',
    gap: '$48',
  },
});

export interface ImpactSummaryProps {
  allocationProposal: AllocationProposalTypes.InvestmentAllocationProposal;
  title: string;
}

function ImpactSummary(props: ImpactSummaryProps) {
  const { allocationProposal: investmentAllocationProposal, title } = props;

  const allocationProposal = AllocationProposal.withBlended(
    investmentAllocationProposal,
  );

  const { transitionedNetworth } = allocationProposal;

  const unomittedNetworth =
    AllocationProposal.unomittedNetworth(allocationProposal);

  const hasTransitionedNetworth = transitionedNetworth != null;

  const transitionedPercentage = hasTransitionedNetworth
    ? NumberUtils.multiply(
        NumberUtils.divide(transitionedNetworth, unomittedNetworth),
        100,
      )
    : NaN;

  return (
    <Container css={{ gap: '$60' }}>
      <EditableSectionTitle title={title} />

      {hasTransitionedNetworth && (
        <Col css={{ flex: '0 0 50%', gap: '$16' }}>
          <Col css={{ gap: '$12' }}>
            <Text size="13" color="gray9">
              Assets moved
            </Text>

            <Box css={{ h: '1px', w: '280px', bg: '$gray4' }} />
          </Col>

          <Col css={{ gap: '$6' }}>
            <Text size="24" weight="bold">
              <Value value={transitionedNetworth} />
            </Text>

            <Text size="12" color="gray9">
              <Weight weight={transitionedPercentage} /> of total assets
            </Text>
          </Col>
        </Col>
      )}

      <Row css={{ gap: '$40' }}>
        <Col
          css={{
            flex: '0 1 40%',
            w: '100%',
          }}
        >
          <EditableTextArea
            propertyKey="customText"
            value={`We're proposing reallocating some of your assets into a set of new Compound accounts.

To the right is the summary of these changes, and their impact to your asset mix, volatility, and projected return.`}
          />
        </Col>

        <Col
          css={{
            flex: '1 0 60%',
            p: '$20 0',
            position: 'sticky',
            top: 0,
            maxHeight: 'calc(100vh - $20)',
            minHeight: '600px',
            w: '100%',
          }}
        >
          <SimplifiedAllocationProposalChanges
            allocationProposal={allocationProposal}
          />
        </Col>
      </Row>

      <Flex css={{ jc: 'end', ai: 'center' }}>
        <ReturnAndVolatilityDisclaimer
          trigger={
            <Row css={{ ai: 'center', gap: '$4' }}>
              <Text size="12" color="gray9">
                Capital market assumptions
              </Text>

              <ModalIcon
                css={{ cursor: 'help' }}
                size={16}
                color="var(--colors-gray9)"
              />
            </Row>
          }
        />
      </Flex>
    </Container>
  );
}

export default ImpactSummary;
