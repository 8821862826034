export enum SplitFeature {
  ENABLE_SOLUTIONS_DIRECTORY = 'enable_frontend_solutions_directory',
  ENABLE_ALLOCATION_VIEW_IN_PUBLIC_INVESTMENTS = 'enable_allocation_view_in_public_investments',
  ENABLE_NFT = 'enable_nft',
  ENABLE_FAMILY_OFFICE = 'enable_family_office',
  ENABLE_PERFORMANCE_REPORTING = 'enable_performance_reporting',
  DISABLE_DEFI = 'disable_defi',
  ENABLE_BORROW_EXPLORE_CARD = 'enable_borrow_explore_card',
  ENABLE_COMPOUND_2FA = 'enable_frontend_Compound_2FA',
  ENABLE_FRONTEND_NEW_ADD_ASSET_MODAL = 'enable_frontend_new_add_asset_modal',
  ENABLE_CSV_IMPORT = 'enable_frontend_angellist_csv_import',
  ENABLE_EQUITY_VALUATION_METHODS = 'enable_frontend_equity_valuation_method',
  ENABLE_CASHFLOW_ACTIVITY_EXPIRED_MODAL = 'enable_frontend_cashflow_activity_expired_modal',
  ENABLE_EQUITY_V2 = 'enable_frontend_equity_v2',
  ALLOW_EDITING_STRIPE_CUSTOMER_ID = 'allow_editing_stripe_customer_id',
  ENABLE_REFERRALS = 'enable_referrals',
  DISABLE_CARTA_INTEGRATION = 'disable_carta_integration',
  ENABLE_ADD_ACCOUNT_ETH_WALLET = 'enable_add_account_eth_wallet',
  ENABLE_EQUITY_NEW_ADD_ASSET_MODAL = 'enable_equity_new_add_asset_modal',
  ENABLE_PRIVATE_MARKETS = 'enable_private_markets',
  ENABLE_INVEST_V2_OVERRIDE = 'compound_invest_v2_etl_override',
  ENABLE_EQUITY_MODELING = 'enable_frontend_equity_modeling',
  ENABLE_NEW_REAL_ESTATE = 'enable_new_real_estate',
  ENABLE_INTERCOM_SUPPORT = 'enable_intercom_support',
  ENABLE_ONE_TIME_CONSULTATION_CTA = 'enable_one_time_consultation_cta',
  ENABLE_BILLING_TAB = 'enable_billing_tab',
  ENABLE_VAULT_V2 = 'enable_vault_v2',
  ENABLE_SUPERBLOCKS_ADMIN_LINK = 'enable_superblocks_authentication',
  ENABLE_EQUITY_MODELING_ONBOARDING = 'enable_frontend_equity_modeling_onboarding',
  ENABLE_TAX_FILING_INTRO_2022 = 'enable_tax_filing_intro_2022',
  ENABLE_TAX_FILING_FILE_VAULT_2022 = 'enable_tax_filing_file_vault_2022',
  ENABLE_TAX_FILING_QUESTIONNAIRE_2022 = 'enable_tax_filing_questionnaire_2022',
  ENABLE_TAX_FILING_QUESTIONNAIRE_FOLLOW_UP_2022 = 'enable_tax_filing_questionnaire_follow-up_2022',
  ENABLE_TAX_FILING_CLOSING_2022 = 'enable_tax_filing_closing_2022',
  ENABLE_EQUITY_MODELING_ADVANCED = 'enable_advanced_strategies',
  ENABLE_EQUITY_MODELING_VALUATION_CURVE = 'enable_equity_modeling_valuation_curve',
  ENABLE_ADD_ACCOUNT_ARGYLE = 'enable_add_account_argyle',
  ENABLE_NEW_PUBLIC_INVESTMENT = 'enable_new_public_investment',
  ENABLE_NEW_PUBLIC_INVESTMENT_2 = 'enable_new_public_investment_2',
  ENABLE_DEMO_INSTANCE_MODELING_V2 = 'enable_demo_instance_modeling_v2',
  ENABLE_FLOW = 'enable_flow',
  NET_WORTH_CHART = 'net_worth_chart_v2',
  FLOW_ALLOW_ONLY_ONE_ACTION_PER_STRATEGY = 'flow_allow_only_one_action_per_strategy',
  TRANSACTIONS = 'transactions',
  ENABLE_WIDGETS = 'enable_widgets',
  ENABLE_TRANSACTIONS_CATEGORY_SEARCH = 'enable_transactions_category_search',
  ENABLE_DOCUMENT_MAGIC_LINKS = 'enable_document_magic_links',
  ENABLE_LIABILITIES_SECTION = 'enable_liabilities_section',
  ENABLE_RECATEGORIZING_SIDEBAR_SECTIONS = 'enable_recategorizing_sidebar_sections',
  ENABLE_EQUITY_MODELING_REMAINING_SHARES = 'enable_equity_modeling_remaining_shares',
  ENABLE_CASH_ACCOUNT_VIEW = 'ENABLE_CASH_ACCOUNT_VIEW',
  ENABLE_COHERE_ADVISOR_SESSION_RECORDING = 'enable_cohere_advisor_session_recording',
  ENABLE_ACCOUNT_ORGANIZER = 'enable_account_organizer',
  ENABLE_NEW_FLOW_ENGINE = 'enable_new_flow_engine',
  HIDE_ESTATE_PLANNING = 'hide_estate_planning',
  HIDE_ALL_PAGES_BUT_BASIC_ACCOUNTS_AND_VAULT = 'HIDE_ALL_PAGES_BUT_BASIC_ACCOUNTS_AND_VAULT',
  ENABLE_CONTRO_INTEGRATION = 'enable_contro_integration',
  ENABLE_CONTRO_INTEGRATION_PERFORMANCE = 'enable_contro_integration_performance_tab',
  ENABLE_CONTRO_INTEGRATION_TRANSACTIONS = 'enable_contro_integration_transactions_tab',
  ENABLE_CONTRO_INTEGRATION_TRANSFERS = 'enable_contro_integration_transfers_tab',
  ENABLE_VAULT_FILTERING_BY_USER = 'enable_vault_filtering_by_user',
  SHOW_SIGNUP_LINKS_TAB = 'show_signup_links_tab',
  ENABLE_MONTE_CARLO_FLOW_SIMULATION = 'enable_monte_carlo_flow_simulation',
  SHOW_BD_CUTOVER_BANNER = 'BD-to-orion-notice',
}
