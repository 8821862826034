import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import partition from 'lodash/partition';

import { AssetCategorySlug } from 'domain/AssetCategory/constants';
import { ValueOf } from 'types';
import PlaidAccountUtils from 'utils/plaid/accounts';
import { Prettify } from 'utils/types';

import { ASSET_CATEGORIES } from '../constants';
import { AssetCategoryTree } from '../utils/AssetCategoryTree';
import { NumberUtils } from '../../../../utils/NumberUtils';
import { RebalanceError, SeriesUtils } from '../utils/SeriesUtils';
import {
  Allocation,
  AllocationMessageTypes,
  AllocationTypes,
} from './Allocation';
import { AssetAllocationCategory } from './AssetAllocationCategory';
import { InvestmentTargetMessageTypes } from './InvestmentTarget';
import {
  StrategicAllocationProposalStrategyType,
  StrategicAllocationProposalStrategyTypes,
} from './Strategic/StrategicAllocationProposalStrategy';
import {
  TargetAllocationProposalStrategyType,
  TargetAllocationProposalStrategyTypes,
} from './Target/TargetAllocationProposalStrategy';

export const AllocationProposalStrategyType = {
  ...TargetAllocationProposalStrategyType,
  ...StrategicAllocationProposalStrategyType,
} as const;
type AllocationProposalStrategyType = ValueOf<
  typeof AllocationProposalStrategyType
>;

export namespace AllocationProposalStrategyMessageTypes {
  export type AllocationProposalStrategyMessage = {
    id?: string;
    networth: number;
    type: Extract<
      AllocationProposalStrategyType,
      'original' | 'target' | 'retirement' | 'taxable'
    >;
    investmentTarget: InvestmentTargetMessageTypes.InvestmentTargetMessage | null;
    allocations: AllocationMessageTypes.AllocationMessage[];
    updatedAt?: string;
    createdAt?: string;
  };
}

export namespace AllocationProposalStrategyTypes {
  export type BaseAllocationProposalStrategy = {
    id: string;
    networth: number;
    type: AllocationProposalStrategyType;
    updatedAt?: string;
    createdAt?: string;
  };

  export type OriginalAllocationProposalStrategy = Prettify<
    | TargetAllocationProposalStrategyTypes.OriginalAllocationProposalStrategy
    | StrategicAllocationProposalStrategyTypes.OriginalAllocationProposalStrategy
  >;
  export type CategorizedOrginalAllocationProposalStrategy = Prettify<
    | TargetAllocationProposalStrategyTypes.CategorizedOriginalAllocationProposalStrategy
    | StrategicAllocationProposalStrategyTypes.CategorizedOriginalAllocationProposalStrategy
  >;

  export type AllocationProposalStrategy = Prettify<
    | TargetAllocationProposalStrategyTypes.AllocationProposalStrategy
    | StrategicAllocationProposalStrategyTypes.AllocationProposalStrategy
  >;
  export type CategorizedAllocationProposalStrategy = Prettify<
    | TargetAllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy
    | StrategicAllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy
  >;

  export type PresetAssetAllocation = BaseAllocationProposalStrategy & {
    allocations: AllocationTypes.CategoryAllocation[];
  } & { type: 'preset' };

  export type WeightedAllocationProposalStrategy = {
    allocations: Record<string, AllocationTypes.Allocation['weight']>;
  };
}

const DEFAULT_PUBLIC_ASSETS_ASSET_ALLOCATION = {
  'public-assets': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'public-assets' }),
    slug: 'public-assets',
    assetCategorySlug: 'public-assets',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'public-assets',
  },
  equities: {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'equities' }),
    slug: 'equities',
    assetCategorySlug: 'equities',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'equities',
  },
  'us-equities': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'us-equities' }),
    slug: 'us-equities',
    assetCategorySlug: 'us-equities',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'us-equities',
  },
  'us-equities-large-cap': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'us-equities-large-cap' }),
    slug: 'us-equities-large-cap',
    assetCategorySlug: 'us-equities-large-cap',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'us-equities-large-cap',
  },
  'us-equities-smallmid-cap': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'us-equities-smallmid-cap' }),
    slug: 'us-equities-smallmid-cap',
    assetCategorySlug: 'us-equities-smallmid-cap',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'us-equities-smallmid-cap',
  },
  'us-equities-other': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'us-equities-other' }),
    slug: 'us-equities-other',
    assetCategorySlug: 'us-equities-other',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'us-equities-other',
  },
  'international-equities': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'international-equities' }),
    slug: 'international-equities',
    assetCategorySlug: 'international-equities',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'international-equities',
  },
  'international-equities-developed-ex-us': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({
      slug: 'international-equities-developed-ex-us',
    }),
    slug: 'international-equities-developed-ex-us',
    assetCategorySlug: 'international-equities-developed-ex-us',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'international-equities-developed-ex-us',
  },
  'international-equities-emerging-markets': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({
      slug: 'international-equities-emerging-markets',
    }),
    slug: 'international-equities-emerging-markets',
    assetCategorySlug: 'international-equities-emerging-markets',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'international-equities-emerging-markets',
  },
  'international-equities-other': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({
      slug: 'international-equities-other',
    }),
    slug: 'international-equities-other',
    assetCategorySlug: 'international-equities-other',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'international-equities-other',
  },
  'fixed-income': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'fixed-income' }),
    slug: 'fixed-income',
    assetCategorySlug: 'fixed-income',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'fixed-income',
  },
  'us-fixed-income': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'us-fixed-income' }),
    slug: 'us-fixed-income',
    assetCategorySlug: 'us-fixed-income',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'us-fixed-income',
  },
  'international-fixed-income': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'international-fixed-income' }),
    slug: 'international-fixed-income',
    assetCategorySlug: 'international-fixed-income',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'international-fixed-income',
  },
  tips: {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'tips' }),
    slug: 'tips',
    assetCategorySlug: 'tips',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'tips',
  },
  'other-fixed-income': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'other-fixed-income' }),
    slug: 'other-fixed-income',
    assetCategorySlug: 'other-fixed-income',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'other-fixed-income',
  },
  alternatives: {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'alternatives' }),
    slug: 'alternatives',
    assetCategorySlug: 'alternatives',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'alternatives',
  },
  'real-estate-funds': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'real-estate-funds' }),
    slug: 'real-estate-funds',
    assetCategorySlug: 'real-estate-funds',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'real-estate-funds',
  },
  'other-alternatives': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'other-alternatives' }),
    slug: 'other-alternatives',
    assetCategorySlug: 'other-alternatives',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'other-alternatives',
  },
  commodities: {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'commodities' }),
    slug: 'commodities',
    assetCategorySlug: 'commodities',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'commodities',
  },
  'other-public-assets': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'other-public-assets' }),
    slug: 'other-public-assets',
    assetCategorySlug: 'other-public-assets',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'other-public-assets',
  },
} as const satisfies Partial<
  Record<AssetCategorySlug, AllocationTypes.CategoryAllocation>
>;

const DEFAULT_WEIGHT_ASSIGNBALE_ALTERNATIVES_ASSET_ALLOCATION = {
  'private-investments': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'private-investments' }),
    slug: 'private-investments',
    assetCategorySlug: 'private-investments',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'private-investments',
  },
  'private-equity-fund': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'private-equity-fund' }),
    slug: 'private-equity-fund',
    assetCategorySlug: 'private-equity-fund',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'private-equity-fund',
  },
  'venture-capital-fund': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'venture-capital-fund' }),
    slug: 'venture-capital-fund',
    assetCategorySlug: 'venture-capital-fund',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'venture-capital-fund',
  },
  'real-estate-private-fund': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'real-estate-private-fund' }),
    slug: 'real-estate-private-fund',
    assetCategorySlug: 'real-estate-private-fund',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'real-estate-private-fund',
  },
  'private-credit-fund': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'private-credit-fund' }),
    slug: 'private-credit-fund',
    assetCategorySlug: 'private-credit-fund',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'private-credit-fund',
  },
  'hedge-fund': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'hedge-fund' }),
    slug: 'hedge-fund',
    assetCategorySlug: 'hedge-fund',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'hedge-fund',
  },
  cash: {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'cash' }),
    slug: 'cash',
    assetCategorySlug: 'cash',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'cash',
  },
  cryptocurrency: {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'cryptocurrency' }),
    slug: 'cryptocurrency',
    assetCategorySlug: 'cryptocurrency',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'cryptocurrency',
  },
} as const satisfies Partial<
  Record<AssetCategorySlug, AllocationTypes.CategoryAllocation>
>;

const DEFAULT_OTHER_ALTERNATIVES_ASSET_ALLOCATION = {
  'other-assets': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'other-assets' }),
    slug: 'other-assets',
    assetCategorySlug: 'other-assets',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'other-assets',
  },
  'real-estate': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'real-estate' }),
    slug: 'real-estate',
    assetCategorySlug: 'real-estate',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'real-estate',
  },
  'company-equity': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'company-equity' }),
    slug: 'company-equity',
    assetCategorySlug: 'company-equity',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'company-equity',
  },
  'fund-investments': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'fund-investments' }),
    slug: 'fund-investments',
    assetCategorySlug: 'fund-investments',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'fund-investments',
  },
  'other-fund-investments': {
    weight: 0,
    value: 0,
    name: AssetAllocationCategory.name({ slug: 'other-fund-investments' }),
    slug: 'other-fund-investments',
    assetCategorySlug: 'other-fund-investments',
    allocationType: 'ASSET_CATEGORY',
    assetSource: 'assetCategory',
    assetId: 'other-fund-investments',
  },
} as const satisfies Partial<
  Record<AssetCategorySlug, AllocationTypes.CategoryAllocation>
>;

const DEFAULT_ALTERNATIVES_ASSET_ALLOCATION = {
  ...DEFAULT_WEIGHT_ASSIGNBALE_ALTERNATIVES_ASSET_ALLOCATION,
  ...DEFAULT_OTHER_ALTERNATIVES_ASSET_ALLOCATION,
} as const satisfies Partial<
  Record<AssetCategorySlug, AllocationTypes.CategoryAllocation>
>;

const DEFAULT_ASSET_ALLOCATION = {
  ...DEFAULT_PUBLIC_ASSETS_ASSET_ALLOCATION,
  ...DEFAULT_ALTERNATIVES_ASSET_ALLOCATION,
} as const satisfies Record<
  AssetCategorySlug,
  AllocationTypes.CategoryAllocation
>;

const Constants = {
  DEFAULT_ASSET_ALLOCATION,
  DEFAULT_ALTERNATIVES_ASSET_ALLOCATION,
  DEFAULT_PUBLIC_ASSETS_ASSET_ALLOCATION,
  DEFAULT_OTHER_ALTERNATIVES_ASSET_ALLOCATION,
  DEFAULT_WEIGHT_ASSIGNBALE_ALTERNATIVES_ASSET_ALLOCATION,
};

const isCategorizedAllocations = (
  allocations:
    | AllocationProposalStrategyTypes.AllocationProposalStrategy['allocations']
    | AllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy['allocations'],
): allocations is AllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy['allocations'] =>
  !Array.isArray(allocations);

const hasClientAllocations = <
  AllocationType extends AllocationTypes.Allocation,
>(
  categorizedAllocation: Record<string, AllocationType>,
) => fromCategorized(categorizedAllocation).some(Allocation.isClientAllocation);

const create = <
  AllocationType extends
    | AllocationProposalStrategyTypes.AllocationProposalStrategy['allocations']
    | AllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy['allocations'],
>(
  allocations: AllocationType,
): AllocationProposalStrategyTypes.AllocationProposalStrategy['allocations'] =>
  fromCategorized(
    AssetCategoryTree.normalize({
      ...DEFAULT_ASSET_ALLOCATION,
      ...(isCategorizedAllocations(allocations)
        ? allocations
        : toCategorized(allocations)),
    }),
  );

const publicAssets = <
  AllocationType extends Record<string, AllocationTypes.Allocation>,
>(
  categorizedAllocation: AllocationType,
) =>
  AllocationProposalStrategy.toCategorized(
    AllocationProposalStrategy.fromCategorized(categorizedAllocation).filter(
      (allocation) => !!DEFAULT_PUBLIC_ASSETS_ASSET_ALLOCATION[allocation.slug],
    ),
  );

const alternatives = <
  AllocationType extends Record<string, AllocationTypes.Allocation>,
>(
  categorizedAllocation: AllocationType,
) =>
  AllocationProposalStrategy.toCategorized(
    AllocationProposalStrategy.fromCategorized(categorizedAllocation).filter(
      (allocation) => !!DEFAULT_ALTERNATIVES_ASSET_ALLOCATION[allocation.slug],
    ),
  );

const weightAssignableAlternatives = <
  AllocationType extends Record<string, AllocationTypes.Allocation>,
>(
  categorizedAllocation: AllocationType,
) =>
  AllocationProposalStrategy.toCategorized(
    AllocationProposalStrategy.fromCategorized(categorizedAllocation).filter(
      (allocation) =>
        !!DEFAULT_WEIGHT_ASSIGNBALE_ALTERNATIVES_ASSET_ALLOCATION[
          allocation.slug
        ],
    ),
  );

const ASSETS_ID = 'ASSETS_ID';
const retirement = (clientAllocations: AllocationTypes.ClientAllocation[]) => {
  const [accounts, assets] = partition(
    clientAllocations,
    Allocation.isAccountWithAssets,
  );
  const retirementAccounts = accounts.filter((account) => {
    const {
      asset: { originalAsset },
    } = account;
    if (!originalAsset) return false;
    const { subtype } = originalAsset;
    if (!subtype) return false;
    return PlaidAccountUtils.isRetirement({ subtype });
  });
  const allAssetsGroupedByAccount = groupBy(assets, (clientAssetAllocation) => {
    const {
      asset: { originalAsset },
    } = clientAssetAllocation;
    if (!originalAsset) return ASSETS_ID;
    return originalAsset.plaidAccountId ?? ASSETS_ID;
  });
  const { ASSETS_ID: _, ...assetsGroupedByAccount } = allAssetsGroupedByAccount;
  return retirementAccounts.flatMap((retirementAccount) => {
    const { id: accountId } = retirementAccount;
    const accountAssets = assetsGroupedByAccount[accountId!] ?? [];
    return [retirementAccount, ...accountAssets];
  });
};

const fromCategorized = <
  AllocationType extends Record<string, AllocationTypes.Allocation>,
>(
  categorizedAllocation: AllocationType,
) =>
  Object.values(
    categorizedAllocation,
  ) as AllocationType[keyof AllocationType][];

const toCategorized = <AllocationType extends AllocationTypes.Allocation[]>(
  allocations: AllocationType,
) =>
  Object.fromEntries(
    allocations.map((allocation) => [allocation.slug, allocation]),
  ) as Record<string, AllocationType[number]>;

const toAssetCategories = <
  AllocationType extends AllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy['allocations'],
>(
  allocations: AllocationType,
) =>
  toCategorized(
    fromCategorized(
      AssetCategoryTree.normalize(toCategorized(create(allocations))),
    ).filter(Allocation.isCategoryAllocation),
  ) as Record<string, AllocationTypes.CategoryAllocation>;

const toAssetClasses = (
  allocations: AllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy['allocations'],
) =>
  toCategorized(
    fromCategorized(toCategorized(create(allocations))).filter(
      Allocation.isCategoryAllocation,
    ),
  ) as Record<string, AllocationTypes.CategoryAllocation>;

const toWeights = (
  allocations: AllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy['allocations'],
): AllocationProposalStrategyTypes.WeightedAllocationProposalStrategy['allocations'] =>
  Object.fromEntries(
    Object.entries(allocations).map(([slug, allocation]) => [
      slug,
      allocation.weight,
    ]),
  );

const value = (clientAllocations: AllocationTypes.ClientAllocation[]) =>
  clientAllocations.reduce(
    (value, clientAllocation) =>
      NumberUtils.add(value, Allocation.value(clientAllocation)),
    0,
  );

const weight = (clientAllocations: AllocationTypes.ClientAllocation[]) =>
  clientAllocations.reduce(
    (weight, clientAllocation) =>
      NumberUtils.add(weight, Allocation.weight(clientAllocation)),
    0,
  );

const recomputeValues = <
  AllocationType extends Record<string, AllocationTypes.Allocation>,
>({
  allocations,
  networth,
}: {
  allocations: AllocationType;
  networth: number;
}) =>
  toCategorized(
    fromCategorized(allocations).map((allocation) =>
      Allocation.updateWeightAndValue(allocation, {
        weight: Allocation.weight(allocation),
        networth,
      }),
    ),
  );

const sortByAssetClass = <
  AllocationType extends Record<string, AllocationTypes.Allocation>,
>(
  allocations: AllocationType,
) =>
  orderBy(
    fromCategorized(allocations),
    [
      (allocation) => {
        return ASSET_CATEGORIES[allocation.assetCategorySlug].index;
      },
      (allocation) =>
        Allocation.isCategoryAllocation(allocation)
          ? 0
          : Allocation.isClientAccountAllocation(allocation)
          ? 1
          : Allocation.isClientAssetAllocation(allocation)
          ? 2
          : null,
      'weight',
      'name',
    ],
    ['asc', 'asc', 'desc', 'asc'],
  );

const sortByWeight = <
  AllocationType extends
    | Record<string, AllocationTypes.Allocation>
    | AllocationTypes.Allocation[],
>(
  allocations: AllocationType,
) =>
  orderBy(
    Array.isArray(allocations) ? allocations : fromCategorized(allocations),
    ['weight', 'name'],
    ['desc', 'asc'],
  );

type AllocationDepth = 'ROOT' | 'LEAF';
export type AllocationRebalanceError = RebalanceError | 'ParentMayLackWeight';
const toAllocationsRebalancingError = (
  error: RebalanceError | null,
  allocationDepth: AllocationDepth,
): AllocationRebalanceError[] | null => {
  if (!error) return null;
  const isRoot = allocationDepth === 'ROOT';
  if (isRoot) return [error];

  return [error, 'ParentMayLackWeight'];
};
const rebalanceWeights = ({
  categorizedAllocation,
  categorizedAllocations,
}: {
  categorizedAllocation: AllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy['allocations'];
  categorizedAllocations: AllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy['allocations'];
}): {
  data: AllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy['allocations'];
  error: AllocationRebalanceError[] | null;
} => {
  const [allocation] = fromCategorized(categorizedAllocation);
  const previousAllocation = categorizedAllocations[allocation.slug];

  if (
    !AssetCategoryTree.isRoot({
      allocation,
      allocations: categorizedAllocations,
    })
  ) {
    const root = AssetCategoryTree.root({
      allocation,
      allocations: categorizedAllocations,
    });
    const rootDescendants = AssetCategoryTree.descendants({
      allocation: root,
      allocations: categorizedAllocations,
    });
    const { data, error } = SeriesUtils.rebalancing.rebalanceEquitatively<
      AllocationTypes.Allocation,
      'slug',
      'weight'
    >({
      item: allocation,
      items: [root, ...rootDescendants],
      isRebalanceable: (rebalancedAllocation) =>
        AssetCategoryTree.isLeaf({
          allocation: rebalancedAllocation,
          allocations: categorizedAllocations,
        }),
      idKey: 'slug',
      rebalanceKey: 'weight',
    });
    return {
      data: AssetCategoryTree.normalize({
        ...categorizedAllocations,
        ...toCategorized(data),
      }),
      error: toAllocationsRebalancingError(error, 'LEAF'),
    };
  }

  const rootSiblings = AssetCategoryTree.siblings({
    allocation,
    allocations: categorizedAllocations,
  });
  const { data: rebalancedSiblings, error: rebalancedSiblingsError } =
    rootSiblings.length > 0
      ? SeriesUtils.rebalancing.rebalanceEquitatively<
          AllocationTypes.Allocation,
          'slug',
          'weight'
        >({
          item: allocation,
          items: [previousAllocation, ...rootSiblings],
          isRebalanceable: (rebalancedAllocation) =>
            AssetCategoryTree.isRoot({
              allocation: rebalancedAllocation,
              allocations: categorizedAllocations,
            }) && Allocation.hasWeight(rebalancedAllocation),
          idKey: 'slug',
          rebalanceKey: 'weight',
        })
      : { data: [allocation], error: null };
  const {
    data: rebalancedSiblingsDescendants,
    error: rebalancedSiblingsDescendantsError,
  } = rebalancedSiblings
    .filter((allocation) =>
      AssetCategoryTree.isParent({
        allocation,
        allocations: categorizedAllocations,
      }),
    )
    .reduce(
      ({ data: resultData, error: resultError }, rebalancedSibling) => {
        const originalRebalancedSibling =
          categorizedAllocations[rebalancedSibling.slug];
        const siblingDescendants = AssetCategoryTree.descendants({
          allocation: rebalancedSibling,
          allocations: categorizedAllocations,
        });
        const rebalanceDescendants =
          Allocation.weight(originalRebalancedSibling) === 0
            ? SeriesUtils.rebalancing.rebalanceEquitatively
            : SeriesUtils.rebalancing.rebalanceProportionally;
        const { data, error } = rebalanceDescendants<
          AllocationTypes.Allocation,
          'slug',
          'weight'
        >({
          item: rebalancedSibling,
          items: [originalRebalancedSibling, ...siblingDescendants],
          isRebalanceable: (rebalancedAllocation) =>
            AssetCategoryTree.isLeaf({
              allocation: rebalancedAllocation,
              allocations: categorizedAllocations,
            }) && Allocation.hasWeight(rebalancedAllocation),
          idKey: 'slug',
          rebalanceKey: 'weight',
          getRebalanceDifference: ({
            itemRebalanceAmount,
            previousItemRebalanceAmount,
          }: {
            itemRebalanceAmount: number;
            previousItemRebalanceAmount: number;
          }) =>
            NumberUtils.subtract(
              previousItemRebalanceAmount,
              itemRebalanceAmount,
            ),
        });
        return {
          data: [...resultData, ...data],
          error: resultError || error,
        };
      },
      { data: [], error: null },
    );
  return {
    data: AssetCategoryTree.normalize({
      ...categorizedAllocations,
      ...toCategorized(rebalancedSiblingsDescendants),
      ...toCategorized(rebalancedSiblings),
    }),
    error: toAllocationsRebalancingError(
      rebalancedSiblingsError || rebalancedSiblingsDescendantsError,
      'ROOT',
    ),
  };
};

const rebalanceTo =
  (target: number) =>
  <AllocationType extends AllocationTypes.Allocation>(
    categorizedAllocations: Record<string, AllocationType>,
  ): Record<string, AllocationType> => {
    const { data } = SeriesUtils.rebalancing.rebalanceProportionallyToTarget<
      AllocationType,
      'slug',
      'weight'
    >({
      items: AllocationProposalStrategy.fromCategorized(categorizedAllocations),
      target,
      rebalanceKey: 'weight',
      isRebalanceable: (rebalancedAllocation) =>
        Allocation.weight(rebalancedAllocation) !== 0 &&
        AssetCategoryTree.isLeaf({
          allocation: rebalancedAllocation,
          allocations: categorizedAllocations,
        }),
    });
    return AssetCategoryTree.normalize(
      AllocationProposalStrategy.toCategorized(data),
    );
  };

export const AllocationProposalStrategy = {
  Constants,
  alternatives,
  create,
  hasClientAllocations,
  fromCategorized,
  isCategorizedAllocations,
  publicAssets,
  recomputeValues,
  rebalanceWeights,
  rebalanceTo,
  rebalanceTo100: rebalanceTo(100),
  retirement,
  sortByAssetClass,
  sortByWeight,
  toAssetCategories,
  toAssetClasses,
  toCategorized,
  toWeights,
  value,
  weight,
  weightAssignableAlternatives,
};
