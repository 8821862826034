import { useState } from 'react';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';

import {
  Box,
  Button,
  Col,
  EyeHideIcon,
  EyeIcon,
  Flex,
  InformationIcon,
  ModalIcon,
  Row,
  Switcher,
  Text,
  Tooltip,
} from '@compoundfinance/design-system';

import {
  AllocationProposal,
  AllocationProposalTypes,
} from 'containers/Dashboard/AllocationProposals/domain/AllocationProposal';
import { TIME_RANGE_PERFORMANCE_CHART_OPTIONS } from 'containers/Dashboard/AllocationProposals/components/AggregatedProjectedPerformance';
import useIsDocumentEditor from 'containers/Dashboard/Advisor/DocumentsAdmin/hooks/useIsDocumentEditor';
import { useMutableModuleProperty } from 'containers/Dashboard/Advisor/DocumentsAdmin/hooks/useMutableProperty';
import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import EditableTextArea from 'containers/Dashboard/Document/components/EditableTextArea';
import EditablePerson from 'containers/Dashboard/Document/components/EditablePerson';
import { getPerson } from 'containers/Dashboard/Document/utils';
import { DirectoryPerson } from 'domain/Employees/constants';
import { MOMENT_FULL_DATETIME_STR } from 'utils/constants/date';
import DateUtils from '@compoundfinance/compound-core/dist/date-utils';
import UiUtils from 'utils/ui';

import { VisibilityMode } from '../types';

import PerformanceChart from 'containers/Dashboard/AllocationProposals/components/Charts/PerformanceChart';
import TimeRange from 'containers/Dashboard/AllocationProposals/components/ui/TimeRange';
import { LineGrid } from 'containers/Dashboard/AllocationProposals/components/Charts/Chartifacts/Grids/LineGrid';
import { InitialAllocationBreakdown } from './InitialAllocationBreakdown';
import { Percentage } from '../../../components/Figures';
import { EditableSectionTitle } from 'containers/Dashboard/Document/components/SectionTitle';
import { ReturnAndVolatilityDisclaimer } from 'containers/Dashboard/AllocationProposals/components/ReturnAndVolatilityDisclaimer';
import { ValuationMode } from 'containers/Dashboard/AllocationProposals/toolbar';

const Container = styled(ModuleContainer, {
  display: 'flex',
  flexDirection: 'column',
  py: '$24',
  px: '$24',
  gap: '$24',

  '@bp1': {
    py: '$44',
    px: '$48',
    gap: '$48',
  },
});

export interface InitialAllocationProps {
  allocationProposal: AllocationProposalTypes.InvestmentAllocationProposal;
  financialAdvisor: DirectoryPerson;
  title: string;
  visibility: Record<string, VisibilityMode>;
}

function InitialAllocation(props: InitialAllocationProps) {
  const {
    allocationProposal: investmentAllocationProposal,
    financialAdvisor,
    title,
    visibility = {},
  } = props;
  const { singleStockExposure: singleStockExposureVisibility = 'hidden' } =
    visibility;

  const allocationProposal = AllocationProposal.withBlended(
    investmentAllocationProposal,
  );

  const isEditor = useIsDocumentEditor();

  const [, setVisibility] = useMutableModuleProperty('visibility', visibility);

  const [valuationMode, setValuationMode] = useState<ValuationMode>(
    ValuationMode.WEIGHT,
  );
  const [years, setYears] = useState(10);

  const {
    updatedAt,
    blended: {
      original: { wholistic: wholisticOriginal },
    },
  } = allocationProposal;

  const toggleValuationMode = () => {
    if (valuationMode === ValuationMode.WEIGHT) {
      setValuationMode(ValuationMode.DOLLAR);
      return;
    }
    if (valuationMode === ValuationMode.DOLLAR) {
      setValuationMode(ValuationMode.WEIGHT);
      return;
    }
    throw new Error('Unreachable', { cause: valuationMode });
  };

  const financialAdvisorPerson = getPerson(financialAdvisor);

  return (
    <Container css={{ gap: '$48' }}>
      <Row css={{ jc: 'space-between', ai: 'center', gap: '$40' }}>
        <EditableSectionTitle title={title} />

        <Flex
          css={{
            p: '$8 $10',
            height: 'fit-content',
            bg: '$gray2',
            br: '$6',
            ai: 'center',
            jc: 'center',
          }}
        >
          <Text size="13" color="gray11">
            Data imported{' '}
            {DateUtils.formatToStr(
              new Date(updatedAt),
              false,
              MOMENT_FULL_DATETIME_STR,
            )}
          </Text>
        </Flex>
      </Row>

      <Row css={{ ai: 'center', gap: '$20' }}>
        <Col css={{ flex: '0 0 50%', gap: '$16' }}>
          <Col css={{ gap: '$12' }}>
            <Row
              css={{
                w: '280px',
                jc: 'space-between',
                ai: 'center',
                gap: '$20',
              }}
            >
              <Tooltip>
                <Tooltip.Trigger>
                  <Row css={{ ai: 'center', gap: '$6' }}>
                    <EditableTextArea
                      propertyKey="totalAssetsHeading"
                      value={`Total assets.`}
                      textCss={{
                        fontSize: '$13',
                        color: '$gray9',
                      }}
                    />

                    <InformationIcon size={14} />
                  </Row>
                </Tooltip.Trigger>

                <Tooltip.Content
                  css={{
                    bg: '$gray0',
                    boxShadow: '$small',
                  }}
                  hideArrow
                >
                  <Text size="12">
                    Total assets in this proposal may exclude certain accounts
                    or holdings
                  </Text>
                </Tooltip.Content>
              </Tooltip>

              <Switcher value={valuationMode} onChange={toggleValuationMode}>
                <Switcher.List>
                  <Switcher.Trigger value={ValuationMode.WEIGHT}>
                    %
                  </Switcher.Trigger>
                  <Switcher.Trigger value={ValuationMode.DOLLAR}>
                    $
                  </Switcher.Trigger>
                </Switcher.List>
              </Switcher>
            </Row>

            <Box css={{ h: '1px', w: '280px', bg: '$gray4' }} />
          </Col>

          <Text size="24" weight="bold">
            $
            {UiUtils.nFormatter(
              AllocationProposal.unomittedNetworth(allocationProposal),
            )}
          </Text>
        </Col>

        <Col css={{ flex: '0 0 50%', gap: '$28' }}>
          <EditableTextArea
            propertyKey="introText"
            value={`The below represents the zoomed-out view of your entire net worth prior to making any of our proposed changes.`}
            textCss={{
              fontSize: '$14',
              w: '100%',
            }}
          />
          <EditablePerson
            propertyKey="financialAdvisor"
            propertyValue={financialAdvisor}
            person={financialAdvisorPerson}
            size="small"
            level="document"
          />
        </Col>
      </Row>

      <InitialAllocationBreakdown
        allocationProposal={allocationProposal}
        valuationMode={valuationMode}
      />

      <Row css={{ minHeight: '260px', gap: '$48' }}>
        <Col css={{ flex: '0 0 65%', gap: '$12' }}>
          <Row css={{ pr: '148px', jc: 'space-between', ai: 'center' }}>
            <Text size="15" weight="medium">
              Projected returns
            </Text>

            <Row css={{ ai: 'center', gap: '$8' }}>
              <TimeRange<number>
                options={TIME_RANGE_PERFORMANCE_CHART_OPTIONS.filter(
                  (option) => option.value < 50,
                ).map((timeRangePerformanceChartOption) => ({
                  ...timeRangePerformanceChartOption,
                  isSelected: timeRangePerformanceChartOption.value === years,
                }))}
                onChange={setYears}
              />
            </Row>
          </Row>

          <PerformanceChart
            years={years}
            blendedAllocationProposals={[
              { id: 'original', blended: wholisticOriginal },
            ]}
            grid={({ height, width, points }) => (
              <LineGrid height={height} width={width} points={points} />
            )}
          />
        </Col>

        <Col
          css={{
            w: '280px',
            gap: '$24',
          }}
        >
          <Col css={{ gap: '$16' }}>
            <Col css={{ gap: '$4' }}>
              <Text size="13" weight="medium" color="gray9">
                Forward Projected Risk & Return
              </Text>

              <Box css={{ h: '1px', w: '280px', bg: '$gray4' }} />
            </Col>

            <Row css={{ jc: 'space-between' }}>
              <Text size="13" weight="medium">
                Annual Return
              </Text>
              <Text size="13" weight="medium">
                <Percentage percentage={wholisticOriginal.annualReturn} />
              </Text>
            </Row>
            <Row css={{ jc: 'space-between' }}>
              <Text size="13" weight="medium">
                Annual Volatility
              </Text>
              <Text size="13" weight="medium">
                <Percentage percentage={wholisticOriginal.annualVolatility} />
              </Text>
            </Row>
          </Col>

          {(singleStockExposureVisibility === 'visible' || isEditor) && (
            <Col css={{ gap: '$16' }}>
              <Col
                css={{
                  gap: '$4',
                }}
              >
                <Row css={{ ai: 'center', gap: '$8' }}>
                  {isEditor && (
                    <Button
                      variant="internal"
                      css={{
                        h: '24px',
                        w: '24px',
                        display: 'grid',
                        placeItems: 'center',
                      }}
                      onClick={() => {
                        if (singleStockExposureVisibility === 'visible')
                          setVisibility({
                            ...visibility,
                            singleStockExposure: 'hidden',
                          });
                        if (singleStockExposureVisibility === 'hidden')
                          setVisibility({
                            ...visibility,
                            singleStockExposure: 'visible',
                          });
                      }}
                    >
                      {singleStockExposureVisibility === 'visible' && (
                        <EyeIcon size={18} />
                      )}
                      {singleStockExposureVisibility === 'hidden' && (
                        <EyeHideIcon size={18} />
                      )}
                    </Button>
                  )}

                  <Text size="13" weight="medium" color="gray9">
                    Exposure
                  </Text>
                </Row>

                {singleStockExposureVisibility === 'hidden' && (
                  <Text size="11" color="gray9">
                    Hidden to clients
                  </Text>
                )}

                <Box css={{ h: '1px', w: '280px', bg: '$gray4' }} />
              </Col>

              <Row css={{ jc: 'space-between' }}>
                <Text size="13" weight="medium">
                  Single Stock Exposure
                </Text>

                <Text size="13" weight="medium">
                  <Percentage
                    percentage={wholisticOriginal.singleStockExposure}
                  />
                </Text>
              </Row>
            </Col>
          )}
        </Col>
      </Row>

      <Flex css={{ jc: 'end', ai: 'center' }}>
        <ReturnAndVolatilityDisclaimer
          trigger={
            <Row css={{ ai: 'center', gap: '$4' }}>
              <Text size="12" color="gray9">
                Capital market assumptions
              </Text>

              <ModalIcon
                css={{ cursor: 'help' }}
                size={16}
                color="var(--colors-gray9)"
              />
            </Row>
          }
        />
      </Flex>
    </Container>
  );
}

export default InitialAllocation;
