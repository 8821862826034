import { CSS } from '@compoundfinance/design-system/dist/stitches.config';
import React, { useContext } from 'react';

import { Col, Row, Text } from '@compoundfinance/design-system';

import DateUtils from '@compoundfinance/compound-core/dist/date-utils';
import { MOMENT_MO_DAY_YEAR_DATE_STR } from 'utils/constants/date';

import { NumberUtils } from '../../../../../utils/NumberUtils';
import { AllocationProposalTypes } from '../../domain/AllocationProposal';

import AllocationBarChart from './AllocationBarChart';
import { ReturnAndVolatilityDisclaimer } from '../ReturnAndVolatilityDisclaimer';

const AllocationBarChartContext = React.createContext<
  | null
  | (AllocationProposalTypes.BlendendAllocationProposal &
      Omit<Parameters<typeof AllocationBarChart>[number], 'allocations'>)
>(null);
const useAllocationBarChartContext = () => {
  const allocationBarChartContext = useContext(AllocationBarChartContext);
  if (allocationBarChartContext == null) {
    throw new Error(
      `AllocationBarChatWithMetadata.* must be used within AllocationBarChartWithMetadata.Chart`,
    );
  }
  return allocationBarChartContext;
};

const AllocationBarChartWithMetadata = ({
  blendedAllocationProposal,
  children,
  ...props
}: {
  blendedAllocationProposal: AllocationProposalTypes.BlendendAllocationProposal;
  children: React.ReactNode;
} & Omit<Parameters<typeof AllocationBarChart>[number], 'allocations'>) => {
  return (
    <AllocationBarChartContext.Provider
      value={{ ...blendedAllocationProposal, ...props }}
    >
      {children}
    </AllocationBarChartContext.Provider>
  );
};

const Chart = ({
  ...props
}: Partial<Parameters<typeof AllocationBarChart>[number]>) => {
  const allocationBarChartContext = useAllocationBarChartContext();
  return <AllocationBarChart {...allocationBarChartContext} {...props} />;
};

const Header = ({
  children,
  css,
}: {
  children: ({
    formatDate,
  }: {
    formatDate: (date: Date | string) => string;
  }) => React.ReactNode;
  css?: CSS;
}) => {
  return (
    <Col
      css={{
        p: '$12 $2 $2',
        borderRadius: '$6',
        ...css,
      }}
    >
      <Col css={{ w: '100%', jc: 'center', ai: 'center', gap: '$4' }}>
        {children({
          formatDate: (date: string) =>
            DateUtils.formatToStr(date, true, MOMENT_MO_DAY_YEAR_DATE_STR),
        })}
      </Col>
    </Col>
  );
};

const Projections = ({
  children = () => null,
}: {
  children?: (
    blendedAllocationProposal: AllocationProposalTypes.BlendendAllocationProposal,
  ) => React.ReactNode;
}) => {
  const allocationBarChartContext = useAllocationBarChartContext();
  const { annualReturn, annualVolatility, singleStockExposure } =
    allocationBarChartContext;
  return (
    <Col
      css={{
        p: '$12',
        bg: '$gray3',
        borderRadius: '$6',
        gap: '$2',
      }}
    >
      <Row css={{ ai: 'center', gap: '$8' }}>
        <Text size="13" weight="medium">
          Projections
        </Text>

        <ReturnAndVolatilityDisclaimer />
      </Row>

      <Col>
        <Row
          css={{
            ai: 'center',
            jc: 'space-between',
            gap: '$12',
            overflow: 'hidden',
          }}
        >
          <Text
            size="12"
            color="gray10"
            css={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            Annual Return
          </Text>
          <Text size="12">
            {NumberUtils.sanitize(annualReturn, 'Display')}%
          </Text>
        </Row>
        <Row
          css={{
            ai: 'center',
            jc: 'space-between',
            gap: '$12',
            overflow: 'hidden',
          }}
        >
          <Text
            size="12"
            color="gray10"
            css={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            Annual Volatility
          </Text>
          <Text size="12">
            {NumberUtils.sanitize(annualVolatility, 'Display')}%
          </Text>
        </Row>
        <Row
          css={{
            ai: 'center',
            jc: 'space-between',
            gap: '$12',
            overflow: 'hidden',
          }}
        >
          <Text
            size="12"
            color="gray10"
            css={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            Single Stock Exposure
          </Text>
          <Text size="12">
            {NumberUtils.sanitize(singleStockExposure, 'Display')}%
          </Text>
        </Row>

        {children(allocationBarChartContext)}
      </Col>
    </Col>
  );
};

AllocationBarChartWithMetadata.Chart = Chart;
AllocationBarChartWithMetadata.Header = Header;
AllocationBarChartWithMetadata.Projections = Projections;

export default AllocationBarChartWithMetadata;
