import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';
import { Box, Col, Text } from '@compoundfinance/design-system';
import {
  DirectoryPerson,
  DirectoryPersonRole,
} from 'domain/Employees/constants';
import { getPerson } from 'containers/Dashboard/Document/utils';
import { IPerson } from 'domain/Employees/types';
import EditablePerson from 'containers/Dashboard/Document/components/EditablePerson';
import { PersonSize } from 'containers/Dashboard/Document/components/Person';
import EditableTextArea from 'containers/Dashboard/Document/components/EditableTextArea';
import Roadmap from './Roadmap';
// import { CompoundRoutes } from 'utils/constants/url';
// import { useHistory } from 'react-router-dom';

const Container = styled(ModuleContainer, {
  p: '$24',

  '@bp1': {
    py: '$44',
    px: '$48',
  },
});

const GridThirds = styled(Box, {
  display: 'grid',
  gap: '$24',
  position: 'relative',
  '@bp1': {
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '$48',
  },
});

const RoadmapContainer = styled(Box, {
  width: '100%',
  position: 'relative',
});

export interface VisualizeYourFinancesProps {
  salesLead: DirectoryPerson;
}

function VisualizeYourFinances(props: VisualizeYourFinancesProps) {
  const { salesLead } = props;

  const salesLeadPerson = getPerson(salesLead) as IPerson;

  //   const history = useHistory();
  //   const handleBeginOnboarding = () => {
  //     history.push({
  //       pathname: CompoundRoutes.Accounts,
  //       search: '?onboarding=true',
  //     });
  //   };

  return (
    <Container>
      <Col css={{ gap: '$24', '@bp1': { gap: '$48' } }}>
        <Text
          weight="medium"
          color="gray8"
          css={{
            fontSize: '$20',
            letterSpacing: '-0.8px',
            flexShrink: 0,

            '@bp1': { fontSize: '$48', lineHeight: '58px' },
          }}
        >
          Visualize your finances
        </Text>
        <GridThirds>
          <Col
            css={{
              gap: '$24',
              minHeight: '400px',
              '@bp1': { gridColumn: '1' },
            }}
          >
            <Col css={{ gap: '$8' }}>
              <Text size="14" css={{ w: '100%' }}>
                <EditableTextArea
                  propertyKey="visualizeYourFinancesText"
                  value={`Compound’s dashboard interprets data from across your financial life, including brokerage accounts, equity options, crypto, and real estate for a comprehensive view into where your wealth is allocated. \n\nDuring the initial consultation, your membership consultant ${
                    salesLeadPerson.name.split(' ')[0]
                  } will review your finances to provide actionable insights and introduce you to the advisor with specialties that fit your wealth profile.`}
                />
              </Text>
              <EditablePerson
                propertyKey="salesLead"
                propertyValue={salesLead}
                person={salesLeadPerson}
                placeholder="Select Membership Consultant"
                personRole={DirectoryPersonRole.MembershipConsultant}
                size={PersonSize.Small}
              />
            </Col>

            {/* removing for now, will add back in later when decisions are made for onboarding behavior
              around both users and leads and what state they are in the onboarding process as well as if
              they have signup links or not (leads) */}
            {/* <Col css={{ gap: '$8', ai: 'center' }}>
              <Button onClick={handleBeginOnboarding} css={{ w: '100%' }}>
                Begin onboarding
              </Button>
              <Text size="14" color="gray10">
                Onboarding requires ~8 minutes
              </Text>
            </Col> */}
          </Col>
          <Col
            css={{
              gap: '$16',
              '@bp1': {
                gridColumn: '2 / 4',
                position: 'absolute',
                right: '-48px',
                bottom: '-44px',
                w: '100%',
              },
            }}
          >
            <RoadmapContainer>
              <Roadmap size="100%" />
            </RoadmapContainer>
          </Col>
        </GridThirds>
      </Col>
    </Container>
  );
}

export default VisualizeYourFinances;
