import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import { IFinancialAdvisor } from 'domain/Employees/types';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';
import { Box, Col, Grid, Row, Text } from '@compoundfinance/design-system';
import { getPerson } from 'containers/Dashboard/Document/utils';
import EditablePerson from 'containers/Dashboard/Document/components/EditablePerson';
import EditableTextArea from 'containers/Dashboard/Document/components/EditableTextArea';
import { DirectoryPerson } from 'domain/Employees/constants';

const Container = styled(ModuleContainer, {
  p: '$24',

  '@bp1': {
    py: '$44',
    px: '$48',
  },
});

const Grid2x2 = styled(Box, {
  display: 'grid',
  gap: '$12',

  '@bp1': {
    gridTemplateColumns: '1fr 1fr',
    gridRow: 'auto auto',
    gap: '$24',
  },
});

export interface CashManagementProps {
  financialAdvisor: DirectoryPerson;
}

function CashManagement(props: CashManagementProps) {
  const { financialAdvisor } = props;

  const financialAdvisorPerson = getPerson(
    financialAdvisor,
  ) as IFinancialAdvisor;

  return (
    <Container>
      <Col css={{ gap: '$48' }}>
        <Grid
          css={{
            gap: '$24',
            '@bp1': { gridTemplateColumns: '1fr 1fr', gap: '$48' },
          }}
        >
          <Text
            weight="medium"
            color="gray8"
            css={{
              fontSize: '$20',
              letterSpacing: '-0.8px',

              '@bp1': { fontSize: '$48', lineHeight: '58px' },
            }}
          >
            Cash management
          </Text>
          <Col css={{ gap: '$20', w: '100%' }}>
            <Text size="14" css={{ w: '100%' }}>
              <EditableTextArea
                propertyKey="description"
                value={`In today's economic environment, managing your excess cash reserves has become a critical priority. Different individuals and companies use different methods to manage their cash position. Our team implements a customizable approach and recognizes that proper cash management is a key component of wealth management.\n\nTo effectively manage your cash, consider the timing of when you'll need it. Depending upon how much you need and when, investing in different cash equivalents & fixed income instruments may make sense.\n\nA Compound Advisor helps you determine your cash flow and resulting cash management needs. Our strategies can help you reduce excess cash & reach your target allocation. `}
              />
            </Text>
            <EditablePerson
              propertyKey="financialAdvisor"
              propertyValue={financialAdvisor}
              person={financialAdvisorPerson}
              size="small"
            />
          </Col>
        </Grid>

        <Grid2x2>
          <Box
            css={{ border: '1px solid $gray4', p: '$16', borderRadius: '$6' }}
          >
            <Col css={{ gap: '$16' }}>
              <Row css={{ jc: 'space-between' }}>
                <Text color="gray12" weight="medium" size="15">
                  Very near-term
                </Text>
                <Text color="gray10" size="15">
                  3-6 months
                </Text>
              </Row>
              <Box as="ul" css={{ listStylePosition: 'outside', pl: '$12' }}>
                <Box as="li" css={{ listStyleType: 'disc' }}>
                  <Text color="gray11" size="13">
                    Recurring expenses & emergency reserve (3-6 months of living
                    expenses)
                  </Text>
                </Box>
                <Box as="li" css={{ listStyleType: 'disc' }}>
                  <Text color="gray11" size="13">
                    Checking/Savings account up to FDIC insurance limits ($250k
                    for individuals, $500k for couples in joint accounts)
                  </Text>
                </Box>
                <Box as="li" css={{ listStyleType: 'disc' }}>
                  <Text color="gray11" size="13">
                    Expect to earn a zero-to-very-low return on your cash
                    because you prioritized maximum liquidity and low risk.
                  </Text>
                </Box>
              </Box>
            </Col>
          </Box>
          <Box
            css={{ border: '1px solid $gray4', p: '$16', borderRadius: '$6' }}
          >
            <Col css={{ gap: '$16' }}>
              <Row css={{ jc: 'space-between' }}>
                <Text color="gray12" weight="medium" size="15">
                  Near-term
                </Text>
                <Text color="gray10" size="15">
                  6-12 months
                </Text>
              </Row>
              <Box as="ul" css={{ listStylePosition: 'outside', pl: '$12' }}>
                <Box as="li" css={{ listStyleType: 'disc' }}>
                  <Text
                    color="gray11"
                    size="13"
                    css={{ listStyleType: 'disc' }}
                  >
                    Cash needed in the next 6-12 months.
                  </Text>
                </Box>
                <Box as="li" css={{ listStyleType: 'disc' }}>
                  <Text
                    color="gray11"
                    size="13"
                    css={{ listStyleType: 'disc' }}
                  >
                    Within a brokerage account, an allocation to a money market
                    mutual fund (MMMFs).
                  </Text>
                </Box>
                <Box as="li" css={{ listStyleType: 'disc' }}>
                  <Text color="gray11" size="13">
                    Important items to consider: Some MMMFs might have high
                    expense ratios, as well as varying credit quality and
                    borrower types, and enhanced risk profiles.
                  </Text>
                </Box>
              </Box>
            </Col>
          </Box>
          <Box
            css={{ border: '1px solid $gray4', p: '$16', borderRadius: '$6' }}
          >
            <Col css={{ gap: '$16' }}>
              <Row css={{ jc: 'space-between' }}>
                <Text color="gray12" weight="medium" size="15">
                  Medium-term
                </Text>
                <Text color="gray10" size="15">
                  12+ months
                </Text>
              </Row>
              <Box as="ul" css={{ listStylePosition: 'outside', pl: '$12' }}>
                <Box as="li" css={{ listStyleType: 'disc' }}>
                  <Text color="gray11" size="13">
                    If the timing of when the funds will be needed/invested is
                    uncertain, we implement a laddered Treasury Bill strategy.
                  </Text>
                </Box>
                <Box as="li" css={{ listStyleType: 'disc' }}>
                  <Text color="gray11" size="13">
                    This involves purchasing 3, 6, or 12-month T-Bills in your
                    brokerage account for income generation and principal
                    protection.
                  </Text>
                </Box>
              </Box>
            </Col>
          </Box>
          <Box
            css={{ border: '1px solid $gray4', p: '$16', borderRadius: '$6' }}
          >
            <Col css={{ gap: '$16' }}>
              <Row css={{ jc: 'space-between' }}>
                <Text color="gray12" weight="medium" size="15">
                  Long-term
                </Text>
              </Row>
              <Box as="ul" css={{ listStylePosition: 'outside', pl: '$12' }}>
                <Box as="li" css={{ listStyleType: 'disc' }}>
                  <Text color="gray11" size="13">
                    The further out your cash needs are, the more you can
                    incorporate complex fixed income strategies within your
                    portfolio.
                  </Text>
                </Box>
                <Box as="li" css={{ listStyleType: 'disc' }}>
                  <Text color="gray11" size="13">
                    We partner with fixed income specialists that help our
                    clients build diversified bond portfolios according to your
                    investment objectives.
                  </Text>
                </Box>
              </Box>
            </Col>
          </Box>
        </Grid2x2>
      </Col>
    </Container>
  );
}

export default CashManagement;
