import React from 'react';
import { DocumentContext } from '../components/DocumentViewer/DocumentProvider';

function useDocument() {
  const context = React.useContext(DocumentContext);

  if (context === undefined) {
    throw new Error(
      'useDocument must be used within a DocumentProvider component',
    );
  }

  return context;
}

export default useDocument;
