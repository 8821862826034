import { useRef } from 'react';
import { Box } from '@compoundfinance/design-system';
import useAutoScroll, { UseAutoScrollOptions } from 'hooks/useAutoScroll';

type AutoScrollProps = UseAutoScrollOptions;

const AutoScroll: React.FC<AutoScrollProps> = (props) => {
  const { children, ...autoScrollProps } = props;
  const ref = useRef(null);
  useAutoScroll(ref, autoScrollProps);
  return <Box ref={ref}>{children}</Box>;
};

export default AutoScroll;
