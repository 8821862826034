import {
  DocumentModuleGroup,
  DocumentModuleName,
} from 'containers/Dashboard/Document/modules/modules';
import MembershipProposalCashManagement, {
  CashManagementProps as MembershipProposalCashManagementProps,
} from 'containers/Dashboard/Document/modules/MembershipProposal/CashManagement/CashManagement';
import MembershipProposalCustom, {
  CustomProps as MembershipProposalCustomProps,
} from 'containers/Dashboard/Document/modules/MembershipProposal/Custom/Custom';
import MembershipProposalIntroduction, {
  IntroductionProps as MembershipProposalIntroductionProps,
} from 'containers/Dashboard/Document/modules/MembershipProposal/Introduction/Introduction';
import MembershipProposalPricing, {
  PricingProps as MembershipProposalPricingProps,
} from 'containers/Dashboard/Document/modules/MembershipProposal/Pricing/Pricing';
import MembershipProposalPricingV2, {
  PricingProps as MembershipProposalPricingV2Props,
} from 'containers/Dashboard/Document/modules/MembershipProposal/PricingV2/PricingV2';
import MembershipProposalYourTeam, {
  YourTeamProps as MembershipProposalYourTeamProps,
} from 'containers/Dashboard/Document/modules/MembershipProposal/YourTeam/YourTeam';
import MembershipProposalServices, {
  ServicesProps as MembershipProposalServicesProps,
} from 'containers/Dashboard/Document/modules/MembershipProposal/Services/Services';
import MembershipProposalNextSteps, {
  NextStepsProps as MembershipProposalNextStepsProps,
} from 'containers/Dashboard/Document/modules/MembershipProposal/NextSteps/NextSteps';
import MembershipProposalEquityStrategies, {
  EquityStrategiesProps as MembershipProposalEquityStrategiesProps,
} from 'containers/Dashboard/Document/modules/MembershipProposal/EquityStrategies/EquityStrategies';
import MembershipProposalTargetAssetAllocation, {
  TargetAssetAllocationProps as MembershipProposalTargetAssetAllocationProps,
} from 'containers/Dashboard/Document/modules/MembershipProposal/TargetAssetAllocation/TargetAssetAllocation';
import MembershipProposalInvestmentManagement, {
  InvestmentManagementProps as MembershipProposalInvestmentManagementProps,
} from 'containers/Dashboard/Document/modules/MembershipProposal/InvestmentManagement/InvestmentManagement';
import MembershipProposalNetWorth, {
  NetWorthProps as MembershipProposalNetWorthProps,
} from 'containers/Dashboard/Document/modules/MembershipProposal/NetWorth/NetWorth';
import MembershipProposalPrivateMarketDeals from 'containers/Dashboard/Document/modules/MembershipProposal/PrivateMarketDeals/PrivateMarketDeals';
import { PrivateMarketDealsProps } from 'containers/Dashboard/Document/modules/Shared/PrivateMarketDeals';

const membershipProposalModules = {
  [`${DocumentModuleGroup.MembershipProposal}.${DocumentModuleName.MembershipProposal_Introduction}`]:
    (properties: MembershipProposalIntroductionProps) => (
      <MembershipProposalIntroduction {...properties} />
    ),
  [`${DocumentModuleGroup.MembershipProposal}.${DocumentModuleName.MembershipProposal_YourTeam}`]:
    (properties: MembershipProposalYourTeamProps) => (
      <MembershipProposalYourTeam {...properties} />
    ),
  [`${DocumentModuleGroup.MembershipProposal}.${DocumentModuleName.MembershipProposal_Custom}`]:
    (properties: MembershipProposalCustomProps) => (
      <MembershipProposalCustom {...properties} />
    ),
  [`${DocumentModuleGroup.MembershipProposal}.${DocumentModuleName.MembershipProposal_Pricing}`]:
    (properties: MembershipProposalPricingProps) => (
      <MembershipProposalPricing {...properties} />
    ),
  [`${DocumentModuleGroup.MembershipProposal}.${DocumentModuleName.MembershipProposal_PricingV2}`]:
    (properties: MembershipProposalPricingV2Props) => (
      <MembershipProposalPricingV2 {...properties} />
    ),
  [`${DocumentModuleGroup.MembershipProposal}.${DocumentModuleName.MembershipProposal_CashManagement}`]:
    (properties: MembershipProposalCashManagementProps) => (
      <MembershipProposalCashManagement {...properties} />
    ),
  [`${DocumentModuleGroup.MembershipProposal}.${DocumentModuleName.MembershipProposal_Services}`]:
    (properties: MembershipProposalServicesProps) => (
      <MembershipProposalServices {...properties} />
    ),
  [`${DocumentModuleGroup.MembershipProposal}.${DocumentModuleName.MembershipProposal_NextSteps}`]:
    (properties: MembershipProposalNextStepsProps) => (
      <MembershipProposalNextSteps {...properties} />
    ),
  [`${DocumentModuleGroup.MembershipProposal}.${DocumentModuleName.MembershipProposal_EquityStrategies}`]:
    (properties: MembershipProposalEquityStrategiesProps) => (
      <MembershipProposalEquityStrategies {...properties} />
    ),
  [`${DocumentModuleGroup.MembershipProposal}.${DocumentModuleName.MembershipProposal_TargetAssetAllocation}`]:
    (properties: MembershipProposalTargetAssetAllocationProps) => (
      <MembershipProposalTargetAssetAllocation {...properties} />
    ),
  [`${DocumentModuleGroup.MembershipProposal}.${DocumentModuleName.MembershipProposal_InvestmentManagement}`]:
    (properties: MembershipProposalInvestmentManagementProps) => (
      <MembershipProposalInvestmentManagement {...properties} />
    ),
  [`${DocumentModuleGroup.MembershipProposal}.${DocumentModuleName.MembershipProposal_NetWorth}`]:
    (properties: MembershipProposalNetWorthProps) => (
      <MembershipProposalNetWorth {...properties} />
    ),
  [`${DocumentModuleGroup.MembershipProposal}.${DocumentModuleName.MembershipProposal_PrivateMarketDeals}`]:
    (properties: PrivateMarketDealsProps) => (
      <MembershipProposalPrivateMarketDeals {...properties} />
    ),
};

export default membershipProposalModules;
