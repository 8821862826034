export const VESTING_SCHEDULE_COUNTERS = [
  { remainder: 1 / 12, denom: 12 },
  { remainder: 1 / 6, denom: 6 },
  { remainder: 1 / 5, denom: 5 },
  { remainder: 1 / 4, denom: 4 },
  { remainder: 1 / 3, denom: 3 },
  { remainder: 1 / 2, denom: 2 },
  { remainder: 2 / 3, denom: 3 },
  { remainder: 3 / 4, denom: 4 },
  { remainder: 4 / 5, denom: 5 },
  { remainder: 5 / 6, denom: 6 },
];

export const isOnboardTestAccount = (email: string) =>
  email.includes('onboarding') && email.endsWith('@withcompound.com');
