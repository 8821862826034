import {
  Institution,
  PlaidAccount,
} from '@compoundfinance/compound-core/dist/types/plaid';
import { AccountSectionItem } from 'types/assets/section';
import AssetUtils from 'utils/assets';
import { CompoundAsset } from 'components/EquityManagement/Asset/types';
import { AssetTypes } from '@compoundfinance/compound-core/dist/types/account';
import moment from 'moment';
import useTypedSelector from 'hooks/typedSelector';
import { PLAID_ACCOUNT_TYPES } from 'shared/plaid/constants';
import { PrivateInvestment } from 'types/equity';
import {
  Grant,
  PrivateEquityAccount,
  PrivateInvestmentProvider,
} from '@compoundfinance/compound-core/dist/types/equity';
import PeAccountUtils from 'utils/peAccount';
import PlaidAccountUtils from 'utils/plaid/accounts';
import { AccountType } from 'containers/Dashboard/Accounts/types';
import { AccountSectionSortOrder } from 'containers/Dashboard/Accounts/constants';
import orderBy from 'lodash/orderBy';
import has from 'lodash/has';

function accountIsPeAccount(account: AccountSectionItem) {
  return 'relationship' in (account as PrivateEquityAccount);
}

function getPlaidAccountTitle(plaidAccount: PlaidAccount) {
  return plaidAccount.label || plaidAccount.officialName || plaidAccount.name;
}

function accountIsManualAsset(account: AccountSectionItem) {
  return 'assetType' in account;
}

function isPlaidAccount(
  account:
    | AccountSectionItem
    | PlaidAccount
    | PrivateEquityAccount
    | CompoundAsset,
) {
  return has(account, 'subtype');
}

function getAssetTypeFromPlaidType(type: string) {
  switch (type) {
    case PLAID_ACCOUNT_TYPES.LOAN: {
      return AssetTypes.Loan;
    }
    case PLAID_ACCOUNT_TYPES.CREDIT: {
      return AssetTypes.CreditCard;
    }
    case PLAID_ACCOUNT_TYPES.DEPOSITORY: {
      return AssetTypes.Cash;
    }
    case PLAID_ACCOUNT_TYPES.INVESTMENT: {
      return AssetTypes.PublicInvestment;
    }
    case PLAID_ACCOUNT_TYPES.CRYPTO: {
      return AssetTypes.Crypto;
    }
    default: {
      return AssetTypes.Other;
    }
  }
}

function getPlaidLastSyncedDate(plaidAccount: PlaidAccount) {
  // If the account has been manually added or we cant find the corresponding integrationo,
  // use the updatedAt field, as the lastSynced field only applies to entities synced w/ a third party provider.
  const isManual = PlaidAccountUtils.isSelfServeProvider(plaidAccount);
  const integration = useTypedSelector(
    (state) => state.integrations.plaidIntegrations,
  ).find((i) => i.id === plaidAccount.integrationId);
  let syncedAt;

  if (!integration || isManual) {
    syncedAt = plaidAccount.updatedAt;
  } else if (moment(integration.createdAt).isAfter(integration.lastSynced)) {
    // Addressing the issue where new plaid integrations on initial render have an erroneous
    // last synced value
    syncedAt = integration.createdAt;
  } else {
    syncedAt = integration.lastSynced;
  }

  return syncedAt as Date;
}

const liabilityTypes = [
  AssetTypes.Loan,
  AssetTypes.CreditCard,
  PLAID_ACCOUNT_TYPES.CREDIT,
  PLAID_ACCOUNT_TYPES.LOAN,
];

function isLiabilityAccount(account: PlaidAccount) {
  return liabilityTypes.includes((account as PlaidAccount).type as AssetTypes);
}

function getCurrentPlaidBalance(plaidAccount) {
  if (isLiabilityAccount(plaidAccount)) {
    return plaidAccount.currentBalance * -1;
  }

  return plaidAccount.currentBalance;
}

export interface CompoundNetWorthAccount {
  assetType: AssetTypes;
  currentBalance: number;
  id: string;
  lastSynced: Date | null;
  name: string;
  type: string;
  originalType?: string;
  institution: Institution | null;
  integrationId: string | null;
  thirdPartyProvider: boolean;
  key?: string;
  provider: string;
  grants?: Grant[];
  relationship?: string | null;
}

const DEFAULT_NET_WORTH_ACCOUNT = {
  assetType: '' as AssetTypes,
  currentBalance: 0,
  id: '',
  lastSynced: null,
  name: '',
  type: '',
  institution: null,
  thirdPartyProvider: false,
  key: '',
  integrationId: null,
  provider: '',
};

/**
 * Function the exposes a common interface for tabular data display of our various
 * net worth account types.
 *
 * We may want to preserve the underlying account in the return object,
 * we can see how necessary it is to get that data in the future...
 *
 * @param account CompoundAsset | PlaidAcccount | PrivateEquityAccount
 */
function normalizeAccountDetails(
  account: AccountSectionItem,
): CompoundNetWorthAccount {
  if (accountIsPeAccount(account)) {
    const peAccount = account as PrivateEquityAccount;

    return {
      ...peAccount,
      id: account.id as string,
      name: account.name as string,
      currentBalance: account.currentBalance,
      type: AccountType.Equity,
      lastSynced: PeAccountUtils.getLastSyncedDate(
        peAccount as PrivateEquityAccount,
      ),
      institution: null,
      assetType: AssetTypes.StartupEquity,
      key: account.key || account.name,
      thirdPartyProvider: PeAccountUtils.isProviderAuthAccount(peAccount),
      integrationId: null,
      provider: peAccount.provider,
    };
  }

  if (isPlaidAccount(account)) {
    const plaidAccount = account as PlaidAccount;
    const balance = getCurrentPlaidBalance(plaidAccount);
    return {
      id: account.id as string,
      name: getPlaidAccountTitle(plaidAccount),
      currentBalance: balance,
      type: plaidAccount.type,
      originalType: plaidAccount.originalType,
      lastSynced: getPlaidLastSyncedDate(plaidAccount),
      institution: plaidAccount.institution,
      assetType: getAssetTypeFromPlaidType(plaidAccount.type),
      key: account.id as string,
      thirdPartyProvider: !PlaidAccountUtils.isSelfServeProvider(plaidAccount),
      integrationId: plaidAccount.integrationId,
      provider: plaidAccount.provider,
    };
  }

  if (accountIsManualAsset(account)) {
    const asset = account as CompoundAsset;

    const assetType = account.assetType as AssetTypes;

    let thirdPartyProvider = false;
    if ((assetType as AssetTypes) === AssetTypes.PrivateInvestment) {
      const curProvider = (asset as PrivateInvestment).provider;
      thirdPartyProvider = curProvider === PrivateInvestmentProvider.Carta;
    }

    // Manual assets may be liabilities, and `getAssetValue` does not perform any transformations
    // of the asset's balance into a negative number. We multiply liabilities by -1 here because
    // when we display them in the asset account tables, we want to show them as negative numbers
    const currentBalance = AssetUtils.isLiability(assetType)
      ? account.currentBalance * -1
      : AssetUtils.getAssetValue(asset);

    return {
      id: account.id as string,
      name: AssetUtils.getAssetName(asset),
      currentBalance,
      type: assetType as string,
      lastSynced: asset.updatedAt as Date,
      institution: null,
      assetType: assetType as AssetTypes,
      key: account.id as string,
      thirdPartyProvider,
      integrationId: null,
      provider: '',
    };
  }

  return { ...DEFAULT_NET_WORTH_ACCOUNT };
}

const sortAccountSectionItems = (
  items: AccountSectionItem[],
  sortOrder: AccountSectionSortOrder = AccountSectionSortOrder.ValueDescending,
) => {
  switch (sortOrder) {
    case AccountSectionSortOrder.ValueAscending:
      return orderBy(items, (a) => a.currentBalance, 'asc');
    case AccountSectionSortOrder.ValueDescending:
      return orderBy(items, (a) => a.currentBalance, 'desc');
    case AccountSectionSortOrder.NameAscending:
      return orderBy(
        items,
        (a) => a.label?.toLowerCase() || a.name?.toLowerCase(),
        'asc',
      );
    case AccountSectionSortOrder.NameDescending:
      return orderBy(
        items,
        (a) => a.label?.toLowerCase() || a.name?.toLowerCase(),
        'desc',
      );
  }
};

const AccountPageUtils = {
  getAssetTypeFromPlaidType,
  normalizeAccountDetails,
  isPlaidAccount,
  sortAccountSectionItems,
};

export default AccountPageUtils;
