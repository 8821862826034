export enum ProductVisibilitySetting {
  ShowFeature = 'show-feature',
  Hide = 'hide',
}

export enum ProductVisibilityKey {
  Tax = 'tax',
  Estate = 'estate',
  Transactions = 'transactions',
  AdvancedEquityTooling = 'advanced-equity-tooling',
  CompoundFlow = 'compound-flow',
  Documents = 'documents',
  Services = 'services',
}

export type ProductVisibilities = {
  [k in ProductVisibilityKey]: ProductVisibilitySetting;
};
