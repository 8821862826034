import {
  ADD_TOAST_EVENT,
  CLEAR_TOAST_EVENT,
  CLEAR_ALL_TOASTS_EVENTS,
  ADD_NEW_BANK_ACCOUNT_TOAST_EVENT,
  ToastType,
  CLEAR_LATEST_TOAST,
  SHOW_CONTEXT_EVENT,
  HIDE_CONTEXT_EVENT,
  CLEAR_GROUP_TOASTS_EVENTS,
} from './constants';
import { Toast } from './types';
import {
  isToastClosedTwiceInThirtyDays,
  storeToastCancel,
} from './localStorageUtils';
import { AccountSectionItem } from 'types/assets/section';

type TrimmedToast = Omit<Toast, 'id' | 'type'> & { id?: string };

/**
 * Clears the top-most action toast
 */
function clear(toast: Toast, context: string = '') {
  const event = new CustomEvent(`${CLEAR_TOAST_EVENT}${context}`, {
    detail: { toast },
  });
  window.dispatchEvent(event);
}

/**
 * Clears all toasts
 */
function clearAll(context = '') {
  const event = new CustomEvent(`${CLEAR_ALL_TOASTS_EVENTS}${context}`);
  window.dispatchEvent(event);
}

function clearGroup(group, context = '') {
  const event = new CustomEvent(`${CLEAR_GROUP_TOASTS_EVENTS}${context}`, {
    detail: { group },
  });
  window.dispatchEvent(event);
}

function clearLatest(context = '') {
  const event = new CustomEvent(`${CLEAR_LATEST_TOAST}${context}`);
  window.dispatchEvent(event);
}

function newBankAccount(accounts: AccountSectionItem[], providerName: string) {
  const event = new CustomEvent(ADD_NEW_BANK_ACCOUNT_TOAST_EVENT, {
    detail: { accounts, providerName },
  });
  window.dispatchEvent(event);
}

function normal({ context = '', ...content }: TrimmedToast) {
  const event = new CustomEvent(`${ADD_TOAST_EVENT}${context}`, {
    detail: { ...content, type: ToastType.Normal },
  });
  window.dispatchEvent(event);
}

function info({ context = '', ...content }: TrimmedToast) {
  const event = new CustomEvent(`${ADD_TOAST_EVENT}${context}`, {
    detail: { ...content, type: ToastType.Info },
  });
  window.dispatchEvent(event);
}

function action({ context = '', ...content }: TrimmedToast) {
  const event = new CustomEvent(`${ADD_TOAST_EVENT}${context}`, {
    detail: {
      ...content,
      duration: content.duration || false,
      type: ToastType.Action,
    },
  });
  window.dispatchEvent(event);
}

function confirm({ context = '', ...content }: TrimmedToast) {
  const event = new CustomEvent(`${ADD_TOAST_EVENT}${context}`, {
    detail: { ...content, type: ToastType.Success },
  });
  window.dispatchEvent(event);
}

function error({ context = '', ...content }: TrimmedToast) {
  const event = new CustomEvent(`${ADD_TOAST_EVENT}${context}`, {
    detail: { ...content, type: ToastType.Error },
  });
  window.dispatchEvent(event);
}

function warning({ context = '', ...content }: TrimmedToast) {
  const event = new CustomEvent(`${ADD_TOAST_EVENT}${context}`, {
    detail: { ...content, type: ToastType.Warning },
  });
  window.dispatchEvent(event);
}

function demo(
  { context = '', ...content }: Partial<TrimmedToast> = { context: '' },
) {
  info({
    ...content,
    context,
    title: 'Feature disabled',
    description: 'This feature has been turned off during demo mode.',
  });
}

function showContext(context = '') {
  const event = new CustomEvent(`${SHOW_CONTEXT_EVENT}${context}`);
  window.dispatchEvent(event);
}

function hideContext(context = '') {
  const event = new CustomEvent(`${HIDE_CONTEXT_EVENT}${context}`);
  window.dispatchEvent(event);
}

/**
 * Gets an array of values and returns a template string where they're listed in highlights. E.g., toast.confirm(`Grants ${getCommaSeparatedVars(grantNames)}` added to {${grantNames.length}}`, [...grantNames, companyName]); // Would be like 'Grants `{0}`, `{1}`, and `{2}` added to {3}'
 *
 * @param values array of values you want to make a list of
 * @param offset offset from where variable numbering starts. E.g., if we want a template like "Company `{0}` now has grants `{1}`, `{2}`, and `{3}`", offset would be 1
 */
function getCommaSeparatedVars(values: string[], offset: number = 0) {
  if (values.length === 0) {
    return '';
  }
  if (values.length === 1) {
    return values[offset];
  }
  if (values.length === 2) {
    return `${values[offset]} and ${values[offset + 1]}`;
  }
  const template = values
    .slice(0, -1)
    .map((_a, ind) => values[offset + ind])
    .join(', ');
  return template.concat(`, and ${values[offset + values.length - 1]}`);
}

export const toast = {
  normal,
  action,
  confirm,
  info,
  error,
  warning,
  demo,
  clear,
  clearAll,
  clearGroup,
  showContext,
  hideContext,
  newBankAccount,
  getCommaSeparatedVars,
  isToastClosedTwiceInThirtyDays,
  storeToastCancel,
  clearLatest,
};
