import { DirectoryPerson } from 'domain/Employees/constants';
import { ILinkPreview } from 'domain/Employees/types';

import Custom from '../../MembershipProposal/Custom/Custom';

export interface BreckinridgeProps {
  financialAdvisor: DirectoryPerson;
  linkPreviews: ILinkPreview[];
  title: string;
}

function Breckinridge(props: BreckinridgeProps) {
  const { financialAdvisor, linkPreviews, title } = props;

  return (
    <Custom
      title={title}
      customText={`Breckinridge bond portfolios are invested and managed by Breckinridge Capital partners, an investment management firm with over $44 billion in assets under management. Portfolios are typically made up of municipal bonds, which can deliver strong after-tax returns for some investors.`}
      linkPreviews={linkPreviews}
      financialAdvisor={financialAdvisor}
    />
  );
}

export default Breckinridge;
