import { Col, Text } from '@compoundfinance/design-system';

function AboutText() {
  return (
    <Col>
      <Text
        color="gray8"
        css={{
          fontSize: '28px',
          lineHeight: '37px',
          letterSpacing: '-0.8px',
        }}
      >
        <Text
          color="gray12"
          css={{
            fontSize: '28px',
            lineHeight: '37px',
            letterSpacing: '-0.8px',
          }}
        >
          We manage personal finances
        </Text>{' '}
        for startup founders, investors,<br></br>and employees with the same{' '}
        <Text
          color="gray12"
          css={{
            fontSize: '28px',
            lineHeight: '37px',
            letterSpacing: '-0.8px',
          }}
        >
          rigor and sophistication
        </Text>
        <br></br>
        that companies apply to their corporate finances.
      </Text>
    </Col>
  );
}

export default AboutText;
