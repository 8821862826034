import DateUtils from '@compoundfinance/compound-core/dist/date-utils';
import {
  Certificate,
  Grant,
} from '@compoundfinance/compound-core/dist/types/equity';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import CertificateUtils from './certificates';
import GrantUtils from './grant/utils';
import { getSharesVestedByDate, isRSA, isRSU, isRestricted } from './utils';

interface AdjustedCert extends Certificate {
  issueQuantity: number;
}

interface VestedAndCancelledCountConfigs {
  grant: Grant;
  unvestedStopDate: Date;
  isEmploymentEnded: boolean;
}

/**
 * Get vested, unvested, and unvested cancelled counts for a given grant, at the supplied date.
 * unvestedCancelledCount will generally be zero if the user is still employed at their company, or
 * if they were never employees to begin with.
 * @param configs
 */
export function getRawVestedAndCancelledCounts(
  configs: VestedAndCancelledCountConfigs,
) {
  const { grant, unvestedStopDate, isEmploymentEnded } = configs;
  /**
   * Get any unvested options by either:
   *
   * The date from which we want to get a snapshot of grant status
   * The date the user left the company, if applicable
   *
   **/
  const rawUnvestedCount = GrantUtils.getUnvestedCount(grant, unvestedStopDate);

  /* Determine if unvested options should be cancelled.
   * `cancelledCount` is guaranteed to be correct for any
   * options which have not vested by the time the user left the company.
   * Grant.cancelledCount is essentially 'cancelledUnvestedCount'
   */
  const cancelledUnvestedCount = isEmploymentEnded ? grant.cancelledCount : 0;

  /**
   * Subtract the cancelled count, if applicable, from the unvested options.
   * This is the count of truly available unvested options.
   */
  const unvestedCount = Math.max(rawUnvestedCount - cancelledUnvestedCount, 0);

  // Get the number of vested options as though the user is still employed
  const rawVestedCount = (grant.shareCount ?? 0) - rawUnvestedCount;

  return {
    cancelledUnvestedCount,
    rawVestedCount,
    rawUnvestedCount,
    unvestedCount,
  };
}

/**
 * Helper function to get a count of all shares of common and preferred stock a user owns
 * @param certificates
 */
function getPreferredAndCommonCounts(certificates: Certificate[]) {
  return certificates.reduce(
    (memo, cert) => {
      const { remaining, sold } = CertificateUtils.getRemainingQty(cert);

      if (CertificateUtils.isPreferred(cert)) {
        memo.preferredStockCount += remaining;
        memo.soldStockCount += sold;
      } else {
        memo.commonStockCount += remaining;
        memo.soldStockCount += sold;
      }

      return memo;
    },
    {
      commonStockCount: 0,
      preferredStockCount: 0,
      soldStockCount: 0,
    },
  );
}

/**
 * Helper function to sum the value of all preferred and common stock
 * a user owns
 * @param certs
 * @param fmv
 * @param price The optional preferred price of the company to which the certificates are associated
 */
function getPreferredAndCommonTotals(
  certs: Certificate[],
  fmv: number,
  price?: number,
) {
  return certs.reduce(
    (memo, cert) => {
      const { remaining, sold } = CertificateUtils.getRemainingQty(cert);

      if (CertificateUtils.isPreferred(cert)) {
        memo.preferredStockTotal += remaining * (price || fmv);
        memo.soldStockTotal += sold * (price || fmv);
      } else {
        memo.commonStockTotal += remaining * fmv;
        memo.soldStockTotal += sold * fmv;
      }
      return memo;
    },
    {
      preferredStockTotal: 0,
      commonStockTotal: 0,
      soldStockTotal: 0,
    },
  );
}

interface GrantBreakdownProps {
  grant: Grant;
  valuations: { fmv: number; preferredPrice: number };
  targetDate?: Date;
  endDate?: Date | null;
  grantCertificates?: Certificate[];
  provider?: string;
}

function getGrantBreakdown(configs: GrantBreakdownProps) {
  const {
    grant: grantWithoutStart,
    valuations,
    targetDate,
    endDate,
    grantCertificates = [],
  } = configs;

  // If vestingStartDate is missing, fill it out using vestEvents
  const vestingStartDate =
    grantWithoutStart.vestingStartDate ||
    orderBy(grantWithoutStart.vestEvents ?? [], 'date', 'asc')[0]?.date;
  const grant = { ...grantWithoutStart, vestingStartDate };

  const { fmv, preferredPrice } = valuations;

  const today = DateUtils.startOfDayNative();
  const safeTargetDate = targetDate ? new Date(targetDate) : new Date(today);

  const employmentEndDate = DateUtils.getMomentCompatibleNativeDate(endDate);
  const isEmploymentEnded =
    (employmentEndDate &&
      employmentEndDate.getTime() <= safeTargetDate.getTime()) ??
    false;

  let exerciseWindowEnd = DateUtils.getMomentCompatibleNativeDate(endDate);
  if (exerciseWindowEnd) {
    exerciseWindowEnd.setDate(
      exerciseWindowEnd.getDate() +
        GrantUtils.getExerciseWindow(grant, endDate || safeTargetDate),
    );
  }

  /**
   * In the event a user has left their company, we may need to remove cancelled options
   * from their total option counts.
   *
   * Because we keep a count and value of a user's unvested options, any cancelled options
   * must be subtracted from that unvested count. Otherwise, a user may think their stock has
   * more value than it does.
   *
   * Options which have vested but are unexercised will also be cancelled, but not
   * until the end of the exercise window — a period of time (typically 90 days) in which
   * and employee can exercise their vested options after leaving the company
   *
   * RSUs are exempt from this as they are not exercised, once they vest, you own them.
   * We are including RSAs here as well, because we assume they were purchased under an 83(b) election,
   * and are therefore owned when vested.
   */
  const shouldCancelVested =
    safeTargetDate &&
    exerciseWindowEnd &&
    safeTargetDate.getTime() > exerciseWindowEnd.getTime() &&
    !isRestricted(grant);

  // The date to stop counting how many options have vested
  const unvestedStopDate =
    employmentEndDate && safeTargetDate.getTime() >= employmentEndDate.getTime()
      ? new Date(employmentEndDate)
      : safeTargetDate;

  const {
    unvestedCount,
    cancelledUnvestedCount,
    rawUnvestedCount,
    rawVestedCount,
  } = getRawVestedAndCancelledCounts({
    grant,
    unvestedStopDate,
    isEmploymentEnded,
  });

  const { earlyExerciseCount, exerciseCount } = GrantUtils.getExercisedCounts({
    grant,
    unvested: rawUnvestedCount,
    date: safeTargetDate,
  });
  // Since RSAs are assumed to be fully purchased as a result of an
  // 83(b) election, we know that anything exercised, that isn't 'early',
  // must have vested. We don't need to check for RSUs here since their exercise
  // counts will always be zero.
  // It's important to remember that RSAs are not actually exercised, but we can
  // model their early purchase in the same way
  const lastVestDate = employmentEndDate
    ? new Date(Math.min(safeTargetDate.getTime(), employmentEndDate.getTime()))
    : safeTargetDate;
  const vestedAfterExercise = isRSA(grant)
    ? getSharesVestedByDate(grant.vestEvents, lastVestDate)
    : Math.max(rawVestedCount - exerciseCount - earlyExerciseCount, 0);

  const availableVestedCount = shouldCancelVested ? 0 : vestedAfterExercise;

  const cancelledCount =
    cancelledUnvestedCount + (shouldCancelVested ? vestedAfterExercise : 0);

  const availableVestedTotal =
    availableVestedCount * GrantUtils.getBargainElement(grant, fmv);
  const unvestedTotal =
    unvestedCount * GrantUtils.getBargainElement(grant, fmv);

  /**
   * Generate a virtual cert to represent all vested shares
   * associated with this grant.
   *
   * This should result in an accurate valuation, becuase all owned stock
   * is always going to be worth the current valuation of the company
   **/
  const rsuCert =
    isRSU(grant) && !grantCertificates.find((c) => c.grantId === grant.id)
      ? [
          CertificateUtils.buildCert(
            grant,
            valuations.fmv,
            availableVestedCount,
            { issueDate: safeTargetDate },
          ) as Certificate,
        ]
      : [];
  // Certificates should only be counted as owned stock when their issue date falls
  // on or before the date upon which we are calculating this grant's breakdown
  // Otherwise, we may display owned currently owned certs as being owned in the past
  const certificatesToUse = [...grantCertificates, ...rsuCert].filter((c) => {
    const issueDate = DateUtils.getMomentCompatibleNativeDate(c.issueDate);
    return issueDate && issueDate.getTime() <= safeTargetDate.getTime();
  });
  let earlyExercisePool = earlyExerciseCount;

  /**
   * This is probably the most complicated step, so here is an outline of the algorithm
   * used to determine how many shares are owned
   *
   * set EARLY_EXERCISE_POOL to the number of early exercised options
   *
   * Loop through each certificate
   *  ensure certificates are sorted in DESCENDING order by issueDate, to fill up early exercises pool w/ the most recent certs
   *  get the max of the remainaing earlyExercisePool and 0
   *
   *  if the certificate was linked via carta / shareworks or is a restricted stock award
   *    if linked via provider
   *      ignore the above numbers and trust the certificate quantity
   *    if restricted stock
   *      use the supplied vested count
   *  otherwise
   *    subtract the early exercise count from the number of shares in the cert
   *    set the quantity of shares to the MAX of the above number, or ZERO
   *
   *  finally, subtract the number of shares determined above from the original shares in the certificate
   *  this number is subtracted from the total number of early exercise options. When this pool reaches
   *  zero, we know all remaining shares are truly owned
   */
  const adjustedCerts = orderBy(certificatesToUse, 'issueDate', 'desc').map(
    (certificate) => {
      const earlyExercised = Math.max(earlyExercisePool, 0);
      const cancelledCount = certificate?.cancelledCount ?? 0;
      const issueQuantity = isRestricted(grant)
        ? availableVestedCount
        : certificate.quantity;
      let qtyWithCancelled = 0;

      if (isRestricted(grant)) {
        qtyWithCancelled = Math.max(availableVestedCount - cancelledCount, 0);
      } else {
        qtyWithCancelled = Math.max(
          certificate.quantity - earlyExercised - cancelledCount,
          0,
        );
      }
      const adjustedCert: AdjustedCert = {
        ...certificate,
        issueQuantity,
        quantity: qtyWithCancelled,
      };

      // This is the number of options to remove from the early exercise pool
      // If the account is a provider account, this number will be zero
      // Carta and shareworks both properly treat early exercised, but unvested
      // options as non-entities, so I believe this is safe to leave.
      const decrementAmount = certificate.quantity - adjustedCert.quantity;

      // Once this pool of early exercised options is exhausted, we know the user owns
      // the remaining shares
      earlyExercisePool -= decrementAmount;

      return adjustedCert;
    },
  );

  // Weed out future sale events from the adjusted certs, so that they're not mistakenly counted
  const adjustedCertsWithoutFutureSaleEvents = adjustedCerts.map(
    (certificate) => {
      return {
        ...certificate,
        userSaleEvents: (certificate.userSaleEvents ?? []).filter(({ date }) =>
          date ? new Date(date).getTime() <= safeTargetDate.getTime() : false,
        ),
      };
    },
  );

  // Get the counts and totals from the adjusted certificates
  const { preferredStockCount, commonStockCount, soldStockCount } =
    getPreferredAndCommonCounts(adjustedCertsWithoutFutureSaleEvents);

  const { preferredStockTotal, commonStockTotal, soldStockTotal } =
    getPreferredAndCommonTotals(
      adjustedCertsWithoutFutureSaleEvents,
      fmv,
      preferredPrice,
    );

  /**
   * Whatever remains in the early exercise pool, after shifting potentially owned
   * options into stock, informs the total value of early exercised options.
   *
   * If the user has left their company, all options are immediately forfeited and
   * we treat this as zero.
   * */
  const eeTotal =
    (isEmploymentEnded ? 0 : earlyExerciseCount) *
    GrantUtils.getBargainElement(grant, fmv);

  // Vested count and owned count should be 1:1 in the case of restricted stock, but a delta may exist
  // based on sold / cancelled quantities. Maxing here as a safety against a negative vested amount
  const finalVestedCount = isRestricted(grant)
    ? Math.max(commonStockCount - availableVestedCount, 0)
    : availableVestedCount;
  const finalVestedTotal = isRestricted(grant)
    ? finalVestedCount * fmv
    : availableVestedTotal;

  return {
    rawEarlyExerciseCount: earlyExerciseCount,
    // TODO: not 100% sure about this. Theoretically, if this is a self serve grant,
    // the EE count will be correct here, and will be zero if enough options have vested.
    // In the case of a carta / shareworks grant, where certificate quantities may reflect
    // equity that was sold, can we be certain that the early exercise total is in fact the actual
    // total? I think so, but leaving a comment in case
    earlyExerciseCount: isEmploymentEnded ? 0 : earlyExerciseCount,
    earlyExerciseTotal: isEmploymentEnded ? 0 : eeTotal,
    exerciseCount,
    rawUnvestedCount,
    unvestedCount,
    unvestedTotal,
    cancelledCount,
    cancelledTotal: cancelledCount * GrantUtils.getBargainElement(grant, fmv),
    rawVestedCount,
    // Number of vested and unexercised options
    vestedCount: finalVestedCount,
    // Value of vested and unexercised options
    vestedTotal: finalVestedTotal,
    commonStockCount,
    commonStockTotal,
    preferredStockCount,
    preferredStockTotal,
    ownedCount: commonStockCount + preferredStockCount,
    soldStockCount,
    soldStockTotal,
    totalCount: grant.shareCount,
    grant,
    adjustedCerts,

    // Improved names for the above properties
    unvestedUnexercisedCount: Math.max(unvestedCount - earlyExerciseCount, 0),
    unvestedExercisedCount: isEmploymentEnded ? 0 : earlyExerciseCount,
    vestedUnexercisedCount: finalVestedCount,
    vestedExercisedCount: exerciseCount,

    // New properties
    exerciseWindowEnd: moment.utc(exerciseWindowEnd),
  };
}

export default getGrantBreakdown;
