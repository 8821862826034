import EditableTextArea from 'containers/Dashboard/Document/components/EditableTextArea';
import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import { EditableSectionTitle } from 'containers/Dashboard/Document/components/SectionTitle';
import {
  Col,
  GearIcon,
  Grid,
  Row,
  Separator,
  Text,
} from '@compoundfinance/design-system';
import { DirectoryPerson } from 'domain/Employees/constants';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';
import { TaxLossHarvestingAccount } from './types';
import TransactionOverview from './TransactionOverview';
import LossChart from './LossChart';
import TaxLossHarvestingTaxRateModal, {
  TaxLossHarvestingTaxRates,
} from './TaxRateModal';
import { useState } from 'react';
import UiUtils from 'utils/ui';
import EditablePerson from 'containers/Dashboard/Document/components/EditablePerson';
import { getPerson } from 'containers/Dashboard/Document/utils';
import { IFinancialAdvisor } from 'domain/Employees/types';

const Container = styled(ModuleContainer, {
  p: '$24',
  h: '100%',

  '@bp1': {
    py: '$44',
    px: '$48',
  },
});

export type TaxLossHarvestingProps = {
  description: string;
  financialAdvisor: DirectoryPerson;
  title: string;
  accounts: TaxLossHarvestingAccount[];
  taxYear: number;
} & TaxLossHarvestingTaxRates;

function TaxLossHarvesting(props: TaxLossHarvestingProps) {
  const {
    description,
    title,
    accounts,
    taxYear,
    financialAdvisor,
    ...taxRates
  } = props;

  const financialAdvisorPerson = getPerson(
    financialAdvisor,
  ) as IFinancialAdvisor;

  const [isTaxRateModalOpen, setIsTaxRateModalOpen] = useState(false);

  const flattenedTransactions = accounts.flatMap(
    (account) => account.transactions,
  );

  const transactionsPerQuarter = {
    q1: flattenedTransactions.filter(
      (transaction) => new Date(transaction.date).getMonth() < 3,
    ),
    q2: flattenedTransactions.filter(
      (transaction) =>
        new Date(transaction.date).getMonth() >= 3 &&
        new Date(transaction.date).getMonth() < 6,
    ),
    q3: flattenedTransactions.filter(
      (transaction) =>
        new Date(transaction.date).getMonth() >= 6 &&
        new Date(transaction.date).getMonth() < 9,
    ),
    q4: flattenedTransactions.filter(
      (transaction) => new Date(transaction.date).getMonth() >= 9,
    ),
  };

  const lossHarvestedPerQuarter = {
    q1: [
      transactionsPerQuarter.q1.reduce(
        (total, transaction) => total - transaction.realizedLTCG ?? 0,
        0,
      ),
      transactionsPerQuarter.q1.reduce(
        (total, transaction) => total - transaction.realizedSTCG ?? 0,
        0,
      ),
    ] as [number, number],
    q2: [
      transactionsPerQuarter.q2.reduce(
        (total, transaction) => total - transaction.realizedLTCG ?? 0,
        0,
      ),
      transactionsPerQuarter.q2.reduce(
        (total, transaction) => total - transaction.realizedSTCG ?? 0,
        0,
      ),
    ] as [number, number],
    q3: [
      transactionsPerQuarter.q3.reduce(
        (total, transaction) => total - transaction.realizedLTCG ?? 0,
        0,
      ),
      transactionsPerQuarter.q3.reduce(
        (total, transaction) => total - transaction.realizedSTCG ?? 0,
        0,
      ),
    ] as [number, number],
    q4: [
      transactionsPerQuarter.q4.reduce(
        (total, transaction) => total - transaction.realizedLTCG ?? 0,
        0,
      ),
      transactionsPerQuarter.q4.reduce(
        (total, transaction) => total - transaction.realizedSTCG ?? 0,
        0,
      ),
    ] as [number, number],
  };

  const shortTermGains = flattenedTransactions.reduce(
    (total, transaction) => total + (transaction.realizedSTCG ?? 0),
    0,
  );

  const longTermGains = flattenedTransactions.reduce(
    (total, transaction) => total + (transaction.realizedLTCG ?? 0),
    0,
  );

  return (
    <>
      <TaxLossHarvestingTaxRateModal
        isTaxRateModalOpen={isTaxRateModalOpen}
        setIsTaxRateModalOpen={setIsTaxRateModalOpen}
      />
      <Container>
        <Col css={{ gap: '$24', height: '100%', mb: '$12' }}>
          <EditableSectionTitle title={title} />
          <Col css={{ height: '100%', jc: 'space-between' }}>
            <EditableTextArea
              propertyKey="description"
              value={description}
              textCss={{
                fontSize: '$15',
                w: '100%',
              }}
            />
            <Grid
              css={{
                marginTop: '$24',
                gap: '$36',
                // Each section takes half the space
                gridTemplateColumns: '1fr 1fr',
              }}
            >
              <Col css={{ ai: 'flex-start' }}>
                <Text size={13} weight="medium">
                  Estimated Tax Savings
                </Text>
                <Text size={32} weight="medium">
                  {UiUtils.formatMoney(
                    (-shortTermGains * taxRates.federalSTCGRate) / 100 +
                      (-longTermGains * taxRates.federalLTCGRate) / 100 +
                      (-shortTermGains * taxRates.stateSTCGRate) / 100 +
                      (-longTermGains * taxRates.stateLTCGRate) / 100,
                  )}
                </Text>
                <Text size={13} color="gray10" css={{ marginTop: '$8' }}>
                  Adjust {taxYear} estimated tax rates
                  <GearIcon onClick={() => setIsTaxRateModalOpen(true)} />
                </Text>
                <Separator css={{ margin: '$16 0 ' }} />
                <Text size={13} color="gray10">
                  Total Realized Losses
                </Text>
                <Text weight="medium" size={26}>
                  {UiUtils.formatMoney(-shortTermGains - longTermGains)}
                </Text>
                <Row
                  css={{
                    marginTop: '$20',
                    gap: '$24',
                    width: '100%',
                  }}
                >
                  <Col css={{ width: '50%', ai: 'flex-start' }}>
                    <Text size={13} color="gray10">
                      Short Term
                    </Text>
                    <Text size={18} weight="medium">
                      {UiUtils.formatMoney(-shortTermGains)}
                    </Text>
                  </Col>
                  <Col css={{ width: '50%', ai: 'flex-start' }}>
                    <Text size={13} color="gray10">
                      Long Term
                    </Text>
                    <Text size={18} weight="medium">
                      {UiUtils.formatMoney(-longTermGains)}
                    </Text>
                  </Col>
                </Row>
              </Col>
              <LossChart
                data={{
                  Q1: lossHarvestedPerQuarter.q1,
                  Q2: lossHarvestedPerQuarter.q2,
                  Q3: lossHarvestedPerQuarter.q3,
                  Q4: lossHarvestedPerQuarter.q4,
                }}
                taxYear={taxYear}
              />
            </Grid>
            <Separator
              css={{
                margin: '$24 0',
              }}
            />
            <TransactionOverview accounts={accounts} taxYear={taxYear} />
          </Col>
        </Col>
        <EditablePerson
          propertyKey="financialAdvisor"
          propertyValue={financialAdvisor}
          person={financialAdvisorPerson}
          size="small"
          level="document"
        />
      </Container>
    </>
  );
}

export default TaxLossHarvesting;
