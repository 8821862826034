import { DirectoryPerson } from 'domain/Employees/constants';
import { ILinkPreview } from 'domain/Employees/types';

import Custom from '../../MembershipProposal/Custom/Custom';

export interface InvestmentStrategyProps {
  financialAdvisor: DirectoryPerson;
  linkPreviews: ILinkPreview[];
}

function InvestmentStrategy(props: InvestmentStrategyProps) {
  const { financialAdvisor, linkPreviews } = props;

  return (
    <Custom
      title="Investment strategy"
      customText={`The goal of the proposal below is to optimize around your liquidity and income needs, your growth expectations, your risk tolerance, and your tax profile. 

Some of our guiding investment principles include:
(A) Diversification helps us manage portfolio risk without sacrificing returns
(B) Risk isn't something to completely avoid, but we should try to right-size it to your needs
(C) Public markets (liquid) are critical for wealth creation, diversification and income. In conjunction, private markets (illiquid) may provide further diversification and potential for higher returns
(D) People in tech have a unique set of needs and goals, and their investment strategy should be customized 
(E) Wherever possible, we should maintain a long-term investment perspective aligned with our goals; look through near-term noise

To design your portfolio, we use a range of analytical tools — like portfolio- and asset-level return and volatility calculations, macroeconomic data, and tax transition analysis. And when it comes to executing on your investment strategy, we’ll invest across multiple asset classes like real estate, venture capital, fixed income, or global equities.`}
      linkPreviews={linkPreviews}
      financialAdvisor={financialAdvisor}
    />
  );
}

export default InvestmentStrategy;
