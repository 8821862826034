import styled from 'styled-components';

import { FlexCol } from 'components/global';
import { CompoundCFill } from 'assets/svg/CompoundC';

const Card = () => (
  <Wrapper>
    <Eclipse />
    <InnerWrapper>
      <FlexCol flex={1} alignItems="center" justifyContent="center">
        <LogoWrapper>
          <CompoundCFill color="white" />
        </LogoWrapper>
        <Heading>Scheduled Maintenance</Heading>
        <Paragraph>
          The team is pushing some exciting new features to the Compound app
        </Paragraph>
      </FlexCol>
      <Note>
        Please ping{' '}
        <a
          href="mailto:concierge@compoundplanning.com"
          style={{ textDecoration: 'underline', color: 'inherit' }}
        >
          concierge@compoundplanning.com
        </a>{' '}
        if you have any urgent questions. The app will be back up at 7AM PT.
      </Note>
    </InnerWrapper>
  </Wrapper>
);

export default Card;

const Wrapper = styled.div`
  position: relative;
`;

const InnerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.off_white};
  border-radius: 20px;
  padding: 1.425rem 4rem 1.5rem;
  margin: 0 1rem;
  text-align: center;
  max-width: 485px;
  height: 500px;
`;

const Heading = styled.h1`
  font-size: 1.75rem;
  letter-spacing: -0.019em;
  font-weight: 700;
  color: #000;
  margin-bottom: 0.4375rem;
`;

const Paragraph = styled.p`
  font-size: 0.875rem;
  color: #6d707b;
  letter-spacing: -0.006em;
  font-weight: 525;
  margin: 0;
  max-width: 325px;
`;

const Note = styled.p`
  font-size: 0.75rem;
  color: #acadb1;
  margin: 0;
`;

const LogoWrapper = styled.div`
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 4rem;
  margin-bottom: 1.5rem;
  background: linear-gradient(42.09deg, #000000 4.59%, #272d3b 94.52%);
  box-shadow: 0px 56px 40px rgba(0, 0, 0, 0.07),
    0px 36.2963px 23.4259px rgba(0, 0, 0, 0.0531481),
    0px 21.5704px 12.7407px rgba(0, 0, 0, 0.0425185),
    0px 11.2px 6.5px rgba(0, 0, 0, 0.035),
    0px 4.56296px 3.25926px rgba(0, 0, 0, 0.0274815),
    0px 1.03704px 1.57407px rgba(0, 0, 0, 0.0168519);
`;

const Eclipse = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.8;
  background: #e68a64;
  filter: blur(80px);
  width: 186px;
  height: 130px;
  border-radius: 9999px;
`;
