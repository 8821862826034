import DocumentsAPI from 'containers/Dashboard/Document/api';
import {
  DocumentModuleGroup,
  DocumentModuleName,
} from 'containers/Dashboard/Document/modules/modules';
import {
  Button,
  Combobox,
  PlusIcon,
  Row,
} from '@compoundfinance/design-system';
import { useRef, useState } from 'react';
import useDocument from '../../hooks/useDocument';

const ADD_MODULE_COMBO_BOX_OPTIONS = [
  {
    heading: 'Membership Proposal',
    children: [
      {
        label: 'Introduction',
        value: `${DocumentModuleGroup.MembershipProposal}.${DocumentModuleName.MembershipProposal_Introduction}`,
      },
      {
        label: 'Your team',
        subtitle: 'Financial advisor & team that the client will work with',
        value: `${DocumentModuleGroup.MembershipProposal}.${DocumentModuleName.MembershipProposal_YourTeam}`,
      },
      {
        label: 'Equity strategies',
        subtitle: 'Craft and execute a plan for your equity',
        value: `${DocumentModuleGroup.MembershipProposal}.${DocumentModuleName.MembershipProposal_EquityStrategies}`,
      },
      {
        label: 'Target asset allocation',
        subtitle: 'An example target asset allocation',
        value: `${DocumentModuleGroup.MembershipProposal}.${DocumentModuleName.MembershipProposal_TargetAssetAllocation}`,
      },
      {
        label: 'Cash management',
        subtitle:
          'How we manage your cash (Very near-term, Near-term, Medium-term, Long-term)',
        value: `${DocumentModuleGroup.MembershipProposal}.${DocumentModuleName.MembershipProposal_CashManagement}`,
      },
      {
        label: 'Investment management',
        subtitle: 'We will develop a holistic investment plan',
        value: `${DocumentModuleGroup.MembershipProposal}.${DocumentModuleName.MembershipProposal_InvestmentManagement}`,
      },
      {
        label: 'Net worth',
        subtitle: 'A snapshot of your net worth',
        value: `${DocumentModuleGroup.MembershipProposal}.${DocumentModuleName.MembershipProposal_NetWorth}`,
      },
      {
        label: 'Private market deals',
        subtitle:
          'Our current offerings for alternative, private market investments',
        value: `${DocumentModuleGroup.MembershipProposal}.${DocumentModuleName.MembershipProposal_PrivateMarketDeals}`,
      },
      {
        label: 'Services',
        subtitle: 'Our services that we offer to clients',
        value: `${DocumentModuleGroup.MembershipProposal}.${DocumentModuleName.MembershipProposal_Services}`,
      },
      {
        label: 'Membership',
        subtitle: 'Our membership fee and AUM model',
        value: `${DocumentModuleGroup.MembershipProposal}.${DocumentModuleName.MembershipProposal_PricingV2}`,
      },
      {
        label: 'Next steps',
        subtitle:
          'What happens after the client signs the membership agreement',
        value: `${DocumentModuleGroup.MembershipProposal}.${DocumentModuleName.MembershipProposal_NextSteps}`,
      },
      {
        label: 'Custom',
        subtitle: 'Add a link to an external document',
        value: `${DocumentModuleGroup.MembershipProposal}.${DocumentModuleName.MembershipProposal_Custom}`,
      },
    ],
  },
  {
    heading: 'Quarterly Performance Update',
    children: [
      {
        label: 'Introduction',
        value: `${DocumentModuleGroup.QuarterlyPerformanceUpdate}.${DocumentModuleName.QuarterlyPerformanceUpdate_Introduction}`,
      },
      {
        label: 'Market overview',
        value: `${DocumentModuleGroup.QuarterlyPerformanceUpdate}.${DocumentModuleName.QuarterlyPerformanceUpdate_MarketOverview}`,
      },
      {
        label: 'Account overview',
        value: `${DocumentModuleGroup.QuarterlyPerformanceUpdate}.${DocumentModuleName.QuarterlyPerformanceUpdate_AccountOverview}`,
      },
      {
        label: 'Tax Loss Harvesting',
        value: `${DocumentModuleGroup.QuarterlyPerformanceUpdate}.${DocumentModuleName.QuarterlyPerformanceUpdate_Tax_Loss_Harvesting}`,
      },
    ],
  },
  {
    heading: 'Asset Allocation Investment Proposal',
    children: [
      {
        label: 'Introduction',
        value: `${DocumentModuleGroup.AssetAllocationInvestmentProposal}.${DocumentModuleName.AssetAllocationInvestmentProposal_Introduction}`,
      },
      {
        label: 'No Text Introduction',
        value: `${DocumentModuleGroup.AssetAllocationInvestmentProposal}.${DocumentModuleName.AssetAllocationInvestmentProposal_NoTextIntroduction}`,
      },
      {
        label: 'Investment Strategy',
        value: `${DocumentModuleGroup.AssetAllocationInvestmentProposal}.${DocumentModuleName.AssetAllocationInvestmentProposal_InvestmentStrategy}`,
      },

      {
        label: 'Impact Summary',
        value: `${DocumentModuleGroup.AssetAllocationInvestmentProposal}.${DocumentModuleName.AssetAllocationInvestmentProposal_ImpactSummary}`,
      },
      {
        label: 'Initial Allocation',
        value: `${DocumentModuleGroup.AssetAllocationInvestmentProposal}.${DocumentModuleName.AssetAllocationInvestmentProposal_InitialAllocation}`,
      },
      {
        label: 'Proposed Allocation',
        value: `${DocumentModuleGroup.AssetAllocationInvestmentProposal}.${DocumentModuleName.AssetAllocationInvestmentProposal_ProposedAllocation}`,
      },
      {
        label: 'Account Movement',
        value: `${DocumentModuleGroup.AssetAllocationInvestmentProposal}.${DocumentModuleName.AssetAllocationInvestmentProposal_AccountMovement}`,
      },

      {
        label: 'Custom Indexing',
        value: `${DocumentModuleGroup.AssetAllocationInvestmentProposal}.${DocumentModuleName.AssetAllocationInvestmentProposal_CustomIndexing}`,
      },
      {
        label: 'Breckinridge',
        value: `${DocumentModuleGroup.AssetAllocationInvestmentProposal}.${DocumentModuleName.AssetAllocationInvestmentProposal_Breckinridge}`,
      },
      {
        label: 'Foundation',
        value: `${DocumentModuleGroup.AssetAllocationInvestmentProposal}.${DocumentModuleName.AssetAllocationInvestmentProposal_Foundation}`,
      },
      {
        label: 'Private Market Deals',
        subtitle:
          'Our current offerings for alternative, private market investments',
        value: `${DocumentModuleGroup.AssetAllocationInvestmentProposal}.${DocumentModuleName.AssetAllocationInvestmentProposal_PrivateMarketDeals}`,
      },
    ],
  },
  {
    heading: 'Sales Introduction',
    children: [
      {
        label: 'Introduction',
        value: `${DocumentModuleGroup.SalesIntroduction}.${DocumentModuleName.SalesIntroduction_Introduction}`,
      },
      {
        label: 'About',
        value: `${DocumentModuleGroup.SalesIntroduction}.${DocumentModuleName.SalesIntroduction_About}`,
      },
      {
        label: 'Initial consultation',
        subtitle: 'Introduction call scheduling',
        value: `${DocumentModuleGroup.SalesIntroduction}.${DocumentModuleName.SalesIntroduction_InitialConsultation}`,
      },
      {
        label: 'Visualize your finances',
        subtitle: 'Dashboard and roadmap information',
        value: `${DocumentModuleGroup.SalesIntroduction}.${DocumentModuleName.SalesIntroduction_VisualizeYourFinances}`,
      },
      {
        label: 'Services',
        subtitle: 'Our services that we offer to clients',
        value: `${DocumentModuleGroup.SalesIntroduction}.${DocumentModuleName.SalesIntroduction_Services}`,
      },
      {
        label: 'Advisory landscape',
        subtitle: 'Comparison of our services to other advisory products',
        value: `${DocumentModuleGroup.SalesIntroduction}.${DocumentModuleName.SalesIntroduction_AdvisoryLandscape}`,
      },
      {
        label: 'Next steps',
        subtitle: 'What happens after the client has their introduction call',
        value: `${DocumentModuleGroup.SalesIntroduction}.${DocumentModuleName.SalesIntroduction_NextSteps}`,
      },
      {
        label: 'Membership',
        subtitle: 'Our membership fee and AUM model: price as minimum',
        value: `${DocumentModuleGroup.SalesIntroduction}.${DocumentModuleName.SalesIntroduction_MembershipV2}`,
      },
    ],
  },
  {
    heading: 'Tax Transition Analysis',
    children: [
      {
        label: 'Introduction',
        value: `${DocumentModuleGroup.TaxTransitionAnalysis}.${DocumentModuleName.TaxTransitionAnalysis_Introduction}`,
      },
      {
        label: 'Accounts',
        value: `${DocumentModuleGroup.TaxTransitionAnalysis}.${DocumentModuleName.TaxTransitionAnalysis_Accounts}`,
      },
    ],
  },
];

interface AddModuleTriggerProps {
  mutateDocument: any;
  alwaysShow?: boolean;
  moduleIdToInsertAfter?: string;
}

function AddModuleTrigger(props: AddModuleTriggerProps) {
  const { mutateDocument, moduleIdToInsertAfter, alwaysShow = false } = props;

  const { document } = useDocument();
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const [isAddingModule, setIsAddingModule] = useState(false);

  return (
    <Row
      css={{ w: '1034px', ai: 'center', jc: 'center', my: '$6' }}
      onMouseOver={() => {
        if (!alwaysShow) {
          if (buttonRef.current?.style) {
            buttonRef.current!.style.visibility = 'visible';
          }
        }
      }}
      onMouseLeave={() => {
        if (!alwaysShow) {
          if (buttonRef.current?.style) {
            buttonRef.current!.style.visibility = 'hidden';
          }
        }
        setIsAddingModule(false);
      }}
    >
      {!isAddingModule && (
        <Button
          ref={buttonRef}
          variant="internal"
          size="small"
          square
          css={{ visibility: alwaysShow ? 'visible' : 'hidden', w: '100%' }}
          onClick={() => {
            setIsAddingModule(true);
          }}
        >
          <PlusIcon />
        </Button>
      )}

      {isAddingModule && (
        <Combobox
          autoFocus
          css={{ w: '100%' }}
          placeholder="Search for a module to add"
          options={ADD_MODULE_COMBO_BOX_OPTIONS}
          popoverSideOffset={1}
          onChange={async (option) => {
            setIsAddingModule(false);

            if (!option) {
              console.error('Invalid option', option);
              return;
            }

            if (!document) {
              console.error('Invalid document', document);
              return;
            }

            await DocumentsAPI.addModuleToDocument({
              documentId: document.id,
              module: option,
              insertAfter: moduleIdToInsertAfter ?? null,
            });

            mutateDocument();
          }}
        />
      )}
    </Row>
  );
}
export default AddModuleTrigger;
