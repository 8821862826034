import { Row, Text } from '@compoundfinance/design-system';

import { Allocation } from 'containers/Dashboard/AllocationProposals/domain/Allocation';
import { NumberUtils } from 'utils/NumberUtils';
import UiUtils from 'utils/ui';

export const Value = ({ value }) => {
  if (value <= 0) return <>$0</>;
  if (value < 0.01) return <>{'$<0.01'}</>;
  return <>${UiUtils.nFormatter(value, 1)}</>;
};

export const Percentage = ({ percentage }) => {
  if (percentage <= 0) return <>N/A</>;
  if (percentage < 0.01) return <>{'<0.01%'}</>;
  return <>{UiUtils.sanitize(percentage)}%</>;
};

export const Weight = ({ weight }) => {
  if (weight <= 0) return <>0%</>;
  if (weight < 0.01) return <>{'<0.01%'}</>;
  return <>{UiUtils.sanitize(weight)}%</>;
};

export const ValueAndPercentageComparison = ({
  original,
  target,
}: {
  original: { value: number; weight: number };
  target: { value: number; weight: number };
}) => {
  const difference = NumberUtils.subtract(
    Allocation.value(original),
    Allocation.value(target),
  );
  return (
    <Row css={{ ai: 'center', gap: '$4' }}>
      <Text size="13" color="gray9">
        <Value value={Allocation.value(original)} />
        (
        <Weight weight={Allocation.weight(original)} />)
      </Text>

      <Text
        size="15"
        weight="medium"
        color={difference === 0 ? 'gray9' : difference > 0 ? 'red11' : 'green9'}
      >
        →
      </Text>

      <Text size="13" weight="medium">
        <Value value={Allocation.value(target)} />
        (
        <Weight weight={Allocation.weight(target)} />)
      </Text>
    </Row>
  );
};

export const PercentageComparison = ({
  original,
  target,
}: {
  original: number;
  target: number;
}) => {
  const difference = NumberUtils.subtract(
    Allocation.weight({ weight: original }),
    Allocation.weight({ weight: target }),
  );
  return (
    <Row css={{ ai: 'center', gap: '$4' }}>
      <Text size="13" color="gray9">
        <Percentage percentage={Allocation.weight({ weight: original })} />
      </Text>

      <Text
        size="15"
        weight="medium"
        color={difference === 0 ? 'gray9' : difference > 0 ? 'red11' : 'green9'}
      >
        →
      </Text>

      <Text size="13" weight="medium">
        <Percentage percentage={Allocation.weight({ weight: target })} />
      </Text>
    </Row>
  );
};
