import {
  useForwardUserId,
  useAuthentication,
} from 'hooks/useAxiosInterceptors';

function AxiosInterceptors({ children }) {
  useForwardUserId();
  useAuthentication();
  return children;
}

export default AxiosInterceptors;
