import React from 'react';

import { Col, Flex, Row, Text } from '@compoundfinance/design-system';

import {
  AllocationProposal,
  AllocationProposalTypes,
} from '../domain/AllocationProposal';
import { StrategicAllocationProposalTypes } from '../domain/Strategic/StrategicAllocationProposal';

import {
  WeightAndValueAllocationBarChartTooltip,
  WeightOnlyAllocationBarChartTooltip,
} from './Charts/AllocationBarChart';
import AllocationBarChartWithMetadata from './Charts/AllocationBarChartWithMetadata';
import AssetsBarChart from './Charts/AssetsBarChart';
import {
  AssetsAndAssetClassesSwitcher,
  AssetsAndAssetClasses,
  AssetsAndAssetClassesType,
} from './AssetsAndAssetClasses';

const InitialVsTargetComparison = ({
  allocationProposal,
  header = null,
  footer = ({ setActiveTab }) => (
    <AssetsAndAssetClassesSwitcher setActiveTab={setActiveTab} />
  ),
  projections = <AllocationBarChartWithMetadata.Projections />,
  barChartHeaders,
}: {
  allocationProposal: AllocationProposalTypes.CategorizedAllocationProposal;
  header?:
    | (({
        setActiveTab,
      }: {
        setActiveTab: React.Dispatch<AssetsAndAssetClassesType>;
      }) => React.ReactNode)
    | React.ReactNode;
  footer?:
    | (({
        setActiveTab,
      }: {
        setActiveTab: React.Dispatch<AssetsAndAssetClassesType>;
      }) => React.ReactNode)
    | React.ReactNode;
  projections?: React.ReactNode;
  barChartHeaders?: {
    source?: ({
      allocationProposal,
    }: {
      allocationProposal: AllocationProposalTypes.CategorizedAllocationProposal;
    }) => React.ReactNode;
    target?: ({
      allocationProposal,
    }: {
      allocationProposal: AllocationProposalTypes.CategorizedAllocationProposal;
    }) => React.ReactNode;
  };
}) => (
  <AssetsAndAssetClasses
    assets={
      AllocationProposal.isStrategic(allocationProposal) ? (
        <InitialVsTargetComparsionLayout
          allocationProposal={allocationProposal}
          source={
            <InitialVsTargetAssets
              allocationProposal={allocationProposal}
              type="original"
            />
          }
          target={
            <InitialVsTargetAssets
              allocationProposal={allocationProposal}
              type="target"
            />
          }
          barChartHeaders={barChartHeaders}
          projections={projections}
        />
      ) : null
    }
    assetClasses={
      <InitialVsTargetComparsionLayout
        allocationProposal={allocationProposal}
        source={<InitialVsTargetAssetClasses />}
        target={<InitialVsTargetAssetClasses />}
        barChartHeaders={barChartHeaders}
        projections={projections}
      />
    }
    header={header}
    footer={AllocationProposal.isStrategic(allocationProposal) ? footer : null}
  />
);

const InitialVsTargetComparsionLayout = ({
  allocationProposal,
  source,
  target,
  projections,
  barChartHeaders: {
    source: sourceHeader = ({
      allocationProposal,
    }: {
      allocationProposal: AllocationProposalTypes.CategorizedAllocationProposal;
    }) => {
      const { original } = allocationProposal;
      return (
        <AllocationBarChartWithMetadata.Header>
          {({ formatDate }) => (
            <Col
              css={{
                w: '100%',
                jc: 'center',
                ai: 'center',
                gap: '$4',
              }}
            >
              <Text size="13" weight="medium">
                Initial Allocation
              </Text>
              <Text size="12" color="gray10">
                Created {formatDate(original.createdAt!)}
              </Text>
            </Col>
          )}
        </AllocationBarChartWithMetadata.Header>
      );
    },
    target: targetHeader = ({
      allocationProposal,
    }: {
      allocationProposal: AllocationProposalTypes.CategorizedAllocationProposal;
    }) => {
      const { original } = allocationProposal;
      return (
        <AllocationBarChartWithMetadata.Header css={{ paddingBottom: '$2' }}>
          {({ formatDate }) => (
            <Col
              css={{
                w: '100%',
                jc: 'center',
                ai: 'center',
                gap: '$4',
              }}
            >
              <Text size="13" weight="medium">
                Target Allocation
              </Text>
              <Text size="12" color="gray10">
                Updated {formatDate(original.updatedAt!)}
              </Text>
            </Col>
          )}
        </AllocationBarChartWithMetadata.Header>
      );
    },
  } = {},
}: {
  allocationProposal: AllocationProposalTypes.CategorizedAllocationProposal;
  source: React.ReactNode;
  target: React.ReactNode;
  projections?: React.ReactNode;
  barChartHeaders?: {
    source?: ({
      allocationProposal,
    }: {
      allocationProposal: AllocationProposalTypes.CategorizedAllocationProposal;
    }) => React.ReactNode;
    target?: ({
      allocationProposal,
    }: {
      allocationProposal: AllocationProposalTypes.CategorizedAllocationProposal;
    }) => React.ReactNode;
  };
}) => {
  const { blended } = allocationProposal;
  const {
    original: { wholistic: wholisticBlendedOriginal },
    target: { wholistic: wholisticBlendedTarget },
  } = blended;

  return (
    <Row css={{ flex: '1 0 auto', gap: '$16' }}>
      <Col css={{ w: '100%', gap: '$8' }}>
        <AllocationBarChartWithMetadata
          blendedAllocationProposal={wholisticBlendedOriginal}
          tooltip={(allocation) =>
            AllocationProposal.isTarget(allocationProposal) ? (
              <WeightOnlyAllocationBarChartTooltip allocation={allocation} />
            ) : AllocationProposal.isStrategic(allocationProposal) ? (
              <WeightAndValueAllocationBarChartTooltip
                allocation={allocation}
              />
            ) : null
          }
        >
          <Col
            css={{
              h: '100%',
              w: '100%',
              p: '$0 $2 $2',
              bg: '$gray3',
              borderRadius: '$6',
            }}
          >
            {sourceHeader({ allocationProposal })}

            <Flex css={{ h: '100%', w: '100%' }}>{source}</Flex>
          </Col>

          {projections}
        </AllocationBarChartWithMetadata>
      </Col>

      <Col css={{ w: '100%', gap: '$8' }}>
        <AllocationBarChartWithMetadata
          blendedAllocationProposal={wholisticBlendedTarget}
          tooltip={(allocation) =>
            AllocationProposal.isTarget(allocationProposal) ? (
              <WeightOnlyAllocationBarChartTooltip allocation={allocation} />
            ) : AllocationProposal.isStrategic(allocationProposal) ? (
              <WeightAndValueAllocationBarChartTooltip
                allocation={allocation}
              />
            ) : null
          }
        >
          <Col
            css={{
              h: '100%',
              w: '100%',
              p: '$0 $2 $2',
              bg: '$violet3',
              borderRadius: '$6',
            }}
          >
            {targetHeader({ allocationProposal })}

            <Flex css={{ h: '100%', w: '100%' }}>{target}</Flex>
          </Col>

          {projections}
        </AllocationBarChartWithMetadata>
      </Col>
    </Row>
  );
};

const InitialVsTargetAssetClasses = () => (
  <AllocationBarChartWithMetadata.Chart />
);

const clientAllocations = (
  allocationProposal: AllocationProposalTypes.CategorizedAllocationProposal,
  type: 'original' | 'target',
) => {
  const { blended } = allocationProposal;
  const { wholistic: wholisticBlended } = blended[type];
  const { representationalAssets } = wholisticBlended;
  return representationalAssets;
};

const InitialVsTargetAssets = ({
  allocationProposal,
  type,
}: {
  allocationProposal: StrategicAllocationProposalTypes.CategorizedAllocationProposal;
  type: 'original' | 'target';
}) => {
  const assets = clientAllocations(allocationProposal, type);
  return <AssetsBarChart assets={assets} />;
};

export default React.memo(InitialVsTargetComparison);
