import { keyframes } from '@compoundfinance/design-system/dist/stitches.config';
import { VIEWPORT_PADDING } from './constants';

export const fadeUp = keyframes({
  from: {
    transform: 'translateY(100%)',
    opacity: 0,
  },
  to: {
    transform: 'translateY(0)',
    opacity: 1,
  },
});

export const hide = keyframes({
  from: {
    transform: '$$yPosition',
    opacity: 1,
  },
  to: {
    transform: 'translateY(100%)',
    opacity: 0,
  },
});

export const fadeOut = keyframes({
  from: {
    opacity: 1,
  },
  to: {
    opacity: 0,
  },
});

export const swipeOut = keyframes({
  from: {
    transform:
      'translateX(var(--radix-toast-swipe-end-x)) $$yPositionBeforeClose',
  },
  to: {
    transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px)) $$yPositionBeforeClose`,
  },
});
