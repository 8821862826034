import { Group } from '@visx/group';

import { Ticks, TicksAndTocksProps } from './TicksAndTocks';

export const LineGrid = (props: TicksAndTocksProps) => {
  return (
    <Group>
      <Ticks {...props} />
    </Group>
  );
};
