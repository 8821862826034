import noop from 'lodash/noop';
import { useRef, forwardRef } from 'react';
import { Text } from '@compoundfinance/design-system';
import { SpreadsheetIcon } from '@compoundfinance/design-system';
import { EmptyFolder } from '@compoundfinance/design-system';
import { NoDocuments } from './NoDocuments';
import { useFormikContext } from 'formik';

const getFileIcon = (accept?: string) => {
  if (accept === '.csv') {
    return <SpreadsheetIcon size={60} />;
  }
  return <EmptyFolder size={40} />;
};

interface UploadAreaProps {
  setFiles: (files: File[]) => void;
  file?: File;
  accept?: string;
  disabled?: boolean;
  height?: string;
  multiple?: boolean;
  existingFile?: string;
}

export const UploadArea = forwardRef(
  (props: UploadAreaProps, outsideRef: React.RefObject<HTMLInputElement>) => {
    const {
      setFiles,
      file,
      accept,
      multiple,
      disabled = false,
      height = '160px',
      existingFile,
    } = props;
    const innerRef = useRef<HTMLInputElement>(null);
    const fileName =
      file?.name ?? (existingFile || 'Click or drag a file to upload');

    const handleFiles = (event) => {
      const { files } = event.target;
      if (files?.length) {
        setFiles(Array.from(files));
      }
    };

    const ref = outsideRef || innerRef;

    const triggerUploadDialog = () => {
      ref.current?.click();
    };

    return (
      <>
        <NoDocuments
          h={height}
          text={
            <>
              <Text
                weight="medium"
                size="13"
                color={disabled ? 'gray8' : 'gray12'}
                css={{ textAlign: 'center', px: '$8' }}
              >
                {fileName}
              </Text>
              {(file?.name ?? Boolean(existingFile)) && (
                <Text size="12" color="gray10" css={{ mt: '$4' }}>
                  Click or drag a file to replace
                </Text>
              )}
            </>
          }
          fileIcon={file ? getFileIcon(accept) : undefined}
          onClick={triggerUploadDialog}
          disableDrop={disabled}
          className="hover br"
          onDrop={handleFiles}
        />
        <input
          type="file"
          multiple={multiple}
          accept={accept}
          className="sr-only"
          ref={ref}
          onChange={handleFiles}
          disabled={disabled}
        />
      </>
    );
  },
);

interface FormikUploadAreaProps extends Omit<UploadAreaProps, 'setFiles'> {
  onSetFile?: (file: File) => void;
  name: string;
  existingFile?: string;
}

export const FormikUploadArea = forwardRef(
  (props: FormikUploadAreaProps, ref: React.Ref<HTMLInputElement>) => {
    const { onSetFile = noop, name, ...rest } = props;
    const { setFieldValue, values } = useFormikContext<{ file: File }>();

    return (
      <UploadArea
        setFiles={([file]) => {
          onSetFile(file);
          setFieldValue(name, file);
        }}
        file={values[name]}
        ref={ref}
        {...rest}
      />
    );
  },
);
