import { ContainerState, ContainerActions } from '../types/reducers/login';
import ActionTypes from '../actionTypes/login';
import { replaceReducer } from 'reducers/hmr';
import { Reducers } from 'types/reducers';
// The initial state of the App
const initialState: ContainerState = {
  loading: false,
  errorMessage: '',
};

// Take this container's state (as a slice of root state), this container's actions and return new state
function appReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.SENDING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.COMPLETE_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
      };
    default:
      return state;
  }
}

// Replace the reducer with the hot loaded one
if (import.meta.hot) {
  import.meta.hot.accept(replaceReducer(Reducers.LOGIN));
}

export default appReducer;
