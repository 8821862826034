import styled, { css } from 'styled-components';
import { ScProps, CompoundColor } from 'components/types';

export type FlexAlignments =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'stretch'
  | 'auto'
  | 'baseline';

export interface FlexProps extends React.HTMLAttributes<HTMLElement> {
  alignItems?: FlexAlignments;
  alignSelf?: FlexAlignments;
  alignContent?: FlexAlignments;
  changeDirection?: boolean;
  h?: string;
  flexDirection?: 'row' | 'column' | 'column-reverse' | 'row-reverse';
  justifyContent?: FlexAlignments;
  maxWidth?: string;
  minWidth?: string;
  maxHeight?: string;
  minHeight?: string;
  w?: string;
  wrap?: string;
  flex?: number | string;
  bg?: CompoundColor | string;
  hoverBg?: CompoundColor | string;
  domPosition?: 'relative' | 'absolute' | 'sticky';
  name?: string;
}

export const FlexBase = css<FlexProps>`
  align-self: ${({ alignSelf }) => (alignSelf ? alignSelf : 'auto')};
  background-color: ${({ theme, bg }) => {
    const themeColor = theme.colors[bg as CompoundColor];

    if (themeColor) {
      return themeColor;
    }

    if (bg) {
      return bg;
    }

    return 'transparent';
  }};
  display: flex;
  flex: ${({ flex }) => flex || 'initial'};
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex-wrap: ${({ wrap }) => wrap || 'initial'};
  height: ${({ h }) => h || 'auto'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  max-width: ${({ maxWidth }) => maxWidth || 'none'};
  min-width: ${({ minWidth }) => minWidth || 'none'};
  max-height: ${({ maxHeight }) => maxHeight || 'none'};
  min-height: ${({ minHeight }) => minHeight || 'none'};
  position: ${({ domPosition }) => domPosition || 'static'};
  width: ${({ w }) => w || 'initial'};

  &:hover {
    background-color: ${({ theme, bg, hoverBg }) => {
      if (hoverBg) {
        const themeColor = theme.colors[hoverBg as CompoundColor];

        if (themeColor) {
          return themeColor;
        }

        if (hoverBg) {
          return hoverBg;
        }
      }
      return bg ? theme.colors[bg] : '';
    }};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: ${({ changeDirection }) =>
      changeDirection ? 'column' : ''};
    align-items: ${({ changeDirection }) => (changeDirection ? 'stretch' : '')};
  }
`;

const Flex = styled.div`
  ${FlexBase};
`;

export const FlexRow = styled(Flex).attrs((props) => ({
  flexDirection: 'row',
  ...props,
}))`
  align-items: ${({ alignItems }) => alignItems || 'center'};
  flex-direction: ${({ flexDirection }) => flexDirection};
`;

export const FlexCol = styled(Flex).attrs((props) => ({
  flexDirection: 'column',
  ...props,
}))`
  align-items: ${({ alignItems }) => alignItems || 'stretch'};
  flex-direction: ${({ flexDirection }) => flexDirection};
`;

export const ContentWrapSizeStyle = css`
  margin-left: auto;
  margin-right: auto;
  min-width: ${(p: ScProps) => p.theme.sizes.container_lg};
`;

export const FlexColContentWrap = styled(FlexCol)`
  ${ContentWrapSizeStyle};
`;

const CardStyle = css`
  padding: 3.05rem 3.75rem;
  background-color: ${(p: ScProps) => p.theme.colors.white};
  margin-bottom: ${(p: ScProps) => p.mb || 0};
  margin-top: ${(p: ScProps) => p.mt || 0};
`;

export const FlexColCard = styled(FlexCol)`
  ${CardStyle};
` as any;
