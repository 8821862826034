/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 */

enum ActionTypes {
  CREATE_USER_REQUEST = 'ADMIN/CREATE_USER_REQUEST',
  GET_USER_DATA_REQUEST = 'ADMIN/GET_USER_DATA_REQUEST',
  SYNC_USER_DATA = 'ADMIN/SYNC_USER_DATA',
  DOWNLOAD_CSV = 'ADMIN/DOWNLOAD_CSV',
  UpdateAdminState = 'ADMIN/UpdateAdminState',
}

export default ActionTypes;
