import _ from 'lodash';
import moment from 'moment';
import { camelToCapitalCase, CURRENT_TAX_YEAR } from '../utils';
import { v4 as uuid } from 'uuid';
import {
  CERTIFICATE_CLASS_TYPES,
  DEFAULT_CERT,
} from './certificates/constants';
import {
  Certificate,
  Grant,
} from '@compoundfinance/compound-core/dist/types/equity';

const emptyCertProps = {};

/**
 * DEPRECATED
 * Creates a fake cert for use in the grantBreakdown function.
 * Used to ensure that RSUs grants have their values set properly.
 *
 * We now create RSUs on the backend upon grant creation, and these dummy certs don't
 * persist sale events, so this code will be removed when the sale events feature is live and
 * working.
 *
 * @param grant
 * @param fmv
 * @param vested Vested quantity to apply to the certificate
 * @param props Any additional overriding properties the certificate should receive
 */
function buildCert(
  grant: Grant,
  fmv: number,
  vested: number,
  props?: Partial<Certificate>,
) {
  return {
    ...DEFAULT_CERT,
    id: uuid(),
    privateEquityAccountId: grant.privateEquityAccountId,
    quantity: vested,
    pricePerShare: fmv,
    grantId: grant.id,
    ...(props || emptyCertProps),
  };
}

const getClassType = (certificate: Certificate) => {
  return camelToCapitalCase(certificate.classType);
};

const getClass = (certificate: Certificate) =>
  camelToCapitalCase(certificate.class);

const isPreferred = (certificate: Certificate) =>
  certificate.classType === CERTIFICATE_CLASS_TYPES.PREFERRED;

/**
 * Get all sold shares from this year
 * @param cert
 */
const getThisYearsSales = (cert: Pick<Certificate, 'userSaleEvents'>) => {
  const currentYear = moment().year();
  return (cert.userSaleEvents || []).filter((saleEvent) => {
    return moment
      .utc(saleEvent.date)
      .startOf('day')
      .isSameOrAfter(moment.utc([currentYear]).startOf('year'));
  });
};

/**
 * Get all sale events that have occurred prior to this year
 * @param cert
 */
const getPriorYearsSales = (cert: Certificate) => {
  return (cert.userSaleEvents || []).filter((saleEvent) => {
    return moment
      .utc(saleEvent.date)
      .startOf('day')
      .isBefore(moment.utc([CURRENT_TAX_YEAR]).startOf('year'));
  });
};

/**
 * Get the total amount of a certificate sold prior to this year
 * @param cert
 */
const amountSoldInPriorYears = (cert: Certificate) => {
  const priorYearSaleEvents = getPriorYearsSales(cert);
  return _.sumBy(priorYearSaleEvents, 'amount');
};

/**
 * Determines if the certificate is issued in the future,
 * after the supplied dateToCheck
 * @param cert
 * @param dateToCheck
 */
const isFutureCert = (cert: Certificate, dateToCheck: Date) =>
  moment.utc(cert.issueDate).isAfter(dateToCheck, 'day');

/**
 * Get the total sold quantity from this certificate
 * @param cert
 */
const getAllSold = (cert: Certificate) =>
  _.sumBy(cert.userSaleEvents, 'amount');

/**
 * Get the number of shares a certificate has remainining after subtracting the
 * total sold quantity, as well as the sold quantity
 * @param cert
 */
const getRemainingQty = (cert: Certificate) => {
  const soldAmt = getAllSold(cert);

  return {
    remaining: Math.max(cert.quantity - soldAmt, 0),
    sold: soldAmt,
  };
};

/**
 * Get the total amount of a certificate sold historically
 * @param cert
 */
const amountSoldAlready = (cert: Certificate) => {
  return _.sumBy(cert.userSaleEvents, 'amount');
};

const CertificateUtils = {
  amountSoldAlready,
  buildCert,
  isPreferred,
  getClassType,
  getClass,
  getThisYearsSales,
  getPriorYearsSales,
  isFutureCert,
  getRemainingQty,
  getAllSold,
  amountSoldInPriorYears,
};

export default CertificateUtils;
