import omit from 'lodash/omit';
import { AllocationTypes } from './Allocation';
import { EditableAllocationTypes } from './EditableAllocation';
import { InvestmentTargetTypes } from './InvestmentTarget';

export namespace TransitionTypes {
  export type Transition = AllocationTypes.ClientAllocation & {
    investmentTarget: InvestmentTargetTypes.InvestmentTarget | null;
  };
}

const fromEditableAllocationTransitions = (
  editableAllocation: EditableAllocationTypes.EditableAllocationTransition,
): TransitionTypes.Transition =>
  /** @ts-ignore */
  omit(editableAllocation, [
    'editableType',
    'transitions',
    'transition',
    'assets',
  ]);

export const Transition = {
  fromEditableAllocationTransitions,
};
