import React, { Key } from 'react';
import { TimeRangeButton } from './AllocationProposal';
import { Text } from '@compoundfinance/design-system';

type TimeRangeOption<TimeRangeOptionValue extends Key> = {
  label: string;
  value: TimeRangeOptionValue;
  isSelected: boolean;
};

const TimeRange = <TimeRangeOptionValue extends Key>({
  options,
  onChange,
}: {
  options: TimeRangeOption<TimeRangeOptionValue>[];
  onChange: (value: TimeRangeOptionValue) => void;
}) => {
  return (
    <>
      {options.map((option) => (
        <React.Fragment key={option.value}>
          <TimeRangeButton
            variant="ghost"
            onClick={() => {
              onChange(option.value);
            }}
            selected={option.isSelected}
          >
            <Text size="12" color="gray9">
              {option.label}
            </Text>
          </TimeRangeButton>
        </React.Fragment>
      ))}
    </>
  );
};

export default TimeRange;
