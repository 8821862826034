import ModuleContext from 'containers/Dashboard/Advisor/DocumentsAdmin/components/DocumentViewer/ModuleContext';
import useIsDocumentEditor from 'containers/Dashboard/Advisor/DocumentsAdmin/hooks/useIsDocumentEditor';
import { useMutableModuleProperty } from 'containers/Dashboard/Advisor/DocumentsAdmin/hooks/useMutableProperty';
import {
  Box,
  Button,
  Col,
  DollarAltIcon,
  Row,
  Text,
  BorrowIcon,
} from '@compoundfinance/design-system';
import { useContext } from 'react';
import EditableTextArea from 'containers/Dashboard/Document/components/EditableTextArea';

export type MembershipVariation = 'minimum' | 'percent';

type MembershipVariationControlsProps = {
  variation: MembershipVariation;
  setVariation: (value: MembershipVariation) => void;
};

function MembershipVariationControls(
  props: React.PropsWithChildren<MembershipVariationControlsProps>,
) {
  const { variation, setVariation } = props;

  const { isHover } = useContext(ModuleContext);

  const handleChangeVariation = (newVariation: MembershipVariation) => {
    if (variation !== newVariation) setVariation(newVariation);
  };

  return (
    <Box css={{ position: 'relative' }}>
      {props.children}

      {isHover && (
        <Col css={{ position: 'absolute', top: 0, right: '-$32', gap: '$4' }}>
          <Button
            type="button"
            size="small"
            square
            variant="internal"
            onClick={() => handleChangeVariation('minimum')}
            title="Minimum"
          >
            <DollarAltIcon size={16} />
          </Button>
          <Button
            type="button"
            size="small"
            square
            variant="internal"
            onClick={() => handleChangeVariation('percent')}
            title="Percent"
          >
            <BorrowIcon size={16} />
          </Button>
        </Col>
      )}
    </Box>
  );
}

type MembershipVariationWrapperProps = {
  variation: MembershipVariation;
  price: string;
};

function MembershipVariationWrapper(props: MembershipVariationWrapperProps) {
  const { variation, price } = props;
  const isDocumentEditor = useIsDocumentEditor();

  return (
    <Box css={{ bg: isDocumentEditor ? '$indigo3' : 'transparent' }}>
      {variation === 'percent' ? (
        <Col>
          <Text size="32" color="gray8">
            Our annual fee is based on a marginal{' '}
            <Text color="gray12">0.35</Text> - <Text color="gray12">0.65%</Text>{' '}
            rate on assets under management.
          </Text>
        </Col>
      ) : (
        <Col>
          <Text size="13" color="gray10">
            Our minimum membership fee
          </Text>
          <Row css={{ gap: '$8', ai: 'baseline' }}>
            <Text size="48" color="gray12">
              <EditableTextArea propertyKey="price" value={price}>
                {price}
              </EditableTextArea>
            </Text>
            <Text
              size="12"
              color="gray10"
              weight="medium"
              css={{ textTransform: 'uppercase' }}
            >
              annually
            </Text>
          </Row>
        </Col>
      )}
    </Box>
  );
}

type Props = {
  variation: MembershipVariation;
  price: string;
  ViewComponent: React.ComponentType<MembershipVariationWrapperProps>;
};

function MembershipVariationEdit(props: Props) {
  const { variation, price, ViewComponent } = props;
  const isDocumentEditor = useIsDocumentEditor();

  const [, setVariation] = useMutableModuleProperty<MembershipVariation>(
    'variation',
    variation,
  );

  if (isDocumentEditor) {
    return (
      <MembershipVariationControls
        variation={variation}
        setVariation={setVariation}
      >
        <ViewComponent price={price} variation={variation} />
      </MembershipVariationControls>
    );
  }

  return <ViewComponent price={price} variation={variation} />;
}

MembershipVariationEdit.defaultProps = {
  ViewComponent: MembershipVariationWrapper,
};

export default MembershipVariationEdit;
