import _ from 'lodash';

import {
  CompoundAsset,
  Loan,
  OtherAsset,
  RealEstate,
} from 'components/EquityManagement/Asset/types';
import { Ownership } from '@compoundfinance/compound-core/dist/types/account';
import { PlaidAccount } from '@compoundfinance/compound-core/dist/types/plaid';
import { AccountSnapshot } from '@compoundfinance/compound-core/dist/types/account';
import { GPExposure, LPExposure } from 'types/fundInvestments';
import { Integration } from 'types/user';

/**
 * Sums up all ownerships for the asset, and normalizes the result from [0, 100] to [0, 1].
 * If there are no ownerships, assume 100% total ownership
 * @param asset asset whose ownerships to sum together
 * @returns total ownership ratio, a val in range [0, 1]
 */
function getTotalOwnershipRatio(asset: { ownership?: Ownership }) {
  if (!asset.ownership?.owners) {
    return 1;
  }
  let totalOwnership = 0;
  Object.entries(asset.ownership.owners).forEach(
    ([ownerId, assetOwnershipPercentage]) => {
      const ownerOwnership =
        asset.ownership?.associatedOwners?.find(({ id }) => {
          return id === ownerId;
        })?.ownershipPercentage ?? 100;
      totalOwnership +=
        ((assetOwnershipPercentage / 100) * ownerOwnership) / 100;
    },
  );
  return totalOwnership;
}

/**
 * Sum up all ownerships for the snapshot and adjust the balance based on that.
 * Saves the original balance in `actualBalance` field.
 * If there are no ownerships, assume 100% total ownership.
 * @param snapshot snapshot whose balance is to be adjusted
 * @returns snapshot where the underlying asset balance is moved to field `actualBalance` and `balance` is the underlying asset balance multiplied by the total ownership
 */
function adjustSnapshotBalance(snapshot: AccountSnapshot) {
  const snapshotTotalOwnershipRatio = snapshot.ownerships
    ? Object.values(snapshot.ownerships).reduce((sum, val) => sum + val, 0) /
      100
    : 1;
  const { actualBalance, balance, ...rest } = snapshot;
  return {
    ...rest,
    balance: (actualBalance ?? balance) * snapshotTotalOwnershipRatio,
    actualBalance: actualBalance ?? balance,
  };
}

/**
 * Takes a snapshot with `balance` adjusted on ownership, and negates this adjustments.
 * If `actualBalance` is not found, do nothing.
 * @param snapshot snapshot whose `balance` may be adjusted based on the ownership
 * @returns snapshot with balance being the underlying asset value no matter the ownership
 */
function deAdjustSnapshotBalance(snapshot: AccountSnapshot) {
  const { actualBalance, balance, ...rest } = snapshot;
  return {
    ...rest,
    balance: actualBalance ?? balance,
  };
}

/**
 * Takes an account and adjusts its current value based on the total ownership.
 * If total ownership is null, assume 100% ownership.
 * @param account any account with `ownership` field
 * @param valueKey key of the value field, e.g., `currentBalance`
 * @returns account with the field depicted by @param valueKey being adjusted based on the ownership. Original value is included in field prefixed with `actual`
 */
function getAdjustedAccountBalance(
  account: {
    ownership?: Ownership;
  },
  valueKey: string,
) {
  const totalOwnershipRatio = getTotalOwnershipRatio(account);
  const actualValueKey = `actual${_.upperFirst(valueKey)}`;
  const unadjustedValue = account[actualValueKey] ?? account[valueKey] ?? 0;
  return unadjustedValue * totalOwnershipRatio;
}

/**
 * Adjusts currentBalance and snapshot balances of a Plaid account
 * @param account Plaid account to adjust
 * @returns account with adjusted `currentBalance` and snapshot balances. Saves the original account value in `actualCurrentBalance`
 */
function adjustPlaidAccount(account: PlaidAccount) {
  const accountSnapshots = account.accountSnapshots?.map(adjustSnapshotBalance);

  return {
    ...account,
    accountSnapshots,
    currentBalance: getAdjustedAccountBalance(account, 'currentBalance'),
    actualCurrentBalance:
      account.actualCurrentBalance ?? account.currentBalance,
  };
}

/**
 * Adjusts value and snapshot balances of a Compound asset
 * @param asset Compound asset to adjust
 * @param type assetType of the compound asset
 * @returns Compound asset with adjusted value and snapshot balances. Saves the original account value in a field prefixed w/ `actual`
 */
function adjustCompoundAsset(asset: CompoundAsset, type: string) {
  const accountSnapshots = asset.accountSnapshots?.map(adjustSnapshotBalance);

  switch (type) {
    case 'other':
    case 'realEstate':
      return {
        ...asset,
        accountSnapshots,
        value: getAdjustedAccountBalance(asset, 'value'),
        actualValue:
          (asset as any).actualValue ?? (asset as { value: number }).value,
      };
    // Don't adjust LP and GP exposures, nor private investments
    case 'lpExposure':
    case 'gpExposure':
    case 'privateInvestments':
      return asset;
    case 'otherLiability':
      return {
        ...asset,
        accountSnapshots,
        currentBalance: getAdjustedAccountBalance(asset, 'currentBalance'),
        actualCurrentBalance: (asset as { currentBalance: number })
          .currentBalance,
      };
    default:
      return { ...asset, accountSnapshots };
  }
}

/**
 * Takes a Compound asset and changes its value to be the original asset's value.
 * Snapshot balances are changed to be the original snapshot balances
 * @param asset Compound asset to deadjust
 * @returns asset whose value and snapshot values are the original values
 */
function deAdjustCompoundAsset(asset: CompoundAsset): CompoundAsset {
  const accountSnapshots = asset.accountSnapshots?.map(deAdjustSnapshotBalance);

  switch (asset.assetType) {
    case 'other':
    case 'realEstate': {
      const { actualValue, value, ...rest } = asset as OtherAsset | RealEstate;
      return {
        ...(rest as CompoundAsset),
        accountSnapshots,
        value: actualValue ?? value,
      };
    }
    case 'lpExposure':
    case 'gpExposure':
    case 'otherLiability':
    // Default to treating the asset as a PlaidAccount
    default: {
      const { actualCurrentBalance, currentBalance, ...rest } = asset as
        | LPExposure
        | GPExposure
        | Loan;

      return {
        ...(rest as CompoundAsset),
        accountSnapshots,
        currentBalance: actualCurrentBalance ?? currentBalance ?? 0,
      };
    }
  }
}

/**
 * Takes a Plaid account and changes its currentBalance to be the original account's value.
 * Snapshot balances are changed to be the original snapshot balances
 * @param asset Plaid account to deadjust
 * @returns account whose currentBalance and snapshot balances are the original balances
 */
function deAdjustPlaidAccount(asset: PlaidAccount): PlaidAccount {
  const accountSnapshots = asset.accountSnapshots?.map(deAdjustSnapshotBalance);
  const { actualCurrentBalance, currentBalance, ...rest } = asset;
  return {
    ...rest,
    accountSnapshots,
    currentBalance: actualCurrentBalance ?? currentBalance,
  };
}

/**
 * Takes an integration and adjusts all its Plaid accounts and their snapshots to use balances that are adjusted to the total ownership ratio.
 * @param integration integratino whose accounts are to be adjusted
 * @returns integration with adjusted Plaid accounts
 */
function adjustIntegration(integration: Integration): Integration {
  return {
    ...integration,
    plaidAccounts: integration.plaidAccounts?.map(adjustPlaidAccount),
  };
}

const OwnershipAdjustmentUtils = {
  adjustCompoundAsset,
  adjustIntegration,
  adjustPlaidAccount,
  adjustSnapshotBalance,
  deAdjustCompoundAsset,
  deAdjustPlaidAccount,
  getAdjustedAccountBalance,
  getTotalOwnershipRatio,
};

export default OwnershipAdjustmentUtils;
