import { StringUtils } from 'utils/strings';

import {
  AssetAllocationCategory,
  AssetAllocationCategoryTypes,
} from './AssetAllocationCategory';
import { AllocationAsset } from '../../Accounts/AssetAllocation/AllocationExplorer';

export namespace AssetAllocationAssetTypes {
  export type CategoryAllocation =
    AssetAllocationCategoryTypes.AssetCategory & {
      allocationType: 'ASSET_CATEGORY';
    };
  export type ClientAllocation = AllocationAsset & {
    allocationType: 'ACCOUNT' | 'ASSET';
  };
  export type AssetAllocationAsset = CategoryAllocation | ClientAllocation;
}

const isAssetCategory = (
  assetAllocationAsset: AssetAllocationAssetTypes.AssetAllocationAsset,
): assetAllocationAsset is AssetAllocationAssetTypes.CategoryAllocation => {
  return assetAllocationAsset.allocationType === 'ASSET_CATEGORY';
};

const name = (
  assetAllocationAsset: AssetAllocationAssetTypes.AssetAllocationAsset,
): string => {
  if (isAssetCategory(assetAllocationAsset))
    return AssetAllocationCategory.name(assetAllocationAsset);

  const { name, originalAsset = {} } = assetAllocationAsset;
  const {
    name: originalAssetName,
    officialName: originalAssetOfficialName,
    isoCurrencyCode: originalAssetIsoCurrencyCode,
  } = originalAsset;
  return (
    name ||
    originalAssetOfficialName ||
    originalAssetName ||
    originalAssetIsoCurrencyCode ||
    ''
  );
};

const slug = (
  assetAllocationAsset: AssetAllocationAssetTypes.AssetAllocationAsset,
): string => {
  if (isAssetCategory(assetAllocationAsset))
    return assetCategorySlug(assetAllocationAsset);
  return `${StringUtils.slugify(name(assetAllocationAsset))}#${
    assetAllocationAsset.assetCategorySlug ?? 'other-assets'
  }#${assetAllocationAsset.id}`;
};

const allocationAssetCategory = (
  assetAllocationAsset: AssetAllocationAssetTypes.ClientAllocation,
) => {
  const { assetCategorySlug } = assetAllocationAsset;
  return assetCategorySlug!;
};
const assetCategorySlug = (
  assetAllocationAsset: AssetAllocationAssetTypes.AssetAllocationAsset,
): string => {
  if (isAssetCategory(assetAllocationAsset))
    return AssetAllocationCategory.slug(assetAllocationAsset);

  return allocationAssetCategory(assetAllocationAsset);
};

export const AssetAllocationAsset = {
  name,
  slug,
  assetCategorySlug,
};
