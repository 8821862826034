import useIsDocumentEditor from 'containers/Dashboard/Advisor/DocumentsAdmin/hooks/useIsDocumentEditor';
import {
  Box,
  Button,
  CrossIcon,
  Row,
  Select,
} from '@compoundfinance/design-system';
import { useContext, useRef, useState } from 'react';
import { getEnumValues } from 'utils/constants';
import Person, { PersonProps } from './Person';
import ModuleContext from 'containers/Dashboard/Advisor/DocumentsAdmin/components/DocumentViewer/ModuleContext';
import {
  DirectoryPerson,
  DirectoryPersonRole,
  PEOPLE_DIRECTORY,
} from 'domain/Employees/constants';
import { Level } from 'containers/Dashboard/Documents/types';
import { useMutablePropertyAtLevel } from 'containers/Dashboard/Advisor/DocumentsAdmin/hooks/useMutableProperty';

type Props = PersonProps & {
  propertyKey: string;
  propertyValue: DirectoryPerson;
  personRole?: DirectoryPersonRole;
  placeholder?: string;
  level?: Level;
};

const getDirectoryPersonKey = (key: string): DirectoryPerson | null => {
  const enums = getEnumValues(DirectoryPerson);
  if (enums.includes(key as DirectoryPerson)) {
    return key as DirectoryPerson;
  }

  return null;
};

const peopleOptions = Object.entries(PEOPLE_DIRECTORY).map(([key, value]) => ({
  label: value.name,
  subtitle: value.email,
  value: key,
  role: value.role,
  prefix: value.avatar ? (
    <Box as="img" src={value.avatar} alt="" css={{ br: '$round' }} />
  ) : (
    <></>
  ),
}));

function EditablePersonEditor(props: Props) {
  const {
    propertyKey,
    propertyValue,
    placeholder,
    personRole = DirectoryPersonRole.FinancialAdvisor,
    level = Level.MODULE,
    ...viewProps
  } = props;
  const useMutableProperty = useMutablePropertyAtLevel(level);
  const [, setPropertyValue] = useMutableProperty(propertyKey, propertyValue);
  const [editMode, setEditMode] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const { isHover } = useContext(ModuleContext);

  const handleClick = (e) => {
    e.preventDefault();
    if (!editMode) {
      setEditMode(true);
    }
  };

  return (
    <Box
      css={{
        position: 'relative',
        '&:hover': editMode
          ? {}
          : {
              bg: '$indigo3',
              outline: '1px solid $indigo5',
              outlineOffset: 2,
            },
        bg: isHover ? '$indigo3' : 'inherit',
      }}
      title="Double-click to edit"
      onClick={handleClick}
    >
      <Box css={{ opacity: editMode ? 0.5 : 1 }}>
        <Person {...viewProps} />
      </Box>

      {editMode && (
        <Box ref={containerRef}>
          <Row
            css={{
              gap: '$4',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
            }}
            data-id="row"
          >
            <Box css={{ flex: '1 1 auto' }}>
              <Select
                placeholder={placeholder || `Select ${personRole}`}
                value={propertyValue}
                options={
                  personRole
                    ? peopleOptions.filter(
                        (person) => person.role === personRole,
                      )
                    : peopleOptions
                }
                onChange={(value) => {
                  const key = getDirectoryPersonKey(value);
                  if (key) {
                    setPropertyValue(key);
                  } else {
                    console.error('Invalid key', value);
                  }
                  setEditMode(false);
                }}
                onFocus={(e) => {
                  e.target.click();
                }}
                autoFocus
              />
            </Box>
            <Button
              square
              css={{ ml: 'auto' }}
              onClick={() => setEditMode(false)}
              variant="ghost"
              size="large"
            >
              <CrossIcon />
            </Button>
          </Row>
        </Box>
      )}
    </Box>
  );
}

function EditablePerson(props: Props) {
  const isDocumentEditor = useIsDocumentEditor();
  const { propertyKey, propertyValue, ...viewProps } = props;

  if (isDocumentEditor) {
    return <EditablePersonEditor {...props} />;
  }

  return <Person {...viewProps} />;
}

export default EditablePerson;
