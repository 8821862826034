import { DirectoryPerson } from 'domain/Employees/constants';
import { ILinkPreview } from 'domain/Employees/types';

import Custom from '../../MembershipProposal/Custom/Custom';

export interface CustomIndexingProps {
  financialAdvisor: DirectoryPerson;
  linkPreviews: ILinkPreview[];
  title: string;
}

function CustomIndexing(props: CustomIndexingProps) {
  const { financialAdvisor, linkPreviews, title } = props;

  return (
    <Custom
      title={title}
      customText={`Direct Index investing provides the benefits of broad-market exposure alongside greater customization, diversification, and after-tax returns. 

Direct Indexing strategies can be quite profitable, often out-performing the exchange-traded funds (ETFs) they track by as much as 0.34% to 3.62% annually. In a direct indexing portfolio, investors hold the individual positions that comprise the ETF rather than the ETF itself, allowing them to isolate specific positions to sell at a loss through a process called “tax-loss harvesting.” 
            
Holding individual positions also provides investors with a much greater degree of precision, enabling them to build a portfolio around specific criteria. 
            
Previously reserved for institutional and high-net-worth investors, Direct Indexing has recently become far more accessible thanks to new financial technology infrastructure, the ability to trade fractional shares, and zero-commission trading.`}
      linkPreviews={linkPreviews}
      financialAdvisor={financialAdvisor}
    />
  );
}

export default CustomIndexing;
