/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { connectRouter } from 'connected-react-router';

import history from 'utils/history';
// import languageProviderReducer from 'containers/LanguageProvider/reducer';
import globalReducer from 'reducers/global';
import integrationsReducer from 'reducers/integrations';
import onboardReducer from 'reducers/onboard';
import adminReducer from 'reducers/admin';
import loginReducer from 'reducers/login';
import settingsReducer from 'reducers/settings';
import assetsReducer from 'reducers/assets';
import estateReducer from 'reducers/estate';
import cashflowReducer from 'reducers/cashflow';
import createReducerManager from 'reducerManager';
import { Reducers } from 'types/reducers';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function makeReducerManager(injectedReducers = {}) {
  const allReducers = {
    [Reducers.GLOBAL]: globalReducer,
    [Reducers.INTEGRATIONS]: integrationsReducer,
    [Reducers.ONBOARD]: onboardReducer,
    [Reducers.ADMIN]: adminReducer,
    [Reducers.LOGIN]: loginReducer,
    [Reducers.SETTINGS]: settingsReducer,
    [Reducers.ASSETS]: assetsReducer,
    [Reducers.ESTATE]: estateReducer,
    [Reducers.CASHFLOW]: cashflowReducer,
    [Reducers.ROUTER]: connectRouter(history),
    ...injectedReducers,
  };

  return createReducerManager(allReducers);
}
