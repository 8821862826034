import DateUtils from '@compoundfinance/compound-core/dist/date-utils';

export enum ChartNames {
  CompoundPortfolio = 'compoundPortfolio',
  PublicInvestments = 'publicInvestments',
  NetWorth = 'netWorth',
  Crypto = 'cryptoInvestments',
  Invest = 'compoundInvestments',
}

export enum ChartSettingsFields {
  Type = 'type',
  Filter = 'filter',
  Condense = 'condense',
  CustomFilterDateRange = 'customFilterDateRange',
  ExcludedBenchmarks = 'excludedBenchmarks',
  MainLineType = 'mainLineType',
}

export enum NetWorthDateInterval {
  OneDay = 'Past day',
  OneWeek = 'Past week',
  OneMonth = 'Past month',
  ThreeMonths = 'Three months',
  YearToDate = 'Year to Date',
  OneYear = 'Past year',
  All = 'All time',
  Custom = 'Custom',
}

export const NetWorthDayRanges = {
  [NetWorthDateInterval.OneDay]: 1,
  [NetWorthDateInterval.OneWeek]: 7,
  [NetWorthDateInterval.OneMonth]: 30,
  [NetWorthDateInterval.ThreeMonths]: 90,
  [NetWorthDateInterval.YearToDate]: DateUtils.daysFromStartOfYear(),
  [NetWorthDateInterval.OneYear]: 365,
  [NetWorthDateInterval.All]: Number.MAX_SAFE_INTEGER,
};

export const COLORS = {
  redStroke: 'var(--colors-red9)',
  greenStroke: 'var(--colors-green8)',
  greenGradientStart: 'rgba(204, 255, 203, 0.47)',
  greenGradientStop: 'rgba(255, 255, 255, 0.4)',
  redGradientStart: 'rgba(255, 168, 168, 0.02)',
  redGradientStop: 'rgba(255, 227, 227, 0.4)',
};
