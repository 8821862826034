import { UserRoleType } from 'utils/constants/user';
import useTypedSelector from '../typedSelector';

/**
 * Simple hook which reflects whether the app is in an advisor state.
 * This is the case when an advisor is currently "looking" at a user, meaning the adminUser field on the admin store
 * is populated with the admin's data and the user field on the global store is populated with an arbitrary user's data.
 * OR the admin hasn't fetched user data yet and the global user field is set to their data.
 */
export default function useIsAdvisor() {
  const userRole = useTypedSelector((state) => state.global.user.role);
  const adminRole = useTypedSelector((state) => state.admin.adminUser.role);
  return (
    userRole === UserRoleType.Advisor || adminRole === UserRoleType.Advisor
  );
}
