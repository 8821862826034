import { useEffect, useState, useRef, useCallback } from 'react';
import { Box } from '@compoundfinance/design-system';
import { Row } from '@compoundfinance/design-system';

interface TimerProps {
  duration: number;
  pauseTimer: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  hidden: boolean;
}

// Length of arc
const FULL_DASH_ARRAY = 283;

const calculateTimeFraction = (timeLeft: number, duration: number) => {
  return timeLeft / duration;
};

const Timer = (props: TimerProps) => {
  const { duration, setIsOpen, pauseTimer, hidden } = props;
  const [timeLeft, setTimeLeft] = useState(duration);

  const remainingTimePathRef = useRef<SVGPathElement | null>(null);

  const setCircleDasharray = useCallback(() => {
    const circleDasharray = `${(
      calculateTimeFraction(timeLeft, duration) * FULL_DASH_ARRAY
    ).toFixed(0)} ${FULL_DASH_ARRAY}`;

    const node = remainingTimePathRef.current;

    if (node) {
      node.setAttribute('stroke-dasharray', circleDasharray);
    }
  }, [timeLeft, duration]);

  useEffect(() => {
    if (pauseTimer) {
      return;
    }

    if (timeLeft < 0) {
      // Let the loader stay for 200ms before closing
      const setTimeoutId = setTimeout(() => {
        setIsOpen(false);
      }, 200);
      return () => clearTimeout(setTimeoutId);
    }

    // No need for setInterval as useEffect will run in each timeLeft change
    const setTimeoutId = setTimeout(() => {
      setTimeLeft((t) => t - 100);
      setCircleDasharray();
    }, 100);

    return () => clearTimeout(setTimeoutId);
  }, [timeLeft, setCircleDasharray, duration, setIsOpen, pauseTimer]);

  return (
    <Row
      css={{
        size: 14,
        flexShrink: 0,
        ai: 'center',
        opacity: hidden ? 0 : 1,
      }}
    >
      <Box as="svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <Box as="g" css={{ fill: 'none', stroke: 'none' }}>
          <Box
            as="circle"
            css={{ strokeWidth: 9, stroke: '$gray12' }}
            cx="50"
            cy="50"
            r="45"
          />
          <Box
            ref={remainingTimePathRef}
            as="path"
            strokeDasharray={`${FULL_DASH_ARRAY} ${FULL_DASH_ARRAY}`}
            css={{
              strokeWidth: 14,
              /* Makes sure the animation starts at the top of the circle */
              transform: 'rotate(90deg)',
              transformOrigin: 'center',
              transition: '100ms linear stroke-dasharray',
              stroke: '$gray0',
            }}
            d="
          M 50, 50
          m -45, 0
          a 45,45 0 1,0 90,0
          a 45,45 0 1,0 -90,0
        "
          />
        </Box>
      </Box>
    </Row>
  );
};

export default Timer;
