import { ClientPartnership } from 'types/clientPartnership';
import { PartnerUser } from 'types/user';

export interface PortalContainerProps {
  isLoading: boolean;
  partner: PartnerUser;
  partnership: ClientPartnership;
  updatePartnership: (updates: Partial<ClientPartnership>) => void;
}

export enum OrganizerIntakeStatus {
  New = 'new',
  InProgress = 'inProgress',
  SaveState = 'saveState',
  Complete = 'complete',
}
