import _ from 'lodash';

import { AssetAccountSection } from 'types/assets/section';
import {
  MekkoSectionAccountDatum,
  MekkoSectionDatum,
} from 'containers/Dashboard/Accounts/NetWorthChart/MekkoChartLegacy/types';
import UiUtils from 'utils/ui';
import useGetAccountsBreakdown from 'hooks/useGetAccountsBreakdown';
import { Row } from '@compoundfinance/design-system';
import { FlexRow } from 'components/global';

import MekkoCategory from './MekkoCategory';
import { labelKey } from 'containers/Dashboard/Accounts/NetWorthChart/MekkoChartLegacy/constants';
import useMeasureChart from 'containers/Dashboard/Accounts/NetWorthChart/MekkoChartLegacy/useMeasureChart';
import Typography from 'components/global/Typography';

/**
 * The value of some accounts can make up a very tiny percentage of the user's
 * total net worth for a given asset type. For example, they may have two credit cards,
 * one with a balance of 2000 and one with a balance of 100.
 *
 * To avoid the chart displaying very tiny stacked bars, we enforce a minimum percentage
 */
export const SAFE_MIN_PERCENT = 9;

const labelRe = new RegExp('label');

export const buildChartDatum =
  (isFiltered: boolean) =>
  (accountSection: AssetAccountSection): MekkoSectionDatum => {
    // The total methods provided by the account section class are designed to
    // reflect liabilities as losses, but here we need them to count as part
    // of the total assets
    let positiveTotal = _.sumBy(
      isFiltered ? accountSection.filteredAccounts : accountSection.allAccounts,
      (account) => Math.max(account.currentBalance, 0),
    );
    const accountsByBalance = _.orderBy(
      isFiltered ? accountSection.filteredAccounts : accountSection.allAccounts,
      'currentBalance',
      'desc',
    );

    const accountValueMap = accountsByBalance.reduce((memo, account) => {
      // Ignore accounts with a zero balance, they can throw off the chart display
      if (!account.currentBalance) {
        return memo;
      }

      const nickname = _.get(account, 'label');
      const baseName = nickname || account.name;

      let mask = nickname ? '' : _.get(account, 'mask');
      const accountName = `${baseName as string}${!mask ? '' : ' ' + mask}`;
      const balance = account.currentBalance;
      const percentage =
        balance < 0 ? 0 : UiUtils.getPercent(balance, positiveTotal);
      // Ensure that asset accounts with negative balances take up a very small portion of the chart
      const safePercentage = (() => {
        if (balance < 0) {
          return 1;
        }

        return percentage < SAFE_MIN_PERCENT ? SAFE_MIN_PERCENT : percentage;
      })();

      const balanceLabel = `${accountName}-${labelKey}`;

      const isNameDuplicate = balanceLabel in memo && accountName in memo;

      if (isNameDuplicate) {
        const existingBalanceEntry = memo[balanceLabel];
        const existingPercentageEntry = memo[accountName];
        return {
          ...memo,
          [balanceLabel]: existingBalanceEntry + balance,
          [accountName]: safePercentage + existingPercentageEntry,
        };
      }

      return {
        ...memo,
        [balanceLabel]: balance,
        [accountName]: safePercentage,
      };
    }, {});

    // Map account data into a simplified format for chart display
    // The final output has account names keyed to the percentage of
    // the asset type they make up.
    const accountData: MekkoSectionAccountDatum[] = _.reduce(
      accountValueMap,
      (memo, value, key) =>
        key.match(labelRe) ? memo : [...memo, { label: key, value }],
      [],
    );

    return {
      name: accountSection.title,
      type: accountSection.type,
      total: positiveTotal,
      accountValueMap,
      accountData: accountData,
    };
  };

function ChartFallback() {
  return (
    <FlexRow h="100%" w="100%" className="m-0">
      <Typography
        textAlign="center"
        w="80%"
        color="darker_grey"
        className="p-base mx-auto my-0"
      >
        You may have assets with a positive balance, but they have all been
        filtered out. Use the checkboxes in the table below to toggle them.
      </Typography>
    </FlexRow>
  );
}

interface MekkoChartProps extends React.HTMLAttributes<HTMLDivElement> {
  isFiltered: boolean;
  chartData?: MekkoSectionDatum[];
}

/**
 * This component is used to render the chart if chart data is provided
 */
function MekkoChart(props: MekkoChartProps) {
  const { isFiltered, chartData = [], ...rest } = props;

  const totalAssets = _.sumBy(chartData, 'total');

  const { chartRef, chartMeasurements } = useMeasureChart(
    chartData,
    totalAssets,
  );

  return !totalAssets ? (
    <ChartFallback />
  ) : (
    <Row
      ref={chartRef}
      css={{
        flex: '1',
        mt: '-20px',
      }}
      {...rest}
    >
      {chartData.map((categoryDatum: MekkoSectionDatum, chartIndex) => {
        return (
          <MekkoCategory
            key={chartIndex}
            chartIndex={chartIndex}
            numCategories={chartData.length}
            data={categoryDatum}
            totalAssets={totalAssets}
            chartMeasurements={chartMeasurements}
          />
        );
      })}
    </Row>
  );
}

/**
 * This component is used to fetch chart data if it is not provided
 */
function MekkoChartClient(props: MekkoChartProps) {
  const { isFiltered } = props;

  const { assetAccountSections } = useGetAccountsBreakdown();
  const chartData = _.flatten(
    assetAccountSections.map(buildChartDatum(isFiltered)),
  ).filter((d) => d.total !== 0);

  return <MekkoChart {...props} chartData={chartData} />;
}

/**
 * This component is used to wrap the chart component and fetch chart data if it is not provided
 */
function MekkoChartWrapper(props: MekkoChartProps) {
  const { chartData } = props;

  // If we have chart data, render the chart with it
  if (chartData) {
    return <MekkoChart {...props} />;
  }

  // Otherwise, render fetch chart data and render the chart
  return <MekkoChartClient {...props} />;
}

export default MekkoChartWrapper;
