import directEquityBreakdown from './directEquityBreakdown';
import * as _ from 'lodash';
import {
  Certificate,
  EquityStatus,
} from '@compoundfinance/compound-core/dist/types/equity';

type Tranche =
  | 'option'
  | 'rsa'
  | 'rsu'
  | 'common'
  | 'preferred'
  | 'convertible';
type Qty = 'value' | 'count';
type BreakdownQuery = (tranche: Tranche, quantity: Qty) => number;

export interface DePresenterMethods {
  getRawVested: BreakdownQuery;
  getVested: BreakdownQuery;
  getUnvested: BreakdownQuery;
  getCancelled: BreakdownQuery;
  getOwned: BreakdownQuery;
  getExercised: BreakdownQuery;
  getEarlyExercised: BreakdownQuery;
  getTotal: BreakdownQuery;
  getSold: BreakdownQuery;
  availableVestedCount: number;
  vestedRsuTotal: number;
  breakdown: ReturnType<typeof directEquityBreakdown>;
}

/**
 * Presenter class designed to make it easier to interact with the output of
 * the `directEquityBreakdown` method. This presenter will likely, though not always,
 * be used.
 *
 * The presenter exposes methods to get counts or total values for all instances of
 * a given equity tranche (option, rsa, rsu, common or preferred stock, convertible notes)
 * within a private equity account.
 *
 * For example, calling getVested('option', 'count') will return a number
 * equivalent to the sum of all vested, unexercised options tied to the private
 * equity account.
 */
class DirectEquityBreakdownPresenter implements DePresenterMethods {
  readonly breakdown: ReturnType<typeof directEquityBreakdown>;

  constructor(breakdown: ReturnType<typeof directEquityBreakdown>) {
    this.breakdown = breakdown;
  }

  private buildQuery(type: string, tranche: Tranche, qty: Qty) {
    return `${type}.${qty}.${tranche}`;
  }

  private getValueByQuery(query: string) {
    return Math.max(_.get(this.breakdown, query, 0)!, 0);
  }

  /**
   * Get all vested shares / options, irrespective of sold / exercised qtys
   * @param tranche
   * @param qty
   */
  getRawVested(tranche: Tranche, qty: Qty) {
    return this.getValueByQuery(
      this.buildQuery(EquityStatus.RawVested, tranche, qty),
    );
  }
  /**
   * Helper method to get the count or value of all equity vested and unexercised equity
   * in a user's account
   * @param tranche One of the tranches defined above (e.g. 'option', or 'common')
   * @param qty Either the `count` or `value`
   */
  getVested(tranche: Tranche, qty: Qty) {
    return this.getValueByQuery(
      this.buildQuery(EquityStatus.Vested, tranche, qty),
    );
  }
  /**
   * Helper method to get the total count or value of all unvested equity in a user's account
   * @param tranche One of the tranches defined above (e.g. 'option', or 'common')
   * @param qty Either the `count` or `value`
   */
  getUnvested(tranche: Tranche, qty: Qty) {
    return this.getValueByQuery(
      this.buildQuery(EquityStatus.Unvested, tranche, qty),
    );
  }

  /**
   * Helper method to get the total count or value of all cancelled equity in a user's account
   * @param tranche One of the tranches defined above (e.g. 'option', or 'common')
   * @param qty Either the `count` or `value`
   */
  getCancelled(tranche: Tranche, qty: Qty) {
    return this.getValueByQuery(
      this.buildQuery(EquityStatus.Cancelled, tranche, qty),
    );
  }

  /**
   * Helper method to get the total count or value of all owned equity in a user's account
   * @param tranche One of the tranches defined above (e.g. 'option', or 'common')
   * @param qty Either the `count` or `value`
   */
  getOwned(tranche: Tranche, qty: Qty) {
    return this.getValueByQuery(
      this.buildQuery(EquityStatus.Owned, tranche, qty),
    );
  }

  /**
   * Helper method to get the total count or value of all exercised equity in a user's account
   * This method is mostly just for completeness, exercised equity that has vested will always
   * be reflected in the common and / or preferred stock totals
   * @param tranche One of the tranches defined above (e.g. 'option', or 'common')
   * @param qty Either the `count` or `value`
   */
  getExercised(tranche: Tranche, qty: Qty) {
    return this.getValueByQuery(
      this.buildQuery(EquityStatus.Exercised, tranche, qty),
    );
  }

  /**
   * Helper method to get the total count or value of all early exercised equity in a user's account
   * @param tranche One of the tranches defined above (e.g. 'option', or 'common')
   * @param qty Either the `count` or `value`
   */
  getEarlyExercised(tranche: Tranche, qty: Qty) {
    return this.getValueByQuery(this.buildQuery('earlyExercise', tranche, qty));
  }

  /**
   * Helper method to get the total count or value of all equity in a user's account
   * @param tranche One of the tranches defined above (e.g. 'option', or 'common')
   * @param qty Either the `count` or `value`
   */
  getTotal(tranche: Tranche, qty: Qty) {
    return this.getValueByQuery(this.buildQuery('total', tranche, qty));
  }

  getSold(tranche: Tranche, qty: Qty) {
    return this.getValueByQuery(this.buildQuery('sold', tranche, qty));
  }

  get availableVestedTotal() {
    return this.breakdown.availableVestedTotal;
  }
  get certificatesTotal() {
    return this.breakdown.certificatesTotal;
  }
  get commonStockTotal() {
    return this.breakdown.commonStockTotal;
  }
  get preferredStockTotal() {
    return this.breakdown.preferredStockTotal;
  }
  get convertibleNotesTotal() {
    return this.breakdown.convertibleNotesTotal;
  }
  get vestedRsuTotal() {
    return this.breakdown.vestedRsuTotal;
  }

  get adjustedCerts(): Certificate[] {
    return this.breakdown.adjustedCerts;
  }

  get allEarlyExerciseCount() {
    return this.breakdown.earlyExerciseCount;
  }

  get allEarlyExerciseValue() {
    return this.breakdown.earlyExerciseTotal;
  }

  get availableVestedCount() {
    return this.breakdown.availableVestedCount;
  }
}

export default DirectEquityBreakdownPresenter;
