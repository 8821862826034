import React from 'react';
import { motion } from 'framer-motion';

import { Flex, Row, Text, Tooltip } from '@compoundfinance/design-system';

import AssetUtils from 'utils/assets';
import { InstitutionLogos } from 'utils/assets/institutionLogos';
import { InstitutionName } from 'utils/plaid/constants';

import { ASSET_CATEGORIES } from '../../constants';
import { Allocation, AllocationTypes } from '../../domain/Allocation';
import { AllocationProposalStrategy } from '../../domain/AllocationProposalStrategy';
import { NumberUtils } from '../../../../../utils/NumberUtils';

import AccountLogo from 'components/AccountLogo';
import BarChart from './Chartifacts/BarChart';
import AllocationBarChart from './AllocationBarChart';

const ASSETS_BAR_CHART_MINIMUM_BAR_HEIGHT = 32;

const toBar = (allocation: AllocationTypes.Allocation) => ({
  value: Allocation.weight(allocation),
  id: Allocation.slug(allocation),
});
const toBars = (
  allocations: Record<string, AllocationTypes.ClientAllocation>,
) => AllocationProposalStrategy.fromCategorized(allocations).map(toBar);

const AssetsBarChart = ({
  assets,
  minimumBarHeight = ASSETS_BAR_CHART_MINIMUM_BAR_HEIGHT,
  ...props
}: {
  assets: Record<string, AllocationTypes.ClientAllocation>;
} & Omit<React.ComponentProps<typeof BarChart>, 'bars' | 'bar'>) => {
  const shouldDisplayBarName = minimumBarHeight >= 20;

  return (
    <Flex
      css={{
        w: '100%',
        '& > ::-webkit-scrollbar': {
          width: '$5',
        },
        '& > ::-webkit-scrollbar-track': {
          br: '6px',
          bg: '$gray4',
        },
        '& > ::-webkit-scrollbar-thumb': {
          br: '6px',
          bg: '$gray8',
        },
      }}
    >
      <BarChart
        {...props}
        minimumBarHeight={minimumBarHeight}
        bars={toBars(assets)}
        bar={({ bar, positioning, size, chartSize }) => {
          const { id: slug } = bar;
          const asset = assets[slug];
          const {
            asset: { originalAsset },
            assetAllocation,
            assetCategorySlug,
          } = asset;
          const { width, height } = size;
          const { y } = positioning;

          const hasOriginalAsset = !!originalAsset;

          return (
            <Tooltip>
              <Tooltip.Trigger asChild>
                <Row
                  as={motion.div}
                  css={{
                    w: width - 4,
                    left: 2,
                    position: 'relative',
                    p: '4px',
                    ai: 'center',
                    jc: 'space-between',
                    bg: ASSET_CATEGORIES[assetCategorySlug].color,
                    borderRadius: '$2',
                    pointerEvents: 'all',
                    '&:hover': {
                      boxShadow: '$focus',
                    },
                  }}
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                    height: height - 4,
                    y: y + 2,
                  }}
                  transition={{ duration: 0.3 }}
                >
                  {shouldDisplayBarName && (
                    <>
                      <Row
                        css={{ ai: 'center', gap: '$8', overflow: 'hidden' }}
                      >
                        {hasOriginalAsset && (
                          <AccountLogo
                            size={14}
                            fontSize={8}
                            {...AssetUtils.getDefaultAssetImageProps(
                              originalAsset as any,
                            )}
                          />
                        )}
                        {!hasOriginalAsset && (
                          <AccountLogo
                            size={14}
                            logo={InstitutionLogos[InstitutionName.Compound]}
                          />
                        )}

                        <Text
                          size="11"
                          color="gray1"
                          css={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'pre',
                            pr: '$8',
                          }}
                        >
                          {asset.name}
                        </Text>
                      </Row>

                      <Text size="11" color="gray1">
                        {NumberUtils.sanitize(Allocation.weight(asset))}%
                      </Text>
                    </>
                  )}
                </Row>
              </Tooltip.Trigger>
              <Tooltip.Content
                css={{
                  w: width * (2 / 3),
                  p: '0',
                  boxShadow: '$small',
                  bg: '$gray0',
                }}
                side="right"
                hideArrow
              >
                <Flex
                  css={{
                    p: '0',
                    width: '100%',
                    jc: 'space-between',
                    flexDirection: 'column',
                    gap: '$2',
                  }}
                >
                  <AllocationBarChart
                    allocations={assetAllocation}
                    animate={false}
                    width={width * (2 / 3)}
                  />
                </Flex>
              </Tooltip.Content>
            </Tooltip>
          );
        }}
      />
    </Flex>
  );
};

export default React.memo(AssetsBarChart);
