import { Matrix, MatrixUtils } from '../utils/MatrixUtils';
import { NumberUtils } from '../../../../utils/NumberUtils';
import {
  ASSET_CATEGORIES_COVARIANCE,
  ASSET_CATEGORIES_COVARIANCE_MATRIX,
  ASSET_CATEGORIES_RETURN_AND_VOLATILITY,
} from '../VolatilityAndReturn';
import {
  AllocationProposalStrategy,
  AllocationProposalStrategyTypes,
} from './AllocationProposalStrategy';
import { Allocation } from './Allocation';
import { AssetAllocationCategory } from './AssetAllocationCategory';
import { AllocationProposalTypes } from './AllocationProposal';
import { not } from '../utils';
import { Asset } from './Asset';

const riskStandardDeviation =
  (covarianceMatrix: Matrix) =>
  (
    allocations: AllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy['allocations'],
  ): number => {
    const assetClasses =
      AllocationProposalStrategy.toAssetCategories(allocations);
    const assetClassesWithKnownVariance =
      AllocationProposalStrategy.toCategorized(
        AllocationProposalStrategy.fromCategorized(assetClasses).filter(
          ({ slug }) => !!ASSET_CATEGORIES_COVARIANCE[slug],
        ),
      );
    const assetClassesWithKnownVarianceRebalancedTo100 =
      AllocationProposalStrategy.rebalanceTo100(assetClassesWithKnownVariance);
    const allocationsWeightsMatrix = [
      AllocationProposalStrategy.sortByAssetClass(
        assetClassesWithKnownVarianceRebalancedTo100,
      ).map((allocation) => Allocation.weight(allocation)),
    ];
    const [[variance]] = MatrixUtils.multiply(
      MatrixUtils.multiply(allocationsWeightsMatrix, covarianceMatrix),
      MatrixUtils.transpose(allocationsWeightsMatrix),
    );
    return variance != null && variance > 0 ? Math.sqrt(variance) : 0;
  };

const meanReturn = (
  allocations: AllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy['allocations'],
) => {
  const assetClassesWithReturn = AllocationProposalStrategy.fromCategorized(
    AllocationProposalStrategy.toAssetCategories(allocations),
  ).filter((allocationCategory) => {
    const { slug } = allocationCategory;
    const assetCategoryReturnAndVolatility =
      ASSET_CATEGORIES_RETURN_AND_VOLATILITY[slug];
    const hasAnnualReturn =
      assetCategoryReturnAndVolatility?.annualReturn != null;
    return hasAnnualReturn;
  });
  const assetClassesWithWeightAndReturnRebalanceTo100 =
    AllocationProposalStrategy.fromCategorized(
      AllocationProposalStrategy.rebalanceTo100(
        AllocationProposalStrategy.toCategorized(assetClassesWithReturn),
      ),
    );
  return assetClassesWithWeightAndReturnRebalanceTo100.reduce(
    (mean, allocationsCategory) => {
      const { slug } = allocationsCategory;
      const assetCategory = ASSET_CATEGORIES_RETURN_AND_VOLATILITY[slug];
      return NumberUtils.add(
        mean,
        NumberUtils.divide(
          NumberUtils.multiply(
            Allocation.weight(allocationsCategory),
            AssetAllocationCategory.annualReturn(assetCategory),
          ),
          100,
        ),
      );
    },
    0,
  );
};

const combineClientAllocations = (
  allocations: AllocationProposalTypes.CategorizedAllocationProposal['original']['allocations'],
) =>
  Object.values(allocations).reduce((combinedClientAllocations, allocation) => {
    if (!Allocation.isClientAllocation(allocation))
      return combinedClientAllocations;
    const { asset, assetId, weight } = allocation;
    const id = asset?.originalAsset?.id ?? assetId;
    return {
      ...combinedClientAllocations,
      [id]: {
        weight: NumberUtils.add(
          Allocation.weight({ weight: combinedClientAllocations[id]?.weight }),
          weight,
        ),
      },
    };
  }, {});

const singleStockExposure = (
  allocations: AllocationProposalTypes.CategorizedAllocationProposal['original']['allocations'],
) =>
  Math.max(
    ...Object.values<
      AllocationProposalTypes.CategorizedAllocationProposal['original']['allocations'][number]
    >(combineClientAllocations(allocations))
      .filter(not(Asset.isRealEstate))
      .map(Allocation.weight),
    0,
  );

export const AllocationProposalCalculations = {
  return: { mean: meanReturn },
  volatility: {
    standardDeviation: riskStandardDeviation(
      ASSET_CATEGORIES_COVARIANCE_MATRIX,
    ),
  },
  insights: {
    singleStockExposure,
  },
};
