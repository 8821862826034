import {
  estateData,
  INTAKE_STEP_ARRAY,
} from 'containers/Dashboard/PartnerPortal/Content/Intake/Estate/constants';
import { OrganizerIntakeStatus } from 'containers/Dashboard/PartnerPortal/types';
import { LifecycleStatus, Reducers } from 'types/reducers';
import { replaceReducer } from 'reducers/hmr';
import { ContainerState, ContainerActions } from 'types/reducers/estate';
import EstateActionTypes from '../actionTypes/estate';

const initialState: ContainerState = {
  estateProfile: estateData,
  currentWizardStep: 0,
  loading: false,
  requestStatus: LifecycleStatus.Idle,
  status: OrganizerIntakeStatus.New,
};

// Take this container's state (as a slice of root state), this container's actions and return new state
function estateReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case EstateActionTypes.SendingRequest:
      return {
        ...state,
        loading: true,
      };
    case EstateActionTypes.CompleteRequest:
      return {
        ...state,
        loading: false,
      };
    case EstateActionTypes.UpdateWizardStep:
      const maxSteps = INTAKE_STEP_ARRAY.length - 1;
      const nextStep = Math.max(Math.min(action.payload.step, maxSteps), 0);

      return {
        ...state,
        currentWizardStep: nextStep,
      };
    case EstateActionTypes.SetEstateProfile:
      return {
        ...state,
        estateProfile: action.payload.estateProfile,
      };
    case EstateActionTypes.SetStatus:
      return {
        ...state,
        status: action.payload.status,
      };
    case EstateActionTypes.SetRequestStatus:
      return {
        ...state,
        requestStatus: action.payload.status,
      };
    default:
      return state;
  }
}

// Replace the reducer with the hot loaded one
if (import.meta.hot) {
  import.meta.hot.accept(replaceReducer(Reducers.ESTATE));
}

export default estateReducer;
