import EditablePerson from 'containers/Dashboard/Document/components/EditablePerson';
import EditableTextArea from 'containers/Dashboard/Document/components/EditableTextArea';
import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import { DirectoryPerson } from 'domain/Employees/constants';
import { getPerson } from 'containers/Dashboard/Document/utils';
import { Text, Row, Col } from '@compoundfinance/design-system';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';

const DEFAULT_NEXT_STEPS = [
  {
    title: 'Sign Compound Membership Agreement',
    description: 'When you’re ready, we’ll send the agreement via DocuSign.',
  },
  {
    title: 'Schedule kickoff meeting',
    description:
      'We’ll create a financial roadmap of near- and long-term goals and plans as well as company equity and investment strategies.',
  },
  {
    title: 'Integrate your accounts',
    description:
      'Visit your Compound dashboard to sync your accounts for in-depth analysis into your balance sheet.',
  },
  {
    title: 'Evaluate company equity, balance sheet, tax planning, and more',
    description:
      'We’ll craft a financial roadmap together which will outline the major areas we’ll focus on in the upcoming weeks, months, and year+. Afterward, we’ll begin tackling each area.',
  },
];

const Container = styled(ModuleContainer, {
  display: 'grid',
  py: '$24',
  px: '$24',
  gap: '$24',

  '@bp1': {
    py: '$44',
    px: '$48',
    gridTemplateColumns: '1fr 1px 1fr',
    gap: '$48',
  },
});

const VerticalSplit = styled('div', {
  border: '1px solid $gray4',
});

export interface NextStepsProps {
  financialAdvisor: DirectoryPerson;
  steps?: Array<{ title: string; description: string }>;
}

function NextSteps(props: NextStepsProps) {
  const { financialAdvisor, steps = DEFAULT_NEXT_STEPS } = props;

  const financialAdvisorPerson = getPerson(financialAdvisor);

  return (
    <Container>
      <Col css={{ h: '100%', gap: '$24' }}>
        <Col css={{ gap: '$12' }}>
          <Text
            weight="medium"
            color="gray8"
            css={{
              fontSize: '$20',
              letterSpacing: '-0.8px',

              '@bp1': { fontSize: '$48', lineHeight: '58px' },
            }}
          >
            Next steps
          </Text>
        </Col>

        <Col css={{ h: '100%', gap: '$24', justifyContent: 'space-between' }}>
          <Text size="14" css={{ w: '100%' }}>
            <EditableTextArea
              propertyKey="description"
              value="Finance is complex and there are a lot of specializations. Through our extensive network, we have vetted a big group of trusted partners that we can find the best deal for your need and work closely with."
            />
          </Text>
          <EditablePerson
            propertyKey="financialAdvisor"
            propertyValue={financialAdvisor}
            person={financialAdvisorPerson}
            size="small"
          />
        </Col>
      </Col>

      <VerticalSplit />

      <Col css={{ gap: '$24' }}>
        {steps.map((step, index) => (
          <Row key={step.title} css={{ gap: '$24' }}>
            <Text
              color="gray8"
              weight="medium"
              css={{ fontSize: '$32', fontVariantNumeric: 'tabular-nums' }}
            >
              {index < 9 ? '0' : ''}
              {index + 1}
            </Text>
            <Col>
              <Text size="13" color="gray12" weight="medium">
                {step.title}
              </Text>
              <Text size="13" color="gray10">
                {step.description}
              </Text>
            </Col>
          </Row>
        ))}
      </Col>
    </Container>
  );
}

export default NextSteps;
