import React from 'react';
import { DocumentContext } from '../components/DocumentViewer/DocumentProvider';

function useIsDocumentEditor() {
  const context = React.useContext(DocumentContext);

  if (context === undefined) {
    throw new Error(
      'useIsEditingDocument must be used within a DocumentProvider component',
    );
  }

  return context.isEditor;
}

export default useIsDocumentEditor;
