import { AssetTypes } from '@compoundfinance/compound-core/dist/types/account';

const INVESTMENT_ACCOUNTS = 'investmentAccounts';
const BANK_ACCOUNTS = 'bankAccounts';
const LIABILITY_ACCOUNTS = 'liabilityAccounts';
const CREDIT_ACCOUNTS = 'creditAccounts';
const LOAD_ACCOUNTS = 'loanAccounts';
const ASSET_ACCOUNTS = 'assetAccounts';
const CRYPTO_ACCOUNTS = 'cryptoAccounts';
const OTHER_ACCOUNTS = 'otherAccounts';
export const COMPOUND_PORTFOLIO_NAME = 'Compound Managed Portfolio';

export const PLAID_FILTERED_ACCOUNT_TYPES = {
  INVESTMENT: INVESTMENT_ACCOUNTS,
  BANK: BANK_ACCOUNTS,
  LIABILITY: LIABILITY_ACCOUNTS,
  LOAN: LOAD_ACCOUNTS,
  CREDIT: CREDIT_ACCOUNTS,
  ASSET: ASSET_ACCOUNTS,
  CRYPTO: CRYPTO_ACCOUNTS,
  OTHER: OTHER_ACCOUNTS,
};

export const PlaidToAssetTypeMap = {
  [PLAID_FILTERED_ACCOUNT_TYPES.INVESTMENT]: AssetTypes.PublicInvestment,
  [PLAID_FILTERED_ACCOUNT_TYPES.BANK]: AssetTypes.Cash,
  [PLAID_FILTERED_ACCOUNT_TYPES.LOAN]: AssetTypes.Loan,
  [PLAID_FILTERED_ACCOUNT_TYPES.CREDIT]: AssetTypes.CreditCard,
  [PLAID_FILTERED_ACCOUNT_TYPES.CRYPTO]: AssetTypes.Crypto,
  [PLAID_FILTERED_ACCOUNT_TYPES.OTHER]: AssetTypes.Other,
};

export const PLAID_ACCOUNT_TYPES = {
  LOAN: 'loan',
  CREDIT: 'credit',
  INVESTMENT: 'investment',
  DEPOSITORY: 'depository',
  CRYPTO: 'cryptocurrency',
  OTHER: 'other',
} as const;
