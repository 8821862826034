import { Formik } from 'formik';
import isEqual from 'lodash/isEqual';
import {
  Box,
  Button,
  Col,
  Dialog,
  PercentField,
  Row,
  Separator,
  TaxIcon,
} from '@compoundfinance/design-system';
import { useMutableModuleProperty } from 'containers/Dashboard/Advisor/DocumentsAdmin/hooks/useMutableProperty';

type Props = {
  isTaxRateModalOpen: boolean;
  setIsTaxRateModalOpen: (isOpen: boolean) => void;
};

export interface TaxLossHarvestingTaxRates {
  federalLTCGRate: number;
  federalSTCGRate: number;
  stateLTCGRate: number;
  stateSTCGRate: number;
}

export default function TaxLossHarvestingTaxRateModal(props: Props) {
  const { isTaxRateModalOpen, setIsTaxRateModalOpen } = props;

  const [federalLTCGRate, setFederalLTCGRate] = useMutableModuleProperty(
    'federalLTCGRate',
    0,
  );
  const [federalSTCGRate, setFederalSTCGRate] = useMutableModuleProperty(
    'federalSTCGRate',
    0,
  );
  const [stateLTCGRate, setStateLTCGRate] = useMutableModuleProperty(
    'stateLTCGRate',
    0,
  );
  const [stateSTCGRate, setStateSTCGRate] = useMutableModuleProperty(
    'stateSTCGRate',
    0,
  );

  const onSubmit = (values: TaxLossHarvestingTaxRates) => {
    setFederalLTCGRate(values.federalLTCGRate);
    setFederalSTCGRate(values.federalSTCGRate);
    setStateLTCGRate(values.stateLTCGRate);
    setStateSTCGRate(values.stateSTCGRate);
    setIsTaxRateModalOpen(false);
  };

  return (
    <Dialog
      isOpen={isTaxRateModalOpen}
      setIsOpen={setIsTaxRateModalOpen}
      zIndex={999999}
      css={{ w: 450 }}
    >
      <Formik
        initialValues={{
          federalLTCGRate,
          federalSTCGRate,
          stateLTCGRate,
          stateSTCGRate,
        }}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formikProps) => {
          return (
            <>
              <Dialog.Header>
                <Row css={{ jc: 'center', pb: '$28' }}>
                  <Box
                    css={{
                      w: 'fit-content',
                      h: 'fit-content',
                      p: '$16',
                      bg: '$gray3',
                      br: '$10',
                    }}
                  >
                    <TaxIcon size={38} />
                  </Box>
                </Row>
                <Dialog.Title>Set Tax Rates</Dialog.Title>
              </Dialog.Header>
              <Col css={{ ai: 'center', gap: '$16' }}>
                <Separator
                  label="Federal"
                  align="left"
                  labelCss={{ fontSize: '$16', color: '$gray12' }}
                />
                <Row css={{ gap: '$16' }}>
                  <PercentField
                    label="LTCG rate"
                    name="federalLTCGRate"
                    onChange={(value) =>
                      formikProps.setFieldValue('federalLTCGRate', value)
                    }
                    value={formikProps.values.federalLTCGRate}
                  />
                  <PercentField
                    label="STCG rate"
                    name="federalSTCGRate"
                    onChange={(value) =>
                      formikProps.setFieldValue('federalSTCGRate', value)
                    }
                    value={formikProps.values.federalSTCGRate}
                  />
                </Row>
                <Separator
                  label="State"
                  align="left"
                  labelCss={{ fontSize: '$16', color: '$gray12' }}
                />
                <Row css={{ gap: '$16' }}>
                  <PercentField
                    label="LTCG rate"
                    name="stateLTCGRate"
                    onChange={(value) =>
                      formikProps.setFieldValue('stateLTCGRate', value)
                    }
                    value={formikProps.values.stateLTCGRate}
                  />
                  <PercentField
                    label="STCG rate"
                    name="stateSTCGRate"
                    onChange={(value) =>
                      formikProps.setFieldValue('stateSTCGRate', value)
                    }
                    value={formikProps.values.stateSTCGRate}
                  />
                </Row>
                <Col css={{ gap: '$12', w: '100%' }}>
                  <Button
                    css={{ w: '100%', h: '$48', fontSize: '$14' }}
                    disabled={isEqual(
                      formikProps.initialValues,
                      formikProps.values,
                    )}
                    onClick={formikProps.handleSubmit}
                  >
                    Save
                  </Button>
                  <Button
                    css={{ w: '100%', h: '$48', fontSize: '$14' }}
                    variant="ghost"
                    onClick={() => setIsTaxRateModalOpen(false)}
                  >
                    Cancel
                  </Button>
                </Col>
              </Col>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
}
