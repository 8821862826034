import {
  Button,
  ChevronDown,
  Collapsible,
  Text,
} from '@compoundfinance/design-system';
import AutoScroll from './AutoScroll';

interface Props {
  children: React.ReactNode;
  summary?: React.ReactNode;
}

function Disclosure(props: Props) {
  return (
    <Collapsible>
      <Collapsible.Trigger asChild>
        <Button
          type="button"
          css={{
            all: 'unset',
            p: '$2 0',
            '&:focus, &:hover': {
              color: 'inherit',
              boxShadow: 'none',
              textDecoration: 'underline',
              textUnderlineOffset: '2px',
            },
            '&[data-state=open]': {
              mb: '$4',

              '& svg': {
                transform: 'rotate(180deg)',
              },
            },
          }}
        >
          {props.summary || (
            <Text size="12" color="gray11">
              Disclosure
            </Text>
          )}

          <ChevronDown
            css={{
              ml: '$4',
              color: '$gray11',
              transition: 'transform 0.25s ease 0s',
            }}
            size={18}
          />
        </Button>
      </Collapsible.Trigger>

      <Collapsible.Content>
        <AutoScroll delay={200}>
          <Text as="p" size="12" color="gray11">
            {props.children}
          </Text>
        </AutoScroll>
      </Collapsible.Content>
    </Collapsible>
  );
}

export default Disclosure;
