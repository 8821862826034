import EditablePerson from 'containers/Dashboard/Document/components/EditablePerson';
import EditableTextArea from 'containers/Dashboard/Document/components/EditableTextArea';
import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import { getPerson } from 'containers/Dashboard/Document/utils';
import { Text, Col, Grid, Row } from '@compoundfinance/design-system';
import { DirectoryPerson } from 'domain/Employees/constants';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';

const INVESTMENT_MANAGEMENT_SERVICES = [
  {
    title: 'Custom Indexing Portfolio',
    description:
      'Compound’s Custom Indexing offering allows investors to construct professionally-managed portfolios that are fine-tuned to their risk appetite and existing investments and optimized for tax-loss harvesting.',
    url: 'https://compound-info.webflow.io/whitepapers/custom-indexing#s04',
  },
  {
    title: 'Compound’s Foundation Portfolio',
    description:
      'Compound’s managed portfolio is tax-optimized and diversified across 10 global asset classes to optimize your long-term, risk-adjusted return.',
    url: 'https://info.compoundplanning.com/foundation-portfolio',
  },
  {
    title: 'Access to alternative investment deals',
    description:
      'With Compound, you can access private, vetted investments (e.g. private real estate, credit, hedge funds, and venture capital) to help you preserve capital, generate income, and build long-term wealth.',
    url: 'https://compoundplanning.com/newsroom/access-alternative-investments-on-compound',
  },
];

const Container = styled(ModuleContainer, {
  py: '$24',
  px: '$24',
  gap: '$24',

  '@bp1': {
    py: '$44',
    px: '$48',
    gap: '$48',
  },
});

export interface InvestmentManagementProps {
  financialAdvisor: DirectoryPerson;
}

function InvestmentManagement(props: InvestmentManagementProps) {
  const { financialAdvisor } = props;

  const financialAdvisorPerson = getPerson(financialAdvisor);

  return (
    <Container>
      <Col css={{ h: '100%', gap: '$24', '@bp1': { gap: '$48' } }}>
        <Text
          weight="medium"
          color="gray8"
          css={{
            fontSize: '$20',
            letterSpacing: '-0.8px',

            '@bp1': { fontSize: '$48', lineHeight: '58px' },
          }}
        >
          Investment management
        </Text>

        <Grid
          css={{
            gap: '$48',
            '@bp1': {
              gridTemplateColumns: '1fr 1fr',
            },
          }}
        >
          <Col
            css={{
              h: '100%',
              gap: '$24',
              justifyContent: 'space-between',
              '@bp1': { gap: '$48' },
            }}
          >
            <Col css={{ gap: '$24' }}>
              <Text size="14" css={{ w: '100%' }}>
                <EditableTextArea
                  propertyKey="description"
                  value={`We will develop a holistic investment plan with consideration for taxes, liquidity, and ongoing surplus cash flow. An investment plan is especially pertinent after a liquidity event.\n\nHere are a few links that explore our investment philosophy and offering.`}
                />
              </Text>
              <EditablePerson
                propertyKey="financialAdvisor"
                propertyValue={financialAdvisor}
                person={financialAdvisorPerson}
                size="small"
              />
            </Col>
          </Col>

          <Col css={{ gap: '$24' }}>
            {INVESTMENT_MANAGEMENT_SERVICES.map((service) => (
              <Col
                key={service.title}
                css={{
                  p: '$16',
                  border: '1px solid $gray4',
                  br: '$4',
                  gap: '$16',
                }}
              >
                <Row css={{ jc: 'space-between', gap: '$8' }}>
                  <Text size="15" weight="medium" color="gray12">
                    {service.title}
                  </Text>

                  <Text
                    as="a"
                    size="15"
                    color="gray11"
                    css={{ textDecoration: 'underline', whiteSpace: 'nowrap' }}
                    href={service.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    More details →
                  </Text>
                </Row>
                <Text size="13" color="gray11">
                  {service.description}
                </Text>
              </Col>
            ))}
          </Col>
        </Grid>
      </Col>
    </Container>
  );
}

export default InvestmentManagement;
