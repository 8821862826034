import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';
import { Box, Col, Text } from '@compoundfinance/design-system';
import { DirectoryPerson } from 'domain/Employees/constants';
import ComparisonTable from './ComparisonTable';

const Container = styled(ModuleContainer, {
  p: '$24',

  '@bp1': {
    py: '$44',
    px: '$48',
  },
});

const ComparisonTableContainer = styled(Box, {
  width: '100%',
  position: 'relative',
});

export interface AdvisoryLandscapeProps {
  salesLead: DirectoryPerson;
}

function AdvisoryLandscape(props: AdvisoryLandscapeProps) {
  return (
    <Container>
      <Col css={{ gap: '$24', '@bp1': { gap: '$48' } }}>
        <Text
          weight="medium"
          color="gray8"
          css={{
            fontSize: '$20',
            letterSpacing: '-0.8px',
            flexShrink: 0,

            '@bp1': { fontSize: '$48', lineHeight: '58px' },
          }}
        >
          Advisory landscape
        </Text>
        <ComparisonTableContainer>
          <ComparisonTable size="100%" />
        </ComparisonTableContainer>
      </Col>
    </Container>
  );
}

export default AdvisoryLandscape;
