import React from 'react';

import {
  Box,
  Col,
  Flex,
  FlexTable,
  InformationIcon,
  Text,
  Tooltip,
} from '@compoundfinance/design-system';

import { ASSET_CATEGORIES } from '../constants';
import { NumberUtils } from '../../../../utils/NumberUtils';
import { ASSET_CATEGORIES_RETURN_AND_VOLATILITY } from '../VolatilityAndReturn';

export const ReturnAndVolatilityDisclaimer = ({
  trigger = (
    <InformationIcon
      css={{ cursor: 'help' }}
      size={14}
      color="var(--colors-gray9)"
    />
  ),
}: {
  trigger?: React.ReactNode;
}) => (
  <Tooltip delayDuration={0}>
    <Tooltip.Trigger asChild>
      <Box css={{ lineHeight: 0 }}>{trigger}</Box>
    </Tooltip.Trigger>
    <Tooltip.Content
      css={{
        borderRadius: '$10',
        w: '446px',
        minWidth: '446px',
        maxWidth: '446px',
        bg: '$gray0',
        p: '$28',
        boxShadow: '$small',
      }}
      hideArrow
    >
      <Col css={{ gap: '$20' }}>
        <Text size="12" weight="medium">
          Projected return & volatility assumptions
        </Text>

        <Flex css={{ width: 'calc(100% + 8px)', ml: '-4px' }}>
          <ReturnAndVolatilityTable />
        </Flex>

        <Text size="10" color="gray9">
          The above capital market projections do not take into account any
          Compound fees. However all return projections for Compound managed
          portfolios take into account both the above capital market assumptions
          and a fee estimate for the portfolio.
        </Text>
      </Col>
    </Tooltip.Content>
  </Tooltip>
);

const ROW_FLEX = {
  name: '1 1 auto',
  return: '0 0 80px',
  volatility: '0 0 80px',
};

const ASSET_CATEGORIES_WITH_RETURN_AND_VOLATILITY = Object.entries(
  ASSET_CATEGORIES_RETURN_AND_VOLATILITY,
);
const ReturnAndVolatilityTable = ({
  children,
}: {
  children?: React.ReactNode;
}) => (
  <Col css={{ w: '100%', gap: '$4' }}>
    <FlexTable
      role="table"
      aria-label="Projected annual return and volatility for each asset class"
      css={{
        p: 0,
        "& [role='row']": {
          '&::before': {
            content: 'none',
          },
        },
      }}
    >
      <FlexTable.Head
        css={{
          jc: 'space-between',
          ai: 'center',
          overflow: 'hidden',
        }}
      >
        <FlexTable.Row
          role="row"
          css={{ h: 'auto', py: '$2', px: '$4', ai: 'end', gap: '$20' }}
        >
          <FlexTable.HeadCell flex={ROW_FLEX.name} css={{ overflow: 'hidden' }}>
            <Text size="10" color="gray9">
              Asset class
            </Text>
          </FlexTable.HeadCell>
          <FlexTable.HeadCell
            flex={ROW_FLEX.return}
            align="right"
            css={{ overflow: 'hidden' }}
          >
            <Text size="10" color="gray9" css={{ textAlign: 'right' }}>
              Projected annual return
            </Text>
          </FlexTable.HeadCell>
          <FlexTable.HeadCell
            flex={ROW_FLEX.volatility}
            align="right"
            css={{ overflow: 'hidden' }}
          >
            <Text size="10" color="gray9" css={{ textAlign: 'right' }}>
              Projected annual volatility
            </Text>
          </FlexTable.HeadCell>
        </FlexTable.Row>
      </FlexTable.Head>
      <FlexTable.Content>
        {ASSET_CATEGORIES_WITH_RETURN_AND_VOLATILITY.map(
          ([slug, { annualReturn, annualVolatility }]) => (
            <FlexTable.Row
              key={slug}
              role="row"
              css={{
                h: '28px',
                py: '$2',
                px: '$4',
                gap: '$20',
              }}
            >
              <FlexTable.Cell flex={ROW_FLEX.name} css={{ overflow: 'hidden' }}>
                <Text
                  size="12"
                  css={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {ASSET_CATEGORIES[slug].name}
                </Text>
              </FlexTable.Cell>
              <FlexTable.Cell
                flex={ROW_FLEX.return}
                align="right"
                css={{ overflow: 'hidden' }}
              >
                <Text size="12" color="gray10">
                  {annualReturn != null
                    ? NumberUtils.sanitize(annualReturn)
                    : '-'}
                  %
                </Text>
              </FlexTable.Cell>
              <FlexTable.Cell
                flex={ROW_FLEX.volatility}
                align="right"
                css={{ overflow: 'hidden' }}
              >
                <Text size="12" color="gray10">
                  {annualVolatility != null
                    ? NumberUtils.sanitize(annualVolatility)
                    : '-'}
                  %
                </Text>
              </FlexTable.Cell>
            </FlexTable.Row>
          ),
        )}
      </FlexTable.Content>
    </FlexTable>

    {children}
  </Col>
);
