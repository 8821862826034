import {
  PlaidAccount,
  Institution,
} from '@compoundfinance/compound-core/dist/types/plaid';
import { IntegrationStatus } from 'utils/integrations/types';

export enum PlaidLinkEventTypes {
  Handoff = 'HANDOFF',
  Open = 'OPEN',
}

export enum InstitutionName {
  RobinHood = 'Robinhood',
  Chase = 'Chase',
  ETrade = 'E*TRADE Securities - Stock Plans',
  Titan = 'Titanvest',
  TDBank = 'TD Bank',
  WellsFargo = 'Wells Fargo',
  Betterment = 'Betterment',
  Citi = 'Citi',
  CitizensBank = 'Citizens Bank',
  Fidelity = 'Fidelity',
  Amex = 'American Express',
  Wealthfront = 'Wealthfront',
  CharlesSchwab = 'Charles Schwab',
  BankOfAmerica = 'Bank of America',
  Vanguard = 'Vanguard',
  Invesco = 'Invesco',
  iShares = 'iShares',
  StateStreetGlobalAdvisors = 'State Street Global Advisors',
  CompoundIntelligentInvest = 'Compound Intelligent Investing',
  Compound = 'Compound',
  // Place CoinbasePro before Coinbase to ensure that also assets that are manually named "Coinbase Pro" get the right logo
  CoinbasePro = 'Coinbase Pro',
  Coinbase = 'Coinbase',
  Binance = 'Binance',
  Gemini = 'Gemini',
  Trezor = 'Trezor',
  Bitmex = 'BitMEX',
  Ledger = 'Ledger',
  Huobi = 'Huobi',
  FirstTrustCA = 'First Trust California',
  NewportGroup = 'The Newport Group',
  Ethplorer = 'Ethereum',
  BlockchainDotCom = 'Bitcoin',
}

export enum PlaidAccountProvider {
  Plaid = 'plaid',
  SelfServe = 'selfServe',
  BlackDiamond = 'blackDiamond',
  Schawb = 'schwab',
  Yodlee = 'yodlee',
  Coinbase = 'coinbase',
  CoinbasePro = 'coinbasePro',
  Gemini = 'gemini',
  Ethereum = 'ethereum',
  Bitcoin = 'blockchainDotCom',
  Mx = 'mx',
  Orion = 'orion',
}
export const PROVIDERS_THAT_CAN_BE_HARD_REFRESHED = [
  PlaidAccountProvider.Plaid,
  PlaidAccountProvider.Mx,
  PlaidAccountProvider.Bitcoin,
];

export const DEFAULT_PLAID_ACCOUNT_ATTRS: PlaidAccount = {
  label: '',
  name: '',
  integrationId: '',
  id: '',
  notes: '',
  officialName: '',
  ownerId: '',
  institution: { name: '' } as Institution,
  type: 'depository',
  originalType: 'depository',
  subtype: 'Other',
  assetType: undefined,
  currentBalance: 0,
  accountSnapshots: [],
  mask: '',
  institutionId: '',
  investmentMetadatum: null,
  provider: '',
  ownership: { owners: null },
  assetCategory: null,
  plaidMortgage: null,
};

export const TEMPORARILY_DOWN = 'Disconnected';

export const plaidErrorStatus = [
  IntegrationStatus.Error,
  IntegrationStatus.PendingExpiration,
];

export enum InterestRateType {
  Fixed = 'fixed',
  Variable = 'variable',
}
