import { useRef } from 'react';
import { ResponsiveContainer, BarChart, Bar, YAxis, Tooltip } from 'recharts';

import {
  getRadiusSetting,
  labelKey,
} from 'containers/Dashboard/Accounts/NetWorthChart/MekkoChartLegacy/constants';
import { COMPOUND_COLORS } from 'style/theme';
import MekkoSectionLabel from 'containers/Dashboard/Accounts/NetWorthChart/MekkoChartLegacy/MekkoSectionLabel';
import UiUtils from 'utils/ui';
import { MekkoSectionDatum } from 'containers/Dashboard/Accounts/NetWorthChart/MekkoChartLegacy/types';
import MekkoTooltip from './MekkoTooltip';

const MARGINS = { left: 0, right: 0, top: 25, bottom: 8 };
const YDOMAIN = [0, 'dataMax'];

interface MekkoCategoryProps {
  chartIndex: number;
  data: MekkoSectionDatum;
  numCategories: number;
  totalAssets: number;
  chartMeasurements: Record<string, number>;
}

function MekkoCategory(props: MekkoCategoryProps) {
  const { data, totalAssets, chartIndex, numCategories, chartMeasurements } =
    props;

  const categoryType = data.type;
  const sectionName = data.name;
  const sectionData = data.accountData;
  const accountNames = sectionData.map((datum) => datum.label);
  const chartSize = Math.ceil(Math.abs(data.total / totalAssets) * 100);
  const activeBar = useRef<string>();

  function updateActiveBar(name: string) {
    if (activeBar.current !== name && name) {
      activeBar.current = name;
    }
  }

  return (
    <ResponsiveContainer width={`${chartSize}%`}>
      <BarChart
        data={[data.accountValueMap]}
        barCategoryGap={0}
        margin={MARGINS}
        className="animate-opacity"
        //@ts-ignore
        isAnimationActive={true}
      >
        <Tooltip
          cursor={false}
          content={(props) => {
            if (!props.active || !activeBar.current) {
              return null;
            }

            const accountDollarValue =
              data.accountValueMap[`${activeBar.current}-${labelKey}`];
            /**
             * Because this chart only shows assets with a positive value, and some asset accounts may have
             * a negative value, e.g. a margin trading account, we want to treat their value as zero
             * when calculating that account's percentage of overall assets,
             * as it does not positively affect net worth
             */
            const accountPercentValue =
              (accountDollarValue < 0 ? 0 : accountDollarValue / totalAssets) *
              100;

            // We need to perform these calculations because each section of the
            // mekko chart is its own recharts bar graph, which means we don't actually
            // know the overall (chart-wide) x offset of the current bar. So,
            // we manually track and calculate that offset.
            const { windowWidth } = chartMeasurements;
            const sectionOffset = chartMeasurements[sectionName];
            const totalOffset =
              chartMeasurements.chartXOffset + sectionOffset + 148;

            const xOffset = totalOffset >= windowWidth ? -148 : 0;

            return (
              <MekkoTooltip
                {...props}
                coordinate={props.coordinate as { x: number; y: number }}
                active={props.active as boolean}
                activeBar={activeBar.current}
                activeCategory={sectionName}
                dollarValue={accountDollarValue}
                percentValue={accountPercentValue}
                xOffset={xOffset}
              />
            );
          }}
        />
        <YAxis hide domain={YDOMAIN as any} />
        {accountNames.map((key: string, barIndex: number) => {
          const color = `var(--colors-${COMPOUND_COLORS[categoryType]}9)`;

          const radius = getRadiusSetting(
            chartIndex,
            barIndex,
            numCategories,
            accountNames.length,
          );
          const dollarValue = data.accountValueMap[`${key}-${labelKey}`];
          const percent = UiUtils.getPercent(dollarValue, totalAssets);
          const formattedPercent =
            percent < 0.01 ? '0.00' : UiUtils.formatDecimals(percent, 1, 1);

          return (
            <Bar
              key={key}
              //@ts-ignore
              maxPointSize={100}
              radius={radius}
              isAnimationActive={false}
              dataKey={key}
              stackId={categoryType}
              fill={color}
              label={(labelProps) => {
                return (
                  <MekkoSectionLabel
                    onMouseEnter={() => updateActiveBar(key)}
                    onMouseLeave={() => updateActiveBar('')}
                    accountName={key}
                    percentage={formattedPercent}
                    radius={radius}
                    // { reversed because these svgs are drawn in reverse order}
                    isLastSection={barIndex === 0}
                    isLastCategory={chartIndex === numCategories - 1}
                    {...labelProps}
                  />
                );
              }}
            />
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default MekkoCategory;
