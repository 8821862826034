import { useState } from 'react';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';

import {
  Box,
  Button,
  Col,
  EyeHideIcon,
  EyeIcon,
  Flex,
  ModalIcon,
  Row,
  TColorsKey,
  Text,
} from '@compoundfinance/design-system';

import {
  AllocationProposal,
  AllocationProposalTypes,
} from 'containers/Dashboard/AllocationProposals/domain/AllocationProposal';
import { TIME_RANGE_PERFORMANCE_CHART_OPTIONS } from 'containers/Dashboard/AllocationProposals/components/AggregatedProjectedPerformance';
import useIsDocumentEditor from 'containers/Dashboard/Advisor/DocumentsAdmin/hooks/useIsDocumentEditor';
import { useMutableModuleProperty } from 'containers/Dashboard/Advisor/DocumentsAdmin/hooks/useMutableProperty';
import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import EditableTextArea from 'containers/Dashboard/Document/components/EditableTextArea';
import EditablePerson from 'containers/Dashboard/Document/components/EditablePerson';
import { getPerson } from 'containers/Dashboard/Document/utils';
import { DirectoryPerson } from 'domain/Employees/constants';
import { MOMENT_FULL_DATETIME_STR } from 'utils/constants/date';
import DateUtils from '@compoundfinance/compound-core/dist/date-utils';

import { VisibilityMode } from '../types';

import PerformanceChart from 'containers/Dashboard/AllocationProposals/components/Charts/PerformanceChart';
import TimeRange from 'containers/Dashboard/AllocationProposals/components/ui/TimeRange';
import { LineGrid } from 'containers/Dashboard/AllocationProposals/components/Charts/Chartifacts/Grids/LineGrid';
import { ProposedAllocationChanges } from './ProposedAllocationChanges';
import { PercentageComparison } from '../../../components/Figures';
import { EditableSectionTitle } from 'containers/Dashboard/Document/components/SectionTitle';
import { ReturnAndVolatilityDisclaimer } from 'containers/Dashboard/AllocationProposals/components/ReturnAndVolatilityDisclaimer';

const Container = styled(ModuleContainer, {
  display: 'flex',
  flexDirection: 'column',
  py: '$24',
  px: '$24',
  gap: '$24',

  '@bp1': {
    py: '$44',
    px: '$48',
    gap: '$48',
  },
});

export interface ProposedAllocationProps {
  allocationProposal: AllocationProposalTypes.InvestmentAllocationProposal;
  financialAdvisor: DirectoryPerson;
  title: string;
  visibility: Record<string, VisibilityMode>;
}

function ProposedAllocation(props: ProposedAllocationProps) {
  const {
    allocationProposal: investmentAllocationProposal,
    financialAdvisor,
    title,
    visibility = {},
  } = props;
  const { singleStockExposure: singleStockExposureVisibility = 'hidden' } =
    visibility;

  const allocationProposal = AllocationProposal.withBlended(
    investmentAllocationProposal,
  );

  const isEditor = useIsDocumentEditor();

  const [, setVisibility] = useMutableModuleProperty('visibility', visibility);

  const [years, setYears] = useState(10);

  const {
    updatedAt,
    blended: {
      original: { wholistic: wholisticOriginal },
      target: { wholistic: wholisticTarget },
    },
  } = allocationProposal;

  const financialAdvisorPerson = getPerson(financialAdvisor);

  return (
    <Container css={{ gap: '$48' }}>
      <Row css={{ jc: 'space-between', ai: 'center', gap: '$40' }}>
        <EditableSectionTitle title={title} />

        <Flex
          css={{
            p: '$8 $10',
            height: 'fit-content',
            bg: '$gray2',
            br: '$6',
            ai: 'center',
            jc: 'center',
          }}
        >
          <Text size="13" color="gray11">
            Data imported{' '}
            {DateUtils.formatToStr(
              new Date(updatedAt),
              false,
              MOMENT_FULL_DATETIME_STR,
            )}
          </Text>
        </Flex>
      </Row>

      <ProposedAllocationChanges allocationProposal={allocationProposal} />

      <Row css={{ minHeight: '260px', gap: '$48' }}>
        <Col css={{ flex: '0 0 65%', gap: '$12' }}>
          <Row css={{ pr: '148px', jc: 'space-between', ai: 'center' }}>
            <Text size="15" weight="medium">
              Projected returns
            </Text>

            <Row css={{ ai: 'center', gap: '$8' }}>
              <TimeRange<number>
                options={TIME_RANGE_PERFORMANCE_CHART_OPTIONS.filter(
                  (option) => option.value < 50,
                ).map((timeRangePerformanceChartOption) => ({
                  ...timeRangePerformanceChartOption,
                  isSelected: timeRangePerformanceChartOption.value === years,
                }))}
                onChange={setYears}
              />
            </Row>
          </Row>

          <PerformanceChart
            years={years}
            blendedAllocationProposals={[
              {
                id: 'original',
                blended: wholisticOriginal,
                metadata: {
                  label: 'Initial',
                  bg: 'var(--colors-gray3)',
                  stroke: 'var(--colors-gray8)',
                  color: 'gray11',
                },
              },
              {
                id: 'target',
                blended: wholisticTarget,
                metadata: {
                  label: 'Target',
                  bg: 'var(--colors-violet3)',
                  stroke: 'var(--colors-violet8)',
                  color: 'violet11' as TColorsKey,
                },
              },
            ]}
            grid={({ height, width, points }) => (
              <LineGrid height={height} width={width} points={points} />
            )}
          />
        </Col>

        <Col
          css={{
            w: '280px',
            gap: '$24',
          }}
        >
          <Col css={{ gap: '$16' }}>
            <Col css={{ gap: '$4' }}>
              <Text size="13" weight="medium" color="gray9">
                Forward Projected Risk & Return
              </Text>

              <Box css={{ h: '1px', w: '280px', bg: '$gray4' }} />
            </Col>

            <Row css={{ jc: 'space-between' }}>
              <Text size="13" weight="medium">
                Annual Return
              </Text>
              <Row css={{ ai: 'center', gap: '$4' }}>
                <PercentageComparison
                  original={wholisticOriginal.annualReturn}
                  target={wholisticTarget.annualReturn}
                />
              </Row>
            </Row>
            <Row css={{ jc: 'space-between' }}>
              <Text size="13" weight="medium">
                Annual Volatility
              </Text>
              <Row css={{ ai: 'center', gap: '$4' }}>
                <PercentageComparison
                  original={wholisticOriginal.annualVolatility}
                  target={wholisticTarget.annualVolatility}
                />
              </Row>
            </Row>
          </Col>

          {(singleStockExposureVisibility === 'visible' || isEditor) && (
            <Col css={{ gap: '$16' }}>
              <Col css={{ gap: '$4' }}>
                <Row css={{ ai: 'center', gap: '$8' }}>
                  {isEditor && (
                    <Button
                      variant="internal"
                      css={{
                        h: '24px',
                        w: '24px',
                        display: 'grid',
                        placeItems: 'center',
                      }}
                      onClick={() => {
                        if (singleStockExposureVisibility === 'visible')
                          setVisibility({
                            ...visibility,
                            singleStockExposure: 'hidden',
                          });
                        if (singleStockExposureVisibility === 'hidden')
                          setVisibility({
                            ...visibility,
                            singleStockExposure: 'visible',
                          });
                      }}
                    >
                      {singleStockExposureVisibility === 'visible' && (
                        <EyeIcon size={18} />
                      )}
                      {singleStockExposureVisibility === 'hidden' && (
                        <EyeHideIcon size={18} />
                      )}
                    </Button>
                  )}

                  <Text size="13" weight="medium" color="gray9">
                    Exposure
                  </Text>
                </Row>

                {singleStockExposureVisibility === 'hidden' && (
                  <Text size="11" color="gray9">
                    Hidden to clients
                  </Text>
                )}

                <Box css={{ h: '1px', w: '280px', bg: '$gray4' }} />
              </Col>

              <Row css={{ jc: 'space-between' }}>
                <Text size="13" weight="medium">
                  Single Stock Exposure
                </Text>
                <Row css={{ ai: 'center', gap: '$4' }}>
                  <PercentageComparison
                    original={wholisticOriginal.singleStockExposure}
                    target={wholisticTarget.singleStockExposure}
                  />
                </Row>
              </Row>
            </Col>
          )}
        </Col>
      </Row>

      <Col css={{ w: 'calc(65% - 148px)', gap: '$28' }}>
        <EditableTextArea
          propertyKey="introText"
          value={`The above is a visualization of how the asset allocation of your entire balance sheet would change after our proposed reallocation.`}
          textCss={{
            fontSize: '$14',
            w: '100%',
          }}
        />
        <EditablePerson
          propertyKey="financialAdvisor"
          propertyValue={financialAdvisor}
          person={financialAdvisorPerson}
          size="small"
          level="document"
        />
      </Col>

      <Flex css={{ jc: 'end', ai: 'center' }}>
        <ReturnAndVolatilityDisclaimer
          trigger={
            <Row css={{ ai: 'center', gap: '$4' }}>
              <Text size="12" color="gray9">
                Capital market assumptions
              </Text>

              <ModalIcon
                css={{ cursor: 'help' }}
                size={16}
                color="var(--colors-gray9)"
              />
            </Row>
          }
        />
      </Flex>
    </Container>
  );
}

export default ProposedAllocation;
