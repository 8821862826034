import { setViewCompanyEquityModalOpen, setCurrentAsset } from 'actions/assets';
import { useDispatch } from 'react-redux';
import { CompoundAsset } from 'components/EquityManagement/Asset/types';
import { isGrantOrCertificate, ViewCompanyEquityModalSteps } from './types';

function useViewCompanyEquityModal() {
  const dispatch = useDispatch();

  function closeViewCompanyEquityModal() {
    dispatch(setViewCompanyEquityModalOpen(false));
  }

  function openViewCompanyEquityModal(
    asset = {},
    step?: ViewCompanyEquityModalSteps,
  ) {
    dispatch(setCurrentAsset(asset as CompoundAsset));
    dispatch(
      setViewCompanyEquityModalOpen(
        true,
        step ??
          (isGrantOrCertificate(asset as any)
            ? ViewCompanyEquityModalSteps.HOLDINGS
            : ViewCompanyEquityModalSteps.GENERAL),
      ),
    );
  }

  return { openViewCompanyEquityModal, closeViewCompanyEquityModal };
}

export default useViewCompanyEquityModal;
