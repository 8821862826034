import { SvgProps } from './types';

interface CompoundCFillProps extends SvgProps {
  height?: string;
  width?: string;
}

// Version without the gradient
export const CompoundCFill = (props: CompoundCFillProps) => {
  const { color = 'black', width = 30, height = 35, ...rest } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M28.9699 24.4692L29.9737 25.4599C28.0574 29.0625 23.5862 34.1962 16.7424 34.1962C7.25247 34.1962 0.5 26.901 0.5 17.4441C0.5 8.25754 6.97871 0.511986 16.8337 0.511986C24.6812 0.511986 29.3349 5.46555 29.3349 9.78867C29.3349 12.4006 27.3274 14.2018 24.9549 14.2018C22.4912 14.2018 20.5749 12.3105 20.5749 9.96879C20.5749 7.71714 22.0349 5.73575 24.2249 5.73575C23.0387 3.93447 21.2137 3.03378 18.3849 3.03378C13.6399 3.03378 9.71623 6.27615 9.71623 14.2018C9.71623 23.9288 14.0962 28.342 20.3012 28.342C24.3162 28.432 26.5974 26.5407 28.9699 24.4692Z"
        fill={color}
      />
    </svg>
  );
};

export const CompoundCRounded = (props: SvgProps) => {
  const { size = '32', ...rest } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_162_16040)">
        <rect
          width="32"
          height="32"
          transform="translate(0.5)"
          fill="var(--colors-gray12)"
          rx="100%"
        />
        <path
          d="M23.5355 19.5764L24.0295 20.0846C23.0605 21.8917 20.8373 24.4705 17.417 24.4705C12.6857 24.4705 9.28442 20.7811 9.28442 16.0564C9.28442 11.4446 12.5147 7.5293 17.455 7.5293C21.3693 7.5293 23.7255 10.014 23.7255 12.2164C23.7255 13.534 22.7374 14.4375 21.5403 14.4375C20.3052 14.4375 19.3362 13.4964 19.3362 12.3105C19.3362 11.1999 20.0582 10.2022 21.1793 10.2022C20.6093 9.29871 19.6782 8.84694 18.2531 8.84694C15.8969 8.84694 13.9208 10.4846 13.9208 14.4564C13.9208 19.3505 16.1059 21.5528 19.2222 21.5528C21.1983 21.5528 22.3574 20.6305 23.5355 19.5764Z"
          fill="var(--colors-gray0)"
        />
      </g>
      <defs>
        <clipPath id="clip0_162_16040">
          <path
            d="M0.5 16C0.5 7.16344 7.66344 0 16.5 0C25.3366 0 32.5 7.16344 32.5 16C32.5 24.8366 25.3366 32 16.5 32C7.66344 32 0.5 24.8366 0.5 16Z"
            fill="var(--colors-gray0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
