import React, { useState } from 'react';
import { ValueOf } from 'types';

import { Col, Flex, Switcher } from '@compoundfinance/design-system';

import { AllocationProposalTypes } from '../domain/AllocationProposal';

export const AssetsAndAssetClassesType = {
  ASSET_CLASSES: 'assetClasses',
  REPRESENTATIONAL_ASSETS: 'representationalAssets',
} as const;
export type AssetsAndAssetClassesType = ValueOf<
  typeof AssetsAndAssetClassesType
>;

const ASSETS_AND_ASSET_CLASSES_TYPE_TO_BLENDED_ALLOCATIONS = {
  [AssetsAndAssetClassesType.REPRESENTATIONAL_ASSETS]: 'representationalAssets',
  [AssetsAndAssetClassesType.ASSET_CLASSES]: 'allocations',
} as const satisfies Record<
  AssetsAndAssetClassesType,
  keyof AllocationProposalTypes.BlendendAllocationProposal
>;
export const getAllocationsByType = (
  blendedAllocation: AllocationProposalTypes.BlendendAllocationProposal,
  type: AssetsAndAssetClassesType,
) => {
  if (type === AssetsAndAssetClassesType.ASSET_CLASSES)
    return blendedAllocation[
      ASSETS_AND_ASSET_CLASSES_TYPE_TO_BLENDED_ALLOCATIONS[
        AssetsAndAssetClassesType.ASSET_CLASSES
      ]
    ];
  if (type === AssetsAndAssetClassesType.REPRESENTATIONAL_ASSETS)
    return blendedAllocation[
      ASSETS_AND_ASSET_CLASSES_TYPE_TO_BLENDED_ALLOCATIONS[
        AssetsAndAssetClassesType.REPRESENTATIONAL_ASSETS
      ]
    ];
  throw new Error('Unreachable');
};

export const AssetsAndAssetClasses = ({
  assets,
  assetClasses,
  footer = ({
    setActiveTab,
  }: {
    setActiveTab: React.Dispatch<AssetsAndAssetClassesType>;
  }) => <AssetsAndAssetClassesSwitcher setActiveTab={setActiveTab} />,
  header = () => null,
}: {
  assets?: React.ReactNode;
  assetClasses?: React.ReactNode;
  header?:
    | (({
        setActiveTab,
      }: {
        setActiveTab: React.Dispatch<AssetsAndAssetClassesType>;
      }) => React.ReactNode)
    | React.ReactNode;
  footer?:
    | (({
        setActiveTab,
      }: {
        setActiveTab: React.Dispatch<AssetsAndAssetClassesType>;
      }) => React.ReactNode)
    | React.ReactNode;
}) => {
  const [activeTab, setActiveTab] = useState<AssetsAndAssetClassesType>(
    AssetsAndAssetClassesType.ASSET_CLASSES,
  );

  return (
    <Switcher
      value={activeTab}
      onChange={setActiveTab}
      css={{ h: '100%', w: '100%' }}
    >
      <Col css={{ h: '100%', w: '100%', gap: '$20' }}>
        {typeof header === 'function' ? header({ setActiveTab }) : header}

        <Switcher.Content
          value={AssetsAndAssetClassesType.REPRESENTATIONAL_ASSETS}
          asChild
        >
          <Flex css={{ h: '100%', w: '100%' }}>{assets}</Flex>
        </Switcher.Content>

        <Switcher.Content
          value={AssetsAndAssetClassesType.ASSET_CLASSES}
          asChild
        >
          <Flex css={{ h: '100%', w: '100%' }}>{assetClasses}</Flex>
        </Switcher.Content>

        {typeof footer === 'function' ? footer({ setActiveTab }) : footer}
      </Col>
    </Switcher>
  );
};

export const AssetsAndAssetClassesSwitcher = ({
  setActiveTab,
}: {
  setActiveTab: React.Dispatch<AssetsAndAssetClassesType>;
}) => (
  <Flex css={{ w: '160px' }}>
    <Switcher.List css={{ h: '$32' }}>
      <Switcher.Trigger
        value={AssetsAndAssetClassesType.ASSET_CLASSES}
        onClick={(event) => {
          event.preventDefault();
          setActiveTab(AssetsAndAssetClassesType.ASSET_CLASSES);
        }}
      >
        Asset classes
      </Switcher.Trigger>
      <Switcher.Trigger
        value={AssetsAndAssetClassesType.REPRESENTATIONAL_ASSETS}
        onClick={(event) => {
          event.preventDefault();
          setActiveTab(AssetsAndAssetClassesType.REPRESENTATIONAL_ASSETS);
        }}
      >
        Assets
      </Switcher.Trigger>
    </Switcher.List>
  </Flex>
);
