import groupBy from 'lodash/groupBy';

import { AssetSource } from 'domain/AssetSource';
import { ValueOf } from 'types';

import { AllocationAsset } from '../../Accounts/AssetAllocation/AllocationExplorer';
import { NumberUtils } from '../../../../utils/NumberUtils';
import { SimplifyOriginalAsset } from '../utils/types';
import {
  ALLOCATION_TYPES,
  Allocation,
  AllocationMessageTypes,
  AllocationTypes,
} from './Allocation';
import { AllocationProposalStrategyType } from './AllocationProposalStrategy';
import { StrategicAllocationProposalStrategyTypes } from './Strategic/StrategicAllocationProposalStrategy';
import {
  EditableAllocation,
  EditableAllocationTypes,
} from './EditableAllocation';
import { Transition, TransitionTypes } from './Transition';

export const LiquidityType = {
  Liquid: 'liquid',
  Illiquid: 'illiquid',
} as const;
export const LiquidityTypes = [
  LiquidityType.Liquid,
  LiquidityType.Illiquid,
] as const;
export type LiquidityType = ValueOf<typeof LiquidityType>;

export const TaxTreatment = {
  Taxable: 'taxable',
  Retirement: 'retirement',
} as const;
export const TaxTreatments = [
  TaxTreatment.Taxable,
  TaxTreatment.Retirement,
] as const;
export type TaxTreatment = ValueOf<typeof TaxTreatment>;

export namespace InvestmentTargetMessageTypes {
  export type InvestmentTargetMessage = {
    id?: string;
    name: string;
    weight: number;
    taxTreatment: TaxTreatment;
    liquidityType: LiquidityType;
    transitions: AllocationMessageTypes.AllocationMessage[];
    assetId: string | null;
    assetSource: AssetSource | null;
    updatedAt?: string;
    createdAt?: string;
  };
}

export namespace InvestmentTargetTypes {
  export type InvestmentTarget = {
    id: string;
    name: string;
    weight: number;
    taxTreatment: TaxTreatment;
    liquidityType: LiquidityType;
    transitions: TransitionTypes.Transition[];
    asset: SimplifyOriginalAsset<AllocationAsset> | null;
    assetId: string | null;
    assetSource: AssetSource | null;
    assetAllocation: Record<string, AllocationTypes.CategoryAllocation>;
    updatedAt?: string;
    createdAt?: string;
  };
}

export const InvestmentTarget = {
  asClientAllocation: (
    investmentTarget: InvestmentTargetTypes.InvestmentTarget,
  ): AllocationTypes.ClientAccountAllocation => {
    const weight = InvestmentTarget.totalWeight(investmentTarget);
    const value = InvestmentTarget.totalValue(investmentTarget);
    return {
      id: `${investmentTarget.id ?? ''}#${investmentTarget.assetId}`,
      name: investmentTarget.name,
      slug: `investmentTarget#${investmentTarget.id}`,
      assetCategorySlug: 'other-assets',
      weight,
      value,
      asset: investmentTarget.asset ?? {
        name: investmentTarget.name,
        weight,
        value,
        assetCategorySlug: 'other-assets',
        assetCategorization: {
          categorizations: [
            { assetCategory: { slug: 'other-assets' }, share: 100 },
          ],
        },
        originalAsset: undefined,
      },
      assetId: investmentTarget.assetId ?? '',
      assetSource: 'plaidAccount',
      assetAllocation: investmentTarget.assetAllocation,
      allocationType: ALLOCATION_TYPES.ACCOUNT,
      isOmitted: false,
    };
  },
  computeNetworth: (
    investmentTarget: Pick<
      InvestmentTargetTypes.InvestmentTarget,
      'transitions'
    >,
  ) =>
    investmentTarget.transitions.reduce(
      (networth, transition) =>
        NumberUtils.add(networth, Allocation.value(transition)),
      0,
    ),
  isLiquid: (
    investmentTarget: Pick<
      InvestmentTargetTypes.InvestmentTarget,
      'liquidityType'
    >,
  ) => investmentTarget.liquidityType === LiquidityType.Liquid,
  isIlliquid: (
    investmentTarget: Pick<
      InvestmentTargetTypes.InvestmentTarget,
      'liquidityType'
    >,
  ) => investmentTarget.liquidityType === LiquidityType.Illiquid,
  isCustom: (
    investmentTarget: Pick<InvestmentTargetTypes.InvestmentTarget, 'assetId'>,
  ) => investmentTarget.assetId == null,
  createAllocationProposalStrategy: (
    investmentTarget: InvestmentTargetTypes.InvestmentTarget,
  ): StrategicAllocationProposalStrategyTypes.CategorizedInvestmentTargetAllocationProposalStrategy => ({
    id: `ALLOCATION_PROPOSAL_STRATEGY#${investmentTarget.id}`,
    type: AllocationProposalStrategyType.Target,
    networth: 0,
    allocations: investmentTarget.assetAllocation,
    investmentTarget,
  }),
  toAllocationProposalStrategy: (
    allocationProposalInvestmentTargets: StrategicAllocationProposalStrategyTypes.CategorizedInvestmentTargetAllocationProposalStrategy[],
    editables: EditableAllocationTypes.EditableAllocation[],
  ): StrategicAllocationProposalStrategyTypes.CategorizedInvestmentTargetAllocationProposalStrategy[] => {
    const transitions = EditableAllocation.toTransitions(editables).filter(
      EditableAllocation.hasInvestmentTarget,
    );
    const transitionsGroupedByInvestmentTarget = groupBy(
      transitions,
      (transition) => transition.investmentTarget?.id,
    );
    return allocationProposalInvestmentTargets.map(
      (allocationProposalInvestmentTarget) => {
        const { investmentTarget } = allocationProposalInvestmentTarget;
        const transitions =
          transitionsGroupedByInvestmentTarget[investmentTarget.id] ?? [];
        return {
          ...allocationProposalInvestmentTarget,
          networth: InvestmentTarget.computeNetworth({
            transitions,
          }),
          investmentTarget: {
            ...investmentTarget,
            transitions: transitions.map(
              Transition.fromEditableAllocationTransitions,
            ),
          },
        };
      },
    );
  },
  liquidityType: (investmentTarget: InvestmentTargetTypes.InvestmentTarget) =>
    investmentTarget.liquidityType,
  taxTreatment: (investmentTarget: InvestmentTargetTypes.InvestmentTarget) =>
    investmentTarget.taxTreatment,
  totalValue: (investmentTarget: InvestmentTargetTypes.InvestmentTarget) =>
    NumberUtils.add(
      InvestmentTarget.value(investmentTarget),
      InvestmentTarget.transitionsValue(investmentTarget),
    ),
  totalWeight: (investmentTarget: InvestmentTargetTypes.InvestmentTarget) =>
    NumberUtils.add(
      InvestmentTarget.weight(investmentTarget),
      InvestmentTarget.transitionsWeight(investmentTarget),
    ),
  transitionsValue: (
    investmentTarget: InvestmentTargetTypes.InvestmentTarget,
  ) =>
    investmentTarget.transitions.reduce(
      (totalValue, transition) =>
        NumberUtils.add(totalValue, Allocation.value(transition)),
      0,
    ),
  transitionsWeight: (
    investmentTarget: InvestmentTargetTypes.InvestmentTarget,
  ) =>
    investmentTarget.transitions.reduce(
      (totalWeight, transition) =>
        NumberUtils.add(totalWeight, Allocation.weight(transition)),
      0,
    ),
  value: (investmentTarget: InvestmentTargetTypes.InvestmentTarget) =>
    investmentTarget.asset?.value ?? 0,
  weight: (investmentTarget: InvestmentTargetTypes.InvestmentTarget) =>
    investmentTarget.weight ?? 0,
};
