import { SvgProps } from 'assets/svg/types';

export const Error = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.8934 0C4.76079 0 4.63361 0.0526784 4.53984 0.146447L0.146447 4.53984C0.0526784 4.63361 0 4.76079 0 4.8934V11.1066C0 11.2392 0.0526786 11.3664 0.146447 11.4602L4.53984 15.8536C4.63361 15.9473 4.76079 16 4.8934 16H11.1066C11.2392 16 11.3664 15.9473 11.4602 15.8536L15.8536 11.4602C15.9473 11.3664 16 11.2392 16 11.1066V4.8934C16 4.76079 15.9473 4.63361 15.8536 4.53984L11.4602 0.146447C11.3664 0.0526784 11.2392 0 11.1066 0H4.8934ZM4.14547 10.9L7.04526 8.00019L4.14565 5.10006C3.95039 4.9048 3.95039 4.58822 4.14565 4.39296L4.39314 4.14547C4.5884 3.95021 4.90498 3.95021 5.10025 4.14547L7.99985 7.0456L10.8997 4.14547C11.095 3.95021 11.4115 3.95021 11.6068 4.14547L11.8543 4.39296C12.0495 4.58822 12.0495 4.9048 11.8543 5.10006L8.95445 8.0002L11.8543 10.9C12.0495 11.0953 12.0495 11.4119 11.8543 11.6071L11.6068 11.8546C11.4115 12.0499 11.095 12.0499 10.8997 11.8546L7.99985 8.95479L5.10006 11.8546C4.9048 12.0499 4.58822 12.0499 4.39296 11.8546L4.14547 11.6071C3.95021 11.4119 3.95021 11.0953 4.14547 10.9Z"
      fill="var(--colors-red9)"
    />
  </svg>
);

export const Success = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99984 16.0004C12.4183 16.0004 16.0002 12.4185 16.0002 8.00008C16.0002 3.58162 12.4183 -0.000244141 7.99984 -0.000244141C3.58138 -0.000244141 -0.000488281 3.58162 -0.000488281 8.00008C-0.000488281 12.4185 3.58138 16.0004 7.99984 16.0004ZM12.7658 5.37561C12.9571 5.5669 12.9571 5.87703 12.7658 6.06832L7.15241 11.6839C6.96112 11.8752 6.65098 11.8752 6.4597 11.6839L3.73671 8.9622C3.54543 8.77091 3.54543 8.46078 3.73671 8.26949L3.97916 8.02705C4.17044 7.83576 4.48058 7.83576 4.67186 8.02705L6.80605 10.16L11.8306 5.13317C12.0219 4.94188 12.3321 4.94188 12.5234 5.13317L12.7658 5.37561Z"
      fill="var(--colors-green9)"
    />
  </svg>
);

// We have a Warning icon in our app already, but can't pass a custom fill to it there
export const Warning = () => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.51045 0.299482C9.28358 -0.099827 8.71642 -0.0998275 8.48955 0.299482L0.0798457 15.1016C-0.147019 15.5009 0.136561 16 0.590292 16H17.4097C17.8634 16 18.147 15.5009 17.9202 15.1016L9.51045 0.299482ZM9.67255 11.15L9.85754 5.39067H8.13655L8.32715 11.15H9.67255ZM8.33836 12.3292C8.17393 12.4887 8.09171 12.69 8.09171 12.9331C8.09171 13.1685 8.17393 13.3679 8.33836 13.5312C8.50654 13.6945 8.72703 13.7762 8.99985 13.7762C9.27641 13.7762 9.4969 13.6945 9.66134 13.5312C9.82578 13.3679 9.90799 13.1685 9.90799 12.9331C9.90799 12.69 9.82578 12.4887 9.66134 12.3292C9.4969 12.1659 9.27641 12.0843 8.99985 12.0843C8.72703 12.0843 8.50654 12.1659 8.33836 12.3292Z"
      fill="var(--colors-yellow10)"
    />
  </svg>
);

export const Info = (props: SvgProps) => {
  const { size = '16', fill = 'var(--colors-gray12)', ...rest } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM9.5 4.75C9.5 5.30228 9.05228 5.75 8.5 5.75C7.94772 5.75 7.5 5.30228 7.5 4.75C7.5 4.19772 7.94772 3.75 8.5 3.75C9.05228 3.75 9.5 4.19772 9.5 4.75ZM7.63548 7.75H7C6.72386 7.75 6.5 7.52614 6.5 7.25V6.9C6.5 6.62386 6.72386 6.4 7 6.4H8.72414C9.04942 6.4 9.2881 6.70569 9.20921 7.02127L8.36452 10.9H9C9.27614 10.9 9.5 11.1239 9.5 11.4V11.75C9.5 12.0261 9.27614 12.25 9 12.25H7.27586C6.95058 12.25 6.7119 11.9443 6.79079 11.6287L7.63548 7.75Z"
        fill={fill}
      />
    </svg>
  );
};
