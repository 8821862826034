import { createGlobalStyle } from 'styled-components';

/**
 * Non-atomic styles shared across the app
 */
const CompoundStyle = createGlobalStyle`
  .save-button {
    background-color: ${({ theme }) => theme.colors.muted_blue} !important;
    color: ${({ theme }) => theme.colors.white} !important;
    border: 1px solid ${({ theme }) => theme.colors.muted_blue} !important;
  }

  .cancel-button {
    background-color: ${({ theme }) => theme.colors.transparent} !important;
    color: ${({ theme }) => theme.colors.black_grey} !important;
    border: ${({ theme }) => theme.border} !important;
  }

  .delete-button {
    background-color: ${({ theme }) => theme.colors.transparent} !important;
    color: ${({ theme }) => theme.colors.red} !important;
    border: ${({ theme }) => theme.border} !important;
  }

  .edit-button {
    background-color: ${({ theme }) => theme.colors.transparent} !important;
    color: ${({ theme }) => theme.colors.black_grey} !important;
    border: ${({ theme }) => theme.border} !important;
  }

  .compound-button {
    transition: 0.2s ease;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.muted_blue};
    border: 1px solid ${({ theme }) => theme.colors.muted_blue};
    border-radius: .25rem;
    outline: none;
    font-size: 0.875rem;
    height: 2.5rem;
    font-weight: ${({ theme }) => theme.fw.medium};
    width: fit-content;
    padding: 0 1.25rem;
    &:focus {
      outline: none;
    }
    &.inactive, &:disabled {
      pointer-events: none;
      background-color: ${({ theme }) => theme.colors.inactive_grey};
      border-color: ${({ theme }) => theme.colors.inactive_grey};
    }
    &:hover {
      opacity: 0.9;
    }
  }


  .compound-button-xs {
    padding: 0.125rem 0.5rem;
    font-size: 0.75rem;
    height: auto;
    width: auto;
    line-height: normal;
  }

  .compound-button-sm {
    padding: 0.4375rem 0.5625rem;
    font-size: 0.8125rem;
    height: auto;
    width: auto;
    line-height: normal;
  }

  .compound-drop-shadow {
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.11);
  }
  .compound-darker-shadow {
    box-shadow: 0px 1px 6px rgba(100, 100, 100, 0.25);
  }
  .compound-deep-shadow {
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.35);
  }

  .box-shadow-before {
    position: relative;

    &:before {
      border-radius: 6px;
      box-shadow: 0px -2px 12px rgba(0, 0, 0, 0.06);
      content: ' ';
      height: 100%;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .compound-button-grey {
    background-color: ${({ theme }) => theme.colors.border_grey} !important;
  }

  .compound-button-white {
    background-color: ${({ theme }) => theme.colors.white} !important;
  }

  .compound-button-denim-blue {
    background-color: ${({ theme }) => theme.colors.denim_blue} !important;
    border: 1px solid ${({ theme }) => theme.colors.denim_blue} !important;
  }

  .row-cta-button {
    text-align: center;
    font-size: 0.75rem !important;
    color: ${({ theme }) => theme.colors.darker_grey} !important;
    border-color: ${({ theme }) => theme.colors.off_white} !important;
    background-color: ${({ theme }) => theme.colors.off_white} !important;
    height: 2.25rem !important;
    width: 100% !important;
  }

  /**
   * Some of our monochrome SVG icons use the fill property to provide their primary color, and some use
   * stroke.
   * Adding the use-(fill | stroke) class in conjunction with active- / inactive- (fill | stroke)
   * stroke classes will proprly darken or lighten the svg color when the icon is interacted with.
   * For example, the developer may toggle the active-fill class when the user hovers over the component.
   */
  svg.use-fill {
    &.inactive-fill {
      path, rect, circle, line {
        fill: ${({ theme }) => theme.colors.grey};
      }
    }

    &.active-fill {
      path, rect, circle, line {
        fill: ${({ theme }) => theme.colors.black};
      }
    }
  }

  svg.use-current-color {
	  path {
		  fill: currentColor;
	  }
  }

  svg.use-stroke {
      &.inactive-stroke {
      path, rect, circle, line {
        stroke: ${({ theme }) => theme.colors.grey};
      }
    }

    &.active-stroke {
      path, rect, circle, line {
        stroke: ${({ theme }) => theme.colors.black};
      }
    }
  }

  .ellipsis-overflow {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .line-clamp-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .line-clamp-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }


`;

export default CompoundStyle;
