import { PEOPLE_DIRECTORY } from 'domain/Employees/constants';
import { IPerson } from '../../../domain/Employees/types';

export const getPerson = (identifier?: string) => {
  const dummyPerson: IPerson = {
    name: 'Unknown',
    email: 'unknown@withcompound.com',
    role: 'Unknown person',
    description: '',
  };

  if (!identifier || !PEOPLE_DIRECTORY[identifier]) return dummyPerson;

  return PEOPLE_DIRECTORY[identifier];
};
