import * as ToastPrimitive from '@radix-ui/react-toast';
import mergeRefs from 'react-merge-refs';
import {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Cross } from '@compoundfinance/design-system/dist/icons/legacy/Cross';
import { differenceWith, isEqual } from 'lodash';
import { Box } from '@compoundfinance/design-system';
import { textFontSize } from '@compoundfinance/design-system';
import {
  CSS,
  styled,
} from '@compoundfinance/design-system/dist/stitches.config';
import AccountLogo from 'components/AccountLogo';
import { Button } from '@compoundfinance/design-system';
import { fadeUp, fadeOut, swipeOut, hide } from './keyframes';
import { Toast as ToastType, Heights } from './types';
import { toast as toastUtils } from './util';
import { Row } from '@compoundfinance/design-system';
import Icon from './Icon';
import Timer from './Timer';
import { DEFAULT_TOAST_LIFETIME, TOAST_WIDTH } from './constants';
import AssetUtils from 'utils/assets';
import { storeToastCancel } from './localStorageUtils';

const Close = styled(ToastPrimitive.Close, {
  position: 'absolute',
  left: 0,
  top: 0,
  transform: 'translate(-35%, -35%)',
  size: 18,
  opacity: 0,
  p: 0,
  display: 'flex',
  ai: 'center',
  jc: 'center',
  border: 'none',
  br: '$round',
  bg: '$gray0',
  color: '$gray9',
  transition: 'color 200ms, opacity 200ms',
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.16);',

  '&:focus': {
    transition: 'color 200ms, opacity 0',
    opacity: 1,
    boxShadow:
      '0px 0px 8px rgba(0, 0, 0, 0.16), 0 0 0 1px rgba($colors$gray13Rgb, 0.2)',
  },

  '&:hover': {
    transition: 'color 200ms, opacity 0',
    color: '$gray12',
  },
});

const Root = styled(ToastPrimitive.Root, {
  p: '$16',
  br: '$8',
  bg: '$gray0',
  boxShadow: '$small',
  w: TOAST_WIDTH,
  position: 'absolute',
  bottom: 0,
  right: 0,
  $$gap: '14px',
  display: 'flex',
  ai: 'center',
  jc: 'space-between',
  transition: 'opacity 400ms, transform 400ms, height 400ms, box-shadow 200ms',
  userSelect: 'none',
  touchAction: 'none',

  '&:focus-visible': {
    outline: 'none',
    boxShadow: '$shadows$focus',
  },

  // Element above the toast, suposed to mimic the gap, it's needed to not set isHovering state to false when in between toasts
  '&:before': {
    content: '',
    position: 'absolute',
    top: '-$$gap',
    left: 0,
    right: 0,
    h: '$$gap',
  },

  '&:after': {
    content: '',
    position: 'absolute',
    left: -9,
    top: 8,
    w: 9,
    height: '100%',
  },
});

const Title = styled(ToastPrimitive.Title, {
  ...textFontSize[13],
  lineHeight: 1.4,
  fontWeight: '$medium',
});

const Description = styled(ToastPrimitive.Description, {
  ...textFontSize[12],
  color: '$gray10',
});

interface ToastProps {
  toast: ToastType;
  index: number;
  DOMIndex: number;
  isHovering: boolean;
  toasts: ToastType[];
  currentToasts: ToastType[];
  heights: Heights[];
  disableTransition: boolean;
  setHeights: React.Dispatch<React.SetStateAction<Heights[]>>;
  setIsHovering: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentToasts: React.Dispatch<React.SetStateAction<ToastType[]>>;
  newBatchedToasts: ToastType[];
  batchedHeights: Heights[];
  setBatchedHeights: React.Dispatch<React.SetStateAction<Heights[]>>;
  css?: CSS;
}

const Toast = forwardRef((props: ToastProps, ref: React.Ref<HTMLLIElement>) => {
  const {
    toast,
    index,
    DOMIndex,
    css,
    isHovering,
    setHeights,
    setIsHovering,
    batchedHeights,
    setCurrentToasts,
    newBatchedToasts,
    setBatchedHeights,
    toasts,
    currentToasts,
    heights,
    disableTransition,
  } = props;
  const {
    title,
    description,
    duration = DEFAULT_TOAST_LIFETIME,
    action,
    asset,
  } = toast;
  const [isOpen, setIsOpen] = useState(true);
  const [offsetHeight, setOffsetHeight] = useState(0);
  const [renderedOnMount, setRenderedOnMount] = useState(false);
  const [offsetHeightBeforeClose, setOffsetHeightBeforeClose] = useState(0);
  const [initialHeight, setInitialHeight] = useState(0);
  const toastRef = useRef<HTMLLIElement>(null);
  const animationEnd = useRef(false);
  const initialIndexRef = useRef(DOMIndex);
  const [isHeightSet, setIsHeightSet] = useState(false);
  const [assetImageProps, setAssetImageProps] = useState(null as any);

  useEffect(() => {
    if (asset) {
      const imageProps = AssetUtils.getDefaultAssetImageProps(asset as any);
      setAssetImageProps(imageProps);
    }
  }, [asset]);

  useEffect(() => {
    const node = toastRef.current;

    const alreadyExists = heights.find((h) => h.toastId === toast.id);

    // This check is needed in case of adding a batch on window load to avoid squashed toasts, dirty solution for now, but couldn't come up with anything else for now
    if (node && !alreadyExists && initialHeight) {
      const newToasts = differenceWith(toasts, currentToasts, isEqual);

      // Determine whether the toast is added in batches
      const isBatch = newToasts.length > 1;

      if (newBatchedToasts.length > 0) {
        setBatchedHeights((heights) => [
          { toastId: toast.id, height: initialHeight },
          ...heights,
        ]);
      } else if (!isBatch && !isHeightSet) {
        // Add height in the correct order, if it is batch it's added in reverse order so we need to take it into account
        setHeights((heights) => [
          { height: initialHeight, toastId: toast.id },
          ...heights,
        ]);
        setIsHeightSet(true);
      }
    }
  }, [
    setHeights,
    toast.id,
    newBatchedToasts,
    heights,
    currentToasts,
    isHeightSet,
    toasts,
    initialHeight,
    setBatchedHeights,
  ]);

  useEffect(() => {
    if (batchedHeights.length > 0 && !isHeightSet) {
      setHeights((heights) => {
        const alreadyExists = heights.find((h) => h.toastId === toast.id);
        if (alreadyExists) {
          return heights;
        } else {
          // https://github.com/radix-ui/primitives/blob/main/packages/react/toast/src/Toast.tsx#L188
          return [...batchedHeights.reverse(), ...heights];
        }
      });
      setIsHeightSet(true);
      setBatchedHeights([]);
    }
  }, [
    batchedHeights,
    setBatchedHeights,
    setIsHeightSet,
    toast.id,
    setHeights,
    isHeightSet,
  ]);

  const hideOnSwipe = () => {
    const node = toastRef.current;

    if (node && node.dataset.swipe === 'end') {
      setOffsetHeightBeforeClose(offsetHeight);
      setIsOpen(false);
    }
  };

  useLayoutEffect(() => {
    const node = toastRef.current;

    if (!node) return;
    let setTimeoutId: any = undefined;

    const onAnimationStart = (e: AnimationEvent) => {
      if (e.animationName === `${fadeUp}`) {
        // using ref to prevent a re-render
        setTimeoutId = setTimeout(() => {
          animationEnd.current = true;
        }, 400);
      }
    };

    // We can't use onanimationend as the animation won't end if a new toast is added within 400ms so we set timeout which has a duration equal to enter animation
    node.addEventListener('animationstart', onAnimationStart);

    return () => {
      node.removeEventListener('animationstart', onAnimationStart);
      if (setTimeoutId) {
        clearTimeout(setTimeoutId);
      }
    };
  }, []);

  useEffect(() => {
    if (disableTransition) {
      setRenderedOnMount(true);
    }
  }, []);

  useEffect(() => {
    // Recalculate only when isHovering changes
    if (isHovering) {
      // This function gets yOffset by summig up the height of all preceding toasts
      const offset = heights.reduce((prev, curr, reducerIndex) => {
        // Calculate offset up untill current  toast
        if (reducerIndex >= index) {
          return prev;
        }

        return prev + curr.height;
      }, 0);

      setOffsetHeight(offset);
    }
  }, [heights, index, isHovering]);

  useEffect(() => {
    if (!isOpen) {
      setCurrentToasts((currentToasts) =>
        currentToasts.filter((t) => t.id !== toast.id),
      );

      setHeights((heights) =>
        heights.filter((height) => height.toastId !== toast.id),
      );

      if (toast?.group) {
        storeToastCancel(toast.group, toast.id);
      }

      // Can't remove instantly as there will be no exit animation
      const setTimeoutId = setTimeout(() => {
        toastUtils.clear(toast);
      }, 250);

      return () => clearTimeout(setTimeoutId);
    }
    return;
  }, [isOpen, toast, setCurrentToasts, setHeights]);

  useEffect(() => {
    if (index > 0 && initialIndexRef.current === 0) {
      initialIndexRef.current = index;
    }
  }, [index]);

  const isNotFront = DOMIndex > 0;
  const isHidden = DOMIndex >= 3;

  // This is needed to avoid a bug when rapidly adding new toats
  // Animation duration is 400ms, when you add new one within 400ms, it will get janky
  const getEnterAnimation = () => {
    // If animation was already played, don't animate again, or when the toasts has been added on load and it's not the first one
    if (
      // enter animation has ran already
      animationEnd.current ||
      // added in batch, animate only first one
      initialIndexRef.current > 0 ||
      // rendered on mount, don't animate
      renderedOnMount ||
      // if is not front, when rapidly adding toasts
      isNotFront
    ) {
      return 'none';
    } else {
      return `${fadeUp} 400ms ease`;
    }
  };

  useLayoutEffect(() => {
    const node = toastRef.current;

    // We need this for batched updates, otherwise, when 3 toast are added only the first one would render at full dimensions, other would never have the chance to render full dimensions
    if (node && !initialHeight) {
      // Get correct height for the element based on it's position
      const initialHeight = node.style.height;
      // Render at full dimensions
      node.style.height = 'auto';
      const height = node.clientHeight;
      // Set it back to correct height
      node.style.height = initialHeight;
      setInitialHeight(height);
    }
  });

  return (
    <Root
      role={toast.action ? 'alertdialog' : 'status'}
      data-height={initialHeight}
      css={{
        // This one is needed to accurately animate swipe exit
        $$yPositionBeforeClose: `translateY(calc(${DOMIndex} * -$$gap - 20px - ${offsetHeightBeforeClose}px)) scale(1)`,
        // Expose yPosition as css variable to use it in keyframes.ts file
        $$yPosition: `translateY(calc(${index} * -$$gap - $$yElevate - ${offsetHeight}px)) scale(1)`,
        // Make height of all stacked toast equal to the front toast so they stick out evenly
        h:
          isNotFront && !isHovering
            ? '$$frontToastHeight'
            : initialHeight || 'auto',
        opacity: isHidden && !isHovering ? 0 : 1,
        pointerEvents: isHidden && !isHovering ? 'none' : 'auto',

        transform:
          isHovering && isNotFront
            ? `translateY(calc(${index} * -$$gap - $$yElevate - ${offsetHeight}px)) scale(1)`
            : `translateY(calc(${index} * -15px - $$yElevate)) scale(calc(${index} * -0.05 + 1))`,
        ...css,

        '@motionSafe': {
          '&[data-state="closed"]': {
            animation:
              isHidden && !isHovering
                ? 'none'
                : `${isNotFront ? fadeOut : hide} 250ms ease forwards`,

            '&[data-swipe="end"]': {
              animation: `${swipeOut} 100ms ease forwards`,
            },
          },
          '&[data-state="open"]': {
            // We need to set animation to none to avoid bugs when adding toasts rapidly
            animation: getEnterAnimation(),
          },
          '&[data-swipe="move"]': {
            transition: 'none',
            transform: `translateX(var(--radix-toast-swipe-move-x)) $$yPosition`,
          },
        },

        '&:hover': {
          [`& ${Close}`]: {
            opacity: isNotFront && !isHovering ? 0 : 1,
          },
        },
      }}
      // Set state only when duration prop is passed, otherwise it won't do anything but cause a redundant re-render
      ref={mergeRefs([ref, toastRef])}
      onSwipeEnd={() => {
        // If you close the last toast by swiping or if theres only one toast, turn hover to off
        if (index === toasts.length - 1) {
          setIsHovering(false);
        }
      }}
      open={isOpen}
      onOpenChange={hideOnSwipe}
    >
      <Row
        css={{
          ai: 'center',
          opacity: index > 0 && !isHovering ? 0 : 1,
          transition: 'opacity 200ms',
          position: 'relative',
        }}
      >
        <Row
          css={{
            mr: '$10',
            ai: 'center',
            w: 24,
            jc: 'center',
          }}
        >
          {toast.icon ? (
            <Box as="img" alt="" src={toast.icon} css={{ w: 22, h: 22 }} />
          ) : assetImageProps ? (
            <AccountLogo {...assetImageProps} fontSize={12} size={22} />
          ) : (
            toast.type !== undefined && <Icon type={toast.type} />
          )}
        </Row>
        <Box>
          <Title>{title}</Title>
          {description && <Description>{description}</Description>}
        </Box>
      </Row>
      <Row
        css={{
          gap: '$12',
          ai: 'center',
          ml: '$12',
          opacity: index > 0 && !isHovering ? 0 : 1,
          transition: 'opacity 200ms',
        }}
      >
        {duration && (
          <Timer
            hidden={index > 0}
            duration={duration}
            pauseTimer={isHovering || index > 0}
            setIsOpen={(o) => setIsOpen(o)}
          />
        )}
        {action && (
          <Button
            size="small"
            css={{ position: 'relative' }}
            onClick={() => {
              action.onClick();
              setIsOpen(false);
            }}
          >
            {action.label}
          </Button>
        )}
      </Row>
      <Close
        onClick={() => {
          setIsOpen(false);
        }}
        aria-label="Close"
      >
        <Cross fill="currentColor" style={{ width: 15, height: 15 }} />
      </Close>
    </Root>
  );
});

export default Toast;
