import cx from 'classnames';
import { Group } from '@visx/group';
import { sankey as d3Sankey } from 'd3-sankey';

export type SankeyNode<NodeType, LinkType> = NodeType & {
  x0: number;
  x1: number;
  y0: number;
  y1: number;
  depth: number;
  sourceLinks: SankeyLink<NodeType, LinkType>[];
  targetLinks: SankeyLink<NodeType, LinkType>[];
};

type SankeyLink<NodeType, LinkType> = Omit<LinkType, 'source' | 'target'> & {
  source: SankeyNode<NodeType, LinkType>;
  target: SankeyNode<NodeType, LinkType>;
  index: number;
  width: number;
  height: number;
};

interface SankeyProps<NodeType, LinkType> {
  top?: number;
  left?: number;
  className?: string;
  data: { nodes: NodeType[]; links: LinkType[] };
  size: any;
  nodeId?: any;
  nodeAlign?: any;
  nodeWidth?: any;
  nodePadding?: any;
  extent?: any;
  iterations?: any;
  circularLinkGap?: any;
  children?: ({
    data,
  }: {
    data: {
      nodes: SankeyNode<NodeType, LinkType>[];
      links: SankeyLink<NodeType, LinkType>[];
    };
  }) => React.ReactNode;
}

export default function Sankey<NodeType, LinkType>({
  top,
  left,
  className,
  data,
  size,

  nodeId,
  nodeAlign,
  nodeWidth,
  nodePadding,
  extent,
  iterations,

  children,
}: SankeyProps<NodeType, LinkType>) {
  const Sankey = d3Sankey<
    SankeyNode<NodeType, LinkType>,
    SankeyLink<NodeType, LinkType>
  >();
  if (size) Sankey.size(size);
  if (nodeId) Sankey.nodeId(nodeId);
  if (nodeAlign) Sankey.nodeAlign(nodeAlign);
  if (nodeWidth) Sankey.nodeWidth(nodeWidth);
  if (nodePadding) Sankey.nodePadding(nodePadding);
  if (extent) Sankey.extent(extent);
  if (iterations) Sankey.iterations(iterations);

  /** @ts-ignore */
  const sankeyData = Sankey(data);

  if (!children) return null;
  return (
    <Group top={top} left={left} className={cx('vx-sankey', className)}>
      {children({ data: sankeyData })}
    </Group>
  );
}
