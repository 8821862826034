import moment from 'moment';
import {
  ExerciseEvent,
  Grant,
  PrivateEquityAccount,
} from '@compoundfinance/compound-core/dist/types/equity';
import { isISO } from '../utils';
import {
  DEFAULT_FMV_FOR_PUBLIC_COMPANY,
  PeRelationships,
  ValuationMethodType,
} from '@compoundfinance/compound-core/dist/constants/peAccount';

export function isCurrentEmployee(relationship: string | null) {
  return (
    relationship === PeRelationships.Employee ||
    relationship === PeRelationships.Founder ||
    relationship === PeRelationships.Advisor
  );
}

export function isFormerEmployee(relationship: string | null) {
  return (
    relationship === PeRelationships.FormerEmployee ||
    relationship === PeRelationships.FormerFounder
  );
}

export function isFormerEmployeeOrAdvisor(relationship: string | null) {
  return (
    relationship === PeRelationships.FormerEmployee ||
    relationship === PeRelationships.FormerFounder ||
    relationship === PeRelationships.Advisor ||
    relationship === PeRelationships.FormerAdvisor
  );
}

/**
 * Override peAccount fmv value with latest stock price.
 * Save reference to old fmv value in originalFmv field.
 * @param peAccount
 */
export function updateFmvFromStockValuation(peAccount: PrivateEquityAccount) {
  if (!peAccount.hasLiquidityEvent) return;

  const latestStockValuation = peAccount.stockValuationHistory?.[0];
  const latestStockPrice = latestStockValuation?.price;
  if (latestStockPrice) {
    peAccount.fmv = latestStockPrice;
  }
}

/**
 * Override peAccount fmv value with specified valuation method
 */
/* eslint no-param-reassign: ["error", { "props": false }] */
export function updateFmvFromValuationMethod(peAccount: PrivateEquityAccount) {
  if (peAccount.hasLiquidityEvent) return;

  switch (peAccount.valuationMethod) {
    case ValuationMethodType.Estimated:
      peAccount.fmv = peAccount.estimatedPrice;
      break;
    case ValuationMethodType.Preferred:
      peAccount.fmv = peAccount.preferredPrice;
      break;
    case ValuationMethodType.FMV:
      peAccount.fmv =
        peAccount.originalFmv ||
        peAccount.fmv ||
        DEFAULT_FMV_FOR_PUBLIC_COMPANY;
      break;
    default:
      break;
  }
}

export function getFmvFromValuationMethod(peAccount: PrivateEquityAccount) {
  if (peAccount.hasLiquidityEvent) {
    return peAccount.fmv;
  }

  switch (peAccount.valuationMethod) {
    case ValuationMethodType.Estimated:
      return peAccount.estimatedPrice;
    case ValuationMethodType.Preferred:
      return peAccount.preferredPrice;
    case ValuationMethodType.FMV:
      return (
        peAccount.originalFmv || peAccount.fmv || DEFAULT_FMV_FOR_PUBLIC_COMPANY
      );
    default:
      return peAccount.fmv;
  }
}

/**
 * filters grants and private equity accounts for ISO exercises in a given year
 * @param year the year for filtering
 * @param peAccounts private equity accounts of a client
 * @returns accounts data with ISO exercises
 */
export function filterPEAccountsWithISOExercises(
  peAccounts: PrivateEquityAccount[],
  year: number,
) {
  return (
    peAccounts
      .map((peAccount) => {
        const filteredGrants = peAccount.grants
          // filter for grants of ISO award type
          .filter(isISO)
          // filter for exercise events within the tax year
          .map((grant: Grant) => ({
            ...grant,
            exerciseEvents: grant.exerciseEvents?.filter(
              (exerciseEvent: ExerciseEvent) =>
                moment.utc(exerciseEvent.date).year() === year,
            ),
          }))
          // filter for grants with filtered events
          .filter((grant: Grant) => !!grant.exerciseEvents?.length);
        return {
          ...peAccount,
          grants: filteredGrants,
        };
      })
      // filter for accounts with filtered events
      .filter((account) => account.grants.length > 0)
  );
}
