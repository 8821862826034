import { NumberUtils } from '../../../../utils/NumberUtils';

type FixedFee = {
  threshold: number;
  fixed: number;
};
type RateFee = {
  threshold: number;
  rate: number;
};
type Fee = FixedFee | RateFee;

const isFixedFee = (fee: Fee): fee is FixedFee => Object.hasOwn(fee, 'fixed');

const isRateFee = (fee: Fee): fee is RateFee => Object.hasOwn(fee, 'rate');

const getFeeAmount = ({ value, fee }: { value: number; fee: Fee }) => {
  if (isFixedFee(fee)) return fee.fixed;
  if (isRateFee(fee)) return NumberUtils.percentageFromValue(fee.rate, value);
  throw new Error('Unreachable', { cause: fee });
};

const calculate = (fees: Fee[]) => (amount: number) => {
  if (amount === 0) return 0;
  const { feeAmount } = fees.reduce(
    ({ remanent, feeAmount }, fee, index) => {
      const isLastFee = index === fees.length - 1;
      const value = isLastFee
        ? remanent
        : Math.min(
            remanent,
            NumberUtils.subtract(
              fee.threshold,
              NumberUtils.subtract(amount, remanent),
            ),
          );
      return {
        remanent: isLastFee
          ? 0
          : Math.max(0, NumberUtils.subtract(remanent, value)),
        feeAmount: NumberUtils.add(
          feeAmount,
          Fee.feeAmount({
            value,
            fee,
          }),
        ),
      };
    },
    { remanent: amount, feeAmount: 0 },
  );
  return NumberUtils.percentage(feeAmount, amount);
};

const getCompoundInvestmentFee = (networth: number) => {
  if (networth <= 1_000_000) return 0;
  const calculateFee = Fee.calculate([
    { threshold: 1_999_999, rate: 0.65 },
    { threshold: 2_999_999, rate: 0.55 },
    { threshold: 4_999_999, rate: 0.5 },
    { threshold: 9_999_999, rate: 0.45 },
    { threshold: Infinity, rate: 0.35 },
  ]);
  return calculateFee(networth);
};

export const Fee = {
  calculate,
  compoundInvestmentFee: getCompoundInvestmentFee,
  feeAmount: getFeeAmount,
};
