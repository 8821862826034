import React from 'react';
import { Group } from '@visx/group';
import { Line } from '@visx/shape';

const SPACING = 8;
const METRIC_LINE_HEIGHT = 8;
const POINT_LINE_HEIGHT = 12;

export const XAxis = ({
  points,
  height,
  width,
  children,
}: {
  points: number;
  height: number;
  width: number;
  children: ({
    x,
    y,
    index,
    spacing,
  }: {
    x: number;
    y: number;
    index: number;
    spacing: number;
  }) => React.ReactNode;
}) => {
  const spacing = width / (points - 1);
  return (
    <Group>
      {Array.from({ length: points }, (_, index) => {
        const x = index * spacing;
        const isPoint = index % 5 === 0;
        return (
          <Line
            key={index}
            from={{ x, y: height + SPACING }}
            to={{
              x,
              y:
                height +
                SPACING +
                (isPoint ? POINT_LINE_HEIGHT : METRIC_LINE_HEIGHT),
            }}
            stroke={'var(--colors-gray8)'}
            strokeWidth={1}
            shapeRendering="geometricPrecision"
          />
        );
      })}
      <Group>
        {Array.from({ length: points }, (_, index) => {
          const x = index * spacing;
          return (
            <React.Fragment key={index}>
              {children({ x, y: height + SPACING + 10, index, spacing })}
            </React.Fragment>
          );
        })}
      </Group>
    </Group>
  );
};
