import { MainWrap } from 'components/MainWrap';
import { LIGHT_GREY } from '@compoundfinance/design-system/dist/style/theme';
import { LoadingWheel } from '@compoundfinance/design-system';

export const LoaderWrap = () => {
  return <MainWrap h="100vh" bg={LIGHT_GREY} style={{ overflowY: 'hidden' }} />;
};

interface LoaderProps {
  delay?: boolean;
}

export default function WrappedCompoundSpinner(props: LoaderProps) {
  const { delay } = props;
  return (
    <MainWrap
      h="100vh"
      bg={LIGHT_GREY}
      style={{ overflowY: 'hidden' }}
      className={`${delay && 'delay-visibility'}`}
    >
      <LoadingWheel size="xlarge" />
    </MainWrap>
  );
}
