import { ChartTypes } from 'containers/Dashboard/Accounts/NetWorthChart/constants';
import { Address } from 'types/address';
import { ExtensionStatus, TaxProfile, User, WealthProfile } from 'types/user';
import { ChartNames, NetWorthDateInterval } from 'shared/chart/constants';
import { CURRENT_TAX_YEAR } from 'utils/constants/tax';
import { UserRoleType } from 'utils/constants/user';

const DEFAULT_WEALTH_PROFILE: WealthProfile = {
  id: '',
  hasTrust: false,
  expenses: 0,
  userId: '',
  advisorDriveLink: null,
};

const DEFAULT_ADDRESS_STATE: Address = {
  streetAddress: '',
  aptUnitNumber: '',
  city: '',
  zipCode: '',
  state: '',
};

const DEFAULT_USER_STATE: User = {
  id: '',
  firstName: '',
  lastName: '',
  legalFirstName: '',
  legalLastName: '',
  email: 'email',
  ssoId: null,
  ssoProvider: null,
  intercomHash: null,
  onboardingComplete: false,
  reportComplete: false,
  dashboardApproved: false,
  accountAuthenticated: false,
  birthday: new Date(),
  role: UserRoleType.Default,
  wealthProfile: DEFAULT_WEALTH_PROFILE,
  files: [],
  phoneNumber: '',
  hasSpouse: false,
  address: DEFAULT_ADDRESS_STATE,
  isEmployee: false,
};

const DEFAULT_TAX_PROFILE_STATE: TaxProfile = {
  id: '',
  taxableIncome: 0,
  salary: 0,
  bonus: 0,
  irregularIncome: 0,
  interestAndDividends: 0,
  qualifiedInterestAndDividends: 0,
  selfEmploymentIncome: 0,
  federalWithholding: 0,
  stateWithholding: 0,
  filingStatus: 'single',
  numChildDependents: 0,
  numOtherDependents: 0,
  year: CURRENT_TAX_YEAR,
  nonEquityLtcg: 0,
  nonEquityStcg: 0,
  residentState: '',
  rentalIncome: 0,
  four01KDeduction: 0,
  mortgageInterestDeduction: 0,
  propertyTaxDeduction: 0,
  charitableContributionsDeduction: 0,
  iraContributionsDeduction: 0,
  medicalExpensesDeduction: 0,
  studentLoanInterestDeduction: 0,
  fsaDeduction: 0,
  hsaDeduction: 0,
  five29Deduction: 0,
  dependentCareFsaDeduction: 0,
  sepIraDeduction: 0,
  amtFederalCredits: 0,
  amtStateCredits: 0,
  user: DEFAULT_USER_STATE,
  userId: '',
  extensionStatus: ExtensionStatus.OFF,
  saltDeduction: 0,
  saltNiiDeduction: 0,
  medicareWages: 0,
};

const DEFAULT_TREND_CHART = {
  filter: NetWorthDateInterval.OneMonth,
  type: ChartTypes.Mekko,
  condense: true,
};

const DEFAULT_CHART_SETTINGS = Object.values(ChartNames).reduce(
  (chartsDefaults, name) => {
    return {
      ...chartsDefaults,
      [name]: { ...DEFAULT_TREND_CHART },
    };
  },
  {},
);

const DEFAULT_SPOUSE_STATE = {
  firstName: '',
  lastName: '',
  legalFirstName: '',
  legalLastName: '',
  birthday: new Date(),
  phoneNumber: '',
};

const DEFAULT_FINANCIAL_MANAGER = {
  firstName: '',
  lastName: '',
  id: '',
  email: '',
};

const DEFAULT_CLIENT_ACCESS_SESSION = {
  id: '',
  employeeId: '',
  clientId: '',
  startTime: new Date(),
  endTime: new Date(),
  reason: '',
};

export {
  DEFAULT_USER_STATE,
  DEFAULT_TAX_PROFILE_STATE,
  DEFAULT_WEALTH_PROFILE,
  DEFAULT_CHART_SETTINGS,
  DEFAULT_FINANCIAL_MANAGER,
  DEFAULT_ADDRESS_STATE,
  DEFAULT_SPOUSE_STATE,
  DEFAULT_CLIENT_ACCESS_SESSION,
};
