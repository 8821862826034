import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';
import {
  Box,
  Row,
  ArrowRightIcon,
  Col,
  FlexTable,
  Text,
  Switcher,
  Collapsible,
  ChevronDown,
  InformationIcon,
  CrossIcon,
  IconButton,
} from '@compoundfinance/design-system';
import { Pagination } from 'components/Pagination';
import LineChart, {
  ChartData,
} from 'containers/Dashboard/Accounts/NetWorthChart/LineChart';
import { formatDecimals } from 'utils/ui';
import { useState } from 'react';
import Benchmarks from 'components/AssetOverview/ChartView/Benchmarks';
import EditableTextArea from 'containers/Dashboard/Document/components/EditableTextArea';
import { SYMBOL_TO_NAME } from 'components/AssetOverview/ChartView/useGetBenchmarks';
import moment from 'moment';
import TimeRange, { CoreTimeButton } from 'components/TimeRange';
import { NetWorthDateInterval } from 'shared/chart/constants';
import { NetWorthDayRanges } from 'shared/chart/constants';
import { EditableSectionTitle } from 'containers/Dashboard/Document/components/SectionTitle';
import UiUtils from 'utils/ui';
import useTypedSelector from 'hooks/typedSelector';
import compact from 'lodash/compact';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import startCase from 'lodash/startCase';
import sumBy from 'lodash/sumBy';
import { ValueOf } from 'types';
import TimeWeightedReturnProvider from 'components/PublicInvestmentView/Common/TimeWeightedReturn';
import { InvestmentTransactionTypes } from 'domain/InvestmentTransaction';
import { PlaidAccountProvider } from 'utils/plaid/constants';

const TransactionTypesGeneratedByTheAccount = [
  InvestmentTransactionTypes.Income,
  InvestmentTransactionTypes.Dividend,
  InvestmentTransactionTypes.DividendReinvestment,
  InvestmentTransactionTypes.Interest,
];

function quarterIntervalDates(quarter: number, year: number): [Date, Date] {
  const firstMonth = quarter * 3 - 2;
  const startDate = moment.utc(`${year}-${firstMonth}-01`).toDate();
  const endDate = moment
    .utc(`${year}-${firstMonth + 2}-01`)
    .endOf('month')
    .startOf('day')
    .toDate();
  return [startDate, endDate];
}

const Container = styled(ModuleContainer, {
  p: '$24',

  '@bp1': {
    py: '$44',
    px: '$48',
  },
});

const FLEX_STYLES = [0.5, 0.15, 0.1, 0.15, 0.1];
const INCOME_FLEX_STYLES = [0.4, 0.2, 0.25, 0.1, 0.05];

const formatNumber = (num: number) => (
  <Text color={num > 0 ? 'green10' : num < 0 ? 'red10' : undefined}>
    {num > 0 ? '+' : num < 0 ? '-' : ''}$
    {UiUtils.sanitize(Math.abs(num)).replace(/\.00/, '')}
  </Text>
);

// type Account = {
//   name: string;
//   transactions: Array<{ value: number; position: string; type: string }>;
// };

type InvestmentTransaction = {
  id: string;
  amount?: number;
  type?: string;
  subCode?: string;
  externalFlowAffect?: string;
  twrDate?: string;
  security?: { tickerSymbol: string };
};

type Performance = {
  date: string | Date;
  twr: number;
  aggregatedTwr: number;
  balance: number;
  beginBalance: number;
  endBalance: number;
  totalTransactionsAmount: number;
  transactions: InvestmentTransaction[];
};

const TRANSACTIONS_PER_PAGE = 10;
interface IncomeGenerationProps {
  transactions: InvestmentTransaction[];
  accountName: string;
}

function IncomeGeneration(props: IncomeGenerationProps) {
  const { accountName, transactions } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  if (transactions.length === 0) return null;
  const pageCount = Math.ceil(transactions.length / TRANSACTIONS_PER_PAGE);
  const totalTransactionsAmount = sumBy(transactions, 'amount');
  return (
    <Collapsible open={isOpen}>
      <FlexTable.Row
        css={{
          '>span': { fontSize: '$13' },
        }}
        hideBorder
        onClick={() => setIsOpen((v) => !v)}
      >
        <FlexTable.Cell flex={INCOME_FLEX_STYLES[0]}>
          {accountName} Total
        </FlexTable.Cell>
        <FlexTable.Cell flex={INCOME_FLEX_STYLES[1]} />
        <FlexTable.Cell flex={INCOME_FLEX_STYLES[2]} />
        <FlexTable.Cell flex={INCOME_FLEX_STYLES[3]} align="right">
          {formatNumber(totalTransactionsAmount)}
        </FlexTable.Cell>
        <FlexTable.Cell flex={INCOME_FLEX_STYLES[4]} align="right">
          {totalTransactionsAmount !== 0 && (
            <ChevronDown
              size={20}
              css={{
                transform: isOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s',
              }}
            />
          )}
        </FlexTable.Cell>
      </FlexTable.Row>
      <Collapsible.Content>
        <>
          <FlexTable.Head>
            <FlexTable.Row noHover css={{ height: 'fit-content', py: '$5' }}>
              <FlexTable.HeadCell flex={INCOME_FLEX_STYLES[0]} />
              <FlexTable.HeadCell flex={INCOME_FLEX_STYLES[1]}>
                Position
              </FlexTable.HeadCell>
              <FlexTable.HeadCell flex={INCOME_FLEX_STYLES[2]}>
                Type
              </FlexTable.HeadCell>
              <FlexTable.HeadCell flex={INCOME_FLEX_STYLES[3]} align="right">
                Value
              </FlexTable.HeadCell>
              <FlexTable.HeadCell flex={INCOME_FLEX_STYLES[4]} />
            </FlexTable.Row>
          </FlexTable.Head>
          <Box css={{ minHeight: pageCount > 1 ? '480px' : undefined }}>
            {transactions
              .slice(
                (currentPage - 1) * TRANSACTIONS_PER_PAGE,
                currentPage * TRANSACTIONS_PER_PAGE,
              )
              .map((transaction) => (
                <FlexTable.Row noHover css={{ '>span': { fontSize: '$13' } }}>
                  <FlexTable.Cell flex={INCOME_FLEX_STYLES[0]} />
                  <FlexTable.Cell flex={INCOME_FLEX_STYLES[1]}>
                    {transaction?.security?.tickerSymbol ?? '-'}
                  </FlexTable.Cell>
                  <FlexTable.Cell flex={INCOME_FLEX_STYLES[2]}>
                    {transaction.type ?? '-'}
                  </FlexTable.Cell>
                  <FlexTable.Cell flex={INCOME_FLEX_STYLES[3]} align="right">
                    {formatNumber(transaction.amount ?? 0)}
                  </FlexTable.Cell>
                  <FlexTable.Cell flex={INCOME_FLEX_STYLES[4]} />
                </FlexTable.Row>
              ))}
          </Box>
          {pageCount > 1 && (
            <Pagination
              count={pageCount}
              current={currentPage}
              setCurrent={setCurrentPage}
            />
          )}
        </>
      </Collapsible.Content>
    </Collapsible>
  );
}

type Benchmark = {
  price: number;
  date: Date | string;
};

export interface AccountOverviewProps {
  performanceByAccount: { [name: string]: Performance[] };
  benchmarks: { color: string; name: string; data: Benchmark[] }[];
  timestamp: string;
  year: number;
  quarter: number;
  title: string;
}

const getDateBoundariesFromDateInterval = ({
  dateInterval,
  timeRange,
  earliestPossibleDate = new Date('01-01-1970'),
}: {
  dateInterval: [Date, Date];
  timeRange: NetWorthDateInterval;
  earliestPossibleDate?: Date;
}) => {
  let earliestDate = moment
    .max(
      moment
        .utc()
        .subtract(NetWorthDayRanges[timeRange], 'days')
        .startOf('day'),
    )
    .toDate();
  const yesterday = new Date(
    new Date().setUTCDate(new Date().getUTCDate() - 1),
  );
  let latestDate = yesterday;
  if (timeRange === NetWorthDateInterval.All) {
    earliestDate = earliestPossibleDate;
  } else if (timeRange === NetWorthDateInterval.Custom) {
    [earliestDate, latestDate] = dateInterval;
  }
  return [earliestDate, latestDate] as const;
};

const keyDate = (date: Date) =>
  `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;

function fillMissingPerformanceDates(
  data: any,
  _startDate: Date,
  _endDate: Date,
) {
  const startDate = new Date(_startDate);
  const endDate = new Date(_endDate);
  const dataByDate = keyBy(data, (data) => keyDate(new Date(data.date)));
  const fullData: any = [];
  const tmpDate = startDate;
  let lastPerformance = {
    date: startDate,
    twr: 0,
    aggregatedTwr: 0,
    beginBalance: 0,
    endBalance: 0,
    totalTransactionsAmount: 0,
    transactions: [],
  };
  while (tmpDate.getTime() <= endDate.getTime()) {
    const dataForDate = dataByDate[keyDate(tmpDate)];
    const hasDataForDate = !!dataForDate;
    if (hasDataForDate) {
      lastPerformance = dataForDate;
      fullData.push(dataForDate);
    } else {
      fullData.push({
        ...lastPerformance,
        date: new Date(tmpDate),
      });
    }
    tmpDate.setUTCDate(tmpDate.getUTCDate() + 1);
  }
  return orderBy(fullData, [(data) => new Date(data.date).getTime()], ['asc']);
}

const fillMissingChartDataDates = (
  data: ChartData[],
  _startDate: Date,
  _endDate: Date,
) => {
  const startDate = new Date(_startDate);
  const endDate = new Date(_endDate);
  const dataByDate = keyBy(data, (data) => keyDate(new Date(data.date)));
  const fullData: any = [];
  const tmpDate = startDate;
  let lastChartData: ChartData = {
    net: 0,
    date: startDate,
  };
  while (tmpDate.getTime() <= endDate.getTime()) {
    const dataForDate = dataByDate[keyDate(tmpDate)];
    const hasDataForDate = !!dataForDate;
    if (hasDataForDate) {
      lastChartData = { ...dataForDate, net: dataForDate.net ?? 0 };
      fullData.push(dataForDate);
    } else {
      fullData.push({
        ...lastChartData,
        date: new Date(tmpDate),
      });
    }
    tmpDate.setUTCDate(tmpDate.getUTCDate() + 1);
  }
  return orderBy(fullData, [(data) => new Date(data.date).getTime()], ['asc']);
};

const fillMissingDates = (
  data: Performance[],
  _startDate: Date,
  _endDate: Date,
) => {
  const startDate = new Date(_startDate);
  const endDate = new Date(_endDate);
  const dataByDate = keyBy(data, (data) => keyDate(new Date(data.date)));
  const fullData: Performance[] = [];
  const tmpDate = startDate;
  let lastChartData: Performance = {
    twr: 0,
    date: startDate,
    balance: 0,
    endBalance: 0,
    beginBalance: 0,
    transactions: [],
    aggregatedTwr: 0,
    totalTransactionsAmount: 0,
  };
  while (tmpDate.getTime() <= endDate.getTime()) {
    const dataForDate = dataByDate[keyDate(tmpDate)];
    const hasDataForDate = !!dataForDate;
    if (hasDataForDate) {
      lastChartData = { ...dataForDate };
      fullData.push(dataForDate);
    } else {
      fullData.push({
        ...lastChartData,
        date: new Date(tmpDate),
      });
    }
    tmpDate.setUTCDate(tmpDate.getUTCDate() + 1);
  }
  return orderBy(
    fullData,
    [(data) => new Date(data.date).getTime()],
    ['asc'],
  ) as Performance[];
};

const performanceToAccountChartData = (performances: Performance[]) =>
  performances.map(({ balance, date }) => ({
    date,
    net: balance,
  }));

const benchmarkToChartData = (benchmarks: Benchmark[]) => {
  if (benchmarks.length === 0) return [];
  const [{ price: initialPrice }] = benchmarks;
  return benchmarks.map(({ price, date }, index) => {
    return {
      net: index === 0 ? 0 : (price - initialPrice) / initialPrice,
      date,
    };
  });
};

const Tabs = {
  Performance: 'performance',
  Balances: 'balances',
};
type Tabs = ValueOf<typeof Tabs>;

const isCurrentTimeRangeSelected = (
  quarter: number,
  year: number,
  customFilterDateRange: [Date, Date],
) => {
  const [startDate, endDate] = quarterIntervalDates(quarter, year);
  const [customStartDate, customEndDate] = customFilterDateRange;
  return (
    startDate.getTime() === customStartDate.getTime() &&
    endDate.getTime() === customEndDate.getTime()
  );
};

function AccountOverview(props: AccountOverviewProps) {
  const { performanceByAccount, benchmarks, timestamp, title, year, quarter } =
    props;
  const earliestDataDate = performanceByAccount['All'].at(0)?.date;
  const allPerformances = Object.values(performanceByAccount).flatMap(
    (performance) => performance,
  );
  const allTransactions = compact(
    allPerformances.flatMap((performance) => performance.transactions),
  );
  const quarterInterval = quarterIntervalDates(quarter, year);

  const [quarterStart, quarterEnd] = quarterInterval;
  const filteredTransactionsByDate = allTransactions.filter((transaction) => {
    const transactionDate = moment(transaction.twrDate);
    return transactionDate.isBetween(quarterStart, quarterEnd, 'day', '[]');
  });

  const incomeGeneratingTransactionsByDate = filteredTransactionsByDate.filter(
    (transaction) =>
      TransactionTypesGeneratedByTheAccount.includes(transaction.type!),
  );

  const totalTransactionsAmount = filteredTransactionsByDate.reduce(
    (totalTransactionsAmount, transaction) =>
      totalTransactionsAmount + (transaction.amount ?? 0),
    0,
  );

  const plaidAccounts = useTypedSelector((state) => state.assets.accounts);
  const selfDirectedAccounts = plaidAccounts.filter(
    (account) =>
      (account.isSelfDirected === null || account.isSelfDirected === false) &&
      account.provider === PlaidAccountProvider.BlackDiamond,
  );

  const [selectedAccount, setSelectedAccount] = useState('All');
  const [tab, setTab] = useState<Tabs>(Tabs.Performance);
  const [showTWRInfo, setShowTWRInfo] = useState(false);
  const [selectedBenchmarks, setSelectedBenchmarks] = useState({
    [SYMBOL_TO_NAME.IVV]: false,
    [SYMBOL_TO_NAME.ACWI]: true,
    [SYMBOL_TO_NAME.AGG]: false,
  });
  const [timeRange, setTimeRange] = useState<NetWorthDateInterval>(
    NetWorthDateInterval.Custom,
  );
  const [customFilterDateRange, setCustomFilterDateRange] =
    useState<[Date, Date]>(quarterInterval);

  const [earliestDateFromRange, latestDateFromRange] =
    getDateBoundariesFromDateInterval({
      timeRange,
      dateInterval: customFilterDateRange,
      ...(earliestDataDate && {
        earliestPossibleDate: new Date(earliestDataDate),
      }),
    });

  function filterDataInRange<Dated extends { date: Date | string }>(
    data: Dated[],
    [earliestDate, latestDate] = [earliestDateFromRange, latestDateFromRange],
  ) {
    return data.filter(
      (d) =>
        moment.utc(d.date).isSameOrAfter(moment.utc(earliestDate), 'day') &&
        moment.utc(d.date).isSameOrBefore(moment.utc(latestDate), 'day'),
    );
  }

  const selectedAccountPerformanceData = performanceByAccount[selectedAccount];

  const selectedAccountBalancesWithinInterval = performanceToAccountChartData(
    fillMissingDates(
      filterDataInRange(selectedAccountPerformanceData),
      earliestDateFromRange,
      latestDateFromRange,
    ),
  );

  const selectedAccountTwrWithinInterval = fillMissingPerformanceDates(
    fillMissingDates(
      filterDataInRange(selectedAccountPerformanceData),
      earliestDateFromRange,
      latestDateFromRange,
    ).map((d) => ({ ...d, net: d.aggregatedTwr })),
    earliestDateFromRange,
    latestDateFromRange,
  ).map((data) => ({
    ...data,
    net: data.net * 100,
  }));

  const benchmarksWithinInterval = benchmarks.map((benchmark) => {
    return {
      ...benchmark,
      data: fillMissingChartDataDates(
        benchmarkToChartData(filterDataInRange(benchmark.data)),
        earliestDateFromRange,
        latestDateFromRange,
      ).map((data) => ({
        ...data,
        net: data.net * 100,
      })),
    };
  });

  const accountsPerformanceWithinInterval = Object.fromEntries(
    Object.entries(performanceByAccount).map(([accountName, performances]) => {
      return [
        accountName,
        fillMissingDates(
          filterDataInRange(performances),
          earliestDateFromRange,
          latestDateFromRange,
        ),
      ] as const;
    }),
  );

  const isCustomSelected =
    timeRange === 'Custom' &&
    isCurrentTimeRangeSelected(quarter, year, customFilterDateRange);

  return (
    <Container>
      <Col css={{ gap: '$24', '@bp1': { gap: '$48' } }}>
        <Row css={{ jc: 'space-between' }}>
          <EditableSectionTitle title={title} />
          <Box
            css={{
              bg: '$gray2',
              h: 'fit-content',
              br: '$6',
              py: '$8',
              px: '$10',
            }}
          >
            <EditableTextArea
              propertyKey="timestamp"
              value={timestamp}
              textCss={{
                fontSize: '$13',
                color: '$gray11',
              }}
            />
          </Box>
        </Row>
        <Switcher value={tab} onChange={setTab}>
          <Row
            css={{
              jc: 'space-between',
              mb: '$24',
            }}
          >
            <Switcher.List
              css={{
                w: 'fit-content',
              }}
            >
              <Switcher.Trigger value={Tabs.Performance}>
                Performance
              </Switcher.Trigger>
              <Switcher.Trigger value={Tabs.Balances}>
                Account Balances
              </Switcher.Trigger>
            </Switcher.List>
            {tab === Tabs.Performance && (
              <Text
                size={12}
                color="gray9"
                css={{
                  display: 'flex',
                  ai: 'center',
                  jc: 'center',
                }}
              >
                <IconButton
                  variant="naked"
                  onClick={() => setShowTWRInfo(!showTWRInfo)}
                  css={{
                    color: '$gray9',
                  }}
                >
                  <InformationIcon size={12} css={{ mr: '$6' }} />
                </IconButton>
                Time-weighted return
              </Text>
            )}
          </Row>
          <Box>
            <Switcher.Content value={Tabs.Performance}>
              {showTWRInfo && (
                <Box
                  css={{
                    bg: '$gray2',
                    p: '$16',
                    borderRadius: '$6',
                    color: '$gray11',
                    fontSize: '$13',
                    display: 'flex',
                    gap: '$14',
                  }}
                >
                  <Box>
                    {selfDirectedAccounts.length > 0 && (
                      <Col
                        css={{
                          gap: '$8',
                        }}
                      >
                        <Text weight="medium" size="14">
                          Accounts included
                        </Text>
                        <Text
                          color="gray11"
                          size="14"
                          css={{
                            mb: '$20',
                          }}
                        >
                          Only Compound Managed Account are included in
                          performance calculation, that includes:
                          <ul
                            style={{
                              marginLeft: '20px',
                              lineHeight: '1.4',
                            }}
                          >
                            {selfDirectedAccounts.map((account) => (
                              <li
                                style={{
                                  listStyleType: 'disc',
                                }}
                                key={account.id}
                              >
                                {startCase(account.name)} -{' '}
                                {UiUtils.formatMoney(account.currentBalance)}
                              </li>
                            ))}
                          </ul>
                        </Text>
                      </Col>
                    )}
                    <Text weight="medium">Time-weighted return</Text>
                    <p>
                      Time-weighted return (TWR) calculates the compound rate of
                      growth for a portfolio. TWR is the industry standard for
                      performance reporting, and is used to report the
                      performances of common indices like the S&P 500. TWR
                      eliminates the distorting effects of deposits and
                      withdrawals on portfolio performance by calculating the
                      return of a portfolio for different 'periods', which are
                      created when money is deposited or withdrawn from a
                      portfolio.
                    </p>{' '}
                    <p>
                      To calculate TWR across multiple periods, multiply each
                      period’s TWR.
                    </p>{' '}
                    For example if a month included two periods — one with a
                    -10% return and another with a 20% return — the TWR for the
                    month would be 8%.
                  </Box>
                  <IconButton
                    variant="naked"
                    css={{
                      color: '$gray11',
                      alignItems: 'start',
                    }}
                    onClick={() => setShowTWRInfo(false)}
                  >
                    <CrossIcon size={20} css={{ flexShrink: 0 }} />
                  </IconButton>
                </Box>
              )}
              <Col css={{ gap: '$10', mb: '$40' }}>
                {Object.values(selectedAccountTwrWithinInterval).length > 0 && (
                  <LineChart
                    width={900}
                    height={250}
                    formatYAxis={(value: number) =>
                      `${formatDecimals(value, 0, 2)}%`
                    }
                    formatTooltip={(value: number) =>
                      `${formatDecimals(value, 0, 2)}%`
                    }
                    data={selectedAccountTwrWithinInterval}
                    extraLines={benchmarksWithinInterval.filter(
                      ({ name }) => selectedBenchmarks[name],
                    )}
                  />
                )}
                <Row css={{ gap: '$12', mt: '$16' }}>
                  <TimeRange
                    timeRangeOptions={[
                      {
                        label: NetWorthDateInterval.OneWeek,
                        value: NetWorthDateInterval.OneWeek,
                      },
                      {
                        label: NetWorthDateInterval.OneMonth,
                        value: NetWorthDateInterval.OneMonth,
                      },
                      {
                        label: NetWorthDateInterval.ThreeMonths,
                        value: NetWorthDateInterval.ThreeMonths,
                      },
                      {
                        label: NetWorthDateInterval.YearToDate,
                        value: NetWorthDateInterval.YearToDate,
                      },
                      {
                        label: NetWorthDateInterval.All,
                        value: NetWorthDateInterval.All,
                      },
                    ]}
                    timeRange={timeRange}
                    customFilterDateRange={customFilterDateRange}
                    onTimeRangeUpdate={(val) => setTimeRange(val)}
                    onUpdateCustomRange={(startDate, endDate) => {
                      setCustomFilterDateRange([startDate, endDate]);
                      setTimeRange(NetWorthDateInterval.Custom);
                    }}
                    earliestAssetAccountCreationDate={
                      new Date('01-01-1970')
                      // values.length ? new Date(values[0].date) : new Date()
                    }
                    css={{ w: 'fit-content' }}
                  />
                  <CoreTimeButton
                    selected={isCustomSelected}
                    onClick={() => {
                      setCustomFilterDateRange(
                        quarterIntervalDates(quarter, year),
                      );
                      setTimeRange(NetWorthDateInterval.Custom);
                    }}
                  >
                    {year} Q{quarter}
                  </CoreTimeButton>
                  <Benchmarks
                    selectedBenchmarks={selectedBenchmarks}
                    setSelectedBenchmarks={(key, val) =>
                      setSelectedBenchmarks((prev) => ({ ...prev, [key]: val }))
                    }
                  />
                </Row>
              </Col>
              <FlexTable css={{ p: 0, mb: '$48' }}>
                <FlexTable.Head>
                  <FlexTable.Row
                    css={{
                      height: '$32',
                      borderRadius: 0,
                    }}
                    noHover
                  >
                    <FlexTable.HeadCell flex={FLEX_STYLES[0]}>
                      Account
                    </FlexTable.HeadCell>
                    <FlexTable.HeadCell flex={FLEX_STYLES[1]}>
                      Starting balance
                    </FlexTable.HeadCell>
                    <FlexTable.HeadCell flex={FLEX_STYLES[2]} />
                    <FlexTable.HeadCell flex={FLEX_STYLES[3]}>
                      Ending balance
                    </FlexTable.HeadCell>
                    <FlexTable.HeadCell flex={FLEX_STYLES[4]} align="right">
                      Performance
                    </FlexTable.HeadCell>
                  </FlexTable.Row>
                </FlexTable.Head>
                <FlexTable.Content>
                  {Object.entries(accountsPerformanceWithinInterval).map(
                    ([accountName, performances]) => {
                      const startBalance =
                        performances.at(0)?.beginBalance ?? 0;
                      const endBalance = performances.at(-1)?.endBalance ?? 0;
                      const performance =
                        TimeWeightedReturnProvider.getPeriodTwr(
                          performances as any,
                        );
                      let performanceTextColor:
                        | undefined
                        | 'green10'
                        | 'red10' = undefined;
                      if (performance > 0) {
                        performanceTextColor = 'green10';
                      } else if (performance < 0) {
                        performanceTextColor = 'red10';
                      }
                      return (
                        <FlexTable.Row
                          css={{
                            py: '$10',
                            h: 'fit-content',
                            bg:
                              selectedAccount === accountName
                                ? '$gray2'
                                : undefined,
                            '>span': {
                              fontSize: '$13',
                            },
                          }}
                          hideBorder
                          showFirstBorder
                          onClick={() => setSelectedAccount(accountName)}
                        >
                          <FlexTable.Cell flex={FLEX_STYLES[0]}>
                            {accountName}
                          </FlexTable.Cell>
                          <FlexTable.Cell
                            flex={FLEX_STYLES[1]}
                            css={{ mb: 'auto', pt: '$1' }}
                          >
                            {UiUtils.formatMoney(
                              startBalance ? startBalance : 0,
                            )}
                          </FlexTable.Cell>
                          <FlexTable.Cell
                            flex={FLEX_STYLES[2]}
                            css={{ mb: 'auto', pt: '$1' }}
                          >
                            <ArrowRightIcon size={18} />
                          </FlexTable.Cell>
                          <FlexTable.Cell
                            flex={FLEX_STYLES[3]}
                            css={{ mb: 'auto', pt: '$1' }}
                          >
                            {UiUtils.formatMoney(endBalance)}
                          </FlexTable.Cell>
                          <FlexTable.Cell
                            flex={FLEX_STYLES[4]}
                            css={{ mb: 'auto', pt: '$1' }}
                            align="right"
                          >
                            <Text color={performanceTextColor}>
                              {Math.abs(performance * 100).toFixed(2)}%
                            </Text>
                          </FlexTable.Cell>
                        </FlexTable.Row>
                      );
                    },
                  )}
                </FlexTable.Content>
              </FlexTable>
              {incomeGeneratingTransactionsByDate.length > 0 && (
                <>
                  <Text
                    size={15}
                    weight="medium"
                    css={{
                      display: 'block',
                      mb: '$16',
                      ml: '$16',
                    }}
                  >
                    Income Generation
                  </Text>
                  <FlexTable css={{ p: 0 }}>
                    <FlexTable.Head>
                      <FlexTable.Row
                        noHover
                        css={{ height: 'fit-content', pb: '$10' }}
                      >
                        <FlexTable.HeadCell
                          flex={INCOME_FLEX_STYLES[0]}
                          align="left"
                        >
                          Account
                        </FlexTable.HeadCell>
                        <FlexTable.HeadCell flex={INCOME_FLEX_STYLES[1]} />
                        <FlexTable.HeadCell flex={INCOME_FLEX_STYLES[2]} />
                        <FlexTable.HeadCell
                          flex={INCOME_FLEX_STYLES[3]}
                          align="right"
                        >
                          Value
                        </FlexTable.HeadCell>
                      </FlexTable.Row>
                    </FlexTable.Head>
                    <FlexTable.Content>
                      {Object.entries(performanceByAccount).map(
                        ([accountName, performances]) => (
                          <IncomeGeneration
                            accountName={accountName}
                            transactions={performances
                              .flatMap(({ transactions }) => transactions)
                              .filter((transaction) =>
                                TransactionTypesGeneratedByTheAccount.includes(
                                  transaction.type!,
                                ),
                              )}
                          />
                        ),
                      )}
                      <FlexTable.Row noHover hideBorder>
                        <FlexTable.Cell
                          flex={INCOME_FLEX_STYLES[0]}
                          css={{ fontWeight: '$bold' }}
                        >
                          All Accounts Total
                        </FlexTable.Cell>
                        <FlexTable.Cell flex={INCOME_FLEX_STYLES[1]} />
                        <FlexTable.Cell flex={INCOME_FLEX_STYLES[2]} />
                        <FlexTable.Cell
                          flex={INCOME_FLEX_STYLES[3]}
                          align="right"
                        >
                          {formatNumber(totalTransactionsAmount)}
                        </FlexTable.Cell>
                      </FlexTable.Row>
                    </FlexTable.Content>
                  </FlexTable>
                </>
              )}
            </Switcher.Content>
            <Switcher.Content value={Tabs.Balances}>
              <Col css={{ gap: '$10', mb: '$40' }}>
                {Object.values(selectedAccountBalancesWithinInterval).length >
                  0 && (
                  <LineChart
                    width={900}
                    height={250}
                    formatYAxis={(value: number) =>
                      UiUtils.nFormatter(value, 2, false, true)
                    }
                    formatTooltip={(value: number) =>
                      `${UiUtils.formatMoney(value)}`
                    }
                    data={selectedAccountBalancesWithinInterval}
                  />
                )}
                <Row css={{ gap: '$12', mt: '$16' }}>
                  <TimeRange
                    timeRangeOptions={[
                      {
                        label: NetWorthDateInterval.OneWeek,
                        value: NetWorthDateInterval.OneWeek,
                      },
                      {
                        label: NetWorthDateInterval.OneMonth,
                        value: NetWorthDateInterval.OneMonth,
                      },
                      {
                        label: NetWorthDateInterval.ThreeMonths,
                        value: NetWorthDateInterval.ThreeMonths,
                      },
                      {
                        label: NetWorthDateInterval.YearToDate,
                        value: NetWorthDateInterval.YearToDate,
                      },
                      {
                        label: NetWorthDateInterval.All,
                        value: NetWorthDateInterval.All,
                      },
                    ]}
                    timeRange={timeRange}
                    customFilterDateRange={customFilterDateRange}
                    onTimeRangeUpdate={(val) => setTimeRange(val)}
                    onUpdateCustomRange={(startDate, endDate) => {
                      setCustomFilterDateRange([startDate, endDate]);
                      setTimeRange(NetWorthDateInterval.Custom);
                    }}
                    earliestAssetAccountCreationDate={
                      new Date('01-01-1970')
                      // values.length ? new Date(values[0].date) : new Date()
                    }
                    css={{ w: 'fit-content' }}
                  />
                  <CoreTimeButton
                    selected={isCustomSelected}
                    onClick={() => {
                      setCustomFilterDateRange(
                        quarterIntervalDates(quarter, year),
                      );
                      setTimeRange(NetWorthDateInterval.Custom);
                    }}
                  >
                    {year} Q{quarter}
                  </CoreTimeButton>
                </Row>
              </Col>
              <FlexTable css={{ p: 0 }}>
                <FlexTable.Head>
                  <FlexTable.Row
                    css={{
                      height: '$32',
                      borderRadius: 0,
                    }}
                    noHover
                  >
                    <FlexTable.HeadCell flex={FLEX_STYLES[0]}>
                      Account
                    </FlexTable.HeadCell>
                    <FlexTable.HeadCell flex={FLEX_STYLES[1]}>
                      Starting balance
                    </FlexTable.HeadCell>
                    <FlexTable.HeadCell flex={FLEX_STYLES[2]} />
                    <FlexTable.HeadCell flex={FLEX_STYLES[3]}>
                      Ending balance
                    </FlexTable.HeadCell>
                    <FlexTable.HeadCell flex={FLEX_STYLES[4]} align="right">
                      Change
                    </FlexTable.HeadCell>
                  </FlexTable.Row>
                </FlexTable.Head>
                <FlexTable.Content>
                  {Object.entries(accountsPerformanceWithinInterval).map(
                    ([accountName, performances]) => {
                      const [
                        earliestQuarterSnapshot = {
                          beginBalance: 0,
                          endBalance: 0,
                        },
                        latestQuarterSnapshot = {
                          beginBalance: 0,
                          endBalance: 0,
                        },
                      ] = [performances.at(0), performances.at(-1)];
                      const startBalance = earliestQuarterSnapshot.beginBalance;
                      const endBalance = latestQuarterSnapshot.endBalance;
                      const change = endBalance - startBalance;
                      let changeTextColor: undefined | 'green10' | 'red10' =
                        undefined;
                      if (change > 0) {
                        changeTextColor = 'green10';
                      } else if (change < 0) {
                        changeTextColor = 'red10';
                      }
                      return (
                        <FlexTable.Row
                          css={{
                            py: '$10',
                            h: 'fit-content',
                            bg:
                              selectedAccount === accountName
                                ? '$gray2'
                                : undefined,
                            '>span': {
                              fontSize: '$13',
                            },
                          }}
                          hideBorder
                          showFirstBorder
                          onClick={() => setSelectedAccount(accountName)}
                        >
                          <FlexTable.Cell flex={FLEX_STYLES[0]}>
                            {accountName}
                          </FlexTable.Cell>
                          <FlexTable.Cell
                            flex={FLEX_STYLES[1]}
                            css={{ mb: 'auto', pt: '$1' }}
                          >
                            {UiUtils.formatMoney(startBalance)}
                          </FlexTable.Cell>
                          <FlexTable.Cell
                            flex={FLEX_STYLES[2]}
                            css={{ mb: 'auto', pt: '$1' }}
                          >
                            <ArrowRightIcon size={18} />
                          </FlexTable.Cell>
                          <FlexTable.Cell
                            flex={FLEX_STYLES[3]}
                            css={{ mb: 'auto', pt: '$1' }}
                          >
                            {UiUtils.formatMoney(endBalance)}
                          </FlexTable.Cell>
                          <FlexTable.Cell
                            flex={FLEX_STYLES[4]}
                            css={{ mb: 'auto', pt: '$1' }}
                            align="right"
                          >
                            <Text color={changeTextColor}>
                              {UiUtils.formatMoney(change)}
                            </Text>
                          </FlexTable.Cell>
                        </FlexTable.Row>
                      );
                    },
                  )}
                </FlexTable.Content>
              </FlexTable>
            </Switcher.Content>
          </Box>
        </Switcher>
      </Col>
    </Container>
  );
}

export default AccountOverview;
