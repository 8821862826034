// Scales min and max value for chart to be displayed properly
// Check test cases to understand better how this works
const getMinAndMaxForChart = (minValue: number, maxValue: number) => {
  const minMagnitude = Math.pow(
    10,
    Math.floor(Math.log10(Math.abs(minValue))) - 1,
  );
  const maxMagnitude = Math.pow(
    10,
    Math.floor(Math.log10(Math.abs(maxValue))) - 1,
  );

  return {
    minValue: minValue === 0 ? -1 : Math.floor(minValue - minMagnitude),
    maxValue: maxValue === 0 ? 1 : Math.ceil(maxValue + maxMagnitude),
  };
};

export default getMinAndMaxForChart;
