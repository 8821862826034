import { Button, Col, Flex, Text } from '@compoundfinance/design-system';

function ErrorScreen({
  error,
}: {
  readonly error?: { readonly message: string };
}) {
  return (
    <Flex
      css={{
        h: '100%',
        w: '100%',
        p: '$10',
        ai: 'center',
        '& :is(p, pre)': {
          m: '0',
        },
      }}
      data-error={error?.message}
    >
      <Col css={{ gap: '$12' }}>
        <Col css={{ gap: '$10' }}>
          <Text size="13">An unexpected error has happened.</Text>
          {error && (
            <pre>
              <Text size="13">{error.message}</Text>
            </pre>
          )}
          <Button
            size="small"
            onClick={() => {
              window.location.reload();
            }}
          >
            Try again
          </Button>
        </Col>
        <Col css={{ gap: '$10' }}>
          <Text size="13">
            If the error persists, please contact{' '}
            <a href="mailto:concierge@compoundplanning.com">
              concierge@compoundplanning.com
            </a>
            .
          </Text>
        </Col>
      </Col>
    </Flex>
  );
}

export default ErrorScreen;
