import { USER_FILING_STATUS } from '../user';
import STATES_IN_USA, { COMPOUND_SUPPORTED_STATES } from '../misc/geography';

export const STATE_SELECT_OPTIONS = Object.keys(STATES_IN_USA).map((abbr) => {
  return { label: STATES_IN_USA[abbr], value: abbr };
});

export const COMPOUND_SUPPORTED_STATE_SELECT_OPTIONS = (curState: string) => {
  const stateList = (COMPOUND_SUPPORTED_STATES as string[]).includes(curState)
    ? COMPOUND_SUPPORTED_STATES
    : [curState, ...COMPOUND_SUPPORTED_STATES];
  return stateList.map((abbr) => {
    return { label: (STATES_IN_USA[abbr] ?? '') as string, value: abbr };
  });
};

export const FILING_STATUS_TO_STR = {
  [USER_FILING_STATUS.SINGLE]: 'Single',
  [USER_FILING_STATUS.MARRIED_JOINTLY]: 'Married filing jointly',
  [USER_FILING_STATUS.MARRIED_SEPARATELY]: 'Married filing separately',
  [USER_FILING_STATUS.HEAD_OF_HOUSEHOLD]: 'Head of household',
};

export const FILING_STATUS_OPTIONS = [
  {
    label: FILING_STATUS_TO_STR[USER_FILING_STATUS.SINGLE],
    value: USER_FILING_STATUS.SINGLE,
  },
  {
    label: FILING_STATUS_TO_STR[USER_FILING_STATUS.MARRIED_JOINTLY],
    value: USER_FILING_STATUS.MARRIED_JOINTLY,
  },
  {
    label: FILING_STATUS_TO_STR[USER_FILING_STATUS.MARRIED_SEPARATELY],
    value: USER_FILING_STATUS.MARRIED_SEPARATELY,
  },
  {
    label: FILING_STATUS_TO_STR[USER_FILING_STATUS.HEAD_OF_HOUSEHOLD],
    value: USER_FILING_STATUS.HEAD_OF_HOUSEHOLD,
  },
];
