import EditablePerson from 'containers/Dashboard/Document/components/EditablePerson';
import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import { getPerson } from 'containers/Dashboard/Document/utils';
import { Text, Row, Col, Box } from '@compoundfinance/design-system';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';
import {
  DirectoryPerson,
  DirectoryPersonRole,
} from 'domain/Employees/constants';
import { PersonSize } from 'containers/Dashboard/Document/components/Person';

const Container = styled(ModuleContainer, {
  p: '$24',

  '@bp1': {
    py: '$44',
    px: '$48',
  },
});

const GridHalves = styled(Box, {
  display: 'grid',
  gap: '$24',

  '@bp1': {
    gridTemplateColumns: '1fr 1fr',
    gap: '$48',
  },
});

export interface InitialConsultationProps {
  salesLead: DirectoryPerson;
}

function InitialConsultation(props: InitialConsultationProps) {
  const { salesLead } = props;
  const salesLeadPerson = getPerson(salesLead);

  return (
    <Container>
      <Col css={{ h: '100%', justifyContent: 'space-between', gap: '$24' }}>
        <Row>
          <Text
            weight="medium"
            color="gray8"
            css={{
              fontSize: '$40',
              letterSpacing: '-0.8px',
              flexShrink: 0,

              '@bp1': { fontSize: '$48', lineHeight: '58px' },
            }}
          >
            Initial consultation
          </Text>
        </Row>
        <GridHalves>
          <Col css={{ gap: '$16', bg: '$blue10' }}>
            <Box css={{ borderBottom: '1px solid $gray4', pb: '$7' }}>
              <Text size="13" color="gray9">
                Initial consultation
              </Text>
            </Box>
            <Text size="14">
              We’ll help you determine the best financial advisory match for
              your unique situation, whether that’s Compound or another service.
            </Text>
            <Text size="14">During your initial consultation, we will:</Text>
            <Box as="ul" css={{ listStylePosition: 'outside', pl: '$12' }}>
              <Box as="li" css={{ listStyleType: 'disc' }}>
                <Text size="14">
                  Understand your financial situation and explore your goals.{' '}
                </Text>
              </Box>
              <Box as="li" css={{ listStyleType: 'disc' }}>
                <Text size="14">
                  Explore how Compound enhances your financial life and
                  demonstrate how we work with similar clients.{' '}
                </Text>
              </Box>
              <Box as="li" css={{ listStyleType: 'disc' }}>
                <Text size="14">
                  Explain Compound’s services and determine your next steps.
                </Text>
              </Box>
            </Box>
            <Text size="14">If there’s a potential fit, we will:</Text>
            <Box as="ul" css={{ listStylePosition: 'outside', pl: '$12' }}>
              <Box as="li" css={{ listStyleType: 'disc' }}>
                <Text size="14">
                  Schedule an introductory call with your advisor to draft an
                  initial financial plan.
                </Text>
              </Box>
              <Box as="li" css={{ listStyleType: 'disc' }}>
                <Text size="14">
                  Match you with one of our in-house advisors with expertise
                  that overlaps with your finances and goals.{' '}
                </Text>
              </Box>
            </Box>
          </Col>
          <Col css={{ gap: '$16', bg: '$red10' }}>
            <Box css={{ borderBottom: '1px solid $gray4', pb: '$7' }}>
              <Text size="13" color="gray9">
                Your membership consultant
              </Text>
            </Box>
            <Col css={{ gap: '$24' }}>
              <EditablePerson
                propertyKey="salesLead"
                propertyValue={salesLeadPerson}
                placeholder="Select Membership Consultant"
                personRole={DirectoryPersonRole.MembershipConsultant}
                person={salesLeadPerson}
                size={PersonSize.Medium}
              />
              <Text
                size="14"
                css={{ maxWidth: '448px', whiteSpace: 'pre-wrap' }}
              >
                {salesLeadPerson.description}
              </Text>
            </Col>
          </Col>
        </GridHalves>
      </Col>
    </Container>
  );
}

export default InitialConsultation;
