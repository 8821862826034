import { useState } from 'react';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';

import { Col, FlexTable, Row, Text } from '@compoundfinance/design-system';

import { PlaidAccount } from '@compoundfinance/compound-core/dist/types/plaid';
import AssetUtils from 'utils/assets';
import { InstitutionLogos } from 'utils/assets/institutionLogos';
import { InstitutionName } from 'utils/plaid/constants';
import UiUtils, { capitalizeFirstLetters } from 'utils/ui';

import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import AccountLogo from 'components/AccountLogo';
import { Pagination } from 'components/Pagination';

const Container = styled(ModuleContainer, {
  py: '$24',
  px: '$24',
  gap: '$24',

  '@bp1': {
    py: '$44',
    px: '$48',
  },
});

const FLEX_STYLES = [0.4, 0.15, 0.15, 0.15, 0.15];

type Holding = {
  id: string;
  name: string;
  ticker: string;
  value: number;
  ltcg: number;
  stcg: number;
  costOfTransition: number;
  targetName: string;
  account: PlaidAccount;
};

type Transfer = {
  id: string;
  value: number;
  ltcg: number;
  stcg: number;
  costOfTransition: number;
  fromAccount: PlaidAccount;
  toAccount: { name: string };
  holdings: Holding[];
};

export interface AccountsProps {
  transfers?: {
    [fromAccountName: string]: {
      [toAccountId: string]: Holding[];
    };
  };
}

function Accounts(props: AccountsProps) {
  const { transfers: _transfers = [] } = props;
  const transfers = Object.entries(_transfers).flatMap(([_, toAccounts]) => {
    return Object.entries(toAccounts).reduce(
      (processedTransfers, [_, holdings]) => {
        const [{ account: fromAccount, targetName }] = holdings;
        const value = holdings.reduce(
          (value, holding) => value + holding.value,
          0,
        );
        const ltcg = holdings.reduce((ltcg, holding) => ltcg + holding.ltcg, 0);
        const stcg = holdings.reduce((stcg, holding) => stcg + holding.stcg, 0);
        const costOfTransition = holdings.reduce(
          (costOfTranstion, holding) =>
            costOfTranstion + holding.costOfTransition,
          0,
        );
        return [
          ...processedTransfers,
          {
            id: `${fromAccount.name}-${targetName}`,
            fromAccount,
            toAccount: { name: targetName },
            value,
            ltcg,
            stcg,
            costOfTransition,
            holdings: holdings,
          },
        ];
      },
      [],
    );
  });

  return (
    <Container>
      <Col css={{ gap: '$40' }}>
        {transfers.map((transfer) => (
          <Transfer key={transfer.fromAccount.id} transfer={transfer} />
        ))}
      </Col>
    </Container>
  );
}

const PAGE_LENGTH = 5;
const Transfer = ({ transfer }: { transfer: Transfer }) => {
  const { toAccount, fromAccount, holdings } = transfer;
  const [page, setPage] = useState(1);
  const pageCount = Math.ceil(holdings.length / PAGE_LENGTH);
  return (
    <Col css={{ gap: '$2' }}>
      <FlexTable>
        <FlexTable.Head>
          <FlexTable.Row noHover>
            <FlexTable.HeadCell flex={FLEX_STYLES[0]} css={{ pr: '$20' }}>
              Account / Position
            </FlexTable.HeadCell>
            <FlexTable.HeadCell flex={FLEX_STYLES[1]}>
              Position Size
            </FlexTable.HeadCell>
            <FlexTable.HeadCell flex={FLEX_STYLES[2]}>LTCG</FlexTable.HeadCell>
            <FlexTable.HeadCell flex={FLEX_STYLES[3]}>STCG</FlexTable.HeadCell>
            <FlexTable.HeadCell flex={FLEX_STYLES[4]}>
              Cost of transition
            </FlexTable.HeadCell>
          </FlexTable.Row>
        </FlexTable.Head>
        <FlexTable.Content>
          <FlexTable.Row noHover>
            <FlexTable.Cell
              flex={FLEX_STYLES[0]}
              css={{ pr: '$20', overflow: 'hidden' }}
            >
              <Row css={{ ai: 'center', gap: '$12' }}>
                <Row css={{ ai: 'center', gap: '$6', overflow: 'hidden' }}>
                  <AccountLogo
                    {...AssetUtils.getDefaultAssetImageProps(
                      fromAccount as any,
                    )}
                  />
                  <Text
                    size="14"
                    css={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {fromAccount.name}
                  </Text>
                </Row>

                <Text size="20" color="gray9">
                  →
                </Text>

                <Row css={{ ai: 'center', gap: '$6', overflow: 'hidden' }}>
                  <AccountLogo
                    size={26}
                    logo={InstitutionLogos[InstitutionName.Compound]}
                  />
                  <Text
                    size="14"
                    css={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {toAccount.name}
                  </Text>
                </Row>
              </Row>
            </FlexTable.Cell>
            <FlexTable.Cell flex={FLEX_STYLES[1]}>
              {UiUtils.formatMoney(transfer.value)}
            </FlexTable.Cell>
            <FlexTable.Cell flex={FLEX_STYLES[2]}>
              {UiUtils.formatMoney(transfer.ltcg)}
            </FlexTable.Cell>
            <FlexTable.Cell flex={FLEX_STYLES[3]}>
              {UiUtils.formatMoney(transfer.stcg)}
            </FlexTable.Cell>
            <FlexTable.Cell flex={FLEX_STYLES[4]}>
              {UiUtils.formatMoney(transfer.costOfTransition)}
            </FlexTable.Cell>
          </FlexTable.Row>

          {holdings
            .slice((page - 1) * PAGE_LENGTH, page * PAGE_LENGTH)
            .map((holding) => (
              <FlexTable.Row key={holding.id} noHover>
                <FlexTable.Cell
                  flex={FLEX_STYLES[0]}
                  css={{ pr: '$20', overflow: 'hidden' }}
                >
                  <Row css={{ overflow: 'hidden', ai: 'center', gap: '$8' }}>
                    <Text
                      css={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                    >
                      {capitalizeFirstLetters(holding.name.toLowerCase())}
                    </Text>
                    <Text color="gray9">{holding.ticker}</Text>
                  </Row>
                </FlexTable.Cell>
                <FlexTable.Cell css={{ color: '$gray9' }} flex={FLEX_STYLES[1]}>
                  {UiUtils.formatMoney(holding.value)}
                </FlexTable.Cell>
                <FlexTable.Cell css={{ color: '$gray9' }} flex={FLEX_STYLES[2]}>
                  {UiUtils.formatMoney(holding.ltcg)}
                </FlexTable.Cell>
                <FlexTable.Cell css={{ color: '$gray9' }} flex={FLEX_STYLES[3]}>
                  {UiUtils.formatMoney(holding.stcg)}
                </FlexTable.Cell>
                <FlexTable.Cell css={{ color: '$gray9' }} flex={FLEX_STYLES[4]}>
                  {UiUtils.formatMoney(holding.costOfTransition)}
                </FlexTable.Cell>
              </FlexTable.Row>
            ))}
        </FlexTable.Content>
      </FlexTable>

      {pageCount > 1 && (
        <Pagination
          count={pageCount}
          current={page}
          setCurrent={setPage}
          className="mt-base"
        />
      )}
    </Col>
  );
};

export default Accounts;
