import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';
import { Box, Col, Row, Text } from '@compoundfinance/design-system';
import AdvisoryServices from './AdvisoryServices';
import TaxFiling from './TaxFiling';
import ManagedInvestments from './ManagedInvestments';
import ExclusiveAccess from './ExclusiveAccess';

const DEFAULT_SERVICES = [
  {
    title: 'Advisory services',
    description: `You get a dedicated team of financial, tax, and investment advisors. Their job is to help you make the best possible decisions. We can help you prepare for an IPO, plan for a family, or set up a donor-advised fund. And if you ever have an ad-hoc request ("Transfer this amount from my distribution account for my Q1 capital call"), just email your team and we'll take care of it.`,
    image: <AdvisoryServices />,
  },
  {
    title: 'Tax filing',
    description: `Compound’s in-house team of CPAs and tax experts work in concert with your financial advisor to provide a range of services, including monitoring your estimated tax liability, developing strategies, and filing your taxes. Our tax filing service includes filing your federal return, as many state returns as needed, and, if you need them, extensions and quarterly estimates. We aim to make the process as pain-free as possible—we’ll tell you what we need, when we need it, and precisely when your returns are being filed so there are no surprises.`,
    image: <TaxFiling />,
  },
  {
    title: 'Managed investments',
    description: `Work with your dedicated team to design and implement a portfolio that takes into account your full balance sheet—including your startup equity and private investments. Connect your accounts, visualize your holdings, and design a strategy around them. Built for the long-term, so you can grow with confidence.`,
    image: <ManagedInvestments />,
  },
  {
    title: 'Exclusive access',
    description: `Diversify your portfolio with private funds previously reserved for family offices and ultra-high-net-worth investors. Discover and invest in low-minimum opportunities across venture capital, real estate, and more—all vetted by our in-house team of investment experts.`,
    image: <ExclusiveAccess />,
  },
];

const Container = styled(ModuleContainer, {
  p: '$24',

  '@bp1': {
    py: '$44',
    px: '$48',
  },
});

const ServiceRow = styled(Box, {
  display: 'grid',
  gap: '$12',

  '@bp1': {
    gap: '$48',
    gridTemplateColumns: '1fr .85fr',
  },
});

export interface ServicesProps {
  steps?: Array<{ title: string; description: string; image: JSX.Element }>;
}

function Services(props: ServicesProps) {
  const { steps = DEFAULT_SERVICES } = props;

  return (
    <Container>
      <Row css={{ gap: '$24', '@bp1': { gap: '$48' } }}>
        <Text
          weight="medium"
          color="gray8"
          css={{
            fontSize: '$20',
            letterSpacing: '-0.8px',
            flexShrink: 0,

            '@bp1': { fontSize: '$48', lineHeight: '58px' },
          }}
        >
          Services
        </Text>
      </Row>
      <Col css={{ py: '$24', gap: '$24' }}>
        {steps.map((step, index) => (
          <ServiceRow>
            <Col css={{ gap: '$7' }}>
              <Text size="20" color="gray13" weight="medium">
                {step.title}
              </Text>
              <Text size="14" color="gray11">
                {step.description}
              </Text>
            </Col>
            <Col>{step.image}</Col>
          </ServiceRow>
        ))}
      </Col>
    </Container>
  );
}

export default Services;
