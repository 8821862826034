import { Status, FileManagerState } from './reducer';
import { LifecycleStatus } from 'types/reducers';

const isRejected = (state: FileManagerState) =>
  state.requestStatus === LifecycleStatus.Rejected;

const isIdle = (state: FileManagerState) =>
  state.requestStatus === LifecycleStatus.Idle;

const isPending = (state: FileManagerState) =>
  state.requestStatus === LifecycleStatus.Pending;

const isResolved = (state: FileManagerState) =>
  state.requestStatus === LifecycleStatus.Resolved;

const isUploadSuccess = (state: FileManagerState) =>
  state.status === Status.Upload && isResolved(state);

const isUploadError = (state: FileManagerState) =>
  state.status === Status.Upload && isRejected(state);

const isUploadIdleForSomeFile = (state: FileManagerState) =>
  Object.values(state.fileUploadStatus).some(
    (fus) => fus.status === LifecycleStatus.Idle,
  );

const isUploadSuccessForFile = (filename: string, state: FileManagerState) =>
  state.fileUploadStatus[filename]?.status === LifecycleStatus.Resolved;

const isUploadErrorForFile = (filename: string, state: FileManagerState) =>
  state.fileUploadStatus[filename]?.status === LifecycleStatus.Rejected;

const FileManagerUtils = {
  isRejected,
  isIdle,
  isPending,
  isResolved,
  isUploadSuccess,
  isUploadError,
  isUploadIdleForSomeFile,
  isUploadSuccessForFile,
  isUploadErrorForFile,
};

export default FileManagerUtils;
