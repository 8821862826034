import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import URLUtils from './constants/url';

const SentryUtils = {
  init: () => {
    if (process.env.NODE_ENV === 'production') {
      Sentry.init({
        dsn: 'https://4984823c57f84dc69fcf72e5ce3b72cf@o351733.ingest.sentry.io/2459655',
        integrations: [new Integrations.BrowserTracing()],
        // We recommend adjusting this value in production, or using tracesSampler for finer control
        tracesSampleRate: 1.0,
        release: `compound-frontend@${
          process.env.REACT_APP_VERSION ?? 'localhost'
        }`,
        environment: URLUtils.isProduction() ? 'production' : 'staging',
      });
    }
  },
};

export default SentryUtils;
