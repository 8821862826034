import { CompoundCFill } from 'assets/svg/CompoundC';
import Wordmark from 'assets/svg/Wordmark';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';
import { Box, Col, Text } from '@compoundfinance/design-system';
import URLUtils from 'utils/constants/url';

const Deeplink = () => {
  return (
    <Col
      css={{
        jc: 'space-between',
        ai: 'center',
        h: '100%',
        pt: '$28',
        pb: '$64',
      }}
    >
      <Wordmark color="#222" />
      <Col css={{ ai: 'center' }}>
        <LogoWrapper>
          <CompoundCFill
            color="hsl(202, 61%, 98%)"
            height="50px"
            width="43px"
          />
        </LogoWrapper>
        <Text
          weight="bold"
          size="26"
          css={{
            letterSpacing: '-0.018em',
            lineHeight: '1.3em',
            mt: '$20',
            ta: 'center',
          }}
        >
          {URLUtils.isDemo() ? 'Compound Demo' : 'Opening the link'}{' '}
          <Box css={{ display: 'block', color: '$gray9' }}>
            {URLUtils.isDemo()
              ? "isn't available on mobile"
              : 'in the iOS app...'}
          </Box>
        </Text>
      </Col>
      <Text size="15" css={{ color: '$gray11', ta: 'center' }}>
        {URLUtils.isDemo() ? (
          'Please visit demo.compoundplanning.com on a desktop browser.'
        ) : (
          <>
            If you don't have the iOS app, please download it
            <Box
              as="a"
              href="https://apps.apple.com/us/app/compound-mobile/id1570521519"
              css={{
                textDecoration: 'underline',
                color: 'inherit',
                display: 'block',
              }}
            >
              here
            </Box>
          </>
        )}
      </Text>
    </Col>
  );
};

const LogoWrapper = styled(Box, {
  h: '$84',
  w: '$84',
  background: 'linear-gradient(42.09deg,$colors$gray13 4.59%,#272d3b 94.52%);',
  boxShadow:
    '0px 36.2963px 23.4259px rgba(0, 0, 0, 0.0531481), 0px 21.5704px 12.7407px rgba(0, 0, 0, 0.0425185), 0px 11.2px 6.5px rgba(0, 0, 0, 0.035), 0px 4.56296px 3.25926px rgba(0, 0, 0, 0.0274815), 0px 1.03704px 1.57407px rgba(0, 0, 0, 0.0168519);',
  display: 'flex',
  ai: 'center',
  jc: 'center',
  br: '$20',
});

export default Deeplink;
