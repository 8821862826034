import { useState } from 'react';
import { ValueOf } from 'types';

import {
  Box,
  Button,
  Col,
  CollapseToggleIcon,
  Flex,
  Row,
  Text,
} from '@compoundfinance/design-system';

import { ASSET_CATEGORY_SLUG_TO_COLOR } from 'domain/AssetCategory/colors';
import { AssetCategorySlug } from 'domain/AssetCategory/constants';
import {
  AllocationProposal,
  AllocationProposalTypes,
} from 'containers/Dashboard/AllocationProposals/domain/AllocationProposal';
import { AllocationProposalStrategy } from 'containers/Dashboard/AllocationProposals/domain/AllocationProposalStrategy';
import {
  Allocation,
  AllocationTypes,
} from 'containers/Dashboard/AllocationProposals/domain/Allocation';
import { AssetCategoryTree } from 'containers/Dashboard/AllocationProposals/utils/AssetCategoryTree';
import { InvestmentProposal } from 'containers/Dashboard/AllocationProposals/domain/InvestmentProposal';
import { NumberUtils } from 'utils/NumberUtils';

import { AssetsAndAssetClassesSwitcher } from 'containers/Dashboard/AllocationProposals/components/AssetsAndAssetClasses';
import { ValueAndPercentageComparison } from '../../../components/Figures';
import InitialVsTargetComparison from 'containers/Dashboard/AllocationProposals/components/InitialVsTargetComparison';

const toComparison = (
  allocationProposal: AllocationProposalTypes.CategorizedAllocationProposal,
) => {
  const {
    blended: {
      original: {
        wholistic: { allocations: wholisticOriginalAllocations },
      },
      target: {
        wholistic: { allocations: wholisticTargetAllocations },
      },
    },
  } = allocationProposal;

  const wholisticComparisonAllocationTree = AssetCategoryTree.toTree<
    {
      slug: AssetCategorySlug;
      name: string;
      original: AllocationTypes.CategoryAllocation;
      target: AllocationTypes.CategoryAllocation;
    },
    AllocationTypes.CategoryAllocation
  >(
    AllocationProposalStrategy.toCategorized(
      AllocationProposalStrategy.create(wholisticOriginalAllocations),
    ),
    (allocation) => ({
      slug: allocation.slug,
      name: allocation.name,
      original: allocation,
      target: (wholisticTargetAllocations[allocation.slug] ?? {
        ...allocation,
        weight: 0,
        value: 0,
      }) as AllocationTypes.CategoryAllocation,
    }),
  );

  const publicAssets = wholisticComparisonAllocationTree.find(
    (allocation) => allocation.slug === 'public-assets',
  )!;
  const [publicEquities, fixedIncome] = publicAssets.children.filter(
    (publicAssetClass) =>
      ['equities', 'fixed-income'].includes(publicAssetClass.slug),
  );
  const liquidAssets = [
    ...publicAssets.children.filter((publicAssetClass) =>
      ['alternatives', 'commodities', 'other-public-assets'].includes(
        publicAssetClass.slug,
      ),
    ),
    ...wholisticComparisonAllocationTree.filter((assetClass) =>
      ['cryptocurrency'].includes(assetClass.slug),
    ),
  ];
  const illiquidAssets = wholisticComparisonAllocationTree.filter(
    (assetClass) =>
      !['public-assets', 'cash', 'cryptocurrency'].includes(assetClass.slug),
  );
  const [cash] = wholisticComparisonAllocationTree.filter(
    (assetClass) => assetClass.slug === 'cash',
  );

  return {
    publicEquities,
    fixedIncome,
    liquidAssets,
    illiquidAssets,
    cash,
  };
};

const SectionVisibilityStatus = {
  EXPANDED: 'expanded',
  COLLAPSED: 'collapsed',
} as const;
type SectionVisibilityStatus = ValueOf<typeof SectionVisibilityStatus>;

export const ProposedAllocationChanges = ({
  allocationProposal,
}: {
  allocationProposal: AllocationProposalTypes.CategorizedAllocationProposal;
}) => {
  const [sectionVisibilityStatus, setSectionVisibilityStatus] = useState<
    Record<
      | 'publicEquities'
      | 'fixedIncome'
      | 'liquidAssets'
      | 'illiquidAssets'
      | 'cash',
      SectionVisibilityStatus
    >
  >({
    publicEquities: SectionVisibilityStatus.COLLAPSED,
    fixedIncome: SectionVisibilityStatus.COLLAPSED,
    liquidAssets: SectionVisibilityStatus.COLLAPSED,
    illiquidAssets: SectionVisibilityStatus.COLLAPSED,
    cash: SectionVisibilityStatus.COLLAPSED,
  });

  const toggleSectionVisibilityStatus = (section: string) => {
    setSectionVisibilityStatus({
      ...sectionVisibilityStatus,
      [section]:
        sectionVisibilityStatus[section] === SectionVisibilityStatus.EXPANDED
          ? SectionVisibilityStatus.COLLAPSED
          : sectionVisibilityStatus[section] ===
            SectionVisibilityStatus.COLLAPSED
          ? SectionVisibilityStatus.EXPANDED
          : SectionVisibilityStatus.EXPANDED,
    });
  };

  const { publicEquities, fixedIncome, liquidAssets, illiquidAssets, cash } =
    toComparison(allocationProposal);

  return (
    <Col css={{ gap: '$24' }}>
      <Row css={{ ai: 'center', gap: '$4' }}>
        <Text size="15" weight="medium" color="gray8">
          Initial
        </Text>
        <Text size="15" weight="medium" color="gray8">
          →
        </Text>
        <Text size="15" weight="medium">
          Proposed allocation
        </Text>
      </Row>

      <Row css={{ minHeight: '460px', gap: '$48' }}>
        <Row css={{ w: 'calc(100% - 480px)', gap: '$48' }}>
          <Col css={{ w: '100%', h: 'fit-content', gap: '$24' }}>
            <Col css={{ gap: '$12' }}>
              <Row css={{ ai: 'start', gap: '$4' }}>
                <Button
                  css={{
                    all: 'unset',
                    display: 'grid',
                    placeItems: 'center',
                    '&:hover, &:focus': {
                      boxShadow: '$focus',
                      color: '$gray12',
                    },
                  }}
                  onClick={() => {
                    toggleSectionVisibilityStatus('publicEquities');
                  }}
                >
                  <CollapseToggleIcon
                    size={16}
                    css={{
                      transform:
                        sectionVisibilityStatus.publicEquities ===
                        SectionVisibilityStatus.EXPANDED
                          ? 'rotate(90deg)'
                          : '',
                      transition: 'transform 0.1s ease',
                    }}
                  />
                </Button>

                <Col css={{ w: '100%', gap: '$4' }}>
                  <Row css={{ jc: 'space-between', ai: 'center' }}>
                    <Text size="13" weight="medium">
                      Public equity
                    </Text>

                    <Row css={{ ai: 'center', gap: '$6' }}>
                      <ValueAndPercentageComparison
                        original={publicEquities.original}
                        target={publicEquities.target}
                      />
                    </Row>
                  </Row>

                  <Box css={{ h: '1px', w: '100%', bg: '$gray4' }} />
                </Col>
              </Row>

              {sectionVisibilityStatus.publicEquities ===
                SectionVisibilityStatus.EXPANDED && (
                <>
                  {publicEquities.children.flatMap((publicEquity) =>
                    publicEquity.children.map((publicEquity) => (
                      <Row
                        key={publicEquity.slug}
                        css={{
                          jc: 'space-between',
                          ai: 'center',
                          gap: '$12',
                          overflow: 'hidden',
                        }}
                      >
                        <Row
                          css={{ ai: 'center', gap: '$8', overflow: 'hidden' }}
                        >
                          <Box
                            css={{
                              flex: '0 0 auto',
                              h: '$16',
                              w: '$16',
                              br: '$2',
                              bg: ASSET_CATEGORY_SLUG_TO_COLOR[
                                publicEquity.slug
                              ],
                              overflow: 'hidden',
                            }}
                          />

                          <Text
                            size="13"
                            color="gray11"
                            css={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                            }}
                          >
                            {publicEquity.name}
                          </Text>
                        </Row>

                        <ValueAndPercentageComparison
                          original={publicEquity.original}
                          target={publicEquity.target}
                        />
                      </Row>
                    )),
                  )}
                </>
              )}
            </Col>

            <Col css={{ gap: '$12' }}>
              <Row css={{ ai: 'start', gap: '$4' }}>
                <Button
                  css={{
                    all: 'unset',
                    display: 'grid',
                    placeItems: 'center',
                    '&:hover, &:focus': {
                      boxShadow: '$focus',
                      color: '$gray12',
                    },
                  }}
                  onClick={() => {
                    toggleSectionVisibilityStatus('fixedIncome');
                  }}
                >
                  <CollapseToggleIcon
                    size={16}
                    css={{
                      transform:
                        sectionVisibilityStatus.fixedIncome ===
                        SectionVisibilityStatus.EXPANDED
                          ? 'rotate(90deg)'
                          : '',
                      transition: 'transform 0.1s ease',
                    }}
                  />
                </Button>

                <Col css={{ w: '100%', gap: '$4' }}>
                  <Row css={{ jc: 'space-between', ai: 'center' }}>
                    <Text size="13" weight="medium">
                      Fixed income
                    </Text>

                    <Row css={{ ai: 'center', gap: '$6' }}>
                      <ValueAndPercentageComparison
                        original={fixedIncome.original}
                        target={fixedIncome.target}
                      />
                    </Row>
                  </Row>

                  <Box css={{ h: '1px', w: '100%', bg: '$gray4' }} />
                </Col>
              </Row>

              {sectionVisibilityStatus.fixedIncome ===
                SectionVisibilityStatus.EXPANDED && (
                <>
                  {fixedIncome.children.map((fixedIncome) => (
                    <Row
                      key={fixedIncome.slug}
                      css={{
                        jc: 'space-between',
                        ai: 'center',
                        gap: '$12',
                        overflow: 'hidden',
                      }}
                    >
                      <Row
                        css={{ ai: 'center', gap: '$8', overflow: 'hidden' }}
                      >
                        <Box
                          css={{
                            flex: '0 0 auto',
                            h: '$16',
                            w: '$16',
                            br: '$2',
                            bg: ASSET_CATEGORY_SLUG_TO_COLOR[fixedIncome.slug],
                            overflow: 'hidden',
                          }}
                        />

                        <Text
                          size="13"
                          color="gray11"
                          css={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                          }}
                        >
                          {fixedIncome.name}
                        </Text>
                      </Row>

                      <ValueAndPercentageComparison
                        original={fixedIncome.original}
                        target={fixedIncome.target}
                      />
                    </Row>
                  ))}
                </>
              )}
            </Col>

            <Col css={{ gap: '$12' }}>
              <Row css={{ ai: 'start', gap: '$4' }}>
                <Button
                  css={{
                    all: 'unset',
                    display: 'grid',
                    placeItems: 'center',
                    '&:hover, &:focus': {
                      boxShadow: '$focus',
                      color: '$gray12',
                    },
                  }}
                  onClick={() => {
                    toggleSectionVisibilityStatus('liquidAssets');
                  }}
                >
                  <CollapseToggleIcon
                    size={16}
                    css={{
                      transform:
                        sectionVisibilityStatus.liquidAssets ===
                        SectionVisibilityStatus.EXPANDED
                          ? 'rotate(90deg)'
                          : '',
                      transition: 'transform 0.1s ease',
                    }}
                  />
                </Button>

                <Col css={{ w: '100%', gap: '$4' }}>
                  <Row css={{ jc: 'space-between', ai: 'center' }}>
                    <Text size="13" weight="medium">
                      Other liquid assets
                    </Text>

                    <Row css={{ ai: 'center', gap: '$6' }}>
                      <ValueAndPercentageComparison
                        original={{
                          value: liquidAssets.reduce(
                            (value, liquidAsset) =>
                              NumberUtils.add(
                                value,
                                Allocation.value(liquidAsset.original),
                              ),
                            0,
                          ),
                          weight: liquidAssets.reduce(
                            (weight, liquidAsset) =>
                              NumberUtils.add(
                                weight,
                                Allocation.weight(liquidAsset.original),
                              ),
                            0,
                          ),
                        }}
                        target={{
                          value: liquidAssets.reduce(
                            (value, liquidAsset) =>
                              NumberUtils.add(
                                value,
                                Allocation.value(liquidAsset.target),
                              ),
                            0,
                          ),
                          weight: liquidAssets.reduce(
                            (weight, liquidAsset) =>
                              NumberUtils.add(
                                weight,
                                Allocation.weight(liquidAsset.target),
                              ),
                            0,
                          ),
                        }}
                      />
                    </Row>
                  </Row>

                  <Box css={{ h: '1px', w: '100%', bg: '$gray4' }} />
                </Col>
              </Row>

              {sectionVisibilityStatus.liquidAssets ===
                SectionVisibilityStatus.EXPANDED && (
                <>
                  {liquidAssets.map((liquidAsset) => (
                    <Row
                      key={liquidAsset.slug}
                      css={{
                        jc: 'space-between',
                        ai: 'center',
                        gap: '$12',
                        overflow: 'hidden',
                      }}
                    >
                      <Row
                        css={{ ai: 'center', gap: '$8', overflow: 'hidden' }}
                      >
                        <Box
                          css={{
                            flex: '0 0 auto',
                            h: '$16',
                            w: '$16',
                            br: '$2',
                            bg: ASSET_CATEGORY_SLUG_TO_COLOR[liquidAsset.slug],
                            overflow: 'hidden',
                          }}
                        />

                        <Text
                          size="13"
                          color="gray11"
                          css={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                          }}
                        >
                          {liquidAsset.name}
                        </Text>
                      </Row>

                      <ValueAndPercentageComparison
                        original={liquidAsset.original}
                        target={liquidAsset.target}
                      />
                    </Row>
                  ))}
                </>
              )}
            </Col>

            <Col css={{ gap: '$12' }}>
              <Row css={{ ai: 'start', gap: '$4' }}>
                <Button
                  css={{
                    all: 'unset',
                    display: 'grid',
                    placeItems: 'center',
                    '&:hover, &:focus': {
                      boxShadow: '$focus',
                      color: '$gray12',
                    },
                  }}
                  onClick={() => {
                    toggleSectionVisibilityStatus('cash');
                  }}
                >
                  <CollapseToggleIcon
                    size={16}
                    css={{
                      transform:
                        sectionVisibilityStatus.cash ===
                        SectionVisibilityStatus.EXPANDED
                          ? 'rotate(90deg)'
                          : '',
                      transition: 'transform 0.1s ease',
                    }}
                  />
                </Button>

                <Col css={{ w: '100%', gap: '$4' }}>
                  <Row css={{ jc: 'space-between', ai: 'center' }}>
                    <Text size="13" weight="medium">
                      Cash
                    </Text>

                    <ValueAndPercentageComparison
                      original={cash.original}
                      target={cash.target}
                    />
                  </Row>

                  <Box css={{ h: '1px', w: '100%', bg: '$gray4' }} />
                </Col>
              </Row>

              {sectionVisibilityStatus.cash ===
                SectionVisibilityStatus.EXPANDED && (
                <Row
                  key={cash.slug}
                  css={{
                    jc: 'space-between',
                    ai: 'center',
                    gap: '$12',
                    overflow: 'hidden',
                  }}
                >
                  <Row css={{ ai: 'center', gap: '$8', overflow: 'hidden' }}>
                    <Box
                      css={{
                        flex: '0 0 auto',
                        h: '$16',
                        w: '$16',
                        br: '$2',
                        bg: ASSET_CATEGORY_SLUG_TO_COLOR[cash.slug],
                        overflow: 'hidden',
                      }}
                    />

                    <Text
                      size="13"
                      color="gray11"
                      css={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                    >
                      {cash.name}
                    </Text>
                  </Row>

                  <ValueAndPercentageComparison
                    original={cash.original}
                    target={cash.target}
                  />
                </Row>
              )}
            </Col>

            <Col css={{ gap: '$12' }}>
              <Row css={{ ai: 'start', gap: '$4' }}>
                <Button
                  css={{
                    all: 'unset',
                    display: 'grid',
                    placeItems: 'center',
                    '&:hover, &:focus': {
                      boxShadow: '$focus',
                      color: '$gray12',
                    },
                  }}
                  onClick={() => {
                    toggleSectionVisibilityStatus('illiquidAssets');
                  }}
                >
                  <CollapseToggleIcon
                    size={16}
                    css={{
                      transform:
                        sectionVisibilityStatus.illiquidAssets ===
                        SectionVisibilityStatus.EXPANDED
                          ? 'rotate(90deg)'
                          : '',
                      transition: 'transform 0.1s ease',
                    }}
                  />
                </Button>

                <Col css={{ w: '100%', gap: '$4' }}>
                  <Row css={{ jc: 'space-between', ai: 'center' }}>
                    <Text size="13" weight="medium">
                      Illiquid
                    </Text>

                    <Row css={{ ai: 'center', gap: '$6' }}>
                      <ValueAndPercentageComparison
                        original={{
                          value: illiquidAssets.reduce(
                            (value, illiquidAsset) =>
                              NumberUtils.add(
                                value,
                                Allocation.value(illiquidAsset.original),
                              ),
                            0,
                          ),
                          weight: illiquidAssets.reduce(
                            (weight, illiquidAsset) =>
                              NumberUtils.add(
                                weight,
                                Allocation.weight(illiquidAsset.original),
                              ),
                            0,
                          ),
                        }}
                        target={{
                          value: illiquidAssets.reduce(
                            (value, illiquidAsset) =>
                              NumberUtils.add(
                                value,
                                Allocation.value(illiquidAsset.target),
                              ),
                            0,
                          ),
                          weight: illiquidAssets.reduce(
                            (weight, illiquidAsset) =>
                              NumberUtils.add(
                                weight,
                                Allocation.weight(illiquidAsset.target),
                              ),
                            0,
                          ),
                        }}
                      />
                    </Row>
                  </Row>

                  <Box css={{ h: '1px', w: '100%', bg: '$gray4' }} />
                </Col>
              </Row>

              {sectionVisibilityStatus.illiquidAssets ===
                SectionVisibilityStatus.EXPANDED && (
                <>
                  {illiquidAssets.map((illiquidAsset) => (
                    <Row
                      key={illiquidAsset.slug}
                      css={{
                        jc: 'space-between',
                        ai: 'center',
                        gap: '$12',
                        overflow: 'hidden',
                      }}
                    >
                      <Row
                        css={{ ai: 'center', gap: '$8', overflow: 'hidden' }}
                      >
                        <Box
                          css={{
                            flex: '0 0 auto',
                            h: '$16',
                            w: '$16',
                            br: '$2',
                            bg: ASSET_CATEGORY_SLUG_TO_COLOR[
                              illiquidAsset.slug
                            ],
                            overflow: 'hidden',
                          }}
                        />

                        <Text
                          size="13"
                          color="gray11"
                          css={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                          }}
                        >
                          {illiquidAsset.name}
                        </Text>
                      </Row>

                      <ValueAndPercentageComparison
                        original={illiquidAsset.original}
                        target={illiquidAsset.target}
                      />
                    </Row>
                  ))}
                </>
              )}
            </Col>
          </Col>
        </Row>

        <Row
          css={{
            flex: '1 0 auto',
            position: 'sticky',
            top: 0,
            w: '480px',
            maxHeight: '100vh',
            gap: '$16',
          }}
        >
          <InitialVsTargetComparison
            allocationProposal={allocationProposal}
            barChartHeaders={{
              source: () => (
                <Flex css={{ p: '$8 $12 $4' }}>
                  <Text size="12" weight="medium">
                    Initial allocation
                  </Text>
                </Flex>
              ),
              target: () => (
                <Flex css={{ p: '$8 $12 $4' }}>
                  <Text size="12" weight="medium">
                    Proposed allocation
                  </Text>
                </Flex>
              ),
            }}
            footer={
              AllocationProposal.isStrategic(allocationProposal) &&
              InvestmentProposal.hasAssetInformation(allocationProposal)
                ? ({ setActiveTab }) => (
                    <Flex css={{ jc: 'end', ai: 'center' }}>
                      <AssetsAndAssetClassesSwitcher
                        setActiveTab={setActiveTab}
                      />
                    </Flex>
                  )
                : null
            }
            projections={null}
          />
        </Row>
      </Row>
    </Col>
  );
};
