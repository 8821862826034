// TODO: merge with shared/types/AssetTypes
export enum AssetType {
  RealEstate = 'realEstate',
  Other = 'otherAsset',
  PrivateInvestment = 'privateInvestment',
  FundInvestment = 'fundInvestment',
  CompoundFunds = 'compoundFunds',
  LP = 'lpExposure',
  GP = 'gpExposure',
  Loan = 'loan',
  Cash = 'cash',
  PrivateEquityAccount = 'privateEquityAccount',
  OtherLiability = 'otherLiability',
  PublicInvestment = 'publicInvestment',
  CreditCard = 'creditCard',
  Tax = 'taxProfile',
  PlaidAccount = 'plaidAccount',
  Grant = 'grant',
  Certificate = 'certificate',
  OtherFiles = 'otherFiles',
  Crypto = 'cryptocurrency',
  Estate = 'estatePlanning',
  CompoundInvestAccount = 'compoundInvestAccount',
  DefiAccount = 'defiAccount',
  Holding = 'holding',
  NFT = 'nft',
}

export enum AssetSubType {
  Common = 'common',
  EarlyExerciseRSA = 'early-exercise-rsa',
  EarlyExerciseUnvested = 'early-exercise-unvested',
  Convertible = 'convertible',
  UnvestedRSU = 'unvested-rsu',
  UnvestedOptions = 'unvested-options',
  VestedOptions = 'vested',
  Company = 'company',
}

export enum FilterType {
  Hide = 'hide',
  Exclude = 'exclude',
}

export interface AssetFilter {
  name: string;
  assetId: string;
  assetType: AssetType;
  assetSubType?: AssetSubType | null;
  filter?: FilterType;
}

export interface Filterable {
  name?: string | null;
  title?: string | null;
  id: string;
  key?: string;
  assetType: AssetType;
}
