import {
  Certificate,
  Grant,
  PrivateEquityAccount,
} from '@compoundfinance/compound-core/dist/types/equity';
import React from 'react';

type HoldingsType = Grant | Certificate;

export const isGrantOrCertificate = (
  asset: PrivateEquityAccount | HoldingsType,
): asset is HoldingsType => {
  return (
    (asset as Grant).awardType !== undefined ||
    (asset as Certificate).classType !== undefined
  );
};

export const isGrant = (
  asset: PrivateEquityAccount | HoldingsType,
): asset is Grant => {
  return (asset as Grant).awardType !== undefined;
};

export const isCertificate = (
  asset: PrivateEquityAccount | HoldingsType,
): asset is Certificate => {
  return (asset as Certificate).classType !== undefined;
};

export enum ViewCompanyEquityModalSteps {
  GENERAL = 0,
  RELATIONSHIP = 1,
  VALUATION = 2,
  HOLDINGS = 3,
}

export interface CompanyEquityModalStep {
  label: string;
  component: React.ReactNode;
  description: string | string[];
}
