import React from 'react';
import { LinePathProps } from '@visx/shape/lib/shapes/LinePath';
import { Group } from '@visx/group';
import { GridRows } from '@visx/grid';
import { LinePath } from '@visx/shape';
import { ParentSize } from '@visx/responsive';

import Loadable from 'components/Loadable';

export const Root = ({
  children,
  isLoading = false,
}: {
  children: ({ width, height }) => React.ReactNode;
  isLoading?: boolean;
}) => {
  return (
    <Loadable
      isLoading={isLoading}
      css={{
        h: '100%',
      }}
    >
      <ParentSize style={{ height: '100%', width: '100%', minWidth: 0 }}>
        {children}
      </ParentSize>
    </Loadable>
  );
};

export const MARGIN = { top: 10, right: 0, bottom: 25, left: 33 };

interface ContainerProps {
  width: number;
  height: number;
  yScale?: any;
  xScale?: any;
  xMax: number;
  yMax: number;
  margin?: typeof MARGIN;
  children: React.ReactNode;
}

const Container = ({
  width,
  height,
  yScale,
  xScale,
  xMax,
  yMax,
  margin: propMargin,
  children,
}: ContainerProps) => {
  const margin = propMargin || MARGIN;

  return (
    <svg width={width} height={height}>
      <Group width={xMax} height={yMax} top={margin.top} left={margin.left}>
        <GridRows
          left={margin.left}
          scale={yScale}
          width={xMax}
          strokeDasharray="1,4"
          stroke={'var(--colors-gray7)'}
          strokeOpacity={0.6}
          pointerEvents="none"
          shapeRendering="geometricPrecision"
        />
        {children}
      </Group>
    </svg>
  );
};

interface PathProps extends LinePathProps<any> {
  data?: any;
  color?: string;
  x?: any;
  y?: any;
  d?: any;
  dashed?: boolean;
}

const Path = ({ data, color, x, y, dashed, ...props }: PathProps) => {
  return (
    <LinePath
      data={data}
      x={x}
      y={y}
      strokeWidth={1}
      stroke={color}
      strokeDasharray={dashed ? '4 3' : undefined}
      {...props}
    />
  );
};

const Charting = { Root, Container, Path };
export default Charting;
