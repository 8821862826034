import groupBy from 'lodash/groupBy';
import partition from 'lodash/partition';

import { ValueOf } from 'types';

import { Allocation, AllocationTypes } from './Allocation';
import { TransitionTypes } from './Transition';
import { AllocationProposalStrategy } from './AllocationProposalStrategy';
import { keyBy } from 'lodash';

const EditableType = {
  ASSET: 'ASSET',
  ACCOUNT_WITH_ASSETS: 'ACCOUNT_WITH_ASSETS',
} as const;
type EditableType = ValueOf<typeof EditableType>;

export const TransitionType = {
  SURPLUS: 'SURPLUS',
  ASSET: 'ASSET',
} as const;
export type TransitionType = ValueOf<typeof TransitionType>;

export namespace EditableAllocationTypes {
  type BaseEditableAllocation = AllocationTypes.ClientAllocation & {
    editableType: EditableType;
    transition: EditableAllocationTransition;
  };
  export type EditableAccountAllocation = BaseEditableAllocation & {
    editableType: Extract<EditableType, 'ACCOUNT_WITH_ASSETS'>;
  };
  export type EditableAccountAllocationWithAssets =
    EditableAccountAllocation & {
      assets: EditableAssetAllocation[];
    };
  export type EditableAssetAllocation = BaseEditableAllocation & {
    editableType: Extract<EditableType, 'ASSET'>;
    transitions: EditableAllocationTransition[];
  };
  export type EditableAllocation =
    | EditableAccountAllocation
    | EditableAssetAllocation;

  export type EditableAllocationTransition = TransitionTypes.Transition & {
    transitionType: TransitionType;
  };
}

const isAccountWithAssetsAllocation = (
  editableAllocation: EditableAllocationTypes.EditableAllocation,
): editableAllocation is EditableAllocationTypes.EditableAccountAllocationWithAssets =>
  editableAllocation.editableType === EditableType.ACCOUNT_WITH_ASSETS;

const isAssetAllocation = (
  editableAllocation: EditableAllocationTypes.EditableAllocation,
): editableAllocation is EditableAllocationTypes.EditableAssetAllocation =>
  editableAllocation.editableType === EditableType.ASSET;

const hasInvestmentTarget = (
  transition: EditableAllocationTypes.EditableAllocationTransition,
) => !!transition.investmentTarget;

const toTransitions = (
  editableAllocations: EditableAllocationTypes.EditableAllocation[],
): EditableAllocationTypes.EditableAllocationTransition[] =>
  editableAllocations.flatMap((editableAllocation) => {
    if (isAssetAllocation(editableAllocation)) {
      const hasTransitions = editableAllocation.transitions.length > 0;
      return hasTransitions
        ? editableAllocation.transitions
        : editableAllocation.transition;
    }
    if (isAccountWithAssetsAllocation(editableAllocation))
      return editableAllocation.transition;
    throw new Error('Unreachable');
  });

const ASSETS_ID = 'ASSETS_ID';

const accountWithAssetsToEditableAllocation =
  (
    transitionsGroupedByClientAllocation: Record<
      string,
      TransitionTypes.Transition[]
    >,
  ) =>
  (clientAccountAllocation: AllocationTypes.ClientAllocation) => {
    const { assetId: accountId } = clientAccountAllocation;

    const [clientAccountAllocationTransition] =
      transitionsGroupedByClientAllocation[clientAccountAllocation.assetId] ??
      [];

    const hasClientAccountAllocationTransition =
      !!clientAccountAllocationTransition;

    return {
      ...clientAccountAllocation,
      id: `EDITABLE_ACCOUNT_WITH_ASSETS#${accountId}`,
      editableType: EditableType.ACCOUNT_WITH_ASSETS,
      transition: hasClientAccountAllocationTransition
        ? {
            ...clientAccountAllocationTransition,
            transitionType: TransitionType.SURPLUS,
          }
        : {
            ...clientAccountAllocation,
            id: `NEW_ACCOUNT_WITH_ASSETS_TRANSITION#${accountId}`,
            investmentTarget: null,
            transitionType: TransitionType.SURPLUS,
          },
    };
  };

const assetsToEditableAllocation =
  (
    transitionsGroupedByClientAllocation: Record<
      string,
      TransitionTypes.Transition[]
    >,
  ) =>
  (clientAssetAllocation: AllocationTypes.ClientAllocation) => {
    const { id: accountId } = clientAssetAllocation;

    const clientAssetAllocationTransitions =
      transitionsGroupedByClientAllocation[clientAssetAllocation.assetId] ?? [];
    const [clientAssetAllocationTransition] = clientAssetAllocationTransitions;

    const hasClientAssetAllocationTransitions =
      clientAssetAllocationTransitions.length > 0;
    const hasOneAssetAllocationTransitions =
      clientAssetAllocationTransitions.length === 1;

    return {
      ...clientAssetAllocation,
      id: `EDITABLE_ASSET#${accountId}`,
      editableType: EditableType.ASSET,
      transition: hasOneAssetAllocationTransitions
        ? {
            ...clientAssetAllocationTransition,
            transitionType: TransitionType.ASSET,
          }
        : {
            ...clientAssetAllocation,
            id: `NEW_ASSET_TRANSITION#${accountId}`,
            investmentTarget: null,
            transitionType: TransitionType.ASSET,
          },
      transitions: hasClientAssetAllocationTransitions
        ? clientAssetAllocationTransitions.map((transition) => ({
            ...transition,
            transitionType: TransitionType.ASSET,
          }))
        : [],
    };
  };
const fromClientAllocations = (
  clientAllocations: AllocationTypes.ClientAllocation[],
  transitions: TransitionTypes.Transition[],
): EditableAllocationTypes.EditableAllocation[] => {
  const transitionsGroupedByClientAllocation = groupBy(
    transitions,
    (transition) => transition.assetId,
  );
  const [accountsWithAssets, assets] = partition(
    clientAllocations,
    Allocation.isAccountWithAssets,
  );
  return [
    ...accountsWithAssets.map(
      accountWithAssetsToEditableAllocation(
        transitionsGroupedByClientAllocation,
      ),
    ),
    ...assets.map(
      assetsToEditableAllocation(transitionsGroupedByClientAllocation),
    ),
  ];
};

const groupAssetsWithinTheAccountTheyBelongTo = (
  editableAllocations: EditableAllocationTypes.EditableAllocation[],
) => {
  const [accountsWithAssets, assets] = partition(
    editableAllocations,
    (editableAllocation) => {
      if (EditableAllocation.isAccountWithAssetsAllocation(editableAllocation))
        return true;
      if (EditableAllocation.isAssetAllocation(editableAllocation))
        return false;
      throw new Error('Unreachable');
    },
  );
  const accountsWithAssetsById = keyBy(accountsWithAssets, 'assetId');
  const allAssetsGroupedByAccount = groupBy(assets, (clientAssetAllocation) => {
    const {
      asset: { originalAsset },
    } = clientAssetAllocation;
    if (!originalAsset) return ASSETS_ID;
    const hasMatchingAccount =
      !!accountsWithAssetsById[originalAsset.plaidAccountId!];
    return !hasMatchingAccount
      ? ASSETS_ID
      : originalAsset.plaidAccountId ?? ASSETS_ID;
  });
  const { ASSETS_ID: independentAssets = [], ...assetsGroupedByAccount } =
    allAssetsGroupedByAccount;

  return [
    ...accountsWithAssets.map((editableAccountAllocation) => {
      const { assetId: accountId } = editableAccountAllocation;
      const hasId = !!accountId;
      const assets = hasId ? assetsGroupedByAccount[accountId] ?? [] : [];
      return {
        ...editableAccountAllocation,
        assets: AllocationProposalStrategy.sortByWeight(
          AllocationProposalStrategy.toCategorized(assets),
        ),
      };
    }),
    ...independentAssets,
  ];
};

export const EditableAllocation = {
  fromClientAllocations,
  groupAssetsWithinTheAccountTheyBelongTo,
  hasInvestmentTarget,
  isAccountWithAssetsAllocation,
  isAssetAllocation,
  toTransitions,
};
