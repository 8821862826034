import React from 'react';
import { Owner } from '@compoundfinance/compound-core/dist/types/account';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';
import UiUtils from 'utils/ui';
import * as Avatar from '@radix-ui/react-avatar';

// Can't import from design-system here as tests will fail for some weird reason.
const Box = styled('div');
const AvatarImage = styled(Avatar.Image);
const AvatarFallback = styled(Avatar.Fallback);

interface AssetLogoProps {
  inactive: boolean;
  bgColor?: string;
  borderRadius?: string;
  size?: number;
  fontSize?: number;
  text: string;
  className?: string;
  style?: React.CSSProperties;
  owner?: Owner;
  textStyle?: React.CSSProperties;
  useExactText?: boolean;
  ariaHidden?: boolean;
}

const firstAlphanumericRegexp = /[\w\d]/;

// Fallback for deleted owners
const FALLBACK_COLOR = 'pink';

type DefaultLogoProps = Omit<AssetLogoProps, 'inactive'> & {
  inactive?: boolean;
};
function AssetLogoDefault(props: DefaultLogoProps) {
  const {
    text,
    inactive = false,
    bgColor,
    borderRadius,
    size = 26,
    fontSize,
    style,
    owner,
    textStyle,
    useExactText,
  } = props;

  const fontSizeToUse = fontSize ? `${fontSize}px` : owner ? `12px` : `12px`;
  let logoText = text ?? '-';

  if (!useExactText) {
    if (!owner) {
      logoText = text.match(firstAlphanumericRegexp)?.[0] ?? '-';
    } else {
      logoText = text
        .split(' ')
        .map((name) => name[0])
        .splice(0, 2)
        .join('');
    }
  }

  return (
    <Box
      as="span"
      css={{
        userSelect: 'none',
        display: 'flex',
        ai: 'center',
        jc: 'center',
        bg: owner
          ? `$${owner.color ?? FALLBACK_COLOR}${owner.color ? 5 : 3}`
          : `$${bgColor}4` || '#0F6CCA',
        br: borderRadius || '$round',
        clipPath: borderRadius ? 'initial' : 'circle()',
        filter: inactive ? 'grayscale(100%) opacity(0.5)' : 'none',
        size,
        lineHeight: `${size}px`,
        textAlign: 'center',
        flexShrink: 0,
        ...style,

        '& span': {
          textShadow: inactive ? '' : '0px 0px 2px rgba(0, 0, 0, 0.1)',
        },
      }}
      aria-hidden={props.ariaHidden}
      className={props.className}
    >
      <Box
        css={{
          fontWeight: '$medium',
          fontSize: fontSizeToUse,
          lineHeight: 'unset',
          ...textStyle,
          // Show deleted owners as muted by turning down the colors
          color: owner
            ? `$${owner.color ?? FALLBACK_COLOR}${owner.color ? 9 : 7}`
            : `$${bgColor}11`,
        }}
      >
        {logoText.toUpperCase()}
      </Box>
    </Box>
  );
}

interface ImageProps {
  alt?: string;
  text?: string;
  src: string;
  filtered: boolean;
  size?: number;
  borderRadius?: string;
  className?: string;
  style: React.CSSProperties;
  ariaHidden?: boolean;
}

function Image(props: ImageProps) {
  const {
    alt = '',
    src,
    text,
    size = 26,
    borderRadius = '50%',
    filtered,
    style,
    className = '',
  } = props;

  return (
    <Avatar.Root>
      <AvatarImage
        css={{
          background: 'white',
          borderRadius,
          clipPath: borderRadius === '50%' ? 'circle()' : '',
          flex: `0 0 ${size}px`,
          filter: filtered ? 'grayscale(100%) opacity(0.2)' : 'none',
          objectFit: 'contain',
          objectPosition: 'center',
          ...style,
        }}
        alt={alt}
        src={src}
        className={className}
        width={size}
        height={size}
        aria-hidden={props.ariaHidden}
      />
      <AvatarFallback asChild>
        <AssetLogoDefault
          text={text || ''}
          bgColor={UiUtils.getImageVisColor(text ?? '')}
        />
      </AvatarFallback>
    </Avatar.Root>
  );
}

const MemoImage = React.memo(Image);

/**
 * Note that although all the props are nominally optional, in reality at least some data is needed for this component to work as intended.
 * @param name Account name. The first letter is used in the fallback logo in case `logo` prop is not defined. Optional.
 * @param filteredOut Determines if logo should be grayscaled to indicate it's inactive. Optional.
 * @param logo Src path for the logo image. If this is not used, at least the `name` prop should be defined in order to render the fallback logo correctly.
 * @param color Determines background color for fallback logo. Should be defined if `logo` prop isn't.
 * @param size Sets logo size, defaults to a value in child components. Optional.
 * @param className Css classes to provide to underlying image component (optional)
 * @param borderRadius Border radius override (optional)
 */
export interface AccountLogoProps {
  alt?: string;
  borderRadius?: string;
  className?: string;
  color?: string;
  filteredOut?: boolean;
  logo?: string;
  name?: string;
  owner?: Owner;
  size?: number;
  style?: React.CSSProperties;
  fontSize?: number;
  useExactText?: boolean;
  ariaHidden?: boolean;
}

function AccountLogo(props: AccountLogoProps) {
  const {
    alt,
    name,
    filteredOut = false,
    fontSize,
    logo,
    color,
    size,
    owner,
    useExactText,
    borderRadius,
    style = {},
    className = '',
  } = props;

  if (logo) {
    return (
      <MemoImage
        alt={alt}
        src={logo}
        filtered={filteredOut}
        size={size}
        borderRadius={borderRadius}
        className={className}
        style={style}
        ariaHidden={props.ariaHidden}
        text={name}
      />
    );
  }

  return (
    <AssetLogoDefault
      text={name || ''}
      inactive={filteredOut}
      bgColor={color ?? UiUtils.getImageVisColor(name ?? '')}
      owner={owner}
      useExactText={useExactText}
      borderRadius={borderRadius}
      size={size}
      className={className}
      fontSize={fontSize}
      style={style}
      ariaHidden={props.ariaHidden ?? true}
    />
  );
}

export { AssetLogoDefault };

export default React.memo(AccountLogo);
