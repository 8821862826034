import { Box, Flex } from '@compoundfinance/design-system';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';

const Step = styled(Box, {
  h: '$4',
  bg: '$gray6',
  br: '$8',

  // data active
  '&[data-active="true"]': {
    bg: '$gray12',
  },
});

interface DocumentProgressInticatorProps {
  currentStep: number;
  setCurrentStep: (step: number) => void;
  totalSteps: number;
}

function DocumentProgressIndicator(props: DocumentProgressInticatorProps) {
  const { currentStep, setCurrentStep, totalSteps } = props;

  return (
    <Flex css={{ jc: 'space-between', gap: '$6', px: '$16' }}>
      {Array.from({ length: totalSteps }).map((_, index) => (
        <Box
          key={index}
          css={{ flexGrow: 1, pt: '$8', pb: '$12', cursor: 'pointer' }}
          onClick={() => {
            setCurrentStep(index);
          }}
        >
          <Step data-active={index === currentStep} />
        </Box>
      ))}
    </Flex>
  );
}

export default DocumentProgressIndicator;
