import EditableTextArea from 'containers/Dashboard/Document/components/EditableTextArea';
import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import { EditableSectionTitle } from 'containers/Dashboard/Document/components/SectionTitle';
import { Col } from '@compoundfinance/design-system';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';

const Container = styled(ModuleContainer, {
  pt: '$44',
  pb: 0,
  px: '$48',
  gap: '$24',

  '@bp1': {
    pt: '$44',
    px: '$48',
    gap: '$48',
  },
});

export interface MarketOverviewProps {
  title: string;
}

function MarketOverview({ title }: MarketOverviewProps) {
  return (
    <Container>
      <Col css={{ h: '100%', gap: '$24' }}>
        <Col>
          <EditableSectionTitle title={title} />
        </Col>

        <Col
          css={{
            h: '100%',
            w: '100%',
          }}
        >
          <EditableTextArea
            propertyKey="description"
            value="Lorem ipsum dolor sit amet consectetur. Aliquam est augue ullamcorper vulputate. Cras nisl id tellus at montes. Aliquet aliquam in id feugiat ullamcorper morbi. A at mi sed massa pharetra neque quam turpis. In tincidunt dui ultricies eget tortor convallis. Gravida imperdiet posuere suspendisse pretium scelerisque ipsum massa. Sagittis facilisis morbi placerat at. Eu ut libero vivamus eu eget. Id libero quis tempor id urna eleifend aliquet gravida."
            textCss={{ fontSize: '$15', w: '100%' }}
          />
        </Col>
      </Col>
    </Container>
  );
}

export default MarketOverview;
