import * as _ from 'lodash';
import {
  AssetAccountSection,
  AccountSectionItem,
  AssetAccountSectionProps,
} from 'types/assets/section';
import { AssetTypes } from '@compoundfinance/compound-core/dist/types/account';
import AssetUtils from 'utils/assets';

const sortAccounts = (accounts: AccountSectionItem[]) =>
  _.orderBy(accounts, ['currentBalance'], ['desc']);

class AccountSection implements AssetAccountSection {
  accounts: AccountSectionItem[];
  displayOrder: number;
  filtered: string[];
  readonly filterKey: string;
  title: string;
  type: AssetTypes;

  constructor({
    accounts,
    displayOrder,
    filtered,
    filterKey = 'id',
    title,
    type,
  }: AssetAccountSectionProps) {
    this.accounts = sortAccounts([...accounts]);
    this.displayOrder = displayOrder;
    this.filtered = filtered || ([] as string[]);
    this.filterKey = filterKey;
    this.title = title;
    this.type = type;
  }

  accountIds() {
    return this.accounts.map((a) => a[this.filterKey] as string);
  }

  allFiltered() {
    return this.filtered.length === this.accounts.length;
  }

  noneFiltered() {
    return this.filtered.length === 0;
  }

  someFiltered() {
    return !!_.difference(this.filtered, this.accountIds()).length;
  }

  setFiltered(filters: string[] = []) {
    this.filtered = filters;
  }

  isFiltered(accountId: string) {
    return this.filtered.includes(accountId);
  }

  setAccounts(accounts = [] as AccountSectionItem[]) {
    this.accounts = accounts;
  }

  updateAccounts(accounts = [] as AccountSectionItem[]) {
    this.accounts = sortAccounts([...this.accounts, ...accounts]);
  }

  private getAssetSum(accounts: AccountSectionItem[]) {
    return _.sumBy(accounts, (acct) => {
      // We only want to apply the modifier when the account is a liability
      const isLiability = AssetUtils.isLiability(this.type);
      const modifier = isLiability ? -1 : 1;
      return modifier * acct.currentBalance;
    });
  }

  get allAccounts() {
    return this.accounts;
  }

  get filteredAccounts() {
    return this.accounts.filter(
      (account) => !this.isFiltered(account[this.filterKey] as string),
    );
  }

  get total() {
    return this.getAssetSum(this.accounts);
  }

  get filteredTotal() {
    return this.getAssetSum(this.filteredAccounts);
  }

  get rawTotal() {
    return _.sumBy(this.accounts, 'currentBalance');
  }

  get rawFilteredTotal() {
    return _.sumBy(this.filteredAccounts, 'currentBalance');
  }
}

export function buildAssetAccountSection(props: AssetAccountSectionProps) {
  return new AccountSection(props);
}
