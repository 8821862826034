import { RechartsTooltipProps } from 'types/chart';
import { FlexCol, FlexRow } from 'components/global';
import Typography from 'components/global/Typography';
import { TooltipContainer, TooltipBox } from 'components/Charts/Tooltip';
import UiUtils from 'utils/ui';

interface MekkoTooltipProps extends RechartsTooltipProps {
  activeBar: string;
  activeCategory: string;
  dollarValue: number;
  percentValue: number;
  xOffset: number;
}

function MekkoTooltip(props: MekkoTooltipProps) {
  if (!props.activeBar) {
    return null;
  }

  const nonZeroOffset = UiUtils.getFirstNonZeroDigit(
    String(props.percentValue),
  );

  const displayPercent = UiUtils.formatDecimals(
    props.percentValue,
    1,
    Math.max(nonZeroOffset, 1),
  );

  return (
    <TooltipContainer
      x={props.coordinate.x + props.xOffset}
      y={props.coordinate.y}
      style={{ width: 148 }}
    >
      <TooltipBox className="p-0">
        <FlexCol
          style={{ background: 'var(--colors-gray2)' }}
          className="px-sm05 pt-xs pb-xs mb-0"
          justifyContent="center"
        >
          <Typography
            style={{ color: 'var(--colors-gray12)' }}
            fontSize="0.75rem"
            fontWeight={525}
            className="mb-0"
          >
            {props.activeCategory}
          </Typography>
        </FlexCol>
        <FlexCol
          className="px-sm05 pt-xs pb-xs mb-0"
          alignItems="center"
          w="100%"
          justifyContent="space-between"
          color="white"
        >
          <Typography className="mr-xs mb-xs" color="darker_grey" as="p">
            {props.activeBar}
          </Typography>
          <FlexRow w="100%" justifyContent="space-between">
            <Typography color="black">
              ${UiUtils.formatDecimals(props.dollarValue)}
            </Typography>
            <Typography color="black" textAlign="right">
              {displayPercent}%
            </Typography>
          </FlexRow>
        </FlexCol>
      </TooltipBox>
    </TooltipContainer>
  );
}

export default MekkoTooltip;
