import styled from 'styled-components';
import { MainTheme, CompoundColor } from 'components/types';

export type TextAlign = 'left' | 'center' | 'right' | 'inherit';

export interface TypographyProps {
  // Force using fontWeight 450 instead of 400 and 525 instead of 500. Feel free to add other values as necessary, though.
  // If you really need to use 400 or 500, perhaps you could set them via `style` prop.
  fontWeight?: 300 | 450 | 525 | 600 | 700 | 800;
  fontSize?: string;
  fontFamily?: keyof MainTheme['fonts'];
  htmlFor?: string;
  label?: string;
  h?: string;
  w?: string;
  color?: CompoundColor;
  textAlign?: TextAlign;
  lineHeight?: number | string;
  className?: string;
  onClick?: any;
  onMouseEnter?: any;
  onMouseLeave?: any;
  hoverBg?: CompoundColor;
  hoverColor?: CompoundColor;
  decoration?: string;
  bgColor?: CompoundColor;
  flex?: string | number;
  verticalAlign?: string;
  style?: React.CSSProperties;
  title?: string;
  whiteSpace?: 'normal' | 'pre-wrap' | 'nowrap' | 'pre-line';
}
const LETTER_SPACING_MAP = {
  '2rem': '-0.02em',
  '1.5rem': '-0.018em',
  '1.375rem': '-0.016em',
  '1.25rem': '-0.013em',
  '20px': '-0.013em',
  '1.125rem': '-0.012em',
  '1rem': '-0.008em',
  '15px': '-0.007em',
  '0.875rem': '-0.006em',
  '13px': '-0.0025em',
};

export default styled.span<TypographyProps>`
  background-color: ${({ bgColor, theme }) =>
    bgColor ? theme.colors[bgColor] : 'initial'};
  font-weight: ${({ fontWeight, theme }) =>
    fontWeight ? `${fontWeight} !important ` : theme.fw.regular};
  font-size: ${({ fontSize }) => fontSize || '0.875rem'};
  font-family: ${({ fontFamily, theme }) =>
    fontFamily ? theme.fonts[fontFamily] : theme.fonts.sansSerif};
  color: ${({ color, theme }) =>
    color ? theme.colors[color] : theme.colors.black};
  text-align: ${({ textAlign }) => textAlign || 'initial'};
  height: ${({ h }) => h || 'auto'};
  line-height: ${({ lineHeight }) => lineHeight || '1.5'};
  width: ${({ w }) => w || 'inherit'};
  text-decoration: ${({ decoration }) => decoration || 'none'};
  ${({ flex }) => flex && `flex: ${flex}`};
  white-space: ${({ whiteSpace }) => whiteSpace || 'normal'};
  letter-spacing: ${({ fontSize }) =>
    LETTER_SPACING_MAP[fontSize as string] || '0em'};
  ${({ verticalAlign }) => verticalAlign && `vertical-align: ${verticalAlign}`};
  &:hover {
    background-color: ${({ hoverBg, bgColor, theme }) =>
      hoverBg
        ? theme.colors[hoverBg]
        : bgColor
        ? theme.colors[bgColor]
        : 'initial'};
    color: ${({ hoverColor, color, theme }) =>
      hoverColor
        ? theme.colors[hoverColor]
        : color
        ? theme.colors[color]
        : theme.colors.black};
  }
`;
