import {
  Box,
  Button,
  CheckIcon,
  DropdownMenu,
  Row,
  SettingsIcon,
  Text,
} from '@compoundfinance/design-system';

interface Props {
  selectedBenchmarks: { [key: string]: boolean };
  setSelectedBenchmarks: (key: string, value: boolean) => void;
}

function Benchmarks(props: Props) {
  const { selectedBenchmarks, setSelectedBenchmarks } = props;
  return (
    <DropdownMenu>
      <DropdownMenu.Trigger>
        <Button size="small" variant="internal" css={{ ml: 'auto' }}>
          <Row css={{ ai: 'center', gap: '$6' }}>
            <SettingsIcon size={16} />
            Benchmarks
          </Row>
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content css={{ w: 50 }}>
        <Text size="12" color="gray10">
          Overlay Indices
        </Text>
        {Object.entries(selectedBenchmarks).map(([benchmark, selected]) => (
          <DropdownMenu.Item
            prefix={selected ? <CheckIcon /> : <Box />}
            onSelect={() => setSelectedBenchmarks(benchmark, !selected)}
          >
            {benchmark}
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu>
  );
}

export default Benchmarks;
