import { Box, Col, Text } from '@compoundfinance/design-system';
import { useAtom } from 'jotai';
import { tableOfContentsModuleVisibilityAtom } from '.';
import { CompoundDocument } from '../..';

interface TableOfContentsProps {
  document: CompoundDocument;
}

function TableOfContents(props: TableOfContentsProps) {
  const { document } = props;

  const [tableOfContentsCurrentModuleId] = useAtom(
    tableOfContentsModuleVisibilityAtom,
  );

  const highlightedModule = document.moduleOrder?.find(
    (moduleId) => tableOfContentsCurrentModuleId[moduleId],
  );

  return (
    <Box data-table-of-contents>
      <Box
        css={{
          position: 'sticky',
          top: '$20',
          pr: '$24',
        }}
      >
        <Col css={{ gap: '$12' }}>
          {document.moduleOrder?.map((moduleId) => {
            const module = document?.modules?.[moduleId];

            if (!module) return null;

            return (
              <Box key={module.id}>
                <Text
                  weight="medium"
                  size="15"
                  color={highlightedModule === module.id ? 'gray12' : 'gray8'}
                  as="a"
                  href={`#${module.id}`}
                >
                  {module.properties.title || module.moduleName}
                </Text>
              </Box>
            );
          })}
        </Col>
      </Box>
    </Box>
  );
}

export default TableOfContents;
