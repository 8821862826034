import EditablePerson from 'containers/Dashboard/Document/components/EditablePerson';
import EditableTextArea from 'containers/Dashboard/Document/components/EditableTextArea';
import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import { getPerson } from 'containers/Dashboard/Document/utils';
import { Text, Col, Box } from '@compoundfinance/design-system';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';
import EquityStrategiesBackground from './equity-strategies-background.png';
import { DirectoryPerson } from 'domain/Employees/constants';

const Container = styled(ModuleContainer, {
  display: 'grid',
  pt: '$24',
  pb: 0,
  px: '$24',
  gap: '$24',

  '@bp1': {
    pt: '$44',
    px: '$48',
    gridTemplateColumns: '1fr 1fr',
    gap: '$48',
  },
});

export interface EquityStrategiesProps {
  financialAdvisor: DirectoryPerson;
}

function EquityStrategies(props: EquityStrategiesProps) {
  const { financialAdvisor } = props;

  const financialAdvisorPerson = getPerson(financialAdvisor);

  return (
    <Container>
      <Col css={{ h: '100%', gap: '$24' }}>
        <Col css={{ gap: '$12' }}>
          <Text
            weight="medium"
            color="gray8"
            css={{
              fontSize: '$20',
              letterSpacing: '-0.8px',

              '@bp1': { fontSize: '$48', lineHeight: '58px' },
            }}
          >
            Equity strategies
          </Text>
        </Col>

        <Col
          css={{
            h: '100%',
            gap: '$24',
            justifyContent: 'space-between',
            mb: '$24',

            '@bp1': {
              mb: '$44',
            },
          }}
        >
          <Text size="14" css={{ w: '100%' }}>
            <EditableTextArea
              propertyKey="description"
              value={`We’ll work together to craft and execute a plan for your equity. This will include modeling it under different valuation conditions and taking into account tax implications.\n\nAs part of that we'll also work together on both a forward-looking cash flow plan and an asset allocation plan. These plans will help with strategizing around equity decisions, like potential exercises or what to do with any potential liquidity and diversifying assets.`}
            />
          </Text>
          <EditablePerson
            propertyKey="financialAdvisor"
            propertyValue={financialAdvisor}
            person={financialAdvisorPerson}
            size="small"
          />
        </Col>
      </Col>

      <Box
        as="img"
        src={EquityStrategiesBackground}
        alt="Strategies cards"
        css={{ alignSelf: 'flex-end', w: '100%' }}
      />
    </Container>
  );
}

export default EquityStrategies;
