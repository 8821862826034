import { Text, Col } from '@compoundfinance/design-system';
import useDragAndDrop, {
  DropEvent,
} from 'components/FileManager/useDragAndDrop';
import FileManagerUtils from 'components/FileManager/utils';
import { CompoundColor } from 'components/types';
import useFileManagerContext from 'components/FileManager/context';
import { DEFAULT_DOCUMENTS_HEIGHT } from 'components/EquityManagement/Documents/constants';
import { SvgProps } from 'assets/svg/types';

const EmptyDocument = (props: SvgProps) => (
  <svg
    width={props.size || 68}
    height={props.size || 85}
    viewBox="0 0 68 85"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M60.1256 18.5848L44.9023 1.66241C44.523 1.24078 43.9826 1 43.4154 1H3.35867C2.2541 1 1.35867 1.89543 1.35867 3V77C1.35867 78.1046 2.2541 79 3.35867 79H58.6387C59.7432 79 60.6387 78.1046 60.6387 77V19.9224C60.6387 19.4285 60.4559 18.952 60.1256 18.5848Z"
      fill="#F2F2F2"
      stroke="#E4E4E4"
      strokeWidth="1.8"
    />
    <rect
      x="45"
      y="62"
      width="23"
      height="23"
      rx="11.5"
      fill="var(--colors-gray12)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.9528 74.5954V80.0716H57.5956V74.5954H63.0718V72.9525H57.5956V67.4763H55.9528V72.9525H50.4766V74.5954H55.9528Z"
      fill="white"
    />
  </svg>
);

interface NoDocumentsProps {
  onClick?: () => void;
  onDrop: (event: DropEvent) => void;
  bg?: CompoundColor;
  className?: string;
  h?: string;
  disableDrop?: boolean;
  fileIcon?: JSX.Element;
  text?: JSX.Element;
}

export function NoDocuments(props: NoDocumentsProps) {
  const { h = DEFAULT_DOCUMENTS_HEIGHT } = props;
  const { fileManagerState } = useFileManagerContext();
  const inactive = FileManagerUtils.isPending(fileManagerState);

  const className = props.className || 'hover br';

  const { dragging, ...dragAndDrop } = useDragAndDrop({
    inactive,
    onDrop: props.onDrop,
  });

  const dragAndDropProps = props.disableDrop ? {} : dragAndDrop;

  return (
    <Col
      css={{
        jc: 'center',
        ai: 'center',
        width: '100%',
        height: h,
        bg: '$gray0',
        cursor: 'pointer',
        border: dragging ? '1px solid $gray11' : '$borderStyles$default',
      }}
      className={className}
      onClick={props.onClick}
      {...dragAndDropProps}
    >
      <Col css={{ ai: 'center' }}>
        {props.fileIcon || <EmptyDocument size={60} />}
        {props.text || (
          <Text weight="medium" size="13" css={{ mt: '$8' }}>
            Click or drag a file to upload
          </Text>
        )}
      </Col>
    </Col>
  );
}
