import useIsDocumentEditor from 'containers/Dashboard/Advisor/DocumentsAdmin/hooks/useIsDocumentEditor';
import { useMutableModuleProperty } from 'containers/Dashboard/Advisor/DocumentsAdmin/hooks/useMutableProperty';
import EditablePerson from 'containers/Dashboard/Document/components/EditablePerson';
import EditableTextArea from 'containers/Dashboard/Document/components/EditableTextArea';
import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import { getPerson } from 'containers/Dashboard/Document/utils';
import {
  Tag,
  TagType,
  classMapping,
  fundTypeMapping,
  goalMapping,
} from 'containers/Dashboard/Services/Products/PrivateMarkets/DealCard';
import { PrivateMarketDeal } from 'containers/Dashboard/Services/Products/PrivateMarkets/types';
import {
  Row,
  Col,
  Text,
  Box,
  Button,
  SettingsIcon,
  Badge,
  InformationIcon,
  Grid,
  Tooltip,
  Popover,
  Checkbox,
} from '@compoundfinance/design-system';
import { DirectoryPerson } from 'domain/Employees/constants';
import { useCompoundFetch } from 'hooks/useCompoundFetch';
import { noop } from 'lodash';
import moment from 'moment';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';
import { StringUtils } from 'utils/strings';
import UiUtils from 'utils/ui';

const Container = styled(ModuleContainer, {
  p: '$24',

  '@bp1': {
    py: '$44',
    px: '$48',
  },
});

const DataRow = ({
  title,
  dataPoint,
  tooltip,
}: {
  title: string;
  dataPoint: string;
  tooltip?: string;
}) => {
  return (
    <Row
      css={{
        jc: 'space-between',
        ai: 'center',
        lineHeight: '1',
      }}
    >
      <Box>
        <Text
          size="13"
          color="gray11"
          css={{
            mr: '$6',
          }}
        >
          {title}
        </Text>
        {tooltip && (
          <Tooltip>
            <Tooltip.Trigger>
              <InformationIcon color="gray" size={13} />
            </Tooltip.Trigger>

            <Tooltip.Content>
              {
                <Box
                  dangerouslySetInnerHTML={{
                    __html: tooltip,
                  }}
                />
              }
            </Tooltip.Content>
          </Tooltip>
        )}
      </Box>
      <Text size="13" color="gray12">
        {dataPoint}
      </Text>
    </Row>
  );
};

const Image = styled('img', {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '$6',
});

const DealCard = ({ deal }: { deal: PrivateMarketDeal }) => {
  return (
    <Box
      css={{
        mt: '$48',
      }}
    >
      <Text size="13" color="gray12">
        {deal.fundName ?? deal.name}
      </Text>
      <Box
        css={{
          h: '1px',
          bg: '$gray4',
          w: '100%',
          mt: '$8',
          mb: '$12',
        }}
      />
      <Grid
        css={{
          gap: '$24',
          gridTemplateColumns: 'repeat(2, 1fr)',
          '@bp1': {
            gridTemplateColumns: 'repeat(3, 1fr)',
          },
        }}
      >
        <Image
          src={deal.bannerImageUrl}
          alt=""
          css={{
            gridRow: '1 / span 2',
            '@bp1': {
              gridRow: '1 / span 1',
            },
          }}
        />
        <Box
          css={{
            gridColumn: '2 / 2',
            gridRow: '1',
            '@bp1': {
              gridColumn: '2 / span 1',
              gridRow: '1 / 1',
            },
          }}
        >
          <Row
            css={{
              gap: '$4',
              mb: '$12',
              flexWrap: 'wrap',
            }}
          >
            {deal.class && (
              <Tag text={classMapping[deal.class]} type={TagType.Class} />
            )}
            {deal.goals.map((goal) => (
              <Tag text={goalMapping[goal]} type={TagType.Goal} key={goal} />
            ))}
          </Row>
          <Text
            color="gray11"
            size="13"
            dangerouslySetInnerHTML={{ __html: deal.description }}
          />
        </Box>
        <Col
          css={{
            w: '100%',
            gap: '$16',
            gridColumn: '2 / 2',
            gridRow: '2',
            '@bp1': {
              gridColumn: '3 / span 1',
              gridRow: '1',
            },
          }}
        >
          <DataRow
            title="Targeted return"
            dataPoint={`${deal.targetReturnLowerBound}% - ${deal.targetReturnUpperBound}%`}
            tooltip={deal.targetedReturnTooltip}
          />
          <DataRow
            title="Lockup Period"
            dataPoint={deal.lockUpPeriod}
            tooltip={deal.expectedLockupPeriodTooltip}
          />
          <DataRow
            title="Min. Investment"
            dataPoint={UiUtils.formatDecimals(
              deal.minimumInvestment,
              0,
              0,
              true,
            )}
            tooltip={deal.minimumInvestmentTooltip}
          />
          <DataRow
            title="Liquidity"
            dataPoint={deal.liquidity}
            tooltip={deal.liquidityTooltip}
          />
          <DataRow
            title="Fund type"
            dataPoint={fundTypeMapping[deal.fundType]}
          />
        </Col>
      </Grid>
    </Box>
  );
};

export interface PrivateMarketDealsProps {
  financialAdvisor: DirectoryPerson;
}

const PrivateMarketDeals = (props) => {
  const {
    financialAdvisor,
    deals: selectedDeals,
    generatedAt,
    propertyKey = 'deals',
    propertyValue = [],
  } = props;

  const isDocumentEditor = useIsDocumentEditor();
  const [, setPropertyValue] = useMutableModuleProperty(
    propertyKey,
    propertyValue,
  );

  const handleClick = (deal: PrivateMarketDeal) => {
    if (selectedDeals.find((d) => d.id === deal.id)) {
      setPropertyValue(selectedDeals.filter((d) => d.id !== deal.id));
      return;
    } else {
      setPropertyValue([...selectedDeals, deal]);
      return;
    }
  };

  const { data: { data: dealsList } = { data: [] }, isLoading } =
    useCompoundFetch<{ data: PrivateMarketDeal[] }>(
      '/api/privateMarket/deal/all',
    );

  const deals = dealsList.map((deal) => ({
    ...deal,
    slug: StringUtils.slugify(deal.name),
  }));

  const financialAdvisorPerson = getPerson(financialAdvisor);

  return (
    <Container>
      <Col
        css={{
          gap: '$24',
          ai: 'start',
        }}
      >
        <Grid
          css={{
            width: '100%',
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          <Text
            weight="medium"
            color="gray8"
            css={{
              fontSize: '$20',
              letterSpacing: '-0.8px',
              flexShrink: 0,

              '@bp1': { fontSize: '$48', lineHeight: '58px' },
            }}
          >
            Open alts
          </Text>
          <Badge size="medium" css={{ color: '$gray11', br: '$6', ml: 'auto' }}>
            Data imported {moment(generatedAt).fromNow()} at{' '}
            {moment(generatedAt).format('H:mmA z MMMM D, YYYY')}
          </Badge>
        </Grid>
        <Col
          css={{
            gap: '$16',
            width: '50%',
          }}
        >
          <Text
            size="20"
            color="gray10"
            css={{
              width: '100%',
              lineHeight: '1',
            }}
          >
            <EditableTextArea
              propertyKey="description"
              value={'This is a description'}
            />
          </Text>
          <EditablePerson
            propertyKey="financialAdvisor"
            propertyValue={financialAdvisor}
            person={financialAdvisorPerson}
            size="small"
          />
        </Col>
      </Col>
      {(isDocumentEditor || !isLoading) && (
        <Popover>
          <Popover.Trigger asChild>
            <Button
              variant="internal"
              size="small"
              prefix={<SettingsIcon />}
              css={{
                gap: '$4',
                mt: '$48',
              }}
            >
              Add a deal
            </Button>
          </Popover.Trigger>
          <Popover.Content
            align="start"
            css={{
              zIndex: 100000,
              bg: 'white',
              color: '$gray10',
              border: '1px solid $gray4',
              display: 'flex',
              flexDirection: 'column',
            }}
            hideArrow
          >
            {deals.map((deal) => {
              return (
                <Row
                  key={deal.id}
                  as={'button'}
                  css={{
                    ai: 'center',
                    gap: '$8',
                    py: '$4',
                    borderRadius: '$4',
                    '&:hover': {
                      bg: '$gray3',
                    },
                  }}
                  onClick={() => handleClick(deal)}
                >
                  <Checkbox
                    onChange={noop}
                    checked={selectedDeals.some(
                      (selectedDeal: PrivateMarketDeal) =>
                        selectedDeal.id === deal.id,
                    )}
                  />
                  <Text>{deal.name}</Text>
                </Row>
              );
            })}
          </Popover.Content>
        </Popover>
      )}
      {selectedDeals.length ? (
        selectedDeals.map((deal: PrivateMarketDeal) => (
          <DealCard key={deal.id} deal={deal} />
        ))
      ) : (
        <Text
          css={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            mt: '$48',
          }}
          color="gray10"
          size="13"
        >
          There's currently no alternative
          <br /> investment opportunities available.
        </Text>
      )}
    </Container>
  );
};

export default PrivateMarketDeals;
