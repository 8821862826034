import D from 'decimal.js-light';

const NET_WORTH_MULTIPLIERS = {
  K: 1_000,
  M: 1_000_000,
  B: 1_000_000_000,
  T: 1_000_000_000_000,
  P: 1_000_000_000_000_000,
  E: 1_000_000_000_000_000_000,
} as const;
const MULTIPLIERS = {
  '': 1,
  ...NET_WORTH_MULTIPLIERS,
} as const;
type MultiplierSymbols = keyof typeof MULTIPLIERS;
type Multipliers = { [key in MultiplierSymbols]?: number };

const getMultiplier = (multipliers: Multipliers) => (value: number) =>
  Object.entries(multipliers).reduce(
    (potentialMultiplier, [symbol, multiplier]) => {
      const division = value / multiplier;
      if (isExponential(division)) return potentialMultiplier;
      const [integer] = String(division).split('.');
      if (!(Number(integer) > 0)) return potentialMultiplier;
      return [symbol, multiplier];
    },
  );

const withMultiplier =
  (multipliers: Multipliers) =>
  (number: number = 0) => {
    if (number === 0) return '$0';
    const absoluteNumber = Math.abs(number);
    const [symbol, multiplier] = getMultiplier(multipliers)(absoluteNumber);
    return `${number >= 0 ? '' : '-'}$${(absoluteNumber / multiplier)
      .toFixed(2)
      .padEnd(4, '0')}${symbol}`;
  };

const isExponential = (number: number) => String(number).includes('e');

const Formatters = {
  withMultiplier: withMultiplier(MULTIPLIERS),
  networth: withMultiplier(NET_WORTH_MULTIPLIERS),
};

const Decimal = D.clone({ rounding: D.ROUND_HALF_EVEN, toExpNeg: -21 });

const add = (a: number, b: number): number =>
  new Decimal(a).plus(new Decimal(b)).toNumber();

const subtract = (a: number, b: number): number =>
  new Decimal(a).minus(new Decimal(b)).toNumber();

const multiply = (a: number, b: number): number =>
  new Decimal(a).times(new Decimal(b)).toNumber();

const divide = (a: number, b: number): number =>
  new Decimal(a).div(new Decimal(b)).toNumber();

const percentage = (a: number, b: number): number =>
  multiply(divide(a, b), 100);

const percentageFromValue = (percentage: number, value: number): number =>
  divide(multiply(percentage, value), 100);

const round = (value: number, decimalPlaces = 2): number =>
  new Decimal(value).toDecimalPlaces(decimalPlaces).toNumber();

type SanitizeMode = 'Display' | 'Precision';
const sanitize = (value: number, mode: SanitizeMode = 'Display'): string => {
  if (value === 0) return String(value);
  if (mode === 'Display') {
    if (value > 1) return String(round(value));
    return new Decimal(value)
      .toSignificantDigits(
        Math.min(new Decimal(value).precision(false), 2),
        D.ROUND_DOWN,
      )
      .toString();
  }
  if (mode === 'Precision') {
    return new Decimal(value)
      .toSignificantDigits(
        Math.min(new Decimal(value).precision(false), 6),
        D.ROUND_DOWN,
      )
      .toString();
  }
  throw new Error('Unreachable');
};

export const NumberUtils = {
  Formatters,
  add,
  divide,
  multiply,
  percentageFromValue,
  percentage,
  round,
  sanitize,
  sum: add,
  subtract,
};
