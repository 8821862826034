import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import { IFinancialAdvisor } from 'domain/Employees/types';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';
import { Box, Col, Grid, Text } from '@compoundfinance/design-system';
import { getPerson } from 'containers/Dashboard/Document/utils';
import EditablePerson from 'containers/Dashboard/Document/components/EditablePerson';
import { DirectoryPerson } from 'domain/Employees/constants';
import EditableTextArea from 'containers/Dashboard/Document/components/EditableTextArea';

const Container = styled(ModuleContainer, {
  p: '$24',

  '@bp1': {
    py: '$44',
    px: '$48',
  },
});

const GridThirds = styled(Box, {
  display: 'grid',
  gap: '$24',

  '@bp1': {
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '$48',
  },
});

export interface ServicesProps {
  financialAdvisor: DirectoryPerson;
}

function Services(props: ServicesProps) {
  const { financialAdvisor } = props;

  const financialAdvisorPerson = getPerson(
    financialAdvisor,
  ) as IFinancialAdvisor;

  return (
    <Container>
      <Col css={{ gap: '$48' }}>
        <Grid
          css={{
            gap: '$24',

            '@bp1': { gridTemplateColumns: '1fr 1fr', gap: '$48' },
          }}
        >
          <Text
            weight="medium"
            color="gray8"
            css={{
              fontSize: '$20',
              letterSpacing: '-0.8px',

              '@bp1': { fontSize: '$48', lineHeight: '58px' },
            }}
          >
            Services
          </Text>
          <Col css={{ gap: '$20', w: '100%' }}>
            <Text size="14" css={{ w: '100%' }}>
              <EditableTextArea
                propertyKey="description"
                value={`Compound helps people manage their personal finances with the same degree of rigor and sophistication that companies apply to their corporate finances.\n\nIn the near-term my team and I can focus on areas like creating a holistic financial plan across all of your finance, a forward-looking cash flow plan, estate planning, and executing an investment allocation.`}
              />
            </Text>
            <EditablePerson
              propertyKey="financialAdvisor"
              propertyValue={financialAdvisor}
              person={financialAdvisorPerson}
              size="small"
            />
          </Col>
        </Grid>

        <Col css={{ gap: '$48' }}>
          {/* Advisory services */}
          <Col css={{ gap: '$24' }}>
            <GridThirds>
              <Col css={{ gap: '$16', gridColumn: '1 / 4' }}>
                <Box css={{ borderBottom: '1px solid $gray4', pb: '$7' }}>
                  <Text size="13" color="gray9">
                    Advisory services
                  </Text>
                </Box>
              </Col>
            </GridThirds>
            <GridThirds>
              <Col css={{ gap: '$16' }}>
                <Text size="15">Financial planning</Text>
                <Text size="15">Cash flow management</Text>
                <Text size="15">Tax filing</Text>
              </Col>
              <Col css={{ gap: '$16' }}>
                <Text size="15">Company equity planning</Text>
                <Text size="15">Liquidity event planning</Text>
                <Text size="15">Estate planning</Text>
              </Col>
              <Col css={{ gap: '$16' }}>
                <Text size="15">Investment management</Text>
                <Text size="15">Asset allocation</Text>
                <Text size="15">Financial concierge</Text>
              </Col>
            </GridThirds>
          </Col>
        </Col>

        <GridThirds>
          <Col css={{ gap: '$16' }}>
            <Box css={{ borderBottom: '1px solid $gray4', pb: '$7' }}>
              <Text size="13" color="gray9">
                Secondary market
              </Text>
            </Box>
            <Col css={{ gap: '$16' }}>
              <Text size="15">Secondary marketplace</Text>
              <Text size="15">Exclusive alternative investments</Text>
            </Col>
          </Col>
          <Col css={{ gap: '$16' }}>
            <Box css={{ borderBottom: '1px solid $gray4', pb: '$7' }}>
              <Text size="13" color="gray9">
                Lifestyle
              </Text>
            </Box>
            <Col css={{ gap: '$16' }}>
              <Text size="15">Realtors</Text>
              <Text size="15">Private chefs</Text>
              <Text size="15">Executive coaching</Text>
            </Col>
          </Col>
          <Col css={{ gap: '$16' }}>
            <Box css={{ borderBottom: '1px solid $gray4', pb: '$7' }}>
              <Text size="13" color="gray9">
                Startup equity liquidity
              </Text>
            </Box>
            <Col css={{ gap: '$16' }}>
              <Text size="15">Startup-backed loans</Text>
              <Text size="15">Stock option financing</Text>
            </Col>
          </Col>
        </GridThirds>

        <GridThirds>
          <Col css={{ gap: '$16' }}>
            <Box css={{ borderBottom: '1px solid $gray4', pb: '$7' }}>
              <Text size="13" color="gray9">
                Lending
              </Text>
            </Box>
            <Col css={{ gap: '$16' }}>
              <Text size="15">Margin loans</Text>
              <Text size="15">Pledged asset lines</Text>
              <Text size="15">Personal lines of credit</Text>
              <Text size="15">Mortgages</Text>
              <Text size="15">Home equity loans (HELOCs)</Text>
              <Text size="15">Small business loans</Text>
            </Col>
          </Col>
          <Col css={{ gap: '$16' }}>
            <Box css={{ borderBottom: '1px solid $gray4', pb: '$7' }}>
              <Text size="13" color="gray9">
                Protection
              </Text>
            </Box>
            <Col css={{ gap: '$16' }}>
              <Text size="15">Complete personal insurance</Text>
              <Text size="15">Umbrella insurance</Text>
              <Text size="15">Irrevocable trust</Text>
              <Text size="15">Revocable trust</Text>
              <Text size="15">529 plans</Text>
              <Text size="15">Personal security audit</Text>
            </Col>
          </Col>
          <Col css={{ gap: '$16' }}>
            <Box css={{ borderBottom: '1px solid $gray4', pb: '$7' }}>
              <Text size="13" color="gray9">
                Philanthropy
              </Text>
            </Box>
            <Col css={{ gap: '$16' }}>
              <Text size="15">Donor advised fund</Text>
              <Text size="15">Charitable trust</Text>
              <Text size="15">In-kind gifts</Text>
            </Col>
          </Col>
        </GridThirds>
      </Col>
    </Container>
  );
}

export default Services;
