import * as ToastPrimitive from '@radix-ui/react-toast';
import { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';

import { styled } from '@compoundfinance/design-system/dist/stitches.config';
import { Toast as ToastType, Heights, BankAccountToast } from './types';
import BankAccountToastContainer from './BankAccountToastContainer';
import { TOAST_WIDTH, VIEWPORT_PADDING } from './constants';
import Toast from './Toast';
import { Box } from '@compoundfinance/design-system';
import { toast as toastUtils } from './util';
import useTypedSelector from 'hooks/typedSelector';
import { CrossIcon } from '@compoundfinance/design-system';

const DISMISS_BUTTON_WIDTH = 95;

interface ToastContainerProps {
  toasts: ToastType[];
  newBatchedToasts: ToastType[];
  newBankAccountToasts: BankAccountToast[];
  setNewBankAccountToasts: React.Dispatch<
    React.SetStateAction<BankAccountToast[]>
  >;
  zIndex?: number;
}

const Viewport = styled(ToastPrimitive.Viewport, {
  position: 'fixed',
  right: 24,
  bottom: 80,
  m: 0,
  isolation: 'isolate',

  '&:focus': {
    outline: 'none',
  },

  // Container to avoid hover bug when lifting the toasts
  '&:after': {
    content: '',
    position: 'absolute',
    w: 356,
    bottom: 0,
    right: 0,
    height: 20,
  },
});

const ToastsContainer = (props: ToastContainerProps) => {
  const {
    toasts,
    newBankAccountToasts,
    setNewBankAccountToasts,
    newBatchedToasts,
    zIndex = 9999,
  } = props;
  const [frontToastHeight, setFrontHeightToast] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const [heights, setHeights] = useState<Heights[]>([]);
  const frontToastRef = useRef<HTMLLIElement | null>(null);
  const secondToastRef = useRef<HTMLLIElement | null>(null);
  const [currentToasts, setCurrentToasts] = useState(toasts);
  const [batchedHeights, setBatchedHeights] = useState([] as Heights[]);
  const prevCurrentToasts = useRef([] as ToastType[]);
  const [portalRef, setPortalRef] = useState<HTMLDivElement | null>(null);
  const disableTransitionRef = useRef(true);
  const loaded = useTypedSelector((state) => state.global.loaded);

  useEffect(() => {
    if (!loaded) return;

    // Disable initial toasts animations on page load only
    let timeoutId = setTimeout(() => {
      disableTransitionRef.current = false;
    }, 1);

    return () => clearTimeout(timeoutId);
  }, [loaded]);

  // Create portal node. This element is used as to host toast container
  useEffect(() => {
    // Check if ref exists
    if (!portalRef) {
      const portalRef = document.createElement('div');
      // Add styles to render toasts on top of modal
      portalRef.style.cssText = `z-index: ${zIndex}; position: fixed; width: 100%`;
      document.getElementsByTagName('body')[0].appendChild(portalRef);
      setPortalRef(portalRef);
    }

    return () => {
      if (portalRef) {
        document.getElementsByTagName('body')[0].removeChild(portalRef);
        setPortalRef(null);
      }
    };
  }, [setPortalRef, portalRef, zIndex]);

  useEffect(() => {
    setCurrentToasts(toasts);
  }, [toasts]);

  useEffect(() => {
    const node = frontToastRef.current;
    const secondToastNode = secondToastRef.current;

    if (node) {
      // If the toast is closing we need to get second toast' height to update it before the toast gets removed from toasts array to avoid 250ms height animation delay
      if (node.dataset.state === 'closed' && secondToastNode) {
        const initialToastHeight = secondToastNode.dataset.height as any;
        if (initialToastHeight) {
          setFrontHeightToast(initialToastHeight);
        }
      } else {
        setFrontHeightToast(node.clientHeight);
      }
    }
  }, [toasts, currentToasts]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      // Cancel hover when esc is pressed
      setIsHovering(false);
    }
  };

  useEffect(() => {
    prevCurrentToasts.current = currentToasts;

    if (currentToasts.length > 0) return;

    const setTimeoutId = setTimeout(() => {
      toastUtils.clearAll();
    }, 250);

    return () => clearTimeout(setTimeoutId);
  }, [currentToasts]);

  if (!portalRef) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <Box
        css={{
          $$yElevate: isHovering ? '20px' : '0px',
          opacity: currentToasts.length > 0 ? 1 : 0,
          transition: 'opacity 150ms',
          pointerEvents: currentToasts.length > 0 ? 'auto' : 'none',
        }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseMove={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onFocus={() => setIsHovering(true)}
        onKeyDown={(e) => onKeyDown(e)}
        onBlur={() => setIsHovering(false)}
      >
        <ToastPrimitive.Provider>
          {toasts.map((toast, DOMIndex) => {
            // When we remove a toast, no animation is being played, because they are removed instantly
            // We create a copy of the toats array, remove the toast from the copy instantly and then we set a Timeout with duration equal to exit animation we remove the actual toast
            // We need an updated index that matches the copied array in order to accurately calculate height offset
            // We can't just use radix's animation on remove as we provide a custom array instead of switching the open prop

            const updatedIndex = currentToasts.findIndex(
              (t) => t.id === toast.id,
            );

            return (
              <Toast
                toasts={toasts}
                setIsHovering={setIsHovering}
                currentToasts={currentToasts}
                setCurrentToasts={setCurrentToasts}
                isHovering={isHovering}
                toast={toast}
                heights={heights}
                disableTransition={disableTransitionRef.current}
                setHeights={setHeights}
                batchedHeights={batchedHeights}
                setBatchedHeights={setBatchedHeights}
                newBatchedToasts={newBatchedToasts}
                ref={
                  DOMIndex === 0
                    ? frontToastRef
                    : DOMIndex === 1
                    ? secondToastRef
                    : null
                }
                //   Normally a zIndex here shouldn't be needed as the newest toast would render as last in the dom (automatically highest zIndex)
                //   However, radix is re-odering DOM so most recent toasts are at top of DOM structure to improve tab order
                //   https://github.com/radix-ui/primitives/blob/main/packages/react/toast/src/Toast.tsx#L188
                css={{
                  zIndex: toasts.length - DOMIndex,
                  $$frontToastHeight: `${frontToastHeight}px`,
                }}
                // This index doesn't contain hidden toasts
                index={updatedIndex}
                // This is the actual DOM index, but it also contains hidden toasts which will get removed after 250ms
                DOMIndex={DOMIndex}
                key={toast.id}
              />
            );
          })}
          <Viewport />
          {/* Safe triangle area */}
          <Box
            css={{
              clipPath: 'polygon(0 0, 100% 100%, 100% 0)',
              h: 42,
              w: TOAST_WIDTH - VIEWPORT_PADDING - DISMISS_BUTTON_WIDTH,
              position: 'fixed',
              bottom: 12,
              pointerEvents: isHovering ? 'auto' : 'none',
              // Offset by buttons width + viewport padding
              right: VIEWPORT_PADDING + DISMISS_BUTTON_WIDTH,
              zIndex: '$1',
            }}
          />
          {toasts.length > 1 && (
            <Box
              as="button"
              css={{
                position: 'fixed',
                bottom: 66,
                right: 26,
                // p: '$8 $12',
                p: 0,
                fontSize: 12,
                fontWeight: '$medium',
                border: 'none',
                bg: 'rgba(255, 255, 255, 0.8)',
                boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.16);',
                br: '$pill',
                opacity: isHovering ? 1 : 0,
                pointerEvents: isHovering ? 'auto' : 'none',
                transitionProperty: 'opacity, transform, background-color',
                transitionDuration: '200ms',
                zIndex: '$max',
                size: 28,
                backdropFilter: 'saturate(180%) blur(11px)',

                '&:hover': {
                  //   bg: '$gray2',
                },
              }}
              onClick={() => {
                setCurrentToasts([]);
                setIsHovering(false);
              }}
            >
              <CrossIcon size={20} />
              {/* Show current length, if currentToasts have 0 (after pressing the button), show length of toasts as they are removed 200ms later */}
              {/* {currentToasts.length > 0 ? currentToasts.length : toasts.length} */}
            </Box>
          )}
        </ToastPrimitive.Provider>
      </Box>
      {newBankAccountToasts.length > 0 && (
        <BankAccountToastContainer
          toasts={newBankAccountToasts}
          setToasts={setNewBankAccountToasts}
        />
      )}
    </>,
    portalRef,
  );
};

export default ToastsContainer;
