import ModuleContext from 'containers/Dashboard/Advisor/DocumentsAdmin/components/DocumentViewer/ModuleContext';
import isEqual from 'lodash/isEqual';
import { useContext } from 'react';
import DocumentsAPI from '../../../Document/api';
import useDocument from './useDocument';
import { Level } from 'containers/Dashboard/Documents/types';

function useMutableDocumentProperty<T>(
  propertyKey: string,
  defaultValue: T,
): [T, (newValue: T) => void] {
  const { document, mutateDocument } = useDocument();

  const value =
    document && propertyKey && propertyKey in document.properties
      ? document.properties[propertyKey]
      : defaultValue;

  const setValue = (newValue: T) => {
    const updatePropertyValue = async () => {
      if (!document) {
        console.error('Document data not available or not provided');
        return;
      }

      if (!propertyKey) {
        console.error('Document key not provided found');
        return;
      }

      if (!mutateDocument) {
        console.error('Mutate function not found');
        return;
      }

      if (isEqual(value, newValue)) {
        console.info(`Property ${propertyKey} value is unchanged`);
        return;
      }

      const updatedDocumentProperties = {
        ...document.properties,
        [propertyKey]: newValue,
      };

      // Get the updated document object
      const updatedDocument = {
        ...document,
        properties: updatedDocumentProperties,
      };

      // Commit to the SWR cache, but don't revaluate the document
      mutateDocument(updatedDocument, { revalidate: false });

      // Send the server request and then revaluate on complete
      await DocumentsAPI.updateDocumentProperties(
        document.id,
        updatedDocumentProperties,
      );
      mutateDocument();
    };

    updatePropertyValue();
  };

  return [value, setValue];
}

export function useMutableModuleProperty<T>(
  propertyKey: string,
  defaultValue: T,
): [T, (newValue: T) => void] {
  const { document, mutateDocument } = useDocument();
  const { module } = useContext(ModuleContext);

  const value =
    module && propertyKey && propertyKey in module.properties
      ? module.properties[propertyKey]
      : defaultValue;

  const setValue = (newValue: T) => {
    const updatePropertyValue = async () => {
      if (!module) {
        console.error('Module data not available or not provided');
        return;
      }

      if (!propertyKey) {
        console.error('Property key not provided found');
        return;
      }

      if (!mutateDocument) {
        console.error('Mutate function not found');
        return;
      }

      if (isEqual(value, newValue)) {
        console.info(`Property ${propertyKey} value is unchanged`);
        return;
      }

      const propertiesUpdateObj = { [propertyKey]: newValue };

      // Get the updated module object
      const updatedModule = {
        ...module,
        properties: {
          ...module.properties,
          [propertyKey]: newValue,
        },
      };

      // Get the updated document object
      const updatedDocument = {
        ...document,
        modules: {
          ...document.modules,
          [module.id]: updatedModule,
        },
      };

      // Commit to the SWR cache, but don't revaluate the document
      mutateDocument(updatedDocument, { revalidate: false });

      // Send the server request and then revaluate on complete
      await DocumentsAPI.updateModuleProperties(module.id, propertiesUpdateObj);
      mutateDocument();
    };

    updatePropertyValue();
  };

  return [value, setValue];
}

export function useMutablePropertyAtLevel(level: Level) {
  if (level === 'document') return useMutableDocumentProperty;
  if (level === 'module') return useMutableModuleProperty;
  throw new Error('Unreachable', { cause: level });
}
