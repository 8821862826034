import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';

import {
  Box,
  Button,
  Col,
  DropdownMenu,
  Flex,
  Key,
  PieIcon,
  Row,
  SidebarIcon,
  Text,
  ThreeDotsHorizontalIcon,
  Tooltip,
} from '@compoundfinance/design-system';

import { useSafeState } from 'hooks/useSafeState';

export const Header = ({ children }: { children: React.ReactNode }) => {
  return (
    <Row
      css={{
        h: '100%',
        w: '100%',
        jc: 'space-between',
        ai: 'center',
        flexShrink: 0,
      }}
    >
      {children}
    </Row>
  );
};

export const Sidebar = ({
  children,
  header,
  isDefaultToggled = true,
}: {
  children: React.ReactNode;
  header: React.ReactNode;
  isDefaultToggled?: boolean;
}) => {
  const [isToggled, setIsToggled] = useState(isDefaultToggled);
  return (
    <Col
      css={{ height: '100%' }}
      as={motion.div}
      initial={{ width: 0, opacity: 0 }}
      animate={{ width: isToggled ? 360 : 56, opacity: 1 }}
      exit={{ width: 0, opacity: 0 }}
      transition={{
        duration: 0.2,
      }}
    >
      <Row
        css={{
          h: '42px',
          px: '$12',
          py: '$8',
          jc: 'space-between',
          ai: 'center',
          bg: '$gray1',
          borderBottom: '1px solid $gray6',
          gap: '$16',
        }}
      >
        {isToggled && (
          <Flex css={{ flexGrow: 1, overflow: 'hidden' }}>{header}</Flex>
        )}

        <Button
          css={{
            h: '$32',
            w: '$32',
            ml: 'auto',
            bg: '$gray1',
            border: 'none',
            '&:hover': {
              bg: '$gray3',
            },
          }}
          type="button"
          onClick={() => {
            setIsToggled(!isToggled);
          }}
        >
          <SidebarIcon size={20} color="#8F8F8F" />
        </Button>
      </Row>
      {isToggled && (
        <Col
          css={{
            h: 'calc(100% - 42px)',
          }}
        >
          {children}
        </Col>
      )}
    </Col>
  );
};

export const EscToExitButton = ({ onExit }: { onExit: () => void }) => (
  <Tooltip>
    <Tooltip.Trigger asChild>
      <Box>
        <Button
          css={{
            all: 'unset',
            display: 'grid',
            placeItems: 'center',
            p: '$2 $4',
            br: '$4',
          }}
          type="button"
          onClick={onExit}
        >
          <Row css={{ ai: 'center', gap: 4, cursor: 'pointer' }}>
            <Key css={{ width: 'max-content', px: 4, pointerEvents: 'none' }}>
              Esc
            </Key>{' '}
            <Text size="13" color="gray11">
              to exit
            </Text>
          </Row>
        </Button>
      </Box>
    </Tooltip.Trigger>
    <Tooltip.Content>Exit allocation proposals</Tooltip.Content>
  </Tooltip>
);

export const ProposalName = ({ children }: { children: React.ReactNode }) => (
  <Row
    css={{
      bg: '$violet3',
      p: '$2 $4',
      br: '$4',
      ai: 'center',
      gap: '$6',
      overflow: 'hidden',
    }}
  >
    <Flex
      css={{
        bg: '$violet10',
        p: '$2',
        br: '$4',
        ai: 'center',
      }}
    >
      <PieIcon size={12} color="white" />
    </Flex>
    <Text
      size="13"
      color="violet10"
      css={{
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}
    >
      {children}
    </Text>
  </Row>
);

export const DropdownDottedButton = ({
  children,
  ...props
}: { children: React.ReactNode } & Parameters<typeof Button>[number]) => {
  const [isOpen, setIsOpen] = useSafeState(false);
  return (
    <DropdownMenu isOpen={isOpen} setIsOpen={setIsOpen}>
      <DropdownMenu.Trigger>
        <Button
          {...props}
          css={{
            all: 'unset',
            p: '$4',
            borderRadius: '50%',
            display: 'grid',
            placeItems: 'center',
            '&:hover': {
              color: '$gray12',
              bg: '$gray3',
            },
            ...props.css,
          }}
          type="button"
        >
          <ThreeDotsHorizontalIcon />
        </Button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Content>{children}</DropdownMenu.Content>
    </DropdownMenu>
  );
};

export const TimeRangeButton = styled(Button, {
  '&&&': {
    h: 'auto',
    py: '$4',
    px: '$4',
  },
  br: '$4',
  border: 'none',
  transition: '$default',
  variants: {
    selected: {
      true: {
        bg: '$gray4',
        '& > span': {
          color: '$gray13',
          fontWeight: '$medium',
        },
      },
      false: {
        '&:hover': {
          bg: '$gray3',
        },
      },
    },
  },
});
