import { API_URL } from 'utils/api/constants';
import { isEmpty, pickBy, identity } from 'lodash';
import { RedirectState } from './types';

export function getLoginUrl(state: RedirectState) {
  const baseUrl = 'https://appleid.apple.com/auth/authorize';
  const options = pickBy(
    {
      scope: 'name email',
      response_mode: 'form_post',
      response_type: 'code id_token',
      client_id: 'com.withcompound.compound.Services',
      redirect_uri: `${API_URL}/api/user/authenticate/sso/apple`,
      state: !isEmpty(state) ? JSON.stringify(state) : undefined,
    },
    identity,
  ) as Record<string, string>;

  const queryString = new URLSearchParams(options).toString();

  return `${baseUrl}?${queryString}`;
}
