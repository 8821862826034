import { useCompoundFetch } from 'hooks/useCompoundFetch';
import buildRequest from 'utils/api';
import { REQUEST_TYPES } from 'utils/constants/axios';
import { Redirect, useParams } from 'react-router-dom';
import DocumentProvider from '../DocumentViewer/DocumentProvider';
import DocumentViewer from '../DocumentViewer';
import { CompoundRoutes } from 'utils/constants/url';
import ExpiredOrDeletedView from './expiredOrDeletedView';
import { LoadingWheel, Row } from '@compoundfinance/design-system';
import { isMobileOnly } from 'react-device-detect';
import DocumentMobileViewer from '../DocumentMobileViewer';

function DocumentMagicLinkViewer() {
  const { sig } = useParams<{ sig: string }>();

  // Fetch document with SWR
  const {
    data,
    isLoading: isDataLoading,
    mutate: mutateMagicLink,
  } = useCompoundFetch(`/api/reports-private/${sig}`, {
    fetcher: async (path: string) => {
      const res = await buildRequest(path, true, {}, REQUEST_TYPES.GET);

      return res.data;
    },
    options: {
      notForwardUserId: true,
    },
  });

  // TODO loading, error, and empty state UI
  if (isDataLoading)
    return (
      <Row css={{ jc: 'center', ai: 'center', h: '100%' }}>
        <LoadingWheel size="xlarge" />
      </Row>
    );
  const {
    document,
    isAuthenticatedAndDocumentOwner,
    isExpired,
    expirationDate,
  } = data;

  // Redirect to dashboard document viewer if owner of document
  if (isAuthenticatedAndDocumentOwner && !isMobileOnly) {
    return (
      <Redirect
        to={CompoundRoutes.DocumentsTab.replace(':documentId', document.id)}
      />
    );
  }

  if (isMobileOnly) {
    return (
      <DocumentMobileViewer
        document={document}
        mutateDocument={mutateMagicLink}
      />
    );
  }

  // If they are not the owner of the document and it is expired
  if (!document || isExpired) {
    return (
      <ExpiredOrDeletedView
        document={document}
        expirationDate={expirationDate}
        code={sig}
        isDeleted={!document}
      />
    );
  }

  // Show standalone document viewer
  return (
    <DocumentProvider initialDocument={document} isEditor={false}>
      <DocumentViewer onClose={() => {}} />
    </DocumentProvider>
  );
}

export default DocumentMagicLinkViewer;
