import { StrategicAllocationProposalTypes } from './Strategic/StrategicAllocationProposal';

/**
 * Initially, asset allocation investment proposals would lack `investmentTargets` to reduce the payload size.
 * Later, we determined we needed them.
 * `hasAssetInformation` differentiates between old and new investment proposals.
 */
const hasAssetInformation = (
  allocationProposal: Pick<
    StrategicAllocationProposalTypes.CategorizedAllocationProposal,
    'investmentTargets' | 'blended'
  >,
) => allocationProposal.investmentTargets.length > 0;

export const InvestmentProposal = { hasAssetInformation };
