import { styled } from '@compoundfinance/design-system/dist/stitches.config';

import { Text, Col } from '@compoundfinance/design-system';

import { getPerson } from 'containers/Dashboard/Document/utils';
import { DirectoryPerson } from 'domain/Employees/constants';

import EditablePerson from 'containers/Dashboard/Document/components/EditablePerson';
import EditableTextArea from 'containers/Dashboard/Document/components/EditableTextArea';
import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import { EditableSectionTitle } from 'containers/Dashboard/Document/components/SectionTitle';

const Container = styled(ModuleContainer, {
  display: 'grid',
  py: '$24',
  px: '$24',
  gap: '$24',

  '@bp1': {
    py: '$44',
    px: '$48',
    gridTemplateColumns: '1fr 1px 1fr',
    gap: '$48',
  },
});

const VerticalSplit = styled('div', {
  border: '1px solid $gray4',
});

export interface IntroductionProps {
  title: string;
  recipient: string;
  financialAdvisor: DirectoryPerson;
}

function Introduction(props: IntroductionProps) {
  const { title, recipient, financialAdvisor } = props;

  const financialAdvisorPerson = getPerson(financialAdvisor);

  return (
    <Container>
      <Col css={{ h: '100%', justifyContent: 'space-between', gap: '$24' }}>
        <Col css={{ gap: '$12' }}>
          <EditableSectionTitle title={title} />

          <Text size="20" color="gray10">
            <EditableTextArea propertyKey="recipient" value={recipient}>
              for {recipient}
            </EditableTextArea>
          </Text>
        </Col>
      </Col>

      <VerticalSplit />

      <Col css={{ h: '100%', jc: 'space-between' }}>
        <EditableTextArea
          propertyKey="introText"
          value={`${recipient},

Copy.`}
          textCss={{
            fontSize: '$14',
            w: '100%',
          }}
        />
        <EditablePerson
          propertyKey="financialAdvisor"
          propertyValue={financialAdvisor}
          person={financialAdvisorPerson}
          size="small"
          level="document"
        />
      </Col>
    </Container>
  );
}

export default Introduction;
