import orderBy from 'lodash/orderBy';

import { isApproximately, not } from '.';
import { NumberUtils } from '../../../../utils/NumberUtils';

type Rebalanceable<
  IdKey extends PropertyKey,
  RebalanceKey extends PropertyKey,
> = Record<IdKey, PropertyKey> &
  Record<RebalanceKey, number | null | undefined>;

export type RebalanceError =
  | 'NoRebalanceableItems'
  | 'NotEnoughWeight'
  | 'Unknown';

type RebalanceData<Data> = Data;

type RebalanceResult<RebalanceResultDataType> =
  | RebalanceResultSuccessful<RebalanceResultDataType>
  | RebalanceResultErroneous<RebalanceResultDataType>;

type RebalanceResultSuccessful<RebalanceResultDataType> = {
  error: null;
  data: RebalanceData<RebalanceResultDataType>;
};

type RebalanceResultErroneous<RebalanceResultDataType> = {
  error: RebalanceError;
  data: RebalanceData<RebalanceResultDataType>;
};

const isRebalanceError = <RebalanceResultDataType>(
  result: RebalanceResult<RebalanceResultDataType>,
): result is RebalanceResultErroneous<RebalanceResultDataType> =>
  !!result.error;

const getEquitativeContributionPerItem = <
  Type extends Rebalanceable<IdKey, RebalanceKey>,
  IdKey extends keyof Type,
  RebalanceKey extends keyof Type,
>({
  items,
  contribution,
  idKey = 'id' as keyof Type,
  rebalanceKey,
}: {
  items: Type[];
  contribution: number;
  idKey?: keyof Type;
  rebalanceKey: keyof Type;
}) => {
  const contributionPerItem: Record<PropertyKey, number> = {};

  let itemsLeft = items.length;
  let contributionLeftover = contribution;
  let proportionalContribution = NumberUtils.divide(
    contribution,
    itemsLeft || 1,
  );
  const sortedByLowerProportionalContributionItems = orderBy(
    items,
    rebalanceKey,
    'asc',
  );
  for (const item of sortedByLowerProportionalContributionItems) {
    itemsLeft = itemsLeft - 1;
    const itemContribution = Math.min(
      item[rebalanceKey] ?? 0,
      proportionalContribution,
    );
    contributionPerItem[item[idKey]] = itemContribution;
    contributionLeftover = NumberUtils.subtract(
      contributionLeftover,
      itemContribution,
    );
    proportionalContribution = NumberUtils.divide(
      contributionLeftover,
      itemsLeft || 1,
    );
  }

  return contributionPerItem;
};

const rebalanceEquitatively = <
  Type extends Rebalanceable<IdKey, RebalanceKey>,
  IdKey extends keyof Type,
  RebalanceKey extends keyof Type,
>({
  items,
  item,
  rebalanceKey,
  idKey = 'id' as keyof Type,
  isRebalanceable = () => true,
  getRebalanceDifference = ({
    itemRebalanceAmount,
    previousItemRebalanceAmount,
  }: {
    itemRebalanceAmount: number;
    previousItemRebalanceAmount: number;
  }) => NumberUtils.subtract(itemRebalanceAmount, previousItemRebalanceAmount),
}: {
  items: Type[];
  item: Type;
  rebalanceKey: keyof Type;
  idKey?: keyof Type;
  isRebalanceable?: (item: Type) => boolean;
  getRebalanceDifference?: ({
    itemRebalanceAmount,
    previousItemRebalanceAmount,
  }: {
    itemRebalanceAmount: number;
    previousItemRebalanceAmount: number;
  }) => number;
}): RebalanceResult<Type[]> => {
  const isItem = (i) => i[idKey] === item[idKey];
  const itemIndex = items.findIndex(isItem);
  const previousItem = items[itemIndex];
  const itemRebalanceAmount = item[rebalanceKey] ?? 0;
  const previousItemRebalanceAmount = previousItem[rebalanceKey] ?? 0;
  const rebalanceDifference = getRebalanceDifference({
    itemRebalanceAmount,
    previousItemRebalanceAmount,
  });

  const rebalanceableItems = items.filter(not(isItem)).filter(isRebalanceable);
  const isThereItemsToRebalance = rebalanceableItems.length > 0;

  const rebalanceableItemsWeight = rebalanceableItems
    .map((i) => i[rebalanceKey] ?? 0)
    .reduce(NumberUtils.add, 0);
  const isRebalanceableItemsWeightEnoughToRebalance =
    rebalanceableItemsWeight > rebalanceDifference ||
    isApproximately({
      value: rebalanceableItemsWeight,
      target: rebalanceDifference,
    });

  const isAmountDifferent = !isApproximately({
    value: itemRebalanceAmount,
    target: previousItemRebalanceAmount,
  });
  const requiresRebalancing = isAmountDifferent;
  const canRebalance =
    isThereItemsToRebalance && isRebalanceableItemsWeightEnoughToRebalance;
  if (requiresRebalancing && !canRebalance)
    return {
      data: items,
      error: !isThereItemsToRebalance
        ? 'NoRebalanceableItems'
        : !isRebalanceableItemsWeightEnoughToRebalance
        ? 'NotEnoughWeight'
        : 'Unknown',
    };

  const contributionPerRebalanceableItem = getEquitativeContributionPerItem({
    items: rebalanceableItems,
    contribution: rebalanceDifference,
    idKey,
    rebalanceKey,
  });
  return {
    data: [
      ...items.slice(0, itemIndex).map((i) =>
        canRebalance && isRebalanceable(i)
          ? {
              ...i,
              [rebalanceKey]: Math.max(
                NumberUtils.subtract(
                  i[rebalanceKey],
                  contributionPerRebalanceableItem[i[idKey]],
                ),
                0,
              ),
            }
          : i,
      ),
      {
        ...item,
        [rebalanceKey]:
          item[rebalanceKey] != null
            ? Math.max(
                canRebalance
                  ? itemRebalanceAmount
                  : previousItemRebalanceAmount,
                0,
              )
            : null,
      },
      ...items.slice(itemIndex + 1).map((i) =>
        canRebalance && isRebalanceable(i)
          ? {
              ...i,
              [rebalanceKey]: Math.max(
                NumberUtils.subtract(
                  i[rebalanceKey],
                  contributionPerRebalanceableItem[i[idKey]],
                ),
                0,
              ),
            }
          : i,
      ),
    ],
    error: null,
  };
};

const rebalanceProportionally = <
  Type extends Rebalanceable<IdKey, RebalanceKey>,
  IdKey extends keyof Type,
  RebalanceKey extends keyof Type,
>({
  items,
  item,
  rebalanceKey,
  idKey = 'id' as keyof Type,
  isRebalanceable = () => true,
}: {
  items: Type[];
  item: Type;
  rebalanceKey: keyof Type;
  idKey?: keyof Type;
  isRebalanceable?: (item: Type) => boolean;
}): RebalanceResult<Type[]> => {
  const isItem = (i) => i[idKey] === item[idKey];
  const rebalanceableItems = items.filter(not(isItem)).filter(isRebalanceable);

  const itemIndex = items.findIndex(isItem);
  const previousItem = items[itemIndex];
  const itemRebalanceAmount = item[rebalanceKey] ?? 0;
  const previousItemRebalanceAmount = previousItem[rebalanceKey] ?? 0;
  const rebalanceProportion = NumberUtils.divide(
    itemRebalanceAmount,
    previousItemRebalanceAmount || 1,
  );

  const isAmountDifferent = !isApproximately({
    value: itemRebalanceAmount,
    target: previousItemRebalanceAmount,
  });
  const requiresRebalancing = isAmountDifferent;
  const isThereItemsToRebalance = rebalanceableItems.length > 0;
  const canRebalance = isThereItemsToRebalance;
  if (requiresRebalancing && !canRebalance)
    return {
      data: items,
      error: isThereItemsToRebalance ? 'NoRebalanceableItems' : 'Unknown',
    };

  return {
    data: [
      ...items.slice(0, itemIndex).map((i) =>
        isRebalanceable(i)
          ? {
              ...i,
              [rebalanceKey]: NumberUtils.multiply(
                i[rebalanceKey],
                rebalanceProportion,
              ),
            }
          : i,
      ),
      {
        ...item,
        [rebalanceKey]:
          item[rebalanceKey] != null ? Math.max(itemRebalanceAmount, 0) : null,
      },
      ...items.slice(itemIndex + 1).map((i) =>
        isRebalanceable(i)
          ? {
              ...i,
              [rebalanceKey]: NumberUtils.multiply(
                i[rebalanceKey],
                rebalanceProportion,
              ),
            }
          : i,
      ),
    ],
    error: null,
  };
};

const rebalanceProportionallyToTarget = <
  Type extends Rebalanceable<IdKey, RebalanceKey>,
  IdKey extends keyof Type,
  RebalanceKey extends keyof Type,
>({
  items,
  target = 100,
  rebalanceKey,
  isRebalanceable = () => true,
}: {
  items: Type[];
  target: number;
  rebalanceKey: keyof Type;
  isRebalanceable?: (item: Type) => boolean;
}): RebalanceResult<Type[]> => {
  const rebalanceableItems = items.filter(isRebalanceable);

  const rebalanceableItemsWeight = rebalanceableItems
    .map((i) => i[rebalanceKey] ?? 0)
    .reduce(NumberUtils.add, 0);
  const rebalanceProportion = NumberUtils.divide(
    target,
    rebalanceableItemsWeight || 1,
  );

  const isAmountDifferent = !isApproximately({
    value: rebalanceableItemsWeight,
    target,
  });
  const requiresRebalancing = isAmountDifferent;
  const isThereItemsToRebalance = rebalanceableItems.length > 0;
  const canRebalance = isThereItemsToRebalance;
  if (requiresRebalancing && !canRebalance)
    return {
      data: items,
      error: !isThereItemsToRebalance ? 'NoRebalanceableItems' : 'Unknown',
    };

  return {
    data: items.map((i) => {
      return isRebalanceable(i)
        ? {
            ...i,
            [rebalanceKey]: NumberUtils.multiply(
              i[rebalanceKey],
              rebalanceProportion,
            ),
          }
        : i;
    }),
    error: null,
  };
};

export const SeriesUtils = {
  rebalancing: {
    rebalanceEquitatively,
    rebalanceProportionally,
    rebalanceProportionallyToTarget,
    isRebalanceError,
  },
};
