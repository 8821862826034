import { AssetTypes } from '@compoundfinance/compound-core/dist/types/account';
import {
  Cashflow,
  CashflowProfileUpdateType,
  CashflowSettings,
} from 'types/cashflow';
import AssetUtils from 'utils/assets';
import { getEnumValues } from 'utils/constants';

export const DEFAULT_CASHFLOW_ASSET_TYPE_GROWTH_RATES = {
  [AssetTypes.Cash]: 1,
  [AssetTypes.RealEstate]: 3,
  [AssetTypes.PublicInvestment]: 6,
};

export const DEFAULT_CASHFLOW_PROFILE_GROWTH_RATES = {
  [CashflowProfileUpdateType.AnnualIncome]: 3,
  [CashflowProfileUpdateType.MonthlyExpenses]: 3,
};

export const DEFAULT_GROUP_GROWTH_RATES: Record<AssetTypes, number | null> =
  getEnumValues(AssetTypes)
    .filter((a) => AssetUtils.isAsset(a))
    .reduce((memo, key) => {
      const rate = DEFAULT_CASHFLOW_ASSET_TYPE_GROWTH_RATES[key] || 0;
      return { ...memo, [key]: rate };
    }, {});

export const EMPTY_CASHFLOW: Cashflow = {
  id: '',
  name: '',
  profileUpdates: [],
  transfers: [],
  growthRates: [],
  paydownRates: [],
  balanceUpdates: [],
  otherTransactions: [],
  futureAccounts: [],
  homes: [],
  accountFilters: {},
  simulationFilters: {},
  groupGrowthRates: DEFAULT_GROUP_GROWTH_RATES,
  userId: '',
  inflowAccountId: '',
  outflowAccountId: '',
  outputCache: { fiveYear: null, tenYear: null },
  advisor: null,
  notes: '',
  rehydrated: false,
  updatedBalances: [],
};

export const DEFAULT_CASHFLOW_SETTINGS: CashflowSettings = {
  id: '',
  bookmarkCashflowId: null,
  userCashflowOrder: [],
  advisorCashflowOrder: [],
  userId: '',
};
