import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import partition from 'lodash/partition';

import { Prettify } from 'utils/types';
import { NumberUtils } from 'utils/NumberUtils';
import { ValueOf } from 'types';

import { ASSET_CATEGORY } from '../../constants';
import { not } from '../../utils';
import { AllocationAsset } from '../../../Accounts/AssetAllocation/AllocationExplorer';
import { AssetCategoryTree } from '../../utils/AssetCategoryTree';
import {
  Allocation,
  AllocationMessageTypes,
  AllocationTypes,
} from '../Allocation';
import { Asset } from '../Asset';
import {
  InvestmentTarget,
  InvestmentTargetMessageTypes,
  InvestmentTargetTypes,
} from '../InvestmentTarget';
import { AllocationProposalStrategy } from '../AllocationProposalStrategy';
import { TransitionTypes } from '../Transition';

export const StrategicAllocationProposalStrategyType = {
  Preset: 'preset',
  Original: 'original',
  Target: 'target',
} as const;
type StrategicAllocationProposalStrategyType = ValueOf<
  typeof StrategicAllocationProposalStrategyType
>;

export namespace AllocationProposalStrategyMessageTypes {
  export type AllocationProposalStrategyMessage = {
    id?: string;
    networth: number;
    type: Extract<
      StrategicAllocationProposalStrategyType,
      'original' | 'target'
    >;
    investmentTarget: InvestmentTargetMessageTypes.InvestmentTargetMessage | null;
    allocations: AllocationMessageTypes.AllocationMessage[];
    updatedAt?: string;
    createdAt?: string;
  };
}

export namespace StrategicAllocationProposalStrategyTypes {
  type BaseAllocationProposalStrategy = {
    id: string;
    networth: number;
    type: StrategicAllocationProposalStrategyType;
    updatedAt?: string;
    createdAt?: string;
  };
  type AllocationProposalStrategyAllocations = {
    allocations: AllocationTypes.Allocation[];
  };
  type AllocationProposalStrategyCategorizedAllocations = {
    allocations: Record<string, AllocationTypes.Allocation>;
  };
  type AllocationProposalStrategyClientAllocations = {
    allocations: AllocationTypes.ClientAllocation[];
  };
  type AllocationProposalStrategyCategorizedClientAllocations = {
    allocations: Record<string, AllocationTypes.ClientAllocation>;
  };

  type BaseInvestmentTargetAllocationProposalStrategy =
    BaseAllocationProposalStrategy & {
      type: Extract<StrategicAllocationProposalStrategyType, 'target'>;
      investmentTarget: InvestmentTargetTypes.InvestmentTarget;
    };
  type BaseOriginalAllocationProposalStrategy =
    BaseAllocationProposalStrategy & {
      type: Extract<StrategicAllocationProposalStrategyType, 'original'>;
    };

  export type InvestmentTargetAllocationProposalStrategy = Prettify<
    BaseInvestmentTargetAllocationProposalStrategy &
      AllocationProposalStrategyAllocations
  >;
  export type CategorizedInvestmentTargetAllocationProposalStrategy = Prettify<
    BaseInvestmentTargetAllocationProposalStrategy &
      AllocationProposalStrategyCategorizedAllocations
  >;

  export type OriginalAllocationProposalStrategy = Prettify<
    BaseOriginalAllocationProposalStrategy &
      AllocationProposalStrategyClientAllocations
  >;
  export type CategorizedOriginalAllocationProposalStrategy = Prettify<
    BaseOriginalAllocationProposalStrategy &
      AllocationProposalStrategyCategorizedClientAllocations
  >;

  export type AllocationProposalStrategy = Prettify<
    | InvestmentTargetAllocationProposalStrategy
    | OriginalAllocationProposalStrategy
  >;

  export type CategorizedAllocationProposalStrategy = Prettify<
    | CategorizedInvestmentTargetAllocationProposalStrategy
    | CategorizedOriginalAllocationProposalStrategy
  >;

  export type PresetAssetAllocation = BaseAllocationProposalStrategy & {
    allocations: AllocationTypes.CategoryAllocation[];
  } & { type: 'preset' };

  export type WeightedAllocationProposalStrategy = {
    allocations: Record<string, AllocationTypes.Allocation['weight']>;
  };
}

const isOriginal = (
  allocationProposalStrategy:
    | StrategicAllocationProposalStrategyTypes.AllocationProposalStrategy
    | StrategicAllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy,
): allocationProposalStrategy is
  | StrategicAllocationProposalStrategyTypes.OriginalAllocationProposalStrategy
  | StrategicAllocationProposalStrategyTypes.CategorizedOriginalAllocationProposalStrategy =>
  allocationProposalStrategy.type === 'original';

const hasClientAllocations = <
  AllocationType extends AllocationTypes.Allocation,
>(
  categorizedAllocation: Record<string, AllocationType>,
) =>
  AllocationProposalStrategy.fromCategorized(categorizedAllocation).some(
    Allocation.isClientAllocation,
  );

const isInvestmentTarget = (
  allocationProposalStrategies:
    | StrategicAllocationProposalStrategyTypes.AllocationProposalStrategy
    | StrategicAllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy,
): allocationProposalStrategies is
  | StrategicAllocationProposalStrategyTypes.InvestmentTargetAllocationProposalStrategy
  | StrategicAllocationProposalStrategyTypes.CategorizedInvestmentTargetAllocationProposalStrategy =>
  allocationProposalStrategies.type === 'target' &&
  allocationProposalStrategies.investmentTarget != null;

const onlyOriginal = <
  AllocationProposalStrategyType extends
    | StrategicAllocationProposalStrategyTypes.AllocationProposalStrategy
    | StrategicAllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy,
>(
  allocationProposalStrategies: AllocationProposalStrategyType[],
) => allocationProposalStrategies.find(isOriginal)!;

const onlyInvestmentTargets = <
  AllocationProposalStrategyType extends
    | StrategicAllocationProposalStrategyTypes.AllocationProposalStrategy
    | StrategicAllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy,
>(
  allocationProposalStrategies: AllocationProposalStrategyType[],
) => allocationProposalStrategies.filter(isInvestmentTarget);

const transitions = <
  AllocationType extends Pick<
    StrategicAllocationProposalStrategyTypes.CategorizedInvestmentTargetAllocationProposalStrategy,
    'investmentTarget'
  >,
>(
  allocationProposalInvestmentTargets: AllocationType[],
): AllocationType['investmentTarget']['transitions'] =>
  allocationProposalInvestmentTargets.flatMap(
    ({ investmentTarget: { transitions } }) => transitions,
  );

const networth = <
  AllocationType extends StrategicAllocationProposalStrategyTypes.CategorizedOriginalAllocationProposalStrategy,
>(
  originalAllocationProposalStrategy: AllocationType,
) =>
  AllocationProposalStrategy.fromCategorized(
    originalAllocationProposalStrategy.allocations,
  ).reduce(
    (unomittedNetworth, transition) =>
      NumberUtils.add(unomittedNetworth, Allocation.value(transition)),
    0,
  );

const transitionedNetworth = <
  AllocationType extends StrategicAllocationProposalStrategyTypes.CategorizedInvestmentTargetAllocationProposalStrategy,
>(
  allocationProposalInvestmentTargets: AllocationType[],
) =>
  AllocationProposalStrategy.value(
    transitions(allocationProposalInvestmentTargets),
  );

const unomittedNetworth = <
  AllocationType extends StrategicAllocationProposalStrategyTypes.CategorizedOriginalAllocationProposalStrategy,
>(
  originalAllocationProposalStrategy: AllocationType,
) =>
  AllocationProposalStrategy.value(
    AllocationProposalStrategy.fromCategorized(
      originalAllocationProposalStrategy.allocations,
    ).filter(not(Allocation.isOmitted)),
  );

const compoundManagedNetworth = <
  AllocationType extends StrategicAllocationProposalStrategyTypes.CategorizedOriginalAllocationProposalStrategy,
>(
  originalAllocationProposalStrategy: AllocationType,
) =>
  AllocationProposalStrategy.value(
    AllocationProposalStrategy.fromCategorized(
      originalAllocationProposalStrategy.allocations,
    ).filter((clientAllocation) => {
      const {
        asset: { originalAsset },
      } = clientAllocation;
      const hasOriginalAsset = !!originalAsset;
      if (!hasOriginalAsset) return false;
      return Asset.isCompoundInvestment(originalAsset);
    }),
  );

const subtract = <
  AllocationType extends Record<string, AllocationTypes.Allocation>,
>(
  targetAllocations: AllocationType,
  sourceAllocations: AllocationType,
) =>
  AllocationProposalStrategy.toCategorized(
    AllocationProposalStrategy.fromCategorized(targetAllocations).map(
      (targetAllocation) => {
        const { slug } = targetAllocation;
        const sourceAllocation = sourceAllocations[slug];
        if (!sourceAllocation) return targetAllocation;
        return {
          ...targetAllocation,
          weight: NumberUtils.subtract(
            Allocation.weight(targetAllocation),
            Allocation.weight(sourceAllocation),
          ),
          value: NumberUtils.subtract(
            Allocation.value(targetAllocation),
            Allocation.value(sourceAllocation),
          ),
        };
      },
    ),
  );

const merge = <
  AllocationType extends StrategicAllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy['allocations'],
>(
  allocations: AllocationType[],
): AllocationType =>
  allocations.reduce(
    (mergedAllocations, allocations) => ({
      ...mergedAllocations,
      ...Object.entries(allocations).reduce(
        (mergedAllocations, [slug, allocation]) => {
          const blendedAllocation = mergedAllocations[slug] || {};
          return {
            ...mergedAllocations,
            [slug]: {
              ...allocation,
              weight: NumberUtils.add(
                Allocation.weight(blendedAllocation),
                Allocation.weight(allocation),
              ),
              value: NumberUtils.add(
                Allocation.value(blendedAllocation),
                Allocation.value(allocation),
              ),
            },
          };
        },
        mergedAllocations,
      ),
    }),
    {} as AllocationType,
  );

const toBlendedAllocations = (
  allocationProposalStrategies: StrategicAllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy[],
): StrategicAllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy['allocations'] => {
  const investmentTargetAllocationProposalStrategies =
    allocationProposalStrategies.filter(
      isInvestmentTarget,
    ) as StrategicAllocationProposalStrategyTypes.CategorizedInvestmentTargetAllocationProposalStrategy[];
  const categorizedInvestmentTargetAllocationProposalStrategies =
    investmentTargetAllocationProposalStrategies.map(
      constrainAllocationsWeightByInvestmentTargetWeight,
    );
  return categorizedInvestmentTargetAllocationProposalStrategies.reduce(
    (blendedAllocations, allocations) => ({
      ...blendedAllocations,
      ...Object.entries(allocations).reduce(
        (blendedAllocations, [slug, allocation]) => {
          const blendedAllocation = blendedAllocations[slug] || {};
          return {
            ...blendedAllocations,
            [slug]: {
              ...allocation,
              weight: NumberUtils.add(
                Allocation.weight(blendedAllocation),
                Allocation.weight(allocation),
              ),
            },
          };
        },
        blendedAllocations,
      ),
    }),
    {},
  );
};

const constrainAllocationsWeightByInvestmentTargetWeight = (
  allocationProposalInvestmentTarget: StrategicAllocationProposalStrategyTypes.CategorizedInvestmentTargetAllocationProposalStrategy,
) => {
  const { investmentTarget, allocations } = allocationProposalInvestmentTarget;
  const rebalanceToAccountsWeight = AllocationProposalStrategy.rebalanceTo(
    InvestmentTarget.totalWeight(investmentTarget),
  );
  return rebalanceToAccountsWeight(allocations);
};

const ACCOUNTLESS_ASSETS_ID = 'ACCOUNTLESS_ASSETS_ID';
const absorbHoldingsWithinTheAccountTheyBelongTo = (
  assets: AllocationTypes.ClientAllocation[],
) => {
  const assetsGroupedByAccount = groupBy(assets, (clientAllocation) =>
    clientAllocation.assetSource === 'plaidAccount'
      ? clientAllocation.asset?.originalAsset?.id
      : clientAllocation.asset?.originalAsset?.plaidAccountId ??
        ACCOUNTLESS_ASSETS_ID,
  );
  const { ACCOUNTLESS_ASSETS_ID: accountlessAssets = [], ...assetsAndAccount } =
    assetsGroupedByAccount;
  return [
    ...Object.entries(assetsAndAccount).reduce(
      (accounts, [accountId, assetsAndAccount]) => {
        const [[account], assets] = partition(
          assetsAndAccount,
          (clientAllocation) => clientAllocation.assetId === accountId,
        );
        const hasAccount = !!account;
        if (!hasAccount) return [...accounts, ...assets];
        return [
          ...accounts,
          {
            ...account,
            weight: NumberUtils.add(
              Allocation.weight(account),
              assets.reduce(
                (assetsWeight, asset) =>
                  NumberUtils.add(assetsWeight, Allocation.weight(asset)),
                0,
              ),
            ),
            value: NumberUtils.add(
              Allocation.value(account),
              assets.reduce(
                (assetValue, asset) =>
                  NumberUtils.add(assetValue, Allocation.value(asset)),
                0,
              ),
            ),
          },
        ];
      },
      [],
    ),
    ...accountlessAssets,
  ];
};

const representationalClientAllocations = <
  Allocation extends
    | AllocationTypes.ClientAllocation
    | TransitionTypes.Transition,
>({
  clientAllocations,
  numberOfAssets,
}: {
  clientAllocations: Allocation[];
  numberOfAssets: number;
}) => {
  const representationalClientAllocations =
    StrategicAllocationProposalStrategy.absorbHoldingsWithinTheAccountTheyBelongTo(
      clientAllocations,
    );
  const representationalClientAllocationsOrderedBySize =
    orderBy<AllocationTypes.ClientAllocation>(
      representationalClientAllocations,
      [Allocation.weight],
      ['desc'],
    );
  const [xBiggerClientAllocations, xSmallerClientAllocations] = [
    representationalClientAllocationsOrderedBySize.slice(0, numberOfAssets),
    representationalClientAllocationsOrderedBySize.slice(numberOfAssets),
  ];
  const hasXSmallerAllocations = xSmallerClientAllocations.length > 0;
  return [
    ...xBiggerClientAllocations,
    ...(hasXSmallerAllocations
      ? [Allocation.fromMany(xSmallerClientAllocations)]
      : []),
  ] as Allocation[];
};

const toInvestmentProposal = (
  allocationProposalInvestmentTargets: StrategicAllocationProposalStrategyTypes.CategorizedInvestmentTargetAllocationProposalStrategy[],
) =>
  allocationProposalInvestmentTargets.map(
    (allocationProposalInvestmentTarget) => {
      const { investmentTarget } = allocationProposalInvestmentTarget;
      const { asset, transitions } = investmentTarget;
      const hasAsset = !!asset;
      const { originalAsset } = asset ?? {};
      const hasOriginalAsset = !!originalAsset;
      return {
        ...allocationProposalInvestmentTarget,
        investmentTarget: {
          ...investmentTarget,
          asset: hasAsset
            ? {
                ...asset,
                originalAsset: hasOriginalAsset
                  ? Asset.simplifyOriginalAsset(originalAsset)
                  : undefined,
              }
            : null,
          transitions:
            StrategicAllocationProposalStrategy.representationalClientAllocations(
              {
                clientAllocations: transitions,
                numberOfAssets: 10,
              },
            ).map(Allocation.toInvestmentProposal),
        },
      };
    },
  );

const toMessage = (
  allocationProposalStrategy: StrategicAllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy,
): AllocationProposalStrategyMessageTypes.AllocationProposalStrategyMessage => {
  if (isInvestmentTarget(allocationProposalStrategy)) {
    const { investmentTarget, ...allocationProposalStrategyMessage } =
      allocationProposalStrategy;
    const { asset, transitions, assetAllocation, ...investmentTargetMessage } =
      investmentTarget;
    return {
      ...allocationProposalStrategyMessage,
      allocations: AllocationProposalStrategy.fromCategorized(
        assetAllocation,
      ).map(Allocation.toMessage),
      investmentTarget: {
        ...investmentTargetMessage,
        transitions: transitions
          .map(Allocation.fromTransition)
          .map(Allocation.toMessage),
      },
    };
  }
  const { allocations, ...allocationProposalStrategyMessage } =
    allocationProposalStrategy;
  return {
    ...allocationProposalStrategyMessage,
    investmentTarget: null,
    allocations: AllocationProposalStrategy.fromCategorized(allocations).map(
      Allocation.toMessage,
    ),
  };
};

const fromMessage =
  ({
    assetCategories,
    assets,
    networth,
  }: {
    assetCategories: ASSET_CATEGORY[];
    assets: AllocationAsset[];
    networth: number;
  }) =>
  (
    allocationProposalStrategyMessage: AllocationProposalStrategyMessageTypes.AllocationProposalStrategyMessage,
  ) => {
    const allocationFromMessage = Allocation.fromMessage({
      assetCategories,
      assets,
      networth,
    });

    const { allocations, investmentTarget } = allocationProposalStrategyMessage;
    const allocationsFromMessage = AssetCategoryTree.normalize(
      AllocationProposalStrategy.toCategorized(
        allocations.map(allocationFromMessage),
      ),
    );
    const { transitions = [] } = investmentTarget ?? {};
    const transitionsFromMessage = transitions.map(allocationFromMessage);
    return {
      ...allocationProposalStrategyMessage,
      ...(investmentTarget && {
        investmentTarget: {
          ...investmentTarget,
          transitions: transitionsFromMessage.map((transition) => ({
            ...transition,
            investmentTarget: investmentTarget ?? null,
          })),
          asset: assets.find(
            (asset) =>
              asset.assetSource === investmentTarget.assetSource &&
              asset.id === investmentTarget.assetId,
          ),
          assetAllocation: AllocationProposalStrategy.toCategorized(
            allocations.map(allocationFromMessage),
          ),
        },
      }),
      allocations: allocationsFromMessage,
    } as StrategicAllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy;
  };

export const StrategicAllocationProposalStrategy = {
  absorbHoldingsWithinTheAccountTheyBelongTo,
  compoundManagedNetworth,
  constrainAllocationsWeightByInvestmentTargetWeight,
  fromMessage,
  hasClientAllocations,
  merge,
  networth,
  onlyOriginal,
  onlyInvestmentTargets,
  representationalClientAllocations,
  subtract,
  toBlendedAllocations,
  toInvestmentProposal,
  toMessage,
  transitions,
  transitionedNetworth,
  unomittedNetworth,
};
