/* TODO: Moved to src/widgets/AssetAllocationWidget/MekkoChart, remove once released  */

export const labelKey = 'label';

export const RADIUS = 4;
//top-left, top-right, bottom-right, bottom left
const BarRadii = {
  Left: [RADIUS, 0, 0, RADIUS],
  LeftBottom: [0, 0, 0, RADIUS],
  LeftTop: [RADIUS, 0, 0, 0],
  Right: [0, RADIUS, RADIUS, 0],
  RightBottom: [0, 0, RADIUS, 0],
  RightTop: [0, RADIUS, 0, 0],
  All: [RADIUS, RADIUS, RADIUS, RADIUS],
  Bottom: [0, 0, RADIUS, RADIUS],
  Top: [RADIUS, RADIUS, 0, 0],
  None: [0, 0, 0, 0],
} as Record<string, [number, number, number, number]>;

export function getRadiusSetting(
  categoryIndex: number,
  sectionIndex: number,
  numCategories: number,
  numSections: number,
) {
  if (numCategories === 1) {
    // There is a single bar being displayed
    if (numSections === 1) {
      return BarRadii.All;
    }

    // There is only one category bar, but more than 1 section (account) bar
    if (sectionIndex === 0) {
      return BarRadii.Bottom;
    }

    if (sectionIndex + 1 === numSections) {
      return BarRadii.Top;
    }
  }

  if (categoryIndex === 0) {
    if (numSections === 1) {
      return BarRadii.Left;
    }

    if (sectionIndex === 0) {
      return BarRadii.LeftBottom;
    }

    if (sectionIndex + 1 === numSections) {
      return BarRadii.LeftTop;
    }
  }

  if (categoryIndex + 1 === numCategories) {
    if (numSections === 1) {
      return BarRadii.Right;
    }

    if (sectionIndex === 0) {
      return BarRadii.RightBottom;
    }

    if (sectionIndex + 1 === numSections) {
      return BarRadii.RightTop;
    }
  }

  return BarRadii.None;
}
