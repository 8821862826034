import EditablePerson from 'containers/Dashboard/Document/components/EditablePerson';
import EditableTextArea from 'containers/Dashboard/Document/components/EditableTextArea';
import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import { DirectoryPerson } from 'domain/Employees/constants';
import { getPerson } from 'containers/Dashboard/Document/utils';
import { Text, Col, Box, Row, Grid } from '@compoundfinance/design-system';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';
import TargetAssetAllocationPieChart from './target-asset-allocation-pie-chart.png';

const TARGET_ASSET_ALLOCATION_BREAKDOWN: Array<AssetAllocationBreakdownLegendItem> =
  [
    {
      title: 'Public',
      value: 65,
      // color: '#3A5CCC', derive gradient from children colors
      children: [
        { title: 'Equity', value: 54, color: '#3A5CCC' },
        { title: 'Fixed Income', value: 7, color: '#8DA4EF' },
        { title: 'Real Assets', value: 4, color: '#C6D4F9' },
      ],
    },
    {
      title: 'Alternative',
      value: 30,
      // color: '#3A5CCC', derive gradient from children colors
      children: [
        { title: 'Equity', value: 18, color: '#AB4ABA' },
        { title: 'Fixed Income', value: 12, color: '#CF91D8' },
      ],
    },
    {
      title: 'Cash',
      value: 5,
      color: '#46A758',
    },
  ];

interface AssetAllocationBreakdownLegendItem {
  title: string;
  value: number;
  color?: string;
  children?: Array<AssetAllocationBreakdownLegendItem>;
}

interface AssetAllocationBreakdownLegendProps {
  breakdown: Array<AssetAllocationBreakdownLegendItem>;
  boldText?: boolean;
}

function AssetAllocationBreakdownLegend(
  props: AssetAllocationBreakdownLegendProps,
) {
  const { breakdown, boldText = true } = props;

  return (
    <Col css={{ gap: '$16' }}>
      {breakdown.map((item) => {
        // Use color from item or derive from children or fallback to gray6
        const backgroundCss = {
          background:
            item.color ||
            (item.children &&
              item.children.length > 1 &&
              `linear-gradient(to bottom right, ${item.children[0].color}, ${
                item.children.at(-1)!.color
              })`) ||
            '$gray6',
        };
        return (
          <Col key={item.title + item.value} css={{ gap: '$16' }}>
            <Row css={{ jc: 'space-between', ai: 'center', gap: '$8' }}>
              <Row css={{ gap: '$8' }}>
                <Box
                  css={{
                    w: '$16',
                    h: '$16',
                    br: '$2',
                    ...backgroundCss,
                  }}
                />
                <Text size="13" weight={boldText ? 'bold' : 'regular'}>
                  {item.title}
                </Text>
              </Row>

              <Text size="13" weight={boldText ? 'bold' : 'regular'}>
                {item.value}%
              </Text>
            </Row>
            {item.children && (
              <Box css={{ pl: '$24' }}>
                <AssetAllocationBreakdownLegend
                  breakdown={item.children || []}
                  boldText={false}
                />
              </Box>
            )}
          </Col>
        );
      })}
    </Col>
  );
}

const Container = styled(ModuleContainer, {
  py: '$24',

  px: '$24',
  gap: '$24',

  '@bp1': {
    py: '$44',
    px: '$48',

    gap: '$48',
  },
});

export interface TargetAssetAllocationProps {
  financialAdvisor: DirectoryPerson;
}

function TargetAssetAllocation(props: TargetAssetAllocationProps) {
  const { financialAdvisor } = props;

  const financialAdvisorPerson = getPerson(financialAdvisor);

  return (
    <Container>
      <Col css={{ h: '100%', gap: '$24', '@bp1': { gap: '$48' } }}>
        <Text
          weight="medium"
          color="gray8"
          css={{
            fontSize: '$20',
            letterSpacing: '-0.8px',

            '@bp1': { fontSize: '$48', lineHeight: '58px' },
          }}
        >
          Target asset allocation
        </Text>

        <Grid
          css={{
            '@bp1': { gridTemplateColumns: '1fr 1fr' },
          }}
        >
          <Col
            css={{
              h: '100%',
              gap: '$24',
              justifyContent: 'flex-start',
              '@bp1': { gap: '$48' },
            }}
          >
            <Box css={{ w: '100%', '@bp1': { w: '60%' } }}>
              <AssetAllocationBreakdownLegend
                breakdown={TARGET_ASSET_ALLOCATION_BREAKDOWN}
              />
            </Box>

            <Col css={{ gap: '$24' }}>
              <Text size="14" css={{ w: '100%' }}>
                <EditableTextArea
                  propertyKey="description"
                  value={`This is an example target asset allocation. Once we understand your full balance sheet of assets and liabilities, we will work with you to create a personalized allocation target. When developing your target allocation, we work to align your finances with your short- and long-term goals. Based on a tax analysis, we will craft a plan to transition your assets to your target allocation, regardless of whether the assets are managed by Compound, elsewhere, or self-managed. We can also help manage the execution of that transition.`}
                />
              </Text>
              <EditablePerson
                propertyKey="financialAdvisor"
                propertyValue={financialAdvisor}
                person={financialAdvisorPerson}
                size="small"
              />
            </Col>
          </Col>

          <Col css={{ display: 'none', '@bp1': { display: 'flex' } }}>
            <Box
              as="img"
              src={TargetAssetAllocationPieChart}
              alt="Pie chart"
              css={{ w: '100%', alignSelf: 'center', '@bp1': { w: '80%' } }}
            />
          </Col>
        </Grid>
      </Col>
    </Container>
  );
}

export default TargetAssetAllocation;
