enum AssetActionTypes {
  FETCH_TRANSACTIONS = 'ASSETS/FETCH_TRANSACTIONS',
  FETCH_HOLDINGS = 'ASSETS/FETCH_HOLDINGS',
  SENDING_REQUEST = 'ASSETS/SENDING_REQUEST',
  COMPLETE_REQUEST = 'ASSETS/COMPLETE_REQUEST',
  SYNC_PLAID_ACCOUNT_TOKEN = 'ASSETS/SYNC_PLAID_ACCOUNT_TOKEN',
  SYNC_YODLEE_ACCOUNT_TOKEN = 'ASSETS/SYNC_YODLEE_ACCOUNT_TOKEN',
  REMOVE_PLAID_INSTITUTION = 'ASSETS/REMOVE_PLAID_INSTITUTION',
  REMOVE_PRODUCT = 'ASSETS/REMOVE_PRODUCT',
  UPDATE_ASSETS_STATE_FIELD = 'ASSETS/UPDATE_ASSETS_STATE_FIELD',
  UPDATE_ASSETS_STATE = 'ASSETS/UPDATE_ASSETS_STATE',
  CreateAsset = 'Assets/CreateAsset',
  GetAssets = 'Assets/GetAssets',
  UpdateAsset = 'Assets/UpdateAsset',
  DeleteAsset = 'Assets/DeleteAsset',
  SetAssetModalOpen = 'Assets/SetAssetModalOpen',
  SetAddAssetModalOpen = 'Assets/SetAddAssetModalOpen',
  SetOwnerManagementModalOpen = 'Assets/SetOwnerManagementModalOpen',
  SetViewAssetModalOpen = 'Assets/SetViewAssetModalOpen',
  SetViewCompanyEquityModalOpen = 'Assets/SetViewCompanyEquityModalOpen',
  SetCSVModal = 'Assets/SetCSVModal',
  SetMxModal = 'Assets/SetMxModal',
  SetCurrentAsset = 'Assets/SetCurrentAsset',
  SyncPlaidData = 'Assets/SyncPlaidData',
  UpdateAccountSnapshot = 'Assets/UpdateAccountSnapshot',
  RESOLVE_REQUEST = 'ASSETS/RESOLVE_REQUEST',
  REJECT_REQUEST = 'ASSETS/REJECT_REQUEST',
  UPDATE_NET_WORTH = 'ASSETS/UPDATE_NET_WORTH',
  UPDATE_ACCOUNT_FILTERS = 'APP/UPDATE_ACCOUNT_FILTERS',
  SYNC_ASSET_FILTERS = 'APP/SYNC_ASSET_FILTERS',
  BATCH_UPDATE_ACCOUNT_FILTERS = 'APP/BATCH_UPDATE_ACCOUNT_FILTERS',
  BATCH_UPDATE_PE_ACCOUNT_REQUEST = 'INTEGRATIONS/BATCH_UPDATE_PE_ACCOUNT_REQUEST',
  SET_ACTIVE_PE_INTEGRATION = 'INTEGRATIONS/SET_ACTIVE_PE_INTEGRATION',
  UPDATE_GRANT_REQUEST = 'INTEGRATIONS/UPDATE_GRANT_REQUEST',
  SET_PE_ACCOUNTS = 'INTEGRATIONS/SET_PE_ACCOUNTS',
  REMOVE_PE_ACCOUNT_REQUEST = 'INTEGRATIONS/REMOVE_PE_ACCOUNT_REQUEST',
  REMOVE_PE_ACCOUNT = 'INTEGRATIONS/REMOVE_PE_ACCOUNT',
  CLEAR_PE_ACCOUNT_STATE = 'INTEGRATIONS/CLEAR_PE_ACCOUNT_STATE',
  DELETE_GRANT_REQUEST = 'INTEGRATIONS/DELETE_GRANT_REQUEST',
  CREATE_GRANT_REQUEST = 'INTEGRATIONS/CREATE_GRANT_REQUEST',
  CREATE_PE_ACCOUNT_REQUEST = 'INTEGRATIONS/CREATE_PE_ACCOUNT_REQUEST',
  DELETE_PE_ACCOUNT_REQUEST = 'INTEGRATIONS/DELETE_PE_ACCOUNT_REQUEST',
  BATCH_DELETE_PE_ACCOUNT_REQUEST = 'INTEGRATIONS/BATCH_DELETE_PE_ACCOUNT_REQUEST',
  MERGE_ACTIVE_PE_ACCOUNT = 'INTEGRATIONS/MERGE_ACTIVE_PE_ACCOUNT',
  UPDATE_PE_ACCOUNT_REQUEST = 'SETTINGS/UPDATE_PE_ACCOUNT_REQUEST',
  UPDATE_PE_ACCOUNT = 'SETTINGS/UPDATE_PE_ACCOUNT',
  UPDATE_ACTIVE_PE_ACCOUNT = 'SETTINGS/UPDATE_ACTIVE_PE_ACCOUNT',
  CREATE_ELECTION = 'INTEGRATIONS/CREATE_ELECTION',
  DELETE_ELECTION = 'INTEGRATIONS/DELETE_ELECTION',
  UPDATE_CERTIFICATE_ELECTION = 'INTEGRATIONS/UPDATE_CERTIFICATE_ELECTION',
  DELETE_CERTIFICATE_ELECTION = 'INTEGRATIONS/DELETE_CERTIFICATE_ELECTION',
  SET_REFINERY_METADATA = 'INTEGRATIONS/SET_REFINERY_METADATA',
}

export default AssetActionTypes;
