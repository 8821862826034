export const not =
  (fn: Function) =>
  (...args: any[]) =>
    !fn(...args);

const EPSILON = 1 * 10 ** -9;
export const isApproximately = ({
  value,
  target,
}: {
  value: number;
  target: number;
}) => {
  return value <= target + EPSILON && value >= target - EPSILON;
};
