import {
  AssetCategoryName,
  AssetCategorySlug,
  ASSET_CATEGORY_NAME_TO_SLUG,
  ASSET_CATEGORY_SLUG_TO_NAME,
  ASSET_CATEGORY_SLUG_TO_SHORT_NAME,
  ASSET_CATEGORY_SLUG_TO_DISPLAY_NAME,
} from 'domain/AssetCategory/constants';

export namespace AssetAllocationCategoryTypes {
  type BaseAssetCategory = {
    slug: AssetCategorySlug;
    name: AssetCategoryName;
  };

  export type AssetCategory = BaseAssetCategory & {
    id: string;
  };

  export type EnhancedAssetCategory = BaseAssetCategory & {
    annualReturn?: number;
    annualStandardDeviations?: number;
  };
}

const slug = (
  assetCategory: Pick<AssetAllocationCategoryTypes.AssetCategory, 'name'>,
) => ASSET_CATEGORY_NAME_TO_SLUG[assetCategory.name];

const displayName = (
  assetCategory: Pick<AssetAllocationCategoryTypes.AssetCategory, 'slug'>,
) => ASSET_CATEGORY_SLUG_TO_DISPLAY_NAME[assetCategory.slug];

const name = (
  assetCategory: Pick<AssetAllocationCategoryTypes.AssetCategory, 'slug'>,
) => ASSET_CATEGORY_SLUG_TO_NAME[assetCategory.slug];

const shortName = (
  assetCategory: Pick<AssetAllocationCategoryTypes.AssetCategory, 'slug'>,
) => ASSET_CATEGORY_SLUG_TO_SHORT_NAME[assetCategory.slug];

const annualReturn = (
  assetCategory: AssetAllocationCategoryTypes.EnhancedAssetCategory,
) => assetCategory.annualReturn ?? 0;

const annualStandardDeviations = (
  assetCategory: AssetAllocationCategoryTypes.EnhancedAssetCategory,
) => assetCategory.annualStandardDeviations ?? 1;

export const AssetAllocationCategory = {
  annualReturn,
  annualStandardDeviations,
  displayName,
  name,
  shortName,
  slug,
};
