import { AssetTypes } from '@compoundfinance/compound-core/dist/types/account';
import { ValueOf } from 'types';

export const AssetSource = {
  PlaidAccount: 'plaidAccount',
  Security: 'security',
  Holding: 'holding',
  PrivateEquityAccount: 'privateEquityAccount',
  LimitedPartnership: 'lpExposure',
  GeneralPartnership: 'gpExposure',
  PrivateInvestment: 'privateInvestment',
  RealEstate: 'realEstate',
  Other: 'otherAsset',
  NFT: 'nft',
  DefiAccount: 'defiAccount',
  Loan: 'loan',
  OtherLiability: 'otherLiability',
} as const;
export type AssetSource = ValueOf<typeof AssetSource>;

export const AssetSourceUtils = {
  fromAssetType: (assetType: AssetTypes) => {
    if (assetType === AssetTypes.Other) return AssetSource.Other;
    return assetType;
  },
};
