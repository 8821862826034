import omit from 'lodash/omit';
import { ValueOf } from 'types';

import { AssetSource } from 'domain/AssetSource';
import { AssetAllocationCategorization } from 'domain/AssetCategorization/AssetCategorization';
import { AssetCategorySlug } from 'domain/AssetCategory/constants';

import { AllocationAsset } from '../../Accounts/AssetAllocation/AllocationExplorer';
import { ASSET_CATEGORY } from '../constants';
import { AllocationProposalStrategy } from './AllocationProposalStrategy';
import { AssetAllocationAsset } from './AssetAllocationAsset';
import { NumberUtils } from '../../../../utils/NumberUtils';
import { FakeAllocationAsset, SimplifyOriginalAsset } from '../utils/types';

import { TransitionTypes } from './Transition';
import { Asset } from './Asset';
import { StrategicAllocationProposalStrategy } from './Strategic/StrategicAllocationProposalStrategy';
import { AssetAllocationCategory } from './AssetAllocationCategory';
import { PLAID_ACCOUNT_TYPES } from 'shared/plaid/constants';

export namespace AllocationMessageTypes {
  export type AllocationMessage = {
    id?: string;
    assetSource: AssetAllocationAssetType;
    assetId: string;
    assetCategorySlug: AssetCategorySlug;
    weight: number;
    isOmitted: boolean;
  };
}

export namespace AllocationTypes {
  export type Allocation = CategoryAllocation | ClientAllocation;

  export type BaseAllocation = {
    id?: string;
    assetCategorySlug: AssetCategorySlug;
    name: string;
    slug: string;
    weight: number;
    value: number;
    allocationType: AllocationType;
    assetSource: AssetAllocationAssetType;
    assetId: string;
  };
  export type CategoryAllocation = BaseAllocation & {
    slug: AssetCategorySlug;
    allocationType: 'ASSET_CATEGORY';
    assetSource: 'assetCategory';
  };
  export type BaseClientAllocation = BaseAllocation & {
    assetSource: Exclude<AssetAllocationAssetType, 'assetCategory'>;
    asset: SimplifyOriginalAsset<AllocationAsset> | FakeAllocationAsset;
    assetAllocation: Record<AssetCategorySlug, CategoryAllocation>;
    isOmitted: boolean;
  };
  export type ClientAccountAllocation = BaseClientAllocation & {
    allocationType: 'ACCOUNT';
    assetSource: 'plaidAccount';
  };
  export type ClientAssetAllocation = BaseClientAllocation & {
    allocationType: 'ASSET';
    assetSource: Exclude<AssetAllocationAssetType, 'assetCategory'>;
    account: SimplifyOriginalAsset<AllocationAsset> | null;
  };
  export type ClientAllocation =
    | ClientAccountAllocation
    | ClientAssetAllocation;
}

export const ALLOCATION_TYPES = {
  ASSET_CATEGORY: 'ASSET_CATEGORY',
  ACCOUNT: 'ACCOUNT',
  ASSET: 'ASSET',
} as const;
type AllocationType = ValueOf<typeof ALLOCATION_TYPES>;

export const ASSET_ALLOCATION_ASSET_TYPES = {
  ...omit(AssetSource, ['Loan', 'OtherLiability']),
  AssetCategory: 'assetCategory',
} as const;
type AssetAllocationAssetType = ValueOf<typeof ASSET_ALLOCATION_ASSET_TYPES>;

const ASSET_ALLOCATION_ASSET_TYPES_TO_ALLOCATION_TYPES: Record<
  AssetAllocationAssetType,
  AllocationType
> = {
  [ASSET_ALLOCATION_ASSET_TYPES.AssetCategory]: ALLOCATION_TYPES.ASSET_CATEGORY,
  [ASSET_ALLOCATION_ASSET_TYPES.PlaidAccount]: ALLOCATION_TYPES.ACCOUNT,
  [ASSET_ALLOCATION_ASSET_TYPES.DefiAccount]: ALLOCATION_TYPES.ACCOUNT,
  [ASSET_ALLOCATION_ASSET_TYPES.PrivateEquityAccount]: ALLOCATION_TYPES.ASSET,
  [ASSET_ALLOCATION_ASSET_TYPES.NFT]: ALLOCATION_TYPES.ASSET,
  [ASSET_ALLOCATION_ASSET_TYPES.Holding]: ALLOCATION_TYPES.ASSET,
  [ASSET_ALLOCATION_ASSET_TYPES.Security]: ALLOCATION_TYPES.ASSET,
  [ASSET_ALLOCATION_ASSET_TYPES.Other]: ALLOCATION_TYPES.ASSET,
  [ASSET_ALLOCATION_ASSET_TYPES.RealEstate]: ALLOCATION_TYPES.ASSET,
  [ASSET_ALLOCATION_ASSET_TYPES.LimitedPartnership]: ALLOCATION_TYPES.ASSET,
  [ASSET_ALLOCATION_ASSET_TYPES.GeneralPartnership]: ALLOCATION_TYPES.ASSET,
  [ASSET_ALLOCATION_ASSET_TYPES.PrivateInvestment]: ALLOCATION_TYPES.ASSET,
} as const;

const Constants = {
  ALLOCATION_TYPES,
  ASSET_ALLOCATION_ASSET_TYPES_TO_ALLOCATION_TYPES,
};

const isCategoryAllocation = (
  allocation: Pick<AllocationTypes.Allocation, 'allocationType'>,
): allocation is AllocationTypes.CategoryAllocation =>
  allocation.allocationType === 'ASSET_CATEGORY';

const isClientAssetAllocation = (
  allocation: Pick<AllocationTypes.Allocation, 'allocationType'>,
): allocation is AllocationTypes.ClientAssetAllocation =>
  allocation.allocationType === 'ASSET';

const isClientAccountAllocation = (
  allocation: Pick<AllocationTypes.Allocation, 'allocationType'>,
): allocation is AllocationTypes.ClientAccountAllocation =>
  allocation.allocationType === 'ACCOUNT';

const isClientAllocation = (
  allocation: Pick<AllocationTypes.Allocation, 'allocationType'>,
): allocation is AllocationTypes.ClientAllocation =>
  isClientAccountAllocation(allocation) || isClientAssetAllocation(allocation);

const isAccountWithAssets = (allocation: AllocationTypes.ClientAllocation) => {
  const {
    asset: { originalAsset },
  } = allocation;
  const hasOriginalAsset = !!originalAsset;
  if (!hasOriginalAsset) return false;
  if (
    ![AssetSource.PlaidAccount, AssetSource.DefiAccount].includes(
      allocation.assetSource as
        | typeof AssetSource.PlaidAccount
        | typeof AssetSource.DefiAccount,
    )
  )
    return false;
  return [PLAID_ACCOUNT_TYPES.INVESTMENT, PLAID_ACCOUNT_TYPES.CRYPTO].includes(
    originalAsset.type as
      | typeof PLAID_ACCOUNT_TYPES.INVESTMENT
      | typeof PLAID_ACCOUNT_TYPES.CRYPTO,
  );
};

const allocationType = (allocation: AllocationTypes.Allocation) =>
  allocation.allocationType;

const isOmitted = (allocation: AllocationTypes.ClientAllocation) =>
  !!allocation.isOmitted;

const slug = (allocation: Pick<AllocationTypes.Allocation, 'slug'>) =>
  allocation.slug;

const weight = (allocation: Pick<AllocationTypes.Allocation, 'weight'>) =>
  allocation.weight ?? 0;

const value = (allocation: Pick<AllocationTypes.Allocation, 'value'>) =>
  allocation.value ?? 0;

const computeValue = (
  allocation: Pick<AllocationTypes.Allocation, 'weight'>,
  networth: number,
) => NumberUtils.percentageFromValue(weight(allocation), networth);

const updateWeightAndValue = (
  allocation: AllocationTypes.Allocation,
  {
    weight,
    networth,
  }: { weight: AllocationTypes.Allocation['weight']; networth: number },
) => ({
  ...allocation,
  weight: Allocation.weight({ weight }),
  value: computeValue({ weight: Allocation.weight({ weight }) }, networth),
});

const hasWeight = (allocation: Pick<AllocationTypes.Allocation, 'weight'>) =>
  weight(allocation) > 0;

const displayName = (allocation: AllocationTypes.Allocation) => {
  const { name } = allocation;
  if (Allocation.isCategoryAllocation(allocation))
    return AssetAllocationCategory.displayName(allocation);
  if (Allocation.isClientAccountAllocation(allocation)) return name;
  if (Allocation.isClientAssetAllocation(allocation)) {
    const { account } = allocation;
    const hasAccount = !!account;
    if (!hasAccount) return name;
    return `${name} (from ${account.name})`;
  }
  throw new Error('Unreachable');
};

const name = (allocation: AllocationTypes.Allocation) => allocation.name;

const assetAllocation = (
  clientAllocation: Pick<AllocationTypes.ClientAllocation, 'asset'>,
) => {
  const { asset: allocationAsset } = clientAllocation;
  const {
    assetCategorization: {
      categorizations = [
        { assetCategory: { slug: 'other-assets' }, share: 100 },
      ],
    } = {},
  } = allocationAsset;
  return AllocationProposalStrategy.toCategorized(
    categorizations.map(Allocation.fromCategorization),
  );
};

const accountAllocation = (
  clientAllocation: Pick<AllocationTypes.ClientAccountAllocation, 'asset'>,
  holdings: SimplifyOriginalAsset<AllocationAsset>[],
) => {
  const holdingsAsAllocations = holdings.map((holding) => {
    const assetAllocation = Allocation.assetAllocation({ asset: holding });
    return AllocationProposalStrategy.rebalanceTo(holding.weight ?? 0)(
      assetAllocation,
    );
  });
  const { asset: allocationAsset } = clientAllocation;
  const accountAsAllocation = AllocationProposalStrategy.rebalanceTo(
    allocationAsset.weight ?? 0,
  )(Allocation.assetAllocation({ asset: allocationAsset }));
  return AllocationProposalStrategy.rebalanceTo100(
    StrategicAllocationProposalStrategy.merge([
      ...holdingsAsAllocations,
      accountAsAllocation,
    ]),
  );
};

const fromMany = (
  clientAllocations: AllocationTypes.ClientAllocation[],
): AllocationTypes.ClientAssetAllocation => {
  const value = clientAllocations.reduce(
    (value, clientAllocation) =>
      NumberUtils.add(value, Allocation.value(clientAllocation)),
    0,
  );
  const weight = clientAllocations.reduce(
    (weight, clientAllocation) =>
      NumberUtils.add(weight, Allocation.weight(clientAllocation)),
    0,
  );
  return {
    name: `${clientAllocations.length} other assets`,
    slug: clientAllocations
      .map((clientAllocation) => clientAllocation.slug)
      .join('#'),
    allocationType: ALLOCATION_TYPES.ASSET,
    assetCategorySlug: 'other-assets',
    assetId: '',
    assetSource: AssetSource.Other,
    value,
    weight,
    assetAllocation: AllocationProposalStrategy.rebalanceTo100(
      AllocationProposalStrategy.toAssetCategories(
        AllocationProposalStrategy.toCategorized(clientAllocations),
      ),
    ) as AllocationTypes.ClientAllocation['assetAllocation'],
    isOmitted: clientAllocations.every(isOmitted),
    asset: {
      name: `${clientAllocations.length} other assets`,
      value,
      weight,
      assetCategorySlug: 'other-assets',
      assetCategorization: {
        categorizations: [
          { assetCategory: { slug: 'other-assets' }, share: 100 },
        ],
      },
      originalAsset: undefined,
    },
    account: null,
  };
};

const fromAllocationAsset =
  (assets: AllocationAsset[]) =>
  (asset: AllocationAsset): AllocationTypes.ClientAllocation => {
    const { id: assetId, assetSource, weight, value } = asset;
    const allocationType = ASSET_ALLOCATION_ASSET_TYPES_TO_ALLOCATION_TYPES[
      assetSource
    ] as AllocationTypes.ClientAllocation['allocationType'];
    const holdings = assets
      .filter((asset) => asset.assetSource === AssetSource.Holding)
      .filter((asset) => asset.originalAsset.plaidAccountId === asset.id);
    return {
      allocationType,
      assetId,
      assetSource,
      name: AssetAllocationAsset.name({
        ...asset,
        allocationType,
      }),
      slug: AssetAllocationAsset.slug({
        ...asset,
        allocationType,
      }),
      assetCategorySlug: AssetAllocationAsset.assetCategorySlug({
        ...asset,
        allocationType,
      }),
      weight: weight!,
      value: value,
      asset,
      ...(isClientAssetAllocation({
        allocationType,
      } as AllocationTypes.Allocation) && {
        account: assets.find(
          (account) => account.id === asset.originalAsset?.plaidAccountId,
        ),
        assetAllocation: assetAllocation({ asset }),
      }),
      ...(isClientAccountAllocation({
        allocationType,
      } as AllocationTypes.Allocation) && {
        assetAllocation: accountAllocation({ asset }, holdings),
      }),
    } as AllocationTypes.ClientAllocation;
  };

const fromTransition = (
  transition: TransitionTypes.Transition,
): AllocationTypes.Allocation =>
  /** @ts-ignore */
  omit(transition, ['investmentTarget']);

const fromCategorization = (
  categorization: AssetAllocationCategorization,
): AllocationTypes.CategoryAllocation => {
  const { share, assetCategory } = categorization;
  const { slug, title } = assetCategory;
  return {
    allocationType: 'ASSET_CATEGORY',
    assetId: slug,
    assetSource: 'assetCategory',
    name: title,
    slug: slug,
    assetCategorySlug: slug,
    weight: share,
    value: 0,
  };
};

const fromMessage =
  ({
    assetCategories,
    assets,
    networth,
  }: {
    assetCategories: ASSET_CATEGORY[];
    assets: AllocationAsset[];
    networth: number;
  }) =>
  (
    allocationMessage: AllocationMessageTypes.AllocationMessage,
  ): AllocationTypes.Allocation => {
    const lookup = {
      [Allocation.Constants.ALLOCATION_TYPES.ASSET_CATEGORY]: assetCategories,
      [Allocation.Constants.ALLOCATION_TYPES.ACCOUNT]: assets,
      [Allocation.Constants.ALLOCATION_TYPES.ASSET]: assets,
    } as const;
    const {
      id,
      assetId,
      assetSource,
      assetCategorySlug: originalAssetCategorySlug,
      weight,
      isOmitted,
    } = allocationMessage;
    const allocationType =
      ASSET_ALLOCATION_ASSET_TYPES_TO_ALLOCATION_TYPES[assetSource];
    const allocationTypeLookup = lookup[allocationType];
    const asset =
      /** @ts-ignore */
      allocationTypeLookup.find((item) => {
        if (allocationType === ALLOCATION_TYPES.ASSET_CATEGORY) {
          return (item as ASSET_CATEGORY).slug === assetId;
        }
        return (
          ((item as AllocationAsset).assetSource === assetSource &&
            (item as AllocationAsset).id === assetId &&
            (item as AllocationAsset).assetCategorySlug ===
              originalAssetCategorySlug) ||
          ((item as AllocationAsset).assetSource === assetSource &&
            (item as AllocationAsset).id === assetId)
        );
      }) ?? {
        name: 'Deleted asset',
        weight,
        value: computeValue(allocationMessage, networth),
        assetCategorySlug: originalAssetCategorySlug,
        assetCategorization: {
          categorizations: [
            { assetCategory: { slug: originalAssetCategorySlug }, share: 100 },
          ],
        },
        originalAsset: undefined,
      };
    const holdings = assets
      .filter((asset) => asset.assetSource === AssetSource.Holding)
      .filter((asset) => asset.originalAsset.plaidAccountId === assetId);
    return {
      id,
      allocationType,
      name: AssetAllocationAsset.name({
        ...asset,
        allocationType,
      }),
      slug: AssetAllocationAsset.slug({
        ...asset,
        allocationType,
      }),
      value: computeValue(allocationMessage, networth),
      weight,
      assetCategorySlug: AssetAllocationAsset.assetCategorySlug({
        ...asset,
        allocationType,
      }),
      assetSource,
      assetId,
      ...(isClientAllocation({
        allocationType,
      } as AllocationTypes.Allocation) && {
        asset,
        isOmitted,
      }),
      ...(isClientAssetAllocation({
        allocationType,
      } as AllocationTypes.Allocation) && {
        assetAllocation: assetAllocation({ asset }),
        account: assets.find(
          (account) => account.id === asset.originalAsset?.plaidAccountId,
        ),
      }),
      ...(isClientAccountAllocation({
        allocationType,
      } as AllocationTypes.Allocation) && {
        assetAllocation: accountAllocation({ asset }, holdings),
      }),
    } as AllocationTypes.Allocation;
  };

const toMessage = (
  allocation: AllocationTypes.Allocation,
): AllocationMessageTypes.AllocationMessage => {
  const weight = Allocation.weight(allocation);
  if (isCategoryAllocation(allocation)) {
    const {
      allocationType,
      assetCategorySlug,
      value,
      name,
      slug,
      ...allocationMessage
    } = allocation;
    return {
      ...allocationMessage,
      assetId: assetCategorySlug,
      assetCategorySlug,
      weight,
      isOmitted: false,
    };
  }
  if (isClientAssetAllocation(allocation)) {
    const {
      allocationType,
      asset,
      value,
      name,
      slug,
      assetCategorySlug,
      assetAllocation,
      account,
      isOmitted,
      ...allocationMessage
    } = allocation;
    return {
      ...allocationMessage,
      assetCategorySlug,
      weight,
      isOmitted,
    };
  }
  if (isClientAccountAllocation(allocation)) {
    const {
      allocationType,
      asset,
      value,
      name,
      slug,
      assetCategorySlug,
      assetAllocation,
      isOmitted,
      ...allocationMessage
    } = allocation;
    return {
      ...allocationMessage,
      assetCategorySlug,
      weight,
      isOmitted,
    };
  }
  throw new Error('Unreachable', { cause: allocation });
};

const toInvestmentProposal = <
  AllocationType extends AllocationTypes.Allocation,
>(
  allocation: AllocationType,
) => {
  if (!isClientAllocation(allocation)) return allocation;
  const { asset } = allocation;
  const { originalAsset } = asset;
  const hasOriginalAsset = !!originalAsset;
  if (isClientAccountAllocation(allocation))
    return {
      ...allocation,
      asset: {
        ...asset,
        originalAsset: hasOriginalAsset
          ? Asset.simplifyOriginalAsset(originalAsset)
          : undefined,
      },
    };
  if (isClientAssetAllocation(allocation))
    return {
      ...allocation,
      asset: {
        ...asset,
        originalAsset: hasOriginalAsset
          ? Asset.simplifyOriginalAsset(originalAsset)
          : undefined,
      },
      account: null,
    };
  throw new Error('Unreachable', { cause: allocation });
};

export const Allocation = {
  Constants,
  assetAllocation,
  accountAllocation,
  allocationType,
  computeValue,
  displayName,
  fromAllocationAsset,
  fromCategorization,
  fromMany,
  fromMessage,
  fromTransition,
  hasWeight,
  isCategoryAllocation,
  isClientAllocation,
  isClientAssetAllocation,
  isClientAccountAllocation,
  isAccountWithAssets,
  isOmitted,
  name,
  slug,
  toMessage,
  toInvestmentProposal,
  updateWeightAndValue,
  value,
  weight,
};
