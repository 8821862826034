import { Group } from '@visx/group';

import { Ticks, TicksAndTocksProps, Tocks } from './TicksAndTocks';

export const SquareGrid = ({
  points,
  styles,
  ...props
}: Omit<TicksAndTocksProps, 'shouldDisplay'>) => {
  return (
    <Group>
      <Ticks
        {...props}
        points={points}
        shouldDisplay={({ index }) => index % 5 === 0 || index === points - 1}
        styles={{ strokeDasharray: '6 8', ...styles }}
      />
      <Tocks
        {...props}
        points={points}
        styles={{ strokeDasharray: '6 8', ...styles }}
        shouldDisplay={({ index }) => index % 5 === 0 || index === points - 1}
      />
    </Group>
  );
};
