/**
 * Gets previous toast close dates for a group and checks if the id is still the same.
 * If the same announcement is closed 2 times a month,
 * this returns true. If the id changes, the dates are reset.
 *
 * @param {string} group toast type, used as a key in localStorage
 * @param {string} id unqiue name for toast content
 */
export const isToastClosedTwiceInThirtyDays = (group: string, id: string) => {
  let strDates: string[];

  try {
    const { dates, id: itemId }: { dates: string[]; id: string } = JSON.parse(
      localStorage.getItem(group)!,
    );
    strDates = dates;
    if (itemId !== id) {
      addLocalStorage(group, { id, dates: [] });
      return false;
    }
  } catch {
    // the item is not in the local storage
    return false;
  }
  const dates = strDates.map((dateStr) => new Date(dateStr));
  const currentDateTime = new Date().getTime();
  const thirtyDaysMilliseconds = 1000 * 60 * 60 * 24 * 30;
  const datesWithinThirtyDays = dates.filter((date) => {
    const diff = currentDateTime - date.getTime();
    return diff < thirtyDaysMilliseconds;
  });
  return datesWithinThirtyDays.length > 1;
};

/**
 * Saves new date to localStorage using group as a key
 *
 * @param {string} group toast type, used as a key in localStorage
 * @param {string} id unqiue name for toast content
 */
export const storeToastCancel = (group: string, id: string) => {
  try {
    const { id: itemId, dates } = JSON.parse(localStorage.getItem(group)!);
    if (itemId === id) {
      addLocalStorage(group, {
        id,
        dates: [...dates.slice(-1), new Date()],
      });
      return;
    }
  } catch {
    // the item is not in the local storage
  }
  addLocalStorage(group, { id, dates: [new Date()] });
};

const addLocalStorage = (
  group: string,
  data: { id: string; dates: Date[] },
) => {
  localStorage.setItem(group, JSON.stringify(data));
};
