import axios, { AxiosHeaders, InternalAxiosRequestConfig } from 'axios';
import { useRef } from 'react';
import { getAdminHeader, getAuthHeader } from 'utils/api';
import { API_URL } from 'utils/api/constants';
import useTypedSelector from './typedSelector';
import useIsClient from './useIsClient';
import { omit } from 'lodash';

/**
 * Disclaimer:
 * I'm afraid this might not be concurrent-mode safe; although if it wasn't, the impact should be fairly low.
 * The reason we decided to set the interceptors during `render` is because doing it in an `effect` would,
 * in some occasions, be too late, since `child`'s effects are run earlier than parent's.
 */

export function useForwardUserId() {
  const userId = useTypedSelector((state) => state.global.user?.id);
  const adminId = useTypedSelector((state) => state.admin?.adminUser?.id);
  const shouldForwardUserId = userId !== adminId;
  const isClient = useIsClient();

  const interceptorIdRef = useRef<number>();

  if (interceptorIdRef.current) {
    axios.interceptors.request.eject(interceptorIdRef.current);
  }

  interceptorIdRef.current = axios.interceptors.request.use((requestConfig) => {
    const { params = {}, url = '' } = requestConfig;
    const isRequestToOurAPI = url.includes(API_URL);

    if (params.noForwardedId) {
      return { ...requestConfig, params: omit(params, 'noForwardedId') };
    }
    if (isClient) return requestConfig;
    if (!shouldForwardUserId) return requestConfig;
    if (!userId) return requestConfig;
    if (!isRequestToOurAPI) return requestConfig;

    return {
      ...requestConfig,
      params: {
        userId,
        /** Notice we leave the option open for overwriting the `userId` by setting your own from the request handler */
        ...requestConfig?.params,
      },
    };
  });
}

export function useAuthentication() {
  const isClient = useIsClient();

  const interceptorIdRef = useRef<number>();

  if (interceptorIdRef.current) {
    axios.interceptors.request.eject(interceptorIdRef.current);
  }

  interceptorIdRef.current = axios.interceptors.request.use(
    (requestConfig: InternalAxiosRequestConfig<any>) => {
      const { headers = new AxiosHeaders(), url = '' } = requestConfig;
      const isRequestToOurAPI = url.includes(API_URL);
      if (!isRequestToOurAPI) return requestConfig;

      const additionalHeaders = new AxiosHeaders(
        isClient ? getAuthHeader() : getAdminHeader(),
      );

      return {
        ...requestConfig,
        // concat the original headers on to the `additionalHeaders` so the original values win.
        headers: additionalHeaders.concat(headers),
      };
    },
  );
}
