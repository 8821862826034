import EditablePerson from 'containers/Dashboard/Document/components/EditablePerson';
import EditableTextArea from 'containers/Dashboard/Document/components/EditableTextArea';
import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import { EditableSectionTitle } from 'containers/Dashboard/Document/components/SectionTitle';
import { getPerson } from 'containers/Dashboard/Document/utils';
import { Col } from '@compoundfinance/design-system';
import { DirectoryPerson } from 'domain/Employees/constants';
import { IFinancialAdvisor } from 'domain/Employees/types';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';

const Container = styled(ModuleContainer, {
  p: '$24',
  h: '100%',

  '@bp1': {
    py: '$44',
    px: '$48',
  },
});

export interface InsightsProps {
  description: string;
  financialAdvisor: DirectoryPerson;
  title: string;
}

function Insights(props: InsightsProps) {
  const { description, financialAdvisor, title } = props;
  const financialAdvisorPerson = getPerson(
    financialAdvisor,
  ) as IFinancialAdvisor;

  return (
    <Container>
      <Col css={{ gap: '$24', height: '100%' }}>
        <EditableSectionTitle title={title} />
        <Col css={{ height: '100%', jc: 'space-between' }}>
          <EditableTextArea
            propertyKey="description"
            value={description}
            textCss={{
              fontSize: '$15',
              w: '100%',
            }}
          />
          <EditablePerson
            propertyKey="financialAdvisor"
            propertyValue={financialAdvisor}
            person={financialAdvisorPerson}
            size="small"
            level="document"
          />
        </Col>
      </Col>
    </Container>
  );
}

export default Insights;
