import EditablePerson from 'containers/Dashboard/Document/components/EditablePerson';
import EditableTextArea from 'containers/Dashboard/Document/components/EditableTextArea';
import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import { getPerson } from 'containers/Dashboard/Document/utils';
import { Text, Col, Badge } from '@compoundfinance/design-system';
import moment from 'moment';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';
import {
  DirectoryPerson,
  DirectoryPersonRole,
} from 'domain/Employees/constants';

const Container = styled(ModuleContainer, {
  display: 'grid',
  py: '$24',
  px: '$24',
  gap: '$24',

  '@bp1': {
    py: '$44',
    px: '$48',
    gridTemplateColumns: '1fr 1px 1fr',
    gap: '$48',
  },
});

const VerticalSplit = styled('div', {
  borderBottom: '1px solid $gray4',

  '@bp1': {
    borderLeft: '1px solid $gray4',
  },
});

export interface IntroductionProps {
  recipient: string;
  salesLead: DirectoryPerson;
  createdAt: Date;
}

function Introduction(props: IntroductionProps) {
  const { recipient, salesLead, createdAt } = props;

  const salesLeadPerson = getPerson(salesLead);

  return (
    <Container>
      <Col css={{ h: '100%', justifyContent: 'space-between', gap: '$24' }}>
        <Col css={{ gap: '$12' }}>
          <Text
            weight="medium"
            css={{
              fontSize: '$40',
              letterSpacing: '-0.8px',

              '@bp1': { fontSize: '$48', lineHeight: '58px' },
            }}
          >
            Introduction to Compound
          </Text>
          <Text size="20" color="gray10" css={{ display: 'flex', gap: '$6' }}>
            for
            <EditableTextArea propertyKey="recipient" value={recipient}>
              {recipient}
            </EditableTextArea>
          </Text>
        </Col>
      </Col>

      <VerticalSplit />

      <Col css={{ h: '100%', jc: 'space-between' }}>
        <Col css={{ gap: '$16' }}>
          <Text size="14" css={{ w: '100%' }}>
            <EditableTextArea
              propertyKey="introText"
              value={`${
                recipient.split(' ')[0]
              },\n\nBelow is an outline of your advisory team, highlighted areas we will focus on in the near- and long-term, and more details on Compound’s offering.\n\nI am here to go at your pace for potential next steps. Please let me know if you have any questions.\n\n`}
            />
          </Text>
          <EditablePerson
            propertyKey="salesLead"
            propertyValue={salesLead}
            person={salesLeadPerson}
            placeholder="Select Membership Consultant"
            personRole={DirectoryPersonRole.MembershipConsultant}
            size="small"
          />
        </Col>

        <Badge
          size="medium"
          css={{
            bg: 'transparent',
            alignSelf: 'flex-end',
            visibility: createdAt ? 'visible' : 'hidden',
          }}
        >
          Proposal created {moment(createdAt).fromNow()} on{' '}
          {moment(createdAt).format('MMMM DD, YYYY')}
        </Badge>
      </Col>
    </Container>
  );
}

export default Introduction;
