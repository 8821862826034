export enum DocumentModuleGroup {
  MembershipProposal = 'MembershipProposal',
  QuarterlyPerformanceUpdate = 'QuarterlyPerformanceUpdate',
  AssetAllocationInvestmentProposal = 'AssetAllocationInvestmentProposal',
  SalesIntroduction = 'SalesIntroduction',
  TaxTransitionAnalysis = 'TaxTransitionAnalysis',
}

export enum DocumentModuleName {
  // Membership Proposal
  MembershipProposal_Introduction = 'Introduction',
  MembershipProposal_YourTeam = 'YourTeam',
  MembershipProposal_Custom = 'Custom',
  MembershipProposal_CashManagement = 'CashManagement',
  MembershipProposal_Services = 'Services',
  MembershipProposal_Pricing = 'Pricing',
  MembershipProposal_PricingV2 = 'PricingV2',
  MembershipProposal_NetWorth = 'NetWorth',
  MembershipProposal_NextSteps = 'NextSteps',
  MembershipProposal_EquityStrategies = 'EquityStrategies',
  MembershipProposal_TargetAssetAllocation = 'TargetAssetAllocation',
  MembershipProposal_InvestmentManagement = 'InvestmentManagement',
  MembershipProposal_PrivateMarketDeals = 'PrivateMarketDeals',

  // Quarterly Performance Update
  QuarterlyPerformanceUpdate_Introduction = 'Introduction',
  QuarterlyPerformanceUpdate_MarketOverview = 'Market Overview',
  QuarterlyPerformanceUpdate_AccountOverview = 'AccountOverview',
  QuarterlyPerformanceUpdate_Insights = 'Insights',
  QuarterlyPerformanceUpdate_Tax_Loss_Harvesting = 'Tax Loss Harvesting',

  // Asset Allocation Investment Proposal
  AssetAllocationInvestmentProposal_Introduction = 'Introduction',
  AssetAllocationInvestmentProposal_NoTextIntroduction = 'No Text Introduction',
  AssetAllocationInvestmentProposal_InvestmentStrategy = 'Investment Strategy',
  AssetAllocationInvestmentProposal_ImpactSummary = 'Impact Summary',
  AssetAllocationInvestmentProposal_InitialAllocation = 'Initial Allocation',
  AssetAllocationInvestmentProposal_ProposedAllocation = 'Proposed Allocation',
  AssetAllocationInvestmentProposal_AccountMovement = 'Account Movement',
  AssetAllocationInvestmentProposal_CustomIndexing = 'Custom Indexing',
  AssetAllocationInvestmentProposal_Breckinridge = 'Breckinridge',
  AssetAllocationInvestmentProposal_Foundation = 'Foundation',
  AssetAllocationInvestmentProposal_PrivateMarketDeals = 'Private Market Deals',
  AssetAllocationInvestmentProposal_ConcludingThoughts = 'Concluding Thoughts',
  AssetAllocationInvestmentProposal_Disclaimer = 'Disclaimer',

  // Sales Introduction
  SalesIntroduction_Introduction = 'Introduction',
  SalesIntroduction_About = 'About',
  SalesIntroduction_InitialConsultation = 'Initial Consultation',
  SalesIntroduction_VisualizeYourFinances = 'Visualize Your Finances',
  SalesIntroduction_Services = 'Services',
  SalesIntroduction_AdvisoryLandscape = 'Advisory Landscape',
  SalesIntroduction_NextSteps = 'Next Steps',
  SalesIntroduction_Membership = 'Membership',
  SalesIntroduction_MembershipV2 = 'MembershipV2',

  // Tax Transition Analysis
  TaxTransitionAnalysis_Introduction = 'Introduction',
  TaxTransitionAnalysis_Accounts = 'Accounts',
}
