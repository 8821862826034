import { AssetTypes } from '@compoundfinance/compound-core/dist/types/account';
import { FileEntityTypes } from 'utils/file/types';

export const FILTER_ASCENDING = 'asc';
export const FILTER_DESCENDING = 'desc';

export const AssetsDisplayOrder = {
  [AssetTypes.Cash || FileEntityTypes.Cash]: 0,
  [AssetTypes.PublicInvestment || FileEntityTypes.PublicInvestment]: 1,
  [AssetTypes.CompoundInvestAccount ||
  FileEntityTypes.CompoundInvestAccount]: 2,
  [AssetTypes.Crypto || FileEntityTypes.Crypto]: 3,
  [AssetTypes.StartupEquity || FileEntityTypes.PrivateEquityAccount]: 4,
  [AssetTypes.PrivateInvestment || FileEntityTypes.PrivateInvestment]: 5,
  [AssetTypes.CompoundFunds || FileEntityTypes.CompoundFunds]: 5,
  [AssetTypes.FundInvestment || FileEntityTypes.FundInvestment]: 6,
  [AssetTypes.LP || FileEntityTypes.LP]: 6,
  [AssetTypes.GP || FileEntityTypes.GP]: 6,
  [AssetTypes.RealEstate || FileEntityTypes.RealEstate]: 7,
  [AssetTypes.Other || FileEntityTypes.Other]: 8,
  [AssetTypes.Loan || FileEntityTypes.Loan]: 9,
  [AssetTypes.CreditCard || FileEntityTypes.CreditCard]: 10,
  [AssetTypes.OtherLiability || FileEntityTypes.OtherLiability]: 11,
};
