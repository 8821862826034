export enum PrivateMarketDealGoal {
  Diversification = 'diversification',
  Growth = 'growth',
  Income = 'income',
}

export enum PrivateMarketDealClass {
  Venture = 'venture',
  RealEstate = 'realEstate',
  Debt = 'debt',
  HedgeFund = 'hedgeFund',
  PrivateEquity = 'privateEquity',
  DirectOrCoInvestment = 'directOrCoInvestment',
}

export enum PrivateMarketDealFundType {
  CloseEnded = 'close-ended',
  OpenEnded = 'open-ended',
}

type PrivateMarketDealFile = {
  isLink?: boolean;
  label: string;
  url: string;
};

export type PrivateMarketDeal = {
  id: string;
  name: string;
  fundName: string;
  key: string;
  imageUrl: string;
  bannerImageUrl: string;
  description: string;
  goals: PrivateMarketDealGoal[];
  class: PrivateMarketDealClass;
  targetReturnLowerBound: number;
  targetReturnUpperBound: number;
  targetedReturnTooltip?: string;
  lockUpPeriod: string;
  expectedLockupPeriodTooltip?: string;
  minimumInvestment: number;
  minimumInvestmentTooltip?: string;
  liquidity: string;
  liquidityTooltip?: string;
  taxReporting: string;
  taxReportingTooltip?: string;
  fundCloseDate: string;
  fundCloseDateTooltip?: string;
  fundType: PrivateMarketDealFundType;
  marketingFiles: Record<string, PrivateMarketDealFile>;
  investmentHighlights?: string;
  managerProfile?: string;
  overview?: string;
  keyRisks?: string;
  targetReturns?: string;
  portfolioConstruction?: string;
  trackRecord?: string;
  fees?: string;
  disclosure?: string;
};
