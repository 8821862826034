import { Box, Flex, Text } from '@compoundfinance/design-system';
import { Text as VisxText } from '@visx/text';

import AssetUtils from 'utils/assets';
import UiUtils from 'utils/ui';

import { AllocationTypes } from '../domain/Allocation';
import { InvestmentTarget } from '../domain/InvestmentTarget';
import { StrategicAllocationProposalStrategy } from '../domain/Strategic/StrategicAllocationProposalStrategy';
import { StrategicAllocationProposalTypes } from '../domain/Strategic/StrategicAllocationProposal';

import { Root as ChartRoot } from 'components/Charting';
import AccountLogo from 'components/AccountLogo';
import SankeyChart from './Charts/SankeyChart';

const AssetTransitions = ({
  allocationProposal,
}: {
  allocationProposal: StrategicAllocationProposalTypes.CategorizedAllocationProposal;
}) => {
  const { investmentTargets: allocationProposalInvestmentTargets } =
    allocationProposal;

  const investmentTargets = allocationProposalInvestmentTargets.map(
    (allocationProposalInvestmentTarget) => {
      const { investmentTarget } = allocationProposalInvestmentTarget;
      const { transitions } = investmentTarget;
      return {
        ...investmentTarget,
        type: 'INVESTMENT_TARGET',
        value: InvestmentTarget.totalValue(investmentTarget),
        transitions:
          StrategicAllocationProposalStrategy.representationalClientAllocations(
            {
              clientAllocations: transitions,
              numberOfAssets: 10,
            },
          ).map((transition) => ({
            ...transition,
            type: 'TRANSITION',
          })),
      };
    },
  );

  return (
    <Flex
      css={{
        pt: '$40',
        height: 400,
        w: '100%',
        overflowY: 'auto',
      }}
    >
      <Flex css={{ h: '100%', w: '100%' }}>
        <ChartRoot>
          {({ width, height }) => (
            <SankeyChart<
              Omit<AllocationTypes.ClientAllocation, 'id'> & {
                id: string;
                type: 'INVESTMENT_TARGET' | 'TRANSITION';
              }
            >
              data={{
                /** @ts-ignore */
                nodes: investmentTargets.flatMap((investmentTarget) => {
                  return [investmentTarget, ...investmentTarget.transitions];
                }),
                links: investmentTargets.flatMap((investmentTarget) => {
                  return investmentTarget.transitions.map((transition) => {
                    return {
                      source: transition.id,
                      target: investmentTarget.id,
                      value: transition.weight,
                    };
                  });
                }),
              }}
              width={width}
              height={height}
              description={({ node }) => (
                <>
                  {node.depth === 0 && (
                    <foreignObject height={20} width={20} x={-28} y={0}>
                      {node.asset?.originalAsset && (
                        <AccountLogo
                          size={16}
                          fontSize={8}
                          {...AssetUtils.getDefaultAssetImageProps(
                            node.asset.originalAsset as any,
                          )}
                        />
                      )}
                    </foreignObject>
                  )}

                  <foreignObject x={18} y={10} width={300} height={300}>
                    <Text
                      color="gray12"
                      size="13"
                      weight="medium"
                      css={{
                        overflow: 'hidden',
                        width: '16ch',
                        display: 'block',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {node.name}
                    </Text>
                  </foreignObject>

                  <VisxText
                    x={18}
                    y={38}
                    fontSize={13}
                    verticalAnchor="middle"
                    fill="var(--colors-gray10)"
                  >
                    {UiUtils.formatMoney(node.value)}
                  </VisxText>
                </>
              )}
              tooltip={({ node }) => (
                <>
                  <Box
                    css={{
                      display: 'flex',
                      ai: 'center',
                      lineHeight: 1,
                      gap: '$6',
                      color: '$gray13',
                      fontSize: '$13',
                    }}
                  >
                    {node.asset?.originalAsset && (
                      <AccountLogo
                        size={16}
                        {...AssetUtils.getDefaultAssetImageProps(
                          node.asset.originalAsset as any,
                        )}
                      />
                    )}
                    {node.name}
                  </Box>
                  <Box
                    css={{
                      color: '$gray10',
                      mt: '$4',
                      fontSize: '$13',
                    }}
                  >
                    {UiUtils.formatMoney(node.value)}
                  </Box>
                </>
              )}
              color={({ node }) => {
                if (node.depth > 0) return 'black';
                return node.type === 'INVESTMENT_TARGET'
                  ? (UiUtils.getImageVisColor(node.id) as string)
                  : node.type === 'TRANSITION'
                  ? `var(--colors-${UiUtils.getImageVisColor(node.assetId)}9)`
                  : 'black';
              }}
            />
          )}
        </ChartRoot>
      </Flex>
    </Flex>
  );
};

export default AssetTransitions;
