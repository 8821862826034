import { UserRoleType } from 'utils/constants/user';
import useTypedSelector from './typedSelector';

export default function useIsClient() {
  const userRole = useTypedSelector((state) => state.global.user.role);
  const adminRole = useTypedSelector((state) => state.admin.adminUser.role);

  return (
    userRole === UserRoleType.Default &&
    (!adminRole || adminRole === UserRoleType.Default)
  );
}
