import { FundInvestmentType } from 'types/fundInvestments';

export const FundInvestmentTypeLabels = {
  PrivateEquity: 'Private Equity',
  VentureCapital: 'Venture Capital',
  RealEstate: 'Real Estate',
  PrivateCredit: 'Private Credit',
  Hedge: 'Hedge',
  Other: 'Other',
};

export const FundInvestmentTypeOptions = Object.entries(FundInvestmentType).map(
  ([fundKey, value]) => ({
    label: FundInvestmentTypeLabels[fundKey],
    value,
    placeholder: false,
  }),
);

export enum FundInvestmentFields {
  EntityName = 'entityName',
  FirmName = 'firmName',
  FundName = 'fundName',
  Name = 'name',
  Type = 'type',
  Notes = 'notes',
  AssetType = 'assetType',
  CurrentAccountBalance = 'currentBalance',
  CommittedCapital = 'committedCapital',
  LegallySubscribed = 'legallySubscribed',
  LPExposureEvents = 'events',
  FundLaunchYear = 'fundLaunchYear',
  FundSize = 'fundSize',
  ManagementFeeRate = 'managementFeeRate',
  EstimatedCarryChargedPercent = 'estimatedCarryChargedPercent',
  OwnershipPercent = 'ownershipPercent',
  ManagedByCompound = 'managedByCompound',
}

export const FundInvestmentFieldLabels = {
  firmName: 'Firm name',
  fundName: 'Fund name',
  name: 'Fund name',
  type: 'Fund type',
  currentAccountBalance: 'Total value',
  currentBalance: 'Current account balance',
  committedCapital: 'Total commitment',
  legallySubscribed: 'Legally subscribed',
  fundLaunchYear: 'Fund launch year',
  fundSize: 'Fund size',
  managementFeeRate: 'Management fee',
  estimatedCarryChargedPercent: 'Estimated % carry charged',
  ownershipPercent: 'Personal % ownership of GP Pool',
  managedByCompound: 'Alt facilitated by Compound',
};

export const CAPITAL_CALLS_COLUMNS = [
  {
    headers: [
      {
        title: 'Date',
        props: {},
      },
      {
        title: 'Called Amount',
        props: {
          flex: 1.1,
        },
      },
      {
        title: 'From Account',
        props: {
          textAlign: 'left',
          flex: 1.25,
          className: 'ml-base05',
        },
      },
      {
        title: 'Total Called',
        props: {
          textAlign: 'right',
        },
      },
    ],
    columnKey: 'capitalCalls',
  },
];

export const DISTRIBUTIONS_COLUMNS = [
  {
    headers: [
      {
        title: 'Date',
        props: {},
      },
      {
        title: 'Distributed Amount',
        props: {
          flex: 1.1,
        },
      },
      {
        title: 'To Account',
        props: {
          textAlign: 'left',
          flex: 1.25,
          className: 'ml-base05',
        },
      },
      {
        title: 'Total Distributed',
        props: {
          textAlign: 'right',
        },
      },
    ],
    columnKey: 'distributions',
  },
];

export const FUND_INVESTMENT_GLOSSARY = {
  GP_CURRENT_BALANCE:
    'This is the current estimated value of your General Partner position. This value is often updated on a quarterly or yearly basis based on fund performance.',
  ESTIMATED_CARRY_CHARGED_PERCENT:
    "This value is often dependent on the fund's performance. This value can be changed overtime, but for now, enter the most likely value.",
  GP_OWNERSHIP_PERCENT:
    'This is your specific percentage of the General Partner carried interest pool. This is often dependent on the number of other partners in the fund.',
  CALLED_CAPITAL:
    'This is the total amount of money that has been called by the fund from you. Each capital call can be found under the "Capital Calls" tab.',
  UNCALLED_CAPITAL:
    'This is the total amount of money that the fund will still call from you. This is calculated via Committed Capital minus Called Capital.',
  DISTRIBUTED_CAPITAL:
    'This is the total amount of money that has been distributed by the fund back to you. Each distribution can be found under the "Distributions" tab.',
  LP_CURRENT_BALANCE:
    'Current Account Balance is provided by the fund. It represents the value of your called, but not yet distributed capital.',
  LEGALLY_SUBSCRIBED:
    'Please select "Yes" if you have signed a formal commitment to provide this capital to the fund. If you have not formally committed to providing this capital, you can select "No." Funds often require a formal commitment from their Limited Partners to ensure capital can be raised when needed for investment opportunities.',
};
