import { mainTheme } from 'style/theme';

type InvestmentAccount = {
  name: string;
};

const COMPOUND_INTELLIGENT_INVEST_NAME = 'Compound Intelligent Investing';

export const isCompoundIntelligentPortfolio = (account: InvestmentAccount) => {
  return account.name.includes(COMPOUND_INTELLIGENT_INVEST_NAME);
};

export const AssetCategoryColors = {
  Orange: mainTheme.colors.vis_orange,
  Aqua: mainTheme.colors.vis_aqua,
  Purple: mainTheme.colors.vis_purple,
  Magenta: mainTheme.colors.vis_magenta,
  Grey: mainTheme.colors.inactive_grey,
  Green: mainTheme.colors.manualBankAccount,
  PublicInvestment: mainTheme.colors.publicInvestment,
  PrivateInvestment: mainTheme.colors.privateInvestment,
  OtherAssets: mainTheme.colors.other,
};
