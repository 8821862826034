export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const PHONE_NUMBER_MASK = '(***) ***-';

export const getEnumValues = (e: any) => {
  const keys = Object.keys(e);
  return keys.map((k) => e[k as any]); // [0, 1]
};

export const QUERY_PARAM_KEYS = {
  ONBOARDING: 'onboarding',
  // The same key is also used in BE src/controllers/signupCode.ts 2022/05/31
  INVITATION: 'invitation-modal',
};

export enum Links {
  Agreement = 'https://compoundplanning.com/legal/adv',
  ClientRelationshipSummary = 'https://compoundplanning.com/legal/crs',
  FormADV = 'https://compoundplanning.com/legal/adv',
  Legal = 'https://legal.compoundplanning.com',
  Privacy = 'https://legal.compoundplanning.com/privacy-policy',
  Terms = 'https://legal.compoundplanning.com/terms-of-service',
  GeneralDisclosures = 'https://legal.compoundplanning.com/general-disclosures',
  ClientAgreements = 'https://legal.compoundplanning.com/client-agreements',
}

export const E2E_USER_DOMAIN = 'withcompound.robot';
