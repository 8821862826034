import { isEmpty, pickBy, identity } from 'lodash';
import { RedirectState } from './types';

export const getLoginUrl = (state: RedirectState) => {
  const options = pickBy(
    {
      scope:
        'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
      access_type: 'offline',
      response_type: 'code',
      client_id:
        '100515111664-bespr823jq36ph6fp3h3n111rfeaj4jm.apps.googleusercontent.com',
      redirect_uri: `${window.location.origin}/auth`,
      state: !isEmpty(state) ? JSON.stringify(state) : undefined,
    },
    identity,
  ) as Record<string, string>;

  const queryString = new URLSearchParams(options).toString();
  const baseUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
  return `${baseUrl}?${queryString}`;
};
