import * as _ from 'lodash';
import { CompoundAsset } from 'components/EquityManagement/Asset/types';
import { AssetTypes } from '@compoundfinance/compound-core/dist/types/account';
import AssetUtils from '.';
import { AssetTitles } from 'components/EquityManagement/Asset/constants';
import { AssetAccountSection } from 'types/assets/section';
import { PrivateInvestment } from 'types/equity';
import { AssetsDisplayOrder } from 'utils/constants/assets';
import { buildAssetAccountSection } from 'utils/presenters/accountSections';
import { AssetAccountSectionTypes } from '@compoundfinance/compound-core/dist/types/account';
import { FilterType } from 'utils/assetFilters/types';
import { LPExposure } from 'types/fundInvestments';
import { IntegrationType } from 'utils/constants/integration';

type AssetsRecord = {
  [key in AssetTypes]: CompoundAsset[];
};

function getExcludedAccountIds(accounts, filterMap) {
  return accounts
    .filter((a) => filterMap[a.id] === FilterType.Exclude)
    .map((a) => a.id);
}

/**
 * Breakdown into AssetAccountSections.
 * Group assets by type and then conform them to asset section interface (consumed by accounts page).
 * @param assets
 */
const getOtherAssetsSectionBreakdown = (
  assets: CompoundAsset[],
  filterMap?: Record<string, FilterType>,
  includeHiddenAccounts?: boolean,
  splitFundInvestmentAndCompoundAlts: boolean = false,
): AssetAccountSection[] => {
  // Combine LP and GP exposures under 'FundInvestment' virtual type
  const [lpGpExposure, otherAssets] = _.partition(assets, (a) =>
    [AssetTypes.LP, AssetTypes.GP].includes(a.assetType),
  );
  const [fundInvestments, compoundFunds] = _.partition(lpGpExposure, (a) => {
    if (!splitFundInvestmentAndCompoundAlts) {
      return true;
    }
    if (a.assetType === AssetTypes.LP) {
      return (a as LPExposure).provider !== IntegrationType.BlackDiamond;
    }
    return true;
  });

  // Doing this because lodash returns a Dictionary type which breaks our object structure
  const breakdownByType = _.groupBy(
    otherAssets,
    (a) => a.assetType as AssetTypes,
  ) as AssetsRecord;

  const entryBreakdowns = Object.entries(breakdownByType);
  const accountsByType = (
    lpGpExposure.length
      ? [
          ...entryBreakdowns,
          [AssetTypes.FundInvestment as string, fundInvestments],
          [AssetTypes.CompoundFunds as string, compoundFunds],
        ]
      : entryBreakdowns
  ) as [string, CompoundAsset[]][];

  const breakdown = accountsByType.map(([type, listOfAssets]) => {
    const typeToUse =
      type === AssetTypes.Loan ? AssetTypes.OtherLiability : type;

    const accounts = listOfAssets.map((a) => ({
      ...a,
      provider:
        (a as PrivateInvestment).provider || AssetAccountSectionTypes.Manual,
      currentBalance: AssetUtils.getAssetValue(a),
      name: AssetUtils.getAssetName(a),
    }));

    const excluded = filterMap
      ? getExcludedAccountIds(listOfAssets, filterMap)
      : [];
    return buildAssetAccountSection({
      title: AssetTitles[typeToUse],
      type: typeToUse as AssetTypes,
      accounts:
        !includeHiddenAccounts && filterMap
          ? accounts.filter(
              (account) =>
                account.id && filterMap[account.id] !== FilterType.Hide,
            )
          : accounts,
      displayOrder: AssetsDisplayOrder[typeToUse],
      filtered: excluded,
    });
  });

  return breakdown;
};

export default getOtherAssetsSectionBreakdown;
