import {
  DocumentModuleGroup,
  DocumentModuleName,
} from 'containers/Dashboard/Document/modules/modules';
import Introduction, {
  IntroductionProps,
} from 'containers/Dashboard/Document/modules/AssetAllocationInvestmentProposal/Introduction/Introduction';
import NoTextIntroduction, {
  NoTextIntroductionProps,
} from 'containers/Dashboard/Document/modules/AssetAllocationInvestmentProposal/NoTextIntroduction/NoTextIntroduction';
import InvestmentStrategy, {
  InvestmentStrategyProps,
} from 'containers/Dashboard/Document/modules/AssetAllocationInvestmentProposal/InvestmentStrategy/InvestmentStrategy';
import ImpactSummary, {
  ImpactSummaryProps,
} from 'containers/Dashboard/Document/modules/AssetAllocationInvestmentProposal/ImpactSummary/ImpactSummary';
import InitialAllocation, {
  InitialAllocationProps,
} from 'containers/Dashboard/Document/modules/AssetAllocationInvestmentProposal/InitialAllocation/InitialAllocation';
import ProposedAllocation, {
  ProposedAllocationProps,
} from 'containers/Dashboard/Document/modules/AssetAllocationInvestmentProposal/ProposedAllocation/ProposedAllocation';
import CustomIndexing, {
  CustomIndexingProps,
} from 'containers/Dashboard/Document/modules/AssetAllocationInvestmentProposal/CustomIndexing/CustomIndexing';
import Breckinridge, {
  BreckinridgeProps,
} from 'containers/Dashboard/Document/modules/AssetAllocationInvestmentProposal/Breckinridge/Breckinridge';
import Foundation, {
  FoundationProps,
} from 'containers/Dashboard/Document/modules/AssetAllocationInvestmentProposal/Foundation/Foundation';
import ConcludingThoughts, {
  ConcludingThoughtsProps,
} from 'containers/Dashboard/Document/modules/AssetAllocationInvestmentProposal/ConcludingThoughts/ConcludingThoughts';
import PrivateMarketDeals from 'containers/Dashboard/Document/modules/AssetAllocationInvestmentProposal/PrivateMarketDeals/PrivateMarketDeals';
import { PrivateMarketDealsProps } from 'containers/Dashboard/Document/modules/Shared/PrivateMarketDeals';
import AccountMovement, {
  AccountMovementProps,
} from 'containers/Dashboard/Document/modules/AssetAllocationInvestmentProposal/AccountMovement/AccountMovement';

const assetAllocationInvestmentProposalModules = {
  [`${DocumentModuleGroup.AssetAllocationInvestmentProposal}.${DocumentModuleName.AssetAllocationInvestmentProposal_Introduction}`]:
    (properties: IntroductionProps) => <Introduction {...properties} />,
  [`${DocumentModuleGroup.AssetAllocationInvestmentProposal}.${DocumentModuleName.AssetAllocationInvestmentProposal_NoTextIntroduction}`]:
    (properties: NoTextIntroductionProps) => (
      <NoTextIntroduction {...properties} />
    ),
  [`${DocumentModuleGroup.AssetAllocationInvestmentProposal}.${DocumentModuleName.AssetAllocationInvestmentProposal_InvestmentStrategy}`]:
    (properties: InvestmentStrategyProps) => (
      <InvestmentStrategy {...properties} />
    ),
  [`${DocumentModuleGroup.AssetAllocationInvestmentProposal}.${DocumentModuleName.AssetAllocationInvestmentProposal_ImpactSummary}`]:
    (properties: ImpactSummaryProps) => <ImpactSummary {...properties} />,
  [`${DocumentModuleGroup.AssetAllocationInvestmentProposal}.${DocumentModuleName.AssetAllocationInvestmentProposal_InitialAllocation}`]:
    (properties: InitialAllocationProps) => (
      <InitialAllocation {...properties} />
    ),
  [`${DocumentModuleGroup.AssetAllocationInvestmentProposal}.${DocumentModuleName.AssetAllocationInvestmentProposal_ProposedAllocation}`]:
    (properties: ProposedAllocationProps) => (
      <ProposedAllocation {...properties} />
    ),
  [`${DocumentModuleGroup.AssetAllocationInvestmentProposal}.${DocumentModuleName.AssetAllocationInvestmentProposal_AccountMovement}`]:
    (properties: AccountMovementProps) => <AccountMovement {...properties} />,
  [`${DocumentModuleGroup.AssetAllocationInvestmentProposal}.${DocumentModuleName.AssetAllocationInvestmentProposal_CustomIndexing}`]:
    (properties: CustomIndexingProps) => <CustomIndexing {...properties} />,
  [`${DocumentModuleGroup.AssetAllocationInvestmentProposal}.${DocumentModuleName.AssetAllocationInvestmentProposal_Breckinridge}`]:
    (properties: BreckinridgeProps) => <Breckinridge {...properties} />,
  [`${DocumentModuleGroup.AssetAllocationInvestmentProposal}.${DocumentModuleName.AssetAllocationInvestmentProposal_Foundation}`]:
    (properties: FoundationProps) => <Foundation {...properties} />,
  [`${DocumentModuleGroup.AssetAllocationInvestmentProposal}.${DocumentModuleName.AssetAllocationInvestmentProposal_PrivateMarketDeals}`]:
    (properties: PrivateMarketDealsProps) => (
      <PrivateMarketDeals {...properties} />
    ),
  [`${DocumentModuleGroup.AssetAllocationInvestmentProposal}.${DocumentModuleName.AssetAllocationInvestmentProposal_ConcludingThoughts}`]:
    (properties: ConcludingThoughtsProps) => (
      <ConcludingThoughts {...properties} />
    ),
};

export default assetAllocationInvestmentProposalModules;
