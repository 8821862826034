import {
  ContainerState,
  ContainerActions,
} from '../types/reducers/integration';
import ActionTypes from '../actionTypes/integrations';
import { replaceReducer } from 'reducers/hmr';
import { LifecycleStatus, Reducers } from 'types/reducers';
import OwnershipAdjustmentUtils from 'utils/assets/ownershipAdjustment';

export const INITIAL_INTEGRATIONS_STATE: ContainerState = {
  loading: false,
  errorMessage: '',
  username: '',
  password: '',
  provider: '',
  plaidIntegrations: [],
  equityIntegrations: [],
  argyleIntegrations: [],
  twoFactorEnabled: false,
  method: '',
  phoneNumber: '',
  resynced: false,
  equityLoaded: false,
  status: LifecycleStatus.Idle,
  refreshed: false,
  resyncProvider: '',
  resyncUsername: '',
  hardRefreshed: false,
  equityAccountSnapshotsLoaded: false,
};

// Take this container's state (as a slice of root state), this container's actions and return new state
function integrationsReducer(
  state: ContainerState = INITIAL_INTEGRATIONS_STATE,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.SENDING_PE_INTEGRATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.START_REQUEST:
      return {
        ...state,
        loading: true,
        status: LifecycleStatus.Pending,
      };
    case ActionTypes.COMPLETE_REQUEST:
      return {
        ...state,
        loading: false,
        status: LifecycleStatus.Idle,
      };
    case ActionTypes.UPDATE_PLAID_INTEGRATIONS:
      return {
        ...state,
        plaidIntegrations: action.payload.integrations?.map(
          OwnershipAdjustmentUtils.adjustIntegration,
        ),
      };
    case ActionTypes.UPDATE_INTEGRATIONS_STATE:
      return {
        ...state,
        ...action.payload.state,
        plaidIntegrations:
          action.payload.state.plaidIntegrations?.map(
            OwnershipAdjustmentUtils.adjustIntegration,
          ) ??
          state.plaidIntegrations?.map(
            OwnershipAdjustmentUtils.adjustIntegration,
          ) ??
          [],
      };
    case ActionTypes.DismissIntegrationError:
      return {
        ...state,
        plaidIntegrations:
          state.plaidIntegrations?.map((ig) =>
            ig.id === action.payload.id
              ? { ...ig, lastSnoozed: new Date() }
              : ig,
          ) ?? [],
      };

    case ActionTypes.SET_ERROR_MESSAGE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };
    case ActionTypes.SET_RESYNCED:
      return {
        ...state,
        resynced: action.payload.resynced,
      };
    case ActionTypes.UpdateEquityIntegrations:
      return {
        ...state,
        equityIntegrations: action.payload.integrations,
      };
    case ActionTypes.UPDATE_ARGYLE_INTEGRATIONS:
      return {
        ...state,
        argyleIntegrations: action.payload.integrations,
      };
    default:
      return state;
  }
}

// Replace the reducer with the hot loaded one
if (import.meta.hot) {
  import.meta.hot.accept(replaceReducer(Reducers.ASSETS));
}

export default integrationsReducer;
