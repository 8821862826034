import { actionTypes } from './actions';
import { LifecycleStatus } from 'types/reducers';

export enum Status {
  Upload = 'upload',
  Download = 'download',
  Open = 'open',
  Delete = 'delete',
  Idle = 'idle',
  SigningURL = 'signingUrl',
}

export interface FileManagerState {
  status: Status;
  requestStatus: LifecycleStatus;
  filesToUpload: File[];
  fileUploadStatus: Record<string, { status: LifecycleStatus }>;
  fileToDownload: { blob: Blob; fileName: string } | null;
}

export const fileManagerReducerState: FileManagerState = {
  status: Status.Idle,
  requestStatus: LifecycleStatus.Idle,
  filesToUpload: [],
  fileUploadStatus: {},
  fileToDownload: null,
};

export function fileUploadReducer(state: FileManagerState, action) {
  switch (action.type) {
    // upload
    case actionTypes.StartUpload: {
      const { file } = action.payload;
      const filesToUpload = [...state.filesToUpload, file];
      const fileUploadStatus = {
        ...state.fileUploadStatus,
        [file.name]: {
          status: LifecycleStatus.Pending,
        },
      };

      return {
        ...state,
        requestStatus: LifecycleStatus.Pending,
        status: Status.Upload,
        filesToUpload,
        fileUploadStatus,
      };
    }
    case actionTypes.ResolveUpload: {
      const { file } = action.payload;
      const filesToUpload = state.filesToUpload.filter(
        (f) => f.name !== file.name,
      );

      let requestStatus =
        filesToUpload.length > 0
          ? LifecycleStatus.Pending
          : LifecycleStatus.Resolved;
      return {
        ...state,
        requestStatus,
        fileUploadStatus: {
          ...state.fileUploadStatus,
          [file.name]: {
            status: LifecycleStatus.Resolved,
          },
        },
        filesToUpload,
      };
    }
    case actionTypes.RejectUpload: {
      const { file } = action.payload;
      const filesToUpload = state.filesToUpload.filter((f) => f !== file);

      return {
        ...state,
        status: filesToUpload.length > 0 ? state.status : Status.Idle,
        requestStatus:
          filesToUpload.length > 0
            ? LifecycleStatus.Pending
            : LifecycleStatus.Rejected,
        fileUploadStatus: {
          ...state.fileUploadStatus,
          [file.name]: {
            status: LifecycleStatus.Rejected,
          },
        },
        filesToUpload,
      };
    }
    // delete
    case actionTypes.StartDelete: {
      return {
        ...state,
        requestStatus: LifecycleStatus.Pending,
        status: Status.Delete,
      };
    }
    case actionTypes.ResolveDelete: {
      return {
        ...state,
        requestStatus: LifecycleStatus.Resolved,
      };
    }
    case actionTypes.RejectDelete: {
      return {
        ...state,
        requestStatus: LifecycleStatus.Rejected,
      };
    }
    // other
    case actionTypes.ResetState: {
      return fileManagerReducerState;
    }
    default:
      return state;
  }
}
