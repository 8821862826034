import { CompoundCFill } from 'assets/svg/CompoundC';
import { Logo } from 'components/ContentHeader/Logo';
import { Col, Text } from '@compoundfinance/design-system';

function Mobile() {
  return (
    <Col
      css={{
        ai: 'center',
        jc: 'space-between',
        py: '$52',
        h: '100%',
        ta: 'center',
        bg: '$gray2',
      }}
    >
      <Logo fill="black" />
      <Col
        css={{ ai: 'center', jc: 'center', gap: '$24', w: '80%', mx: 'auto' }}
      >
        <Col
          css={{
            ai: 'center',
            jc: 'center',
            background:
              'linear-gradient(42.09deg, $colors$gray13 4.59%, #272D3B 94.52%)',
            br: '$20',
            p: '$20',
            boxShadow:
              '0px 36.2963px 23.4259px rgba(0, 0, 0, 0.0531481), 0px 21.5704px 12.7407px rgba(0, 0, 0, 0.0425185), 0px 11.2px 6.5px rgba(0, 0, 0, 0.035), 0px 4.56296px 3.25926px rgba(0, 0, 0, 0.0274815), 0px 1.03704px 1.57407px rgba(0, 0, 0, 0.0168519);',
          }}
        >
          <CompoundCFill color="white" width="50" height="50" />
        </Col>
        <Text size={24} weight="bold">
          Unlock Compound on Desktop
        </Text>
      </Col>
      <Col css={{ ai: 'center', gap: '$16', w: '65%', mx: 'auto' }}>
        <Text size={14} color="gray10">
          The unlock and setup experience is not yet available on mobile.
        </Text>
        <Text size={14} color="gray10">
          If you have any questions, email{' '}
          <Text
            as="a"
            href="mailto:concierge@compoundplanning.com"
            color="gray10"
            css={{ textDecoration: 'underline' }}
          >
            concierge@compoundplanning.com
          </Text>
        </Text>
      </Col>
    </Col>
  );
}

export default Mobile;
