import { DirectoryPerson } from 'domain/Employees/constants';
import { ILinkPreview } from 'domain/Employees/types';

import Custom from '../../MembershipProposal/Custom/Custom';

export interface FoundationProps {
  financialAdvisor: DirectoryPerson;
  linkPreviews: ILinkPreview[];
  title: string;
}

function Foundation(props: FoundationProps) {
  const { financialAdvisor, linkPreviews, title } = props;

  return (
    <Custom
      title={title}
      customText={`Foundation Portfolios provide diversified, multi-asset and cross-geography exposure using low-cost, highly liquid ETFs. Portfolios are managed by Compound's in-house investment team, who monitor for rebalancing and tax-loss harvesting.`}
      linkPreviews={linkPreviews}
      financialAdvisor={financialAdvisor}
    />
  );
}

export default Foundation;
