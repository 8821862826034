import { action } from 'typesafe-actions';
import ActionTypes from '../actionTypes/assets';
import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link';
import {
  PlaidAccount,
  RefineryMetadata,
} from '@compoundfinance/compound-core/dist/types/plaid';
import { AssetTypes } from '@compoundfinance/compound-core/dist/types/account';
import { AssetsState, CSVModalType } from 'types/reducers/assets';
import { PrivateEquityAccount } from '../types/reducers/integration';
import { CompoundAsset } from 'components/EquityManagement/Asset/types';
import { YodleeFastLinkData } from 'utils/yodlee/types';
import { PlaidAccountProvider } from 'utils/plaid/constants';
import { AssetFilter } from 'utils/assetFilters/types';
import {
  Grant,
  Election,
} from '@compoundfinance/compound-core/dist/types/equity';

export const syncPlaidAccountToken = (
  token: string,
  metadata: PlaidLinkOnSuccessMetadata | YodleeFastLinkData | null = null,
  provider: PlaidAccountProvider = PlaidAccountProvider.Plaid,
) =>
  action(ActionTypes.SYNC_PLAID_ACCOUNT_TOKEN, {
    token,
    metadata,
    provider,
  });
export const updateAssetsStateField = (field: string, value: any) =>
  action(ActionTypes.UPDATE_ASSETS_STATE_FIELD, {
    field,
    value,
  });
export const updateAssetsState = (stateUpdate: Partial<AssetsState>) =>
  action(ActionTypes.UPDATE_ASSETS_STATE, {
    stateUpdate,
  });
const getPropertyAssets = () => action(ActionTypes.GetAssets);
const createPropertyAsset = (
  asset: CompoundAsset,
  assetType: AssetTypes,
  ownerId?: string,
) => action(ActionTypes.CreateAsset, { asset, assetType, ownerId });
const updatePropertyAsset = (
  asset: CompoundAsset,
  assetType: AssetTypes,
  ownerId?: string,
) => action(ActionTypes.UpdateAsset, { asset, assetType, ownerId });
const deletePropertyAsset = (asset: CompoundAsset, assetType: AssetTypes) =>
  action(ActionTypes.DeleteAsset, { asset, assetType });

export const setCurrentAsset = (asset: CompoundAsset | PlaidAccount) =>
  action(ActionTypes.SetCurrentAsset, { asset });

export const syncPlaidData = (payload?: {
  excludeSnapshots?: boolean;
  forceSync?: boolean;
}) => {
  const { excludeSnapshots = false, forceSync = false } = payload || {};
  return action(ActionTypes.SyncPlaidData, { excludeSnapshots, forceSync });
};

export const setAssetModalOpen = (isOpen: boolean) =>
  action(ActionTypes.SetAssetModalOpen, isOpen);

export const setAddAssetModalOpen = (isOpen: boolean) =>
  action(ActionTypes.SetAddAssetModalOpen, isOpen);

export const setOwnerManagementModalOpen = (isOpen: boolean) =>
  action(ActionTypes.SetOwnerManagementModalOpen, isOpen);

export const setViewAssetModalOpen = (isOpen: boolean, step?: number | null) =>
  action(ActionTypes.SetViewAssetModalOpen, { isOpen, step });

export const setViewCompanyEquityModalOpen = (
  isOpen: boolean,
  step?: number | null,
) => action(ActionTypes.SetViewCompanyEquityModalOpen, { isOpen, step });

export const setCSVModal = (
  type: { open: boolean } | (CSVModalType & { open: boolean }),
) => action(ActionTypes.SetCSVModal, type);

export const setMxModal = (type: {
  open: boolean;
  integrationId?: string | null;
  institutionCode?: string | null;
}) => action(ActionTypes.SetMxModal, type);

export const startAssetsRequest = () => action(ActionTypes.SENDING_REQUEST);
export const completeAssetsRequest = () => action(ActionTypes.COMPLETE_REQUEST);

export const resolveRequest = () => action(ActionTypes.RESOLVE_REQUEST);
export const rejectRequest = () => action(ActionTypes.REJECT_REQUEST);

const updateNetWorth = (filteredNetWorth: number, totalNetWorth: number) =>
  action(ActionTypes.UPDATE_NET_WORTH, { filteredNetWorth, totalNetWorth });

const updateAccountFilters = (assetFilter: AssetFilter) =>
  action(ActionTypes.UPDATE_ACCOUNT_FILTERS, { filter: assetFilter });

const batchUpdateAccountFilters = (
  assetFilters: AssetFilter[],
  isFiltering: boolean,
) =>
  action(ActionTypes.BATCH_UPDATE_ACCOUNT_FILTERS, {
    filters: assetFilters,
    isFiltering,
  });

const syncAssetFilters = () => action(ActionTypes.SYNC_ASSET_FILTERS);

const setActivePeAccount = (privateEquityAccount: PrivateEquityAccount) =>
  action(ActionTypes.SET_ACTIVE_PE_INTEGRATION, {
    privateEquityAccount,
  });

const removePrivateEquityAccount = (id: string) =>
  action(ActionTypes.REMOVE_PE_ACCOUNT, {
    id,
  });

const clearPeAccountState = () =>
  action(ActionTypes.CLEAR_PE_ACCOUNT_STATE, {});

const deletePeAccountRequest = (id: string) =>
  action(ActionTypes.DELETE_PE_ACCOUNT_REQUEST, { id });

const batchUpdatePeAccountRequest = (
  accounts: Array<Partial<PrivateEquityAccount>>,
) => action(ActionTypes.BATCH_UPDATE_PE_ACCOUNT_REQUEST, { accounts });

const updatePrivateEquityAccount = (ind: number, field: string, value: any) =>
  action(ActionTypes.UPDATE_PE_ACCOUNT, {
    ind,
    field,
    value,
  });

const updateActivePeAccount = (value: Partial<PrivateEquityAccount>) =>
  action(ActionTypes.UPDATE_ACTIVE_PE_ACCOUNT, {
    value,
  });

const updatePeAccountRequest = (peAccount: Partial<PrivateEquityAccount>) =>
  action(ActionTypes.UPDATE_PE_ACCOUNT_REQUEST, peAccount);

/**
 * GRANT ACTIONS
 */
const updateGrantRequest = (grant: Partial<Grant>) =>
  action(ActionTypes.UPDATE_GRANT_REQUEST, grant);

const deleteGrantRequest = (id: string) =>
  action(ActionTypes.DELETE_GRANT_REQUEST, {
    id,
  });

const createGrantRequest = (
  grant: Omit<Grant, 'id'>,
  restartAddModal: boolean = false,
) =>
  action(ActionTypes.CREATE_GRANT_REQUEST, {
    grant,
    restartAddModal,
  });

const createElection = (
  election: Omit<Election, 'id'>,
  privateEquityAccountId: string,
) => action(ActionTypes.CREATE_ELECTION, { election, privateEquityAccountId });

const deleteElection = (electionId: string, privateEquityAccountId: string) =>
  action(ActionTypes.DELETE_ELECTION, { electionId, privateEquityAccountId });

const updateCertificateElection = (
  election: Election,
  privateEquityAccount: PrivateEquityAccount,
) =>
  action(ActionTypes.UPDATE_CERTIFICATE_ELECTION, {
    election,
    activePeAccount: privateEquityAccount,
  });

const deleteCertificateElection = (
  electionId: string,
  privateEquityAccount: PrivateEquityAccount,
) =>
  action(ActionTypes.DELETE_CERTIFICATE_ELECTION, {
    electionId,
    activePeAccount: privateEquityAccount,
  });

const setRefineryMetadata = (metadata: RefineryMetadata[]) =>
  action(ActionTypes.SET_REFINERY_METADATA, {
    metadata,
  });

const AssetActions = {
  batchUpdateAccountFilters,
  batchUpdatePeAccountRequest,
  clearPeAccountState,
  completeAssetsRequest,
  createElection,
  createGrantRequest,
  createPropertyAsset,
  deleteCertificateElection,
  deleteElection,
  deleteGrantRequest,
  deletePeAccountRequest,
  deletePropertyAsset,
  getPropertyAssets,
  rejectRequest,
  removePrivateEquityAccount,
  resolveRequest,
  setActivePeAccount,
  setCurrentAsset,
  setRefineryMetadata,
  startAssetsRequest,
  syncAssetFilters,
  syncPlaidData,
  updateAccountFilters,
  updateActivePeAccount,
  updateAssetsState,
  updateAssetsStateField,
  updateCertificateElection,
  updateGrantRequest,
  updateNetWorth,
  updatePeAccountRequest,
  updatePrivateEquityAccount,
  updatePropertyAsset,
};

export default AssetActions;
