import { ReactNode } from 'react';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';

import { Box, Col, Row, Text } from '@compoundfinance/design-system';

import { DirectoryPerson } from 'domain/Employees/constants';
import { InstitutionLogos } from 'utils/assets/institutionLogos';
import { InstitutionName } from 'utils/plaid/constants';
import UiUtils from 'utils/ui';

import AccountLogo from 'components/AccountLogo';
import EditablePerson from 'containers/Dashboard/Document/components/EditablePerson';
import EditableTextArea from 'containers/Dashboard/Document/components/EditableTextArea';
import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import { getPerson } from 'containers/Dashboard/Document/utils';

const Container = styled(ModuleContainer, {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  py: '$24',
  px: '$24',
  gap: '$24',

  '@bp1': {
    py: '$44',
    px: '$48',
  },
});

export interface IntroductionProps {
  financialAdvisor: DirectoryPerson;
  data: {
    total: number;
    stcg: number;
    ltcg: number;
    positionsChanged: number;
    accountsUsage: { currentBalance: number; usedBalance: number }[];
    taxRates: {
      federalLCTGRate: number;
      federalSCTGRate: number;
      stateLCTGRate: number;
      stateSCTGRate: number;
    };
  };
}

interface LabelValueProps {
  label: string;
  value: string;
  valueSize?: '18' | '26';
}

function LabelValue(props: LabelValueProps) {
  const { label, value, valueSize = '18' } = props;
  return (
    <Col css={{ gap: '$6' }}>
      <Text size="13" color="gray10" css={{ w: 170 }}>
        {label}
      </Text>
      <Text size={valueSize}>{value}</Text>
    </Col>
  );
}

function Table({ children }: { children: ReactNode }) {
  return (
    <Col css={{ display: 'grid', gridTemplateRows: 'auto 1px 1fr', gap: '$6' }}>
      {children}
    </Col>
  );
}

function TableRow({ children }: { children: ReactNode }) {
  return (
    <Row
      css={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '$6',
      }}
    >
      {children}
    </Row>
  );
}

function Introduction(props: IntroductionProps) {
  const { financialAdvisor, data } = props;
  const { total, stcg, ltcg, positionsChanged, accountsUsage, taxRates } = data;

  const financialAdvisorPerson = getPerson(financialAdvisor);

  return (
    <Container>
      <Col css={{ bg: '$gray2', br: '$8', p: '$20', gap: '$24' }}>
        <LabelValue
          label="Total position size"
          value={UiUtils.formatMoney(total)}
          valueSize="26"
        />

        <Row>
          <LabelValue
            label="Long-term capital gain realized"
            value={UiUtils.formatMoney(ltcg)}
          />
          <LabelValue
            label="Short-term capital gain realized"
            value={UiUtils.formatMoney(stcg)}
          />
        </Row>

        <Row>
          <LabelValue
            label="Positions changed"
            value={positionsChanged.toString()}
          />
        </Row>

        <Table>
          <TableRow>
            <Text color="gray10" size="13">
              Impacted accounts
            </Text>
            <Text color="gray10" size="13">
              Transition size/Total
            </Text>
          </TableRow>

          <Box css={{ w: '100%', h: 1, bg: '$gray8' }} />

          <Col css={{ mt: '$2', gap: '$4' }}>
            {Object.entries(accountsUsage).map(
              ([accountName, { usedBalance, currentBalance }]) => (
                <TableRow>
                  <Row css={{ ai: 'center', gap: '$4', overflow: 'hidden' }}>
                    <AccountLogo
                      size={26}
                      logo={InstitutionLogos[InstitutionName.Compound]}
                    />

                    <Text
                      size="13"
                      css={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                    >
                      {accountName}
                    </Text>
                  </Row>

                  <Row css={{ gap: '$4' }}>
                    <Row css={{ gap: '$2' }}>
                      <Text size="13" color="gray9">
                        {UiUtils.nFormatter(usedBalance)}
                      </Text>
                      <Text size="13" color="gray9">
                        /
                      </Text>
                      <Text size="13" color="gray9">
                        {UiUtils.nFormatter(currentBalance)}
                      </Text>
                    </Row>

                    <Text size="13" color="gray9" css={{ pl: '$5' }}>
                      {Math.round((usedBalance / currentBalance) * 100)}%
                    </Text>
                  </Row>
                </TableRow>
              ),
            )}
          </Col>
        </Table>

        <Col css={{ gap: '$8' }}>
          <Text size="13" color="gray10">
            Federal LTCG: {taxRates.federalLCTGRate}%<br />
          </Text>
          <Text size="13" color="gray10">
            Federal STCG: {taxRates.federalSCTGRate}%<br />
          </Text>
          <Text size="13" color="gray10">
            State LTCG: {taxRates.stateLCTGRate}%<br />
          </Text>
          <Text size="13" color="gray10">
            State STCG: {taxRates.stateSCTGRate}%
          </Text>
        </Col>
      </Col>

      <Col css={{ gap: '$16' }}>
        <EditableTextArea
          propertyKey="introText"
          value="We created this proposal taking cost of transition into account. Generally avoid realizing large amount of capital gains but still achieving as much diversification as we can ..."
          textCss={{
            fontSize: '$14',
            w: '100%',
          }}
        />
        <EditablePerson
          propertyKey="financialAdvisor"
          propertyValue={financialAdvisor}
          person={financialAdvisorPerson}
          size="small"
          level="document"
        />
      </Col>
    </Container>
  );
}

export default Introduction;
