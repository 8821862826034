import { Col, Row, Text } from '@compoundfinance/design-system';
import TransactionTable from './TransactionTable';
import { TaxLossHarvestingAccount } from './types';
import { useState } from 'react';
import UiUtils from 'utils/ui';
import sumBy from 'lodash/sumBy';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';

type TransactionOverviewProps = {
  accounts: TaxLossHarvestingAccount[];
  taxYear: number;
};

const StyledRow = styled(Row, {
  gap: '$24',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '$gray5',
  },
  width: '100%',
  justifyContent: 'space-between',
  padding: '8px 16px 8px 0px',
  borderRadius: '$8',
});

const TransactionOverview = ({
  accounts,
  taxYear,
}: TransactionOverviewProps) => {
  const [selectedAccountId, setSelectedAccountId] = useState('all');

  const totalTransactionCount = sumBy(
    accounts,
    (account) => account.transactions.length,
  );

  const totalTransactionValue = sumBy(accounts, (account) =>
    sumBy(account.transactions, 'amount'),
  );

  const selectedTransactions =
    accounts.find((account) => account.id === selectedAccountId)
      ?.transactions ?? accounts.flatMap((account) => account.transactions);

  return (
    <Row>
      <Col
        css={{
          borderRight: '1px solid $gray1',
        }}
      >
        <Text
          color="gray9"
          size={18}
          weight="medium"
          css={{ marginBottom: '$12' }}
        >
          <Text color="gray12">{totalTransactionCount} </Text>TLH transactions
          in {taxYear}
        </Text>
        <StyledRow
          onClick={() => setSelectedAccountId('all')}
          css={{
            backgroundColor: selectedAccountId === 'all' ? '$gray6' : undefined,
          }}
        >
          <Text>All</Text>
          <Text>{totalTransactionCount}</Text>
          <Text>{UiUtils.formatMoney(totalTransactionValue)}</Text>
        </StyledRow>
        {accounts.map((account) => (
          <StyledRow
            onClick={() => setSelectedAccountId(account.id)}
            css={{
              backgroundColor:
                selectedAccountId === account.id ? '$gray6' : undefined,
            }}
          >
            <Text>{account.name}</Text>
            <Text>{account.transactions.length}</Text>
            <Text>
              {UiUtils.formatMoney(sumBy(account.transactions, 'amount'))}
            </Text>
          </StyledRow>
        ))}
      </Col>
      <TransactionTable transactions={selectedTransactions} />
    </Row>
  );
};

export default TransactionOverview;
