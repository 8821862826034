import { useState } from 'react';
import { CompoundDocument, CompoundDocumentSection } from '../..';
import ModuleContext from './ModuleContext';
import { KeyedMutator } from 'swr';

type Props = {
  module: CompoundDocumentSection;
  mutateDocument: KeyedMutator<CompoundDocument> | null;
  documentProperties: Record<string, any>;
};

function ModuleContextProvider(props: React.PropsWithChildren<Props>) {
  const { children, module, mutateDocument, documentProperties } = props;
  const [isHover, setIsHover] = useState(false);

  const value = {
    module,
    mutateDocument,
    documentProperties,
    isHover,
    setIsHover,
  };

  return (
    <ModuleContext.Provider value={value}>{children}</ModuleContext.Provider>
  );
}

export default ModuleContextProvider;
