import ModuleContext from 'containers/Dashboard/Advisor/DocumentsAdmin/components/DocumentViewer/ModuleContext';
import useIsDocumentEditor from 'containers/Dashboard/Advisor/DocumentsAdmin/hooks/useIsDocumentEditor';
import { useMutableModuleProperty } from 'containers/Dashboard/Advisor/DocumentsAdmin/hooks/useMutableProperty';
import DocumentsAPI from 'containers/Dashboard/Document/api';
import LinkPreview from 'containers/Dashboard/Document/components/LinkPreview';
import { ILinkPreview } from 'domain/Employees/types';
import {
  Box,
  Button,
  CameraIcon,
  Col,
  EditIcon,
  Text,
  TrashIcon,
} from '@compoundfinance/design-system';
import { useContext } from 'react';

async function getLinkPreview(url: string): Promise<ILinkPreview> {
  try {
    const urlInfo = await DocumentsAPI.getLinkPreview(url);

    return {
      url: urlInfo.url,
      title: urlInfo.title,
      image: urlInfo.image,
    };
  } catch (e) {
    window.alert("Couldn't get link preview details");
    return {
      url,
      title: url,
      image: null,
    };
  }
}

type AddLinkPreviewProps = {
  linkPreviews: ILinkPreview[];
  setLinkPreviews: (value: ILinkPreview[]) => void;
};

function AddLinkPreview(props: AddLinkPreviewProps) {
  const { linkPreviews, setLinkPreviews } = props;

  const { isHover } = useContext(ModuleContext);

  const handleAddLink = async () => {
    const prompt = window.prompt('Enter new link');
    if (prompt) {
      const linkPreview = await getLinkPreview(prompt);
      setLinkPreviews([...linkPreviews, linkPreview]);
    }
  };

  return (
    <Col
      css={{
        bg: isHover ? '$indigo3' : '$gray1',
        borderRadius: '8px',
        p: '$20',
        ai: 'center',
        jc: 'center',
        cursor: 'pointer',
        '&:hover': {
          bg: '$gray2',
        },
      }}
      onClick={handleAddLink}
    >
      <Text size="20" color="gray10">
        Add a link
      </Text>
    </Col>
  );
}

type LinkPreviewControlsProps = {
  propertyIndex: number;
  linkPreviews: ILinkPreview[];
  setLinkPreviews: (value: ILinkPreview[]) => void;
};

function LinkPreviewControls(
  props: React.PropsWithChildren<LinkPreviewControlsProps>,
) {
  const { propertyIndex: index, linkPreviews, setLinkPreviews } = props;

  const { isHover } = useContext(ModuleContext);

  const handleDeleteLink = () => {
    const newLinkPreviews = [...linkPreviews];
    newLinkPreviews.splice(index, 1);
    setLinkPreviews(newLinkPreviews);
  };

  const handleTitleEdit = () => {
    const defaultValue = linkPreviews[index].title;
    const newTitle = window.prompt('Enter new title', defaultValue);

    if (newTitle && newTitle !== defaultValue) {
      const newLinkPreviews = [...linkPreviews];
      newLinkPreviews[index] = { ...newLinkPreviews[index], title: newTitle };
      setLinkPreviews(newLinkPreviews);
    }
  };

  const handleImageEdit = () => {
    const defaultValue = linkPreviews[index].image ?? '';
    const newImage = window.prompt('Enter new image', defaultValue);

    if (newImage !== null && newImage !== defaultValue) {
      const newLinkPreviews = [...linkPreviews];

      newLinkPreviews[index] = {
        ...newLinkPreviews[index],
        image: newImage || null,
      };

      setLinkPreviews(newLinkPreviews);
    }
  };

  return (
    <Box css={{ position: 'relative' }}>
      {props.children}

      {isHover && (
        <Col css={{ position: 'absolute', top: 0, right: '-$32', gap: '$4' }}>
          <Button
            type="button"
            size="small"
            square
            variant="internal"
            onClick={handleTitleEdit}
            title="Edit title"
          >
            <EditIcon size={16} />
          </Button>

          <Button
            type="button"
            size="small"
            square
            variant="internal"
            onClick={handleImageEdit}
            title="Edit image"
          >
            <CameraIcon size={16} />
          </Button>
          <Button
            type="button"
            onClick={handleDeleteLink}
            size="small"
            square
            variant="internal"
            title="Delete link"
          >
            <TrashIcon size={16} />
          </Button>
        </Col>
      )}
    </Box>
  );
}

type LinkPreviewWrapperProps = {
  linkPreview: ILinkPreview;
};

function LinkPreviewWrapper(props: LinkPreviewWrapperProps) {
  const { linkPreview } = props;
  return <LinkPreview {...linkPreview} />;
}

type Props = {
  propertyKey: string;
  linkPreviews: ILinkPreview[];
  ViewComponent: React.ComponentType<LinkPreviewWrapperProps>;
};

function LinksEditor(props: Props) {
  const { propertyKey, linkPreviews, ViewComponent } = props;
  const isDocumentEditor = useIsDocumentEditor();
  const [, setLinkPreviews] = useMutableModuleProperty<ILinkPreview[]>(
    propertyKey,
    [],
  );

  return (
    <>
      {linkPreviews.map((linkPreview, idx) => {
        if (isDocumentEditor) {
          return (
            <LinkPreviewControls
              key={`${idx}-${linkPreview.url}`}
              propertyIndex={idx}
              linkPreviews={linkPreviews}
              setLinkPreviews={setLinkPreviews}
            >
              <ViewComponent linkPreview={linkPreview} />
            </LinkPreviewControls>
          );
        }

        return (
          <ViewComponent
            key={`${idx}-${linkPreview.url}`}
            linkPreview={linkPreview}
          />
        );
      })}

      {isDocumentEditor && (
        <AddLinkPreview
          linkPreviews={linkPreviews}
          setLinkPreviews={setLinkPreviews}
        />
      )}
    </>
  );
}

LinksEditor.defaultProps = {
  ViewComponent: LinkPreviewWrapper,
};

export default LinksEditor;
