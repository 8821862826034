import styled from 'styled-components';

import { MainWrap } from 'components/MainWrap';
import Footer from 'components/Footer';
import Card from './Card';

function Maintenance() {
  return (
    <MainWrap h={'100vh'} bg="#000">
      <CardWrapper>
        <Card />
      </CardWrapper>
      <Footer style={{ opacity: 0.6 }} sm />
    </MainWrap>
  );
}

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export default Maintenance;
