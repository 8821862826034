export enum LifecycleStatus {
  Pending = 'pending',
  Resolved = 'resolved',
  Rejected = 'rejected',
  Idle = 'idle',
}

export enum Reducers {
  GLOBAL = 'global',
  INTEGRATIONS = 'integrations',
  ONBOARD = 'onboard',
  ADMIN = 'admin',
  LOGIN = 'login',
  SETTINGS = 'settings',
  ASSETS = 'assets',
  ESTATE = 'estate',
  CASHFLOW = 'cashflow',
  ROUTER = 'router',
}
