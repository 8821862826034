import { AssetTypes } from '@compoundfinance/compound-core/dist/types/account';
import { AccountType, AccountSubtype, CryptoAccountSubtype } from './types';

export const AccountTypeLabels = {
  [AccountType.Credit]: {
    [AccountSubtype.CreditCard]: 'Credit Card',
    [AccountSubtype.PayPal]: 'PayPal issued credit card',
    [AccountSubtype.Other]: 'Other',
  },
  [AccountType.Depository]: {
    [AccountSubtype.CashManagement]: 'Cash management account',
    [AccountSubtype.CD]: 'Certificate of Deposit',
    [AccountSubtype.Checking]: 'Checking Account',
    [AccountSubtype.HSA]: 'Health Savings Account that may only hold cash',
    [AccountSubtype.Savings]: 'Savings Account',
    [AccountSubtype.MoneyMarket]: 'Money Market Account',
    [AccountSubtype.PayPal]: 'PayPal held depository account',
    [AccountSubtype.Prepaid]: 'Prepaid debit card',
    [AccountSubtype.Other]: 'Other Cash Account',
  },
  [AccountType.Loan]: {
    [AccountSubtype.Auto]: 'Automobile loan',
    [AccountSubtype.Commercial]: 'Commercial loan',
    [AccountSubtype.Construction]: 'Construction loan',
    [AccountSubtype.Consumer]: 'Consumer loan',
    [AccountSubtype.HomeEquity]: 'Home Equity Line of Credit (HELOC)',
    [AccountSubtype.Loan]: 'General loan',
    [AccountSubtype.Mortgage]: 'Mortgage loan',
    [AccountSubtype.Overdraft]:
      'Pre-approved overdraft account, usually tied to a checking account',
    [AccountSubtype.LineOfCredit]: 'Pre-approved line of credit',
    [AccountSubtype.Student]: 'Student loan',
    [AccountSubtype.Other]: 'Other',
  },
  [AccountType.Investment]: {
    [AccountSubtype.Tax401a]:
      'Employer-sponsored money-purchase retirement plan',
    [AccountSubtype.Tax401k]: '401(K)',
    [AccountSubtype.Tax403b]: 'Tax-advantaged retirement savings plan',
    [AccountSubtype.Tax457b]:
      'Tax-advantaged deferred-compensation retirement plan',
    [AccountSubtype.Tax529]: '529 College Savings Plan',
    [AccountSubtype.Brokerage]: 'Brokerage Account',
    [AccountSubtype.CashISA]: 'UK account that pays interest tax-free',
    [AccountSubtype.EducationSavings]:
      'Tax-advantaged Education Savings Account (ESA)',
    [AccountSubtype.FixedAnnuity]: 'Fixed Annuity',
    [AccountSubtype.GIC]: 'Canadian Guaranteed Investment Certificate',
    [AccountSubtype.HealthArrangement]: 'Tax-advantaged health benefit plan',
    [AccountSubtype.HSA]: 'Tax-advantaged medical Health Savings Account',
    [AccountSubtype.IRA]: 'IRA',
    [AccountSubtype.ISA]: "UK 'Individual Savings Account'",
    [AccountSubtype.KEOGH]: 'Keogh self-employed pension plan',
    [AccountSubtype.LIF]: 'Canadian registered Retirement Income Fund',
    [AccountSubtype.LIRA]: 'Canadian Locked-In Retirement Account',
    [AccountSubtype.LRIF]: 'Canadian Locked-in Retirement Income Fund',
    [AccountSubtype.LRSP]: 'Canadian Locked-in Retirement Savings Plan',
    [AccountSubtype.MutualFund]: 'Mutual Fund',
    [AccountSubtype.NonTaxableBrokerage]:
      'A non-taxable brokerage account that is not covered by a more specific subtype',
    [AccountSubtype.Pension]: 'Standard Pension account',
    [AccountSubtype.PRIF]:
      'Canadian Prescribed Registered Retirement Income Fund',
    [AccountSubtype.ProfitSharingPlan]:
      'Plan gives employees share of company profits',
    [AccountSubtype.QSHR]: 'Qualifying share account',
    [AccountSubtype.RDSP]: 'Canadian Registered Disability Savings Plan',
    [AccountSubtype.RESP]: 'Canadian Registered Education Savings Plan',
    [AccountSubtype.Retirement]:
      'A retirement account not covered by other subtypes',
    [AccountSubtype.RLIF]: 'Canadian Restricted Life Income Fund',
    [AccountSubtype.ROTH]: 'Roth IRA',
    [AccountSubtype.Roth401K]: 'Roth 401(k)',
    [AccountSubtype.RRIF]: 'Canadian Registered Retirement Income Fund',
    [AccountSubtype.RRSP]:
      'Canadian Registered Retirement Savings Plan (equivalent to 401k)',
    [AccountSubtype.SARSEP]:
      'Salary Reduction Simplified Employee Pension Plan',
    [AccountSubtype.SEPIRA]: 'Simplified Employee Pension IRA',
    [AccountSubtype.SimpleIRA]:
      'Savings Incentive Match Plan for Employees IRA',
    [AccountSubtype.SIPP]: 'UK Self-Invested Personal Pension',
    [AccountSubtype.StockPlan]: 'Standard Stock Plan account',
    [AccountSubtype.TFSA]:
      'Canadian tax-free savings account, equivalent to roth',
    [AccountSubtype.ThriftSavingsPlan]:
      'Defined contribution plan for US civil servants',
    [AccountSubtype.Trust]:
      'Account representing funds or assets held by a trustee for the benefit of a beneficiary',
    [AccountSubtype.UGMA]:
      "Uniform Gift to Minors Act' (brokerage account for minors)",
    [AccountSubtype.UTMA]:
      "Uniform Transfers to Minors Act' (brokerage account for minors)",
    [AccountSubtype.VariableAnnuity]:
      'Tax-deferred capital accumulation annuity contract',
    [AccountSubtype.Other]: 'Other Investment Accounts',
  },
  [AccountType.Other]: {
    [AccountSubtype.Other]: 'Other',
  },
  [AccountType.Crypto]: {
    ...CryptoAccountSubtype,
    [AccountSubtype.Other]: 'Other',
  },
};

export const AssetTypeTitles = {
  [AssetTypes.Cash]: 'Cash',
  [AssetTypes.Crypto]: 'Crypto',
  [AssetTypes.PublicInvestment]: 'Public Assets',
  [AssetTypes.StartupEquity]: 'Company Equity',
  [AssetTypes.PrivateInvestment]: 'Private Investments',
  [AssetTypes.FundInvestment]: 'Fund Investments',
  [AssetTypes.CompoundFunds]: 'Compound Funds',
  [AssetTypes.LP]: 'Limited Partnerships',
  [AssetTypes.GP]: 'General Partnerships',
  [AssetTypes.RealEstate]: 'Real Estate',
  [AssetTypes.Other]: 'Other Assets',
  [AssetTypes.Loan]: 'Loans',
  [AssetTypes.CreditCard]: 'Credit Cards',
  [AssetTypes.OtherLiability]: 'Other Liabilities',
  [AssetTypes.ManualBankAccount]: 'Cash',
  [AssetTypes.ManualCryptoAccount]: 'Crypto',
  [AssetTypes.ManualInvestmentAccount]: 'Public Assets',
};

export const AssetTypeTitlesSingular = {
  [AssetTypes.Cash]: 'Cash',
  [AssetTypes.Crypto]: 'Crypto',
  [AssetTypes.PublicInvestment]: 'Public Asset',
  [AssetTypes.StartupEquity]: 'Company Equity',
  [AssetTypes.PrivateInvestment]: 'Private Investment',
  [AssetTypes.FundInvestment]: 'Fund Investment',
  [AssetTypes.CompoundFunds]: 'Compound Fund',
  [AssetTypes.LP]: 'Limited Partnership',
  [AssetTypes.GP]: 'General Partnership',
  [AssetTypes.RealEstate]: 'Real Estate',
  [AssetTypes.Other]: 'Other Asset',
  [AssetTypes.Loan]: 'Loans',
  [AssetTypes.CreditCard]: 'Credit Card',
  [AssetTypes.OtherLiability]: 'Other Liability',
  [AssetTypes.ManualBankAccount]: 'Cash',
  [AssetTypes.ManualCryptoAccount]: 'Crypto',
  [AssetTypes.ManualInvestmentAccount]: 'Public Asset',
};

export enum AccountSectionSortOrder {
  ValueAscending = 'valueAscending',
  ValueDescending = 'valueDescending',
  NameAscending = 'nameAscending',
  NameDescending = 'nameDescending',
}
