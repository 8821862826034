import { LIGHT_GREY } from '@compoundfinance/design-system/dist/style/theme';

export const DROPDOWN_INPUT_STYLE = {
  backgroundColor: LIGHT_GREY,
  padding: '0.5rem 0.75rem',
  fontSize: '13px',
  lineHeight: '15px',
  height: 32,
  backgroundPositionY: '60%',
  fontWeight: 525,
};

export const ChartTypes = {
  Trend: 'trend',
  Mekko: 'mekko',
  Line: 'line',
};
