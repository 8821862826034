import capitalize from 'lodash/capitalize';
import { PartnerUser, User } from 'types/user';
import { isOnboardTestAccount } from 'containers/Onboard/util/constant';

function isTestAccount(user: User) {
  return isOnboardTestAccount(user.email);
}

function isProcessing(user: User) {
  return (
    (!user.reportComplete || !user.accountAuthenticated) && !isTestAccount(user)
  );
}

function isOnboarding(user: User) {
  const isOnboardingUser = isTestAccount(user);

  if (isOnboardingUser && !user.onboardingComplete) {
    return true;
  }

  return (
    (!isProcessing(user) && !user.onboardingComplete) || !user.dashboardApproved
  );
}

function getName(firstName: string, lastName: string) {
  return `${capitalize(firstName)} ${capitalize(lastName)}`;
}

function getInitials(firstName: string, lastName: string) {
  if (!firstName || !lastName) {
    return '-';
  }

  return `${firstName?.[0]}${lastName?.[0]}`;
}

function getFirstInitial(firstName: string) {
  if (!firstName) {
    return '-';
  }

  return `${firstName?.[0]}`;
}

function getInitialsFromFullName(fullName: string) {
  if (!fullName) {
    return '-';
  }
  return fullName
    .split(' ')
    .slice(0, 2)
    .map((name) => name[0])
    .join('');
}

/**
 * Get either the preferred name of a partner user, or the name inferred from
 * the gmail account they used to sign up
 * @param user
 * @returns
 */
function getPartnerName(user: PartnerUser) {
  const preferredNames = {
    preferredFirstName: user.userProfile.preferredFirstName,
    preferredLastName: user.userProfile.preferredLastName,
  };

  return {
    firstName: preferredNames.preferredFirstName || user.firstName,
    lastName: preferredNames.preferredLastName || user.lastName,
  };
}

const UserUtils = {
  isOnboarding,
  isTestAccount,
  isProcessing,
  getName,
  getInitials,
  getFirstInitial,
  getPartnerName,
  getInitialsFromFullName,
};

export default UserUtils;
