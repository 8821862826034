import RootActionTypes from 'actionTypes/root';
import { combineReducers } from 'redux';

/**
This function is used to create a reducer manager. It allows you to add and remove reducers from the store at runtime.
In particular, it is used to swap out reducers when hot reloading is enabled.

From: https://redux.js.org/usage/code-splitting#using-a-reducer-manager

@function createReducerManager
@param {Object} initialReducers - An object containing the initial reducers to be added to the manager.
@returns {Object} An object with methods for managing and accessing reducers.
@property {Function} getReducerMap - Returns the current reducer mapping object.
@property {Function} reduce - The root reducer function exposed by this object. This will be passed to the store.
@property {Function} add - Adds a new reducer with the specified key.
@property {Function} remove - Removes a reducer with the specified key.
@property {Function} replace - Replaces a reducer with the specified key.
*/
function createReducerManager(initialReducers) {
  // Create an object which maps keys to reducers
  const reducers = { ...initialReducers };

  // Create the initial combinedReducer
  let combinedReducer = combineReducers(reducers);

  // An array which is used to delete state keys when reducers are removed
  let keysToRemove: string[] = [];

  return {
    getReducerMap: () => reducers,

    // The root reducer function exposed by this object
    // This will be passed to the store
    reduce: (state, action) => {
      // If any reducers have been removed, clean up their state first
      if (keysToRemove.length > 0) {
        state = { ...state };
        for (let key of keysToRemove) {
          delete state[key];
        }
        keysToRemove = [];
      }

      // Elias Lankinen 2022/02/09
      // Resets Redux state. Created for advisor portal when changing a client but
      // we didn't end up using because it doesn't reload everything like hard refresh.
      if (action.type === RootActionTypes.RESET) {
        state = undefined;
      }

      // Delegate to the combined reducer
      // @ts-ignore
      return combinedReducer(state, action);
    },

    // Adds a new reducer with the specified key
    add: (key, reducer) => {
      if (!key || reducers[key]) {
        return;
      }

      // Add the reducer to the reducer mapping
      reducers[key] = reducer;

      // Generate a new combined reducer
      combinedReducer = combineReducers(reducers);
    },

    // Removes a reducer with the specified key
    remove: (key) => {
      if (!key || !reducers[key]) {
        return;
      }

      // Remove it from the reducer mapping
      delete reducers[key];

      // Add the key to the list of keys to clean up
      keysToRemove.push(key);

      // Generate a new combined reducer
      combinedReducer = combineReducers(reducers);
    },

    // Replaces a reducer with the specified key
    replace: (key, reducer) => {
      if (!key || !reducers[key]) {
        return;
      }

      // Replace the reducer in the reducer mapping
      reducers[key] = reducer;

      // Generate a new combined reducer
      combinedReducer = combineReducers(reducers);
    },
  };
}

export default createReducerManager;
