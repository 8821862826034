import { DirectoryPerson } from 'domain/Employees/constants';
import { ILinkPreview } from 'domain/Employees/types';

import Custom from '../../MembershipProposal/Custom/Custom';

export interface ConcludingThoughtsProps {
  financialAdvisor: DirectoryPerson;
  linkPreviews: ILinkPreview[];
  title: string;
}

function ConcludingThoughts(props: ConcludingThoughtsProps) {
  const { financialAdvisor, linkPreviews, title } = props;

  return (
    <Custom
      title={title}
      customText={`Our proposed investment strategy is designed to balance your financial goals, risk tolerance, and time horizon, while optimizing your growth potential.

Our firm's expertise, combined with our strategic partnership with leading asset managers like O'Shaughnessy and Blackstone ensures that your investments are managed with precision, care, and diligence.

We look forward to working with you to build on your new financial independence, and we are confident that entrusting your assets under our firm's direct management will yield significant long-term benefits.`}
      linkPreviews={linkPreviews}
      financialAdvisor={financialAdvisor}
    />
  );
}

export default ConcludingThoughts;
