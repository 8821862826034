import { createBrowserHistory, Location } from 'history';
import { trackPageView } from 'utils/amplitude';

const EMBEDDED_PREFIX = '/dashboard';

export function getIsEmbedded() {
  return window && !!window?.location.pathname.startsWith(EMBEDDED_PREFIX);
}

/**
 * When building links that do hard navigation (i.e. that don't go through the history API), we need to add
 * a prefix to the path that accounts for being in the embedded experience.
 * @returns The given {@link path} prepended with the {@link EMBEDDED_PREFIX} iff we are in the embedded experience.
 */
export function addPathPrefix(path: string): string {
  if (getIsEmbedded()) {
    return EMBEDDED_PREFIX + path;
  }

  return path;
}

const history = createBrowserHistory({
  basename: getIsEmbedded() ? '/dashboard' : undefined,
});
history.listen((location, action) => {
  trackPageView(location as Location, action);
});
export default history;
