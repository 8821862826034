import { TwrEntry } from 'utils/blackDiamond/timeWeightedReturn/api';

// accepts a subarray of the twrData and re-computes the TWR of the period from aggregatedTwr values
function getPeriodTwr(twrData: TwrEntry[]) {
  if (1 + twrData[0].aggregatedTwr === 0) {
    return 0;
  }
  return (
    ((1 + twrData[twrData.length - 1].aggregatedTwr) /
      (1 + twrData[0].aggregatedTwr)) *
      (1 + twrData[0].twr) -
    1
  );
}

const TimeWeightedReturnProvider = {
  getPeriodTwr,
};

export default TimeWeightedReturnProvider;
