/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 */

enum ActionTypes {
  START_REQUEST = 'ONBOARD/START_REQUEST',
  COMPLETE_REQUEST = 'ONBOARD/COMPLETE_REQUEST',
  EDIT_ACTIVE_PE_ACCOUNT = 'ONBOARD/EDIT_ACTIVE_PE_ACCOUNT',
  SENDING_ONBOARDING_SYNC_REQUEST = 'ONBOARD/SENDING_ONBOARDING_SYNC_REQUEST',
  UPDATE_LAST_ONBOARDING_SECTION = 'ONBOARD/UPDATE_LAST_ONBOARDING_SECTION',
  UPDATE_USER_REQUEST = 'ONBOARD/UPDATE_USER_REQUEST',
  UPDATE_PE_ACCOUNT_REQUEST = 'ONBOARD/UPDATE_PE_ACCOUNT_REQUEST',
  UPDATE_ACTIVE_TAX_PROFILE_REQUEST = 'ONBOARD/UPDATE_ACTIVE_TAX_PROFILE_REQUEST',
  UPDATE_WEALTH_PROFILE_REQUEST = 'ONBOARD/UPDATE_WEALTH_PROFILE_REQUEST',
  SET_DEPENDENTS_REQUEST = 'ONBOARD/SET_DEPENDENTS_REQUEST',
  SUBMIT_PLAID_ACCOUNTS = 'ONBOARD/SUBMIT_PLAID_ACCOUNTS',
  FETCH_FILES = 'ONBOARD/FETCH_FILES',
  UPLOAD_FILE = 'ONBOARD/UPLOAD_FILE',
  DELETE_FILE = 'ONBOARD/DELETE_FILE',
  UPDATE_FILE = 'ONBOARD/UPDATE_FILE',
  CREATE_PE_ACCOUNT = 'ONBOARD/CREATE_PE_ACCOUNT',
  COMPLETE_ONBOARDING = 'ONBOARD/COMPLETE_ONBOARDING',

  AcceptToS = 'ONBOARD/AcceptToS',
  SaveBasicInformation = 'ONBOARD/SaveBasicInformation',
  SaveSpouseInformation = 'ONBOARD/SaveSpouseInformation',
}

export default ActionTypes;
