import { ContainerState, ContainerActions } from 'types/reducers/settings';
import ActionTypes from 'actionTypes/settings';
import { replaceReducer } from 'reducers/hmr';
import { Reducers } from 'types/reducers';

const initialState: ContainerState = {
  loading: false,
  errorMessage: '',
};

// Take this container's state (as a slice of root state), this container's actions and return new state
function settingsReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  switch (action.type) {
    case ActionTypes.START_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.COMPLETE_REQUEST:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

// Replace the reducer with the hot loaded one
if (import.meta.hot) {
  import.meta.hot.accept(replaceReducer(Reducers.SETTINGS));
}

export default settingsReducer;
