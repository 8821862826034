import { Box } from '@compoundfinance/design-system';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';

const ModuleContainer = styled(Box, {
  w: '100%',

  '@bp1': {
    w: '1034px',
    minHeight: '428px',
    bg: '$gray0',
    border: '1px solid $gray4',
    br: '$10',
  },
});

export default ModuleContainer;
