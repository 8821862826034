import {
  Box,
  Button,
  Col,
  MailIcon,
  Row,
  Text,
} from '@compoundfinance/design-system';
import { IPerson } from '../../../../domain/Employees/types';

export enum PersonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export interface PersonProps {
  person: IPerson;
  size?: string | PersonSize;
}

function Person(props: PersonProps) {
  const { person, size = PersonSize.Medium } = props;

  let imageSize = '$20';
  if (size === PersonSize.Medium) imageSize = '$40';
  if (size === PersonSize.Large) imageSize = '120px';

  let horizontalGap = '$6';
  if (size === PersonSize.Medium) horizontalGap = '$12';
  if (size === PersonSize.Large) horizontalGap = '$24';

  return (
    <Row css={{ ai: 'center', gap: horizontalGap }}>
      {person.avatar && (
        <Box
          as="img"
          css={{
            w: imageSize,
            h: imageSize,
            br: '99999px',
          }}
          src={person.avatar}
        />
      )}
      <Col css={{ gap: '$4' }}>
        <Text size="13" weight="medium">
          {person.name}
        </Text>

        {(size === PersonSize.Medium || size === PersonSize.Large) && (
          <Text size="13" color="gray10">
            {person.role}
          </Text>
        )}

        {size === PersonSize.Large && (
          <Button
            as="a"
            href={`mailto:${person.email}`}
            target="_blank"
            rel="noopener noreferrer"
            variant="ghost"
            size="small"
            css={{ bg: '$gray2', borderColor: '$gray2', mt: '$12' }}
          >
            <Row css={{ h: '$24', ai: 'center', gap: '$2' }}>
              <MailIcon size={20} />
              <Text size="13">Send email</Text>
            </Row>
          </Button>
        )}
      </Col>
    </Row>
  );
}
export default Person;
