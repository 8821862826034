import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import cloneDeep from 'lodash/cloneDeep';
import React, { useState } from 'react';
import { linkHorizontal } from 'd3-shape';
import { Group } from '@visx/group';

import { useTooltip, defaultStyles, useTooltipInPortal } from '@visx/tooltip';

import { NumberUtils } from 'utils/NumberUtils';

import Sankey, { SankeyNode } from './Chartifacts/Sankey';
import { Col, Text } from '@compoundfinance/design-system';

type Link = { target: string; source: string | undefined; value: number };
type Node<T> = T;

const getLinkKey = (link: Link) => `${link.source}-${link.target}`;

const tooltipStyles = {
  ...defaultStyles,
  padding: '8px 12px',
  zIndex: 100000,
  minWidth: 132,
  borderRadius: 6,
};

const getRepresentationalValueByLink = ({
  links,
  totalValue,
  minimumLinkValue = 0,
}: {
  links: Link[];
  totalValue: number;
  minimumLinkValue: number;
}) => {
  const representationalValues: { [Key in string]: number } = {};
  let availableValue = totalValue;
  let remainingValue = links.reduce(
    (remainingValue, link) => NumberUtils.add(remainingValue, link.value),
    0,
  );
  for (const link of sortBy(links, 'value', 'asc')) {
    const { value } = link;
    const representationalValue = Math.max(
      NumberUtils.divide(
        NumberUtils.multiply(availableValue, value),
        remainingValue,
      ),
      minimumLinkValue,
    );
    representationalValues[getLinkKey(link)] = representationalValue;
    remainingValue = NumberUtils.subtract(remainingValue, value);
    availableValue = NumberUtils.subtract(
      availableValue,
      representationalValue,
    );
  }
  return representationalValues;
};

const normalizeLinks = ({
  links,
  totalValue,
  minimumLinkValue = 0,
}: {
  links: Link[];
  totalValue: number;
  minimumLinkValue: number;
}) => {
  const representationalValues = getRepresentationalValueByLink({
    links,
    totalValue,
    minimumLinkValue,
  });
  return links.map((link) => ({
    ...link,
    value: representationalValues[getLinkKey(link)],
  }));
};

const path = linkHorizontal()
  //@ts-ignore
  .source((d) => [d.source.x1, d.y0])
  //@ts-ignore
  .target((d) => [d.target.x0, d.y1]);

let tooltipTimeout: number;

const SankeyChart = <NodeType extends { id: string }>(props: {
  data: { links: Link[]; nodes: NodeType[] };
  description?: ({
    node,
  }: {
    node: SankeyNode<NodeType, Link>;
  }) => React.ReactNode;
  tooltip?: ({ node }: { node: SankeyNode<NodeType, Link> }) => React.ReactNode;
  color: ({ node }: { node: SankeyNode<NodeType, Link> }) => string;
  width: number;
  height: number;
}) => {
  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
  } = useTooltip<SankeyNode<NodeType, Link>>();
  const { TooltipInPortal } = useTooltipInPortal();
  const [highlightLinkIndexes, setHighlightLinkIndexes] = useState<any>([]);

  const {
    color,
    data,
    description = () => null,
    tooltip = () => null,
    width,
    height: outerHeight,
  } = props;

  const { nodes, links } = data;

  const nodesById = keyBy(nodes, 'id');

  const normalizedData = {
    nodes: cloneDeep(nodes),
    links: normalizeLinks({
      links,
      totalValue: outerHeight,
      minimumLinkValue: 6,
    }),
  };

  const innerHeight = normalizedData.links.reduce(
    (innerHeight, link) => NumberUtils.add(innerHeight, link.value),
    0,
  );

  const height = Math.max(outerHeight, innerHeight);

  if (width < 10) return null;
  if (nodes.length === 0 || links.length === 0)
    return (
      <Col
        css={{
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          textAlign: 'center',
          gap: '$6',
        }}
      >
        <Text weight="medium">No data found</Text>
        <Text size={12}>
          You can update the allocation proposals to <br />
          see how the investments would change over time
        </Text>
      </Col>
    );

  return (
    <>
      <svg width={width} height={height}>
        <Sankey<Node<NodeType>, Link>
          data={normalizedData}
          size={[width - 200, height]}
          nodeWidth={2}
          nodePadding={10}
          nodeId={(node) => node.id}
        >
          {({ data }) => {
            return (
              <Group left={30}>
                {data.nodes.map((node, i) => {
                  const width = node.x1 - node.x0;
                  const height = node.y1 - node.y0;
                  const shouldShowDescription =
                    (height >= 44 && node.depth === 0) || node.depth !== 0;
                  return (
                    <React.Fragment key={node.id}>
                      <Group top={node.y0} left={node.x0}>
                        <rect
                          id={`rect-${i}`}
                          width={width}
                          height={height}
                          fill={color({ node })}
                          rx={1}
                          onMouseOver={() => {
                            setHighlightLinkIndexes([
                              ...node.sourceLinks.map((l) => l.index),
                              ...node.targetLinks.map((l) => l.index),
                            ]);
                          }}
                          onMouseOut={() => {
                            setHighlightLinkIndexes([]);
                          }}
                        />

                        {shouldShowDescription && (
                          <Group width={300} height={height}>
                            {description({
                              node: { ...node, ...nodesById[node.id] },
                            })}
                          </Group>
                        )}
                      </Group>
                    </React.Fragment>
                  );
                })}

                <Group>
                  {data.links.map((link, index) => {
                    return (
                      <path
                        key={`link-${index}`}
                        /** @ts-ignore */
                        d={path(link) ?? ''}
                        stroke={color({ node: link.source })}
                        strokeWidth={Math.max(10, link.width)}
                        opacity={
                          highlightLinkIndexes.includes(index) ? 0.5 : 0.15
                        }
                        fill="none"
                        onMouseOver={() => {
                          setHighlightLinkIndexes([index]);
                        }}
                        onMouseOut={() => {
                          setHighlightLinkIndexes([]);
                          tooltipTimeout = window.setTimeout(() => {
                            hideTooltip();
                          }, 200);
                        }}
                        onMouseMove={(event) => {
                          if (tooltipTimeout) clearTimeout(tooltipTimeout);
                          const top = event.clientY;
                          const left = event.clientX;
                          showTooltip({
                            tooltipData: link.source,
                            tooltipTop: top,
                            tooltipLeft: left,
                          });
                        }}
                      />
                    );
                  })}
                </Group>
              </Group>
            );
          }}
        </Sankey>
      </svg>
      {tooltipData && tooltipOpen && (
        <TooltipInPortal
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          {tooltip({
            node: { ...tooltipData, ...nodesById[tooltipData.id] },
          })}
        </TooltipInPortal>
      )}
    </>
  );
};

export default SankeyChart;
