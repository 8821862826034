import sortBy from 'lodash/sortBy';

import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';

import { Box } from '@compoundfinance/design-system';
import { NumberUtils } from '../../../../../../utils/NumberUtils';

const BAR_MINIMUM_HEIGHT = 8;

const hasValue = (bar: Bar) => bar.value > 0;

const getRepresentationalHeightByBar = ({
  bars,
  totalHeight,
  minimumBarHeight = 0,
}: {
  bars: Bar[];
  totalHeight: number;
  minimumBarHeight: number;
}) => {
  const representationalHeights: { [Key in string]: number } = {};
  let availableHeight = totalHeight;
  let remainingValue = bars.reduce(
    (remainingValue, bar) => NumberUtils.add(remainingValue, bar.value),
    0,
  );
  for (const bar of sortBy(bars, 'value', 'asc')) {
    const { value } = bar;
    const representationalHeight = Math.max(
      NumberUtils.divide(
        NumberUtils.multiply(availableHeight, value),
        remainingValue,
      ),
      minimumBarHeight,
    );
    representationalHeights[bar.id] = representationalHeight;
    remainingValue = NumberUtils.subtract(remainingValue, value);
    availableHeight = NumberUtils.subtract(
      availableHeight,
      representationalHeight,
    );
  }
  return representationalHeights;
};

type Bar = {
  id: string;
  value: number;
};

const BarChart = ({
  bars,
  width: customWidth,
  minimumBarHeight = BAR_MINIMUM_HEIGHT,
  bar: renderBar,
}: {
  bars: Bar[];
  bar: ({
    bar,
    size,
    positioning,
  }: {
    bar: Bar;
    size: { width: number; height: number };
    positioning: { y: number; x: number };
    chartSize: { width: number; height: number };
  }) => React.ReactNode;
  width?: number;
  minimumBarHeight?: number;
}) => {
  const representationalBars = bars.filter(hasValue);
  return (
    <ParentSize style={{ height: '100%', width: '100%', overflowY: 'auto' }}>
      {({ height: parentHeight, width: parentWidth }) => {
        const width = customWidth ?? parentWidth;
        const height = parentHeight;
        const representationalHeightByBar = getRepresentationalHeightByBar({
          bars: representationalBars,
          minimumBarHeight,
          totalHeight: height,
        });
        const totalHeight =
          Object.values(representationalHeightByBar).reduce(
            NumberUtils.add,
            0,
          ) ?? 0;
        return (
          <svg width={width} height={totalHeight}>
            <Group>
              {representationalBars.map((bar, index) => {
                const { id } = bar;

                const y = representationalBars
                  .slice(0, index)
                  .reduce(
                    (soFar, bar) =>
                      NumberUtils.add(
                        soFar,
                        representationalHeightByBar[bar.id],
                      ),
                    0,
                  );

                return (
                  <Group key={bar.id}>
                    <Box
                      as="foreignObject"
                      width={width}
                      height={totalHeight}
                      css={{ pointerEvents: 'none' }}
                    >
                      {renderBar({
                        bar,
                        size: {
                          width,
                          height: representationalHeightByBar[id],
                        },
                        positioning: { y, x: 0 },
                        chartSize: { width, height: totalHeight },
                      })}
                    </Box>
                  </Group>
                );
              })}
            </Group>
          </svg>
        );
      }}
    </ParentSize>
  );
};

export default BarChart;
