import { Box, LoadingWheel } from '@compoundfinance/design-system';
import { CSS } from '@compoundfinance/design-system/dist/stitches.config';

interface Props {
  children: React.ReactNode;
  css?: CSS;
  isLoading: boolean;
}

function Loadable(props: Props) {
  const { children, css, isLoading } = props;

  return (
    <Box
      css={{
        h: '100%',
        w: '100%',
        ...css,
        position: 'relative',
        display: 'grid',
        placeItems: 'center',
        gridTemplateAreas: '1fr / 1fr',
      }}
    >
      <Box
        css={{
          gridArea: '1 / 1 / 2 / 2',
          h: '100%',
          w: '100%',
          display: 'grid',
          placeItems: 'center',
          transition:
            'backdrop-filter $transitions$default, background-color $transitions$default',
          backdropFilter: isLoading ? 'blur(5px)' : 'none',
          pointerEvents: isLoading ? 'default' : 'none',
          inset: 0,
          bg: isLoading ? 'rgba(255,255,255,0.08)' : 'rgba(255,255,255,0)',
          br: '$8',
        }}
      >
        <LoadingWheel
          size="xlarge"
          css={{
            transition: 'opacity $transitions$default',
            opacity: isLoading ? 1 : 0,
          }}
        />
      </Box>

      <Box
        css={{
          gridArea: '1 / 1 / 2 / 2',
          h: '100%',
          w: '100%',
          minWidth: 0,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

export default Loadable;
