enum CashflowActionTypes {
  GetCashflows = 'Cashflow/GetCashflows',
  GetCashflow = 'Cashflow/GetCashflow',
  UpdateCashflowState = 'Cashflow/UpdateCashflowState',
  UpdateCashflowRequest = 'Cashflow/UpdateCashflowRequest',
  UpdateCashflowRehydrateState = 'Cashflow/UpdateCashflowRehydrateState',
  DeleteCashflow = 'Cashflow/DeleteCashflow',
  SendingRequest = 'Cashflow/SendingRequest',
  ResolveRequest = 'Cashflow/ResolveRequest',
  RejectRequest = 'Cashflow/RejectRequest',
  FetchAccountData = 'Cashflow/FetchAccountData',
  CreateCashflowRequest = 'Cashflow/CreateCashflowRequest',
  GetCashflowRequest = 'Cashflow/GetCashflowRequest',
  DeleteCashflowRequest = 'Cashflow/DeleteCashflowRequest',
  UpdateAccountFilters = 'Cashflow/UpdateAccountFilters',
  BATCH_UPDATE_ACCOUNT_FILTERS = 'Cashflow/BATCH_UPDATE_ACCOUNT_FILTERS',
  DuplicateCashflow = 'Cashflow/Duplicate',
  UpdateCashflowSettings = 'Cashflow/UpdateCashflowSettings',
  Reset = 'Cashflow/Reset',
  ResetToCashflowModal = 'Cashflow/ResetToCashflowModal',
}

export default CashflowActionTypes;
