import {
  DocumentModuleGroup,
  DocumentModuleName,
} from 'containers/Dashboard/Document/modules/modules';
import Introduction, {
  IntroductionProps,
} from 'containers/Dashboard/Document/modules/TaxTransitionAnalysis/Introduction/Introduction';
import Accounts, {
  AccountsProps,
} from 'containers/Dashboard/Document/modules/TaxTransitionAnalysis/Accounts/Accounts';

const TaxTransitionAnalysisModules = {
  [`${DocumentModuleGroup.TaxTransitionAnalysis}.${DocumentModuleName.TaxTransitionAnalysis_Introduction}`]:
    (properties: IntroductionProps) => <Introduction {...properties} />,
  [`${DocumentModuleGroup.TaxTransitionAnalysis}.${DocumentModuleName.TaxTransitionAnalysis_Accounts}`]:
    (properties: AccountsProps) => <Accounts {...properties} />,
};

export default TaxTransitionAnalysisModules;
