export const API_URL = (() => {
  const onCompoundPlanning =
    typeof window !== 'undefined' &&
    window.location.hostname.endsWith('compoundplanning.com');
  const baseDomain = onCompoundPlanning
    ? 'compoundplanning.com'
    : 'withcompound.com';

  if (
    typeof window !== 'undefined' &&
    window.location.hostname.includes('demo')
  ) {
    return `https://demo.internal.${baseDomain}`;
  }
  if (
    typeof window !== 'undefined' &&
    window.location.hostname.includes('alpha')
  ) {
    return `https://alpha.internal.${baseDomain}`;
  }
  // Test if global.window exists due to node env in `parseCompoundInvestModelPortfolioTop50.test.ts`
  if (
    typeof window !== 'undefined' &&
    window.location.hostname.includes('staging')
  ) {
    return `https://api-staging.internal.${baseDomain}`;
  }
  return process.env.NODE_ENV === 'development'
    ? 'http://localhost:3001'
    : `https://api.${baseDomain}`;
})();
