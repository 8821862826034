import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import { IFinancialAdvisor } from 'domain/Employees/types';
import {
  css,
  styled,
} from '@compoundfinance/design-system/dist/stitches.config';
import { Box, Col, Row, Text } from '@compoundfinance/design-system';
import { getPerson } from 'containers/Dashboard/Document/utils';
import EditableTextArea from 'containers/Dashboard/Document/components/EditableTextArea';
import EditablePerson from 'containers/Dashboard/Document/components/EditablePerson';
import {
  DirectoryPerson,
  DirectoryPersonRole,
} from 'domain/Employees/constants';
import MembershipVariationEdit, {
  MembershipVariation,
} from './MembershipVariationEdit';

const DEFAULT_PRICE = '$6,500';

const Container = styled(ModuleContainer, {
  p: '$24',

  '@bp1': {
    py: '$44',
    px: '$48',
  },
});

const Grid = styled(Box, {
  display: 'grid',
  gap: '$24',

  '@bp1': {
    gridTemplateColumns: '1fr 1px .9fr',
    gap: '$48',
  },
});

const MembershipRow = styled(Box, {
  display: 'grid',
  gridTemplateColumns: 'min(100px) auto min(100px) min(100px)',
});

const VerticalSplit = styled('div', {
  border: '1px solid $gray4',
});

const HorizontalLine = css({
  margin: '0 auto',
  display: 'block' /* make the span a block-level element */,
  borderBottom: '1px solid $gray8' /* add a horizontal border */,
  width: '80%' /* set the width to 100% to stretch edge-to-edge */,
  height: '50%',
  minWidth: '$8',
});

const Grid2Column = styled(Box, {
  display: 'grid',
  gap: '$12',

  '@bp1': {
    gap: 0,
    gridTemplateColumns: '0.9fr 1fr',
  },
});

export interface MembershipProps {
  salesLead: DirectoryPerson;
  price?: string;
  variation?: MembershipVariation;
}

function Membership(props: MembershipProps) {
  const { salesLead, price = DEFAULT_PRICE, variation = 'minimum' } = props;

  const salesLeadPerson = getPerson(salesLead) as IFinancialAdvisor;

  return (
    <Container>
      <Col css={{ gap: '$24', '@bp1': { gap: '$48' } }}>
        <Text
          weight="medium"
          color="gray8"
          css={{
            fontSize: '$20',
            letterSpacing: '-0.8px',
            flexShrink: 0,

            '@bp1': { fontSize: '$48', lineHeight: '58px' },
          }}
        >
          Membership
        </Text>

        <Grid>
          <Col css={{ gap: '$20' }}>
            <MembershipVariationEdit variation={variation} price={price} />

            <Col
              css={{ bg: '$gray2', borderRadius: '$8', p: '$16', gap: '$16' }}
            >
              <Row css={{ jc: 'space-between' }}>
                <Text size="13" weight="medium" color="gray12">
                  Assets under management (AUM)
                </Text>
                <Text
                  size="13"
                  weight="medium"
                  color="gray12"
                  css={{ textAlign: 'right' }}
                >
                  Marginal AUM fee
                </Text>
              </Row>

              <Col css={{ gap: '$16' }}>
                <MembershipRow>
                  <Text size="15" color="gray11">
                    $10,000,000+
                  </Text>
                  <Box></Box>
                  <Text
                    size="15"
                    color="gray11"
                    css={{ textAlign: 'right' }}
                  ></Text>
                  <Text size="15" color="gray11" css={{ textAlign: 'right' }}>
                    0.35%
                  </Text>
                </MembershipRow>
                <MembershipRow>
                  <Text size="15" color="gray11">
                    $5,000,000
                  </Text>
                  <Box className={HorizontalLine()}></Box>
                  <Text size="15" color="gray11" css={{ textAlign: 'right' }}>
                    $9,999,999
                  </Text>
                  <Text size="15" color="gray11" css={{ textAlign: 'right' }}>
                    0.45%
                  </Text>
                </MembershipRow>
                <MembershipRow>
                  <Text size="15" color="gray11">
                    $3,000,000
                  </Text>
                  <Box className={HorizontalLine()}></Box>
                  <Text size="15" color="gray11" css={{ textAlign: 'right' }}>
                    $4,999,999
                  </Text>
                  <Text size="15" color="gray11" css={{ textAlign: 'right' }}>
                    0.50%
                  </Text>
                </MembershipRow>
                <MembershipRow>
                  <Text size="15" color="gray11">
                    $2,000,000
                  </Text>
                  <Box className={HorizontalLine()}></Box>
                  <Text size="15" color="gray11" css={{ textAlign: 'right' }}>
                    $2,999,999
                  </Text>
                  <Text size="15" color="gray11" css={{ textAlign: 'right' }}>
                    0.55%
                  </Text>
                </MembershipRow>
                <MembershipRow>
                  <Text size="15" color="gray11">
                    $0
                  </Text>
                  <Box className={HorizontalLine()}></Box>
                  <Text size="15" color="gray11" css={{ textAlign: 'right' }}>
                    $1,999,999
                  </Text>
                  <Text size="15" color="gray11" css={{ textAlign: 'right' }}>
                    0.65%
                  </Text>
                </MembershipRow>
              </Col>
            </Col>

            <EditableTextArea
              propertyKey="description"
              value={`Our membership fee is based on a percentage of assets under management (AUM) with a ${price}/year minimum. The membership fee is billed at the end of each quarterly and can be cancelled anytime.`}
            />

            <EditablePerson
              propertyKey="salesLead"
              propertyValue={salesLead}
              person={salesLeadPerson}
              placeholder="Select Membership Consultant"
              personRole={DirectoryPersonRole.MembershipConsultant}
              size="small"
            />
          </Col>
          <VerticalSplit />

          <Col css={{ gap: '$24' }}>
            <Grid2Column>
              <Text size="13" color="gray10">
                Included with Membership
              </Text>
              <Col css={{ gap: '$12', '@bp1': { gap: '$24' } }}>
                <Text size="13" weight="medium" color="gray12">
                  Financial planning
                </Text>
                <Text size="13" weight="medium" color="gray12">
                  Desktop and mobile platform
                </Text>
                <Text size="13" weight="medium" color="gray12">
                  Alternative investments
                </Text>
                <Text size="13" weight="medium" color="gray12">
                  Third party network access
                </Text>
                <Text size="13" weight="medium" color="gray12">
                  Additional a la carte tax
                </Text>
              </Col>
            </Grid2Column>
            <Grid2Column>
              <Text size="13" color="gray10">
                Included with &gt;$2M AUM
              </Text>
              <Col css={{ gap: '$12', '@bp1': { gap: '$24' } }}>
                <Text size="13" weight="medium" color="gray12">
                  Tax filing
                </Text>
                <Text size="13" weight="medium" color="gray12">
                  Year-end tax planning
                </Text>
              </Col>
            </Grid2Column>
            <Grid2Column>
              <Text size="13" color="gray10">
                Included with &gt;$3M AUM
              </Text>
              <Col css={{ gap: '$12', '@bp1': { gap: '$24' } }}>
                <Text size="13" weight="medium" color="gray12">
                  Quarterly tax estimates 
                </Text>
                <Text size="13" weight="medium" color="gray12">
                  Quarterly tax planning call
                </Text>
                <Text size="13" weight="medium" color="gray12">
                  Basic revocable trust
                </Text>
              </Col>
            </Grid2Column>
            <Grid2Column>
              <Text size="13" color="gray10">
                Included with &gt;$5M AUM
              </Text>
              <Col css={{ gap: '$12', '@bp1': { gap: '$24' } }}>
                <Text size="13" weight="medium" color="gray12">
                  Tax projects on-demand
                </Text>
                <Text size="13" weight="medium" color="gray12">
                  Grantor trust and gift tax returns
                </Text>
              </Col>
            </Grid2Column>
          </Col>
        </Grid>
      </Col>
    </Container>
  );
}

export default Membership;
