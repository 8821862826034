import React from 'react';
import { CompoundDocument } from '../..';
import { REQUEST_TYPES } from 'utils/constants/axios';
import buildRequest from 'utils/api';
import { useCompoundFetch } from 'hooks/useCompoundFetch';
import { KeyedMutator } from 'swr';

type DocumentContextType = {
  document: CompoundDocument;
  mutateDocument: KeyedMutator<CompoundDocument>;
  isEditor: boolean;
};

export const DocumentContext = React.createContext<
  DocumentContextType | undefined
>(undefined);

type Props = {
  isEditor: boolean;
  documentId?: string;
  userId?: string;
  initialDocument?: CompoundDocument;
};

function DocumentProvider(props: React.PropsWithChildren<Props>) {
  const {
    initialDocument,
    userId,
    documentId,
    isEditor = false,
    children,
  } = props;

  const { data: document, mutate: mutateDocument } = useCompoundFetch(
    documentId && userId ? `/api/documents/${documentId}` : null,
    {
      fetcher: async (path: string) => {
        const res = await buildRequest(path, true, {}, REQUEST_TYPES.GET, {
          userId: userId,
        });

        if (!res?.data) throw Error('No data returned from fetcher.');

        return res.data.document;
      },
      options: {
        notForwardUserId: true,
      },
    },
  );

  const value = {
    isEditor,
    document: document || initialDocument,
    mutateDocument,
  };

  return (
    <DocumentContext.Provider value={value}>
      {children}
    </DocumentContext.Provider>
  );
}

export default DocumentProvider;
