/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 */

enum GlobalActionTypes {
  SENDING_REQUEST = 'APP/SENDING_REQUEST',
  COMPLETE_REQUEST = 'APP/COMPLETE_REQUEST',
  LOGIN = 'APP/LOGIN',
  REGISTER = 'APP/REGISTER',
  SET_AUTH = 'APP/SET_AUTH',
  SET_TWO_FACTOR = 'APP/SET_TWO_FACTOR',
  CHECK_IF_AUTHED = 'APP/CHECK_IF_AUTHED',
  LOGOUT = 'APP/LOGOUT',
  ERROR_MESSAGE = 'APP/ERROR_MESSAGE',
  UPDATE_USER = 'APP/UPDATE_USER',
  UPDATE_ACTIVE_TAX_PROFILE = 'APP/UPDATE_ACTIVE_TAX_PROFILE',
  UPDATE_WEALTH_PROFILE = 'APP/UPDATE_WEALTH_PROFILE ',
  UPDATE_USER_AND_INCREMENT = 'APP/UPDATE_USER_AND_INCREMENT',
  COMPLETE_ONBOARDING_SYNC_REQUEST = 'APP/COMPLETE_ONBOARDING_SYNC_REQUEST',
  UPDATE_FILES = 'APP/UPDATE_FILES',
  GET_FILES = 'APP/GET_FILES',
  EQUITY_GUIDE = 'APP/EQUITY_GUIDE',
  UPDATE_GLOBAL_STATE = 'APP/UPDATE_GLOBAL_STATE ',
  SYNC_USER_SETTINGS = 'APP/SYNC_USER_SETTINGS',
  UpdateUserSettings = 'APP/UpdateUserSettings',
  UpdateOwners = 'APP/UpdateOwners',
  UpdateOwnerState = 'APP/UpdateOwnerState',
  SetTaxProfiles = 'APP/SetTaxProfiles',
  UpdateChartSettings = 'APP/UpdateChartSettings',
  UpdateClientAgreement = 'APP/UpdateClientAgreement',
  SetClientAgreement = 'APP/SetClientAgreement',
  UpdateAddress = 'APP/UpdateAddress',
  GetAddressRequest = 'APP/GetAddressRequest',
  UpdateActiveAccountSections = 'APP/UpdateActiveAccountSections',
  SetSessionExpiredModalOpen = 'APP/SetSessionExpiredModalOpen',
  UpdateProductVisibility = 'APP/UpdateProductVisibility',
  UpdateLastOnboardingStep = 'APP/UpdateLastOnboardingStep',
  AcceptToS = 'APP/AcceptToS',
  UpdateTaxViewSettings = 'APP/UpdateTaxViewSettings',
}

export default GlobalActionTypes;
