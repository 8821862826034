export type Investment =
  | {
      name: string;
    }
  | { companyName: string };

export enum CSVSources {
  AngelList = 'angellist',
  ArchLabs = 'arch',
  Spreadsheet = 'spreadsheet',
}

export enum CSVModalStage {
  Import = 'import',
  FundDuplicateFound = 'fundDuplicateFound',
  PrivateDuplicateFound = 'privateDuplicateFound',
  FundFound = 'fundFound',
  PrivateFound = 'privateFound',
}

export type CSVParserResponse = {
  fundInvestments: {
    newInserts: any[];
    override: any[];
  };
  privateInvestments: {
    newInserts?: any[];
    override?: any[];
  };
};

export enum CSVModalTypes {
  Fund = 'fund',
  Private = 'private',
}
