import React, { useEffect } from 'react';
import { DOMUtils } from 'utils/dom';

export type UseAutoScrollOptions = {
  delay?: number;
  offset?: number;
  shouldAutoScroll?: boolean;
  behavior?: ScrollBehavior;
};

type UseAutoScroll = (
  ref: React.RefObject<HTMLElement>,
  options?: UseAutoScrollOptions,
) => void;

const clamp = (from) => (to) => (value) => Math.max(from, Math.min(value, to));
const clampFromZeroTo = clamp(0);

/**
 * @param {React.RefObject<HTMLElement>} ref The reference of the element you want to scroll to
 * @param {UseAutoScrollOptions?} options `behavior`, `delay`, `offset` & `shouldAutoScroll`
 *
 * Takes a ref, and scrolls to it (unless `shouldAutoScroll` prevents it).
 */
const useAutoScroll: UseAutoScroll = (
  ref,
  { delay = 0, shouldAutoScroll = true, offset = 0, behavior = 'smooth' } = {},
) => {
  useEffect(() => {
    if (!shouldAutoScroll) return;
    const element = ref.current;
    if (!element) return;

    const timeoutId = setTimeout(() => {
      /**
       * Solutions which rely on `element.scrollIntoView` work fine most of the time,
       * but are also limitting due to the impossibility of providing an `offset`.
       */
      const scrollableAncestor =
        DOMUtils.getVerticallyScrollableAncestor(element);
      if (!scrollableAncestor) return;

      const clampFromZeroToScrollableHeight = clampFromZeroTo(
        scrollableAncestor.scrollHeight,
      );
      const { height: elementHeight } = element.getBoundingClientRect();
      const positionFromTop = element.offsetTop + elementHeight;
      scrollableAncestor.scrollTo({
        behavior,
        top: clampFromZeroToScrollableHeight(positionFromTop + offset),
      });
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [behavior, delay, offset, ref, shouldAutoScroll]);
};

export default useAutoScroll;
