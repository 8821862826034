import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import { Text, Col, Row, Badge, Box } from '@compoundfinance/design-system';
import sumBy from 'lodash/sumBy';
import moment from 'moment';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';
import UiUtils from 'utils/ui';
import MekkoChart from './MekkoChart';

const Container = styled(ModuleContainer, {
  display: 'grid',
  p: '$24',
  gap: '$24',

  '@bp1': {
    py: '$44',
    px: '$48',
    gap: '$48',
  },
});

const GridThirds = styled(Box, {
  display: 'grid',
  gap: '$24',

  '@bp1': {
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '$48',
  },
});

export interface NetWorthProps {
  chartData: any;
  generatedAt?: Date;
}

function NetWorth(props: NetWorthProps) {
  const { chartData, generatedAt } = props;

  const netWorthTotalFromChartData = sumBy(chartData, 'total');

  return (
    <Container>
      <Col css={{ h: '100%', gap: '$24' }}>
        <Row
          css={{
            jc: 'space-between',
            gap: '$12',
            flexDirection: 'column',
            '@bp1': { flexDirection: 'row' },
          }}
        >
          <Text
            weight="medium"
            color="gray8"
            css={{
              fontSize: '$20',
              letterSpacing: '-0.8px',
              lineHeight: '58px',
              flexShrink: 0,

              '@bp1': { fontSize: '$48' },
            }}
          >
            Net worth
          </Text>

          <Badge size="medium" css={{ color: '$gray11', br: '$6' }}>
            Data imported {moment(generatedAt).fromNow()} at{' '}
            {moment(generatedAt).format('H:mmA z MMMM D, YYYY')}
          </Badge>
        </Row>

        <Col
          css={{
            h: '100%',
            gap: '$24',
            justifyContent: 'space-between',
          }}
        >
          {/* Total net worth number */}
          <GridThirds>
            <Col css={{ gap: '$16' }}>
              <Box css={{ borderBottom: '1px solid $gray4', pb: '$7' }}>
                <Text size="13" color="gray9">
                  Total net worth
                </Text>
              </Box>
              <Text size="24" weight="medium">
                {UiUtils.nFormatter(netWorthTotalFromChartData, 2, false, true)}
              </Text>
            </Col>
          </GridThirds>

          {/* Chart */}
          <Row css={{ minHeight: 325 }}>
            <MekkoChart
              isFiltered={true}
              style={{ width: '100%' }}
              chartData={chartData}
            />
          </Row>
        </Col>
      </Col>
    </Container>
  );
}

export default NetWorth;
