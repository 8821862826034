import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import { IFinancialAdvisor } from 'domain/Employees/types';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';
import { Badge, Box, Col, Row, Text } from '@compoundfinance/design-system';
import Person, {
  PersonSize,
} from 'containers/Dashboard/Document/components/Person';
import { getPerson } from 'containers/Dashboard/Document/utils';
import EditablePerson from 'containers/Dashboard/Document/components/EditablePerson';
import { DirectoryPerson } from 'domain/Employees/constants';

const Container = styled(ModuleContainer, {
  p: '$24',

  '@bp1': {
    py: '$44',
    px: '$48',
  },
});

const GridThirds = styled(Box, {
  display: 'grid',
  gap: '$24',

  '@bp1': {
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '$48',
  },
});

export interface YourTeamProps {
  financialAdvisor: DirectoryPerson;
}

function YourTeam(props: YourTeamProps) {
  const { financialAdvisor } = props;

  const financialAdvisorPerson = getPerson(
    financialAdvisor,
  ) as IFinancialAdvisor;

  const teamPerson = financialAdvisorPerson.team?.map((identifier) =>
    getPerson(identifier),
  );

  return (
    <Container>
      <Col css={{ gap: '$24', '@bp1': { gap: '$48' } }}>
        <Text
          weight="medium"
          color="gray8"
          css={{
            fontSize: '$20',
            letterSpacing: '-0.8px',

            '@bp1': { fontSize: '$48', lineHeight: '58px' },
          }}
        >
          Your team
        </Text>
        <GridThirds>
          <Col css={{ gap: '$24', '@bp1': { gridColumn: '1 / 3' } }}>
            <EditablePerson
              propertyKey="financialAdvisor"
              propertyValue={financialAdvisor}
              person={financialAdvisorPerson}
              size={PersonSize.Large}
            />
            <Text size="14" css={{ maxWidth: '448px', whiteSpace: 'pre-wrap' }}>
              {financialAdvisorPerson.description}
            </Text>
          </Col>
          <Col css={{ gap: '$16', '@bp1': { gridColumn: '3' } }}>
            <Box css={{ borderBottom: '1px solid $gray4', pb: '$7' }}>
              <Text size="13" color="gray9">
                Advisory team
              </Text>
            </Box>
            {teamPerson?.map((person) => (
              <Person key={person.name} person={person} />
            ))}
          </Col>
        </GridThirds>

        <GridThirds>
          {!!financialAdvisorPerson.priorExperience?.length && (
            <Col css={{ gap: '$16' }}>
              <Box css={{ borderBottom: '1px solid $gray4', pb: '$7' }}>
                <Text size="13" color="gray9">
                  Prior experience
                </Text>
              </Box>
              {financialAdvisorPerson.priorExperience?.map((experience) => (
                <Row
                  key={experience.title + experience.subtitle}
                  css={{ ai: 'center', gap: '$12' }}
                >
                  {experience.avatar && (
                    <Box
                      as="img"
                      css={{
                        w: '$40',
                        h: '$40',
                        br: '99999px',
                      }}
                      src={experience.avatar}
                    />
                  )}
                  <Col css={{ gap: '$2' }}>
                    <Text size="13" weight="medium">
                      {experience.title}
                    </Text>

                    <Text size="13" color="gray10">
                      {experience.subtitle}
                    </Text>
                  </Col>
                </Row>
              ))}
            </Col>
          )}
          {!!financialAdvisorPerson.qualifications?.length && (
            <Col css={{ gap: '$16' }}>
              <Box css={{ borderBottom: '1px solid $gray4', pb: '$7' }}>
                <Text size="13" color="gray9">
                  Qualifications
                </Text>
              </Box>
              {financialAdvisorPerson.qualifications?.map((qualifition) => (
                <Row
                  key={qualifition.title + qualifition.subtitle}
                  css={{ ai: 'center', gap: '$12' }}
                >
                  <Col css={{ gap: '$2' }}>
                    <Text size="13" weight="medium">
                      {qualifition.title}
                    </Text>

                    <Text size="13" color="gray10">
                      {qualifition.subtitle}
                    </Text>
                  </Col>
                </Row>
              ))}
            </Col>
          )}
          {!!financialAdvisorPerson.specialties?.length && (
            <Col css={{ gap: '$16' }}>
              <Box css={{ borderBottom: '1px solid $gray4', pb: '$7' }}>
                <Text size="13" color="gray9">
                  Specialties
                </Text>
              </Box>
              <Col css={{ gap: '$6' }}>
                {financialAdvisorPerson.specialties?.map((specialty) => (
                  <Badge
                    key={specialty}
                    variant="rounded"
                    weight="medium"
                    size="medium"
                    color="gray12"
                  >
                    {specialty}
                  </Badge>
                ))}
              </Col>
            </Col>
          )}
        </GridThirds>
      </Col>
    </Container>
  );
}

export default YourTeam;
