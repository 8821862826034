import {
  ArrowDown,
  Button,
  Col,
  DeleteIcon,
  Row,
  Tooltip,
} from '@compoundfinance/design-system';
import { useContext, useRef } from 'react';
import buildRequest from 'utils/api';
import { REQUEST_TYPES } from 'utils/constants/axios';
import ModuleContext from './ModuleContext';

interface MopduleControlsProps {
  children: React.ReactNode;
  module: any;
  mutateDocument: any;
}

function ModuleControls(props: MopduleControlsProps) {
  const { module, mutateDocument } = props;
  const { setIsHover } = useContext(ModuleContext);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const moveUpElementRef = useRef<HTMLButtonElement | null>(null);
  const moveDownElementRef = useRef<HTMLButtonElement | null>(null);

  const onDelete = async (event: React.MouseEvent) => {
    event.stopPropagation();

    const confirmDelete = window.confirm(
      'Are you sure you want to delete this module?',
    );

    if (!confirmDelete) return;

    // update remote
    await buildRequest(
      `/api/documents/modules/${module.id}`,
      true,
      {},
      REQUEST_TYPES.DELETE,
    );

    // revalidate
    mutateDocument();
  };

  const onMoveUp = async (event: React.MouseEvent) => {
    event.stopPropagation();

    // update remote
    await buildRequest(
      `/api/documents/modules/${module.id}/moveUp`,
      true,
      {},
      REQUEST_TYPES.POST,
    );

    // revalidate
    await mutateDocument();
  };

  const onMoveDown = async (event: React.MouseEvent) => {
    event.stopPropagation();

    // update remote
    await buildRequest(
      `/api/documents/modules/${module.id}/moveDown`,
      true,
      {},
      REQUEST_TYPES.POST,
    );

    // revalidate
    await mutateDocument();
  };

  return (
    <Row
      onMouseOver={() => {
        if (containerRef.current?.style) {
          containerRef.current!.style!.visibility = 'visible';
          setIsHover(true);
        }
      }}
      onMouseLeave={() => {
        if (containerRef.current?.style) {
          containerRef.current!.style!.visibility = 'hidden';
          setIsHover(false);
        }
      }}
    >
      {props.children}
      <Col
        ref={containerRef}
        css={{ pl: '$6', gap: '$6', visibility: 'hidden' }}
      >
        {/* Delete module */}
        <Tooltip disableHoverableContent>
          <Tooltip.Content>Delete module</Tooltip.Content>
          <Tooltip.Trigger asChild>
            <Button variant="internal" square onClick={onDelete}>
              <DeleteIcon />
            </Button>
          </Tooltip.Trigger>
        </Tooltip>

        {/* Move module up */}
        <Tooltip disableHoverableContent>
          <Tooltip.Content>Move up</Tooltip.Content>
          <Tooltip.Trigger asChild>
            <Button
              ref={moveUpElementRef}
              variant="internal"
              square
              onClick={onMoveUp}
            >
              <ArrowDown size={16} css={{ transform: 'rotate(180deg)' }} />
            </Button>
          </Tooltip.Trigger>
        </Tooltip>

        {/* Move module down */}
        <Tooltip disableHoverableContent>
          <Tooltip.Content>Move down</Tooltip.Content>
          <Tooltip.Trigger asChild>
            <Button
              ref={moveDownElementRef}
              variant="internal"
              square
              onClick={onMoveDown}
            >
              <ArrowDown size={16} />
            </Button>
          </Tooltip.Trigger>
        </Tooltip>
      </Col>
    </Row>
  );
}

export default ModuleControls;
