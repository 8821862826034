import { ValueOf } from 'types';

export const InvestorQualification = {
  QualifiedPurcharser: 'qualifiedPurchaser',
  AccreditedInvestor: 'accreditedInvestor',
  NonAccreditedInvestor: 'nonAccreditedInvestor',
} as const;
export type InvestorQualification = ValueOf<typeof InvestorQualification>;

export const INVESTOR_QUALIFICATION_LABELS = {
  [InvestorQualification.QualifiedPurcharser]: 'QP',
  [InvestorQualification.AccreditedInvestor]: 'AI',
  [InvestorQualification.NonAccreditedInvestor]: 'Non-AI',
} as const;

export const InvestmentPolicy = {
  AggresiveGrowth: 'aggresiveGrowth',
  ModerateGrowth: 'moderateGrowth',
  Balanced: 'balanced',
  ConservativeBalanced: 'conservativeBalanced',
  CapitalPreservation: 'capitalPreservation',
} as const;
export type InvestmentPolicy = ValueOf<typeof InvestmentPolicy>;

export const INVESTMENT_POLICY_LABELS = {
  [InvestmentPolicy.AggresiveGrowth]: 'Aggressive Growth',
  [InvestmentPolicy.ModerateGrowth]: 'Moderate Growth',
  [InvestmentPolicy.Balanced]: 'Balanced',
  [InvestmentPolicy.ConservativeBalanced]: 'Conservative balanced',
  [InvestmentPolicy.CapitalPreservation]: 'Capital preservation',
} as const;

export const Preference = {
  Alternatives: 'alternatives',
  NoAlternatives: 'noAlternatives',
} as const;
export type Preference = ValueOf<typeof Preference>;

export const PREFERENCE_LABELS = {
  [Preference.Alternatives]: 'Alternatives',
  [Preference.NoAlternatives]: 'No alternatives',
} as const;

export namespace AllocationProposalSettingsTypes {
  export type Settings = {
    id: string;
    investorQualification: InvestorQualification | null;
    investmentPolicy: InvestmentPolicy | null;
    preference: Preference | null;
  };
}
