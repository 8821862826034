enum EstateActionTypes {
  FetchEstateProfile = 'Estate/FetchEstateProfile',
  UpdateEstateProfile = 'Estate/UpdateEstateProfile',
  SetEstateProfile = 'Estate/SetEstateProfile',
  SendingRequest = 'Estate/SendingRequest',
  CompleteRequest = 'Estate/CompleteRequest',
  UpdateWizardStep = 'Estate/UpdateWizardStep',
  SetStatus = 'Estate/SetStatus',
  SetRequestStatus = 'Estate/SetRequestStatus',
  Quicksave = 'Estate/Quicksave',
}

export default EstateActionTypes;
