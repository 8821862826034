import _ from 'lodash';

import RobinhoodLogo from 'assets/images/institutions/robinhood.webp';
import AmexLogo from 'assets/images/institutions/amex.webp';
import BettermentLogo from 'assets/images/institutions/betterment.webp';
import BOALogo from 'assets/images/institutions/boa.webp';
import ChaseLogo from 'assets/images/institutions/chase.webp';
import CitiLogo from 'assets/images/institutions/citi.webp';
import CitizensBankLogo from 'assets/images/institutions/citizens-bank.webp';
import ETradeLogo from 'assets/images/institutions/etrade.webp';
import TitanLogo from 'assets/images/institutions/titan.webp';
import FidelityLogo from 'assets/images/institutions/fidelity.webp';
import TDLogo from 'assets/images/institutions/td-ameritrade.webp';
import WealthfrontLogo from 'assets/images/institutions/wealthfront.webp';
import WellsFargoLogo from 'assets/images/institutions/wells-fargo.webp';
import CharlesSchwabLogo from 'assets/images/institutions/charles-schwab.webp';
import VanguardLogo from 'assets/images/institutions/vanguard.webp';
import SSGALogo from 'assets/images/institutions/ssga.webp';
import iSharesLogo from 'assets/images/institutions/iShares.webp';
import invescoLogo from 'assets/images/institutions/invesco.webp';
import CompoundModelAccount from 'assets/images/institutions/compoundModel.webp';
import CoinbaseLogo from 'assets/images/companies/coinbase.webp';
import CoinbaseProLogo from 'assets/images/companies/coinbase_pro.webp';
import BinanceLogo from 'assets/images/institutions/binance.webp';
import GeminiLogo from 'assets/images/institutions/gemini.webp';
import TrezorLogo from 'assets/images/institutions/trezor.webp';
import BitmexLogo from 'assets/images/institutions/bitmex.webp';
import LedgerLogo from 'assets/images/institutions/ledger.webp';
import HuobiLogo from 'assets/images/institutions/huobi.webp';
import FirstTrustCALogo from 'assets/images/institutions/first-trust-california.webp';
import NewportGroup from 'assets/images/institutions/newport.svg';
import CompoundIntelligentInvestLogo from 'assets/images/institutions/compoundIntelligent.webp';
import EthereumLogo from 'assets/images/security/crypto/ethereum.png';
import BitcoinLogo from 'assets/images/security/crypto/bitcoin.png';

import { PlaidAccount } from '@compoundfinance/compound-core/dist/types/plaid';
import { InstitutionName } from 'utils/plaid/constants';
import { getEnumValues } from 'utils/constants';

export const InstitutionLogos = {
  [InstitutionName.RobinHood]: RobinhoodLogo,
  [InstitutionName.Chase]: ChaseLogo,
  [InstitutionName.ETrade]: ETradeLogo,
  [InstitutionName.Titan]: TitanLogo,
  [InstitutionName.TDBank]: TDLogo,
  [InstitutionName.WellsFargo]: WellsFargoLogo,
  [InstitutionName.Betterment]: BettermentLogo,
  [InstitutionName.Citi]: CitiLogo,
  [InstitutionName.CitizensBank]: CitizensBankLogo,
  [InstitutionName.Fidelity]: FidelityLogo,
  [InstitutionName.Amex]: AmexLogo,
  [InstitutionName.Wealthfront]: WealthfrontLogo,
  [InstitutionName.CharlesSchwab]: CharlesSchwabLogo,
  [InstitutionName.BankOfAmerica]: BOALogo,
  [InstitutionName.Vanguard]: VanguardLogo,
  [InstitutionName.StateStreetGlobalAdvisors]: SSGALogo,
  [InstitutionName.iShares]: iSharesLogo,
  [InstitutionName.Invesco]: invescoLogo,
  [InstitutionName.Compound]: CompoundModelAccount,
  [InstitutionName.CoinbasePro]: CoinbaseProLogo,
  [InstitutionName.Coinbase]: CoinbaseLogo,
  [InstitutionName.Binance]: BinanceLogo,
  [InstitutionName.Gemini]: GeminiLogo,
  [InstitutionName.Trezor]: TrezorLogo,
  [InstitutionName.Bitmex]: BitmexLogo,
  [InstitutionName.Ledger]: LedgerLogo,
  [InstitutionName.Huobi]: HuobiLogo,
  [InstitutionName.FirstTrustCA]: FirstTrustCALogo,
  [InstitutionName.NewportGroup]: NewportGroup,
  [InstitutionName.CompoundIntelligentInvest]: CompoundIntelligentInvestLogo,
  [InstitutionName.Ethplorer]: EthereumLogo,
  [InstitutionName.BlockchainDotCom]: BitcoinLogo,
};

const INSTITUTIONS_WITH_LOGOS = getEnumValues(InstitutionName);

function getInstitutionImage(account: PlaidAccount, name?: InstitutionName) {
  if (name && name in InstitutionLogos) {
    return InstitutionLogos[name];
  }

  const existingInstitution = account.institution?.name;

  if (existingInstitution && InstitutionLogos[existingInstitution]) {
    return InstitutionLogos[existingInstitution];
  }

  // Check if the institution name matches an institution with a logo
  if (existingInstitution) {
    const matchedInstitution = INSTITUTIONS_WITH_LOGOS.find((k) =>
      _.toLower(existingInstitution).includes(_.toLower(k)),
    );

    if (matchedInstitution) {
      return InstitutionLogos[matchedInstitution];
    }
  }

  // Check if the account name matches an institution with a logo
  const matchedInstitution = INSTITUTIONS_WITH_LOGOS.find((k) =>
    _.toLower(account.name).includes(_.toLower(k)),
  );

  // Explicitly return null if we cannot find the matched institution
  return matchedInstitution ? InstitutionLogos[matchedInstitution] : null;
}

export default getInstitutionImage;
