import {
  AvailableOwnerColors,
  OwnerType,
} from '@compoundfinance/compound-core/dist/types/account';

export const OwnerTypeLabels = {
  [OwnerType.LLC]: 'LLC / Company',
  [OwnerType.Spouse]: 'Spouse',
  [OwnerType.Trust]: 'Trust',
  [OwnerType.OtherFamilyMember]: 'Other family member',
  [OwnerType.DonorAdvisedFund]: 'Donor Advised Fund',
  [OwnerType.FamilyFoundations]: 'Private Foundation',
};

export const OWNER_TYPE_OPTIONS = [
  { value: OwnerType.LLC, label: OwnerTypeLabels[OwnerType.LLC] },
  { value: OwnerType.Spouse, label: OwnerTypeLabels[OwnerType.Spouse] },
  { value: OwnerType.Trust, label: OwnerTypeLabels[OwnerType.Trust] },
  {
    value: OwnerType.OtherFamilyMember,
    label: OwnerTypeLabels[OwnerType.OtherFamilyMember],
  },
  {
    value: OwnerType.DonorAdvisedFund,
    label: OwnerTypeLabels[OwnerType.DonorAdvisedFund],
  },
  {
    value: OwnerType.FamilyFoundations,
    label: OwnerTypeLabels[OwnerType.FamilyFoundations],
  },
];

export const IMAGE_COLORS: AvailableOwnerColors[] = [
  'tomato',
  'red',
  'crimson',
  'pink',
  'plum',
  'violet',
  'indigo',
  'blue',
  'cyan',
  'teal',
  'grass',
  'brown',
  'orange',
  'gray',
];
