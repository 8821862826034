import isNil from 'lodash/isNil';

import { CompoundRoutes } from './constants/url';
import { CompoundColor } from 'components/types';
import { COMPOUND_COLORS } from 'style/theme';
import { PHONE_NUMBER_MASK } from './constants';
import { IMAGE_COLORS } from 'containers/Dashboard/Accounts/OwnerManagementModal/constants';

const N_FORMATTED_VALUE_SYM_MATCHER = /(-?\d+\.?\d*)([A-Z])/;

function sanitizeNum(num) {
  return isNil(num) ? 'N/A' : sanitize(num, true);
}

/**
 * Depending on how large the input number is, format it to something that
 * can be comfortably displayed while retaining enough precision for small numbers.
 *
 * 0.00981778242 -> $0.0098
 * 18.1 -> $18.10
 * undefined -> N/A
 */
function sanitizeWithPrecision(num) {
  return isNil(num)
    ? 'N/A'
    : Math.abs(num) < 1
    ? sanitizeToSigFigs(num, 2)
    : sanitize(num, true);
}

/**
 * Formats and rounds input number to a certain number of significant figures.
 *
 * examples for 2 significant figures:
 * 0.981778242 -> $0.98
 * 0.0003183 -> $0.00031
 * 0.1 -> $0.10
 * 0.00000000001 -> $0.00
 */
function sanitizeToSigFigs(num: number, numSigFigs: number) {
  const result = NumberHelper(num);
  // if it's too small, toPrecision will show exponential notation.
  const rounded =
    num <= 0.000001 ? '0.00' : Number(result.value.toPrecision(numSigFigs));
  return `${result.sign}$${rounded}`;
}

function sanitize(num: number, isDollar?: boolean) {
  const fixedNum = num.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: isDollar ? 'currency' : 'decimal',
    currency: isDollar ? 'USD' : undefined,
  });

  return fixedNum;
}

const sanitizeAndRound = (num, isDollar?): string =>
  Math.round(num).toLocaleString('en-US', {
    style: isDollar ? 'currency' : 'decimal',
    currency: isDollar ? 'USD' : undefined,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

/**
 * Formats number to use a symbol to depict 10e3 increments. E.g., 1,000,000 would be 1M etc.
 * @param num value to format
 * @param precision number of decimal digits to use. E.g., 1,234 formatted with precision 2 would be 1.23K. 2 by default
 * @param includeTrailingZeros if true, trailing decimals can be zeros. E.g., 10 formatted with precision 2 and trailing zeros would be 10.00. False by default
 * @param isDollar if true, automatically attaches dollar sign in front of number and formats appropriately
 * @param isLowerCase if true, the si units will be returned in lower case
 */
function nFormatter(
  num: number,
  precision: number = 2,
  includeTrailingZeros: boolean = false,
  isDollar?: boolean,
  isLowerCase?: boolean,
  roundUpwards?: boolean,
) {
  const absNum = Math.abs(num);
  let formattedNum = '';
  const numToUse = isDollar ? absNum : num;
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (absNum >= si[i].value) {
      break;
    }
  }
  const siSymbol = isLowerCase ? si[i].symbol.toLowerCase() : si[i].symbol;
  let fixedPrecision;
  if (roundUpwards) {
    fixedPrecision = Math.ceil(
      Number((numToUse / si[i].value).toFixed(1)),
    ).toString();
  } else {
    fixedPrecision = (numToUse / si[i].value).toFixed(precision);
  }
  if (includeTrailingZeros) {
    formattedNum = fixedPrecision + siSymbol;
  } else {
    formattedNum = (fixedPrecision.replace(rx, '$1') + siSymbol) as any;
  }
  const pref = num < 0 ? '-' : '';
  return isDollar ? `${pref}$${formattedNum}` : formattedNum;
}

function NumberHelper(number: number | string) {
  let num = Number(number);
  let sign = '';

  if (num < 0) {
    sign = '-';
    num = Math.abs(num);
  }

  return {
    sign,
    value: num,
  };
}

interface PrecisionFormatter {
  number: number;
  precision?: number;
}

/**
 * Companion function to nFormatter. Used to force a float into a specified number of digits.
 * Designed to produce human-readable strings for display.
 *
 * This function preserves the symbol added by nFormatter, and adds a dollar sign to the output
 * For example, assming a precision of 3
 *
 * 820 -> $820
 * 24 -> $24.0
 * 0 -> $0.00
 * 5,200,000 -> $5.20M
 * 2,400 -> 2.40K
 *
 * Because nFormatter used toFixed,
 * trailing zeros won't be added / preserved
 *
 */
function nFormatterToPrecision({ number, precision = 3 }: PrecisionFormatter) {
  // Let nformatter spit out its fixed and formatted number string
  const formatted = nFormatter(number);
  const matches = formatted.match(N_FORMATTED_VALUE_SYM_MATCHER);

  let symbol: string;
  let num: ReturnType<typeof NumberHelper>;

  if (!matches) {
    num = NumberHelper(formatted);
    symbol = '';
  } else {
    const [, value, sym] = matches;

    num = NumberHelper(value);
    symbol = sym;
  }

  return `${num.sign}$${num.value.toPrecision(precision).slice(0, 4)}${symbol}`;
}

function isHexColor(string: string) {
  return string.match(/^#[0-9A-Fa-f]{6}$/);
}
function getColor(color: string, shade = 9) {
  if (isHexColor(color)) return color;
  return `var(--colors-${color}${shade})`;
}

function getPercent(val, total): number {
  if (!total) {
    return 0;
  }

  return Math.round((val / total) * 10000) / 100;
}

function roundToHundredth(val) {
  return Math.round(100 * val) / 100;
}

function roundToNearest10(value) {
  if (!value) return 0;
  const [integer] = String(Math.abs(value)).split('.');
  const mostSignificantValue = integer[0] ?? '';
  const integerPlaces = integer.length;
  const unit = Number('1'.padEnd(integerPlaces, '0'));
  const lowerBound = Number(mostSignificantValue.padEnd(integerPlaces, '0'));
  const upperBound = lowerBound + unit;
  const half = (lowerBound + upperBound) / 2;
  if (value >= half) return Math.sign(value) * upperBound;
  return Math.sign(value) * half;
}

function formatDecimals(
  val: number | null,
  min?: number | null,
  max?: number | null,
  isDollar?: boolean,
) {
  const maxFractions = !isNil(max) ? max : 3;
  const minFractions = !isNil(min) ? min : 2;
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: Math.min(minFractions, maxFractions),
    maximumFractionDigits: maxFractions,
    style: isDollar ? 'currency' : 'decimal',
    currency: isDollar ? 'USD' : undefined,
  });

  const formatted = formatter.format(val ?? 0);

  return formatted;
}

/**
 * Formats input value in `$1,000.12` format. Omits the decimal suffix if it's `.00`
 *
 * @param val number to format
 * @returns formatted value
 */
function formatMoney(val: number) {
  const formattedVal = sanitize(val, true);
  return formattedVal.replace(/\.00/, '');
}

/**
 * Returns (***) ***-1234
 * @param phoneNumber A US-like phone number with no special suffixes.
 * @returns
 */
function maskPhoneNumber(phoneNumber: string) {
  return phoneNumber && `${PHONE_NUMBER_MASK}${phoneNumber.slice(-4)}`;
}

function capitalizeFirstLetters(str: string) {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substring(1),
  );
}

function titleCase(str: string, keyWordsToOmit?: string[]) {
  return str.replace(/\w\S*/g, (txt) => {
    const remainingChar = keyWordsToOmit?.includes(txt)
      ? txt.substring(1)
      : txt.substring(1).toLowerCase();
    return txt.charAt(0).toUpperCase() + remainingChar;
  });
}

function camelToSentenceCase(s: string) {
  return s
    .replace(/([A-Z])/g, (match) => ` ${match.toLowerCase()}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();
}

const camelToCapitalCase = (s: string) =>
  s.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase();
  });

/**
 * Note that this will not handle strings which have a capitalized final letter. A more sophisticated
 * regex is needed for that.
 *
 * It will handle strings that are true `camelCase`, and well as string that are `CapitalCase`.
 * @param s ex: CapitalCaseString
 * @returns capital_case_string
 */
const camelToSnakeCase = (s: string) => {
  const result = s.replace(/([A-Z])/g, ' $1');
  // thanks tomi
  const trimmed = result.trimStart();
  return trimmed.split(' ').join('_').toLowerCase();
};

const truncateStr = (s: string, maxLen: number) => {
  if (s.length > maxLen) {
    return `${s.substring(0, maxLen)}...`;
  }
  return s;
};

const sleep = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

function pluralize(count: number, word: string, suffix = 's') {
  if (count === 1) return word;
  return `${word}${suffix}`;
}

const isAccountsPageTable = (isAdvisor: boolean) =>
  window.location.pathname === CompoundRoutes.Accounts || isAdvisor;

function getFirstNonZeroDigit(numberString: string) {
  const stringAfterZero = numberString.replace(/\.(0+)?/, '');
  return stringAfterZero.length !== numberString.length
    ? numberString.length - stringAfterZero.length
    : -1;
}
/**
 * Pass in an event and check if you are clicking a label. Use when a checkbox
 * or radio button falls on top of another clickable item and you need to ensure you are
 * NOT clicking on that label
 * @param e
 */
function notLabelClick(e: any) {
  return !(e.target && e.target.closest('label'));
}

function SVGColor(
  color: string | CompoundColor | undefined,
  defaultColor = COMPOUND_COLORS.black,
) {
  return color ? COMPOUND_COLORS[color] : defaultColor;
}

const usOrCanadianPhoneRegExp =
  /(?<countryCode>\+1)?(?<areaCode>[0-9]{3})(?<phoneNumberThreeFirstDigits>[0-9]{3})(?<phoneNumberLastFourDigits>[0-9]{4})/;
/**
 * Attempts to format the supplied string as a US phone number
 */
function formatPhoneNumberUS(phoneNumber: string | null) {
  if (!phoneNumber) {
    return '-';
  }

  const matches = phoneNumber.match(usOrCanadianPhoneRegExp);

  if (!matches) {
    return;
  }

  const {
    countryCode = '+1',
    areaCode,
    phoneNumberThreeFirstDigits,
    phoneNumberLastFourDigits,
  } = matches.groups as {
    countryCode: '+1';
    areaCode: string;
    phoneNumberThreeFirstDigits: string;
    phoneNumberLastFourDigits: string;
  };

  return `${countryCode} (${areaCode})-${phoneNumberThreeFirstDigits}-${phoneNumberLastFourDigits}`;
}

/**
 * Gives us appropriate greeting depending on current time.
 */
function getGreeting(date: string | Date = new Date()) {
  const currentHour = new Date(date).getHours();

  if (currentHour >= 5 && currentHour < 12) {
    return 'Good morning';
  } else if (currentHour >= 12 && currentHour < 18) {
    return 'Good afternoon';
  }

  return 'Good evening';
}

// https://theartincode.stanis.me/008-djb2/
function stringToColorHash(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) + hash + str.charCodeAt(i); // Multiply by 33 and add character code
    hash |= 0; // Convert to 32-bit integer
  }
  return Math.abs(hash);
}

function getImageVisColor(key: string) {
  const hash = stringToColorHash(key);
  const colorIndex = hash % IMAGE_COLORS.length;
  return IMAGE_COLORS[colorIndex];
}

/**
 * Formats the input number in Roman numerals. E.g., 1917 -> MCMXVII
 * Copied from https://blog.stevenlevithan.com/archives/javascript-roman-numeral-converter
 * @param number number to format
 * @returns formatted value
 */
function toRoman(number: number): string {
  if (isNaN(number)) {
    return 'N/A';
  }
  let digits = String(number).split('');
  const key = [
    '',
    'C',
    'CC',
    'CCC',
    'CD',
    'D',
    'DC',
    'DCC',
    'DCCC',
    'CM',
    '',
    'X',
    'XX',
    'XXX',
    'XL',
    'L',
    'LX',
    'LXX',
    'LXXX',
    'XC',
    '',
    'I',
    'II',
    'III',
    'IV',
    'V',
    'VI',
    'VII',
    'VIII',
    'IX',
  ];
  let roman = '';
  let i = 3;
  while (i--) {
    const digit = digits.pop()!;
    roman += key[+digit + i * 10] || '';
  }
  const leftoverDigits = +digits.join('');
  return Array(leftoverDigits + 1).join('M') + roman;
}

export {
  sanitizeNum,
  sanitizeAndRound,
  sanitizeWithPrecision,
  capitalizeFirstLetters,
  formatDecimals,
  formatMoney,
};

const UiUtils = {
  sanitizeNum,
  sanitizeAndRound,
  sanitize,
  nFormatter,
  getPercent,
  getGreeting,
  getColor,
  roundToHundredth,
  capitalizeFirstLetters,
  titleCase,
  formatDecimals,
  formatMoney,
  maskPhoneNumber,
  roundToNearest10,
  camelToCapitalCase,
  truncateStr,
  sleep,
  nFormatterToPrecision,
  pluralize,
  isAccountsPageTable,
  getFirstNonZeroDigit,
  notLabelClick,
  SVGColor,
  camelToSnakeCase,
  camelToSentenceCase,
  formatPhoneNumberUS,
  getImageVisColor,
  toRoman,
};

export default UiUtils;
