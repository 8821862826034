import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';
import { Col, Grid } from '@compoundfinance/design-system';
import EditableTextArea from 'containers/Dashboard/Document/components/EditableTextArea';
import { ILinkPreview } from 'domain/Employees/types';
import { getPerson } from 'containers/Dashboard/Document/utils';
import LinkEditor from './LinksEditor';
import EditablePerson from 'containers/Dashboard/Document/components/EditablePerson';
import { DirectoryPerson } from 'domain/Employees/constants';
import { EditableSectionTitle } from '../../../components/SectionTitle';

const Container = styled(ModuleContainer, {
  p: '$24',

  '@bp1': {
    py: '$44',
    px: '$48',
  },
});

export type SectionTitleProps = {
  title: string;
};

const GridHalfs = styled(Grid, {
  ai: 'start',
  gap: '$48',

  '@bp1': {
    gridTemplateColumns: '1fr 1fr',
  },
});

export interface CustomProps {
  title: string;
  customText: string;
  financialAdvisor: DirectoryPerson;
  linkPreviews: ILinkPreview[];
}

function Custom(props: CustomProps) {
  const { title, customText, financialAdvisor, linkPreviews } = props;

  const financialAdvisorPerson = getPerson(financialAdvisor);

  return (
    <Container>
      <Col css={{ gap: '$24', '@bp1': { gap: '$48' } }}>
        <EditableSectionTitle title={title} />
        <GridHalfs>
          <Col css={{ gap: '$16' }}>
            <EditableTextArea propertyKey="customText" value={customText} />
            <EditablePerson
              propertyKey="financialAdvisor"
              propertyValue={financialAdvisor}
              person={financialAdvisorPerson}
              size="small"
            />
          </Col>
          <Grid css={{ gap: '$16' }}>
            <LinkEditor
              propertyKey="linkPreviews"
              linkPreviews={linkPreviews}
            />
          </Grid>
        </GridHalfs>
      </Col>
    </Container>
  );
}

export default Custom;
