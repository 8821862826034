import { motion } from 'framer-motion';
import React, { useMemo, useRef } from 'react';
import { bisector } from 'd3';
import { Group } from '@visx/group';
import { curveBasis } from '@visx/curve';
import { localPoint } from '@visx/event';
import { ParentSize } from '@visx/responsive';
import { scaleTime, scaleLinear } from '@visx/scale';
import { LinePath } from '@visx/shape';
import { useTooltip, TooltipWithBounds, defaultStyles } from '@visx/tooltip';

import {
  Col,
  Flex,
  Row,
  TColorsKey,
  Text,
} from '@compoundfinance/design-system';

import { AllocationProposalTypes } from '../../domain/AllocationProposal';
import { NumberUtils } from '../../../../../utils/NumberUtils';
import { SquareGrid } from './Chartifacts/Grids/SquareGrid';
import { XAxis } from './Chartifacts/Axis/XAxis';
import { YAxis } from './Chartifacts/Axis/YAxis';

type Metadata = {
  label: string;
  bg: string;
  stroke: string;
  color: TColorsKey;
};

type AccumulatedPerformance = {
  annualReturn: number;
  cumulativeAnnualReturn: number;
  year: number;
};

type AccumulatedPerformanceWithMetadata = {
  id: string;
  performance: AccumulatedPerformance;
  metadata?: Metadata;
};

const accumulatePerformance = ({
  annualReturn,
  currentYear,
  years,
}: {
  annualReturn: number;
  currentYear: number;
  years: number;
}): Record<number, AccumulatedPerformance> => {
  const { performanceProjectionOverYears } = Array.from(
    { length: years },
    (_, year) => year + 1,
  ).reduce(
    ({ performanceProjectionOverYears }, year) => {
      const lastYearPerformance = performanceProjectionOverYears[year - 1];
      const {
        annualReturn: lastYearReturn,
        cumulativeAnnualReturn: lastYearCumulativeAnnualReturn,
      } = lastYearPerformance;
      const yearReturn = NumberUtils.multiply(
        lastYearReturn,
        1 + NumberUtils.divide(annualReturn, 100),
      );
      return {
        performanceProjectionOverYears: {
          ...performanceProjectionOverYears,
          [year]: {
            annualReturn: yearReturn,
            cumulativeAnnualReturn: NumberUtils.add(
              lastYearCumulativeAnnualReturn,
              yearReturn,
            ),
            year: currentYear + year,
          },
        },
      };
    },
    {
      performanceProjectionOverYears: {
        0: {
          annualReturn: annualReturn,
          cumulativeAnnualReturn: annualReturn,
          year: currentYear,
        },
      },
    },
  );
  return performanceProjectionOverYears;
};

const order = (number) => Math.floor(Math.log(number) / Math.LN10);
const roundup = (number) => {
  if (number === 0) return 0;
  const positions = 10 ** order(number);
  return Math.ceil(number / positions) * positions;
};

const ALLOCATION_PROPOSAL_METADA = {
  label: 'Initial',
  bg: 'var(--colors-gray3)',
  stroke: 'var(--colors-gray8)',
  color: 'gray11',
} as const;

const ALLOCATION_PROPOSAL_STRATEGY_AXIS_GAP = 10;
const ALLOCATION_PROPOSAL_STRATEGY_AXIS_HEIGHT = 50;
const ALLOCATION_PROPOSAL_STRATEGY_AXIS_WIDTH = 80;

const getX = (data: Pick<AccumulatedPerformance, 'year'>) =>
  new Date(Date.UTC(data.year, 1, 0));
const getY = (data: Pick<AccumulatedPerformance, 'cumulativeAnnualReturn'>) =>
  data.cumulativeAnnualReturn;

type PerfomanceChartProps = {
  blendedAllocationProposals: Array<{
    id: string;
    blended: AllocationProposalTypes.BlendendAllocationProposal;
    metadata?: Metadata;
  }>;
  years?: number;
  grid?: ({
    height,
    width,
    points,
  }: {
    height: number;
    width: number;
    points: number;
  }) => React.ReactNode;
};

const PerformanceChart = ({
  blendedAllocationProposals,
  years = 30,
  grid = ({ height, width, points }) => (
    <SquareGrid height={height} width={width} points={points} />
  ),
}: PerfomanceChartProps) => {
  const {
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
    showTooltip,
    hideTooltip,
  } = useTooltip<{
    performances: AccumulatedPerformanceWithMetadata[];
    x: number;
    y: number;
  }>();

  const currentYear = useRef(new Date().getFullYear()).current;

  const performances = useMemo(
    () =>
      blendedAllocationProposals.map((blendedAllocationProposal) => {
        const {
          blended: { annualReturn },
          ...rest
        } = blendedAllocationProposal;
        const performanceProjectionOverYears = accumulatePerformance({
          annualReturn: annualReturn,
          years,
          currentYear,
        });
        return {
          performance: Object.values(performanceProjectionOverYears),
          ...rest,
        };
      }),
    [blendedAllocationProposals, currentYear, years],
  );
  const minCumulativeAnnualReturn = Math.min(
    ...performances.map(
      ({ performance }) => performance[0].cumulativeAnnualReturn,
    ),
  );
  const maxCumulativeAnnualReturn = Math.max(
    ...performances.map(
      ({ performance }) => performance[years].cumulativeAnnualReturn,
    ),
  );

  const shouldShowTooltip = !!tooltipData;

  return (
    <ParentSize style={{ width: '100%', height: '100%' }}>
      {({ height, width }) => {
        const chartHeight = height - ALLOCATION_PROPOSAL_STRATEGY_AXIS_HEIGHT;
        const chartWidth =
          width -
          ALLOCATION_PROPOSAL_STRATEGY_AXIS_GAP -
          ALLOCATION_PROPOSAL_STRATEGY_AXIS_WIDTH -
          ALLOCATION_PROPOSAL_STRATEGY_AXIS_GAP;

        const yScale = scaleLinear({
          range: [chartHeight, 0],
          domain: [
            minCumulativeAnnualReturn - roundup(minCumulativeAnnualReturn),
            roundup(maxCumulativeAnnualReturn),
          ],
        });
        const xScale = scaleTime({
          range: [0, chartWidth],
          domain: [
            getX({ year: currentYear }),
            getX({ year: currentYear + years }),
          ],
        });

        const handleTooltip = (event) => {
          const bisectTime = bisector(getX).left;

          const { x, y } = localPoint(event) || { x: 0, y: 0 };
          const date = xScale.invert(x);

          const entries = performances.map(({ performance, ...rest }) => {
            const middleIndex: number = bisectTime(performance, date, 1);
            const leftPerformance = performance[middleIndex - 1];
            const rightPerformance = performance[middleIndex];
            const index =
              date.valueOf() - getX(leftPerformance).valueOf() >
              getX(rightPerformance).valueOf() - date.valueOf()
                ? middleIndex
                : middleIndex - 1;
            return { ...rest, performance: performance[index] };
          });

          const hasData = entries.length > 0;
          showTooltip({
            tooltipData: hasData
              ? {
                  performances: entries,
                  x,
                  y,
                }
              : undefined,
            tooltipLeft: x,
            tooltipTop: yScale(getY(entries[0].performance)),
          });
        };

        return (
          <Flex css={{ position: 'relative' }}>
            <svg width={width} height={height}>
              {grid({
                points: years,
                height: chartHeight,
                width: chartWidth,
              })}

              <XAxis width={chartWidth} height={chartHeight} points={years}>
                {({ index, x, y }) => {
                  const isFirst = index === 0;
                  const isLast = index === years - 1;
                  if (index % 5 !== 0 && !isFirst && !isLast) return null;
                  return (
                    <foreignObject
                      y={y}
                      x={x - (isFirst ? 0 : isLast ? 30 : 30 / 2)}
                      height={ALLOCATION_PROPOSAL_STRATEGY_AXIS_HEIGHT}
                      width={ALLOCATION_PROPOSAL_STRATEGY_AXIS_WIDTH}
                    >
                      <Text size="11">{currentYear + index}</Text>
                    </foreignObject>
                  );
                }}
              </XAxis>

              <YAxis values={[0, roundup(maxCumulativeAnnualReturn)]}>
                {({ value }) => (
                  <foreignObject
                    y={yScale(value) - (value === 0 ? 10 : 0)}
                    x={chartWidth + ALLOCATION_PROPOSAL_STRATEGY_AXIS_GAP}
                    height={20}
                    width={ALLOCATION_PROPOSAL_STRATEGY_AXIS_WIDTH}
                  >
                    <Flex
                      css={{
                        h: '100%',
                        w: '100%',
                        display: 'grid',
                        placeItems: 'center',
                      }}
                    >
                      <Text size="11" color="gray9">
                        {NumberUtils.sanitize(value, 'Display')}%
                      </Text>
                    </Flex>
                  </foreignObject>
                )}
              </YAxis>

              <Group>
                {performances.map(
                  ({
                    id,
                    performance,
                    metadata = ALLOCATION_PROPOSAL_METADA,
                  }) => {
                    const latestDataPointY = Math.max(
                      0,
                      yScale(getY(performance.at(-1)!)) - 80 / 2,
                    );
                    return (
                      <React.Fragment key={id}>
                        <LinePath
                          data={performance}
                          x={(datum) => xScale(getX(datum)) ?? 0}
                          y={(datum) => yScale(getY(datum)) ?? 0}
                          curve={curveBasis}
                        >
                          {({ path }) => (
                            <motion.path
                              initial={false}
                              className="visx-linepath"
                              animate={{
                                d: path(performance) ?? '',
                              }}
                              transition={{
                                duration: 0.25,
                                ease: 'easeInOut',
                              }}
                              fill="transparent"
                              strokeLinecap="round"
                              stroke={metadata.stroke}
                              strokeWidth={1.5}
                            />
                          )}
                        </LinePath>

                        <foreignObject
                          x={chartWidth + ALLOCATION_PROPOSAL_STRATEGY_AXIS_GAP}
                          y={latestDataPointY}
                          width={ALLOCATION_PROPOSAL_STRATEGY_AXIS_WIDTH}
                          height={chartHeight}
                          style={{ pointerEvents: 'none' }}
                        >
                          <Flex
                            css={{
                              maxHeight: `calc(${height}px - ${latestDataPointY}px)`,
                              bg: metadata.bg,
                              borderRadius: '$4',
                              p: '$2',
                              jc: 'center',
                              ai: 'start',
                              pointerEvents: 'all',
                              overflow: 'hidden',
                            }}
                          >
                            <Text
                              size="12"
                              color={metadata.color}
                              css={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'wrap',
                                overflow: 'hidden',
                              }}
                            >
                              {metadata.label}
                            </Text>
                          </Flex>
                        </foreignObject>
                      </React.Fragment>
                    );
                  },
                )}
              </Group>

              <rect
                style={{ position: 'relative' }}
                x={0}
                y={0}
                width={chartWidth}
                height={chartHeight}
                onTouchStart={handleTooltip}
                onTouchMove={handleTooltip}
                onMouseMove={handleTooltip}
                onMouseLeave={hideTooltip}
                fill="transparent"
              />
            </svg>

            {shouldShowTooltip && (
              <TooltipWithBounds
                key={getX(
                  tooltipData.performances[0].performance,
                )?.toISOString()}
                top={tooltipTop}
                left={tooltipLeft}
                style={{
                  ...defaultStyles,
                  background: 'transparent',
                  border: 'none',
                  boxShadow: 'none',
                }}
              >
                <Col
                  css={{
                    bg: '$gray0',
                    p: '$8',
                    br: '10px',
                    boxShadow: '$small',
                    gap: '$2',
                  }}
                >
                  <Text size="13" weight="medium" color="gray13">
                    Performance
                  </Text>
                  <Col>
                    {tooltipData.performances.map((performanceWithMetadata) => {
                      const {
                        id,
                        performance,
                        metadata = ALLOCATION_PROPOSAL_METADA,
                      } = performanceWithMetadata;
                      return (
                        <Row
                          key={id}
                          css={{
                            gap: '$15',
                            jc: 'space-between',
                            ai: 'center',
                          }}
                        >
                          <Text size="12" color={metadata.color}>
                            {metadata.label}
                          </Text>
                          <Text size="12" color="gray9">
                            {NumberUtils.sanitize(
                              performance.cumulativeAnnualReturn,
                              'Display',
                            )}
                            %
                          </Text>
                        </Row>
                      );
                    })}
                  </Col>
                </Col>
              </TooltipWithBounds>
            )}
          </Flex>
        );
      }}
    </ParentSize>
  );
};

export default React.memo(PerformanceChart);
