import {
  DocumentModuleGroup,
  DocumentModuleName,
} from 'containers/Dashboard/Document/modules/modules';
import QuarterlyPerformanceUpdateIntroduction, {
  IntroductionProps as QuarterlyPerformanceUpdateIntroductionProps,
} from 'containers/Dashboard/Document/modules/QuarterlyPerformanceUpdate/Introduction/Introduction';
import QuarterlyPerformanceUpdateMarketOverview, {
  MarketOverviewProps as QuarterlyPerformanceUpdateMarketOverviewProps,
} from 'containers/Dashboard/Document/modules/QuarterlyPerformanceUpdate/MarketOverview/MarketOverview';
import QuarterlyPerformanceUpdateInsights, {
  InsightsProps as QuarterlyPerformanceUpdateInsightsProps,
} from 'containers/Dashboard/Document/modules/QuarterlyPerformanceUpdate/Insights/Insights';
import AccountOverview, {
  AccountOverviewProps as QuarterlyPerformanceUpdateAccountOverviewProps,
} from 'containers/Dashboard/Document/modules/QuarterlyPerformanceUpdate/AccountOverview/AccountOverview';
import TaxLossHarvesting, {
  TaxLossHarvestingProps,
} from 'containers/Dashboard/Document/modules/QuarterlyPerformanceUpdate/TaxLossHarvesting/TaxLossHarvesting';

const quarterlyPerformanceUpdateModules = {
  [`${DocumentModuleGroup.QuarterlyPerformanceUpdate}.${DocumentModuleName.QuarterlyPerformanceUpdate_Introduction}`]:
    (properties: QuarterlyPerformanceUpdateIntroductionProps) => (
      <QuarterlyPerformanceUpdateIntroduction {...properties} />
    ),
  [`${DocumentModuleGroup.QuarterlyPerformanceUpdate}.${DocumentModuleName.QuarterlyPerformanceUpdate_MarketOverview}`]:
    (properties: QuarterlyPerformanceUpdateMarketOverviewProps) => (
      <QuarterlyPerformanceUpdateMarketOverview {...properties} />
    ),
  [`${DocumentModuleGroup.QuarterlyPerformanceUpdate}.${DocumentModuleName.QuarterlyPerformanceUpdate_AccountOverview}`]:
    (properties: QuarterlyPerformanceUpdateAccountOverviewProps) => (
      <AccountOverview {...properties} />
    ),
  [`${DocumentModuleGroup.QuarterlyPerformanceUpdate}.${DocumentModuleName.QuarterlyPerformanceUpdate_Insights}`]:
    (properties: QuarterlyPerformanceUpdateInsightsProps) => (
      <QuarterlyPerformanceUpdateInsights {...properties} />
    ),
  [`${DocumentModuleGroup.QuarterlyPerformanceUpdate}.${DocumentModuleName.QuarterlyPerformanceUpdate_Tax_Loss_Harvesting}`]:
    (properties: TaxLossHarvestingProps) => (
      <TaxLossHarvesting {...properties} />
    ),
};

export default quarterlyPerformanceUpdateModules;
