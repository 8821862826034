import React, { useState } from 'react';

import { Col, Row, TColorsKey } from '@compoundfinance/design-system';

import { not } from '../utils';
import {
  AllocationProposal,
  AllocationProposalTypes,
} from '../domain/AllocationProposal';

import { LineGrid } from './Charts/Chartifacts/Grids/LineGrid';
import PerformanceChart from './Charts/PerformanceChart';
import TimeRange from './ui/TimeRange';

export const TIME_RANGE_PERFORMANCE_CHART_OPTIONS = [
  { label: '1Y', value: 2 },
  { label: '5Y', value: 5 },
  { label: '10Y', value: 10 },
  { label: '20Y', value: 20 },
  { label: '30Y', value: 30 },
  { label: '50Y', value: 50 },
];
type TimeRangePerformanceOption =
  (typeof TIME_RANGE_PERFORMANCE_CHART_OPTIONS)[number];

const AggregatedProjectedPerformance = ({
  currentAllocationProposal,
  allocationProposals,
  timeRangePerformanceOptions = TIME_RANGE_PERFORMANCE_CHART_OPTIONS,
}: {
  currentAllocationProposal: AllocationProposalTypes.CurrentAllocationProposal;
  allocationProposals: AllocationProposalTypes.CategorizedAllocationProposal[];
  timeRangePerformanceOptions?: TimeRangePerformanceOption[];
}) => {
  const {
    blended: {
      original: { wholistic: wholisticBlendedCurrentAllocationProposal },
    },
  } = currentAllocationProposal;

  const [years, setYears] = useState(30);

  return (
    <Col css={{ h: '100%', w: '100%', jc: 'center', ai: 'center', gap: '$12' }}>
      <PerformanceChart
        years={years}
        blendedAllocationProposals={[
          {
            id: 'current',
            blended: wholisticBlendedCurrentAllocationProposal,
            metadata: {
              label: 'Current',
              bg: 'var(--colors-gray4)',
              stroke: 'var(--colors-gray4)',
              color: 'gray12',
            },
          },
          ...allocationProposals
            .filter(not(AllocationProposal.isArchived))
            .map((allocationProposal) => {
              const { id, name } = allocationProposal;
              return {
                id: id!,
                blended: allocationProposal.blended.target.wholistic,
                metadata: {
                  label: name,
                  bg: 'var(--colors-violet3)',
                  stroke: 'var(--colors-violet8)',
                  color: 'violet11' as TColorsKey,
                },
              };
            }),
        ]}
        grid={({ height, width, points }) => (
          <LineGrid height={height} width={width} points={points} />
        )}
      />

      <Row css={{ w: '100%', gap: '$8' }}>
        <TimeRange<number>
          options={timeRangePerformanceOptions.map(
            (timeRangePerformanceChartOption) => ({
              ...timeRangePerformanceChartOption,
              isSelected: timeRangePerformanceChartOption.value === years,
            }),
          )}
          onChange={setYears}
        />
      </Row>
    </Col>
  );
};

export default React.memo(AggregatedProjectedPerformance);
