import { styled } from '@compoundfinance/design-system/dist/stitches.config';

import { Text, Col, Flex, Box } from '@compoundfinance/design-system';

import {
  AllocationProposal,
  AllocationProposalTypes,
} from 'containers/Dashboard/AllocationProposals/domain/AllocationProposal';
import EditableTextArea from 'containers/Dashboard/Document/components/EditableTextArea';
import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import EditablePerson from 'containers/Dashboard/Document/components/EditablePerson';
import { getPerson } from 'containers/Dashboard/Document/utils';
import { DirectoryPerson } from 'domain/Employees/constants';
import AssetTransitions from 'containers/Dashboard/AllocationProposals/components/AssetTransitions';
import { EditableSectionTitle } from 'containers/Dashboard/Document/components/SectionTitle';

const Container = styled(ModuleContainer, {
  display: 'flex',
  flexDirection: 'column',
  py: '$24',
  px: '$24',
  gap: '$24',

  '@bp1': {
    py: '$44',
    px: '$48',
    gap: '$48',
  },
});

export interface AccountMovementProps {
  financialAdvisor: DirectoryPerson;
  title: string;
  allocationProposal: AllocationProposalTypes.InvestmentAllocationProposal;
}

function AccountMovement(props: AccountMovementProps) {
  const {
    financialAdvisor,
    title,
    allocationProposal: investmentAllocationProposal,
  } = props;
  const financialAdvisorPerson = getPerson(financialAdvisor);
  const allocationProposal = AllocationProposal.withBlended(
    investmentAllocationProposal,
  );

  return (
    <Container>
      <EditableSectionTitle title={title} />
      <Flex
        css={{
          fontSize: '$14',
          gap: '$16',
          ai: 'center',
          whiteSpace: 'nowrap',
        }}
      >
        <Text color="gray10">Current accounts</Text>
        <Box css={{ w: '100%', height: '1px', bg: '$gray8' }} />
        <Text color="gray10">Compound managed accounts</Text>
      </Flex>
      {AllocationProposal.isStrategic(allocationProposal) && (
        <AssetTransitions allocationProposal={allocationProposal} />
      )}
      <Col
        css={{
          gap: '$16',
          width: '50%',
        }}
      >
        <EditableTextArea
          propertyKey="description"
          value="This is a description"
        />
        <EditablePerson
          propertyKey="financialAdvisor"
          propertyValue={financialAdvisor}
          person={financialAdvisorPerson}
          size="small"
          level="document"
        />
      </Col>
    </Container>
  );
}

export default AccountMovement;
