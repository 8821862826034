/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 */

enum LoginActionTypes {
  SENDING_REQUEST = 'LOGIN/SENDING_REQUEST',
  COMPLETE_REQUEST = 'LOGIN/COMPLETE_REQUEST',
  LOGIN = 'LOGIN/LOGIN',
  REGISTER = 'LOGIN/REGISTER',
  SET_AUTH = 'LOGIN/SET_AUTH',
  ERROR_MESSAGE = 'LOGIN/ERROR_MESSAGE',
  CHECK_IF_AUTHED = 'LOGIN/CHECK_IF_AUTHED',
}

export default LoginActionTypes;
