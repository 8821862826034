import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AnimatePresence } from 'framer-motion';

import { BankAccountToast as BankAccountToastType } from './types';
import BankAccountToast from './BankAccountToast';
import useTypedSelector from 'hooks/typedSelector';

interface ToastProps {
  toasts: BankAccountToastType[];
  setToasts: React.Dispatch<React.SetStateAction<BankAccountToastType[]>>;
}

function BankAccountToastContainer(props: ToastProps) {
  const { toasts, setToasts } = props;
  const timeoutRef = useRef<any>();

  const assetModalOpen = useTypedSelector(
    (state) => state.assets.assetModalOpen,
  );

  const [currentToasts, setCurrentToasts] = useState<BankAccountToastType[]>(
    [],
  );

  useEffect(() => {
    setCurrentToasts(toasts);
  }, [toasts]);

  const removeToast = useCallback(
    (toastToRemove: BankAccountToastType) => {
      setCurrentToasts((toasts) =>
        toasts.filter((toast) => toast.id !== toastToRemove.id),
      );

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // Sync toasts w/ currentToasts after a slight delay in order to leave time for animations etc.
      // If we'd remove a toast from toasts right as we remove it from currentToasts, it'd just abruptly disappear in the UI.
      timeoutRef.current = setTimeout(() => {
        setToasts((toasts) =>
          toasts.filter((toast) => toast.id !== toastToRemove.id),
        );
      }, 500);
    },
    [setToasts],
  );

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <AnimatePresence>
        {!assetModalOpen &&
          currentToasts.map((toast) => (
            <BankAccountToast
              removeToast={removeToast}
              key={toast.id}
              bankAccountToast={toast}
            />
          ))}
      </AnimatePresence>
    </>
  );
}

export default BankAccountToastContainer;
