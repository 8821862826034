import { setViewAssetModalOpen, setCurrentAsset } from 'actions/assets';
import { useDispatch } from 'react-redux';
import { PlaidAccount } from '@compoundfinance/compound-core/dist/types/plaid';
import PlaidAccountUtils from 'utils/plaid/accounts';
import { AssetTypes } from '@compoundfinance/compound-core/dist/types/account';
import { AssetModalTypes } from '../types';
import { CompoundAsset } from 'components/EquityManagement/Asset/types';

function getManualAssetType(assetType: AssetModalTypes): AssetModalTypes {
  switch (assetType) {
    case AssetTypes.Crypto:
      return AssetTypes.ManualCryptoAccount;
    case AssetTypes.PublicInvestment:
      return AssetTypes.ManualInvestmentAccount;
    case AssetTypes.Cash:
      return AssetTypes.ManualBankAccount;
    default:
      return assetType;
  }
}

function useViewAssetModal() {
  const dispatch = useDispatch();

  function closeViewAssetModal() {
    dispatch(setViewAssetModalOpen(false));
  }

  /**
   * Opens add asset modal. If no asset is provided, assume we're creating a new one
   * @param assetType Asset type to add.
   */
  function openViewAssetModal(
    assetType: AssetModalTypes,
    asset = {},
    step?: number,
  ) {
    const isManual = PlaidAccountUtils.isSelfServeProvider(
      asset as PlaidAccount,
    );
    const currentAsset = {
      ...asset,
      assetType: isManual ? getManualAssetType(assetType) : assetType,
    };

    dispatch(setCurrentAsset(currentAsset as CompoundAsset));
    dispatch(setViewAssetModalOpen(true, step ? step : null));
  }

  return { openViewAssetModal, closeViewAssetModal };
}

export default useViewAssetModal;
