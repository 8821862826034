import _ from 'lodash';
import { PrivateEquityAccount } from '@compoundfinance/compound-core/dist/types/equity';
import getGrantBreakdown from './grantBreakdown';
import { getValuationAtDate, isOption, isRSA, isRSU } from './utils';

interface SupportedGrantTypes {
  option: Array<ReturnType<typeof getGrantBreakdown>>;
  rsa: Array<ReturnType<typeof getGrantBreakdown>>;
  rsu: Array<ReturnType<typeof getGrantBreakdown>>;
}

enum BreakdownSections {
  CancelledCount = 'cancelledCount',
  CancelledTotal = 'cancelledTotal',
  EarlyExerciseCount = 'earlyExerciseCount',
  EarlyExerciseTotal = 'earlyExerciseTotal',
  ExerciseCount = 'exerciseCount',
  UnvestedCount = 'unvestedCount',
  UnvestedTotal = 'unvestedTotal',
  RawVestedCount = 'rawVestedCount',
  VestedCount = 'vestedCount',
  VestedTotal = 'vestedTotal',
  CommonStockCount = 'commonStockCount',
  CommonStockTotal = 'commonStockTotal',
  PreferredStockCount = 'preferredStockCount',
  PreferredStockTotal = 'preferredStockTotal',
  OwnedCount = 'ownedCount',
  SoldStockCount = 'soldStockCount',
  SoldStockTotal = 'soldStockTotal',
  TotalCount = 'totalCount',
}

interface CountsByGrantType {
  option: number;
  rsa: number;
  rsu: number;
}
/**
 * Create a breakdown section per supported equity type (rsa / rsu / option grant)
 * @param grantTypes
 * @param section
 */
function buildBreakdownSection(
  grantTypes: SupportedGrantTypes,
  section: BreakdownSections,
): CountsByGrantType {
  let breakdownSection = {} as CountsByGrantType;
  for (const key in grantTypes) {
    const grants = grantTypes[key as keyof SupportedGrantTypes];
    breakdownSection[key] = grants.reduce(
      (sum, grant) => sum + Math.max(0, grant[section] ?? 0),
      0,
    );
  }
  return breakdownSection;
}

// Helper to sum a list by the supplied property, returning a non-sparse array of values
function sumByProperty(list: any[], property: string) {
  return _.sum(_.concat(_.map(list, property)));
}

function sumBreakdownSection(section) {
  return _.reduce(section, (sum, value) => (sum += value), 0);
}

/**
 * This method is similar to our other equity breakdowns, but it ignores any valuation settings.
 * It should almost certainly be used in any situation in which we need accurate counts and valuations
 * of each state of a user's equity (vested, unvested, cancelled, etc).
 *
 * The breakdown also includes the underlying breakdown of each grant. Those breakdowns contain
 * the same fields as the top-level properties (unvestedCount, unvestedTotal, etc) as the final breakdown object
 * this function returns, with numbers specific to those grants.
 *
 * @param peAccount
 */
function directEquityBreakdown(
  peAccount: PrivateEquityAccount,
  vestByDate?: Date,
) {
  const { grants, convertibleNotes, certificates, endDate } = peAccount;

  const vestDateTarget = vestByDate;
  const { fmv, preferredPrice } = getValuationAtDate(
    peAccount,
    vestDateTarget || new Date(),
  );

  const grantTypes = {
    option: [] as Array<ReturnType<typeof getGrantBreakdown>>,
    rsa: [] as Array<ReturnType<typeof getGrantBreakdown>>,
    rsu: [] as Array<ReturnType<typeof getGrantBreakdown>>,
  };

  grants.forEach((grant) => {
    const grantCertificates = (certificates ?? []).filter(
      (c) => c.grantId === grant.id,
    );

    const breakdown = getGrantBreakdown({
      grant,
      valuations: {
        fmv: fmv as number,
        preferredPrice: preferredPrice as number,
      },
      endDate,
      targetDate: vestDateTarget,
      grantCertificates,
      provider: peAccount.provider,
    });
    if (isOption(grant)) {
      grantTypes.option.push(breakdown);
    } else if (isRSU(grant)) {
      grantTypes.rsu.push(breakdown);
    } else if (isRSA(grant)) {
      grantTypes.rsa.push(breakdown);
    }
  });

  const convertibleNotesCount = _.sumBy(convertibleNotes, 'quantity');
  const convertibleNotesTotal = _.sumBy(convertibleNotes, 'value');

  const breakdown = {
    grants: _.flatten(Object.values(grantTypes)),
    unvested: {
      count: buildBreakdownSection(grantTypes, BreakdownSections.UnvestedCount),
      value: buildBreakdownSection(grantTypes, BreakdownSections.UnvestedTotal),
    },
    rawVested: {
      count: buildBreakdownSection(
        grantTypes,
        BreakdownSections.RawVestedCount,
      ),
    },
    vested: {
      count: buildBreakdownSection(grantTypes, BreakdownSections.VestedCount),
      value: buildBreakdownSection(grantTypes, BreakdownSections.VestedTotal),
    },
    exercised: {
      count: buildBreakdownSection(grantTypes, BreakdownSections.ExerciseCount),
    },
    earlyExercise: {
      count: {
        ...buildBreakdownSection(
          grantTypes,
          BreakdownSections.EarlyExerciseCount,
        ),
      },
      value: {
        common: _.sumBy(
          grantTypes.option,
          BreakdownSections.EarlyExerciseTotal,
        ),
        option: _.sumBy(
          grantTypes.option,
          BreakdownSections.EarlyExerciseTotal,
        ),
        rsa: _.sumBy(grantTypes.rsa, BreakdownSections.EarlyExerciseTotal),
      },
    },
    owned: {
      count: {
        ...buildBreakdownSection(grantTypes, BreakdownSections.OwnedCount),
        common: _.sumBy(
          _.flatten(Object.values(grantTypes)),
          BreakdownSections.CommonStockCount,
        ),
        preferred: _.sumBy(
          _.flatten(Object.values(grantTypes)),
          BreakdownSections.PreferredStockCount,
        ),
        convertible: convertibleNotesCount,
      },
      value: {
        ...buildBreakdownSection(
          grantTypes,
          BreakdownSections.CommonStockTotal,
        ),
        common: _.sumBy(
          _.flatten(Object.values(grantTypes)),
          BreakdownSections.CommonStockTotal,
        ),
        preferred: _.sumBy(
          _.flatten(Object.values(grantTypes)),
          BreakdownSections.PreferredStockTotal,
        ),
        convertible: convertibleNotesTotal,
      },
    },
    sold: {
      count: buildBreakdownSection(
        grantTypes,
        BreakdownSections.SoldStockCount,
      ),
      value: buildBreakdownSection(
        grantTypes,
        BreakdownSections.SoldStockTotal,
      ),
    },
    cancelled: {
      count: buildBreakdownSection(
        grantTypes,
        BreakdownSections.CancelledCount,
      ),
      value: buildBreakdownSection(
        grantTypes,
        BreakdownSections.CancelledTotal,
      ),
    },
    total: {
      count: buildBreakdownSection(grantTypes, BreakdownSections.TotalCount),
      value: {},
    },
  };

  const flatGrants = _.flatten(Object.values(grantTypes));

  const finalBreakdown = {
    ...breakdown,
    // These are total counts and values for ALL equity in an account. They are added for convenience
    unvestedCount: sumBreakdownSection(breakdown.unvested.count),
    unvestedTotal: sumBreakdownSection(breakdown.unvested.value),
    earlyExerciseCount: sumBreakdownSection(breakdown.earlyExercise.count),
    earlyExerciseTotal: sumBreakdownSection(breakdown.earlyExercise.value),
    exercisedCount: sumBreakdownSection(breakdown.exercised.count),
    availableVestedCount: sumBreakdownSection(breakdown.vested.count),
    // Total value of options that are both vested & unexercised
    availableVestedTotal: sumBreakdownSection(breakdown.vested.value),
    // Total value of stocks that correspond to vested options or owned preferred stock
    certificatesTotal:
      sumByProperty(flatGrants, 'commonStockTotal') +
      sumByProperty(flatGrants, 'preferredStockTotal'),
    commonStockCount: breakdown.owned.count.common,
    commonStockTotal: breakdown.owned.value.common,
    preferredStockTotal: breakdown.owned.value.preferred,
    ownedCount: breakdown.owned.count.common + breakdown.owned.count.preferred,
    ownedValue: breakdown.owned.value.common + breakdown.owned.value.preferred,
    convertibleNotesCount,
    convertibleNotesTotal,
    cancelledCount: sumBreakdownSection(breakdown.cancelled.count),
    cancelledTotal: sumBreakdownSection(breakdown.cancelled.value),
    vestedRsuTotal: sumByProperty(grantTypes.rsu, 'vestedTotal'),
    adjustedCerts: _.flatten(_.concat(_.map(flatGrants, 'adjustedCerts'))),
    totalCount: sumBreakdownSection(breakdown.total.count),
  };

  return finalBreakdown;
}

export default directEquityBreakdown;
