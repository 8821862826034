import { ValueOf } from 'types';
import { Prettify } from 'utils/types';
import { NumberUtils } from 'utils/NumberUtils';

import { ASSET_CATEGORY } from '../../constants';
import { AssetCategoryTree } from '../../utils/AssetCategoryTree';
import { AllocationAsset } from '../../../Accounts/AssetAllocation/AllocationExplorer';
import { Allocation, AllocationTypes } from '../Allocation';
import {
  AllocationProposalStrategy,
  AllocationProposalStrategyMessageTypes,
  AllocationProposalStrategyTypes,
} from '../AllocationProposalStrategy';
import { not } from '../../utils';

export const TargetAllocationProposalStrategyType = {
  Original: 'original',
  Taxable: 'taxable',
  Retirement: 'retirement',
} as const;
export type TargetAllocationProposalStrategyType = ValueOf<
  typeof TargetAllocationProposalStrategyType
>;

export namespace TargetAllocationProposalStrategyTypes {
  type AllocationProposalStrategyAllocations = {
    allocations: AllocationTypes.Allocation[];
  };
  type AllocationProposalStrategyCategorizedAllocations = {
    allocations: Record<string, AllocationTypes.Allocation>;
  };
  type AllocationProposalStrategyClientAllocations = {
    allocations: AllocationTypes.ClientAllocation[];
  };
  type AllocationProposalStrategyCategorizedClientAllocations = {
    allocations: Record<string, AllocationTypes.ClientAllocation>;
  };

  type BaseOriginalAllocationProposalStrategy =
    AllocationProposalStrategyTypes.BaseAllocationProposalStrategy & {
      type: Extract<TargetAllocationProposalStrategyType, 'original'>;
    };
  type BaseRetirementAllocationProposalStrategy =
    AllocationProposalStrategyTypes.BaseAllocationProposalStrategy & {
      type: Extract<TargetAllocationProposalStrategyType, 'retirement'>;
    };
  type BaseTaxableAllocationProposalStrategy =
    AllocationProposalStrategyTypes.BaseAllocationProposalStrategy & {
      type: Extract<TargetAllocationProposalStrategyType, 'taxable'>;
    };

  export type OriginalAllocationProposalStrategy = Prettify<
    BaseOriginalAllocationProposalStrategy &
      AllocationProposalStrategyClientAllocations
  >;
  export type TaxableAllocationProposalStrategy = Prettify<
    BaseTaxableAllocationProposalStrategy &
      AllocationProposalStrategyAllocations
  >;
  export type RetirementAllocationProposalStrategy = Prettify<
    BaseRetirementAllocationProposalStrategy &
      AllocationProposalStrategyAllocations
  >;

  export type CategorizedOriginalAllocationProposalStrategy = Prettify<
    BaseOriginalAllocationProposalStrategy &
      AllocationProposalStrategyCategorizedClientAllocations
  >;
  export type CategorizedTaxableAllocationProposalStrategy = Prettify<
    BaseTaxableAllocationProposalStrategy &
      AllocationProposalStrategyCategorizedAllocations
  >;
  export type CategorizedRetirementAllocationProposalStrategy = Prettify<
    BaseRetirementAllocationProposalStrategy &
      AllocationProposalStrategyCategorizedAllocations
  >;

  export type AllocationProposalStrategy = Prettify<
    | OriginalAllocationProposalStrategy
    | RetirementAllocationProposalStrategy
    | TaxableAllocationProposalStrategy
  >;

  export type CategorizedAllocationProposalStrategy = Prettify<
    | CategorizedOriginalAllocationProposalStrategy
    | CategorizedRetirementAllocationProposalStrategy
    | CategorizedTaxableAllocationProposalStrategy
  >;
}

const Constants = {};

const isOriginal = (
  allocationProposalStrategies:
    | TargetAllocationProposalStrategyTypes.AllocationProposalStrategy
    | TargetAllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy,
): allocationProposalStrategies is
  | TargetAllocationProposalStrategyTypes.OriginalAllocationProposalStrategy
  | TargetAllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy =>
  allocationProposalStrategies.type ===
  TargetAllocationProposalStrategyType.Original;

const isTaxable = (
  allocationProposalStrategies:
    | TargetAllocationProposalStrategyTypes.AllocationProposalStrategy
    | TargetAllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy,
): allocationProposalStrategies is
  | TargetAllocationProposalStrategyTypes.TaxableAllocationProposalStrategy
  | TargetAllocationProposalStrategyTypes.CategorizedTaxableAllocationProposalStrategy =>
  allocationProposalStrategies.type ===
  TargetAllocationProposalStrategyType.Taxable;

const isRetirement = (
  allocationProposalStrategies:
    | TargetAllocationProposalStrategyTypes.AllocationProposalStrategy
    | TargetAllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy,
): allocationProposalStrategies is
  | TargetAllocationProposalStrategyTypes.RetirementAllocationProposalStrategy
  | TargetAllocationProposalStrategyTypes.CategorizedRetirementAllocationProposalStrategy =>
  allocationProposalStrategies.type ===
  TargetAllocationProposalStrategyType.Retirement;

const onlyOriginal = <
  AllocationProposalStrategyType extends
    | TargetAllocationProposalStrategyTypes.AllocationProposalStrategy
    | TargetAllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy,
>(
  allocationProposalStrategies: AllocationProposalStrategyType[],
) => allocationProposalStrategies.find(isOriginal)!;

const onlyTaxable = <
  AllocationProposalStrategyType extends
    | TargetAllocationProposalStrategyTypes.AllocationProposalStrategy
    | TargetAllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy,
>(
  allocationProposalStrategies: AllocationProposalStrategyType[],
) => allocationProposalStrategies.find(isTaxable)!;

const onlyRetirement = <
  AllocationProposalStrategyType extends
    | TargetAllocationProposalStrategyTypes.AllocationProposalStrategy
    | TargetAllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy,
>(
  allocationProposalStrategies: AllocationProposalStrategyType[],
) => allocationProposalStrategies.find(isRetirement)!;

const networth = <
  AllocationType extends TargetAllocationProposalStrategyTypes.CategorizedTaxableAllocationProposalStrategy,
>(
  originalAllocationProposalStrategy: AllocationType,
) =>
  AllocationProposalStrategy.fromCategorized(
    originalAllocationProposalStrategy.allocations,
  ).reduce(
    (unomittedNetworth, transition) =>
      NumberUtils.add(unomittedNetworth, Allocation.value(transition)),
    0,
  );

const unomittedNetworth = <
  AllocationType extends TargetAllocationProposalStrategyTypes.CategorizedOriginalAllocationProposalStrategy,
>(
  originalAllocationProposalStrategy: AllocationType,
) =>
  AllocationProposalStrategy.value(
    AllocationProposalStrategy.fromCategorized(
      originalAllocationProposalStrategy.allocations,
    ).filter(not(Allocation.isOmitted)),
  );

const toBlendedAllocations = (
  allocationProposalStrategies: TargetAllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy[],
  networth: number,
): TargetAllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy['allocations'] => {
  const investmentTargetAllocationProposalStrategys =
    allocationProposalStrategies.filter(not(isOriginal));
  const constrainedInvestmentTargetAllocationProposalStrategies =
    investmentTargetAllocationProposalStrategys.map(
      constrainAllocationsWeightByNetworth(networth),
    );
  return constrainedInvestmentTargetAllocationProposalStrategies.reduce(
    (blendedAllocations, allocations) => ({
      ...blendedAllocations,
      ...Object.entries(allocations).reduce(
        (blendedAllocations, [slug, allocation]) => {
          const blendedAllocation = blendedAllocations[slug] || {};
          return {
            ...blendedAllocations,
            [slug]: {
              ...allocation,
              weight: NumberUtils.add(
                Allocation.weight(blendedAllocation),
                Allocation.weight(allocation),
              ),
            },
          };
        },
        blendedAllocations,
      ),
    }),
    {},
  );
};

const constrainAllocationsWeightByNetworth =
  (networth: number) =>
  (
    nonOriginalAllocationProposalStrategy:
      | TargetAllocationProposalStrategyTypes.CategorizedRetirementAllocationProposalStrategy
      | TargetAllocationProposalStrategyTypes.CategorizedTaxableAllocationProposalStrategy,
  ) => {
    const { networth: allocationNetworth, allocations } =
      nonOriginalAllocationProposalStrategy;
    const rebalanceToNetworth = AllocationProposalStrategy.rebalanceTo(
      NumberUtils.percentage(allocationNetworth, networth),
    );
    return rebalanceToNetworth(allocations);
  };

const toMessage = (
  allocationProposalStrategy: TargetAllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy,
): AllocationProposalStrategyMessageTypes.AllocationProposalStrategyMessage => {
  const { allocations, ...allocationProposalStrategyMessage } =
    allocationProposalStrategy;
  return {
    ...allocationProposalStrategyMessage,
    investmentTarget: null,
    allocations: AllocationProposalStrategy.fromCategorized(allocations).map(
      Allocation.toMessage,
    ),
  };
};

const fromMessage =
  ({
    assetCategories,
    assets,
    networth,
  }: {
    assetCategories: ASSET_CATEGORY[];
    assets: AllocationAsset[];
    networth: number;
  }) =>
  (
    allocationProposalStrategyMessage: AllocationProposalStrategyMessageTypes.AllocationProposalStrategyMessage,
  ) => {
    const allocationFromMessage = Allocation.fromMessage({
      assetCategories,
      assets,
      networth,
    });

    const { allocations, type } = allocationProposalStrategyMessage;
    const allocationsFromMessage = AssetCategoryTree.normalize(
      AllocationProposalStrategy.toCategorized(
        allocations.map(allocationFromMessage),
      ),
    );
    return {
      ...allocationProposalStrategyMessage,
      allocations: allocationsFromMessage,
      type,
    } as TargetAllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy;
  };

export const TargetAllocationProposalStrategy = {
  Constants,
  fromMessage,
  isOriginal,
  isRetirement,
  isTaxable,
  networth,
  onlyOriginal,
  onlyRetirement,
  onlyTaxable,
  toBlendedAllocations,
  toMessage,
  unomittedNetworth,
};
