import { ValueOf } from 'types';

import { AllocationAsset } from '../../Accounts/AssetAllocation/AllocationExplorer';
import { ASSET_CATEGORY } from '../constants';
import { AllocationTypes } from './Allocation';
import { AllocationProposalSettingsTypes } from './AllocationProposalSettings';
import {
  TargetAllocationProposal,
  TargetAllocationProposalTypes,
} from './Target/TargetAllocationProposal';
import {
  StrategicAllocationProposal,
  StrategicAllocationProposalTypes,
} from './Strategic/StrategicAllocationProposal';
import {
  AllocationProposalStrategyMessageTypes,
  AllocationProposalStrategyTypes,
} from './AllocationProposalStrategy';
import { StrategicAllocationProposalStrategyTypes } from './Strategic/StrategicAllocationProposalStrategy';
import { TargetAllocationProposalStrategyTypes } from './Target/TargetAllocationProposalStrategy';

export const AllocationProposalStatus = {
  Default: 'default',
  Archived: 'archived',
} as const;
type AllocationProposalStatus = ValueOf<typeof AllocationProposalStatus>;

export const AllocationProposalType = {
  Strategic: 'strategic',
  Target: 'target',
} as const;
export type AllocationProposalType = ValueOf<typeof AllocationProposalType>;

export const AllocationProposalLabel = {
  ...AllocationProposalStatus,
  Target: 'target',
} as const;
export type AllocationProposalLabel = ValueOf<typeof AllocationProposalLabel>;

export namespace AllocationProposalMessageTypes {
  export type AllocationProposalMessage = {
    id?: string;
    userId?: string;
    name: string;
    description: string;
    strategies: AllocationProposalStrategyMessageTypes.AllocationProposalStrategyMessage[];
    allocationProposalSetting: AllocationProposalSettingsTypes.Settings;
    type: AllocationProposalType;
    status: AllocationProposalStatus;
    updatedAt: string;
    createdAt: string;
  };
}

export namespace AllocationProposalTypes {
  export type BaseAllocationProposal = {
    id?: string;
    name: string;
    description: string;
    blended: BlendendAllocationProposals;
    type: AllocationProposalType;
    status: AllocationProposalStatus;
    networth: number;
    settings: AllocationProposalSettingsTypes.Settings;
    updatedAt: string;
    unomittedNetworth: number;
    transitionedNetworth: number;
    createdAt: string;
  };

  export type AllocationProposal =
    | StrategicAllocationProposalTypes.AllocationProposal
    | TargetAllocationProposalTypes.AllocationProposal;

  export type CategorizedAllocationProposal =
    | StrategicAllocationProposalTypes.CategorizedAllocationProposal
    | TargetAllocationProposalTypes.CategorizedAllocationProposal;

  export type CurrentAllocationProposal = BaseAllocationProposal & {
    original: AllocationProposalStrategyTypes.CategorizedOrginalAllocationProposalStrategy;
  };

  export type BlendendAllocationProposals = {
    original: {
      wholistic: BlendendAllocationProposal;
      changes: BlendendAllocationProposal;
    };
    target: {
      wholistic: BlendendAllocationProposal;
      changes: BlendendAllocationProposal;
    };
  };

  export type BlendendAllocationProposal = {
    allocations: AllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy['allocations'];
    annualReturn: number;
    annualVolatility: number;
    singleStockExposure: number;
    assets: Record<string, AllocationTypes.ClientAllocation>;
    representationalAssets: Record<string, AllocationTypes.ClientAllocation>;
  };

  type PortfolioItem = { category: string; weight: number };
  export type PresetAllocationProposal = {
    id: string;
    name: string;
    type: string;
    risk: string;
    portfolio: PortfolioItem[];
    presetAssetAllocation: AllocationProposalStrategyTypes.PresetAssetAllocation;
    annualReturn?: number;
    annualVolatility?: number;
  };

  export type InvestmentAllocationProposal =
    | TargetAllocationProposalTypes.InvestmentAllocationProposal
    | StrategicAllocationProposalTypes.InvestmentAllocationProposal;
}

const blended = (
  allocationProposal:
    | Pick<
        StrategicAllocationProposalTypes.CategorizedAllocationProposal,
        'original' | 'investmentTargets'
      >
    | Pick<
        TargetAllocationProposalTypes.CategorizedAllocationProposal,
        'original' | 'taxable' | 'retirement'
      >,
): AllocationProposalTypes.BlendendAllocationProposals => {
  if (
    Object.hasOwn(allocationProposal, 'original') &&
    Object.hasOwn(allocationProposal, 'taxable') &&
    Object.hasOwn(allocationProposal, 'retirement')
  )
    return TargetAllocationProposal.blended(
      allocationProposal as Pick<
        TargetAllocationProposalTypes.CategorizedAllocationProposal,
        'original' | 'taxable' | 'retirement'
      >,
    );
  if (
    Object.hasOwn(allocationProposal, 'original') &&
    Object.hasOwn(allocationProposal, 'investmentTargets')
  )
    return StrategicAllocationProposal.blended(
      allocationProposal as Pick<
        StrategicAllocationProposalTypes.CategorizedAllocationProposal,
        'original' | 'investmentTargets'
      >,
    );
  throw new Error('Unreachable');
};

const create = ({
  original,
  type,
  ...allocationProposal
}: {
  original: AllocationProposalTypes.AllocationProposal['original'];
  type: AllocationProposalType;
} & Partial<AllocationProposalTypes.AllocationProposal>): AllocationProposalTypes.CategorizedAllocationProposal => {
  if (type === AllocationProposalType.Target)
    return TargetAllocationProposal.create({
      original:
        original as TargetAllocationProposalStrategyTypes.OriginalAllocationProposalStrategy,
      type,
      ...allocationProposal,
    });
  if (type === AllocationProposalType.Strategic)
    return StrategicAllocationProposal.create({
      original:
        original as StrategicAllocationProposalStrategyTypes.OriginalAllocationProposalStrategy,
      type,
      ...allocationProposal,
    });
  throw new Error('Unreachable');
};

const isTarget = (
  allocationProposal:
    | Pick<AllocationProposalTypes.AllocationProposal, 'type'>
    | Pick<AllocationProposalTypes.CategorizedAllocationProposal, 'type'>,
): allocationProposal is
  | TargetAllocationProposalTypes.AllocationProposal
  | TargetAllocationProposalTypes.CategorizedAllocationProposal =>
  allocationProposal.type === AllocationProposalType.Target;

const isStrategic = (
  allocationProposal:
    | Pick<AllocationProposalTypes.CategorizedAllocationProposal, 'type'>
    | Pick<AllocationProposalTypes.CategorizedAllocationProposal, 'type'>,
): allocationProposal is
  | StrategicAllocationProposalTypes.AllocationProposal
  | StrategicAllocationProposalTypes.CategorizedAllocationProposal =>
  allocationProposal.type === AllocationProposalType.Strategic;

const isBlank = (
  allocationProposal: AllocationProposalTypes.CategorizedAllocationProposal,
) => {
  if (AllocationProposal.isTarget(allocationProposal))
    return TargetAllocationProposal.isBlank(allocationProposal);
  if (AllocationProposal.isStrategic(allocationProposal))
    return StrategicAllocationProposal.isBlank(allocationProposal);
  throw new Error('Unreachable');
};

const isArchived = (
  allocationProposal: Pick<
    AllocationProposalTypes.CategorizedAllocationProposal,
    'status'
  >,
) => allocationProposal.status === 'archived';

const isDefault = (
  allocationProposal: Pick<
    AllocationProposalTypes.CategorizedAllocationProposal,
    'status'
  >,
) => allocationProposal.status === 'default';

const target = (
  allocationProposals: AllocationProposalTypes.CategorizedAllocationProposal[],
) => allocationProposals.find(isTarget);

const strategic = (
  allocationProposals: AllocationProposalTypes.CategorizedAllocationProposal[],
) => allocationProposals.filter(isStrategic);

const unomittedNetworth = ({
  unomittedNetworth,
}: AllocationProposalTypes.CategorizedAllocationProposal) =>
  unomittedNetworth ?? 0;

const label = ({
  type,
  status,
}: {
  type: AllocationProposalType;
  status: AllocationProposalStatus;
}): AllocationProposalLabel => {
  if (type === AllocationProposalType.Target) return 'target';
  return status;
};

const withBlended = (
  allocationProposal:
    | AllocationProposalTypes.CategorizedAllocationProposal
    | AllocationProposalTypes.InvestmentAllocationProposal,
): AllocationProposalTypes.CategorizedAllocationProposal => {
  if (isTarget(allocationProposal))
    return TargetAllocationProposal.withBlended(allocationProposal);
  if (isStrategic(allocationProposal))
    return StrategicAllocationProposal.withBlended(allocationProposal);
  throw new Error('Unreachable');
};

const fromCategorized = (
  allocationProposal: AllocationProposalTypes.CategorizedAllocationProposal,
): AllocationProposalTypes.AllocationProposal => {
  if (isTarget(allocationProposal))
    return TargetAllocationProposal.fromCategorized(allocationProposal);
  if (isStrategic(allocationProposal))
    return StrategicAllocationProposal.fromCategorized(allocationProposal);
  throw new Error('Unreachable');
};

const toCategorized = (
  allocationProposal: AllocationProposalTypes.AllocationProposal,
): AllocationProposalTypes.CategorizedAllocationProposal => {
  if (isTarget(allocationProposal))
    return TargetAllocationProposal.toCategorized(allocationProposal);
  if (isStrategic(allocationProposal))
    return StrategicAllocationProposal.toCategorized(allocationProposal);
  throw new Error('Unreachable');
};

const toInvestmentProposal = <
  AllocationProposal extends AllocationProposalTypes.CategorizedAllocationProposal,
>(
  allocationProposal: AllocationProposal,
): AllocationProposalTypes.InvestmentAllocationProposal => {
  if (isTarget(allocationProposal))
    return TargetAllocationProposal.toInvestmentProposal(allocationProposal);
  if (isStrategic(allocationProposal))
    return StrategicAllocationProposal.toInvestmentProposal(allocationProposal);
  throw new Error('Unreachable');
};

const toMessage = (
  allocationProposal: AllocationProposalTypes.CategorizedAllocationProposal,
): AllocationProposalMessageTypes.AllocationProposalMessage => {
  if (isTarget(allocationProposal))
    return TargetAllocationProposal.toMessage(allocationProposal);
  if (isStrategic(allocationProposal))
    return StrategicAllocationProposal.toMessage(allocationProposal);
  throw new Error('Unreachable');
};

const fromMessage =
  ({
    assetCategories,
    assets,
  }: {
    assetCategories: ASSET_CATEGORY[];
    assets: AllocationAsset[];
  }) =>
  (
    assetAllocationStrategyMessage: AllocationProposalMessageTypes.AllocationProposalMessage,
  ): AllocationProposalTypes.CategorizedAllocationProposal => {
    if (isTarget(assetAllocationStrategyMessage))
      return TargetAllocationProposal.fromMessage({ assetCategories, assets })(
        assetAllocationStrategyMessage,
      );
    if (isStrategic(assetAllocationStrategyMessage))
      return StrategicAllocationProposal.fromMessage({
        assetCategories,
        assets,
      })(assetAllocationStrategyMessage);
    throw new Error('Unreachable');
  };

export const AllocationProposal = {
  blended,
  create,
  fromCategorized,
  toCategorized,
  fromMessage,
  isArchived,
  isBlank,
  isDefault,
  isTarget,
  isStrategic,
  label,
  strategic,
  target,
  toMessage,
  toInvestmentProposal,
  unomittedNetworth,
  withBlended,
};
