import { Group } from '@visx/group';
import { Line } from '@visx/shape';
import { useState } from 'react';

export type TicksAndTocksProps = {
  height: number;
  width: number;
  points: number;
  shouldDisplay?: ({ index }: { index: number }) => boolean;
  styles?: Parameters<typeof Line>[number];
};

export const Ticks = ({
  points,
  width,
  height,
  shouldDisplay = () => true,
  styles = {},
}: TicksAndTocksProps) => {
  const spacing = width / (points - 1);
  return (
    <Group>
      {Array.from({ length: points }, (_, index) => {
        if (!shouldDisplay({ index })) return null;
        const x = index * spacing;
        return <Tick key={index} {...styles} x={x} height={height} />;
      })}
    </Group>
  );
};

const Tick = ({
  x,
  height,
  styles,
}: { x: number } & Pick<TicksAndTocksProps, 'height' | 'styles'>) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Line
      from={{ x, y: 0 }}
      to={{ x, y: height }}
      stroke={isHovered ? 'var(--colors-gray8)' : 'var(--colors-gray4)'}
      strokeWidth={1}
      shapeRendering="geometricPrecision"
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      {...styles}
    />
  );
};

export const Tocks = ({
  points,
  width,
  height,
  shouldDisplay = () => true,
  styles = {},
}: TicksAndTocksProps) => {
  const spacing = height / (points - 1);
  return (
    <Group>
      {Array.from({ length: points }, (_, index) => {
        if (!shouldDisplay({ index })) return null;
        const y = index * spacing;
        return (
          <Line
            key={index}
            from={{ x: 0, y }}
            to={{ x: width, y }}
            stroke={'var(--colors-gray4)'}
            strokeWidth={1}
            shapeRendering="geometricPrecision"
            {...styles}
          />
        );
      })}
    </Group>
  );
};
