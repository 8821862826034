import { Link } from 'react-router-dom';
import Wordmark from 'assets/svg/Wordmark';

interface ILogoProps {
  to?: string;
  w?: string;
  fill?: string;
}

const Logo = (props: ILogoProps) => (
  <Link to={props.to || '/'}>
    <Wordmark w={props.w} color={props.fill} />
  </Link>
);

export { Logo };
