import styled from 'styled-components';
import UiUtils from 'utils/ui';
import { SvgProps } from '../types';

const PencilWrap = styled.svg<SvgProps>`
  transition: 0.1s ease;
  &:hover {
    background-color: ${({ theme, hoverBg }) =>
      hoverBg ? theme.colors[hoverBg] : 'initial'};
  }
`;

export default function Pencil(props: SvgProps) {
  const { size = 32, color = 'black', style, ...rest } = props;

  const fill =
    color === 'currentColor' ? 'currentColor' : UiUtils.SVGColor(color);

  return (
    <PencilWrap
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: 'pointer', ...style }}
      {...rest}
    >
      <path
        d="M3.375 12.2808V14.6246H5.71875L12.6313 7.71207L10.2875 5.36832L3.375 12.2808ZM14.4438 5.89957C14.5017 5.84175 14.5477 5.77307 14.579 5.69746C14.6104 5.62186 14.6265 5.5408 14.6265 5.45895C14.6265 5.37709 14.6104 5.29604 14.579 5.22043C14.5477 5.14483 14.5017 5.07615 14.4438 5.01832L12.9813 3.55582C12.9234 3.49788 12.8547 3.45192 12.7791 3.42055C12.7035 3.38919 12.6225 3.37305 12.5406 3.37305C12.4588 3.37305 12.3777 3.38919 12.3021 3.42055C12.2265 3.45192 12.1578 3.49788 12.1 3.55582L10.9563 4.69957L13.3 7.04332L14.4438 5.89957Z"
        fill={fill}
      />
    </PencilWrap>
  );
}
