import { Box, Text } from '@compoundfinance/design-system';
import { useInView } from 'react-intersection-observer';
import { useAtom } from 'jotai';
import { useContext, useEffect } from 'react';
import { isProduction } from 'utils/env';
import { tableOfContentsWriteModuleVisibilityAtom } from '..';
import { CompoundDocumentSection } from '../../..';
import ModuleContext from '../ModuleContext';
import ModuleControls from '../ModuleControls';
import quarterlyPerformanceUpdateModules from './quarterlyPerformanceUpdateModules';
import membershipProposalModules from './membershipProposalModules';
import assetAllocationInvestmentProposalModules from './assetAllocationInvestmentProposalModules';
import salesIntroductionModules from './salesIntroductionModules';
import TaxTransitionAnalysisModules from './taxTransitionAnalysis';

function useModuleInView(id?: string) {
  const [, setTableOfContentsModuleVisibility] = useAtom(
    tableOfContentsWriteModuleVisibilityAtom,
  );

  const { ref, entry, inView } = useInView({
    threshold: [0, 0.1, 0.25, 0.5, 0.75, 0.95, 1],
  });

  useEffect(() => {
    if (!id) return;

    const { boundingClientRect = null, intersectionRatio = 0 } = entry || {};
    const { height, bottom } = boundingClientRect || { height: 0, bottom: 0 };
    const isBottomQuarterVisible = bottom > height / 4;
    const isFullyVisible = intersectionRatio > 0.99;
    const isVisible = inView && (isBottomQuarterVisible || isFullyVisible);

    setTableOfContentsModuleVisibility({ id, isVisible });
  }, [id, entry, setTableOfContentsModuleVisibility, inView]);

  return { ref };
}

interface ModuleSelectorProps {
  section: CompoundDocumentSection;
  documentProperties: Record<string, any>;
}

const getModule = (
  moduleType: string,
  { section, documentProperties }: ModuleSelectorProps,
) => {
  switch (true) {
    case moduleType in membershipProposalModules:
      return membershipProposalModules[moduleType](section.properties as any);
    case moduleType in salesIntroductionModules:
      return salesIntroductionModules[moduleType](section.properties as any);
    case moduleType in quarterlyPerformanceUpdateModules:
      return quarterlyPerformanceUpdateModules[moduleType]({
        ...section.properties,
        ...documentProperties,
      } as any);
    case moduleType in assetAllocationInvestmentProposalModules:
      return assetAllocationInvestmentProposalModules[moduleType]({
        ...section.properties,
        ...documentProperties,
      } as any);
    case moduleType in TaxTransitionAnalysisModules:
      return TaxTransitionAnalysisModules[moduleType]({
        ...section.properties,
        ...documentProperties,
      } as any);
    default:
      if (isProduction) return null;
      return <Text>Unsupported component type: {moduleType}</Text>;
  }
};

function ModuleSelector(props: ModuleSelectorProps) {
  const { section, documentProperties } = props;
  const { ref } = useModuleInView(section?.id);

  const moduleType = section.moduleGroup
    ? `${section.moduleGroup}.${section.moduleName}`
    : section.moduleName;

  if (!section) return null;

  /**
   * Make sure to wrap each module container with the ref and section id. The id
   * supports the table of contents and the ref is used to determine if the
   * module is in view.
   */
  return (
    <Box
      ref={ref}
      id={section.id}
      as="article"
      css={{ scrollMarginTop: '$20' }}
    >
      {getModule(moduleType, { section, documentProperties })}
    </Box>
  );
}

function ModuleSelectorUsingContext(props) {
  const { module, documentProperties } = useContext(ModuleContext);

  if (!module) return null;

  if (props.isEditor)
    return (
      <ModuleControls module={module} mutateDocument={props.mutateDocument}>
        <ModuleSelector
          section={module}
          documentProperties={documentProperties}
        />
      </ModuleControls>
    );

  return (
    <ModuleSelector section={module} documentProperties={documentProperties} />
  );
}

export default ModuleSelectorUsingContext;
