import {
  Box,
  Button,
  ChevronLeft,
  ChevronRight,
  Flex,
  Row,
  Text,
} from '@compoundfinance/design-system';
import useDocument from '../../hooks/useDocument';
import { useEffect, useRef } from 'react';
import { useSwipeable } from 'react-swipeable';

interface DocumentMobileNavigationProps {
  currentStep: number;
  setCurrentStep: (step: number) => void;
  totalSteps: number;
}

function DocumentMobileNavigation(props: DocumentMobileNavigationProps) {
  const { currentStep, setCurrentStep, totalSteps } = props;

  const { document } = useDocument();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const moduleId = document['moduleOrder'][currentStep];
      const element =
        containerRef.current.getElementsByClassName(moduleId)?.[0];
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth', inline: 'center' });
      }, 0);
    }
  }, [currentStep, document]);

  // #region swipeable

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === 'Left') {
        if (currentStep < (document?.moduleOrder?.length - 1 || 1)) {
          setCurrentStep(currentStep + 1);
        }
      } else if (eventData.dir === 'Right') {
        if (currentStep > 0) {
          setCurrentStep(currentStep - 1);
        }
      }
    },
    touchEventOptions: { passive: true },
    trackMouse: true,
  });

  // #endregion

  return (
    <Box css={{ position: 'relative', userSelect: 'none' }} {...handlers}>
      <Flex
        css={{
          jc: 'space-between',
          gap: '$24',
          px: '$16',
          overflowX: 'auto',
          scrollSnapType: 'x mandatory',

          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        ref={containerRef}
      >
        {Array.from({ length: totalSteps }).map((_, index) => {
          const moduleId = document['moduleOrder'][index];
          const module = document.modules[moduleId];
          const title = module.properties.title;
          return (
            <Box
              key={index}
              css={{
                flexGrow: 1,
                pt: '$8',
                pb: '$12',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                scrollSnapAlign: 'center',
              }}
              onClick={(e) => {
                setCurrentStep(index);
              }}
              className={moduleId}
            >
              <Text
                size="15"
                color={currentStep === index ? 'gray12' : 'gray10'}
              >
                {title}
              </Text>
            </Box>
          );
        })}
      </Flex>

      <Row
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          visibility: currentStep === 0 ? 'hidden' : 'visible',
          h: '100%',
          pointerEvents: 'none',
        }}
      >
        <Button
          onClick={() => {
            if (currentStep > 0) {
              setCurrentStep(currentStep - 1);
            }
          }}
          variant="naked"
          css={{
            pointerEvents: 'auto',
            w: '$48',
            bg: '$gray2',
          }}
        >
          <ChevronLeft color="var(--colors-gray12)" />
        </Button>
        <Box
          css={{
            pointerEvents: 'none',
            h: '$48',
            w: '$48',
            background: 'linear-gradient(to right, $gray2, transparent)',
          }}
        ></Box>
      </Row>

      <Row
        css={{
          position: 'absolute',
          top: 0,
          right: 0,
          visibility: currentStep === totalSteps - 1 ? 'hidden' : 'visible',
          h: '100%',
          pointerEvents: 'none',
        }}
      >
        <Box
          css={{
            pointerEvents: 'none',
            h: '$48',
            w: '$48',
            background: 'linear-gradient(to left, $gray2, transparent)',
          }}
        ></Box>
        <Button
          onClick={() => {
            if (currentStep < totalSteps - 1) {
              setCurrentStep(currentStep + 1);
            }
          }}
          variant="naked"
          css={{
            pointerEvents: 'auto',
            w: '$48',
            bg: '$gray2',
          }}
        >
          <ChevronRight color="var(--colors-gray12)" />
        </Button>
      </Row>
    </Box>
  );
}

export default DocumentMobileNavigation;
