import moment from 'moment';

// dupe - src/utils/constants/tax/index.ts
export const CURRENT_TAX_YEAR = moment().year();

// dupe - src/utils/ui.ts
export const camelToCapitalCase = (s: string) =>
  s.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase();
  });

// dupe - src/utils/date.ts
export function startOfDayNative(date?: Date | string): Date {
  let d;

  switch (true) {
    case date instanceof Date:
      d = new Date(date!);
      break;
    case typeof date === 'string':
      d = new Date(date as string);
      break;
    default:
      d = new Date();
  }

  // Rewide hoursValue, minutesValue, secondsValue, msValue to beginning of day in UTC time.
  d.setUTCHours(0, 0, 0, 0);

  return d;
}
