import * as _ from 'lodash';

import { PrivateEquityAccount } from '@compoundfinance/compound-core/dist/types/equity';
import { CompanyAccountTypeLabels } from 'utils/constants/privateEquityAccount';
import { ProviderTypes } from '@compoundfinance/compound-core/dist/constants/equity';
import useTypedSelector from 'hooks/typedSelector';
import { getCurrentValuation, getValuationAtDate } from 'shared/equity/utils';
import { PeRelationships } from '@compoundfinance/compound-core/dist/constants/peAccount';
import { isCurrentEmployee } from 'shared/equity/peAccount/utils';

export const isInvestorAccount = (peAccount: PrivateEquityAccount) => {
  return !!(
    !peAccount.grants.length &&
    (peAccount.convertibleNotes?.length || peAccount.certificates?.length)
  );
};

const isCartaAccount = ({ provider }: { provider: string | null }) =>
  provider === ProviderTypes.Carta;

const isShareworksAccount = ({ provider }: { provider: string | null }) =>
  provider === ProviderTypes.Shareworks;

export const isProviderAuthAccount = (
  peAccount: PrivateEquityAccount | undefined,
) => {
  if (peAccount) {
    return (
      isCartaAccount(peAccount) ||
      peAccount.provider === ProviderTypes.Shareworks
    );
  }

  return false;
};

/**
 * Clears the company name of , Inc.
 */
export const sanitizeCoName = (name: string) => _.replace(name, ', Inc.', '');

function getActivePeAccount(peAccounts, activeAccountId?: string) {
  let activeAccount: PrivateEquityAccount;

  if (!activeAccountId) {
    activeAccount = peAccounts.find(
      (p: PrivateEquityAccount) =>
        isCurrentEmployee(p.relationship) && p.grants.length,
    );
  } else {
    activeAccount = peAccounts.find(
      (p: PrivateEquityAccount) => p.id === activeAccountId,
    );
  }

  return activeAccount || peAccounts[0];
}

const getEmployedStatusLabel = (peAccount: PrivateEquityAccount) => {
  if (isInvestorAccount(peAccount)) {
    return CompanyAccountTypeLabels.Investor;
  } else if (peAccount.relationship === PeRelationships.Advisor) {
    return CompanyAccountTypeLabels.Advisor;
  } else if (peAccount.relationship === PeRelationships.FormerAdvisor) {
    return CompanyAccountTypeLabels.FormerAdvisor;
  } else if (isCurrentEmployee(peAccount.relationship)) {
    return CompanyAccountTypeLabels.Current;
  }
  return CompanyAccountTypeLabels.Previous;
};

/**
 * Return when the PE Account was last updated. Look for the
 * corresponding integration, if the account was synced w/ a provider
 * and use the lastSynced field. Otherwise, use the peAccount's last updated
 * field.
 * @param peAccount
 */
function getLastSyncedDate(peAccount: PrivateEquityAccount) {
  const isManual = !PeAccountUtils.isProviderAuthAccount(peAccount);
  // eslint-disable-next-line
  const integration = useTypedSelector(
    (state) => state.integrations.equityIntegrations,
  ).find((i) => i.id === peAccount.integrationId);
  const syncedAt =
    !integration || isManual ? peAccount.updatedAt : integration.lastSynced;

  return syncedAt as Date;
}

const PeAccountUtils = {
  isCartaAccount,
  isProviderAuthAccount,
  isInvestorAccount,
  getActivePeAccount,
  getEmployedStatusLabel,
  getCurrentValuation,
  getLastSyncedDate,
  getValuationAtDate,
  isShareworksAccount,
};

export default PeAccountUtils;
