import { FlexTable } from '@compoundfinance/design-system';
import { Box } from '@compoundfinance/design-system';
import { useState } from 'react';
import { Pagination } from 'components/Pagination';
import UiUtils from 'utils/ui';

const TRANSACTIONS_PER_PAGE = 10;
const FLEX_STYLES = [0.3, 0.2, 0.25, 0.2, 0.3];

const TransactionTable = (props: { transactions: any[] }) => {
  const { transactions } = props;

  const pageCount = Math.ceil(transactions.length / TRANSACTIONS_PER_PAGE);
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <FlexTable css={{ p: 0, mb: '$48', ml: '$24' }}>
      <FlexTable.Head>
        <FlexTable.Row
          css={{
            height: '$32',
            borderRadius: 0,
          }}
          noHover
        >
          <FlexTable.HeadCell flex={FLEX_STYLES[0]}>Asset</FlexTable.HeadCell>
          <FlexTable.HeadCell flex={FLEX_STYLES[1]}>
            Shares sold
          </FlexTable.HeadCell>
          <FlexTable.HeadCell flex={FLEX_STYLES[2]}>
            Price / Share
          </FlexTable.HeadCell>
          <FlexTable.HeadCell flex={FLEX_STYLES[3]} align="right">
            Cost Basis
          </FlexTable.HeadCell>
          <FlexTable.HeadCell flex={FLEX_STYLES[4]} align="right">
            Realized Loss
          </FlexTable.HeadCell>
        </FlexTable.Row>
      </FlexTable.Head>
      <Box css={{ minHeight: pageCount > 1 ? '480px' : undefined }}>
        {transactions
          .slice(
            (currentPage - 1) * TRANSACTIONS_PER_PAGE,
            currentPage * TRANSACTIONS_PER_PAGE,
          )
          .map((data) => (
            <FlexTable.Row noHover css={{ '>span': { fontSize: '$13' } }}>
              <FlexTable.Cell flex={FLEX_STYLES[0]}>
                {data.position}
              </FlexTable.Cell>
              <FlexTable.Cell flex={FLEX_STYLES[1]}>
                {UiUtils.sanitize(data.quantity)}
              </FlexTable.Cell>
              <FlexTable.Cell flex={FLEX_STYLES[2]}>
                {UiUtils.formatMoney(data.price)}
              </FlexTable.Cell>
              <FlexTable.Cell flex={FLEX_STYLES[3]} align="right">
                {UiUtils.formatMoney(
                  data.amount -
                    (data.realizedLTCG ?? 0) -
                    (data.realizedSTCG ?? 0),
                )}
              </FlexTable.Cell>
              <FlexTable.Cell flex={FLEX_STYLES[4]} align="right">
                {UiUtils.formatMoney(
                  -(data.realizedLTCG ?? 0) - (data.realizedSTCG ?? 0),
                )}
              </FlexTable.Cell>
            </FlexTable.Row>
          ))}
      </Box>
      {pageCount > 1 && (
        <Pagination
          count={pageCount}
          current={currentPage}
          setCurrent={setCurrentPage}
        />
      )}
    </FlexTable>
  );
};

export default TransactionTable;
