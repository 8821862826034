export const CERTIFICATE_CLASS_TYPES = {
  COMMON: 'common',
  PREFERRED: 'preferred',
};

export enum CertificateIssueReason {
  Standard = 'Standard',
  Exercise = 'Exercise',
}

export const DEFAULT_CERT = {
  issueDate: new Date(),
  election: null,
  issueReason: '',
  classType: 'common',
  class: 'common',
  securityId: '',
  label: '',
  userSaleEvents: [],
  pricePerShare: 0,
  quantity: 0,
  grantId: '',
  privateEquityAccountId: '',
  cancelledCount: 0,
  qsbs: null,
  notes: '',
};
