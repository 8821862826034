import { Flex } from '@compoundfinance/design-system';
import ErrorScreen from 'components/PublicInvestmentView/ErrorScreen';

export const ErrorBoundaryFallback = () => {
  return (
    <Flex
      css={{
        m: 'auto',
        jc: 'center',
        ai: 'center',
        br: '$6',
        border: '$borderStyles$default',
        bg: '$gray1',
        p: '$20',
      }}
    >
      <ErrorScreen />
    </Flex>
  );
};
