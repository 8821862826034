import { PlaidAccount } from '@compoundfinance/compound-core/dist/types/plaid';
import {
  PlaidToAssetTypeMap,
  PLAID_FILTERED_ACCOUNT_TYPES,
  PLAID_ACCOUNT_TYPES,
} from './constants';

export type PlaidAccountType = Pick<PlaidAccount, 'type'>;
type PlaidAccountOriginalType = Pick<PlaidAccount, 'originalType'>;

function isCrypto(account: PlaidAccountType) {
  return account.type === PLAID_ACCOUNT_TYPES.CRYPTO;
}

function wasInvestment(account: PlaidAccountOriginalType) {
  return account.originalType === PLAID_ACCOUNT_TYPES.INVESTMENT;
}

function isInvestment(account: PlaidAccountType) {
  return account.type === PLAID_ACCOUNT_TYPES.INVESTMENT;
}

function isBank(account: PlaidAccountType) {
  return account.type === PLAID_ACCOUNT_TYPES.DEPOSITORY;
}

function isCredit(account: PlaidAccountType) {
  return account.type === PLAID_ACCOUNT_TYPES.CREDIT;
}

function isLoan(account: PlaidAccountType) {
  return account.type === PLAID_ACCOUNT_TYPES.LOAN;
}

function isOther(account: PlaidAccountType) {
  return account.type === PLAID_ACCOUNT_TYPES.OTHER;
}

/**
 * Filters a list of plaid account objects into groups according to their type.
 * The returned object has keys defined in the PLAID_FILTERED_ACCOUNT_TYPES enum
 * @param accounts A list of plaid accounts
 */
function groupByAccountType(accounts: PlaidAccount[]) {
  const investmentAccounts = accounts.filter(isInvestment);
  const bankAccounts = accounts.filter(isBank);
  const creditAccounts = accounts.filter(isCredit);
  const loanAccounts = accounts.filter(isLoan);
  const cryptoAccounts = accounts.filter(isCrypto);
  const otherAccounts = accounts.filter(isOther);

  return {
    [PlaidToAssetTypeMap[PLAID_FILTERED_ACCOUNT_TYPES.INVESTMENT]]:
      investmentAccounts,
    [PlaidToAssetTypeMap[PLAID_FILTERED_ACCOUNT_TYPES.BANK]]: bankAccounts,
    [PlaidToAssetTypeMap[PLAID_FILTERED_ACCOUNT_TYPES.CREDIT]]: creditAccounts,
    [PlaidToAssetTypeMap[PLAID_FILTERED_ACCOUNT_TYPES.LOAN]]: loanAccounts,
    [PlaidToAssetTypeMap[PLAID_FILTERED_ACCOUNT_TYPES.CRYPTO]]: cryptoAccounts,
    [PlaidToAssetTypeMap[PLAID_FILTERED_ACCOUNT_TYPES.OTHER]]: otherAccounts,
  };
}

const PlaidAccountSharedUtils = {
  isInvestment,
  isBank,
  isCredit,
  isLoan,
  isCrypto,
  groupByAccountType,
  wasInvestment,
};

export default PlaidAccountSharedUtils;
