import UiUtils from 'utils/ui';
import { getEnumValues } from 'utils/constants';
import { PeRelationships } from '@compoundfinance/compound-core/dist/constants/peAccount';

export const PeAccountFields = {
  Fmv: 'fmv',
  OriginalFmv: 'originalFmv',
  PreferredPrice: 'preferredPrice',
  EstimatedPrice: 'estimatedPrice',
  Valuations: 'valuation',
  Grants: 'grants',
  StartDate: 'startDate',
  EndDate: 'endDate',
  HasExercised: 'hasExercised',
  Provider: 'provider',
  CompanyName: 'companyName',
  Relationship: 'relationship',
  OwnerId: 'ownerId',
  Notes: 'notes',
  HasLiquidityEvent: 'hasLiquidityEvent',
  LiquidityEventAt: 'liquidityEventAt',
  BrokerageAdded: 'brokerageAdded',
  TickerSymbol: 'tickerSymbol',
  IsQsbsQualifiedBusiness: 'isQsbsQualifiedBusiness',
  TotalAssetsOver50Million: 'totalAssetsOver50Million',
  TotalAssetsOver50MillionDate: 'totalAssetsOver50MillionDate',
  ValuationMethod: 'valuationMethod',
  FundingRound: 'fundingRound',
  SharesOutstanding: 'sharesOutstanding',
  EarlyExercise: 'earlyExercise',
  ExerciseWindow: 'exerciseWindow',
};

export const PeAccountLabels = {
  ownerId: 'Owner',
  companyName: 'Company name',
  relationship: 'What is your relationship with the company?',
  endDate: 'When did you leave?',
  hasLiquidityEvent:
    'Is this company publicly traded / experienced a liquidation event?',
  liquidityEventAt: 'Liquidity event date',
  brokerageAdded:
    'Have you added the brokerage account holding your shares to Compound?',
  fmv: 'Valuation',
  preferredPrice: 'Latest preferred price / share',
  estimatedPrice: 'Latest estimated price / share',
  tickerSymbol: 'Public stock ticker symbol',
  isQsbsQualifiedBusiness: 'Is qualified business (QSBS)',
  totalAssetsOver50Million: 'totalAssetsOver50Million',
  totalAssetsOver50MillionDate: 'totalAssetsOver50MillionDate',
  valuationMethod: 'Price used for valuation',
  fundingRound: 'Latest funding round',
  sharesOutstanding: 'Total shares outstanding',
  earlyExerciseAllowed: 'Early exercise allowed',
  exerciseWindow: 'Options expiration policy',
};

export enum CompanyAccountTypeLabels {
  Current = 'Current Employer',
  Previous = 'Former Employer',
  Advisor = 'Advisor',
  FormerAdvisor = 'Former Advisor',
  Investor = 'Investor',
}

export const PE_RELATIONSHIP_SELECT_OPTIONS = getEnumValues(
  PeRelationships,
).map((v) => ({ label: UiUtils.camelToCapitalCase(v), value: v }));

export enum LiquidityEventStatus {
  Public = 'public',
  Private = 'private',
}

export const LiquidityEventMap = {
  [LiquidityEventStatus.Public]: true,
  [LiquidityEventStatus.Private]: false,
};

export const PE_ACCOUNT_TRIGGER_EVENT_OPTIONS = [
  {
    value: LiquidityEventStatus.Private,
    label: 'Private / no liquidation event',
  },
  {
    value: LiquidityEventStatus.Public,
    label: 'Public / had liquidation event',
  },
];
