import React from 'react';

import { FlexRow } from 'components/global';
import ChevronLeft from '@compoundfinance/design-system/dist/icons/legacy/ChevronLeft';
import { TypographyButton } from 'components/global/button';
import Typography from 'components/global/Typography';
import { CompoundColor } from 'components/types';

interface PaginationButtonProps {
  value: number;
  current: number;
  setCurrent: (val: number) => void;
  onBackground?: boolean;
}

function PaginationButton(props: PaginationButtonProps) {
  const color = props.onBackground ? 'hover_grey_on_background' : 'light_grey';
  return (
    <TypographyButton
      fontWeight={525}
      color={props.value === props.current ? 'black' : 'grey'}
      hoverBg={color}
      bg={props.value === props.current ? color : 'transparent'}
      onClick={() => props.setCurrent(props.value)}
      h="20px"
      textAlign="center"
      className="py-0 px-xs05"
    >
      {props.value}
    </TypographyButton>
  );
}

interface PaginationProps extends React.HTMLAttributes<HTMLElement> {
  bg?: CompoundColor;
  className?: string;
  count: number;
  current: number;
  style?: React.CSSProperties;
  setCurrent: (current: number) => void;
  onBackground?: boolean;
}

const chevronButtonWrapStyle = {
  boxSizing: 'border-box',
} as React.CSSProperties;

const MAX_PAGES_VISIBLE = 9;
const MID_PAGES_COUNT = 5;

/**
 * Pagination interface for long lists.
 * @param props.count The total number of pages to be paginated
 * @param props.current The current page of paginated data
 * @param props.setCurrent Updates the current page of data
 * @param props.className Wrapper className
 * @param props.bg background color. Optional.
 * @returns
 */
export function Pagination(props: PaginationProps) {
  const { className = 'my-base', bg = 'transparent', style, ...rest } = props;

  const numbers = Array.from({ length: props.count }, (_, i) => i + 1);
  const showAnyEllipsis = props.count > MAX_PAGES_VISIBLE;
  const showStartEllipsis = showAnyEllipsis && props.current >= MID_PAGES_COUNT;
  const showEndEllipsis =
    showAnyEllipsis &&
    props.current < props.count - Math.ceil(MID_PAGES_COUNT / 2);

  const numbersToShowInTheMiddle = (() => {
    if (props.count <= MAX_PAGES_VISIBLE) {
      return numbers.slice(1, -1);
    }
    if (!showEndEllipsis) {
      return numbers.slice(-MID_PAGES_COUNT - 1, -1);
    }
    if (!showStartEllipsis) {
      return numbers.slice(1, MID_PAGES_COUNT + 1);
    }
    return numbers
      .slice(props.current - Math.ceil(MID_PAGES_COUNT / 2), -1)
      .slice(0, MID_PAGES_COUNT);
  })();

  return (
    <FlexRow
      className={className}
      justifyContent="center"
      style={{ userSelect: 'none', ...style }}
      bg={bg}
      {...rest}
    >
      <FlexRow
        as="button"
        aria-label="Previous page"
        className="br mr-base p-xs border-none"
        hoverBg={props.current === 1 ? 'transparent' : 'light_grey'}
        justifyContent="center"
        onClick={() => props.setCurrent(Math.max(props.current - 1, 1))}
        style={chevronButtonWrapStyle}
        disabled={props.current === 1}
      >
        <ChevronLeft
          className=""
          color={props.current === 1 ? 'border_grey' : ''}
        />
      </FlexRow>
      <PaginationButton
        value={1}
        current={props.current}
        setCurrent={props.setCurrent}
        onBackground={props.onBackground}
      />
      {showStartEllipsis && <Typography color="grey">...</Typography>}
      {numbersToShowInTheMiddle.map((num) => (
        <PaginationButton
          key={num}
          value={num}
          current={props.current}
          setCurrent={props.setCurrent}
          onBackground={props.onBackground}
        />
      ))}
      {showEndEllipsis && <Typography color="grey">...</Typography>}
      {props.count > 1 && (
        <PaginationButton
          value={props.count}
          current={props.current}
          setCurrent={props.setCurrent}
          onBackground={props.onBackground}
        />
      )}
      <FlexRow
        as="button"
        className="br ml-base p-xs border-none"
        hoverBg={props.current === props.count ? 'transparent' : 'light_grey'}
        justifyContent="center"
        onClick={() =>
          props.setCurrent(Math.min(props.current + 1, props.count))
        }
        style={chevronButtonWrapStyle}
        aria-label="Next page"
        disabled={props.current === props.count}
      >
        <ChevronLeft
          style={{ transform: 'rotateY(180deg)' }}
          color={props.current === props.count ? 'border_grey' : ''}
        />
      </FlexRow>
    </FlexRow>
  );
}
