import { createContext, useContext } from 'react';
import { CompoundFile } from 'types/file';
import { NewUploadParams, UploadParams } from '.';

import { FileManagerState } from './reducer';

interface ContextState {
  fileManagerState: FileManagerState;
  filesBeingEdited: CompoundFile[];
  setFilesBeingEdited: React.Dispatch<React.SetStateAction<CompoundFile[]>>;
  selectedFiles: Set<string>;
  setSelectedFiles: React.Dispatch<React.SetStateAction<Set<string>>>;
  deleteFile: (file: CompoundFile) => void;
  deleteFiles: (files: CompoundFile[]) => void;
  downloadFile: (fileId: string, fileName: string, isOpen?: boolean) => void;
  downloadFiles: (files: CompoundFile[]) => void;
  uploadFile: (params: UploadParams) => Promise<CompoundFile | undefined>;
  newUploadFile: (params: NewUploadParams) => Promise<CompoundFile | undefined>;
}

export const FileManagerContext = createContext<ContextState | null>(null);

// This returns `ContextState` if used inside context and `null` otherwise.
// This then allows callling `state.deleteFile()` instead of `state?.deleteFile()`
// and if it's outside of context then it will throw an error like it should.
export default function useFileManagerContext() {
  return useContext(FileManagerContext)!;
}
