import { Box, Text } from '@compoundfinance/design-system';
import { useContext, useLayoutEffect, useRef, useState } from 'react';
import { useMutableModuleProperty } from '../../Advisor/DocumentsAdmin/hooks/useMutableProperty';
import useIsDocumentEditor from 'containers/Dashboard/Advisor/DocumentsAdmin/hooks/useIsDocumentEditor';
import ModuleContext from 'containers/Dashboard/Advisor/DocumentsAdmin/components/DocumentViewer/ModuleContext';
import { CSS } from '@compoundfinance/design-system/dist/stitches.config';

interface EditableTextProps {
  propertyKey: string;
  value: string;
  children?: React.ReactNode;
  textCss?: CSS;
}

function EditableTextArea(props: EditableTextProps) {
  const { children, textCss } = props;
  const [syncValue, setValue] = useMutableModuleProperty(
    props.propertyKey,
    props.value,
  );
  const [editMode, setEditMode] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const isDocumentEditor = useIsDocumentEditor();
  const { isHover } = useContext(ModuleContext);

  const renderVal = syncValue || props.value;

  useLayoutEffect(() => {
    if (!textareaRef.current) return;
    textareaRef.current.style.height = '1px';
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  }, [editMode]);

  if (editMode) {
    return (
      <textarea
        style={{
          overflow: 'hidden',
          resize: 'none',
          width: '100%',
          appearance: 'none',
        }}
        ref={textareaRef}
        autoFocus
        onBlur={(e) => {
          setValue(e.target.value && e.target.value.trim());
          setEditMode(false);
        }}
        onChange={(e) => {
          if (!textareaRef.current) return;
          textareaRef.current.style.height = '1px';
          textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }}
        defaultValue={renderVal}
        placeholder="Enter some text here"
      />
    );
  }

  const trimmedText = typeof renderVal === 'string' ? renderVal.trim() : '';

  return (
    <Box
      onClick={(e) => {
        if (!isDocumentEditor) return;
        setEditMode(true);
      }}
      css={{ bg: isHover ? '$indigo3' : 'inherit' }}
    >
      {children ? (
        children
      ) : (
        <Text size="14" css={{ whiteSpace: 'pre-line', ...textCss }}>
          {trimmedText}
        </Text>
      )}
    </Box>
  );
}
export default EditableTextArea;
