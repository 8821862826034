/**
 * Certain actions performed programmatically on DOM elements won't trigger their related events.
 * i.e., programmatically changing the `.value` property of an `input` won't trigger `onChange`.
 * Whenever you need to
 * - programmatically perform an action on a DOM element, and
 * - trigger a related event as a consequence
 * you can rely on this function.
 *
 * Note: the event needs to `bubble` for some (unknown to me as of now) reason.
 */
const triggerReactEvent = (eventTarget: EventTarget, eventType: string) => {
  const event = new Event(eventType, { bubbles: true });
  eventTarget.dispatchEvent(event);
};

const hasVerticalScrollbar = (element) =>
  ['auto', 'scroll'].some(
    (scrollableOverflowValue) =>
      window.getComputedStyle(element).overflowY === scrollableOverflowValue,
  );
const isVerticallyScrollable = (element) =>
  element.scrollHeight > element.clientHeight;
const getVerticallyScrollableAncestor = (element: HTMLElement | null) => {
  if (!element) return null;
  if (element === window.document.body) return element;

  if (hasVerticalScrollbar(element) && isVerticallyScrollable(element))
    return element;

  return getVerticallyScrollableAncestor(
    element.parentElement ?? window.document.body,
  );
};

function isInViewport(
  element: HTMLElement,
  container = getVerticallyScrollableAncestor(element),
) {
  const bonundingClientRect = element.getBoundingClientRect();

  return (
    bonundingClientRect.top >= 0 &&
    bonundingClientRect.left >= 0 &&
    bonundingClientRect.bottom <=
      (container.innerHeight || container.clientHeight) &&
    bonundingClientRect.right <= (container.innerWidth || container.clientWidth)
  );
}
// Per: https://stackoverflow.com/questions/62954570/javascript-feature-detect-module-support-for-web-workers
function supportsWorkerType() {
  let supports = false;
  const tester: WorkerOptions = {
    get type() {
      supports = true;
      return 'module' as WorkerType;
    }, // it's been called, it's supported
  };
  try {
    // We use "blob://" as url to avoid an useless network request.
    // This will either throw in Chrome
    // either fire an error event in Firefox
    // which is perfect since
    // we don't need the worker to actually start,
    // checking for the type of the script is done before trying to load it.
    new Worker('data:,', tester).terminate();
  } finally {
    return supports;
  }
}

export const DOMUtils = {
  getVerticallyScrollableAncestor,
  isInViewport,
  triggerReactEvent,
  supportsWorkerType,
};
