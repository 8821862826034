/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
import './index.css';
import 'react-day-picker/lib/style.css';
import { datadogRum } from '@datadog/browser-rum';

// Needed for redux-saga es6 generator support
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'assets/fonts/leMonde/LeMondeLivreStd-Bold.otf';
import 'assets/fonts/leMonde/LeMondeLivreStd-ExtraDemi.otf';
import 'assets/fonts/leMonde/LeMondeLivreStd-Regular.otf';
// Import all the third party stuff
import 'assets/stylesheets/bootstrap-reboot.css';
import 'assets/stylesheets/argyle-link.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { ConnectedRouter } from 'connected-react-router';
import ReactDOM from 'react-dom/client';
import { Suspense } from 'react';
import { Provider } from 'react-redux';
import SWRDevTools from './lazy-swr-devtools';
import AxiosInterceptors from 'AxiosInterceptors';
import history, { getIsEmbedded } from './utils/history';
import { DEVELOPMENT, getEnvironmentName } from './utils/env';
import { MotionConfig } from 'framer-motion';

// Import root app
import App from './containers/App';

import configureStore from './configureStore';
import { ThemeProvider } from 'styled-components';
import { mainTheme } from 'style/theme';
import GlobalStyle from 'global-styles';
import 'style/atomic.scss';
import moment from 'moment';
// Safari doesn't implement scroll-behavior: smooth, so add a polyfill
import smoothscroll from 'smoothscroll-polyfill';
import './developmentServer';
import './productionMockServer';

import CompoundStyle from 'style/compound';
import Sentry from './utils/sentry';
import { Tooltip } from '@compoundfinance/design-system';
import { setActiveStore } from 'store';
import { ToastHandler } from 'design-system';

smoothscroll.polyfill();

if (getEnvironmentName() !== DEVELOPMENT) {
  const embedded = getIsEmbedded();
  datadogRum.init({
    applicationId: '8bc33784-ba31-485d-8429-e6033d087994',
    clientToken: 'pubc94b65bd934ff25c1d24b968639e798b',
    site: 'datadoghq.com',
    service: 'compound',
    env: getEnvironmentName(),
    allowedTracingUrls: [
      /https:\/\/.*\.compoundplanning\.com/,
      /https:\/\/.*\.withcompound.com/,
      /https:\/\/.*\.alternativ\.com/,
    ],
    defaultPrivacyLevel: 'mask-user-input',
    sessionReplaySampleRate: embedded ? 50 : 0,
    sessionSampleRate: 100,
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: embedded,
  });
}

Sentry.init();

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
setActiveStore(store);

// Override moment 'seconds ago' string
const secondsAgo = 'just now';
moment.updateLocale('en', {
  relativeTime: {
    past: (str) => (str === secondsAgo ? str : `${str} ago`),
    s: secondsAgo,
    m: '1 min',
    mm: (number) => {
      return `${number} mins`;
    },
    h: '1 hr',
    hh: (number) => {
      return `${number} hrs`;
    },
    d: '1 day',
    dd: (number) => {
      return `${number} days`;
    },
    M: '1 mo',
    MM: (number) => {
      return `${number} mos`;
    },
    y: '1 yr',
    yy: (number) => {
      return `${number} yrs`;
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <Suspense fallback={null}>
    <SWRDevTools>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <MotionConfig reducedMotion="user">
            <ThemeProvider theme={mainTheme}>
              <Tooltip.Provider>
                <GlobalStyle />
                <CompoundStyle />
                <AxiosInterceptors>
                  <App />
                </AxiosInterceptors>
                <ToastHandler />
              </Tooltip.Provider>
            </ThemeProvider>
          </MotionConfig>
        </ConnectedRouter>
      </Provider>
    </SWRDevTools>
  </Suspense>,
);
