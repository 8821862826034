import { Text } from '@compoundfinance/design-system';
import EditableTextArea from 'containers/Dashboard/Document/components/EditableTextArea';
import { SectionTitleProps } from '../modules/MembershipProposal/Custom/Custom';

export function EditableSectionTitle(props: SectionTitleProps) {
  const { title } = props;
  return (
    <Text
      as="h2"
      weight="medium"
      color="gray8"
      css={{
        fontSize: '$20',
        letterSpacing: '-0.8px',

        '@bp1': { fontSize: '$48', lineHeight: '58px' },
      }}
    >
      <EditableTextArea propertyKey="title" value={title}>
        {title}
      </EditableTextArea>
    </Text>
  );
}
