import { createGlobalStyle, keyframes } from 'styled-components';
import {
  ORANGE,
  BREAKPOINT_C,
  OFF_BLACK,
} from '@compoundfinance/design-system/dist/style/theme';
import { mainTheme } from 'style/theme';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
` as any;

const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 0;
  }

  to {
    transform: scale(.25);
    opacity: 1;
  }
` as any;

// Add all colors as CSS variables, prefixed with `color-` as some of the names aren't clear color names
const ColorGlobalVars = Object.entries(mainTheme.colors)
  .map(([color, value]) => `--color-${color}: ${value};`)
  .join('');

const GlobalStyle = createGlobalStyle`
:root {
  ${ColorGlobalVars}
}
  html,
  body {
    height: 100%;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.bg_grey} !important;
    color: #222;
    h1, h2, h3, h4 {
      margin: 0;
    }
  }
  #root {
    height: 100%;
  }

  iframe {
	  pointer-events: auto;
  }
  button {
    outline: none;
    &:focus {
      outline: none;
    }
    line-height: 1;
  }

  h1,h2 {
    font-weight: 600;
    margin: 0;
  }
  h3,h4 {
    font-weight: 525;
    margin: 0;
  }

  body { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    body { font-family: 'Inter var', sans-serif; }
  }

  label {
    margin: 0;
  }

  ul, li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .list {
    margin-bottom: 0;
  }
  .flex {
    display: flex;
  }
  .accent {
    color: ${ORANGE};
  }

/**
  RC-slider specific
 */
.rc-slider {
  height: 12px;
}
.rc-slider-disabled {
  background-color: transparent !important;
}
.rc-slider-handle:focus {
  box-shadow: 0 1px 5px 0px rgba(0,0,0,0.38);
}
.rc-slider-handle:hover {
  box-shadow: 0 1px 5px 0px rgba(0,0,0,0.38);
}


.fade-wait-leave {
  opacity: 1;
}
.fade-wait-leave.fade-wait-leave-active {
  opacity: 0;
  transition: opacity 0.4s ease-in;
}

.fade-wait-enter {
  opacity: 0;
}
.fade-wait-enter.fade-wait-enter-active {
  opacity: 1;
  /* Delay the enter animation until the leave completes */
  transition: opacity 0.4s ease-in 0.6s;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    z-index: 999;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
}

.hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

.ReactModal__Content {
  &.ReactModal__Content--after-open {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
}

.ReactModal__Body--open {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;

  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.ant-checkbox-wrapper {
  width: 15px;
}

.select-wrapper {
  position: relative;
  overflow: hidden;
  &:after {
    font-family: FontAwesome;
  	content: '\f107';
  	font-size: 28px;
  	position: absolute;
  	top: 12px;
  	right: 20px;
  	color: #434B67;
  	pointer-events: none;
  }
}


.fade-wait-height {
  transition: height 0.2s ease-in-out;
}

  #app {
    background-color: #fff;
    height: 100%;
    min-width: 100%;
    margin: 0;
    padding: 0;
  }

  .fadeAnim {
    transition: visibility .1s linear;
    visibility: ${(props: any) => (props.out ? 'hidden' : 'visible')};
    animation: ${(props: any) => (props.out ? fadeOut : fadeIn)} .1s linear;
    transition: visibility .1s linear;
  }

  .submitColor {
    &:hover {
      color: #24A3FF !important;
      opacity: 0.6 !important;
    }
  }

  .buttonLink {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }

  .link-account-button {
    width: 100%
    text-align: center;
    background-color: ${({ theme }) => theme.colors.muted_blue};
    color: ${({ theme }) => theme.colors.white};
    font-weight: ${({ theme }) => theme.fw.bold};
    font-size: 0.875rem;
    border: 1px solid;
    border-radius: 4px;
    transition: 0.3s;
    padding: 0.55rem;
    min-width: 185px;
    &:hover {
      opacity: 0.8;
    }
    &:focus {
      outline: none;
    }
  }

  .tooltipModal {
    max-width: 20rem;
    opacity: 1 !important;
    font-size: 13px !important;
  }

  .rc-tooltip {
    z-index: 9999999;
  }

  .rc-tooltip-inner {
    color: rgb(207, 217, 226) !important;
    background-color: #222 !important;
    padding: 0.5rem 0.75rem;

  }

  .rc-tooltip-hide-arrow {
    .rc-tooltip-arrow {
      display: none;
    }
  }

  .light.rc-tooltip-placement-top {
    background: white !important;
    padding: 0px !important;
    opacity: 1 !important;
    .rc-tooltip-content{
      .rc-tooltip-inner {
        height: auto !important;
        width: auto !important;
        color: black !important;
        background-color: white !important;
        padding: 0.5rem;
      }
      .rc-tooltip-arrow {
        display: none;
      }
    }
  }

  .light {
    &.rc-tooltip-placement-bottom,
    &.rc-tooltip-placement-bottomLeft,
    &.rc-tooltip-placement-bottomRight,
    &.rc-tooltip-placement-top,
    &.rc-tooltip-placement-topLeft,
    &.rc-tooltip-placement-topRight,
    &.rc-tooltip-placement-left,
    &.rc-tooltip-placement-right {
      padding: 0px !important;
      opacity: 1 !important;
      border-radius: 6px;
      .rc-tooltip-content{
        border-radius: 6px;
        .rc-tooltip-inner {
          height: auto !important;
          width: auto !important;
          color: black !important;
          background-color: white !important;
          padding: 0.5rem ;
          border-radius: 6px;
          white-space: nowrap

          &.text-formatting {
            font-size: .875rem;
            font-weight: 525;
            line-height: 1;
            padding: .75rem 1rem !important;
          }
        }

        .rc-tooltip-arrow {
          display: none;
        }
      }
    }
  }

  .light.bold-content {
    .rc-tooltip-content{
      .rc-tooltip-inner {
        font-size: .875rem;
        font-weight: 525;
        line-height: 1;
        padding: .75rem 1rem;
      }
    }
  }

  .submitBtn {
    display: flex;
    padding: 12px 14px !important;
    background-color: #003459 !important;
    color: #fff !important;
    border: none !important;
    border-radius: 4px;
    transition: 0.3s ease;
    &:hover {
      opacity: 0.8;
    }
  }

  .hollow-dots-spinner, .hollow-dots-spinner * {
    box-sizing: border-box;
  }

  .hollow-dots-spinner {
    height: 15px;
    width: calc(30px * 3);
  }

  .hollow-dots-spinner .dot {
    width: 15px;
    height: 15px;
    margin: 0 calc(15px / 2);
    border: calc(15px / 5) solid #ff1d5e;
    border-radius: 50%;
    float: left;
    transform: scale(0);
    animation: hollow-dots-spinner-animation 1000ms ease infinite 0ms;
  }

  .hollow-dots-spinner .dot:nth-child(1) {
    animation-delay: calc(300ms * 1);
  }

  .hollow-dots-spinner .dot:nth-child(2) {
    animation-delay: calc(300ms * 2);
  }

  .hollow-dots-spinner .dot:nth-child(3) {
    animation-delay: calc(300ms * 3);

  }

  @keyframes hollow-dots-spinner-animation {
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .body-text {
    color: ${OFF_BLACK};
    letter-spacing: -0.13px;
    font-size: 1.31rem;
    line-height: 2.1rem;
    max-width: 38.125rem;
    font-weight: 200;
    font-family: 'Le Monde Livre';
    margin-bottom: 1.9rem;
    margin-top: 0;
    text-align: 'inherit';
    min-width: 10rem;
    @media screen and (max-width: ${BREAKPOINT_C}) {
      font-size: 1.05rem;
      line-height: 1.75rem;
    }
    a {
      color: #007bff;
      &:hover {
        text-decoration: none;
        opacity: 1;
        color: #007bff;
      }
    }
  }

  .title-screen-body-text {
    color: ${OFF_BLACK};
    letter-spacing: -0.13px;
    font-size: 1.31rem;
    line-height: 2.1rem;
    max-width: 45%;
    font-weight: 200;
    font-family: 'Le Monde Livre';
    margin-bottom: 1.9rem;
    margin-top: 0;
    min-width: 10rem;
    @media screen and (max-width: ${BREAKPOINT_C}) {
      max-width: 100%;
      text-align: center;
      font-size: 1.15rem;
    }
  }

}

.fastlink-hidden {
  display: none;
}

.fastlink-overlay {
  position: fixed;
  z-index: 99999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden auto;
  background-color: rgba(144, 144, 144, 0.98);
}

.fastlink-container {
  max-width: 750px;
  min-height: 500px;
  background-color: #fff;
  margin: 10vh auto;
  border-radius: 6px;
  overflow: hidden;
}

.delay-visibility {
  animation: .2s popIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes popIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.flicker-anim {
  animation: flicker 2s infinite;
}

@keyframes flicker {
  0% {
    fill: ${({ theme }) => theme.colors.grey};
  }

  50% {
    fill: ${({ theme }) => theme.colors.muted_blue};
  }

  100% {
    fill: ${({ theme }) => theme.colors.grey};
  }
}

.progress-border {
  position: relative;

  &::before {
    border: 4px solid ${({ theme }) => theme.colors.darker_yellow};
    border-radius: 50%;
    border-top-color: transparent;
    box-sizing:content-box;
    content: '';
    width: 100%;
    height: 100%;
    height: 100%;
    left:50%;

    position: absolute;
    top: 50%;
    /* adjust where opening appears */
    transform: translateX(-50%) translateY(-50%) rotate(310deg);
    width: 100%;
  }
}

.fade-in-effect {
  visibility: visible;
  opacity: 1;
  transition: opacity .5s linear;
}

.fade-out-effect {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .5s, opacity .5s linear;
}

.fade-in-effect-fast {
  visibility: visible;
  opacity: 1;
  transition: opacity .25s linear;
}

.fade-out-effect-fast {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .25s, opacity .25s linear;
}

.fade-in-effect-faster {
  visibility: visible;
  opacity: 1;
  transition: opacity .1s linear;
}

.fade-out-effect-faster {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .1s, opacity .1s linear;
}

.transition-delay-100 {
  transition-delay: .1s;
}

` as any;

export default GlobalStyle;
