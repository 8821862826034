import { useState } from 'react';

import {
  Box,
  Button,
  Col,
  CollapseToggleIcon,
  Flex,
  Row,
  Text,
} from '@compoundfinance/design-system';

import { ValueOf } from 'types';
import {
  AllocationProposal,
  AllocationProposalTypes,
} from 'containers/Dashboard/AllocationProposals/domain/AllocationProposal';
import { AssetCategoryTree } from 'containers/Dashboard/AllocationProposals/utils/AssetCategoryTree';
import { AllocationProposalStrategy } from 'containers/Dashboard/AllocationProposals/domain/AllocationProposalStrategy';
import {
  Allocation,
  AllocationTypes,
} from 'containers/Dashboard/AllocationProposals/domain/Allocation';
import { NumberUtils } from 'utils/NumberUtils';
import { ASSET_CATEGORY_SLUG_TO_COLOR } from 'domain/AssetCategory/colors';

import AllocationBarChart, {
  WeightAndValueAllocationBarChartTooltip,
  WeightOnlyAllocationBarChartTooltip,
} from 'containers/Dashboard/AllocationProposals/components/Charts/AllocationBarChart';
import AssetsBarChart from 'containers/Dashboard/AllocationProposals/components/Charts/AssetsBarChart';
import {
  Value,
  Weight,
} from 'containers/Dashboard/Document/components/Figures';
import { ValuationMode } from 'containers/Dashboard/AllocationProposals/toolbar';
import {
  AssetsAndAssetClasses,
  AssetsAndAssetClassesSwitcher,
} from 'containers/Dashboard/AllocationProposals/components/AssetsAndAssetClasses';
import { InvestmentProposal } from 'containers/Dashboard/AllocationProposals/domain/InvestmentProposal';

const toBreakdown = (
  allocationProposal: AllocationProposalTypes.CategorizedAllocationProposal,
) => {
  const {
    blended: {
      original: {
        wholistic: { allocations: wholisticOriginalAllocations },
      },
    },
  } = allocationProposal;

  const wholisticOriginalAllocationsAsTree = AssetCategoryTree.toTree<
    AllocationTypes.CategoryAllocation,
    AllocationTypes.CategoryAllocation
  >(
    AllocationProposalStrategy.toCategorized(
      AllocationProposalStrategy.create(wholisticOriginalAllocations),
    ),
    (allocation) => allocation,
  );

  const publicAssets = wholisticOriginalAllocationsAsTree.find(
    (allocation) => allocation.slug === 'public-assets',
  )!;
  const [publicEquities, fixedIncome] = publicAssets.children.filter(
    (publicAssetClass) =>
      ['equities', 'fixed-income'].includes(publicAssetClass.slug),
  );
  const liquidAssets = [
    ...publicAssets.children.filter((publicAssetClass) =>
      ['alternatives', 'commodities', 'other-public-assets'].includes(
        publicAssetClass.slug,
      ),
    ),
    ...wholisticOriginalAllocationsAsTree.filter((assetClass) =>
      ['cryptocurrency'].includes(assetClass.slug),
    ),
  ];
  const illiquidAssets = wholisticOriginalAllocationsAsTree.filter(
    (assetClass) =>
      !['public-assets', 'cash', 'cryptocurrency'].includes(assetClass.slug),
  );
  const [cash] = wholisticOriginalAllocationsAsTree.filter(
    (assetClass) => assetClass.slug === 'cash',
  );

  return { publicEquities, fixedIncome, liquidAssets, illiquidAssets, cash };
};

const SectionVisibilityStatus = {
  EXPANDED: 'expanded',
  COLLAPSED: 'collapsed',
} as const;
type SectionVisibilityStatus = ValueOf<typeof SectionVisibilityStatus>;

export const InitialAllocationBreakdown = ({
  allocationProposal,
  valuationMode,
}: {
  allocationProposal: AllocationProposalTypes.CategorizedAllocationProposal;
  valuationMode: ValuationMode;
}) => {
  const {
    blended: {
      original: {
        wholistic: {
          allocations: wholisticOriginalAllocations,
          representationalAssets: wholisticOriginalRepresentationalAssets,
        },
      },
    },
  } = allocationProposal;

  const [sectionVisibilityStatus, setSectionVisibilityStatus] = useState<
    Record<
      | 'publicEquities'
      | 'fixedIncome'
      | 'liquidAssets'
      | 'illiquidAssets'
      | 'cash',
      SectionVisibilityStatus
    >
  >({
    publicEquities: SectionVisibilityStatus.COLLAPSED,
    fixedIncome: SectionVisibilityStatus.COLLAPSED,
    liquidAssets: SectionVisibilityStatus.COLLAPSED,
    cash: SectionVisibilityStatus.COLLAPSED,
    illiquidAssets: SectionVisibilityStatus.COLLAPSED,
  });

  const toggleSectionVisibilityStatus = (section: string) => {
    setSectionVisibilityStatus({
      ...sectionVisibilityStatus,
      [section]:
        sectionVisibilityStatus[section] === SectionVisibilityStatus.EXPANDED
          ? SectionVisibilityStatus.COLLAPSED
          : sectionVisibilityStatus[section] ===
            SectionVisibilityStatus.COLLAPSED
          ? SectionVisibilityStatus.EXPANDED
          : SectionVisibilityStatus.EXPANDED,
    });
  };

  const { publicEquities, fixedIncome, liquidAssets, illiquidAssets, cash } =
    toBreakdown(allocationProposal);

  return (
    <Col css={{ gap: '$24' }}>
      <Row css={{ ai: 'center' }}>
        <Text size="15" weight="medium">
          Initial allocation
        </Text>
      </Row>

      <Row css={{ minHeight: '460px', gap: '$48' }}>
        <Row css={{ w: 'calc(100% - 280px)', gap: '$48' }}>
          <Col
            css={{
              w: '60%',
              h: 'fit-content',
              p: '$2',
              gap: '$24',
              overflow: 'hidden',
            }}
          >
            <Col css={{ gap: '$12' }}>
              <Row css={{ ai: 'start', gap: '$4' }}>
                <Button
                  css={{
                    all: 'unset',
                    display: 'grid',
                    placeItems: 'center',
                    '&:hover, &:focus': {
                      boxShadow: '$focus',
                      color: '$gray12',
                    },
                  }}
                  onClick={() => {
                    toggleSectionVisibilityStatus('publicEquities');
                  }}
                >
                  <CollapseToggleIcon
                    size={16}
                    css={{
                      transform:
                        sectionVisibilityStatus.publicEquities ===
                        SectionVisibilityStatus.EXPANDED
                          ? 'rotate(90deg)'
                          : '',
                      transition: 'transform 0.1s ease',
                    }}
                  />
                </Button>

                <Col css={{ w: '100%', gap: '$4' }}>
                  <Row css={{ jc: 'space-between', ai: 'center' }}>
                    <Text size="13" weight="medium">
                      Public equity
                    </Text>

                    <Text size="13">
                      {valuationMode === ValuationMode.WEIGHT && (
                        <Weight weight={Allocation.weight(publicEquities)} />
                      )}
                      {valuationMode === ValuationMode.DOLLAR && (
                        <Value value={Allocation.value(publicEquities)} />
                      )}
                    </Text>
                  </Row>

                  <Box css={{ h: '1px', w: '100%', bg: '$gray4' }} />
                </Col>
              </Row>

              {sectionVisibilityStatus.publicEquities ===
                SectionVisibilityStatus.EXPANDED && (
                <>
                  {publicEquities.children.flatMap((publicEquity) =>
                    publicEquity.children.map((publicEquity) => (
                      <Row
                        key={publicEquity.slug}
                        css={{
                          jc: 'space-between',
                          ai: 'center',
                          gap: '$12',
                          overflow: 'hidden',
                        }}
                      >
                        <Row
                          css={{ ai: 'center', gap: '$8', overflow: 'hidden' }}
                        >
                          <Box
                            css={{
                              h: '$16',
                              w: '$16',
                              br: '$2',
                              bg: ASSET_CATEGORY_SLUG_TO_COLOR[
                                publicEquity.slug
                              ],
                            }}
                          />

                          <Text
                            size="13"
                            color="gray11"
                            css={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                            }}
                          >
                            {publicEquity.name}
                          </Text>
                        </Row>

                        <Text size="13" color="gray11">
                          {valuationMode === ValuationMode.WEIGHT && (
                            <Weight weight={Allocation.weight(publicEquity)} />
                          )}
                          {valuationMode === ValuationMode.DOLLAR && (
                            <Value value={Allocation.value(publicEquity)} />
                          )}
                        </Text>
                      </Row>
                    )),
                  )}
                </>
              )}
            </Col>

            <Col css={{ gap: '$12' }}>
              <Row css={{ ai: 'start', gap: '$4' }}>
                <Button
                  css={{
                    all: 'unset',
                    display: 'grid',
                    placeItems: 'center',
                    '&:hover, &:focus': {
                      boxShadow: '$focus',
                      color: '$gray12',
                    },
                  }}
                  onClick={() => {
                    toggleSectionVisibilityStatus('fixedIncome');
                  }}
                >
                  <CollapseToggleIcon
                    size={16}
                    css={{
                      transform:
                        sectionVisibilityStatus.fixedIncome ===
                        SectionVisibilityStatus.EXPANDED
                          ? 'rotate(90deg)'
                          : '',
                      transition: 'transform 0.1s ease',
                    }}
                  />
                </Button>

                <Col css={{ w: '100%', gap: '$4' }}>
                  <Row css={{ jc: 'space-between', ai: 'center' }}>
                    <Text size="13" weight="medium">
                      Fixed income
                    </Text>

                    <Text size="13">
                      {valuationMode === ValuationMode.WEIGHT && (
                        <Weight weight={Allocation.weight(fixedIncome)} />
                      )}
                      {valuationMode === ValuationMode.DOLLAR && (
                        <Value value={Allocation.value(fixedIncome)} />
                      )}
                    </Text>
                  </Row>

                  <Box css={{ h: '1px', w: '100%', bg: '$gray4' }} />
                </Col>
              </Row>

              {sectionVisibilityStatus.fixedIncome ===
                SectionVisibilityStatus.EXPANDED && (
                <>
                  {fixedIncome.children.map((fixedIncome) => (
                    <Row
                      key={fixedIncome.slug}
                      css={{
                        jc: 'space-between',
                        ai: 'center',
                        gap: '$12',
                        overflow: 'hidden',
                      }}
                    >
                      <Row
                        css={{ ai: 'center', gap: '$8', overflow: 'hidden' }}
                      >
                        <Box
                          css={{
                            h: '$16',
                            w: '$16',
                            br: '$2',
                            bg: ASSET_CATEGORY_SLUG_TO_COLOR[fixedIncome.slug],
                          }}
                        />

                        <Text
                          size="13"
                          color="gray11"
                          css={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                          }}
                        >
                          {fixedIncome.name}
                        </Text>
                      </Row>

                      <Text size="13" color="gray11">
                        {valuationMode === ValuationMode.WEIGHT && (
                          <Weight weight={Allocation.weight(fixedIncome)} />
                        )}
                        {valuationMode === ValuationMode.DOLLAR && (
                          <Value value={Allocation.value(fixedIncome)} />
                        )}
                      </Text>
                    </Row>
                  ))}
                </>
              )}
            </Col>

            <Col css={{ gap: '$12' }}>
              <Row css={{ ai: 'start', gap: '$4' }}>
                <Button
                  css={{
                    all: 'unset',
                    display: 'grid',
                    placeItems: 'center',
                    '&:hover, &:focus': {
                      boxShadow: '$focus',
                      color: '$gray12',
                    },
                  }}
                  onClick={() => {
                    toggleSectionVisibilityStatus('liquidAssets');
                  }}
                >
                  <CollapseToggleIcon
                    size={16}
                    css={{
                      transform:
                        sectionVisibilityStatus.liquidAssets ===
                        SectionVisibilityStatus.EXPANDED
                          ? 'rotate(90deg)'
                          : '',
                      transition: 'transform 0.1s ease',
                    }}
                  />
                </Button>

                <Col css={{ w: '100%', gap: '$4' }}>
                  <Row css={{ jc: 'space-between', ai: 'center' }}>
                    <Text size="13" weight="medium">
                      Other liquid assets
                    </Text>

                    <Text size="13">
                      {valuationMode === ValuationMode.WEIGHT && (
                        <Weight
                          weight={liquidAssets.reduce(
                            (weight, liquidAsset) =>
                              NumberUtils.add(
                                weight,
                                Allocation.weight(liquidAsset),
                              ),
                            0,
                          )}
                        />
                      )}
                      {valuationMode === ValuationMode.DOLLAR && (
                        <Value
                          value={liquidAssets.reduce(
                            (value, liquidAsset) =>
                              NumberUtils.add(
                                value,
                                Allocation.value(liquidAsset),
                              ),
                            0,
                          )}
                        />
                      )}
                    </Text>
                  </Row>

                  <Box css={{ h: '1px', w: '100%', bg: '$gray4' }} />
                </Col>
              </Row>

              {sectionVisibilityStatus.liquidAssets ===
                SectionVisibilityStatus.EXPANDED && (
                <>
                  {liquidAssets.map((liquidAsset) => (
                    <Row
                      key={liquidAsset.slug}
                      css={{
                        jc: 'space-between',
                        ai: 'center',
                        gap: '$12',
                        overflow: 'hidden',
                      }}
                    >
                      <Row
                        css={{ ai: 'center', gap: '$8', overflow: 'hidden' }}
                      >
                        <Box
                          css={{
                            h: '$16',
                            w: '$16',
                            br: '$2',
                            bg: ASSET_CATEGORY_SLUG_TO_COLOR[liquidAsset.slug],
                          }}
                        />

                        <Text
                          size="13"
                          color="gray11"
                          css={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                          }}
                        >
                          {liquidAsset.name}
                        </Text>
                      </Row>

                      <Text size="13" color="gray11">
                        {valuationMode === ValuationMode.WEIGHT && (
                          <Weight weight={Allocation.weight(liquidAsset)} />
                        )}
                        {valuationMode === ValuationMode.DOLLAR && (
                          <Value value={Allocation.value(liquidAsset)} />
                        )}
                      </Text>
                    </Row>
                  ))}
                </>
              )}
            </Col>

            <Col css={{ gap: '$12' }}>
              <Row css={{ ai: 'start', gap: '$4' }}>
                <Button
                  css={{
                    all: 'unset',
                    display: 'grid',
                    placeItems: 'center',
                    '&:hover, &:focus': {
                      boxShadow: '$focus',
                      color: '$gray12',
                    },
                  }}
                  onClick={() => {
                    toggleSectionVisibilityStatus('cash');
                  }}
                >
                  <CollapseToggleIcon
                    size={16}
                    css={{
                      transform:
                        sectionVisibilityStatus.cash ===
                        SectionVisibilityStatus.EXPANDED
                          ? 'rotate(90deg)'
                          : '',
                      transition: 'transform 0.1s ease',
                    }}
                  />
                </Button>

                <Col css={{ w: '100%', gap: '$4' }}>
                  <Row css={{ jc: 'space-between', ai: 'center' }}>
                    <Text size="13" weight="medium">
                      Cash
                    </Text>

                    <Text size="13">
                      {valuationMode === ValuationMode.WEIGHT && (
                        <Weight weight={Allocation.weight(cash)} />
                      )}
                      {valuationMode === ValuationMode.DOLLAR && (
                        <Value value={Allocation.value(cash)} />
                      )}
                    </Text>
                  </Row>

                  <Box css={{ h: '1px', w: '100%', bg: '$gray4' }} />
                </Col>
              </Row>

              {sectionVisibilityStatus.cash ===
                SectionVisibilityStatus.EXPANDED && (
                <Row
                  key={cash.slug}
                  css={{
                    jc: 'space-between',
                    ai: 'center',
                    gap: '$12',
                    overflow: 'hidden',
                  }}
                >
                  <Row css={{ ai: 'center', gap: '$8', overflow: 'hidden' }}>
                    <Box
                      css={{
                        h: '$16',
                        w: '$16',
                        br: '$2',
                        bg: ASSET_CATEGORY_SLUG_TO_COLOR[cash.slug],
                      }}
                    />

                    <Text
                      size="13"
                      color="gray11"
                      css={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                    >
                      {cash.name}
                    </Text>
                  </Row>

                  <Text size="13" color="gray11">
                    {valuationMode === ValuationMode.WEIGHT && (
                      <Weight weight={Allocation.weight(cash)} />
                    )}
                    {valuationMode === ValuationMode.DOLLAR && (
                      <Value value={Allocation.value(cash)} />
                    )}
                  </Text>
                </Row>
              )}
            </Col>
          </Col>

          <Col
            css={{
              w: '40%',
              h: 'fit-content',
              p: '$2',
              gap: '$24',
              overflow: 'hidden',
            }}
          >
            <Col css={{ gap: '$12' }}>
              <Row css={{ ai: 'start', gap: '$4' }}>
                <Button
                  css={{
                    all: 'unset',
                    display: 'grid',
                    placeItems: 'center',
                    '&:hover, &:focus': {
                      boxShadow: '$focus',
                      color: '$gray12',
                    },
                  }}
                  onClick={() => {
                    toggleSectionVisibilityStatus('illiquidAssets');
                  }}
                >
                  <CollapseToggleIcon
                    size={16}
                    css={{
                      transform:
                        sectionVisibilityStatus.illiquidAssets ===
                        SectionVisibilityStatus.EXPANDED
                          ? 'rotate(90deg)'
                          : '',
                      transition: 'transform 0.1s ease',
                    }}
                  />
                </Button>

                <Col css={{ w: '100%', gap: '$4' }}>
                  <Row css={{ jc: 'space-between', ai: 'center' }}>
                    <Text size="13" weight="medium">
                      Illiquid
                    </Text>

                    <Text size="13">
                      {valuationMode === ValuationMode.WEIGHT && (
                        <Weight
                          weight={illiquidAssets.reduce(
                            (weight, illiquidAsset) =>
                              NumberUtils.add(
                                weight,
                                Allocation.weight(illiquidAsset),
                              ),
                            0,
                          )}
                        />
                      )}
                      {valuationMode === ValuationMode.DOLLAR && (
                        <Value
                          value={illiquidAssets.reduce(
                            (value, illiquidAsset) =>
                              NumberUtils.add(
                                value,
                                Allocation.value(illiquidAsset),
                              ),
                            0,
                          )}
                        />
                      )}
                    </Text>
                  </Row>

                  <Box css={{ h: '1px', w: '100%', bg: '$gray4' }} />
                </Col>
              </Row>

              {sectionVisibilityStatus.illiquidAssets ===
                SectionVisibilityStatus.EXPANDED && (
                <>
                  {illiquidAssets.map((iliquidAsset) => (
                    <Row
                      key={iliquidAsset.slug}
                      css={{
                        jc: 'space-between',
                        ai: 'center',
                        gap: '$12',
                        overflow: 'hidden',
                      }}
                    >
                      <Row
                        css={{ ai: 'center', gap: '$8', overflow: 'hidden' }}
                      >
                        <Box
                          css={{
                            h: '$16',
                            w: '$16',
                            br: '$2',
                            bg: ASSET_CATEGORY_SLUG_TO_COLOR[iliquidAsset.slug],
                          }}
                        />

                        <Text
                          size="13"
                          color="gray11"
                          css={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                          }}
                        >
                          {iliquidAsset.name}
                        </Text>
                      </Row>

                      <Text size="13" color="gray11">
                        {valuationMode === ValuationMode.WEIGHT && (
                          <Weight weight={Allocation.weight(iliquidAsset)} />
                        )}
                        {valuationMode === ValuationMode.DOLLAR && (
                          <Value value={Allocation.value(iliquidAsset)} />
                        )}
                      </Text>
                    </Row>
                  ))}
                </>
              )}
            </Col>
          </Col>
        </Row>

        <Flex
          css={{
            minWidth: '280px',
          }}
        >
          <AssetsAndAssetClasses
            assets={
              AllocationProposal.isStrategic(allocationProposal) ? (
                <AssetsBarChart
                  assets={wholisticOriginalRepresentationalAssets}
                />
              ) : null
            }
            assetClasses={
              <AllocationBarChart
                allocations={wholisticOriginalAllocations}
                tooltip={(allocation) =>
                  AllocationProposal.isTarget(allocationProposal) ? (
                    <WeightOnlyAllocationBarChartTooltip
                      allocation={allocation}
                    />
                  ) : AllocationProposal.isTarget(allocationProposal) ? (
                    <WeightAndValueAllocationBarChartTooltip
                      allocation={allocation}
                    />
                  ) : null
                }
              />
            }
            footer={
              AllocationProposal.isStrategic(allocationProposal) &&
              InvestmentProposal.hasAssetInformation(allocationProposal)
                ? ({ setActiveTab }) => (
                    <Flex css={{ jc: 'end', ai: 'center' }}>
                      <AssetsAndAssetClassesSwitcher
                        setActiveTab={setActiveTab}
                      />
                    </Flex>
                  )
                : null
            }
          />
        </Flex>
      </Row>
    </Col>
  );
};
