import {
  ArrowRightIcon,
  Box,
  Grid,
  Row,
  Text,
} from '@compoundfinance/design-system';
import { ILinkPreview } from '../../../../domain/Employees/types';
import { useContext } from 'react';
import ModuleContext from 'containers/Dashboard/Advisor/DocumentsAdmin/components/DocumentViewer/ModuleContext';

const LinkPreview = (props: ILinkPreview) => {
  const { title, image, url } = props;

  const { isHover } = useContext(ModuleContext);

  const domain = new URL(url).hostname;

  return (
    <Grid
      as="a"
      href={url}
      target="_blank"
      rel="noreferrer noopener"
      css={{
        br: '$8',
        bg: isHover ? '$indigo3' : '$gray2',
        gap: '$16',
        p: '$16',
        flexDirection: 'column',
        textDecoration: 'none',
      }}
    >
      <Box>
        <Row css={{ gap: '$4', jc: 'space-between' }}>
          <Box>
            <Text weight="bold" size="13" as="div">
              {title}
            </Text>
            <Text color="gray10" size="12" as="div">
              {domain}
            </Text>
          </Box>
          <ArrowRightIcon size={16} />
        </Row>
      </Box>
      {image && (
        <Box
          as="img"
          src={image}
          alt=""
          css={{
            maxWidth: '100%',
            objectFit: 'cover',
            objectPosition: 'bottom',
            aspectRatio: 1.78,
            br: '$5',
          }}
        />
      )}
    </Grid>
  );
};

export default LinkPreview;
