import { DEFAULT_ADDRESS_STATE } from 'containers/App/util/constants';
import { OrganizerIntakeStatus } from 'containers/Dashboard/PartnerPortal/types';
import { WizardStep } from 'hooks/useWizard';
import { STATE_SELECT_OPTIONS } from 'utils/constants/taxProfile/options';

import {
  UserRelationshipTypes,
  LegalPerson,
  Spouse,
  FamilyAndFriends,
  EstateProfile,
  CitizenTypes,
  PersonalInformation,
  EstateRelationshipTypes,
  EstateDetails,
  PriorSpouse,
} from './types';

export enum EstateWizardIntakeSteps {
  PersonalInformation = 'personalInformation',
  FamilyAndFriends = 'familyAndFriends',
  EstateDetails = 'estateDetails',
  Finances = 'finances',
  Submit = 'submit',
}

enum EstateWizardIntakeTitles {
  PersonalInformation = 'Personal Info',
  FamilyAndFriends = 'Family & Friends',
  EstateDetails = 'Estate Details',
  Finances = 'Finances',
  Submit = 'Submit',
}

export const INTAKE_STEP_ARRAY = [
  EstateWizardIntakeSteps.PersonalInformation,
  EstateWizardIntakeSteps.FamilyAndFriends,
  EstateWizardIntakeSteps.EstateDetails,
  EstateWizardIntakeSteps.Finances,
  EstateWizardIntakeSteps.Submit,
];

export const ESTATE_CITIZEN_TO_STR = {
  [CitizenTypes.Citizen]: 'US citizen',
  [CitizenTypes.DualCitizen]: 'Dual citizen',
  [CitizenTypes.PermanentResident]: 'Permanent resident / Green Card holder',
  [CitizenTypes.NonPermanentResident]: 'Non-permanent resident',
  [CitizenTypes.Other]: 'Other citizenship / permanent residence',
};

export const EstateRelationsipStrings = {
  [EstateRelationshipTypes.Single]: 'Single',
  [EstateRelationshipTypes.Married]: 'Married',
  [EstateRelationshipTypes.TwoYears]: 'Getting married within two years',
  [EstateRelationshipTypes.Divorced]: 'Divorced',
  [EstateRelationshipTypes.Widowed]: 'Widowed',
};

export const getIntakeWizardSteps = (currStep: number): WizardStep[] => {
  return Object.keys(EstateWizardIntakeSteps).map((key, index) => ({
    isVisited: (currStep === 0 && index === 0) || currStep >= index,
    isCompleted: currStep > index,
    stepIndex: index,
    stepKey: EstateWizardIntakeSteps[key],
    stepTitle: EstateWizardIntakeTitles[key],
  }));
};

export const ESTATE_CITIZEN_DROPDOWN_OPTIONS = Object.values(CitizenTypes).map(
  (citizenType) => ({
    label: ESTATE_CITIZEN_TO_STR[citizenType],
    value: citizenType,
  }),
);

export const US_AND_OUTSIDE_OPTIONS = [
  { label: 'Outside the US', value: 'outsideUS' },
  ...STATE_SELECT_OPTIONS,
];

export const ESTATE_RELATIONSHIP_DROPDOWN_OPTIONS = [
  {
    label: 'Single',
    value: EstateRelationshipTypes.Single,
  },
  {
    label: 'Married',
    value: EstateRelationshipTypes.Married,
  },
  {
    label: 'Getting married within two years',
    value: EstateRelationshipTypes.TwoYears,
  },
  {
    label: 'Divorced',
    value: EstateRelationshipTypes.Divorced,
  },
  {
    label: 'Widowed',
    value: EstateRelationshipTypes.Widowed,
  },
];

const defaultLegalPerson = {
  legalFirstName: '',
  legalMiddleName: '',
  legalLastName: '',
  birthday: null,
  residentState: null,
  citizenship: null,
  relationshipStatus: null,
  deceased: null,
  phoneNumber: null,
  residency: null,
  address: { ...DEFAULT_ADDRESS_STATE },
  employmentStatus: null,
};

const defaultPersonalInformation: PersonalInformation = {
  ...defaultLegalPerson,
  otherResidence: '',
  otherCitizenship: '',
  expectedIncome: null,
  hasOtherResidence: null,
  hasOtherCitizenship: null,
  userRelationship: UserRelationshipTypes.Self,
};

export const defaultSpouseData: Spouse = {
  ...defaultLegalPerson,
  userRelationship: UserRelationshipTypes.Spouse,
  relationshipStatus: null,
  preOrPostNuptialAgreement: null,
};

export const defaultFormerSpouse: PriorSpouse = {
  ...defaultLegalPerson,
  userRelationship: UserRelationshipTypes.FormerSpouse,
  continuingObligation: null,
};

export const defaultParentData: LegalPerson = {
  ...defaultLegalPerson,
  userRelationship: UserRelationshipTypes.Parent,
  userId: '',
};

export const defaultChildData: LegalPerson = {
  ...defaultLegalPerson,
  userRelationship: UserRelationshipTypes.Child,
  userId: '',
};

export const defaultTrustedParty: LegalPerson = {
  ...defaultLegalPerson,
  userRelationship: UserRelationshipTypes.TrustedContact,
  userId: '',
};

const defaultFamilyAndFriends: FamilyAndFriends = {
  spouse: {} as Spouse,
  priorSpouses: [],
  parents: [{ ...defaultParentData }, { ...defaultParentData }],
  children: [],
  trustedParties: [],
  hasChildren: null,
  hasTrustedParties: null,
  hasPriorSpouses: null,
};

const defaultEstateDetails: EstateDetails = {
  hasWill: null,
  hasTrust: null,
  hasLifeInsurance: null,
  trustInfo: '',
  inheritanceInfo: '',
  charitableGivingInfo: '',
  lifeInsuranceInfo: '',
};

export const estateData: EstateProfile = {
  userId: '',
  lastWizardStep: 0,
  personalInformation: {
    ...defaultPersonalInformation,
  },
  familyAndFriends: {
    ...defaultFamilyAndFriends,
  },
  estateDetails: {
    ...defaultEstateDetails,
  },
  finances: {},
  status: OrganizerIntakeStatus.New,
  completedAt: null,
};

export const EstateIntakeFormWidths = {
  [EstateWizardIntakeSteps.PersonalInformation]: 58,
  [EstateWizardIntakeSteps.FamilyAndFriends]: 66,
  [EstateWizardIntakeSteps.EstateDetails]: 66,
  [EstateWizardIntakeSteps.Finances]: 90,
  [EstateWizardIntakeSteps.Submit]: 66,
};

export const REQUIRED_FIELD = 'Required field';
