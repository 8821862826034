import moment from 'moment';
import { RangeDatePicker } from '@compoundfinance/design-system';
import {
  Button,
  CalendarIcon,
  Popover,
  Box,
  Row,
} from '@compoundfinance/design-system';
import { RangeOption } from 'hooks/useChartSettings';
import { useState } from 'react';
import { NetWorthDateInterval } from 'shared/chart/constants';
import {
  CSS,
  styled,
} from '@compoundfinance/design-system/dist/stitches.config';

function RangeDateWrap(props: {
  onUpdateCustomRange: (startDate: Date, endDate: Date) => void;
  customFilterDateRange: [Date, Date] | null | undefined;
  earliestAssetAccountCreationDate: Date;
  setIsOpen: (v: boolean) => void;
}) {
  const {
    onUpdateCustomRange,
    customFilterDateRange,
    earliestAssetAccountCreationDate,
    setIsOpen,
  } = props;
  const [customStartDate, customEndDate] = customFilterDateRange || [];
  const [startDate, setStartDate] = useState<Date | undefined>(customStartDate);
  const [endDate, setEndDate] = useState<Date | undefined>(customEndDate);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Box css={{ px: '$16', pb: '$16', mt: '-$2' }}>
      <RangeDatePicker
        lowerBoundDate={startDate}
        upperBoundDate={endDate}
        onChange={({ lowerBound, upperBound }) => {
          if (lowerBound) {
            setStartDate(lowerBound.date.utc);
          }
          if (upperBound) {
            setEndDate(moment(upperBound.date.utc).endOf('day').toDate());
          }
        }}
        lowerMinDate={moment(earliestAssetAccountCreationDate)
          .startOf('day')
          .add(1, 'days')
          .toDate()}
        lowerMaxDate={moment(endDate || new Date())
          .startOf('day')
          .toDate()}
        upperMinDate={
          startDate
            ? moment(startDate).startOf('day').add(2, 'days').toDate()
            : undefined
        }
        upperMaxDate={new Date()}
      />
      <Button
        onClick={() => {
          setIsLoading(true);
          onUpdateCustomRange(startDate!, endDate!);
          setIsLoading(false);
          setIsOpen(false);
        }}
        disabled={!startDate || !endDate}
        css={{ w: '100%' }}
        loading={isLoading}
      >
        Select
      </Button>
    </Box>
  );
}

function CustomTimeButton(props: {
  onUpdateCustomRange: (startDate: Date, endDate: Date) => void;
  isSelected: boolean;
  customFilterDateRange: [Date, Date] | null | undefined;
  earliestAssetAccountCreationDate: Date;
}) {
  const {
    onUpdateCustomRange,
    isSelected,
    customFilterDateRange,
    earliestAssetAccountCreationDate,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover open={isOpen} onOpenChange={(v) => setIsOpen(v)}>
      <Popover.Trigger asChild>
        <CoreTimeButton
          selected={isSelected}
          css={{
            ai: 'center',
            '&:focus, &[data-state=open]': {
              backgroundColor: '$gray3',
            },
          }}
        >
          <CalendarIcon size={18} />
        </CoreTimeButton>
      </Popover.Trigger>
      <Popover.Content
        side="top"
        css={{
          w: 220,
          boxShadow: '$small',
          borderRadius: '$8',
        }}
        theme="light"
      >
        <RangeDateWrap
          onUpdateCustomRange={onUpdateCustomRange}
          customFilterDateRange={customFilterDateRange}
          earliestAssetAccountCreationDate={earliestAssetAccountCreationDate}
          setIsOpen={setIsOpen}
        />
      </Popover.Content>
    </Popover>
  );
}

export const CoreTimeButton = styled('button', {
  fontSize: '$12',
  py: '$3',
  px: '$5',
  br: '$4',
  transition: '$default',
  variants: {
    selected: {
      true: {
        color: '$gray13',
        bg: '$gray4',
        fontWeight: '$medium',
      },
      false: {
        color: '$gray9',
        '&:hover': {
          bg: '$gray3',
        },
      },
    },
  },
  '&:focus-visible': {
    boxShadow: '$focus',
  },
});

const convertToName = {
  [NetWorthDateInterval.OneDay]: '1D',
  [NetWorthDateInterval.OneWeek]: '1W',
  [NetWorthDateInterval.OneMonth]: '1M',
  [NetWorthDateInterval.ThreeMonths]: '3M',
  [NetWorthDateInterval.YearToDate]: 'YTD',
  [NetWorthDateInterval.OneYear]: '1Y',
  [NetWorthDateInterval.All]: 'All',
};

interface TimeButtonProps {
  interval: NetWorthDateInterval;
}

interface Props {
  timeRangeOptions: RangeOption[];
  timeRange: NetWorthDateInterval;
  customFilterDateRange: [Date, Date] | null | undefined;
  onTimeRangeUpdate: (days: NetWorthDateInterval) => void;
  onUpdateCustomRange: (startDate: Date, endDate: Date) => void;
  earliestAssetAccountCreationDate: Date;
  css?: CSS;
}

function TimeRange(props: Props) {
  const {
    timeRangeOptions,
    timeRange,
    customFilterDateRange,
    onTimeRangeUpdate,
    onUpdateCustomRange,
    earliestAssetAccountCreationDate,
    css,
  } = props;
  const selected = timeRangeOptions.find(({ value }) => value === timeRange);

  function TimeButton(props: TimeButtonProps) {
    const { interval } = props;
    return (
      <CoreTimeButton
        selected={selected?.value === interval}
        onClick={() => onTimeRangeUpdate(interval)}
      >
        {convertToName[interval]}
      </CoreTimeButton>
    );
  }

  // No point of selecting dates if account is brand new
  const showCustomTimeButton =
    moment.utc().subtract(2, 'days').startOf('day').toDate() >=
    earliestAssetAccountCreationDate;

  return (
    <Row css={{ gap: '$12', jc: 'center', ...css }}>
      {timeRangeOptions.map((timeRange) => {
        return <TimeButton key={timeRange.label} interval={timeRange.value} />;
      })}
      {showCustomTimeButton && (
        <CustomTimeButton
          isSelected={timeRange === NetWorthDateInterval.Custom}
          onUpdateCustomRange={onUpdateCustomRange}
          customFilterDateRange={customFilterDateRange}
          earliestAssetAccountCreationDate={earliestAssetAccountCreationDate}
        />
      )}
    </Row>
  );
}

export default TimeRange;
