import React, { useMemo } from 'react';
import { motion } from 'framer-motion';

import { Col, Flex, Row, Text, Tooltip } from '@compoundfinance/design-system';

import { ASSET_CATEGORIES } from '../../constants';
import { Allocation, AllocationTypes } from '../../domain/Allocation';
import {
  AllocationProposalStrategy,
  AllocationProposalStrategyTypes,
} from '../../domain/AllocationProposalStrategy';
import { AssetCategoryTree } from '../../utils/AssetCategoryTree';
import { NumberUtils } from '../../../../../utils/NumberUtils';

import BarChart from './Chartifacts/BarChart';
import UiUtils from 'utils/ui';

const ASSET_ALLOCATION_TARGET_CHART_MINIMUM_BAR_HEIGHT = 20;

const toBar = (allocation: AllocationTypes.Allocation) => ({
  value: Allocation.weight(allocation),
  id: Allocation.slug(allocation),
});
const toBars = (
  allocations: AllocationProposalStrategyTypes.AllocationProposalStrategy['allocations'],
) => allocations.map(toBar);

export const WeightAndValueAllocationBarChartTooltip = ({
  allocation,
}: {
  allocation: AllocationTypes.Allocation;
}) => {
  return (
    <Col css={{ gap: '$2' }}>
      {Allocation.isCategoryAllocation(allocation) && (
        <Row
          css={{
            ai: 'center',
            jc: 'space-between',
            gap: '$16',
            width: 'calc(100% + 16px)',
            ml: '-8px',
            p: '0 8px',
          }}
        >
          <Text size="12" weight="medium">
            {ASSET_CATEGORIES[allocation.slug].name}
          </Text>

          <Text size="12" color="gray10" css={{ textAlign: 'right' }}>
            {UiUtils.nFormatter(Allocation.value(allocation), 2, false, true)} (
            {NumberUtils.sanitize(Allocation.weight(allocation))}
            %)
          </Text>
        </Row>
      )}

      {/* {hasAssets && <Assets assets={assets} />} */}
    </Col>
  );
};

export const WeightOnlyAllocationBarChartTooltip = ({
  allocation,
}: {
  allocation: AllocationTypes.Allocation;
}) => {
  return (
    <Row
      css={{
        ai: 'center',
        jc: 'space-between',
        gap: '$16',
        width: 'calc(100% + 16px)',
        ml: '-8px',
        p: '0 8px',
      }}
    >
      <Text size="12" weight="medium">
        {ASSET_CATEGORIES[allocation.slug].name}
      </Text>

      <Text size="12" color="gray10" css={{ textAlign: 'right' }}>
        {NumberUtils.sanitize(Allocation.weight(allocation))}%
      </Text>
    </Row>
  );
};

const AllocationBarChart = ({
  allocations,
  minimumBarHeight = ASSET_ALLOCATION_TARGET_CHART_MINIMUM_BAR_HEIGHT,
  animate = true,
  tooltip = (allocation: AllocationTypes.Allocation) => (
    <WeightOnlyAllocationBarChartTooltip allocation={allocation} />
  ),
  ...props
}: {
  allocations: AllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy['allocations'];
} & {
  animate?: boolean;
  tooltip?: (allocation: AllocationTypes.Allocation) => React.ReactNode;
} & Omit<React.ComponentProps<typeof BarChart>, 'bars' | 'bar'>) => {
  const shouldDisplayBarName = minimumBarHeight >= 20;
  const leafAllocations = useMemo(
    () =>
      AllocationProposalStrategy.sortByAssetClass(
        AssetCategoryTree.leafs({
          allocations,
        }),
      ),
    [allocations],
  );

  return (
    <Flex
      css={{
        w: '100%',
        zIndex: 1,
        '& > ::-webkit-scrollbar': {
          width: '$5',
          height: '$5',
        },
        '& > ::-webkit-scrollbar-track': {
          br: '6px',
          bg: '$gray8',
        },
        '& > ::-webkit-scrollbar-thumb': {
          br: '6px',
          bg: '$gray4',
        },
      }}
    >
      <BarChart
        {...props}
        minimumBarHeight={minimumBarHeight}
        bars={toBars(leafAllocations)}
        bar={({ size, positioning, bar }) => {
          const { id: slug } = bar;
          const allocation = allocations[slug];
          const { width, height } = size;
          const { y } = positioning;
          return (
            <Tooltip>
              <Tooltip.Trigger asChild>
                <Row
                  {...(animate && {
                    as: motion.div,
                    initial: { opacity: 0 },
                    animate: {
                      opacity: 1,
                      height: height - 4,
                      y: y + 2,
                    },
                    transition: { duration: 0.3 },
                  })}
                  css={{
                    ...(!animate && { height: height - 4, top: y + 2 }),
                    w: width - 4,
                    left: 2,
                    position: 'relative',
                    p: '0 4px',
                    ai: 'end',
                    jc: 'space-between',
                    bg: ASSET_CATEGORIES[allocation.assetCategorySlug].color,
                    borderRadius: '$2',
                    pointerEvents: 'all',
                    '&:hover': {
                      boxShadow: '$focus',
                    },
                  }}
                >
                  {shouldDisplayBarName && (
                    <>
                      <Text
                        size="10"
                        color="gray1"
                        css={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'pre',
                          pr: '$8',
                        }}
                      >
                        {allocation.name}
                      </Text>

                      <Text size="10" color="gray1">
                        {NumberUtils.sanitize(Allocation.weight(allocation))}%
                      </Text>
                    </>
                  )}
                </Row>
              </Tooltip.Trigger>
              <Tooltip.Content
                css={{ bg: '$gray0', boxShadow: '$small' }}
                side="left"
                hideArrow
              >
                {tooltip(allocation)}
              </Tooltip.Content>
            </Tooltip>
          );
        }}
      />
    </Flex>
  );
};

export default React.memo(AllocationBarChart);
