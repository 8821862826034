import React from 'react';
import {
  ChevronRight,
  Col,
  Flex,
  Row,
  Text,
} from '@compoundfinance/design-system';

import { AllocationTypes } from '../domain/Allocation';
import {
  AllocationProposal,
  AllocationProposalTypes,
} from '../domain/AllocationProposal';
import { InvestmentProposal } from '../domain/InvestmentProposal';
import { NumberUtils } from '../../../../utils/NumberUtils';

import AllocationBarChartWithMetadata from './Charts/AllocationBarChartWithMetadata';
import AssetsBarChart from './Charts/AssetsBarChart';
import {
  WeightAndValueAllocationBarChartTooltip,
  WeightOnlyAllocationBarChartTooltip,
} from './Charts/AllocationBarChart';
import {
  AssetsAndAssetClasses,
  AssetsAndAssetClassesSwitcher,
  AssetsAndAssetClassesType,
  getAllocationsByType,
} from './AssetsAndAssetClasses';

const AllocationProposalChanges = ({
  allocationProposal,
  children = ({ allocationProposal }) => (
    <AllocationProposalChangesFigures allocationProposal={allocationProposal} />
  ),
}: {
  allocationProposal: AllocationProposalTypes.CategorizedAllocationProposal;
  children?:
    | React.ReactNode
    | (({
        allocationProposal,
      }: {
        allocationProposal: Pick<
          AllocationProposalTypes.CategorizedAllocationProposal,
          'blended'
        >;
      }) => React.ReactNode);
}) => (
  <Col css={{ w: '100%', h: '100%', gap: '$8' }}>
    <AssetsAndAssetClasses
      assetClasses={
        <AllocationProposalChange
          allocationProposal={allocationProposal}
          type={AssetsAndAssetClassesType.ASSET_CLASSES}
        />
      }
      assets={
        <AllocationProposalChange
          allocationProposal={allocationProposal}
          type={AssetsAndAssetClassesType.REPRESENTATIONAL_ASSETS}
        />
      }
      footer={({ setActiveTab }) => (
        <Col css={{ gap: '$8' }}>
          {typeof children === 'function'
            ? children({ allocationProposal })
            : children}

          {AllocationProposal.isStrategic(allocationProposal) &&
            InvestmentProposal.hasAssetInformation(allocationProposal) && (
              <Flex css={{ jc: 'end', ai: 'center' }}>
                <AssetsAndAssetClassesSwitcher setActiveTab={setActiveTab} />
              </Flex>
            )}
        </Col>
      )}
    />
  </Col>
);

const AllocationProposalChange = ({
  allocationProposal,
  type,
}: {
  allocationProposal: Pick<
    AllocationProposalTypes.CategorizedAllocationProposal,
    'blended' | 'type'
  >;
  type: AssetsAndAssetClassesType;
}) => {
  const {
    blended: {
      original: { changes: blendedOriginalChanges },
      target: { changes: blendedTargetChanges },
    },
  } = allocationProposal;

  const originalAllocationChanges = getAllocationsByType(
    blendedOriginalChanges,
    type,
  );
  const targetAllocationChanges = getAllocationsByType(
    blendedTargetChanges,
    type,
  );

  return (
    <Row css={{ flex: '1 0 auto', jc: 'center', ai: 'center', gap: '$8' }}>
      <Flex css={{ w: '120px', flex: '0 0 auto', jc: 'end' }}>
        <Text size="12" color="gray9" css={{ textAlign: 'end' }}>
          Allocations
        </Text>
      </Flex>

      <Flex
        css={{
          flex: '0 1 auto',
          h: '100%',
          w: '100%',
          minHeight: '150px',
          minWidth: 0,
          alignSelf: 'stretch',
        }}
      >
        <AllocationBarChartWithMetadata
          blendedAllocationProposal={blendedOriginalChanges}
          tooltip={(allocation) =>
            AllocationProposal.isTarget(allocationProposal) ? (
              <WeightOnlyAllocationBarChartTooltip allocation={allocation} />
            ) : AllocationProposal.isStrategic(allocationProposal) ? (
              <WeightAndValueAllocationBarChartTooltip
                allocation={allocation}
              />
            ) : null
          }
        >
          {type === AssetsAndAssetClassesType.ASSET_CLASSES && (
            <AllocationBarChartWithMetadata.Chart
              allocations={originalAllocationChanges}
            />
          )}
          {type === AssetsAndAssetClassesType.REPRESENTATIONAL_ASSETS && (
            <AssetsBarChart
              assets={
                originalAllocationChanges as Record<
                  string,
                  AllocationTypes.ClientAllocation
                >
              }
            />
          )}
        </AllocationBarChartWithMetadata>
      </Flex>

      <Flex css={{ flex: '0 0 auto' }}>
        <ChevronRight size={16} />
      </Flex>

      <Flex
        css={{
          flex: '0 1 auto',
          minHeight: '150px',
          w: '100%',
          minWidth: 0,
          alignSelf: 'stretch',
        }}
      >
        <AllocationBarChartWithMetadata
          blendedAllocationProposal={blendedTargetChanges}
        >
          {type === AssetsAndAssetClassesType.ASSET_CLASSES && (
            <AllocationBarChartWithMetadata.Chart
              allocations={targetAllocationChanges}
            />
          )}
          {type === AssetsAndAssetClassesType.REPRESENTATIONAL_ASSETS && (
            <AssetsBarChart
              assets={
                targetAllocationChanges as Record<
                  string,
                  AllocationTypes.ClientAllocation
                >
              }
            />
          )}
        </AllocationBarChartWithMetadata>
      </Flex>
    </Row>
  );
};

const AllocationProposalChangesFigures = ({
  allocationProposal,
}: {
  allocationProposal: Pick<
    AllocationProposalTypes.CategorizedAllocationProposal,
    'blended'
  >;
}) => {
  const {
    blended: {
      original: {
        changes: blendedOriginalChanges,
        wholistic: blendedOriginalWholistic,
      },
      target: {
        changes: blendedTargetChanges,
        wholistic: blendedTargetWholistic,
      },
    },
  } = allocationProposal;

  return (
    <>
      <Row css={{ jc: 'center', ai: 'center', gap: '$8' }}>
        <Flex css={{ w: '120px', flex: '0 0 auto', jc: 'end' }}>
          <Text
            size="12"
            color="gray9"
            css={{ textAlign: 'end', whiteSpace: 'pre-line' }}
          >
            Annual Return
          </Text>
        </Flex>

        <Flex
          css={{
            w: '100%',
            p: '$8',
            bg: '$gray2',
            borderRadius: '$4',
          }}
        >
          <Text size="12" color="gray11">
            {NumberUtils.sanitize(blendedOriginalChanges.annualReturn)}%
          </Text>
        </Flex>

        <Flex css={{ flex: '0 0 auto' }}>
          <ChevronRight size={16} />
        </Flex>

        <Flex
          css={{
            w: '100%',
            p: '$8',
            bg: '$gray2',
            borderRadius: '$4',
          }}
        >
          <Text size="12" color="gray11">
            {NumberUtils.sanitize(blendedTargetChanges.annualReturn)}%
          </Text>
        </Flex>
      </Row>

      <Row css={{ jc: 'center', ai: 'center', gap: '$8' }}>
        <Flex css={{ w: '120px', flex: '0 0 auto', jc: 'end' }}>
          <Text size="12" color="gray9" css={{ textAlign: 'end' }}>
            Annual Volatility
          </Text>
        </Flex>

        <Flex
          css={{
            w: '100%',
            p: '$8',
            bg: '$gray2',
            borderRadius: '$4',
          }}
        >
          <Text size="12" color="gray11">
            {NumberUtils.sanitize(blendedOriginalChanges.annualVolatility)}%
          </Text>
        </Flex>

        <Flex css={{ flex: '0 0 auto' }}>
          <ChevronRight size={16} />
        </Flex>

        <Flex
          css={{
            w: '100%',
            p: '$8',
            bg: '$gray2',
            borderRadius: '$4',
          }}
        >
          <Text size="12" color="gray11">
            {NumberUtils.sanitize(blendedTargetChanges.annualVolatility)}%
          </Text>
        </Flex>
      </Row>

      <Flex css={{ h: '1px', w: '100%', bg: '$gray4' }} />

      <Row css={{ jc: 'center', ai: 'center', gap: '$8' }}>
        <Flex css={{ w: '120px', flex: '0 0 auto', jc: 'end' }}>
          <Text size="12" color="gray9" css={{ textAlign: 'end' }}>
            Overall return
          </Text>
        </Flex>

        <Flex
          css={{
            w: '100%',
            p: '$8',
            bg: '$gray2',
            borderRadius: '$4',
          }}
        >
          <Text size="12" color="gray11">
            {NumberUtils.sanitize(blendedOriginalWholistic.annualReturn)}%
          </Text>
        </Flex>

        <Flex css={{ flex: '0 0 auto' }}>
          <ChevronRight size={16} />
        </Flex>

        <Flex
          css={{
            w: '100%',
            p: '$8',
            bg: '$gray2',
            borderRadius: '$4',
          }}
        >
          <Text size="12" color="gray11">
            {NumberUtils.sanitize(blendedTargetWholistic.annualReturn)}%
          </Text>
        </Flex>
      </Row>

      <Row css={{ jc: 'center', ai: 'center', gap: '$8' }}>
        <Flex css={{ w: '120px', flex: '0 0 auto', jc: 'end' }}>
          <Text size="12" color="gray9" css={{ textAlign: 'end' }}>
            Overall volatility
          </Text>
        </Flex>

        <Flex
          css={{
            w: '100%',
            p: '$8',
            bg: '$gray2',
            borderRadius: '$4',
          }}
        >
          <Text size="12" color="gray11">
            {NumberUtils.sanitize(blendedOriginalWholistic.annualReturn)}%
          </Text>
        </Flex>

        <Flex css={{ flex: '0 0 auto' }}>
          <ChevronRight size={16} />
        </Flex>

        <Flex
          css={{
            w: '100%',
            p: '$8',
            bg: '$gray2',
            borderRadius: '$4',
          }}
        >
          <Text size="12" color="gray11">
            {NumberUtils.sanitize(blendedTargetWholistic.annualReturn)}%
          </Text>
        </Flex>
      </Row>

      <Row css={{ jc: 'center', ai: 'center', gap: '$8' }}>
        <Flex css={{ w: '120px', flex: '0 0 auto', jc: 'end' }}>
          <Text size="12" color="gray9" css={{ textAlign: 'end' }}>
            Single stock concentration
          </Text>
        </Flex>

        <Flex
          css={{
            w: '100%',
            p: '$8',
            bg: '$gray2',
            borderRadius: '$4',
          }}
        >
          <Text size="12" color="gray11">
            {NumberUtils.sanitize(blendedOriginalWholistic.singleStockExposure)}
            %
          </Text>
        </Flex>

        <Flex css={{ flex: '0 0 auto' }}>
          <ChevronRight size={16} />
        </Flex>

        <Flex
          css={{
            w: '100%',
            p: '$8',
            bg: '$gray2',
            borderRadius: '$4',
          }}
        >
          <Text size="12" color="gray11">
            {NumberUtils.sanitize(blendedTargetWholistic.singleStockExposure)}%
          </Text>
        </Flex>
      </Row>
    </>
  );
};

export default AllocationProposalChanges;
