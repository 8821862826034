/**
 * Defines the names of resources to which a file can be associated / categories a file can be
 * filtered by
 * These names mostly correspond to the their lookup keys in our app state.
 *
 * The exceptions are `privateEquityAccount` and `taxProfile`, both of which are pluralized,
 * and `plaidAccount`, which is simply `account`
 */
export enum FileEntityTypes {
  RealEstate = 'realEstate',
  Other = 'other',
  PrivateInvestment = 'privateInvestment',
  FundInvestment = 'fundInvestment',
  CompoundFunds = 'compoundFunds',
  LP = 'lpExposure',
  GP = 'gpExposure',
  Loan = 'loan',
  Cash = 'cash',
  PrivateEquityAccount = 'privateEquityAccount',
  OtherLiability = 'otherLiability',
  PublicInvestment = 'publicInvestment',
  CreditCard = 'creditCard',
  Tax = 'taxProfile',
  PlaidAccount = 'plaidAccount',
  Grant = 'grant',
  Certificate = 'certificate',
  OtherFiles = 'otherFiles',
  Crypto = 'cryptocurrency',
  Estate = 'estatePlanning',
  User = 'user',
  CompoundInvestAccount = 'compoundInvestAccount',
}
