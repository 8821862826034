/* TODO: Moved to src/widgets/AssetAllocationWidget/MekkoChart, remove once released  */

import useWindowSize from '@compoundfinance/design-system/dist/hooks/useWindowSize';
import { useLayoutEffect, useRef, useState } from 'react';
import { MekkoSectionDatum } from './types';

/**
 * Because the mekko chart is made up of multiple charts, we need to manually
 * compute the overall width of the chart, as well as each section's starting offset
 * from the left-most x-position of the chart.
 *
 * This hook encapsulates this as well as x offset of the window. Using these offsets, we
 * can calculate when we need the tooltips to flip their x direction, preventing it
 * from displaying off the page
 * @param data
 * @param dataTotal
 */
function useMeasureChart(data: MekkoSectionDatum[], dataTotal: number) {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const widthRef = useRef(0);
  const windowSize = useWindowSize(200);
  const [chartMeasurements, setChartMeasurements] = useState<any>({});

  useLayoutEffect(() => {
    if (!chartRef.current) {
      return;
    }

    const bbox = chartRef.current.getBoundingClientRect();
    widthRef.current = bbox.width;

    let prevWidth = 0;

    const chartWidths = data.reduce(
      (memo, datum) => {
        const sectionWidth =
          Math.round(widthRef.current * (datum.total / dataTotal)) + prevWidth;

        prevWidth = sectionWidth;
        return {
          ...memo,
          [datum.name]: sectionWidth,
        };
      },
      {
        chartXOffset: bbox.x,
        windowWidth: windowSize.width || 0,
      },
    );

    setChartMeasurements(chartWidths);
  }, [data.length, windowSize.width]);

  return { chartMeasurements, chartRef };
}

export default useMeasureChart;
