import { Box, Button, Table } from '@compoundfinance/design-system';
import { Dialog, Text, TextField } from '@compoundfinance/design-system';
import useTypedSelector from 'hooks/typedSelector';
import { useCompoundFetch } from 'hooks/useCompoundFetch';
import { useCallback, useState } from 'react';
import buildRequest from 'utils/api';
import { REQUEST_TYPES } from 'utils/constants/axios';

interface ShareModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose: () => void;
  documentId: string;
}

function ShareModal(props: ShareModalProps) {
  const { isOpen, setIsOpen, onClose, documentId } = props;
  const [formState, setFormState] = useState({ email: '' });
  const { adminUser } = useTypedSelector((state) => ({
    adminUser: state.admin.adminUser,
  }));

  const updateFormState = useCallback((field: string, val: string) => {
    setFormState((state) => ({ ...state, [field]: val }));
  }, []);

  const { data: magicLinks, mutate: mutateMagicLinks } = useCompoundFetch(
    `/api/reports-private/magicLinks/${documentId}`,
    {
      fetcher: async (path: string) => {
        const res = await buildRequest(path, true, {}, REQUEST_TYPES.GET, {});

        if (!res?.data) throw Error('No data returned from fetcher.');

        return res.data.magicLinks.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        );
      },
    },
  );

  const shareDocument = async () => {
    await buildRequest(
      `/api/reports-private`,
      true,
      {
        createdById: adminUser.id,
        documentId,
        email: formState.email,
      },
      REQUEST_TYPES.POST,
      {},
    );
    mutateMagicLinks();
    updateFormState('email', '');
  };

  const revokeLink = async (code: string) => {
    await buildRequest(
      `/api/reports-private/revoke/${code}`,
      true,
      {},
      REQUEST_TYPES.POST,
      {},
    );
    mutateMagicLinks();
  };

  const refreshLink = async (code: string) => {
    await buildRequest(
      `/api/reports-private/refresh/${code}`,
      true,
      {},
      REQUEST_TYPES.POST,
      {},
    );
    mutateMagicLinks();
  };

  return (
    <Dialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onClose={onClose}
      css={{
        px: 0,
        pt: 0,
        border: '1px solid $gray4',
      }}
      zIndex={99999999}
    >
      <Dialog.StickyHeader>
        <Text>Send document to</Text>
      </Dialog.StickyHeader>

      <Box css={{ p: '$24' }}>
        <Box
          css={{
            display: 'flex',
            width: '100%',
            jc: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Text>Email</Text>
          <TextField
            type="text"
            css={{ width: '100%', order: 1 }}
            name="email"
            value={formState.email}
            onChange={(e) => updateFormState('email', e.target.value)}
          />
        </Box>
        <Box css={{ ml: 'auto' }}>
          <Button
            disable={!formState.email || formState.email === ''}
            onClick={shareDocument}
          >
            Send
          </Button>
        </Box>
        <Table>
          <Table.Head>
            <Table.HeadCell>Link</Table.HeadCell>
            <Table.HeadCell>Email</Table.HeadCell>
            <Table.HeadCell>Expired</Table.HeadCell>
            <Table.HeadCell>Expiration Date</Table.HeadCell>
          </Table.Head>
          <Table.Content>
            {magicLinks?.map((link, index) => {
              return (
                <Table.Row key={index} noHover>
                  <Table.Cell>
                    {window.location.host}/reports-private/{link.code}
                  </Table.Cell>
                  <Table.Cell>{link.email}</Table.Cell>
                  <Table.Cell>{link.isExpired ? 'true' : 'false'}</Table.Cell>
                  <Table.Cell>{link.expirationDate}</Table.Cell>
                  <Table.Cell>
                    <Button
                      disabled={link.isExpired}
                      onClick={() => revokeLink(link.code)}
                    >
                      Revoke Link
                    </Button>
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      disabled={!link.isExpired}
                      onClick={() => refreshLink(link.code)}
                    >
                      Refresh Link
                    </Button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Content>
        </Table>
      </Box>
    </Dialog>
  );
}

export default ShareModal;
