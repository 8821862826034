import {
  DocumentModuleGroup,
  DocumentModuleName,
} from 'containers/Dashboard/Document/modules/modules';
import SalesIntroductionIntroduction, {
  IntroductionProps as SalesIntroductionIntroductionProps,
} from 'containers/Dashboard/Document/modules/SalesIntroduction/Introduction/Introduction';
import SalesIntroductionAbout, {
  AboutProps as SalesIntroductionAboutProps,
} from 'containers/Dashboard/Document/modules/SalesIntroduction/About/About';
import SalesIntroductionInitialConsultation, {
  InitialConsultationProps as SalesIntroductionInitialConsultationProps,
} from 'containers/Dashboard/Document/modules/SalesIntroduction/InitialConsultation/InitialConsultation';
import SalesIntroductionVisualizeYourFinances, {
  VisualizeYourFinancesProps as SalesIntroductionVisualizeYourFinancesProps,
} from 'containers/Dashboard/Document/modules/SalesIntroduction/VisualizeYourFinances/VisualizeYourFinances';
import SalesIntroductionServices, {
  ServicesProps as SalesIntroductionServicesProps,
} from 'containers/Dashboard/Document/modules/SalesIntroduction/Services/Services';
import SalesIntroductionAdvisoryLandscape, {
  AdvisoryLandscapeProps as SalesIntroductionAdvisoryLandscapeProps,
} from 'containers/Dashboard/Document/modules/SalesIntroduction/AdvisoryLandscape/AdvisoryLandscape';
import SalesIntroductionMembership, {
  MembershipProps as SalesIntroductionMembershipProps,
} from 'containers/Dashboard/Document/modules/SalesIntroduction/Membership/Membership';
import SalesIntroductionMembershipV2, {
  MembershipV2Props as SalesIntroductionMembershipV2Props,
} from 'containers/Dashboard/Document/modules/SalesIntroduction/MembershipV2/MembershipV2';

const salesIntroductionModules = {
  [`${DocumentModuleGroup.SalesIntroduction}.${DocumentModuleName.SalesIntroduction_Introduction}`]:
    (properties: SalesIntroductionIntroductionProps) => (
      <SalesIntroductionIntroduction {...properties} />
    ),
  [`${DocumentModuleGroup.SalesIntroduction}.${DocumentModuleName.SalesIntroduction_About}`]:
    (properties: SalesIntroductionAboutProps) => (
      <SalesIntroductionAbout {...properties} />
    ),
  [`${DocumentModuleGroup.SalesIntroduction}.${DocumentModuleName.SalesIntroduction_InitialConsultation}`]:
    (properties: SalesIntroductionInitialConsultationProps) => (
      <SalesIntroductionInitialConsultation {...properties} />
    ),
  [`${DocumentModuleGroup.SalesIntroduction}.${DocumentModuleName.SalesIntroduction_VisualizeYourFinances}`]:
    (properties: SalesIntroductionVisualizeYourFinancesProps) => (
      <SalesIntroductionVisualizeYourFinances {...properties} />
    ),
  [`${DocumentModuleGroup.SalesIntroduction}.${DocumentModuleName.SalesIntroduction_Services}`]:
    (properties: SalesIntroductionServicesProps) => (
      <SalesIntroductionServices {...properties} />
    ),
  [`${DocumentModuleGroup.SalesIntroduction}.${DocumentModuleName.SalesIntroduction_AdvisoryLandscape}`]:
    (properties: SalesIntroductionAdvisoryLandscapeProps) => (
      <SalesIntroductionAdvisoryLandscape {...properties} />
    ),
  [`${DocumentModuleGroup.SalesIntroduction}.${DocumentModuleName.SalesIntroduction_Membership}`]:
    (properties: SalesIntroductionMembershipProps) => (
      <SalesIntroductionMembership {...properties} />
    ),
  [`${DocumentModuleGroup.SalesIntroduction}.${DocumentModuleName.SalesIntroduction_MembershipV2}`]:
    (properties: SalesIntroductionMembershipV2Props) => (
      <SalesIntroductionMembershipV2 {...properties} />
    ),
};

export default salesIntroductionModules;
