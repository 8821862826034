import _ from 'lodash';
import moment from 'moment';
import DateUtils from '@compoundfinance/compound-core/dist/date-utils';
import {
  ISO_AWARD_TYPE,
  NSO_AWARD_TYPE,
  RSA_AWARD_TYPE,
  RSU_AWARD_TYPE,
} from '@compoundfinance/compound-core/dist/constants/equity';
import { getFmvFromValuationMethod } from './peAccount/utils';
import {
  EquityEvent,
  Grant,
  PrivateEquityAccount,
} from '@compoundfinance/compound-core/dist/types/equity';

const getPublicValuationAtDate = (
  peAccount: PrivateEquityAccount,
  date: Date,
) => {
  const valuations = peAccount.stockValuationHistory || [];

  const liquidDate = DateUtils.getMomentCompatibleNativeDate(
    peAccount.liquidityEventAt,
  );
  const valuationAtDate = valuations.find((v) => {
    const vDate = DateUtils.getMomentCompatibleNativeDate(v.date);
    return (
      vDate &&
      vDate.getTime() <= date.getTime() &&
      liquidDate &&
      liquidDate.getTime() <= vDate.getTime()
    );
  });

  const stockPrice = valuationAtDate?.price;
  const normalizeValuationAtDate = {
    fmv: stockPrice,
    preferredPrice: stockPrice,
  };

  return stockPrice ? normalizeValuationAtDate : getCurrentValuation(peAccount);
};

const getPrivateValuationAtDate = (
  peAccount: PrivateEquityAccount,
  date: Date,
) => {
  const today = DateUtils.startOfDayNative();
  const curDate = DateUtils.startOfDayNative(date);
  if (today.getTime() === curDate.getTime()) {
    return {
      fmv: getFmvFromValuationMethod(peAccount),
      preferredPrice: peAccount.preferredPrice,
    };
  }

  const valuations = peAccount.valuationHistory || [];
  const valuationAtDate = valuations.find((v) => {
    const vDate = DateUtils.getMomentCompatibleNativeDate(v.date);
    const nativeDate = DateUtils.getMomentCompatibleNativeDate(date);
    return vDate && nativeDate && vDate.getTime() >= nativeDate.getTime();
  });

  return valuationAtDate || getCurrentValuation(peAccount);
};

/**
 * Retrieve the valuation for the private equity account at the specified date, or today
 * @param peAccount
 * @param date
 */
export function getValuationAtDate(
  peAccount: PrivateEquityAccount,
  date: Date,
) {
  const isPublicCompanyOnDate =
    peAccount.liquidityEventAt && new Date(peAccount.liquidityEventAt) <= date;
  const hasStockValuationHistory = peAccount.stockValuationHistory?.length;

  if (isPublicCompanyOnDate && hasStockValuationHistory) {
    return getPublicValuationAtDate(peAccount, date);
  }

  return getPrivateValuationAtDate(peAccount, date);
}

export const getCurrentValuation = (peAccount: PrivateEquityAccount) => ({
  date: peAccount.liquidityEventAt ? 'Latest' : 'Current',
  fmv: getFmvFromValuationMethod(peAccount),
  preferredPrice: peAccount.preferredPrice,
  estimatedPrice: peAccount.estimatedPrice,
  source: peAccount.provider,
  id: '',
  privateEquityAccountId: peAccount.id,
});

export const isOption = (g: Pick<Grant, 'awardType'>) =>
  g.awardType === ISO_AWARD_TYPE || g.awardType === NSO_AWARD_TYPE;
export const isISO = (g: Pick<Grant, 'awardType'>) =>
  g.awardType === ISO_AWARD_TYPE;
export const isNSO = (g: Pick<Grant, 'awardType'>) =>
  g.awardType === NSO_AWARD_TYPE;
export const isRSU = (g: Pick<Grant, 'awardType'>) =>
  g.awardType === RSU_AWARD_TYPE;
export const isRSA = (g: Pick<Grant, 'awardType'>) =>
  g.awardType === RSA_AWARD_TYPE;
export const isRestricted = (g: Pick<Grant, 'awardType'>) =>
  g.awardType === RSU_AWARD_TYPE || g.awardType === RSA_AWARD_TYPE;

// utils/equity/vest.ts
// TODO dupe

// Gets number of shares vested by a given date
export const getSharesVestedByDate = (events: EquityEvent[], date: Date) => {
  let sharesTriggered = 0;
  const vestByDateTimestamp = new Date(date).getTime();

  for (let i = 0; i < events.length; i++) {
    const vestEvent = events[i];
    const vestEventTimestamp = new Date(vestEvent.date).getTime();

    if (vestEventTimestamp <= vestByDateTimestamp) {
      sharesTriggered += vestEvent.amount;
    }
  }

  return sharesTriggered;
};

export function isosConverted(endDate: Date | null) {
  return (
    !_.isNil(endDate) &&
    moment(endDate).isSameOrBefore(moment().subtract(90, 'days'))
  );
}

export function getIsoConversionDate(endDate: Date) {
  return moment(endDate).add(90, 'days').toDate();
}
