import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import groupBy from 'lodash/groupBy';
import {
  AssetFilter,
  AssetSubType,
  AssetType,
  Filterable,
  FilterType,
} from './types';
import AccountViewUtils from 'utils/view/accounts';

const mapping = {
  rawAccounts: AssetType.PlaidAccount,
  accounts: AssetType.PlaidAccount,
  defiAccounts: AssetType.DefiAccount,
  realEstate: AssetType.RealEstate,
  privateInvestment: AssetType.PrivateInvestment,
  rawPrivateInvestment: AssetType.PrivateInvestment,
  privateEquityAccounts: AssetType.PrivateEquityAccount,
  other: AssetType.Other,
  otherLiability: AssetType.OtherLiability,
  lpExposure: AssetType.LP,
  gpExposure: AssetType.GP,
};

/**
 * Generate a lookup from asset id to asset filter.
 */
export function getAssetIdToAssetFilterMapping(assetFilters: AssetFilter[]) {
  return keyBy(assetFilters, getAssetFilterKey);
}

/**
 * Transform a list of AssetFilters to map from asset id to filter type.
 */
export function getAssetIdToFilterType(
  assetFilters: AssetFilter[],
): Record<string, FilterType> {
  const assetIdToAssetFilterMapping =
    getAssetIdToAssetFilterMapping(assetFilters);
  return mapValues(assetIdToAssetFilterMapping, (value) => value.filter!);
}

/**
 * Generate legacy account filters key from a given AssetFilter.
 *
 * This is mainly useful for privateEquityAccounts which can have special
 * suffixes (e.g. -common, -vested, etc...).
 */
export function getAssetFilterKey(assetFilter: AssetFilter) {
  return assetFilter.assetSubType
    ? `${assetFilter.assetId}-${assetFilter.assetSubType}`
    : assetFilter.assetId;
}

/**
 * Extract components from a legacy account filter key.
 *
 * This is useful for generating an AssetFilter.
 */
export function getPartsFromAccountFilterKey(key: string) {
  const uuidPattern =
    '[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}';
  const assetSubTypePattern = '.*';
  const idWithSubTypeRegExp = new RegExp(
    `(?<id>${uuidPattern})-(?<subType>${assetSubTypePattern})`,
  );

  const { groups } = key.match(idWithSubTypeRegExp) || {};

  if (!groups) return [key, null];

  const { id, subType } = groups;
  return [id, subType];
}

/**
 * Get the assetFilter.assetType based on the key an account is stored under
 * in the global state store.
 */
export function getAssetTypeFromAssetKey(key: string): AssetType {
  const value = mapping[key];
  if (!value) {
    console.warn('No mapping for value', key);
  }
  return value;
}

/**
 * Remove AssetSubType from end of id and add company asset sub type instead
 * @param key
 * @returns
 */
export function createCompanyKey(key: string) {
  const res = Object.values(AssetSubType).reduce(
    (acc, assetSubType) => acc.replace(assetSubType, ''),
    key,
  );
  const last = res.slice(-1);
  let id = res;
  if (last === '-') {
    id = res.slice(0, -1);
  }
  return `${id}-${AssetSubType.Company}`;
}

/**
 * Determine whether at least one account in a set of accounts is hidden or excluded.
 *
 * Being hidden is like a stronger form of exclusion. Essentially, it is saying, do
 * not even show this account, whereas exclude only removes it from net worth. But the
 * account is still visible in the UI in a grayed-out state.
 */
export function isExcluded(accounts, mapping: Record<string, FilterType>) {
  if (isEmpty(accounts)) {
    return false;
  }
  return accounts.some((account) => {
    const key = account.key || account.id;
    if (!key) return false;
    const companyKey = createCompanyKey(key);
    return mapping[key] || mapping[companyKey];
  });
}

/**
 * Determine whether at least one account in a set of accounts is hidden.
 */
export function isHidden(accounts, mapping: Record<string, FilterType>) {
  if (isEmpty(accounts)) {
    return false;
  }
  return accounts.some((account) => {
    const key = account.key || account.id;
    if (!key) return false;
    const companyKey = createCompanyKey(key);
    return (
      mapping[key] === FilterType.Hide ||
      mapping[companyKey] === FilterType.Hide
    );
  });
}

export function isAllExcluded(accounts, mapping: Record<string, FilterType>) {
  if (isEmpty(accounts)) {
    return false;
  }
  return accounts.every((account) => {
    const key = account.key || account.id;
    if (!key) return false;
    const companyKey = createCompanyKey(key);
    return mapping[key] || mapping[companyKey];
  });
}

export function isAllHidden(accounts, mapping: Record<string, FilterType>) {
  if (isEmpty(accounts)) {
    return false;
  }
  return accounts.every((account) => {
    const key = account.key || account.id;
    if (!key) return false;
    const companyKey = createCompanyKey(key);
    return (
      mapping[key] === FilterType.Hide ||
      mapping[companyKey] === FilterType.Hide
    );
  });
}

/**
 * Determine whether all accounts in a group are hidden.
 */
function areAllHidden(accounts, mapping: Record<string, FilterType>) {
  if (isEmpty(accounts)) {
    return false;
  }
  return accounts.every((account) => {
    const key = account.key || account.id;
    return mapping[key] === FilterType.Hide;
  });
}

/**
 * Filter out any accounts that match the given filter type.
 *
 * If no filter type is specified, then the presence of any filter is enough
 * to filter.
 */
export function filterAccounts<T extends { key?: string; id?: string } = any>(
  accounts: T[],
  mapping: Record<string, FilterType | boolean>,
  filterType?: FilterType,
): T[] {
  if (isEmpty(accounts)) {
    return [];
  }
  if (isEmpty(mapping)) {
    return accounts;
  }
  return accounts.filter((account) => {
    const key = account.key || account.id;
    if (!key) {
      console.warn('no key for account', account);
      return true;
    }
    return filterType ? mapping[key] !== filterType : !mapping[key];
  });
}

/**
 * Handle a call to add/remove a filter from a set of accounts
 */
export function onFilter(
  accounts: Filterable[],
  mapping: Record<string, FilterType>,
  filterType: FilterType,
  updateCallback,
  toggleMode: boolean = true,
) {
  if (isEmpty(accounts)) {
    return;
  }
  let isFiltering = true;
  if (toggleMode) {
    isFiltering = !(filterType === FilterType.Hide
      ? isHidden(accounts, mapping)
      : isExcluded(accounts, mapping));
  }
  const assetFilters = accounts.map((account) => {
    // account.key is a special value that will not be set in all cases.
    // currently it is only used to track the various ways we aggregate
    // private equity accounts. For example, you might have a key like
    // 265e8d4c-d873-4b29-8744-00f7d68d807b-common or 265e8d4c-d873-4b29-8744-00f7d68d807b-unvested
    // which are both for the same private equity account, but refer to different
    // option types.
    const key = account.key || account.id;
    if (!key) {
      return null;
    }
    const [assetId, assetSubType] = getPartsFromAccountFilterKey(key);
    return {
      name: account.name || account.title || 'Asset',
      assetId: assetId as string,
      assetSubType: assetSubType as AssetSubType,
      assetType: account.assetType,
      filter: filterType,
    };
  });
  updateCallback(compact(assetFilters), isFiltering);
}

export function getVisibleAccountsFromAssetsState(
  state,
  mapping: Record<string, FilterType>,
): Record<string, any[]> {
  const rawToVisible = {
    rawAccounts: 'accounts',
    rawPrivateInvestment: 'privateInvestment',
    rawPrivateEquityAccounts: 'privateEquityAccounts',
  };
  const output = {};
  Object.entries(rawToVisible).forEach(([rawKey, visibleKey]) => {
    const rawAssets = state[rawKey];
    if (rawAssets) {
      // if a private equity account has tranches AND all of those tranches have
      // been hidden, we also need to filter the entire account out.
      if (rawKey === 'rawPrivateEquityAccounts') {
        const sections = AccountViewUtils.getEquityAccountSection(
          rawAssets,
          mapping,
          undefined,
          true,
        );
        const assetIdToTranches = groupBy(
          sections.equitySection.accounts,
          'id',
        );
        rawAssets.forEach((asset) => {
          const tranches = assetIdToTranches[asset.id];

          // Hiding private equity is a bit complex since the view we display
          // to a user is generated on the fly and does not correspond to any
          // value stored directly in the DB.
          //
          // So we use the -company filter to indicate the entire account is
          // hidden. This allows us to hide accounts with only cancelled options
          // or accounts that have no grants (aka no displayable tranches).
          //
          // Alternatively, we will consider the the account hidden if all of
          // its tranches are hidden, in the case where it has tranches which
          // have (or may eventually have) worth.
          if (
            mapping[`${asset.id}-${AssetSubType.Company}`] ===
              FilterType.Hide ||
            areAllHidden(tranches, mapping)
          ) {
            mapping[asset.id] = FilterType.Hide;
          }
        });
      }
      output[visibleKey] = filterAccounts(
        state[rawKey],
        mapping,
        FilterType.Hide,
      );
    }
  });
  return output;
}
