import { Badge, Col, Text, Row } from '@compoundfinance/design-system';
import { CSS } from '@compoundfinance/design-system/dist/stitches.config';

const LAYOUT_MAX_WIDTH = '1800px';
const LAYOUT_CONTENT_WIDTH = '1400px';

type LayoutTitleProps = {
  title?: string;
  badge?: string;
  css?: CSS;
  children?: React.ReactNode;
};

const LayoutTitle = ({
  title,
  badge,
  css,
  children,
  ...props
}: LayoutTitleProps) => {
  return (
    <Row
      css={{
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: '$24',
        ...css,
      }}
      {...props}
    >
      <Text size="32" weight="bold" as="h1">
        {title || children}
      </Text>
      {badge && (
        <Badge size="medium" weight="medium">
          {badge}
        </Badge>
      )}
    </Row>
  );
};

const LayoutContent = ({
  children,
  css,
  ...props
}: {
  css?: CSS;
  children: React.ReactNode;
}) => {
  return (
    <Col
      css={{
        mt: '$12',
        bg: 'white',
        borderRadius: '$6',
        jc: 'center',
        pt: '$32',
        pb: '$40',
        px: '$24',
        ...css,
      }}
      {...props}
    >
      {children}
    </Col>
  );
};

type LayoutProps = {
  title?: string;
  badge?: string;
  children: React.ReactNode;
  wrapContent?: boolean;
  css?: CSS;
  fullWidth?: boolean;
};

const Layout = ({
  title,
  badge,
  children,
  wrapContent = false,
  fullWidth = false,
  ...props
}: LayoutProps) => {
  const _children = wrapContent ? (
    <LayoutContent>{children}</LayoutContent>
  ) : (
    children
  );
  return (
    <Col
      css={{
        width: '100%',
        maxWidth: fullWidth ? LAYOUT_MAX_WIDTH : LAYOUT_CONTENT_WIDTH,
        margin: '0 auto',
        mt: '$20',
        pb: '$48',
        ...props.css,
      }}
    >
      {title && <LayoutTitle title={title} badge={badge} />}
      {_children}
    </Col>
  );
};

export { Layout, LayoutContent, LAYOUT_MAX_WIDTH, LAYOUT_CONTENT_WIDTH };
