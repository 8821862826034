import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import { ValueOf } from 'types';
import { NumberUtils } from 'utils/NumberUtils';

import { AllocationTypes } from '../domain/Allocation';
import {
  AllocationProposalStrategy,
  AllocationProposalStrategyType,
  AllocationProposalStrategyTypes,
} from '../domain/AllocationProposalStrategy';
import {
  ASSET_CATEGORY_NAME_TO_SLUG,
  ASSET_CATEGORY_SLUG_TO_NAME,
  AssetCategorySlug,
} from 'domain/AssetCategory/constants';
import { TaxTreatment } from '../domain/InvestmentTarget';
import {
  AllocationProposalSettingsTypes,
  InvestmentPolicy,
  InvestorQualification,
  Preference,
} from '../domain/AllocationProposalSettings';
import { TargetAllocationProposalStrategyType } from '../domain/Target/TargetAllocationProposalStrategy';

const IPSCategory = {
  ShortTermIGBond: 'short-term-ig-bond',
  ShortTermMunicipalBond: 'short-term-municipal-bond',
  ShortTermTIPS: 'short-term-tips',
  IntermediateIGBond: 'intermediate-ig-bond',
  IntermediateMunicipalBond: 'intermediate-municipal-bond',
  IntermediateTreasury: 'intermediate-treasury',
  IntermediateCorporate: 'intermediate-corporate',
  USHighYieldBond: 'us-high-yield-bond',
  MunicipalHighYieldBond: 'municipal-high-yield-bond',
  EMDebt: 'em-debt',
  USLargeCap: 'us-large-cap',
  USLargeCapGrowth: 'us-large-cap-growth',
  USLargeCapValue: 'us-large-cap-value',
  USSMIDCap: 'us-smid-cap',
  DevelopedExUSEquities: 'developed-ex-us-equities',
  EmergingMarketsEquity: 'emerging-markets-equity',
  PrivateEquity: 'private-equity',
  DirectLending: 'direct-lending',
  USCoreRealEstate: 'us-core-real-estate',
  VentureCapital: 'venture-capital',
} as const;
type IPSCategory = ValueOf<typeof IPSCategory>;

type IPSPortfolio = Record<string, number>;

type IPSPortfolioDetails = {
  id: string;
  investorQualification: InvestorQualification;
  investmentPolicy: InvestmentPolicy;
  taxTreatment: TaxTreatment;
  preference: Preference;
  portfolio: AllocationTypes.CategoryAllocation[];
};

const IPS_ASSET_TO_IPS_CATEGORY = {
  'Short-term IG Bonds': 'short-term-ig-bond',
  'Short-term Muni': 'short-term-municipal-bond',
  'Short-term TIPS': 'short-term-tips',
  'Interm. IG Bonds': 'intermediate-ig-bond',
  'Interm. Muni': 'intermediate-municipal-bond',
  'Interm. Treasury': 'intermediate-treasury',
  'Interm. Corporate': 'intermediate-corporate',
  'US High Yield Bonds': 'us-high-yield-bond',
  'Muni High Yield': 'municipal-high-yield-bond',
  'EM Debt': 'em-debt',
  'US Large Cap': 'us-large-cap',
  'US Large Cap Growth': 'us-large-cap-growth',
  'US Large Cap Value': 'us-large-cap-value',
  'US SMID Cap': 'us-smid-cap',
  'Developed Ex US Equities': 'developed-ex-us-equities',
  'Emerging Markets Equity': 'emerging-markets-equity',
  'Direct Lending': 'private-equity',
  'US Core Real Estate': 'direct-lending',
  'Venture Capital': 'us-core-real-estate',
  'Private Equity': 'venture-capital',
} as const satisfies Record<string, IPSCategory>;

// prettier-ignore
const IPS_CATEGORY_TO_ASSET_CLASS = {
  [IPSCategory.ShortTermIGBond]: ASSET_CATEGORY_NAME_TO_SLUG["US Fixed Income"],
  [IPSCategory.ShortTermTIPS]: ASSET_CATEGORY_NAME_TO_SLUG["US Fixed Income"],
  [IPSCategory.ShortTermMunicipalBond]: ASSET_CATEGORY_NAME_TO_SLUG["US Fixed Income"],
  [IPSCategory.IntermediateIGBond]: ASSET_CATEGORY_NAME_TO_SLUG["US Fixed Income"],
  [IPSCategory.IntermediateMunicipalBond]: ASSET_CATEGORY_NAME_TO_SLUG["US Fixed Income"],
  [IPSCategory.IntermediateTreasury]: ASSET_CATEGORY_NAME_TO_SLUG["US Fixed Income"],
  [IPSCategory.IntermediateCorporate]: ASSET_CATEGORY_NAME_TO_SLUG["US Fixed Income"],
  [IPSCategory.USHighYieldBond]: ASSET_CATEGORY_NAME_TO_SLUG["US Fixed Income"],
  [IPSCategory.MunicipalHighYieldBond]: ASSET_CATEGORY_NAME_TO_SLUG["US Fixed Income"],
  [IPSCategory.EMDebt]: ASSET_CATEGORY_NAME_TO_SLUG["International Equities: Emerging Markets"],
  [IPSCategory.USLargeCap]: ASSET_CATEGORY_NAME_TO_SLUG["US Equities: Large Cap"],
  [IPSCategory.USLargeCapGrowth]: ASSET_CATEGORY_NAME_TO_SLUG["US Equities: Large Cap"],
  [IPSCategory.USLargeCapValue]: ASSET_CATEGORY_NAME_TO_SLUG["US Equities: Large Cap"],
  [IPSCategory.USSMIDCap]: ASSET_CATEGORY_NAME_TO_SLUG["US Equities: Small/Mid Cap"],
  [IPSCategory.DevelopedExUSEquities]: ASSET_CATEGORY_NAME_TO_SLUG["International Equities: Developed (ex-US)"],
  [IPSCategory.EmergingMarketsEquity]: ASSET_CATEGORY_NAME_TO_SLUG["International Equities: Emerging Markets"],
  [IPSCategory.PrivateEquity]: ASSET_CATEGORY_NAME_TO_SLUG["Private Equity Fund"],
  [IPSCategory.VentureCapital]: ASSET_CATEGORY_NAME_TO_SLUG["Venture Capital Fund"],
  [IPSCategory.USCoreRealEstate]: ASSET_CATEGORY_NAME_TO_SLUG["Real Estate Private Fund"],
  [IPSCategory.DirectLending]: ASSET_CATEGORY_NAME_TO_SLUG["Private Credit Fund"],
} as const satisfies Record<IPSCategory, AssetCategorySlug>;

const ipsPortfolioToAssetClasses = (
  ipsPortfolio: IPSPortfolio,
): AllocationTypes.CategoryAllocation[] =>
  AllocationProposalStrategy.create(
    AllocationProposalStrategy.fromCategorized(
      Object.entries(ipsPortfolio).reduce(
        (assetClasses, [category, weight]) => {
          const assetClass =
            IPS_CATEGORY_TO_ASSET_CLASS[IPS_ASSET_TO_IPS_CATEGORY[category]];
          const { weight: assetClassWeight = 0 } =
            assetClasses[assetClass] ?? {};
          return {
            ...assetClasses,
            [assetClass]: {
              name: ASSET_CATEGORY_SLUG_TO_NAME[assetClass],
              slug: assetClass,
              assetCategorySlug: assetClass,
              weight: NumberUtils.add(assetClassWeight, weight),
              value: 0,
              allocationType: 'ASSET_CATEGORY',
              assetSource: 'assetCategory',
              assetId: assetClass,
            },
          };
        },
        {},
      ),
    ),
  ) as AllocationTypes.CategoryAllocation[];

/**
 * Data extracted from [this spreadsheet](https://docs.google.com/spreadsheets/d/18hhuD6XnbACgEkjqw_jgYg3A9BvRfnOabvi5zwIk0Ng/edit#gid=1109884182)
 * The procedure was:
 * - Copy all the columns and rows.
 * - Paste them into a CSV to JSON converter.
 *  - Using the `Array` option.
 * - Formatting:
 *  - Bulk updating the keys and values.
 *  - Converting the percentage strings to numbers using a regex replace: '([\d.]*)%' => $1.
 */
const IDLESS_IPS_PORTFOLIOS = [
  {
    investmentPolicy: InvestmentPolicy.AggresiveGrowth,
    investorQualification: InvestorQualification.QualifiedPurcharser,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 5.0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 0,
      'EM Debt': 0,
      'US Large Cap': 0,
      'US Large Cap Growth': 12.5,
      'US Large Cap Value': 12.5,
      'US SMID Cap': 15.0,
      'Developed Ex US Equities': 10.0,
      'Emerging Markets Equity': 10.0,
      'Direct Lending': 5.0,
      'US Core Real Estate': 5.0,
      'Venture Capital': 10.0,
      'Private Equity': 15.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.AggresiveGrowth,
    investorQualification: InvestorQualification.QualifiedPurcharser,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 7.7,
      'Interm. Muni': 0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 0,
      'EM Debt': 0,
      'US Large Cap': 0,
      'US Large Cap Growth': 19.2,
      'US Large Cap Value': 19.2,
      'US SMID Cap': 23.1,
      'Developed Ex US Equities': 15.4,
      'Emerging Markets Equity': 15.4,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.AggresiveGrowth,
    investorQualification: InvestorQualification.QualifiedPurcharser,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 7.7,
      'Interm. Treasury': 0,
      'Interm. Corporate': 0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 0,
      'EM Debt': 0,
      'US Large Cap': 0,
      'US Large Cap Growth': 19.2,
      'US Large Cap Value': 19.2,
      'US SMID Cap': 23.1,
      'Developed Ex US Equities': 15.4,
      'Emerging Markets Equity': 15.4,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.AggresiveGrowth,
    investorQualification: InvestorQualification.QualifiedPurcharser,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 7.7,
      'Interm. Muni': 0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 0,
      'EM Debt': 0,
      'US Large Cap': 0,
      'US Large Cap Growth': 19.2,
      'US Large Cap Value': 19.2,
      'US SMID Cap': 23.1,
      'Developed Ex US Equities': 15.4,
      'Emerging Markets Equity': 15.4,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.AggresiveGrowth,
    investorQualification: InvestorQualification.AccreditedInvestor,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 5.0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 0,
      'EM Debt': 0,
      'US Large Cap': 0,
      'US Large Cap Growth': 12.5,
      'US Large Cap Value': 12.5,
      'US SMID Cap': 15.0,
      'Developed Ex US Equities': 10.0,
      'Emerging Markets Equity': 10.0,
      'Direct Lending': 10.0,
      'US Core Real Estate': 10.0,
      'Venture Capital': 0.0,
      'Private Equity': 15.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.AggresiveGrowth,
    investorQualification: InvestorQualification.AccreditedInvestor,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 7.7,
      'Interm. Muni': 0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 0,
      'EM Debt': 0,
      'US Large Cap': 0,
      'US Large Cap Growth': 19.2,
      'US Large Cap Value': 19.2,
      'US SMID Cap': 23.1,
      'Developed Ex US Equities': 15.4,
      'Emerging Markets Equity': 15.4,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.AggresiveGrowth,
    investorQualification: InvestorQualification.AccreditedInvestor,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 7.7,
      'Interm. Treasury': 0,
      'Interm. Corporate': 0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 0,
      'EM Debt': 0,
      'US Large Cap': 0,
      'US Large Cap Growth': 19.2,
      'US Large Cap Value': 19.2,
      'US SMID Cap': 23.1,
      'Developed Ex US Equities': 15.4,
      'Emerging Markets Equity': 15.4,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.AggresiveGrowth,
    investorQualification: InvestorQualification.AccreditedInvestor,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 7.7,
      'Interm. Muni': 0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 0,
      'EM Debt': 0,
      'US Large Cap': 0,
      'US Large Cap Growth': 19.2,
      'US Large Cap Value': 19.2,
      'US SMID Cap': 23.1,
      'Developed Ex US Equities': 15.4,
      'Emerging Markets Equity': 15.4,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.AggresiveGrowth,
    investorQualification: InvestorQualification.NonAccreditedInvestor,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 5.0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 0,
      'EM Debt': 0,
      'US Large Cap': 0,
      'US Large Cap Growth': 12.5,
      'US Large Cap Value': 12.5,
      'US SMID Cap': 15.0,
      'Developed Ex US Equities': 10.0,
      'Emerging Markets Equity': 10.0,
      'Direct Lending': 10.0,
      'US Core Real Estate': 10.0,
      'Venture Capital': 0.0,
      'Private Equity': 15.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.AggresiveGrowth,
    investorQualification: InvestorQualification.NonAccreditedInvestor,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 7.7,
      'Interm. Muni': 0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 0,
      'EM Debt': 0,
      'US Large Cap': 0,
      'US Large Cap Growth': 19.2,
      'US Large Cap Value': 19.2,
      'US SMID Cap': 23.1,
      'Developed Ex US Equities': 15.4,
      'Emerging Markets Equity': 15.4,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.AggresiveGrowth,
    investorQualification: InvestorQualification.NonAccreditedInvestor,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 7.7,
      'Interm. Treasury': 0,
      'Interm. Corporate': 0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 0,
      'EM Debt': 0,
      'US Large Cap': 0,
      'US Large Cap Growth': 19.2,
      'US Large Cap Value': 19.2,
      'US SMID Cap': 23.1,
      'Developed Ex US Equities': 15.4,
      'Emerging Markets Equity': 15.4,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.AggresiveGrowth,
    investorQualification: InvestorQualification.NonAccreditedInvestor,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 7.7,
      'Interm. Muni': 0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 0,
      'EM Debt': 0,
      'US Large Cap': 0,
      'US Large Cap Growth': 19.2,
      'US Large Cap Value': 19.2,
      'US SMID Cap': 23.1,
      'Developed Ex US Equities': 15.4,
      'Emerging Markets Equity': 15.4,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.ModerateGrowth,
    investorQualification: InvestorQualification.QualifiedPurcharser,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 15.0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 0,
      'EM Debt': 0,
      'US Large Cap': 0,
      'US Large Cap Growth': 12.5,
      'US Large Cap Value': 12.5,
      'US SMID Cap': 12.5,
      'Developed Ex US Equities': 7.5,
      'Emerging Markets Equity': 10.0,
      'Direct Lending': 5.0,
      'US Core Real Estate': 5.0,
      'Venture Capital': 8.0,
      'Private Equity': 12.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.ModerateGrowth,
    investorQualification: InvestorQualification.QualifiedPurcharser,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 14.3,
      'Interm. Muni': 0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 7.1,
      'US High Yield Bonds': 0,
      'Muni High Yield': 0,
      'EM Debt': 0,
      'US Large Cap': 0,
      'US Large Cap Growth': 17.9,
      'US Large Cap Value': 17.9,
      'US SMID Cap': 17.9,
      'Developed Ex US Equities': 10.7,
      'Emerging Markets Equity': 14.3,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.ModerateGrowth,
    investorQualification: InvestorQualification.QualifiedPurcharser,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 21.4,
      'Interm. Treasury': 0,
      'Interm. Corporate': 0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 0,
      'EM Debt': 0,
      'US Large Cap': 0,
      'US Large Cap Growth': 17.9,
      'US Large Cap Value': 17.9,
      'US SMID Cap': 17.9,
      'Developed Ex US Equities': 10.7,
      'Emerging Markets Equity': 14.3,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.ModerateGrowth,
    investorQualification: InvestorQualification.QualifiedPurcharser,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 14.3,
      'Interm. Muni': 0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 7.1,
      'US High Yield Bonds': 0,
      'Muni High Yield': 0,
      'EM Debt': 0,
      'US Large Cap': 0,
      'US Large Cap Growth': 17.9,
      'US Large Cap Value': 17.9,
      'US SMID Cap': 17.9,
      'Developed Ex US Equities': 10.7,
      'Emerging Markets Equity': 14.3,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.ModerateGrowth,
    investorQualification: InvestorQualification.AccreditedInvestor,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 15.0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 0,
      'EM Debt': 0,
      'US Large Cap': 0,
      'US Large Cap Growth': 12.5,
      'US Large Cap Value': 12.5,
      'US SMID Cap': 12.5,
      'Developed Ex US Equities': 7.5,
      'Emerging Markets Equity': 10.0,
      'Direct Lending': 7.5,
      'US Core Real Estate': 7.5,
      'Venture Capital': 0.0,
      'Private Equity': 15.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.ModerateGrowth,
    investorQualification: InvestorQualification.AccreditedInvestor,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 14.3,
      'Interm. Muni': 0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 7.1,
      'US High Yield Bonds': 0,
      'Muni High Yield': 0,
      'EM Debt': 0,
      'US Large Cap': 0,
      'US Large Cap Growth': 17.9,
      'US Large Cap Value': 17.9,
      'US SMID Cap': 17.9,
      'Developed Ex US Equities': 10.7,
      'Emerging Markets Equity': 14.3,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.ModerateGrowth,
    investorQualification: InvestorQualification.AccreditedInvestor,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 21.4,
      'Interm. Treasury': 0,
      'Interm. Corporate': 0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 0,
      'EM Debt': 0,
      'US Large Cap': 0,
      'US Large Cap Growth': 17.9,
      'US Large Cap Value': 17.9,
      'US SMID Cap': 17.9,
      'Developed Ex US Equities': 10.7,
      'Emerging Markets Equity': 14.3,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.ModerateGrowth,
    investorQualification: InvestorQualification.AccreditedInvestor,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 14.3,
      'Interm. Muni': 0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 7.1,
      'US High Yield Bonds': 0,
      'Muni High Yield': 0,
      'EM Debt': 0,
      'US Large Cap': 0,
      'US Large Cap Growth': 17.9,
      'US Large Cap Value': 17.9,
      'US SMID Cap': 17.9,
      'Developed Ex US Equities': 10.7,
      'Emerging Markets Equity': 14.3,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.ModerateGrowth,
    investorQualification: InvestorQualification.NonAccreditedInvestor,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 15.0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 0,
      'EM Debt': 0,
      'US Large Cap': 0,
      'US Large Cap Growth': 12.5,
      'US Large Cap Value': 12.5,
      'US SMID Cap': 12.5,
      'Developed Ex US Equities': 7.5,
      'Emerging Markets Equity': 10.0,
      'Direct Lending': 7.5,
      'US Core Real Estate': 7.5,
      'Venture Capital': 0.0,
      'Private Equity': 15.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.ModerateGrowth,
    investorQualification: InvestorQualification.NonAccreditedInvestor,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 14.3,
      'Interm. Muni': 0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 7.1,
      'US High Yield Bonds': 0,
      'Muni High Yield': 0,
      'EM Debt': 0,
      'US Large Cap': 0,
      'US Large Cap Growth': 17.9,
      'US Large Cap Value': 17.9,
      'US SMID Cap': 17.9,
      'Developed Ex US Equities': 10.7,
      'Emerging Markets Equity': 14.3,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.ModerateGrowth,
    investorQualification: InvestorQualification.NonAccreditedInvestor,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 21.4,
      'Interm. Treasury': 0,
      'Interm. Corporate': 0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 0,
      'EM Debt': 0,
      'US Large Cap': 0,
      'US Large Cap Growth': 17.9,
      'US Large Cap Value': 17.9,
      'US SMID Cap': 17.9,
      'Developed Ex US Equities': 10.7,
      'Emerging Markets Equity': 14.3,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.ModerateGrowth,
    investorQualification: InvestorQualification.NonAccreditedInvestor,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 14.3,
      'Interm. Muni': 0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 7.1,
      'US High Yield Bonds': 0,
      'Muni High Yield': 0,
      'EM Debt': 0,
      'US Large Cap': 0,
      'US Large Cap Growth': 17.9,
      'US Large Cap Value': 17.9,
      'US SMID Cap': 17.9,
      'Developed Ex US Equities': 10.7,
      'Emerging Markets Equity': 14.3,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.Balanced,
    investorQualification: InvestorQualification.QualifiedPurcharser,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 20.0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 5.0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 5.0,
      'EM Debt': 5.0,
      'US Large Cap': 0,
      'US Large Cap Growth': 10.0,
      'US Large Cap Value': 10.0,
      'US SMID Cap': 10.0,
      'Developed Ex US Equities': 5.0,
      'Emerging Markets Equity': 5.0,
      'Direct Lending': 7.5,
      'US Core Real Estate': 7.5,
      'Venture Capital': 5.0,
      'Private Equity': 5.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.Balanced,
    investorQualification: InvestorQualification.QualifiedPurcharser,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 20.0,
      'Interm. Muni': 0,
      'Interm. Treasury': 6.7,
      'Interm. Corporate': 6.7,
      'US High Yield Bonds': 6.7,
      'Muni High Yield': 0,
      'EM Debt': 6.7,
      'US Large Cap': 0,
      'US Large Cap Growth': 13.3,
      'US Large Cap Value': 13.3,
      'US SMID Cap': 13.3,
      'Developed Ex US Equities': 6.7,
      'Emerging Markets Equity': 6.7,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.Balanced,
    investorQualification: InvestorQualification.QualifiedPurcharser,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 26.7,
      'Interm. Treasury': 0,
      'Interm. Corporate': 6.7,
      'US High Yield Bonds': 0,
      'Muni High Yield': 6.7,
      'EM Debt': 6.7,
      'US Large Cap': 0,
      'US Large Cap Growth': 13.3,
      'US Large Cap Value': 13.3,
      'US SMID Cap': 13.3,
      'Developed Ex US Equities': 6.7,
      'Emerging Markets Equity': 6.7,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.Balanced,
    investorQualification: InvestorQualification.QualifiedPurcharser,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 20.0,
      'Interm. Muni': 0,
      'Interm. Treasury': 6.7,
      'Interm. Corporate': 6.7,
      'US High Yield Bonds': 6.7,
      'Muni High Yield': 0,
      'EM Debt': 6.7,
      'US Large Cap': 0,
      'US Large Cap Growth': 13.3,
      'US Large Cap Value': 13.3,
      'US SMID Cap': 13.3,
      'Developed Ex US Equities': 6.7,
      'Emerging Markets Equity': 6.7,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.Balanced,
    investorQualification: InvestorQualification.AccreditedInvestor,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 20.0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 5.0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 5.0,
      'EM Debt': 5.0,
      'US Large Cap': 0,
      'US Large Cap Growth': 10.0,
      'US Large Cap Value': 10.0,
      'US SMID Cap': 10.0,
      'Developed Ex US Equities': 5.0,
      'Emerging Markets Equity': 5.0,
      'Direct Lending': 7.5,
      'US Core Real Estate': 7.5,
      'Venture Capital': 0.0,
      'Private Equity': 10.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.Balanced,
    investorQualification: InvestorQualification.AccreditedInvestor,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 20.0,
      'Interm. Muni': 0,
      'Interm. Treasury': 6.7,
      'Interm. Corporate': 6.7,
      'US High Yield Bonds': 6.7,
      'Muni High Yield': 0,
      'EM Debt': 6.7,
      'US Large Cap': 0,
      'US Large Cap Growth': 13.3,
      'US Large Cap Value': 13.3,
      'US SMID Cap': 13.3,
      'Developed Ex US Equities': 6.7,
      'Emerging Markets Equity': 6.7,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.Balanced,
    investorQualification: InvestorQualification.AccreditedInvestor,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 26.7,
      'Interm. Treasury': 0,
      'Interm. Corporate': 6.7,
      'US High Yield Bonds': 0,
      'Muni High Yield': 6.7,
      'EM Debt': 6.7,
      'US Large Cap': 0,
      'US Large Cap Growth': 13.3,
      'US Large Cap Value': 13.3,
      'US SMID Cap': 13.3,
      'Developed Ex US Equities': 6.7,
      'Emerging Markets Equity': 6.7,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.Balanced,
    investorQualification: InvestorQualification.AccreditedInvestor,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 20.0,
      'Interm. Muni': 0,
      'Interm. Treasury': 6.7,
      'Interm. Corporate': 6.7,
      'US High Yield Bonds': 6.7,
      'Muni High Yield': 0,
      'EM Debt': 6.7,
      'US Large Cap': 0,
      'US Large Cap Growth': 13.3,
      'US Large Cap Value': 13.3,
      'US SMID Cap': 13.3,
      'Developed Ex US Equities': 6.7,
      'Emerging Markets Equity': 6.7,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.Balanced,
    investorQualification: InvestorQualification.NonAccreditedInvestor,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 20.0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 5.0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 5.0,
      'EM Debt': 5.0,
      'US Large Cap': 0,
      'US Large Cap Growth': 10.0,
      'US Large Cap Value': 10.0,
      'US SMID Cap': 10.0,
      'Developed Ex US Equities': 5.0,
      'Emerging Markets Equity': 5.0,
      'Direct Lending': 7.5,
      'US Core Real Estate': 7.5,
      'Venture Capital': 0.0,
      'Private Equity': 10.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.Balanced,
    investorQualification: InvestorQualification.NonAccreditedInvestor,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 20.0,
      'Interm. Muni': 0,
      'Interm. Treasury': 6.7,
      'Interm. Corporate': 6.7,
      'US High Yield Bonds': 6.7,
      'Muni High Yield': 0,
      'EM Debt': 6.7,
      'US Large Cap': 0,
      'US Large Cap Growth': 13.3,
      'US Large Cap Value': 13.3,
      'US SMID Cap': 13.3,
      'Developed Ex US Equities': 6.7,
      'Emerging Markets Equity': 6.7,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.Balanced,
    investorQualification: InvestorQualification.NonAccreditedInvestor,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 26.7,
      'Interm. Treasury': 0,
      'Interm. Corporate': 6.7,
      'US High Yield Bonds': 0,
      'Muni High Yield': 6.7,
      'EM Debt': 6.7,
      'US Large Cap': 0,
      'US Large Cap Growth': 13.3,
      'US Large Cap Value': 13.3,
      'US SMID Cap': 13.3,
      'Developed Ex US Equities': 6.7,
      'Emerging Markets Equity': 6.7,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.Balanced,
    investorQualification: InvestorQualification.NonAccreditedInvestor,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 20.0,
      'Interm. Muni': 0,
      'Interm. Treasury': 6.7,
      'Interm. Corporate': 6.7,
      'US High Yield Bonds': 6.7,
      'Muni High Yield': 0,
      'EM Debt': 6.7,
      'US Large Cap': 0,
      'US Large Cap Growth': 13.3,
      'US Large Cap Value': 13.3,
      'US SMID Cap': 13.3,
      'Developed Ex US Equities': 6.7,
      'Emerging Markets Equity': 6.7,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.ConservativeBalanced,
    investorQualification: InvestorQualification.QualifiedPurcharser,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 10.0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 25.0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 5.0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 7.5,
      'EM Debt': 7.5,
      'US Large Cap': 0,
      'US Large Cap Growth': 7.5,
      'US Large Cap Value': 7.5,
      'US SMID Cap': 5.0,
      'Developed Ex US Equities': 5.0,
      'Emerging Markets Equity': 0,
      'Direct Lending': 7.5,
      'US Core Real Estate': 7.5,
      'Venture Capital': 0.0,
      'Private Equity': 5.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.ConservativeBalanced,
    investorQualification: InvestorQualification.QualifiedPurcharser,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 12.5,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 25.0,
      'Interm. Muni': 0,
      'Interm. Treasury': 6.3,
      'Interm. Corporate': 6.3,
      'US High Yield Bonds': 9.4,
      'Muni High Yield': 0,
      'EM Debt': 9.4,
      'US Large Cap': 0,
      'US Large Cap Growth': 9.4,
      'US Large Cap Value': 9.4,
      'US SMID Cap': 6.3,
      'Developed Ex US Equities': 6.3,
      'Emerging Markets Equity': 0,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.ConservativeBalanced,
    investorQualification: InvestorQualification.QualifiedPurcharser,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 12.5,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 31.3,
      'Interm. Treasury': 0,
      'Interm. Corporate': 6.3,
      'US High Yield Bonds': 0,
      'Muni High Yield': 9.4,
      'EM Debt': 9.4,
      'US Large Cap': 0,
      'US Large Cap Growth': 9.4,
      'US Large Cap Value': 9.4,
      'US SMID Cap': 6.3,
      'Developed Ex US Equities': 6.3,
      'Emerging Markets Equity': 0,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.ConservativeBalanced,
    investorQualification: InvestorQualification.QualifiedPurcharser,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 12.5,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 25.0,
      'Interm. Muni': 0,
      'Interm. Treasury': 6.3,
      'Interm. Corporate': 6.3,
      'US High Yield Bonds': 9.4,
      'Muni High Yield': 0,
      'EM Debt': 9.4,
      'US Large Cap': 0,
      'US Large Cap Growth': 9.4,
      'US Large Cap Value': 9.4,
      'US SMID Cap': 6.3,
      'Developed Ex US Equities': 6.3,
      'Emerging Markets Equity': 0,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.ConservativeBalanced,
    investorQualification: InvestorQualification.AccreditedInvestor,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 10.0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 25.0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 5.0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 7.5,
      'EM Debt': 7.5,
      'US Large Cap': 0,
      'US Large Cap Growth': 7.5,
      'US Large Cap Value': 7.5,
      'US SMID Cap': 5.0,
      'Developed Ex US Equities': 5.0,
      'Emerging Markets Equity': 0,
      'Direct Lending': 7.5,
      'US Core Real Estate': 7.5,
      'Venture Capital': 0.0,
      'Private Equity': 5.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.ConservativeBalanced,
    investorQualification: InvestorQualification.AccreditedInvestor,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 12.5,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 25.0,
      'Interm. Muni': 0,
      'Interm. Treasury': 6.3,
      'Interm. Corporate': 6.3,
      'US High Yield Bonds': 9.4,
      'Muni High Yield': 0,
      'EM Debt': 9.4,
      'US Large Cap': 0,
      'US Large Cap Growth': 9.4,
      'US Large Cap Value': 9.4,
      'US SMID Cap': 6.3,
      'Developed Ex US Equities': 6.3,
      'Emerging Markets Equity': 0,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.ConservativeBalanced,
    investorQualification: InvestorQualification.AccreditedInvestor,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 12.5,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 31.3,
      'Interm. Treasury': 0,
      'Interm. Corporate': 6.3,
      'US High Yield Bonds': 0,
      'Muni High Yield': 9.4,
      'EM Debt': 9.4,
      'US Large Cap': 0,
      'US Large Cap Growth': 9.4,
      'US Large Cap Value': 9.4,
      'US SMID Cap': 6.3,
      'Developed Ex US Equities': 6.3,
      'Emerging Markets Equity': 0,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.ConservativeBalanced,
    investorQualification: InvestorQualification.AccreditedInvestor,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 12.5,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 25.0,
      'Interm. Muni': 0,
      'Interm. Treasury': 6.3,
      'Interm. Corporate': 6.3,
      'US High Yield Bonds': 9.4,
      'Muni High Yield': 0,
      'EM Debt': 9.4,
      'US Large Cap': 0,
      'US Large Cap Growth': 9.4,
      'US Large Cap Value': 9.4,
      'US SMID Cap': 6.3,
      'Developed Ex US Equities': 6.3,
      'Emerging Markets Equity': 0,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.ConservativeBalanced,
    investorQualification: InvestorQualification.NonAccreditedInvestor,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 10.0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 25.0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 5.0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 7.5,
      'EM Debt': 7.5,
      'US Large Cap': 0,
      'US Large Cap Growth': 7.5,
      'US Large Cap Value': 7.5,
      'US SMID Cap': 5.0,
      'Developed Ex US Equities': 5.0,
      'Emerging Markets Equity': 0,
      'Direct Lending': 7.5,
      'US Core Real Estate': 7.5,
      'Venture Capital': 0.0,
      'Private Equity': 5.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.ConservativeBalanced,
    investorQualification: InvestorQualification.NonAccreditedInvestor,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 12.5,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 25.0,
      'Interm. Muni': 0,
      'Interm. Treasury': 6.3,
      'Interm. Corporate': 6.3,
      'US High Yield Bonds': 9.4,
      'Muni High Yield': 0,
      'EM Debt': 9.4,
      'US Large Cap': 0,
      'US Large Cap Growth': 9.4,
      'US Large Cap Value': 9.4,
      'US SMID Cap': 6.3,
      'Developed Ex US Equities': 6.3,
      'Emerging Markets Equity': 0,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.ConservativeBalanced,
    investorQualification: InvestorQualification.NonAccreditedInvestor,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 12.5,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 31.3,
      'Interm. Treasury': 0,
      'Interm. Corporate': 6.3,
      'US High Yield Bonds': 0,
      'Muni High Yield': 9.4,
      'EM Debt': 9.4,
      'US Large Cap': 0,
      'US Large Cap Growth': 9.4,
      'US Large Cap Value': 9.4,
      'US SMID Cap': 6.3,
      'Developed Ex US Equities': 6.3,
      'Emerging Markets Equity': 0,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.ConservativeBalanced,
    investorQualification: InvestorQualification.NonAccreditedInvestor,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 12.5,
      'Short-term Muni': 0,
      'Short-term TIPS': 0,
      'Interm. IG Bonds': 25.0,
      'Interm. Muni': 0,
      'Interm. Treasury': 6.3,
      'Interm. Corporate': 6.3,
      'US High Yield Bonds': 9.4,
      'Muni High Yield': 0,
      'EM Debt': 9.4,
      'US Large Cap': 0,
      'US Large Cap Growth': 9.4,
      'US Large Cap Value': 9.4,
      'US SMID Cap': 6.3,
      'Developed Ex US Equities': 6.3,
      'Emerging Markets Equity': 0,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.CapitalPreservation,
    investorQualification: InvestorQualification.QualifiedPurcharser,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 15.0,
      'Short-term TIPS': 10.0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 20.0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 5.0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 12.5,
      'EM Debt': 12.5,
      'US Large Cap': 10.0,
      'US Large Cap Growth': 0,
      'US Large Cap Value': 0,
      'US SMID Cap': 0,
      'Developed Ex US Equities': 0,
      'Emerging Markets Equity': 0,
      'Direct Lending': 7.5,
      'US Core Real Estate': 7.5,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.CapitalPreservation,
    investorQualification: InvestorQualification.QualifiedPurcharser,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 17.6,
      'Short-term Muni': 0,
      'Short-term TIPS': 11.8,
      'Interm. IG Bonds': 23.5,
      'Interm. Muni': 0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 5.9,
      'US High Yield Bonds': 14.7,
      'Muni High Yield': 0,
      'EM Debt': 14.7,
      'US Large Cap': 11.8,
      'US Large Cap Growth': 0,
      'US Large Cap Value': 0,
      'US SMID Cap': 0,
      'Developed Ex US Equities': 0,
      'Emerging Markets Equity': 0,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.CapitalPreservation,
    investorQualification: InvestorQualification.QualifiedPurcharser,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 17.6,
      'Short-term TIPS': 11.8,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 23.5,
      'Interm. Treasury': 0,
      'Interm. Corporate': 5.9,
      'US High Yield Bonds': 0,
      'Muni High Yield': 14.7,
      'EM Debt': 14.7,
      'US Large Cap': 11.8,
      'US Large Cap Growth': 0,
      'US Large Cap Value': 0,
      'US SMID Cap': 0,
      'Developed Ex US Equities': 0,
      'Emerging Markets Equity': 0,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.CapitalPreservation,
    investorQualification: InvestorQualification.QualifiedPurcharser,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 17.6,
      'Short-term Muni': 0,
      'Short-term TIPS': 11.8,
      'Interm. IG Bonds': 23.5,
      'Interm. Muni': 0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 5.9,
      'US High Yield Bonds': 14.7,
      'Muni High Yield': 0,
      'EM Debt': 14.7,
      'US Large Cap': 11.8,
      'US Large Cap Growth': 0,
      'US Large Cap Value': 0,
      'US SMID Cap': 0,
      'Developed Ex US Equities': 0,
      'Emerging Markets Equity': 0,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.CapitalPreservation,
    investorQualification: InvestorQualification.AccreditedInvestor,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 15.0,
      'Short-term TIPS': 10.0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 20.0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 5.0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 12.5,
      'EM Debt': 12.5,
      'US Large Cap': 10.0,
      'US Large Cap Growth': 0,
      'US Large Cap Value': 0,
      'US SMID Cap': 0,
      'Developed Ex US Equities': 0,
      'Emerging Markets Equity': 0,
      'Direct Lending': 7.5,
      'US Core Real Estate': 7.5,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.CapitalPreservation,
    investorQualification: InvestorQualification.AccreditedInvestor,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 17.6,
      'Short-term Muni': 0,
      'Short-term TIPS': 11.8,
      'Interm. IG Bonds': 23.5,
      'Interm. Muni': 0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 5.9,
      'US High Yield Bonds': 14.7,
      'Muni High Yield': 0,
      'EM Debt': 14.7,
      'US Large Cap': 11.8,
      'US Large Cap Growth': 0,
      'US Large Cap Value': 0,
      'US SMID Cap': 0,
      'Developed Ex US Equities': 0,
      'Emerging Markets Equity': 0,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.CapitalPreservation,
    investorQualification: InvestorQualification.AccreditedInvestor,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 17.6,
      'Short-term TIPS': 11.8,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 23.5,
      'Interm. Treasury': 0,
      'Interm. Corporate': 5.9,
      'US High Yield Bonds': 0,
      'Muni High Yield': 14.7,
      'EM Debt': 14.7,
      'US Large Cap': 11.8,
      'US Large Cap Growth': 0,
      'US Large Cap Value': 0,
      'US SMID Cap': 0,
      'Developed Ex US Equities': 0,
      'Emerging Markets Equity': 0,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.CapitalPreservation,
    investorQualification: InvestorQualification.AccreditedInvestor,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 17.6,
      'Short-term Muni': 0,
      'Short-term TIPS': 11.8,
      'Interm. IG Bonds': 23.5,
      'Interm. Muni': 0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 5.9,
      'US High Yield Bonds': 14.7,
      'Muni High Yield': 0,
      'EM Debt': 14.7,
      'US Large Cap': 11.8,
      'US Large Cap Growth': 0,
      'US Large Cap Value': 0,
      'US SMID Cap': 0,
      'Developed Ex US Equities': 0,
      'Emerging Markets Equity': 0,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.CapitalPreservation,
    investorQualification: InvestorQualification.NonAccreditedInvestor,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 15.0,
      'Short-term TIPS': 10.0,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 20.0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 5.0,
      'US High Yield Bonds': 0,
      'Muni High Yield': 12.5,
      'EM Debt': 12.5,
      'US Large Cap': 10.0,
      'US Large Cap Growth': 0,
      'US Large Cap Value': 0,
      'US SMID Cap': 0,
      'Developed Ex US Equities': 0,
      'Emerging Markets Equity': 0,
      'Direct Lending': 7.5,
      'US Core Real Estate': 7.5,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.CapitalPreservation,
    investorQualification: InvestorQualification.NonAccreditedInvestor,
    preference: Preference.Alternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 17.6,
      'Short-term Muni': 0,
      'Short-term TIPS': 11.8,
      'Interm. IG Bonds': 23.5,
      'Interm. Muni': 0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 5.9,
      'US High Yield Bonds': 14.7,
      'Muni High Yield': 0,
      'EM Debt': 14.7,
      'US Large Cap': 11.8,
      'US Large Cap Growth': 0,
      'US Large Cap Value': 0,
      'US SMID Cap': 0,
      'Developed Ex US Equities': 0,
      'Emerging Markets Equity': 0,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.CapitalPreservation,
    investorQualification: InvestorQualification.NonAccreditedInvestor,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Taxable,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 0,
      'Short-term Muni': 17.6,
      'Short-term TIPS': 11.8,
      'Interm. IG Bonds': 0,
      'Interm. Muni': 23.5,
      'Interm. Treasury': 0,
      'Interm. Corporate': 5.9,
      'US High Yield Bonds': 0,
      'Muni High Yield': 14.7,
      'EM Debt': 14.7,
      'US Large Cap': 11.8,
      'US Large Cap Growth': 0,
      'US Large Cap Value': 0,
      'US SMID Cap': 0,
      'Developed Ex US Equities': 0,
      'Emerging Markets Equity': 0,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
  {
    investmentPolicy: InvestmentPolicy.CapitalPreservation,
    investorQualification: InvestorQualification.NonAccreditedInvestor,
    preference: Preference.NoAlternatives,
    taxTreatment: TaxTreatment.Retirement,
    portfolio: ipsPortfolioToAssetClasses({
      'Short-term IG Bonds': 17.6,
      'Short-term Muni': 0,
      'Short-term TIPS': 11.8,
      'Interm. IG Bonds': 23.5,
      'Interm. Muni': 0,
      'Interm. Treasury': 0,
      'Interm. Corporate': 5.9,
      'US High Yield Bonds': 14.7,
      'Muni High Yield': 0,
      'EM Debt': 14.7,
      'US Large Cap': 11.8,
      'US Large Cap Growth': 0,
      'US Large Cap Value': 0,
      'US SMID Cap': 0,
      'Developed Ex US Equities': 0,
      'Emerging Markets Equity': 0,
      'Direct Lending': 0.0,
      'US Core Real Estate': 0.0,
      'Venture Capital': 0.0,
      'Private Equity': 0.0,
    }),
  },
] satisfies Omit<IPSPortfolioDetails, 'id'>[];

export const IPS_PORTFOLIOS = IDLESS_IPS_PORTFOLIOS.map((idlessPortfolio) => ({
  ...idlessPortfolio,
  id: `${idlessPortfolio.investorQualification}-${idlessPortfolio.preference}-${idlessPortfolio.investmentPolicy}-${idlessPortfolio.taxTreatment}`.toLowerCase(),
}));

export const IPS_PORTFOLIOS_BY_TAX_TREATMENT = Object.fromEntries(
  Object.entries(groupBy(IPS_PORTFOLIOS, 'taxTreatment')).map(
    ([taxTreatment, ipsPortfolios]) => [
      taxTreatment,
      Object.fromEntries(
        Object.entries(groupBy(ipsPortfolios, 'investorQualification')).map(
          ([investorQualification, ipsPortfolios]) => [
            investorQualification,
            Object.fromEntries(
              Object.entries(groupBy(ipsPortfolios, 'preference')).map(
                ([preference, ipsPortfolios]) => [
                  preference,
                  keyBy(ipsPortfolios, 'investmentPolicy'),
                ],
              ),
            ),
          ],
        ),
      ),
    ],
  ),
) as Record<
  TaxTreatment,
  Record<
    InvestorQualification,
    Record<Preference, Record<InvestmentPolicy, IPSPortfolioDetails>>
  >
>;

export const defaultTaxableAndRetirementStrategies = ({
  original,
  settings,
}: {
  original: AllocationProposalStrategyTypes.OriginalAllocationProposalStrategy;
  settings: AllocationProposalSettingsTypes.Settings;
}) => {
  const { networth, allocations: originalAssets } = original;
  const originalRetirementAssets =
    AllocationProposalStrategy.retirement(originalAssets);
  const originalRetirementAssetsValue = AllocationProposalStrategy.value(
    originalRetirementAssets,
  );
  const retirementNetworth =
    originalRetirementAssetsValue <
    NumberUtils.percentageFromValue(0.5, networth)
      ? 0
      : originalRetirementAssetsValue;
  const taxableNetworth = NumberUtils.subtract(networth, retirementNetworth);
  const { investmentPolicy, investorQualification, preference } = settings;
  const hasInvestmentPolicy = !!investmentPolicy;
  const hasInvestorQualification = !!investorQualification;
  const hasPreference = !!preference;
  const hasEnoughDataToSelectPortfolio =
    hasInvestmentPolicy && hasInvestorQualification && hasPreference;
  const taxable = {
    id: 'TAXABLE_ASSET_ALLOCATION',
    type: TargetAllocationProposalStrategyType.Taxable,
    networth: taxableNetworth,
    allocations: hasEnoughDataToSelectPortfolio
      ? // prettier-ignore
        AllocationProposalStrategy.create([
          ...IPS_PORTFOLIOS_BY_TAX_TREATMENT
          [TaxTreatment.Taxable]
          [investorQualification]
          [preference]
          [investmentPolicy]
          .portfolio,
        ])
      : [],
  };
  const retirement = {
    id: 'RETIREMENT_ASSET_ALLOCATION',
    type: AllocationProposalStrategyType.Retirement,
    networth: retirementNetworth,
    allocations: hasEnoughDataToSelectPortfolio
      ? // prettier-ignore
        AllocationProposalStrategy.create([
          ...IPS_PORTFOLIOS_BY_TAX_TREATMENT
          [TaxTreatment.Retirement]
          [investorQualification]
          [preference]
          [investmentPolicy]
          .portfolio,
        ])
      : [],
  };
  return { taxable, retirement };
};
