import { useState, useEffect, useRef } from 'react';

export type DropEvent = {
  target: { files: DataTransferItemList };
};

interface DragProps {
  onDrop: (event: DropEvent) => void;
  inactive: boolean;
}

/**
 * Exposes drag and drop event handling (for files) to a component.
 *
 * The easiest way to use this hook is to to include it in a component like so:
 *
 * const dragAndDrop = useDragAndDrop(props);
 *
 * And then spread the returned values directly into the component, e.g. <MyDragComponent {...dragAndDrop} />
 *
 * Adding the following to the component's className prop declaration `isDragging ? dragging : ''` will wrap
 * the component with a dashed border when a file is dragged over it.
 *
 * @param props.inactive When true, the hook will ignore drag and drop events. For example, this could be
 * useful for preventing a new file from being dragged into a component when a file is uploading
 *
 * @param props.onDrop Handler to allow the calling component access to the files that were dropped into the component
 */
function useDragAndDrop(props: DragProps) {
  const isInactive = useRef(props.inactive);
  const [isDragging, setDragging] = useState(false);

  useEffect(() => {
    if (props.inactive !== isInactive.current) {
      isInactive.current = props.inactive;
    }
  }, [props.inactive]);

  function handleDrag(event: React.DragEvent) {
    if (isInactive.current) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    setDragging(true);
  }

  function handleDrop(event) {
    if (isInactive.current) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    const normalizedEvent = {
      target: {
        files: event.dataTransfer.files,
      },
    };

    props.onDrop(normalizedEvent);
    setDragging(false);
  }

  function handleDragLeave() {
    setDragging(false);
  }

  return {
    onDragOver: handleDrag,
    onDragLeave: handleDragLeave,
    onDrop: handleDrop,
    dragging: isDragging,
  };
}

export default useDragAndDrop;
