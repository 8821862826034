import { Group } from '@visx/group';
import React from 'react';

export const YAxis = ({
  values,
  children,
}: {
  values: Array<number>;
  children: ({ value }: { value: number }) => React.ReactNode;
}) => {
  return (
    <Group>
      {values.map((value, index) => (
        <React.Fragment key={index}>{children({ value })}</React.Fragment>
      ))}
    </Group>
  );
};
