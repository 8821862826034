export const SortMethods = {
  IssueDate: 'issueDate',
  CurrentValue: 'currentValue',
  StatusIssueDate: 'statusIssueDate',
  StatusCurrentValue: 'statusCurrentValue',
};

export const InvestmentValuationType = {
  EstimatedValue: 'estimatedValue',
  OriginalPrice: 'originalPrice',
  LatestPrice: 'latestPrice',
  LatestValuation: 'latestValuation',
  CommittedCapital: 'committedCapital',
};

export const InvestmentVehicle = {
  Special: 'specialPurpose',
  Direct: 'direct',
  Other: 'other',
  Rolling: 'rollingFund',
};

export const InvestmentStatus = {
  Active: 'active',
  Exited: 'exited',
  Failed: 'failed',
};

export const InvestmentPlatform = {
  Carta: 'carta',
  Pulley: 'pulley',
  Angelist: 'angelist',
  Other: 'other',
};

export enum PrivateInvestmentFields {
  FundName = 'fundName',
  CompanyName = 'companyName',
  InvestmentStage = 'investmentStage',
  Committed = 'committed',
  ShareQuantity = 'shareQuantity',
  Date = 'date',
  SharePrice = 'sharePrice',
  Type = 'type',
  Provider = 'provider',
  OwnershipPercent = 'ownershipPercent',
  EstimatedValue = 'estimatedValue',
  LatestValuation = 'latestValuation',
  ValuationType = 'valuationType',
  InvestmentStatus = 'investmentStatus',
  ExitDate = 'exitDate',
  InvestmentVehicle = 'investmentVehicle',
  SPVCarryPercent = 'carryPercent',
  PlatformHeld = 'platformHeld',
  PlatformName = 'platformName',
  InvestmentEvents = 'investmentEvents',
  QualifiedBusiness = 'isQsbsQualifiedBusiness',
  QsbsAssetsQualified = 'qsbsAssetsQualified',
}
