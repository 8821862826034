import { Box, Col, Row, Text } from '@compoundfinance/design-system';
import { BarChart, Bar, XAxis, LabelList } from 'recharts';
import { memo } from 'react';
import UiUtils from 'utils/ui';

interface LossChartProps {
  data: {
    Q1: [number, number];
    Q2: [number, number];
    Q3: [number, number];
    Q4: [number, number];
  };
  taxYear: number;
}

const LossChart = ({ data, taxYear }: LossChartProps) => {
  const chartData = Object.entries(data).map(
    ([quarter, [shortTerm, longTerm]]) => ({
      quarter,
      shortTerm,
      longTerm,
    }),
  );

  return (
    <Box
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        backgroundColor: '$gray3',
        borderRadius: '$8',
        padding: '$24',
      }}
    >
      <Row css={{ justifyContent: 'space-between' }}>
        <Text weight="bold" size={15} css={{ alignSelf: 'flex-start' }}>
          {taxYear} Tax Loss Harvesting
        </Text>
        <Col style={{ alignSelf: 'flex-end' }}>
          <Text css={{ margin: 0 }}>Legend:</Text>
          <Row css={{ ai: 'center', gap: '$6' }}>
            <Box
              css={{
                width: '$12',
                height: '$12',
                borderRadius: '50%',
                background: '#9370DB',
              }}
            />
            <Text css={{ color: '#9370DB', margin: 0 }}>Short Term</Text>
          </Row>
          <Row css={{ ai: 'center', gap: '$6' }}>
            <Box
              css={{
                width: '$12',
                height: '$12',
                borderRadius: '50%',
                background: '#30A46C',
              }}
            />
            <Text css={{ color: '#30A46C', margin: 0 }}>Long Term</Text>
          </Row>
        </Col>
      </Row>

      <BarChart width={600} height={300} data={chartData}>
        <XAxis dataKey="quarter" axisLine={false} tickLine={false} />
        <Bar
          isAnimationActive={false}
          dataKey="shortTerm"
          fill="#9370DB"
          radius={[6, 6, 6, 6]}
        >
          <LabelList
            dataKey="shortTerm"
            position="insideTop"
            formatter={(value) => `${UiUtils.formatMoney(value)}`}
            fill="white"
            fontSize={12}
          />
        </Bar>
        <Bar
          isAnimationActive={false}
          dataKey="longTerm"
          fill="#30A46C"
          radius={[6, 6, 6, 6]}
        >
          <LabelList
            dataKey="longTerm"
            position="insideTop"
            formatter={(value) => `${UiUtils.formatMoney(value)}`}
            fill="white"
            fontSize={12}
          />
        </Bar>
      </BarChart>
    </Box>
  );
};

export default memo(LossChart);
