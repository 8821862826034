import EditablePerson from 'containers/Dashboard/Document/components/EditablePerson';
import EditableTextArea from 'containers/Dashboard/Document/components/EditableTextArea';
import ModuleContainer from 'containers/Dashboard/Document/components/ModuleContainer';
import { getPerson } from 'containers/Dashboard/Document/utils';
import { Text, Col } from '@compoundfinance/design-system';
import { DirectoryPerson } from 'domain/Employees/constants';
import { styled } from '@compoundfinance/design-system/dist/stitches.config';
import { User } from 'types/user';
import UserUtils from 'utils/user';

const Container = styled(ModuleContainer, {
  display: 'grid',
  py: '$24',
  px: '$24',
  gap: '$24',

  '@bp1': {
    py: '$44',
    px: '$48',
    gridTemplateColumns: '1fr 1px 1fr',
    gap: '$48',
  },
});

const VerticalSplit = styled('div', {
  borderBottom: '1px solid $gray4',

  '@bp1': {
    borderLeft: '1px solid $gray4',
  },
});

export interface IntroductionProps {
  recipient: User;
  financialAdvisor: DirectoryPerson;
}

function Introduction(props: IntroductionProps) {
  const { recipient, financialAdvisor } = props;

  const financialAdvisorPerson = getPerson(financialAdvisor);

  return (
    <Container>
      <Col css={{ h: '100%', justifyContent: 'space-between', gap: '$24' }}>
        <Col css={{ gap: '$12' }}>
          <EditableTextArea
            propertyKey="title"
            value="Investment review Q1 2023"
            textCss={{
              fontWeight: '$medium',
              fontSize: '$40',
              letterSpacing: '-0.8px',

              '@bp1': { fontSize: '$48', lineHeight: '58px' },
            }}
          />
          <Text size="20" color="gray10">
            for {UserUtils.getName(recipient.firstName, recipient.lastName)}
          </Text>
        </Col>
      </Col>

      <VerticalSplit />

      <Col css={{ h: '100%', jc: 'space-between' }}>
        <EditableTextArea
          propertyKey="introText"
          value={`${recipient.firstName},\n\nBelow is a review of how your investment accounts performed over the past quarter. The goal of our conversation will be to review performance across your different accounts, compare to benchmarks where it makes sense, and discuss how we’re tracking towards your goals.`}
          textCss={{
            fontSize: '$14',
            w: '100%',
          }}
        />
        <EditablePerson
          propertyKey="financialAdvisor"
          propertyValue={financialAdvisor}
          person={financialAdvisorPerson}
          size="small"
          level="document"
        />
      </Col>
    </Container>
  );
}

export default Introduction;
