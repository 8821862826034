import Disclosure from 'components/Disclosure';
import { DocumentModuleGroup } from 'containers/Dashboard/Document/modules/modules';
import { Col, Text } from '@compoundfinance/design-system';

interface DisclaimerProps {
  documentType: string;
}

function Disclaimer(props: DisclaimerProps) {
  const { documentType } = props;

  switch (documentType) {
    case DocumentModuleGroup.MembershipProposal:
      return (
        <Col css={{ gap: '$6', mt: '$4', w: '1034px' }}>
          <Text size="13" color="gray11" weight="medium">
            Disclaimer
          </Text>
          <Text size="13" color="gray10" css={{ lineHeight: '20px' }}>
            Subject to the terms and conditions of the{' '}
            <a
              href="https://legal.compoundplanning.com/client-agreements"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              Compound Client Agreements
            </a>
            . Compound Financial Inc. (“Compound Financial”) offers
            software-based financial management and planning tools. Investment
            advisory services are provided by Compound Advisors, Inc. (“Compound
            Advisers”), an SEC-registered investment adviser. Compound Tax, LLC
            (“Compound Tax”) provides tax consulting and compliance services.
            Compound Advisers and Compound Tax are wholly owned subsidiaries of
            Compound Financial. Altogether, we refer to our business as
            (“Compound”). Registration as an investment adviser does not imply
            any level of skill or training. Nothing on this document should be
            considered an offer, solicitation of an offer, or advice to buy or
            sell securities. Compound is not a licensed lender, law firm or
            insurance agency, and any such services mentioned in this document
            are provided by non-affiliated referrers. Account holdings are for
            illustrative purposes only and are not investment recommendations.
            The content on this document is for informational purposes only and
            does not constitute a comprehensive description of Compound’s
            investment advisory services. Refer to Compound’s Brochure (
            <a
              href="https://compoundplanning.com/legal/adv"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              Form ADV Part 2A
            </a>
            ) and Client Relationship Summary (
            <a
              href="https://compoundplanning.com/legal/crs"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              Form CRS
            </a>
            ) for more information. Brokerage services are provided by Charles
            Schwab & Co., Inc. (“Schwab,” Member SIPC). Access to alternative
            investment deals is available for accredited investors or qualified
            purchasers only.
            <br />
            <br />
            We calculate your fee on a quarterly basis. If your billable assets
            directly managed by Compound do not generate at least $1,625 in a
            quarter, we will bill you $1,625 for that quarter. If your average
            billable and managed assets are $1M or greater in a quarter, we will
            bill you based on the standard AUM schedule. Third party
            sub-advisor-fees may apply outside of our membership fee. “Marginal
            AUM fee” means that the next dollar that falls within the next
            higher value AUM bracket is charged at that new rate in the next
            higher value AUM bracket, and that the dollars falling within the
            lower value AUM brackets will still be charged at the respective
            rates for those lower value AUM brackets regardless of the total
            value of Billable AUM.
            <br />
            <br />
            Please note that this service covers individual federal and state
            tax returns, not business entity returns such as Form 1120, 1120-S
            and 1065. If you sign your agreement and qualify before November
            15th of a year, you will be eligible for that year's tax filing. If
            you qualify after November 15th, we will not be able to guarantee
            the ability to prepare your current year tax return by the regular
            deadline, but may be able to do so on extension. For example,
            signing your agreement and transferring $2,300,000 in assets for
            Compound to manage on October 27, 2023 will make you eligible for
            in-house completion of your 2023 tax returns. Tax filing includes
            service credits of up to $3,000 per tax year at our standard tax
            filing rates. This covers most individual returns of medium
            complexity.
            <br />
            <br />
            All logos are trademarks of their respective owners. For more
            information, please see{' '}
            <a
              href="https://legal.compoundplanning.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              legal.compoundplanning.com
            </a>
            .
          </Text>
        </Col>
      );
    case DocumentModuleGroup.QuarterlyPerformanceUpdate:
      return (
        <Col css={{ gap: '$6', mt: '$4', w: '1034px' }}>
          <Text size="13" color="gray11" weight="medium">
            Disclaimer
          </Text>
          <Text size="13" color="gray10" css={{ lineHeight: '20px' }}>
            <Text>Returns and impact of Compound's Advisory Fees:</Text>
            <br />
            For clients whose accounts' fees fall above the minimum fee amount,
            the performance results presented herein are net of Compound's
            investment advisory fees. These fees are typically charged to
            clients' accounts, unless otherwise indicated, based on the billable
            assets under management and the tiered fee schedule agreed upon
            between Compound and the client. The returns reflect the deduction
            of these fees, which can vary based on the specific fee schedule
            agreed upon with each client.
            <br />
            <br />
            For clients whose accounts' fees fall below the minimum fee amount
            and are not charged to clients’ accounts, the performance results
            are presented gross of Compound's advisory fees. These returns do
            not reflect the deduction of any investment advisory fees charged by
            Compound.
            <br />
            <br />
            <Text>Minimum Fee Amount:</Text>
            <br />
            Compound's standard minimum fee amount is $6,500. This means that if
            a client's calculated fee falls below $6,500, and their fees are not
            charged to their accounts, their account's returns will be presented
            gross of Compound's advisory fees.
            <br />
            <br />
            <Text>Sample fee calculations:</Text>
            <br />
            Compound's fees are typically determined by applying a marginal
            tiered fee rate on the incremental billable assets under management.
            For clients who do not meet the standard minimum fee amount of
            $6,500, their accounts are not subject to the marginal tiered fee
            rate and are only subject to the minimum fee amount. For clients who
            have $1 million in billable assets under management, Compound's
            annual investment advisory services would be $6,500 based on the
            standard fee schedule (or 0.65% annually). As clients add more
            billable assets to be managed by Compound, their investment advisory
            fees will also increase but their fee percentage may decrease. For
            clients who have $2.5 million in billable assets under management,
            Compound's annual investment advisory fees would be $15,750 based on
            the standard fee schedule (or 0.63% annually). In this case, the
            first $2 million is billed according to the first 0.65% fee rate and
            the next $500,000 is billed according to the next 0.55% fee rate.
            Finally, if a client has accounts with $10 million in billable
            assets under management, Compound's fees would be applied as
            follows: (0.65% X $2 million) + (0.55% X $1 million) + (0.50% X $2
            million) + (0.45% X $5 million). This would result in $51,000 in
            annual investment advisory fees, or 0.51% rate.
            <br />
            <br />
            Compound's fees are assessed in arrears, on a quarterly basis, using
            a 365 day timeline and are prorated from and to the date that a
            client executes or terminates their Investment Advisory Agreement.
            Please refer to the Fees and Expenses section of the Services
            Agreement for more detail.
          </Text>
        </Col>
      );
    case DocumentModuleGroup.AssetAllocationInvestmentProposal:
      return (
        <Col css={{ mt: '$4', w: '1034px' }}>
          <Disclosure
            summary={
              <Text size="13" color="gray11">
                Target/Hypothetical Performance Returns
              </Text>
            }
          >
            <Text size="13" color="gray10" css={{ lineHeight: '20px' }}>
              Target returns are hypothetical and are not a guarantee of future
              results. Actual returns may vary and are subject to market
              fluctuations and other risks. Past performance is not indicative
              of future results. Any projections or other forward-looking
              statements regarding future events or performance of investments,
              including without limitation, targets for investment returns,
              involve assumptions, risks and uncertainties which may cause
              actual results to differ materially from those expressed or
              implied in such projections or forward-looking statements.
              Accordingly, any projections or forward-looking statements should
              not be relied upon as accurate predictions of future results. All
              investments involve risk and may lose value. The calculations
              reflected herein are as of today. Capital market assumptions may
              change quickly and may materially alter the projections. Compound
              has no duty to inform you of any changes. Compound's investment
              team reviews, approves, and makes changes to capital market
              assumptions periodically. Compound uses third party data providers
              to inform forward looking capital market assumptions and has
              currently adopted the JP Morgan 2023 Long Term Capital Market
              Assumptions as the basis for forecasting.
            </Text>
          </Disclosure>
          <Disclosure
            summary={
              <Text size="13" color="gray11">
                Returns and impact of Compound's Advisory Fees
              </Text>
            }
          >
            <Text size="13" color="gray10" css={{ lineHeight: '20px' }}>
              For clients whose accounts' fees fall above the minimum fee
              amount, the performance results presented herein are net of
              Compound's investment advisory fees. These fees are typically
              charged to clients' accounts, unless otherwise indicated, based on
              the billable assets under management and the tiered fee schedule
              agreed upon between Compound and the client. The returns reflect
              the deduction of these fees, which can vary based on the specific
              fee schedule agreed upon with each client. For clients whose
              accounts' fees fall below the minimum fee amount and are not
              charged to clients’ accounts, the performance results are
              presented gross of Compound's advisory fees. These returns do not
              reflect the deduction of any investment advisory fees charged by
              Compound.
            </Text>
          </Disclosure>
          <Disclosure
            summary={
              <Text size="13" color="gray11">
                Minimum Fee Amount
              </Text>
            }
          >
            <Text size="13" color="gray10" css={{ lineHeight: '20px' }}>
              Compound's standard minimum fee amount is $6,500. This means that
              if a client's calculated fee falls below $6,500, and their fees
              are not charged to their accounts, their account's returns will be
              presented gross of Compound's advisory fees.
            </Text>
          </Disclosure>
          <Disclosure
            summary={
              <Text size="13" color="gray11">
                Sample fee calculations
              </Text>
            }
          >
            <Text size="13" color="gray10" css={{ lineHeight: '20px' }}>
              Compound's fees are typically determined by applying a marginal
              tiered fee rate on the incremental billable assets under
              management. For clients who do not meet the standard minimum fee
              amount of $6,500, their accounts are not subject to the marginal
              tiered fee rate and are only subject to the minimum fee amount.
              For clients who have $1 million in billable assets under
              management, Compound's annual investment advisory services would
              be $6,500 based on the standard fee schedule (or 0.65% annually).
              As clients add more billable assets to be managed by Compound,
              their investment advisory fees will also increase but their fee
              percentage may decrease. For clients who have $2.5 million in
              billable assets under management, Compound's annual investment
              advisory fees would be $15,750 based on the standard fee schedule
              (or 0.63% annually). In this case, the first $2 million is billed
              according to the first 0.65% fee rate and the next $500,000 is
              billed according to the next 0.55% fee rate. Finally, if a client
              has accounts with $10 million in billable assets under management,
              Compound's fees would be applied as follows: (0.65% X $2 million)
              + (0.55% X $1 million) + (0.50% X $2 million) + (0.45% X $5
              million). This would result in $51,000 in annual investment
              advisory fees, or 0.51% rate. Compound's fees are assessed in
              arrears, on a quarterly basis, using a 365 day timeline and are
              prorated from and to the date that a client executes or terminates
              their Investment Advisory Agreement. Please refer to the Fees and
              Expenses section of the Services Agreement for more detail.
            </Text>
          </Disclosure>
        </Col>
      );
    case DocumentModuleGroup.SalesIntroduction:
      return (
        <Col css={{ gap: '$6', mt: '$4', w: '1034px' }}>
          <Text size="13" color="gray11" weight="medium">
            Disclaimer
          </Text>
          <Text size="13" color="gray10" css={{ lineHeight: '20px' }}>
            Subject to the terms and conditions of the{' '}
            <a
              href="https://legal.compoundplanning.com/client-agreements"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              Compound Client Agreements
            </a>
            . Compound Financial Inc. (“Compound Financial”) offers
            software-based financial management and planning tools. Investment
            advisory services are provided by Compound Advisors, Inc. (“Compound
            Advisers”), an SEC-registered investment adviser. Compound Tax, LLC
            (“Compound Tax”) provides tax consulting and compliance services.
            Compound Advisers and Compound Tax are wholly owned subsidiaries of
            Compound Financial. Altogether, we refer to our business as
            (“Compound”). Registration as an investment adviser does not imply
            any level of skill or training. Nothing on this document should be
            considered an offer, solicitation of an offer, or advice to buy or
            sell securities. Compound is not a licensed lender, law firm or
            insurance agency, and any such services mentioned in this document
            are provided by non-affiliated referrers. Account holdings are for
            illustrative purposes only and are not investment recommendations.
            The content on this document is for informational purposes only and
            does not constitute a comprehensive description of Compound’s
            investment advisory services. Refer to Compound’s Brochure (
            <a
              href="https://compoundplanning.com/legal/adv"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              Form ADV Part 2A
            </a>
            ) and Client Relationship Summary (
            <a
              href="https://compoundplanning.com/legal/crs"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              Form CRS
            </a>
            ) for more information. Brokerage services are provided by Charles
            Schwab & Co., Inc. (“Schwab,” Member SIPC). Access to alternative
            investment deals is available for accredited investors or qualified
            purchasers only.
            <br />
            <br />
            For target performance on Compound managed accounts and portfolios
            we will disclose the following:
            <br />
            <br />
            Target/Hypothetical Performance Returns: Target returns are
            hypothetical and are not a guarantee of future results. Actual
            returns may vary and are subject to market fluctuations and other
            risks. Past performance is not indicative of future results. Any
            projections or other forward-looking statements regarding future
            events or performance of investments, including without limitation,
            targets for investment returns, involve assumptions, risks and
            uncertainties which may cause actual results to differ materially
            from those expressed or implied in such projections or
            forward-looking statements. Accordingly, any projections or
            forward-looking statements should not be relied upon as accurate
            predictions of future results. All investments involve risk and may
            lose value. The calculations reflected herein are as of today.
            Capital market assumptions may change quickly and may materially
            alter the projections. Compound has no duty to inform you of any
            changes. Compound's investment team reviews, approves, and makes
            changes to capital market assumptions periodically. Compound uses
            third party data providers to inform forward looking capital market
            assumptions and has currently adopted the JP Morgan 2023 Long Term
            Capital Market Assumptions as the basis for forecasting.
            <br />
            <br />
            Returns and impact of Compound's Advisory Fees: For clients whose
            accounts' fees fall above the minimum fee amount, the performance
            results presented herein are net of Compound's investment advisory
            fees. These fees are typically charged to clients' accounts, unless
            otherwise indicated, based on the billable assets under management
            and the tiered fee schedule agreed upon between Compound and the
            client. The returns reflect the deduction of these fees, which can
            vary based on the specific fee schedule agreed upon with each
            client.
            <br />
            <br />
            For clients whose accounts' fees fall below the minimum fee amount
            and are not charged to clients’ accounts, the performance results
            are presented gross of Compound's advisory fees. These returns do
            not reflect the deduction of any investment advisory fees charged by
            Compound.
            <br />
            <br />
            Minimum Fee Amount: Compound's standard minimum fee amount is
            $6,500. This means that if a client's calculated fee falls below
            $6,500, and their fees are not charged to their accounts, their
            account's returns will be presented gross of Compound's advisory
            fees.
            <br />
            <br />
            Sample fee calculations: Compound's fees are typically determined by
            applying a marginal tiered fee rate on the incremental billable
            assets under management. For clients who do not meet the standard
            minimum fee amount of $6,500, their accounts are not subject to the
            marginal tiered fee rate and are only subject to the minimum fee
            amount. For clients who have $1 million in billable assets under
            management, Compound's annual investment advisory services would be
            $6,500 based on the standard fee schedule (or 0.65% annually). As
            clients add more billable assets to be managed by Compound, their
            investment advisory fees will also increase but their fee percentage
            may decrease. For clients who have $2.5 million in billable assets
            under management, Compound's annual investment advisory fees would
            be $15,750 based on the standard fee schedule (or 0.63% annually).
            In this case, the first $2 million is billed according to the first
            0.65% fee rate and the next $500,000 is billed according to the next
            0.55% fee rate. Finally, if a client has accounts with $10 million
            in billable assets under management, Compound's fees would be
            applied as follows: (0.65% X $2 million) + (0.55% X $1 million) +
            (0.50% X $2 million) + (0.45% X $5 million). This would result in
            $51,000 in annual investment advisory fees, or 0.51% rate.
            <br />
            <br />
            Please note that this service covers individual federal and state
            tax returns, not business entity returns such as Form 1120, 1120-S
            and 1065.
            <br />
            <br />
            All logos are trademarks of their respective owners. For more
            information, please see{' '}
            <a
              href="https://legal.compoundplanning.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              legal.compoundplanning.com
            </a>
            .
          </Text>
        </Col>
      );
    default:
      return null;
  }
}
export default Disclaimer;
