import styled from 'styled-components';
import { ScProps } from 'components/types';

export const MainWrap = styled.main`
  display: flex;
  flex-direction: column;
  height: ${(props: any) => props.h || 'auto'};
  background-color: ${(props: ScProps) =>
    props.bg || props.theme.colors.off_white};
  overflow-y: auto;
` as any;
