import React from 'react';
import styled, { css } from 'styled-components';
import { ScProps, CompoundColor } from 'components/types';
import Typography, { TypographyProps } from './Typography';

export interface ButtonProps {
  w?: string;
  h?: string;
  fontSize?: string;
  fontWeight?: number;
  bgColor?: CompoundColor;
  hoverBg?: CompoundColor;
  hoverColor?: CompoundColor;
  borderColor?: CompoundColor;
  color?: CompoundColor;
  textAlign?: 'left' | 'center' | 'right' | 'inherit';
  disabled?: boolean;
}

const ButtonCoreStyle = css<ButtonProps>`
  display: flex;
  align-items: center;
  padding: 0 1.25rem;
  width: ${({ w }) => w || 'fit-content'};
  height: ${({ h }) => h || '40px'};
  min-width: fit-content;
  border-radius: 4px;
  line-height: 1;

  border: ${({ theme }) => theme.border};
  font-size: ${({ fontSize }) => fontSize || '0.875rem'};
  font-weight: ${({ fontWeight, theme }) => fontWeight || theme.fw.medium};
  background-color: ${({ bgColor, theme }) =>
    bgColor ? theme.colors[bgColor] : 'transparent'};
  border-color: ${({ borderColor, theme }) =>
    theme.colors[borderColor || 'border_grey']};
  color: ${({ color, theme }) =>
    color ? theme.colors[color] : theme.colors.black_grey};
  text-align: ${({ textAlign }) => textAlign || 'center'};
  justify-content: ${({ textAlign }) => textAlign || 'center'};
  transition: 0.1s;

  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};

  &:hover {
    text-decoration: none;
    color: ${({ hoverColor, theme }) =>
      hoverColor ? theme.colors[hoverColor] : theme.colors.black_grey};
    background-color: ${({ hoverBg, bgColor, theme }) =>
      hoverBg
        ? theme.colors[hoverBg]
        : bgColor
        ? theme.colors[bgColor]
        : 'transparent'};
    opacity: 0.9 !important;
  }

  &:focus {
    outline: none;
  }
`;

export const Button = styled.button`
  ${ButtonCoreStyle};
`;

/**
 * Passing a boolean `inactive` prop yields a react warning:
 *
 * Warning: Received `true` for a non-boolean attribute `inactive`.
 * If you want to write it to the DOM, pass a string instead: inactive="true" or inactive={value.toString()}.
 *
 * Therefore, prefer a string
 */

const CtaButtonCore = css<{ inactive?: boolean | 'true' | 'false' }>`
  ${ButtonCoreStyle};
  text-align: center;
  color: ${(p: ScProps) => p.color || p.theme.colors.white};
  background-color: ${(p: ScProps) =>
    p.inactive?.toString() === 'true'
      ? p.theme.colors.inactive_grey
      : p.bg || p.theme.colors.muted_blue};
  font-weight: ${(p: ScProps) => p.theme.fw.medium};
  border: 1px solid;
  border-color: ${(p: ScProps) =>
    `${
      p.inactive?.toString() === 'true'
        ? p.theme.colors.inactive_grey
        : p.bg || p.theme.colors.muted_blue
    }`};
  transition: 0.1s;
  pointer-events: ${({ inactive }) =>
    inactive?.toString() === 'true' ? 'none' : 'auto'};
  cursor: ${({ inactive }) =>
    inactive?.toString() === 'true' ? 'default' : 'pointer'};
  &:hover {
    background-color: ${(p: ScProps) =>
      p.$hoverBg || p.hoverBg || p.bg || p.theme.colors.muted_blue};
    color: ${(p: ScProps) => p.color || p.theme.colors.white};
    opacity: 0.9;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    pointer-events: none;
  }
`;

export const CtaButton = styled.button<{
  bg?: string;
  color?: string;
  inactive?: 'true' | 'false';
}>`
  ${CtaButtonCore}
` as any;

export const NoBorderButtonStyle = css`
  border: none;
  padding: 0 1.25em 0 1.25em;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.grey};
  &:focus {
    outline: none;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.black_grey};
    background: ${({ theme }) => theme.colors.light_grey};
  }
`;

export const NoBorderButton = styled.button`
  ${CtaButtonCore}
  ${NoBorderButtonStyle}
  min-width: auto;
`;

const TypographyButtonWrap = styled(Typography)<{
  bg?: CompoundColor;
  hoverBg?: CompoundColor;
  hoverColor?: CompoundColor;
  color?: CompoundColor;
  disabled?: boolean;
}>`
  border-radius: 4px;
  line-height: 1;
  padding: 0rem 0.5rem;
  transition: 0.1s ease;
  background: ${({ bg, theme }) => (bg ? theme.colors[bg] : 'transparent')};
  border: none;
  min-width: ${({ w }) => w || 'fit-content'};
  &:focus {
    outline: none;
  }

  &:hover {
    color: ${({ hoverColor, color, theme }) =>
      hoverColor
        ? theme.colors[hoverColor]
        : color
        ? theme.colors[color]
        : theme.colors.black};
    ${({ hoverBg, theme, bg }) => {
      if (hoverBg) {
        return `background: ${theme.colors[hoverBg]}`;
      }
      return `background: ${bg ? theme.colors[bg] : 'transparent'}`;
    }}
  }
`;

export const TypographyButton = (
  props: React.PropsWithChildren<TypographyProps> & {
    style?: React.CSSProperties;
    hoverColor?: CompoundColor;
    bg?: CompoundColor;
    hoverBg?: CompoundColor;
    type?: 'submit' | 'button' | 'reset';
    disabled?: boolean;
    ref?: React.RefObject<any>;
    as?: any;
    tabindex?: string;
  },
) => (
  <TypographyButtonWrap as="button" type={props.type || 'button'} {...props}>
    {props.children}
  </TypographyButtonWrap>
);

export const RemoveButton = styled(Button)`
  font-size: 0.875rem;
  text-align: center;
  font-weight: 525;
  line-height: 1;
  align-items: center;
  background-color: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] : theme.colors.white};
  color: ${({ theme }) => theme.colors.red};
  height: ${({ h }) => h || '40px'};
  min-width: 40px;
  padding: 0rem 1.25rem;
  width: ${({ w }) => w || 'initial'};
  &:hover {
    background: ${({ theme }) => theme.colors.off_white};
    color: ${({ theme }) => theme.colors.red};
  }
  path {
    stroke: ${({ theme }) => theme.colors.red};
  }
`;

export const OutlineButton = (
  props: ButtonProps & {
    children?: React.ReactNode | React.ReactNode[];
    onClick?: (event?: React.MouseEvent) => void;
    className?: string;
    style?: React.CSSProperties;
    type?: 'button' | 'submit';
    as?: any;
    rel?: string;
    href?: string;
    target?: string;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
  },
) => (
  <Button
    hoverBg="light_grey"
    color="black_grey"
    fontWeight={525}
    h="2.5rem"
    fontSize="0.875rem"
    {...props}
  >
    {props.children}
  </Button>
);
