/* TODO: Moved to src/widgets/AssetAllocationWidget/MekkoChart, remove once released  */

import React, { useState, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';

import { Text } from '@compoundfinance/design-system';
import { RechartsLabel } from 'types/chart';
import { RADIUS } from './constants';

enum BorderRadius {
  TopLeft = 'border-top-left-radius',
  TopRight = 'border-top-righ-radius',
  BottomRight = 'border-bottom-right-radius',
  BottomLeft = 'border-bottom-left-radius',
}
const RadiusTable = [
  BorderRadius.TopLeft,
  BorderRadius.TopRight,
  BorderRadius.BottomRight,
  BorderRadius.BottomLeft,
];

const MekkoSectionBorder = styled.div<{
  borderValues: BorderRadius[];
  isLastSection: boolean;
  isLastCategory: boolean;
}>`
  align-items: flex-end;
  border-bottom: ${({ isLastSection }) =>
    isLastSection ? 'none' : '1px solid rgba(255, 255, 255, 0.15)'};
  ${({ borderValues }) => {
    return borderValues.reduce(
      (memo, borderRadiusKey: string) => ({
        ...memo,
        [borderRadiusKey]: `${RADIUS}px`,
      }),
      {},
    );
  }}
  border-right: ${({ isLastCategory }) =>
    isLastCategory ? 'none' : '1px solid rgba(255, 255, 255, 0.15)'};

  display: flex;
  height: 100%;
  justify-content: flex-start;
  width: 100%;
`;

function LabelContent(props: { account: string; percent: string }) {
  return (
    <>
      <Text
        weight="medium"
        color="gray0"
        css={{
          whiteSpace: 'nowrap',
          display: 'block',
        }}
      >
        {props.account}
      </Text>
      <Text
        color="gray0"
        css={{
          whiteSpace: 'nowrap',
          display: 'block',
        }}
      >
        {props.percent}%
      </Text>
    </>
  );
}

// Small scalar to apply to the height of the bar, to account for text that should
// fit but doesnt. Think this is related to line height offset but not 100% sure
const HEIGHT_FUDGE = 1.1;

interface IMekkoSectionLabel extends RechartsLabel {
  accountName: string;
  percentage: string | string;
  radius: number[];
  onMouseEnter: (
    event: React.MouseEvent<SVGForeignObjectElement, MouseEvent>,
  ) => void;
  onMouseLeave: (
    event: React.MouseEvent<SVGForeignObjectElement, MouseEvent>,
  ) => void;
  isLastSection: boolean;
  isLastCategory: boolean;
}

function MekkoSectionLabel(props: IMekkoSectionLabel) {
  const {
    viewBox,
    accountName,
    percentage,
    radius,
    onMouseEnter,
    onMouseLeave,
    isLastCategory,
    isLastSection,
    ...rest
  } = props;
  // Track the size of the text so we know whether or not we can display it in the
  // mekko bar. If the text is larger than the dimensions of the bar chart,
  // we don't show the text.
  const textRef = useRef<HTMLParagraphElement | null>(null);
  const [dim, setDim] = useState({ height: 0, width: 0 });

  const viewBoxString = Object.values(viewBox).join(' ');
  const borderValues = radius
    .reduce((memo, r, index: number) => {
      if (r) {
        memo.push(index);
      }

      return memo;
    }, [] as number[])
    .map((i) => RadiusTable[i]);

  useLayoutEffect(() => {
    if (!textRef.current) {
      return;
    }

    const { height, width } = textRef.current.getBoundingClientRect();
    setDim({ height: Math.floor(height), width: Math.floor(width) });
  }, [textRef, setDim]);

  const canFitText =
    dim.height <= props.height * HEIGHT_FUDGE && dim.width <= props.width;

  return (
    <foreignObject
      {...rest}
      viewBox={viewBoxString}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <MekkoSectionBorder
        borderValues={borderValues}
        isLastSection={isLastSection}
        isLastCategory={isLastCategory}
      >
        <Text
          ref={textRef}
          color="gray0"
          size="12"
          css={{ mx: '$4', w: 'auto', display: 'block', p: '$6' }}
        >
          {canFitText && (
            <LabelContent account={accountName} percent={percentage} />
          )}
        </Text>
      </MekkoSectionBorder>
    </foreignObject>
  );
}

export default MekkoSectionLabel;
