import omit from 'lodash/omit';
import { ValueOf } from 'types';

import { AllocationAsset } from '../../../Accounts/AssetAllocation/AllocationExplorer';
import { ASSET_CATEGORY } from '../../constants';
import { Allocation, AllocationTypes } from '../Allocation';
import {
  TargetAllocationProposalStrategy,
  TargetAllocationProposalStrategyTypes,
} from './TargetAllocationProposalStrategy';
import {
  InvestmentPolicy,
  InvestorQualification,
  Preference,
} from '../AllocationProposalSettings';
import {
  AllocationProposalMessageTypes,
  AllocationProposalTypes,
} from '../AllocationProposal';
import { AllocationProposalStrategy } from '../AllocationProposalStrategy';
import { SimplifyOriginalAsset } from '../../utils/types';
import { NumberUtils } from 'utils/NumberUtils';
import { AllocationProposalCalculations } from '../AllocationProposalCalculations';
import { Fee } from '../../utils/Fee';
import { defaultTaxableAndRetirementStrategies } from '../../constants/ipsPortfolios';
import { not } from '../../utils';

export const AllocationProposalStatus = {
  Default: 'default',
  Archived: 'archived',
} as const;
export type AllocationProposalStatus = ValueOf<typeof AllocationProposalStatus>;

export const AllocationProposalType = {
  Strategic: 'strategic',
  Target: 'target',
} as const;
export type AllocationProposalType = ValueOf<typeof AllocationProposalType>;

export namespace TargetAllocationProposalTypes {
  export type AllocationProposal =
    AllocationProposalTypes.BaseAllocationProposal & {
      original: TargetAllocationProposalStrategyTypes.OriginalAllocationProposalStrategy;
      taxable: TargetAllocationProposalStrategyTypes.TaxableAllocationProposalStrategy;
      retirement: TargetAllocationProposalStrategyTypes.RetirementAllocationProposalStrategy;
    };
  export type CategorizedAllocationProposal =
    AllocationProposalTypes.BaseAllocationProposal & {
      original: TargetAllocationProposalStrategyTypes.CategorizedOriginalAllocationProposalStrategy;
      taxable: TargetAllocationProposalStrategyTypes.CategorizedTaxableAllocationProposalStrategy;
      retirement: TargetAllocationProposalStrategyTypes.CategorizedRetirementAllocationProposalStrategy;
    };
  export type BlendendAllocationProposals = {
    taxable: {
      wholistic: BlendendAllocationProposal;
      changes: BlendendAllocationProposal;
    };
    target: {
      wholistic: BlendendAllocationProposal;
      changes: BlendendAllocationProposal;
    };
  };
  export type BlendendAllocationProposal = {
    allocations: TargetAllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy['allocations'];
    annualReturn: number;
    annualVolatility: number;
    singleStockExposure: number;
    assets: Record<string, AllocationTypes.ClientAllocation>;
  };

  export type InvestmentAllocationProposal = Omit<
    SimplifyOriginalAsset<CategorizedAllocationProposal>,
    'blended'
  > & { blended: null };
}

const blended = (
  allocationProposal: Pick<
    TargetAllocationProposalTypes.CategorizedAllocationProposal,
    'original' | 'taxable' | 'retirement'
  >,
): AllocationProposalTypes.BlendendAllocationProposals => {
  const { original, taxable, retirement } = allocationProposal;
  const { allocations: originalAllocations, networth: originalNetworth } =
    original;
  const unomittedOriginalAllocations = AllocationProposalStrategy.toCategorized(
    AllocationProposalStrategy.fromCategorized(originalAllocations).filter(
      not(Allocation.isOmitted),
    ),
  );
  const blendedAllocations = AllocationProposalStrategy.toAssetClasses(
    unomittedOriginalAllocations,
  );
  const targetAllocations =
    TargetAllocationProposalStrategy.toBlendedAllocations(
      [taxable, retirement],
      originalNetworth,
    );
  const compoundFee = Fee.compoundInvestmentFee(originalNetworth);
  return {
    original: {
      wholistic: {
        allocations: AllocationProposalStrategy.recomputeValues({
          allocations: blendedAllocations,
          networth: originalNetworth,
        }),
        annualReturn: NumberUtils.subtract(
          AllocationProposalCalculations.return.mean(blendedAllocations),
          compoundFee,
        ),
        annualVolatility:
          AllocationProposalCalculations.volatility.standardDeviation(
            blendedAllocations,
          ),
        singleStockExposure: 0,
        assets: {},
        representationalAssets: {},
      },
      changes: {
        allocations: AllocationProposalStrategy.recomputeValues({
          allocations: blendedAllocations,
          networth: originalNetworth,
        }),
        annualReturn: NumberUtils.subtract(
          AllocationProposalCalculations.return.mean(blendedAllocations),
          compoundFee,
        ),
        annualVolatility:
          AllocationProposalCalculations.volatility.standardDeviation(
            blendedAllocations,
          ),
        singleStockExposure: 0,
        assets: {},
        representationalAssets: {},
      },
    },
    target: {
      wholistic: {
        allocations: AllocationProposalStrategy.recomputeValues({
          allocations: targetAllocations,
          networth: originalNetworth,
        }),
        annualReturn: NumberUtils.subtract(
          AllocationProposalCalculations.return.mean(targetAllocations),
          compoundFee,
        ),
        annualVolatility:
          AllocationProposalCalculations.volatility.standardDeviation(
            targetAllocations,
          ),
        singleStockExposure: 0,
        assets: {},
        representationalAssets: {},
      },
      changes: {
        allocations: AllocationProposalStrategy.recomputeValues({
          allocations: targetAllocations,
          networth: originalNetworth,
        }),
        annualReturn: NumberUtils.subtract(
          AllocationProposalCalculations.return.mean(targetAllocations),
          compoundFee,
        ),
        annualVolatility:
          AllocationProposalCalculations.volatility.standardDeviation(
            targetAllocations,
          ),
        singleStockExposure: 0,
        assets: {},
        representationalAssets: {},
      },
    },
  };
};

const DEFAULT_TARGET_INVESTOR_QUALIFICATION =
  InvestorQualification.NonAccreditedInvestor;
const DEFAULT_TARGET_INVESTMENT_POLICY = InvestmentPolicy.CapitalPreservation;
const DEFAULT_TARGET_INVESTMENT_PREFERENCE = Preference.Alternatives;
const create = ({
  original,
  type,
  ...allocationProposal
}: {
  original: TargetAllocationProposalStrategyTypes.OriginalAllocationProposalStrategy;
  type: AllocationProposalType;
} & Partial<TargetAllocationProposalTypes.AllocationProposal>): TargetAllocationProposalTypes.CategorizedAllocationProposal => {
  const { networth } = original;
  const settings = {
    id: 'CREATED_TARGET_SETTINGS',
    investorQualification: DEFAULT_TARGET_INVESTOR_QUALIFICATION,
    investmentPolicy: DEFAULT_TARGET_INVESTMENT_POLICY,
    preference: DEFAULT_TARGET_INVESTMENT_PREFERENCE,
  };
  const { taxable, retirement } = defaultTaxableAndRetirementStrategies({
    original,
    settings,
  });
  const {
    original: categorizedOriginal,
    taxable: categorizedTaxable,
    retirement: categorizedRetirement,
    ...categorizedProposal
  } = toCategorized({
    name: 'New asset allocation',
    description: 'Describe the proposal',
    original,
    taxable,
    retirement,
    settings,
    type,
    status: 'default',
    blended: blended({
      original: {
        ...original,
        allocations: AllocationProposalStrategy.toCategorized(
          original.allocations,
        ),
      },
      taxable: {
        ...taxable,
        allocations: AllocationProposalStrategy.toCategorized(
          taxable.allocations,
        ),
      },
      retirement: {
        ...retirement,
        allocations: AllocationProposalStrategy.toCategorized(
          retirement.allocations,
        ),
      },
    }),
    networth: NaN,
    unomittedNetworth: NaN,
    transitionedNetworth: NaN,
    createdAt: new Date().toString(),
    updatedAt: new Date().toString(),
    ...allocationProposal,
  });
  return {
    ...categorizedProposal,
    original: categorizedOriginal,
    taxable: categorizedTaxable,
    retirement: categorizedRetirement,
    networth,
    unomittedNetworth:
      TargetAllocationProposalStrategy.unomittedNetworth(categorizedOriginal),
    transitionedNetworth:
      TargetAllocationProposalStrategy.unomittedNetworth(categorizedOriginal),
  };
};

const isArchived = (
  allocationProposal: Pick<
    TargetAllocationProposalTypes.CategorizedAllocationProposal,
    'status'
  >,
) => allocationProposal.status === 'archived';

const isDefault = (
  allocationProposal: Pick<
    TargetAllocationProposalTypes.CategorizedAllocationProposal,
    'status'
  >,
) => allocationProposal.status === 'default';

const isLongTerm = (
  allocationProposal: Pick<
    TargetAllocationProposalTypes.CategorizedAllocationProposal,
    'type'
  >,
) => allocationProposal.type === AllocationProposalType.Target;

const longTerm = (
  allocationProposals: TargetAllocationProposalTypes.CategorizedAllocationProposal[],
) => allocationProposals.find(isLongTerm);

const isBlank = (
  allocationProposals: TargetAllocationProposalTypes.CategorizedAllocationProposal,
) => !allocationProposals.taxable && !allocationProposals.retirement;

const withBlended = (
  allocationProposal:
    | TargetAllocationProposalTypes.CategorizedAllocationProposal
    | TargetAllocationProposalTypes.InvestmentAllocationProposal,
): TargetAllocationProposalTypes.CategorizedAllocationProposal => {
  // @ts-ignore
  return {
    ...allocationProposal,
    blended: Object.hasOwn(allocationProposal, 'blended')
      ? // @ts-ignore
        allocationProposal.blended ?? blended(allocationProposal)
      : // @ts-ignore
        blended(allocationProposal),
  };
};

const fromCategorized = (
  allocationProposal: TargetAllocationProposalTypes.CategorizedAllocationProposal,
): TargetAllocationProposalTypes.AllocationProposal => {
  const { original, taxable, retirement } = allocationProposal;
  const { allocations: originalAllocations } = original;
  const { allocations: taxableAllocations } = taxable;
  const { allocations: retirementAllocations } = retirement;
  return {
    ...allocationProposal,
    original: {
      ...original,
      allocations:
        AllocationProposalStrategy.fromCategorized(originalAllocations),
    },
    taxable: {
      ...taxable,
      allocations:
        AllocationProposalStrategy.fromCategorized(taxableAllocations),
    },
    retirement: {
      ...retirement,
      allocations: AllocationProposalStrategy.fromCategorized(
        retirementAllocations,
      ),
    },
  };
};

const toCategorized = (
  allocationProposal: TargetAllocationProposalTypes.AllocationProposal,
): TargetAllocationProposalTypes.CategorizedAllocationProposal => {
  const { original, taxable, retirement } = allocationProposal;
  const { allocations: originalAllocations } = original;
  const { allocations: taxableAllocations } = taxable;
  const { allocations: retirementAllocations } = retirement;
  return {
    ...allocationProposal,
    original: {
      ...original,
      allocations:
        AllocationProposalStrategy.toCategorized(originalAllocations),
    },
    taxable: {
      ...taxable,
      allocations: AllocationProposalStrategy.toCategorized(taxableAllocations),
    },
    retirement: {
      ...retirement,
      allocations: AllocationProposalStrategy.toCategorized(
        retirementAllocations,
      ),
    },
  };
};

const toInvestmentProposal = (
  assetAllocationStrategy: TargetAllocationProposalTypes.CategorizedAllocationProposal,
): TargetAllocationProposalTypes.InvestmentAllocationProposal => {
  const {
    id,
    name,
    description,
    original,
    taxable,
    retirement,
    settings,
    networth,
    unomittedNetworth,
    transitionedNetworth,
    type,
    status,
    updatedAt,
    createdAt,
  } = assetAllocationStrategy;

  return {
    id,
    name,
    description,
    blended: null,
    settings,
    original: {
      ...original,
      allocations: AllocationProposalStrategy.toCategorized(
        AllocationProposalStrategy.fromCategorized(original.allocations).map(
          Allocation.toInvestmentProposal,
        ),
      ),
    },
    taxable: {
      ...taxable,
      allocations: AllocationProposalStrategy.toCategorized(
        AllocationProposalStrategy.fromCategorized(taxable.allocations).map(
          Allocation.toInvestmentProposal,
        ),
      ),
    },
    retirement: {
      ...retirement,
      allocations: AllocationProposalStrategy.toCategorized(
        AllocationProposalStrategy.fromCategorized(retirement.allocations).map(
          Allocation.toInvestmentProposal,
        ),
      ),
    },
    networth,
    unomittedNetworth,
    transitionedNetworth,
    type,
    status,
    updatedAt,
    createdAt,
  };
};

const toMessage = (
  assetAllocationStrategy: TargetAllocationProposalTypes.CategorizedAllocationProposal,
): AllocationProposalMessageTypes.AllocationProposalMessage => {
  const {
    original,
    taxable,
    retirement,
    settings: allocationProposalSetting,
    ...assetAllocationStrategyMessage
  } = assetAllocationStrategy;
  return {
    ...omit(
      assetAllocationStrategyMessage,
      'blended',
      'networth',
      'unomittedNetworth',
      'transitionedNetworth',
    ),
    strategies: [original, taxable, retirement].map(
      TargetAllocationProposalStrategy.toMessage,
    ),
    allocationProposalSetting,
  };
};

const fromMessage =
  ({
    assetCategories,
    assets,
  }: {
    assetCategories: ASSET_CATEGORY[];
    assets: AllocationAsset[];
  }) =>
  (
    assetAllocationStrategyMessage: AllocationProposalMessageTypes.AllocationProposalMessage,
  ): TargetAllocationProposalTypes.CategorizedAllocationProposal => {
    const {
      strategies: strategiesFromMessage,
      allocationProposalSetting: settings,
      ...rest
    } = assetAllocationStrategyMessage;
    const originalFromMessage = strategiesFromMessage.find(
      (strategy) => strategy.type === 'original',
    );
    const { networth = 0 } = originalFromMessage ?? {};
    const strategies = strategiesFromMessage.map(
      TargetAllocationProposalStrategy.fromMessage({
        assetCategories,
        assets,
        networth,
      }),
    );
    const original = TargetAllocationProposalStrategy.onlyOriginal(
      strategies,
    ) as TargetAllocationProposalStrategyTypes.CategorizedOriginalAllocationProposalStrategy;
    const taxable = TargetAllocationProposalStrategy.onlyTaxable(
      strategies,
    ) as TargetAllocationProposalStrategyTypes.CategorizedTaxableAllocationProposalStrategy;
    const retirement = TargetAllocationProposalStrategy.onlyRetirement(
      strategies,
    ) as TargetAllocationProposalStrategyTypes.CategorizedRetirementAllocationProposalStrategy;
    return {
      ...rest,
      settings,
      original,
      taxable,
      retirement,
      blended: blended({ original, taxable, retirement }),
      networth,
      unomittedNetworth: networth,
      transitionedNetworth: networth,
    };
  };

export const TargetAllocationProposal = {
  blended,
  create,
  fromCategorized,
  fromMessage,
  isArchived,
  isBlank,
  isDefault,
  isLongTerm,
  longTerm,
  toCategorized,
  toInvestmentProposal,
  toMessage,
  withBlended,
};
