import identity from 'lodash/identity';

import { ASSET_CATEGORIES } from '../constants';
import { Allocation, AllocationTypes } from '../domain/Allocation';
import {
  AllocationProposalStrategy,
  AllocationProposalStrategyTypes,
} from '../domain/AllocationProposalStrategy';
import { NumberUtils } from '../../../../utils/NumberUtils';

const getChildren = <AllocationType extends AllocationTypes.Allocation>({
  allocation,
  allocations,
}: {
  allocation: AllocationType;
  allocations: Record<string, AllocationType>;
}): AllocationType[] => {
  const { slug } = allocation;
  const children: AllocationType[] = [];
  for (const potentialChild of Object.values(allocations)) {
    if (slug === potentialChild.slug) continue;
    if (
      AssetCategoryTree.parent({ allocation: potentialChild, allocations })
        ?.slug !== slug
    )
      continue;

    children.push(potentialChild);
  }
  return children;
};

const getDescendants = <AllocationType extends AllocationTypes.Allocation>({
  allocation,
  allocations,
}: {
  allocation: AllocationType;
  allocations: Record<string, AllocationType>;
}): AllocationType[] => {
  const children = AssetCategoryTree.children({ allocation, allocations });
  return children.reduce((descendants, child) => {
    return [
      ...descendants,
      ...AssetCategoryTree.descendants({ allocation: child, allocations }),
    ];
  }, children);
};

const getSiblings = <AllocationType extends AllocationTypes.Allocation>({
  allocation,
  allocations,
}: {
  allocation: AllocationType;
  allocations: Record<string, AllocationType>;
}): AllocationType[] => {
  const { slug } = allocation;
  const parent = AssetCategoryTree.parent({ allocation, allocations });
  const siblings: AllocationType[] = [];
  for (const potentialSibling of Object.values(allocations)) {
    if (slug === potentialSibling.slug) continue;
    if (
      parent?.slug !==
      AssetCategoryTree.parent({ allocation: potentialSibling, allocations })
        ?.slug
    )
      continue;

    siblings.push(potentialSibling);
  }
  return siblings;
};

const getIsRoot = <AllocationType extends AllocationTypes.Allocation>({
  allocation,
  allocations,
}: {
  allocation: AllocationType;
  allocations: Record<string, AllocationType>;
}): boolean => AssetCategoryTree.level({ allocation, allocations }) === 0;

const getIsParent = <AllocationType extends AllocationTypes.Allocation>({
  allocation,
  allocations,
}: {
  allocation: AllocationType;
  allocations: Record<string, AllocationType>;
}): boolean =>
  AssetCategoryTree.children({ allocation, allocations }).length > 0;

const getIsLeaf = <AllocationType extends AllocationTypes.Allocation>({
  allocation,
  allocations,
}: {
  allocation: AllocationType;
  allocations: Record<string, AllocationType>;
}): boolean =>
  AssetCategoryTree.children({ allocation, allocations }).length === 0;

const leafs = <AllocationType extends AllocationTypes.Allocation>({
  allocations,
}: {
  allocations: Record<string, AllocationType>;
}): Record<string, AllocationType> =>
  AllocationProposalStrategy.toCategorized(
    AllocationProposalStrategy.fromCategorized(allocations).filter(
      (allocation) => AssetCategoryTree.isLeaf({ allocation, allocations }),
    ),
  );

const getTotalWeight = ({
  allocations,
}: {
  allocations: AllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy['allocations'];
}): number =>
  AllocationProposalStrategy.fromCategorized(allocations).reduce(
    (weight, allocation) =>
      NumberUtils.add(
        weight,
        AssetCategoryTree.isRoot({ allocation, allocations })
          ? AssetCategoryTree.invidualWeight({ allocation, allocations })
          : 0,
      ),
    0,
  ) || 0;

const getIndividualWeight = <
  AllocationType extends AllocationTypes.Allocation,
>({
  allocation,
  allocations,
}: {
  allocation: AllocationType;
  allocations: Record<string, AllocationType>;
}): number => {
  if (!Allocation.isCategoryAllocation(allocation))
    return Allocation.weight(allocation);

  const children = AssetCategoryTree.children({
    allocation,
    allocations,
  });
  if (children.length === 0) return allocation.weight ?? 0;
  return (
    children.reduce(
      (weight, child) =>
        NumberUtils.add(
          weight,
          AssetCategoryTree.invidualWeight({
            allocation: child,
            allocations,
          }) || 0,
        ),
      0,
    ) || 0
  );
};

const getParent = <AllocationType extends AllocationTypes.Allocation>({
  allocation,
  allocations,
}: {
  allocation: AllocationType;
  allocations: Record<string, AllocationType>;
}): AllocationTypes.Allocation | null => {
  if (Allocation.isCategoryAllocation(allocation))
    return (
      allocations[ASSET_CATEGORIES[allocation.assetCategorySlug]?.parent!] ??
      null
    );
  if (Allocation.isClientAllocation(allocation))
    return (
      allocations[ASSET_CATEGORIES[allocation.assetCategorySlug]?.slug] ?? null
    );

  throw new Error('Unreachable');
};

const getLevel = <AllocationType extends AllocationTypes.Allocation>({
  allocation,
  allocations,
}: {
  allocation: AllocationType;
  allocations: Record<string, AllocationType>;
}): number => {
  const parent = AssetCategoryTree.parent({ allocation, allocations });
  if (!parent) return 0;

  return getLevel({ allocation: parent, allocations }) + 1;
};

const getRoot = <AllocationType extends AllocationTypes.Allocation>({
  allocation,
  allocations,
}: {
  allocation: AllocationType;
  allocations: Record<string, AllocationType>;
}): AllocationTypes.Allocation => {
  const parent = AssetCategoryTree.parent({ allocation, allocations });
  if (!parent) return allocations[allocation.assetCategorySlug];

  return getRoot({ allocation: parent, allocations });
};

const normalize = <AllocationType extends AllocationTypes.Allocation>(
  allocations: Record<string, AllocationType>,
): Record<string, AllocationType> =>
  Object.fromEntries(
    Object.entries(allocations).map(([slug, allocation]) => [
      slug,
      {
        ...allocation,
        weight: AssetCategoryTree.invidualWeight({ allocation, allocations }),
      },
    ]),
  );

type LiftToNode<T> = T & { children: LiftToNode<T>[] };
const toNode = <MappedType extends {}>({
  allocation,
  allocations,
  mapper = identity,
}: {
  allocation: AllocationTypes.Allocation;
  allocations: AllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy['allocations'];
  mapper: (allocation: AllocationTypes.Allocation) => MappedType;
}): LiftToNode<MappedType> => {
  const children = AssetCategoryTree.children({
    allocation,
    allocations,
  });
  return {
    ...mapper(allocation),
    children: children.map((child) =>
      toNode({ allocation: child, allocations, mapper }),
    ),
  };
};
const toTree = <
  MappedType extends {},
  MapperType extends AllocationTypes.Allocation,
>(
  allocations: AllocationProposalStrategyTypes.CategorizedAllocationProposalStrategy['allocations'],
  mapper: (allocation: MapperType) => MappedType = identity,
): LiftToNode<MappedType>[] => {
  const roots = AllocationProposalStrategy.fromCategorized(allocations).filter(
    (allocation) => AssetCategoryTree.isRoot({ allocation, allocations }),
  );
  return roots.map((parent) =>
    toNode({ allocation: parent, allocations, mapper }),
  );
};

export const AssetCategoryTree = {
  children: getChildren,
  descendants: getDescendants,
  invidualWeight: getIndividualWeight,
  isLeaf: getIsLeaf,
  isParent: getIsParent,
  isRoot: getIsRoot,
  level: getLevel,
  normalize,
  parent: getParent,
  root: getRoot,
  siblings: getSiblings,
  totalWeight: getTotalWeight,
  leafs,
  toTree,
};
