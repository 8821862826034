export enum AuthMethods {
  Google = 'google',
  Apple = 'apple',
  Password = 'password',
  AdvisorRedirect = 'advisor_redirect',
}

export interface RedirectState {
  invitation_code?: string;
  request_access_id?: string;
  referral_code?: string;
}
